import { useState } from 'react';

import { useGetCaptureObjectsByCaptureId } from '@agerpoint/api';
import { DraggableWindow } from '@agerpoint/component';
import { IViewer } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { ObjectAttributeTableComponent } from '../attribute-tables/object-attributes/attribute-table-object';
import { EditableAttributeTableObjects } from '../attribute-tables/object-attributes/attribute-table-object-editable';

/**
 * @deprecated
 */
export const CaptureObjectsTableAndModal = ({
  viewer,
  captureId,
}: {
  captureId: number | undefined;
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
}) => {
  const {
    captureObjectWindowOpen,
    actions: { setCaptureObjectWindowOpen },
  } = useGlobalStore();
  const { data, refetch } = useGetCaptureObjectsByCaptureId({
    id: Number(captureId),
  });
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const closeEditableAttributeTable = () => {
    refetch();
    setCaptureObjectWindowOpen(true);
    setShowEditModal(false);
  };

  return (
    <>
      <DraggableWindow
        title="Capture Objects"
        show={captureObjectWindowOpen}
        height={224}
        width={450}
        toggleShow={() => {
          setCaptureObjectWindowOpen(!captureObjectWindowOpen);
        }}
        leftInitial={324}
        topInitial={window.innerHeight - 229}
      >
        <ObjectAttributeTableComponent
          captureId={captureId ?? 0}
          viewer={viewer}
          smallEditButton={true}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
        />
      </DraggableWindow>
      {showEditModal && (
        <EditableAttributeTableObjects
          isOpen={showEditModal}
          handleCloseDialog={closeEditableAttributeTable}
          captureId={captureId ?? 0}
          viewer={viewer}
        />
      )}
    </>
  );
};
