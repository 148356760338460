import { useQueryClient } from '@tanstack/react-query';

import { APIClient } from '@agerpoint/api';
import { APIUtils } from '@agerpoint/utilities';

export const useSharedToolbarQueries = () => {
  const queryClient = useQueryClient();

  const captureJobPutMutation = APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.captureJobs],
        });
      },
    },
  });

  return {
    captureJobPutMutation,
  };
};
