import { faClone, faPaste, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, Customer } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  CloneDatasetModal,
  Datatable,
  dataTableAgerStyle,
  useBackgroundTaskManager,
} from '@agerpoint/feature';
import { BackgroundTaskResult } from '@agerpoint/types';
import {
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
  useToasts,
} from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminProjectsQueries } from './admin-projects-queries';

export const AdminProjectsDetails = () => {
  usePageTitle(() => 'Platform - Projects', []);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const { user } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const formValidation = useFormValidation();
  const newLayerGroupFormValidation = useFormValidation();

  const toasts = useToasts();

  const backgroundTaskManager = useBackgroundTaskManager();

  const [project, setProject] =
    useState<Partial<APIModels.Project | undefined>>();

  const [selectedAnotherProject, setSelectedAnotherProject] =
    useState<APIModels.Project>();

  const {
    projectQuery,
    projectsQuery,
    organizationsQuery,
    usersQuery,
    layerGroupsQuery,
    layerGroupsPostMutation,
    cloneCapturesMutation,
    anotherProjectLayerGroupsQuery,
    projectPutMutation,
    projectArchiveDeleteMutation,
    projectUnarchivePutMutation,
  } = useAdminProjectsQueries(undefined, selectedAnotherProject);

  useEffect(() => {
    setProject(projectQuery.data);
  }, [projectQuery.data]);

  const users = useMemo(() => {
    if (!usersQuery.data || !user) return undefined;

    // If the user is not in the list of users, add them to the list from the global store
    if (usersQuery.data.findIndex((u) => u.id === user.id) === -1) {
      return [...usersQuery.data, user];
    }

    return usersQuery.data;
  }, [usersQuery, user]);

  useEffect(() => {
    setSelectedOrganization(
      organizationsQuery.data?.find(
        (o) => o.id === projectQuery.data?.customerId
      )
    );
  }, [projectQuery.data, organizationsQuery.data]);

  useEffect(() => {
    if (projectQuery.isLoading || usersQuery.isLoading) {
      return;
    }

    setSelectedOwner(
      usersQuery.data?.find((u) => u.id === projectQuery.data?.owner) ??
        user ??
        undefined
    );
  }, [
    projectQuery.data,
    projectQuery.isLoading,
    usersQuery.isLoading,
    usersQuery.data,
    user,
  ]);

  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();
  const [selectedOwner, setSelectedOwner] = useState<APIModels.User>();

  useEffect(() => {
    setProject((prev) => ({
      ...prev,
      customerId: selectedOrganization?.id,
    }));
  }, [selectedOrganization]);

  useEffect(() => {
    setProject((prev) => ({
      ...prev,
      owner: selectedOwner?.id,
    }));
  }, [selectedOwner]);

  const saveProject = useCallback(async () => {
    if (
      projectPutMutation.isPending ||
      !project?.uuid ||
      projectQuery.isLoading
    ) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    projectPutMutation.mutate({
      uuid: project.uuid as string,
      data: project as APIModels.Project,
    });
  }, [formValidation, project, projectPutMutation, projectQuery.isLoading]);

  const [fromLayerGroup, setFromLayerGroup] = useState<APIModels.LayerGroup>();
  const [toLayerGroup, setToLayerGroup] = useState<APIModels.LayerGroup>();

  const [newLayerGroupName, setNewLayerGroupName] = useState<string>('');

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Projects',
              path: isViteApp
                ? '/app/admin/platform/projects'
                : '/admin/projects',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-project-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/projects' + params);
            } else {
              navigate('/admin/projects' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">{projectQuery.data?.name}</h1>
      </div>
      {projectQuery.isLoading ? (
        <PageLoadingState />
      ) : projectQuery.isError ? (
        <PageErrorState
          entityName="project"
          pluralEntityName="projects"
          statusCode={projectQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            projectQuery.refetch();
          }}
          tryAgainLoading={projectQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/projects' + params
                : '/admin/projects' + params
            )
          }
        />
      ) : (
        <div className="w-full h-full">
          <div className="p-4 w-full flex flex-col max-w-lg gap-2">
            <Input.Text.Single
              id="project-name"
              label={<Input.Label label="Project Name" required />}
              value={project?.name ?? ''}
              setValue={(name) => {
                setProject((p) => ({ ...p, name }));
              }}
              error={
                <Input.Error error={formValidation.errors['project-name']} />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Name')],
              }}
            />
            <Input.Select.Single
              id="project-organization"
              options={organizationsQuery.data ?? []}
              loading={organizationsQuery.isLoading}
              optionBuilder={(customer) =>
                customer.customerDisplayName ??
                customer.customerName ??
                'Unknown'
              }
              label={<Input.Label label="Organization" required />}
              title="Organization"
              value={selectedOrganization}
              setValue={setSelectedOrganization}
              error={
                <Input.Error
                  error={formValidation.errors['project-organization']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Organization')],
              }}
              compareFn={(a, b) => a.id === b.id}
            />
            <Input.Select.Single
              id="project-owner"
              options={usersQuery.data ?? []}
              loading={usersQuery.isLoading}
              optionBuilder={(o) =>
                `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
              }
              label={<Input.Label label="Owner" required />}
              title="Owner"
              value={selectedOwner}
              setValue={setSelectedOwner}
              error={
                <Input.Error error={formValidation.errors['project-owner']} />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Owner')],
              }}
            />
            <div className="flex flex-row justify-end gap-2 py-4">
              {!isViteApp && (
                <Button.Secondary
                  id="open-in-viewer-button"
                  label={'Open in Viewer'}
                  onClick={() => {
                    navigate(`/project/${project?.uuid}`, {
                      state: { params },
                    });
                  }}
                />
              )}
              {!project?.archived && (
                <Button.Danger
                  id="archive-project-button"
                  onClick={() => {
                    const confirm = window.confirm(
                      'Are you sure you want to archive this project?'
                    );

                    if (confirm) {
                      projectArchiveDeleteMutation.mutate({
                        uuid: project?.uuid as string,
                      });
                    }
                  }}
                  label={'Archive'}
                  disabled={projectUnarchivePutMutation.isPending}
                  loading={projectArchiveDeleteMutation.isPending}
                />
              )}
              {project?.archived && (
                <Button.Secondary
                  id="restore-project-button"
                  onClick={() => {
                    const confirm = window.confirm(
                      'Are you sure you want to restore this project?'
                    );

                    if (confirm) {
                      projectUnarchivePutMutation.mutate({
                        uuid: project?.uuid as string,
                        data: {
                          ...(projectQuery.data as APIModels.Project),
                          archived: false,
                        },
                      });
                    }
                  }}
                  label={'Restore'}
                  disabled={projectArchiveDeleteMutation.isPending}
                  loading={projectUnarchivePutMutation.isPending}
                />
              )}
              <Button.Primary
                id="save-project-button"
                label="Save"
                onClick={saveProject}
                disabled={
                  projectPutMutation.isPending ||
                  projectArchiveDeleteMutation.isPending ||
                  projectUnarchivePutMutation.isPending
                }
              />
            </div>
          </div>
          <hr className="mx-4" />
          <div className="p-4">
            <div className="w-full flex flex-row font-bold">
              Clone captures between layer groups
            </div>
            <div className="py-2">
              <div className="flex flex-col">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-4 mr-2 flex flex-col">
                    <span className="font-bold text-sm">Layer Groups</span>
                    <div className="h-48 border rounded border-gray-500">
                      <Datatable
                        style={{
                          ...dataTableAgerStyle,
                          headerWrapperStyle:
                            'px-2 text-xs text-gray-700 font-normal border-b border-gray-500',
                          rowWrapperStyle:
                            'px-2 items-center text-sm hover:bg-gray-100',
                          headerStyle: 'pr-1 py-1 h-full flex items-center',
                        }}
                        noResults={{
                          message: 'No layer groups found',
                        }}
                        error={
                          layerGroupsQuery.isError
                            ? {
                                title:
                                  'There was a problem loading layer groups',
                                message: 'Try refreshing the page',
                                action: () => layerGroupsQuery.refetch(),
                              }
                            : undefined
                        }
                        data={layerGroupsQuery.data ?? []}
                        loading={layerGroupsQuery.isLoading}
                        columns={[
                          { label: 'Name', value: (row) => row.name },
                          {
                            label: null,
                            value: (g, index) => {
                              if (fromLayerGroup?.id === g.id) {
                                return (
                                  <div className="p-1">
                                    <Button.Small
                                      id={`cancel-clone-${index}`}
                                      label="Cancel"
                                      icon={faXmark}
                                      onClick={() => {
                                        setFromLayerGroup(undefined);
                                      }}
                                    />
                                  </div>
                                );
                              }
                              if (fromLayerGroup !== undefined) {
                                return (
                                  <div className="p-1">
                                    <Button.Small
                                      id={`paste-clone-${index}`}
                                      icon={faPaste}
                                      label="Paste Captures"
                                      onClick={() => {
                                        setToLayerGroup(g);
                                      }}
                                    />
                                  </div>
                                );
                              }
                              return (
                                <div className="p-1">
                                  <Button.Small
                                    id={`clone-${index}`}
                                    label="Clone Captures"
                                    icon={faClone}
                                    onClick={() => {
                                      setFromLayerGroup(g);
                                    }}
                                  />
                                </div>
                              );
                            },
                            style: {
                              bodyStyle: 'justify-end overflow-visible',
                              headerStyle: 'justify-end overflow-visible',
                            },
                          },
                        ]}
                        rowHeight={40}
                      />
                    </div>
                    <div className="flex flex-row gap-2 items-start pt-2">
                      <Input.Text.Single
                        id="new-group-name"
                        disabled={
                          layerGroupsPostMutation.isPending ||
                          layerGroupsQuery.isLoading
                        }
                        value={newLayerGroupName}
                        setValue={setNewLayerGroupName}
                        validation={{
                          validationState: newLayerGroupFormValidation,
                          validators: [Input.validators.required('Name')],
                        }}
                        error={
                          <Input.Error
                            error={
                              newLayerGroupFormValidation.errors[
                                'new-group-name'
                              ]
                            }
                          />
                        }
                        label={<Input.Label label="Name" required />}
                        placeholder="Create new layer group"
                      />
                      <div className="mt-5">
                        <Button.Primary
                          id="create-layer-group-button"
                          label="Create"
                          loading={layerGroupsPostMutation.isPending}
                          disabled={layerGroupsQuery.isLoading}
                          onClick={async () => {
                            if (await newLayerGroupFormValidation.hasErrors()) {
                              return;
                            }

                            layerGroupsPostMutation.mutate(
                              {
                                data: {
                                  name: newLayerGroupName,
                                  projectId: projectQuery.data?.id,
                                  visible: true,
                                },
                              },
                              {
                                onSuccess: () => {
                                  setNewLayerGroupName('');
                                },
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {fromLayerGroup && (
                    <div className="col-span-4 flex flex-col">
                      <Input.Select.Single
                        title="Project"
                        id="another-project"
                        optionBuilder={(o) => o.name}
                        options={projectsQuery.data ?? []}
                        value={selectedAnotherProject}
                        setValue={(value) => {
                          setSelectedAnotherProject(value);
                        }}
                        loading={projectsQuery.isLoading}
                        placeholder="Select Another Project"
                        label={
                          <Input.Label label="Paste in another Project's Layer Group" />
                        }
                      />
                    </div>
                  )}
                  {fromLayerGroup && selectedAnotherProject && (
                    <div className="col-span-4 flex flex-col">
                      <span className="font-bold truncate text-sm">
                        {selectedAnotherProject.name}
                      </span>
                      <div className="h-48 border rounded border-gray-500">
                        <Datatable
                          style={{
                            ...dataTableAgerStyle,
                            headerWrapperStyle:
                              'px-2 text-xs text-gray-700 font-normal border-b border-gray-500',
                            rowWrapperStyle:
                              'px-2 items-center text-sm hover:bg-gray-100',
                            headerStyle: 'pr-1 py-1 h-full flex items-center',
                          }}
                          data={anotherProjectLayerGroupsQuery.data ?? []}
                          loading={anotherProjectLayerGroupsQuery.isLoading}
                          noResults={{
                            message: 'No layer groups found',
                          }}
                          error={
                            anotherProjectLayerGroupsQuery.isError
                              ? {
                                  title:
                                    'There was a problem loading layer groups',
                                  message: 'Try refreshing the page',
                                  action: () =>
                                    anotherProjectLayerGroupsQuery.refetch(),
                                }
                              : undefined
                          }
                          columns={[
                            {
                              label: 'Layer Group Name',
                              value: (row) => row.name,
                            },
                            {
                              label: null,
                              value: (g, index) => {
                                return (
                                  <div className="p-1">
                                    <Button.Small
                                      id={`paste-clone-${index}`}
                                      icon={faPaste}
                                      label="Paste Captures"
                                      onClick={() => {
                                        setToLayerGroup(g);
                                      }}
                                    />
                                  </div>
                                );
                              },
                              style: {
                                bodyStyle: 'justify-end overflow-visible',
                                headerStyle: 'justify-end overflow-visible',
                              },
                            },
                          ]}
                          rowHeight={40}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <CloneDatasetModal
                  cloningCount={1}
                  users={users}
                  organizations={organizationsQuery.data as Customer[]}
                  open={
                    fromLayerGroup !== undefined && toLayerGroup !== undefined
                  }
                  handleCloseDialog={() => {
                    setToLayerGroup(undefined);
                    setFromLayerGroup(undefined);
                  }}
                  title={`Clone Captures from ${fromLayerGroup?.name} to ${toLayerGroup?.name}`}
                  onClone={(org, user) => {
                    if (!fromLayerGroup?.id || !toLayerGroup?.id) {
                      return;
                    }

                    const fromId = fromLayerGroup.id;
                    const toId = toLayerGroup.id;

                    backgroundTaskManager.addTaskGroup({
                      groupDesc: `${fromLayerGroup.name} > ${toLayerGroup.name}: Cloning Captures`,
                      groupTasks: [
                        backgroundTaskManager.createBackgroundTask(
                          `${fromLayerGroup.name} > ${toLayerGroup.name}: Cloning Captures`,
                          async (resolve) => {
                            cloneCapturesMutation.mutate({
                              destinationGroupCaptureId: toId,
                              originGroupCaptureId: fromId,
                              data: {
                                userUuid: user,
                                customerId: org,
                              },
                            });

                            resolve({ type: BackgroundTaskResult.success });
                          }
                        ),
                      ],
                      onError: async () => {
                        toasts.add(
                          toasts.prepare.error('Failed to clone capture(s)!')
                        );
                      },
                      onSuccess: async () => {
                        toasts.add(toasts.prepare.entityCloned('capture(s)'));
                      },
                    });

                    toasts.add({
                      title: 'Cloning capture(s)',
                      message: `From ${fromLayerGroup.name} to ${toLayerGroup.name}`,
                      type: 'info',
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
