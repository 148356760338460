import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import { APIUtils, useIsViteApp, useToasts } from '@agerpoint/utilities';

export const useAdminPipelinesQueries = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const toasts = useToasts();

  const { pipelineId } = useParams();

  const pipelineQuery = APIClient.useGetPipelineById(Number(pipelineId), {
    query: {
      enabled: Number.isSafeInteger(Number(pipelineId)),
      queryKey: [
        APIUtils.QueryKey.pipelines,
        { pipelineId: Number(pipelineId) },
      ],
      initialData: () =>
        APIUtils.searchQueriesForInitialValue<APIModels.Pipeline>({
          queryClient,
          queryKey: [APIUtils.QueryKey.pipelines],
          id: Number(pipelineId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const pipelinesQuery = APIClient.useGetPipelines({
    query: {
      queryKey: [APIUtils.QueryKey.pipelines],
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      select: (data) => APIUtils.Sort.pipelines(data),
    },
  });

  const pipelinePutMutation = APIClient.usePutPipelineById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.pipelines],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.Pipeline>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.pipelines,
            { pipelineId: variables.data.id },
          ],
          data: variables.data,
        });

        APIUtils.updateListQueryCache<APIModels.Pipeline>({
          queryClient,
          queryKey: [APIUtils.QueryKey.pipelines],
          data: variables.data,
          accessor: 'id',
          id: variables.data.id,
        });

        toasts.add(toasts.prepare.entityUpdated('pipeline'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create pipeline!'));
      },
    },
  });

  useEffect(() => {
    if (pipelineId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(pipelineId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/pipelines' + params);
      } else {
        navigate('/admin/pipelines' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.pipelines,
          { pipelineId: Number(pipelineId) },
        ],
      });
    }
  }, [pipelineId]);

  const pipelinePostMutation = APIClient.usePostPipeline({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.pipelines],
        });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.Pipeline>({
          queryClient,
          queryKey: [APIUtils.QueryKey.pipelines, { pipelineId: data.id }],
          data: data,
        });
        toasts.add(toasts.prepare.entityCreated('pipeline'));

        if (isViteApp) {
          navigate(`/app/admin/platform/pipelines/${data.id}/details`, {
            state: { params },
          });
        } else {
          navigate(`/admin/pipelines/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create pipeline!'));
      },
    },
  });

  return {
    pipelinesQuery,
    pipelineQuery,
    pipelinePutMutation,
    pipelinePostMutation,
  };
};
