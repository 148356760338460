import { useCallback, useEffect, useState } from 'react';

import { EventBusNames, LdFlags } from '@agerpoint/types';
import { eventBus, hasPermission, useGlobalStore } from '@agerpoint/utilities';

interface GalleryProps {
  items: React.ReactNode[];
  orientation: GalleryOrientation;
  verticalColumns?: number;
}

export enum GalleryOrientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export enum GalleryEvents {
  scrollTo = 'galleryScrollTo',
}

interface GalleryItemProps {
  item: React.ReactNode;
  index: number;
  parentRef: HTMLDivElement | undefined;
  orientation: GalleryOrientation;
  verticalColumns: number;
  scrollToOverrideId: string;
}

export const Gallery = ({
  items,
  orientation,
  verticalColumns = 5,
}: GalleryProps) => {
  const { permissions } = useGlobalStore();
  const [parentRef, setParentRef] = useState<HTMLDivElement | undefined>();
  const [scrollToOverrideId, setScrollToOverrideId] = useState('');

  useEffect(() => {
    const hasPerm = hasPermission(LdFlags.PotreeAutoScrollGallery, permissions);
    if (!hasPerm) return;
    const callback = (payload: any) => {
      setScrollToOverrideId(payload.detail.id);
    };
    const eventId = eventBus.on(EventBusNames.UpdateClosestImage, callback);
    return () => {
      eventBus.remove(EventBusNames.UpdateClosestImage, callback, eventId);
    };
  }, [permissions]);

  return (
    <div
      ref={(ref) => setParentRef(ref ?? undefined)}
      className={`w-full h-full flex flex-row 
      ${
        orientation === GalleryOrientation.Horizontal
          ? 'overflow-x-auto overflow-y-hidden items-center'
          : 'overflow-x-hidden overflow-y-auto flex-wrap items-start'
      }
      `}
    >
      {items.map((item, index) => (
        <GalleryItem
          key={index}
          index={index}
          item={item}
          parentRef={parentRef}
          orientation={orientation}
          verticalColumns={verticalColumns}
          scrollToOverrideId={scrollToOverrideId}
        />
      ))}
    </div>
  );
};

export const GalleryItem = ({
  item,
  index,
  parentRef,
  orientation,
  verticalColumns,
  scrollToOverrideId,
}: GalleryItemProps) => {
  const [itemRef, setItemRef] = useState<HTMLDivElement | undefined>();

  const scrollToSelf = useCallback(
    (e: any, smoothBehavior = false) => {
      if (orientation === GalleryOrientation.Vertical) {
        return;
      }

      if (e.detail.index === index) {
        if (!parentRef || !itemRef) {
          return;
        }
        const itemOffset = itemRef.offsetLeft;
        const itemWidth = itemRef.getBoundingClientRect().width;
        const parentWidth = parentRef.getBoundingClientRect().width;
        const left = Math.round(itemOffset - parentWidth / 2 + itemWidth / 2);
        parentRef.scrollTo({
          left: left,
          behavior: smoothBehavior ? 'smooth' : undefined,
        });
      }
    },
    [parentRef, itemRef, index, orientation]
  );

  useEffect(() => {
    window.addEventListener(GalleryEvents.scrollTo, scrollToSelf, {
      passive: true,
    });
    return () => {
      window.removeEventListener(GalleryEvents.scrollTo, scrollToSelf);
    };
  }, [scrollToSelf]);

  useEffect(() => {
    const id = (item as any).key;
    if (id === scrollToOverrideId.toString()) {
      scrollToSelf({ detail: { index } }, true);
    }
  }, [scrollToOverrideId]);

  return (
    <div
      className="flex justify-start items-center"
      ref={(ref) => setItemRef(ref ?? undefined)}
      style={{
        width:
          orientation === GalleryOrientation.Horizontal
            ? undefined
            : `${100 / verticalColumns}%`,
        height:
          orientation === GalleryOrientation.Horizontal ? '100%' : undefined,
      }}
    >
      <div
        className="w-full h-full flex justify-center items-center p-0.5"
        style={{ aspectRatio: '1 / 1' }}
        onClick={() => {
          scrollToSelf({ detail: { index } }, true);
        }}
      >
        {item}
      </div>
    </div>
  );
};
