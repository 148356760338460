import {
  faCheck,
  faCircleNotch,
  faMagnifyingGlass,
  faPenField,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import {
  convertAttributeValueToUIFriendly,
  markerColorPalette,
  useFormValidation,
} from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer-context';
import { useCapturesViewerQueries } from '../../../captures-viewer-queries';
import { CVS3AnalyticsRequestListFilter } from '../analytics-requests-list/cvs3-analytics-requests-list';

interface CVS3ObjectsListProps {
  captureObject: APIModels.CaptureObject;
  limited: boolean;
  index: number;
  zoomable?: boolean;
  renameable?: boolean;
  filter?: CVS3AnalyticsRequestListFilter;
}

export const CVS3ObjectsList = ({
  captureObject,
  limited,
  index,
  zoomable,
  renameable,
  filter,
}: CVS3ObjectsListProps) => {
  const { viewerController } = useCapturesViewerContext();

  const { captureObjectPutMutation } = useCapturesViewerQueries({});

  const [renameActive, setRenameActive] = useState(false);

  const limitedTo = useMemo(() => 3, []);

  const attributes = useMemo(
    () => captureObject.captureObjectCustomAttributes,
    [captureObject]
  );

  const [newName, setNewName] = useState(captureObject?.name ?? '');
  const renameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (renameActive) {
      renameInputRef.current?.focus();
    }
  }, [renameActive]);

  const color = useMemo(
    () => markerColorPalette[index % markerColorPalette.length],
    [index]
  );

  const magnifyingGlassClicked = useCallback(() => {
    const x = captureObject.x;
    const y = captureObject.y;
    const z = captureObject.z;

    if (!x || !y || !z) {
      return;
    }

    viewerController?.potreeController?.zoomToLocation({
      loc: { x, y, z },
      distance: 1,
    });
  }, [viewerController?.potreeController?.info.viewerReady, captureObject]);

  const formValidation = useFormValidation();

  const saveCaptureObjectName = useCallback(async () => {
    if (!captureObject.id) {
      return;
    }
    if (await formValidation.hasErrors()) {
      return;
    }

    

    const copy = cloneDeep(captureObject);
    delete copy.captureObjectCustomAttributes;

    captureObjectPutMutation.mutate({
      id: captureObject.id,
      data: {
        ...copy,
        name: newName,
      },
    });

    setRenameActive(false);
  }, [formValidation, captureObject, newName, captureObjectPutMutation]);

  return (
    <div className="flex flex-col px-3 py-1">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-1 items-center w-full">
          <div className="flex flex-row justify-between items-center w-full gap-1 truncate">
            {zoomable && (
              <div
                style={{ backgroundColor: color }}
                className={`w-5 h-5 rounded-full bg-red shadow shrink-0
           flex items-center justify-center border hover:border-white cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  magnifyingGlassClicked();
                }}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="w-3 h-3 text-white"
                />
              </div>
            )}
            {!renameActive ? (
              <span className="text-sm w-full truncate">
                {captureObject?.name}
              </span>
            ) : (
              <div className="w-full">
                <Input.Text.Single
                  id={`capture-object-name-input-${captureObject.id}`}
                  value={newName}
                  inputRef={renameInputRef}
                  setValue={setNewName}
                  style={Input.style.mini}
                  validation={{
                    validationState: formValidation,
                    validators: [Input.validators.required('Name')],
                  }}
                  error={
                    <Input.Error
                      error={
                        formValidation.errors[
                          `capture-object-name-input-${captureObject.id}`
                        ]
                      }
                    />
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      saveCaptureObjectName();
                    }

                    if (e.key === 'Escape') {
                      setRenameActive(false);
                    }
                  }}
                />
              </div>
            )}
            {renameable ? (
              renameActive ? (
                <div className="p-1">
                  <Button.Icon
                    id="save-rename-button"
                    icon={faCheck}
                    loading={captureObjectPutMutation.isPending}
                    internalPadding="p-0.5"
                    onClick={() => {
                      saveCaptureObjectName();
                    }}
                  />
                </div>
              ) : (
                <div className="p-1">
                  <Button.Icon
                    id="rename-button"
                    icon={faPenField}
                    loading={captureObjectPutMutation.isPending}
                    internalPadding="p-0.5"
                    onClick={() => {
                      setRenameActive(true);
                    }}
                  />
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
      {(attributes?.length ?? 0) > 0 && (
        <div className="flex flex-col pl-2 divide-y divide-gray-300">
          <div className="flex flex-col text-xs divide-y divide-gray-300">
            {attributes
              ?.slice(0, limited ? limitedTo : undefined)
              .filter((attribute) => {
                if (filter === undefined || filter?.attributeNames === '') {
                  return true;
                }

                const attributeNames = filter.attributeNames
                  .split(',')
                  .map((a) => a.trim())
                  .filter((a) => a !== '');
                return attributeNames.some((attributeName) => {
                  const attrName =
                    attribute.attributeDisplayName ?? attribute.attributeName;
                  return attrName
                    ?.toLowerCase()
                    .includes(attributeName.toLowerCase().trim());
                });
              })
              .map((attribute, key) => (
                <div key={key} className="flex flex-row justify-between gap-2">
                  <span className="truncate">
                    {attribute.attributeDisplayName ?? attribute.attributeName}
                  </span>
                  <span className="whitespace-nowrap truncate">
                    {convertAttributeValueToUIFriendly(attribute)}
                  </span>
                </div>
              ))}
          </div>
          {limited &&
            limitedTo > 0 &&
            attributes &&
            attributes.length > limitedTo && (
              <div className="text-xs text-gray-500">
                <span>
                  {attributes.length - limitedTo} more attribute(s)...
                </span>
              </div>
            )}
        </div>
      )}
    </div>
  );
};
