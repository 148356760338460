import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';

export const analytics = (data?: APIModels.Analytic[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) =>
    compare(a.analyticName?.toLowerCase(), b.analyticName?.toLowerCase())
  );
  return sorted;
};
