import { PerspectiveCamera, Raycaster, Scene, Vector2, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { APIClient } from '@agerpoint/api';
import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  EventBusNames,
  GeomType,
  HexColor,
  ICustomLine,
  ICustomMesh,
  Line,
  Point,
  Polygon,
  SpecialCaptureObject,
} from '@agerpoint/types';
import { AnnotationGroupName } from '@agerpoint/utilities';

import { AnnotationDrawing } from './annotations-drawing/annotations-drawing';

export class AnnotationsController extends AnnotationDrawing {
  camera: PerspectiveCamera | undefined;
  controls: OrbitControls | undefined;

  protected animationLoopRequestId: number | undefined;
  protected targetEl: HTMLElement;

  constructor(
    camera: PerspectiveCamera,
    scene: Scene,
    controls: OrbitControls,
    targetEl: HTMLElement,
    mouseRef: React.MutableRefObject<Vector3 | undefined>,
    isPotree: boolean = false,
    isMobile: boolean = false
  ) {
    super(camera, scene, targetEl, mouseRef, isPotree);
    this.camera = camera;
    this.camera.far = 1000;
    this.camera.near = 0.0001;
    this.controls = controls;
    this.targetEl = targetEl;
    targetEl.addEventListener('mousemove', this.mouseMove);

    if (!isMobile) {
      this.annotation2d?.on('mousedown', this.object2dMouseDown);
      this.annotation2d?.on('mouseup', this.object2dMouseUp);
      this.annotation3d?.on('label-click', this.object3dLabelClick);
    }

    this.mouseReticle = this.createWhiteSphereReticle();
    this.scene.add(this.mouseReticle);
    this.startAnimationLoop();
  }

  get creating2dPoint() {
    return this._creating2dPoint;
  }
  get creating3dLine() {
    return this._creating3dLine;
  }
  get creating3dMultiPoint() {
    return this._creating3dMultiPoint;
  }

  get editing2dPoint() {
    return !!this.point2dBeingEdited_uniqueId;
  }
  get editing3dLine() {
    return !!this.line3dBeingEdited_uniqueId;
  }
  get editing2dMultiPoint() {
    return !!this.multiPoint2dBeingEdited_uniqueId;
  }

  get activeTool() {
    if (this._creating2dPoint) return GeomType.Point;
    if (this._creating3dLine) return GeomType.LineString;
    if (this._creating3dPolygon) return GeomType.Polygon;
    if (this._creating3dMultiPoint) return GeomType.MultiPoint;
    if (this._editing3dLine) return GeomType.LineString;
    if (this._editing2dPoint) return GeomType.Point;
    if (this.point2dBeingEdited_uniqueId) return GeomType.Point;
    if (this.multiPoint2dBeingEdited_uniqueId) return GeomType.MultiPoint;
    if (this.line3dBeingEdited_uniqueId) return GeomType.LineString;
    return '';
  }

  object2dMouseDown = (id: string) => {
    const point = this.annotation2d?.get2dPointById(id);
    const multiPoint = this.annotation2d?.get2dMultiPointByChildId(id);
    if (!point && !multiPoint) {
      this.cancelEditing2dPoint();
      return;
    }

    this.annotation2d?.annoMgr.captureObjectWasClickedIn3D(id);

    if (point) {
      if (this._creating2dPoint) {
        this.annotation2d?.unHighlightAll();
        this.finishCreating2dPoint(false);
        return;
      }

      if (id === this.point2dBeingEdited_uniqueId) {
        this.startMoving2dPoint();
      } else {
        this.annotation2d?.highlight2dPointById(id);
        this.startEditing2dPoint(id);
      }
    }

    if (multiPoint) {
      // we can reuse point2d edit methods for multiPoint children
      const childPoint = multiPoint.getChildById(id);
      if (!childPoint) {
        this.annotation2d?.unHighlightAll();
        this.cancelEditing2dPoint();
        return;
      }

      if (this._creating2dMultiPoint) {
        this.annotation2d?.unHighlightAll();
        this.add2dMultiPointToPending(id);
        return;
      }
      if (id === this.multiPoint2dBeingEdited_uniqueId) {
        this.startMoving2dMultiPoint();
      } else {
        this.annotation2d?.highlight2dMultiPointById(multiPoint?.uniqueId);
        this.startEditing2dMultiPoint(childPoint?.uniqueId);
      }
    }
  };

  object2dMouseUp = (id: string) => {
    const point = this.annotation2d?.get2dPointById(id);
    const multiPoint = this.annotation2d?.get2dMultiPointByChildId(id);

    if (!point && !multiPoint) {
      this.cancelEditing2dPoint();
      return;
    }
    if (this._editing2dPoint && point) {
      this.annotation2d?.unHighlightAll();
      this.finishEditing2dPoint();
    }
    if (this._editing2dMultiPoint && multiPoint) {
      this.annotation2d?.unHighlightAll();
      this.finishEditing2dMultiPoint();
    }
  };

  object3dLabelClick = (id: string, type: Annotation3dLines) => {
    if (type === Annotation3dLines.AnnotationLine) {
      const line = this.annotation3d?.get3dLineById(id);
      if (!line) return;
      this.object3dMouseDown(line.lineObject);
    }
  };

  object3dMouseDown(object: ICustomLine | ICustomMesh) {
    if (!object) return;
    this.annotation3d?.annoMgr.captureObjectWasClickedIn3D(object.uniqueId);
    if (this._creating3dLine && this.pendingLine) {
      this.addNewPointToPendingLine();
      return;
    }
    if (
      this._creating3dLine ||
      this._creating3dPolygon ||
      this._creating2dPoint ||
      this._creating3dMultiPoint
    )
      return;

    if (
      object.customType === Annotation3dPoints.AnnotationLineVertex &&
      this._editing3dLine
    ) {
      this.startEditing3dLineVertex(object);
      return;
    }
    if (
      object.customType === Annotation3dPoints.AnnotationLineMidpointVertex &&
      this._editing3dLine
    ) {
      this.convertMidpointToVertex(object);
      return;
    }

    if (object.customType === Annotation3dLines.AnnotationLine) {
      this.annotation3d?.highlight3dLineById(object.uniqueId);
      if (!this._editing3dLine) {
        this.startEditing3dLine(object.uniqueId);
      }
      return;
    }

    if (object.customType === Annotation3dPolygons.AnnotationPolygon) {
      this.annotation3d?.highlight3dPolygonById(object.uniqueId);
      return;
    }
  }

  object3dDoubleClick(object: ICustomLine | ICustomMesh) {
    if (!object) return;
    if (object.customType === Annotation3dPoints.AnnotationLineVertex) {
      this.deleteLineVertex(object);
    }
  }

  mouseUp() {
    if (this._editing3dLine) {
      this.finishEditing3dLineVertex();
    }
  }

  mouseMove = (event: MouseEvent) => {
    const mouse = new Vector2();
    const rect = this.targetEl.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    this.updateCursor(mouse);
  };

  sceneWasClicked() {
    this.annotation3d?.unHighlightAll();
    this.annotation2d?.unHighlightAll();

    if (!this.mousePosition) return;
    if (this._creating2dPoint) {
      this._creating2dPoint = false;
      const pnt = this.annotation2d?.get2dPointById(this.new2dPointId || '');
      if (pnt) {
        pnt.updatePosition(this.mousePosition);
        pnt.updateType(Annotation2dPoints.AnnotationPoint2d);
      }
      this.finishCreating2dPoint(false);
      return;
    }

    if (this._creating2dMultiPoint) {
      const pnt = new Vector3().copy(this.mousePosition);
      if (pnt) {
        this.pending2dMultiPoint?.push(pnt);
        this.annotation2d?.updatePending2dMultiPointPositions(
          this.new2dMultiPointId || '',
          [...(this.pending2dMultiPoint || [])]
        );
      }
      return;
    }

    if (this._creating3dLine && this.pendingLine) {
      this.addNewPointToPendingLine();
      return;
    }

    if (this._editing3dLine) {
      this.finishEditing3dLine();
    }
    if (this.isInEditMode && this.point2dBeingEdited_uniqueId) {
      this.cancelEditing2dPoint();
    }

    this.multiPoint2dBeingEdited_uniqueId = '';
    this.point2dBeingEdited_uniqueId = '';
    this.line3dBeingEdited_uniqueId = '';
    this.noMoreEditing();
  }

  setMousePosition(pos: Vector3 | undefined) {
    if (!pos) {
      this.mousePosition = undefined;
    } else {
      this.mousePosition = pos;
    }
  }

  seedCaptureObjects(captureObjects: APIClient.CaptureObject[]) {
    if (!captureObjects?.length) {
      return;
    }

    captureObjects.forEach((co) => {
      const obj: SpecialCaptureObject = co as SpecialCaptureObject;
      const id = obj.id?.toString() || '';
      if (!id) return;
      const color = this.getColor(obj) ?? ColorsThreeD.Cyan;
      const description = obj.description || '';
      const name = obj.name || '';

      const partialCoAttrs = {
        name,
        description,
        id,
        color,
      };

      if (obj?.geom2D?.type === GeomType.Point) {
        const position = obj?.geom2D?.coordinates as Point;

        this.annotation2d?.add2dPoint(
          EventBusNames.Annotation2dPointLocation,
          id,
          new Vector3(position[0], position[1], position[2]),
          {
            fill: color,
            name,
            visible: true,
            visibleLabel: true,
            type: Annotation2dPoints.AnnotationPoint2d,
            clickable: true,
            editable: false,
          }
        );
      } else if (obj?.geom2D?.type === GeomType.LineString) {
        const positions = obj?.geom2D?.coordinates as Line;
        const coAttrs = {
          ...partialCoAttrs,
          type: Annotation3dLines.AnnotationLine,
        };
        this.annotation3d?.add3dLine(
          coAttrs,
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dLines.AnnotationLine
        );
      } else if (obj?.geom2D?.type === GeomType.Polygon) {
        const positions = obj?.geom2D?.coordinates as Polygon;
        const coAttrs = {
          ...partialCoAttrs,
          type: Annotation3dPolygons.AnnotationPolygon,
        };
        this.annotation3d?.add3dPolygon(
          coAttrs,
          positions[0].map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dPolygons.AnnotationPolygon
        );
      } else if (obj?.geom2D?.type === GeomType.MultiPoint) {
        const positions = obj?.geom2D?.coordinates as Point[];
        const coAttrs = {
          ...partialCoAttrs,
          type: Annotation2dPoints.AnnotationMultiPoint2d,
        };
        this.annotation2d?.add2dMultiPoint(
          id,
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          coAttrs
        );
      }
    });
  }

  render() {
    this.setMousePosition(this.mousePositionRef.current);
    if (this._creating2dPoint) {
      this.updatePendingPnt();
    }
    if (this._creating2dMultiPoint) {
      this.updatePending2dMultiPoint();
    }
    if (this._creating3dLine) {
      this.updatePendingLine();
    }
    if (this._creating3dPolygon) {
      this.updatePendingPolygon();
    }
    if (this.isInEditMode && this._editing3dLine) {
      this.updateExistingLine();
    }
    if (this.isInEditMode && this._editing2dPoint) {
      this.updateExisting2dPoint();
    }
    if (this.isInEditMode && this._editing2dMultiPoint) {
      this.update2dMultiPointPosition();
    }

    this.annotation2d?.render();
    this.annotation3d?.render();
  }

  startAnimationLoop = () => {
    this.animationLoopRequestId = requestAnimationFrame(
      this.startAnimationLoop
    );
    this.render();
  };

  destroy() {
    super.destroy();

    cancelAnimationFrame(this.animationLoopRequestId || 0);
    if (this.mouseReticle && this.scene) {
      this.scene.remove(this.mouseReticle);
    }
    this.targetEl.removeEventListener('mousemove', this.mouseMove);
  }

  private getColor(obj: SpecialCaptureObject) {
    return obj?.captureObjectCustomAttributes?.find(
      (attr) => attr.attributeName === 'color'
    )?.attributeValue as HexColor;
  }

  private updatePendingPnt() {
    if (this.mousePosition) {
      const pnt = this.annotation2d?.get2dPointById(this.new2dPointId || '');
      if (pnt) {
        pnt.updatePosition(this.mousePosition);
      }
    }
  }

  private updatePending2dMultiPoint() {
    if (this.mousePosition && this._creating2dMultiPoint) {
      const multiPoint = this.annotation2d?.get2dMultiPointById(
        this.new2dMultiPointId || ''
      );
      if (multiPoint) {
        this.annotation2d?.updatePending2dMultiPointPositions(
          this.new2dMultiPointId || '',
          [
            ...(this.pending2dMultiPoint?.slice(0, -1) || []),
            this.mousePosition,
          ]
        );
      }
    }
  }

  private updatePendingLine() {
    if (this.mousePosition && this.pendingLine?.length) {
      this.annotation3d?.update3dLinePosition(this.new3dLineId || '', [
        ...this.pendingLine,
        this.mousePosition,
      ]);
    }
  }

  private updatePendingPolygon() {
    if (this.mousePosition && this.pendingPolygon?.length) {
      this.annotation3d?.update3dPolygonPosition(this.new3dPolygonId || '', [
        ...this.pendingPolygon,
        this.mousePosition,
        this.pendingPolygon[0],
      ]);
    }
  }

  // private updatePointFace() {
  //   if (!this.camera || !this.mousePosition) {
  //     return;
  //   }
  //   this.annotation3d?.updateAll3dPointLookAt();
  // }

  private getIntersected3dObjects(pos: Vector2) {
    if (!this.scene || !this.camera) return;
    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (!group) return;
    const raycaster = new Raycaster();

    raycaster.setFromCamera(pos, this.camera);
    const intersects = raycaster.intersectObjects(group.children, true);
    return intersects;
  }

  private updateCursor(pos: Vector2) {
    if (!this.camera || !this.mouseReticle || !this.mousePosition) {
      if (this.mouseReticle) {
        this.mouseReticle.visible = false;
      }
      document.body.style.cursor = 'grab';
      return;
    }

    const intersects = this.getIntersected3dObjects(pos);
    if (intersects?.length) {
      document.body.style.cursor = 'pointer';
      this.mouseReticle.visible = false;
      return;
    }

    if (
      this._creating2dPoint ||
      this._creating3dLine ||
      this._creating3dPolygon ||
      this._creating3dMultiPoint ||
      // this._editing3dLine ||
      // this._editing3dLineVertex ||
      this._editing2dMultiPoint ||
      this._editing2dPoint ||
      // this.isInEditMode ||
      this.point2dBeingEdited_uniqueId ||
      this.multiPoint2dBeingEdited_uniqueId
    ) {
      this.mouseReticle.position.copy(this.mousePosition);
      this.mouseReticle.visible = true;
      document.body.style.cursor = 'none';
      return;
    }

    this.mouseReticle.visible = false;
    document.body.style.cursor = 'grab';
  }

  private addNewPointToPendingLine() {
    if (!this.mousePosition || !this.pendingLine || !this.new3dLineId) {
      return;
    }
    const pnt = new Vector3().copy(this.mousePosition);
    const line = [...this.pendingLine, pnt];
    if (pnt) {
      this.pendingLine = line;
      this.annotation3d?.update3dLinePosition(this.new3dLineId, line);
    }
  }

  private checkIfEditedObjectsGotDeleted() {
    /**
     * this can happen if the user deletes the object being edited;
     * the object does the delete and this controller doesnt know
     * so we need to check if the object is still there
     */
    if (this.point2dBeingEdited_uniqueId) {
      const point = this.annotation2d?.get2dPointById(
        this.point2dBeingEdited_uniqueId
      );
      if (!point) {
        this.noMoreEditing();
        this.cancelEditing2dPoint();
      }
    }
    if (this.multiPoint2dBeingEdited_uniqueId) {
      const multiPoint = this.annotation2d?.get2dMultiPointByChildId(
        this.multiPoint2dBeingEdited_uniqueId
      );
      if (!multiPoint) {
        this.noMoreEditing();
        this.cancelEditing2dMultiPoint();
      }
    }
    if (this.line3dBeingEdited_uniqueId) {
      const line = this.annotation3d?.get3dLineById(
        this.line3dBeingEdited_uniqueId
      );
      if (!line) {
        this.noMoreEditing();
      }
    }
  }
}

/**
 * edit desc of new point not working
 * click the label to select it
 * fix notes at the bottom
 * white sphere for reticle; hide the cursor
 * open hand at all times for panning
 * only show reticle when drawing
 * cursor right on marker div is opening the context menu
 * add and remove vertex from editable line
 * turn on and off all annotations, dont switch tab
 * default to annotation tab
 * add length to line
 * add pile of balls for multi point in sidebar
 * remove zoom to
 * change photo realistic to textured
 * link ticket to number 13
 */
