import { motion } from 'framer-motion';
import { useCapturesViewerContext } from 'libs/feature/src/captures-viewer';
import { useThreeDAnnotationsPluginQueries } from 'libs/feature/src/three-d-wrapper/plugins/annotations-plugin/three-d-annotations-plugin-queries';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Raycaster, Vector2 } from 'three';

import { APIClient } from '@agerpoint/api';
import { CloudButton } from '@agerpoint/cloud/components';
import { CaptureImagesGallery } from '@agerpoint/feature';
import { AnnotationsController } from '@agerpoint/three-d-viewer';
import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  EffectNames,
  IGs3dViewerController,
  IPotreeViewerController,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import {
  AnnotationGroupName,
  useAgerStore,
  useMixpanel,
  useViteToasts,
} from '@agerpoint/utilities';

import { useSharedToolbarQueries } from './anno-shared-toolbar-queries';
import './anno-shared-toolbar.scss';

export const SharedThreeDAnnotationToolbar = ({
  viewerController,
  croppingActive,
  isPotree = false,
}: {
  viewerController: IGs3dViewerController | IPotreeViewerController;
  croppingActive: boolean;
  isPotree?: boolean;
}) => {
  const {
    selectedCaptureJob,
    setSelectedCaptureJob,
    setAnnotationCaptureObjects,
    setAnnotationCaptureObjectsLookup,
  } = useCapturesViewerContext();

  const { captureJobPutMutation } = useSharedToolbarQueries();

  const [, setSearch] = useSearchParams();

  const toasts = useViteToasts();

  const {
    isMobile,
    launchDarkly: { hasFeatureFlag },
  } = useAgerStore();

  const hasPolygonPermission = useMemo(
    () => hasFeatureFlag(LdFlags.M2Polygon3DAnnotation),
    [hasFeatureFlag]
  );

  const { captureObjectsBySelectedCaptureJobQuery } =
    useThreeDAnnotationsPluginQueries();
  const [point2dEditInProgress, setPoint2dEditInProgress] = useState(false);
  const [point2dCreateInProgress, setPoint2dCreateInProgress] = useState(false);
  const [line3dCreateInProgress, setLine3dCreateInProgress] = useState(false);
  const [line3dEditInProgress, setLine3dEditInProgress] = useState(false);
  const [polygon3dInProgress, setPolygon3dInProgress] = useState(false);
  const [multiPoint2dInProgress, setMultiPoint2dInProgress] = useState(false);
  const [count2dCreateInProgress, setCount2dCreateInProgress] = useState(false);
  const [count2dEditInProgress, setCount2dEditInProgress] = useState(false);

  const annoCtrl = useRef<AnnotationsController | null>(null);

  const setupCaptureObjects = useCallback(
    (cObjects: APIClient.CaptureObject[]) => {
      const filteredObjects = cObjects.filter((c) => {
        if (c.captureJobId !== selectedCaptureJob?.id) return false;
        // check custom attributes for attributeName _customType
        const customType = c?.captureObjectCustomAttributes?.find(
          (attr) => attr.attributeName === '_customType'
        );
        if (!customType || !customType?.attributeValue) return false;

        const allowedTypes = [
          Annotation2dPoints.AnnotationPoint2d,
          Annotation3dPoints.AnnotationPoint,
          Annotation3dLines.AnnotationLine,
          Annotation2dPoints.AnnotationMultiPoint2d,
        ] as (Annotation3dPoints | Annotation3dLines | Annotation3dPolygons)[];

        if (hasPolygonPermission) {
          allowedTypes.push(Annotation3dPolygons.AnnotationPolygon);
        }

        return allowedTypes.includes(
          customType.attributeValue as
            | Annotation3dLines
            | Annotation3dPoints
            | Annotation3dPolygons
        );
      });
      annoCtrl?.current?.seedCaptureObjects(filteredObjects);
      setAnnotationCaptureObjects?.(filteredObjects);
      const lookup = filteredObjects.reduce((acc, obj) => {
        if (!obj.id) return acc;
        acc[obj.id] = obj;
        return acc;
      }, {} as Record<string, APIClient.CaptureObject>);
      setAnnotationCaptureObjectsLookup?.(lookup);
    },
    [selectedCaptureJob, hasPolygonPermission]
  );

  useEffect(() => {
    annoCtrl.current?.destroy();
  }, [selectedCaptureJob?.id]);

  // Use a ref to store the previous fetching state
  const wasFetching = useRef(false);

  useEffect(() => {
    return () => {
      setAnnotationCaptureObjects?.([]);
      setAnnotationCaptureObjectsLookup?.({});
    };
  }, []);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !viewerController?.info?.sceneLoaded
    )
      return;
    const captureObjects = captureObjectsBySelectedCaptureJobQuery.data;
    if (captureObjects) {
      setupCaptureObjects(captureObjects);
    }
  }, [
    viewerController?.info?.viewerReady,
    viewerController?.info?.sceneLoaded,
    captureObjectsBySelectedCaptureJobQuery.data,
  ]);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !viewerController?.info?.sceneLoaded
    )
      return;
    if (
      wasFetching.current &&
      !captureObjectsBySelectedCaptureJobQuery.isFetching &&
      captureObjectsBySelectedCaptureJobQuery.isSuccess
    ) {
      const captureObjects =
        (captureObjectsBySelectedCaptureJobQuery?.data as APIClient.CaptureObject[]) ??
        [];

      if (captureObjects.length > 0) {
        setupCaptureObjects(captureObjects);
      }
    }

    wasFetching.current = captureObjectsBySelectedCaptureJobQuery.isFetching;
  }, [
    captureObjectsBySelectedCaptureJobQuery.isFetching,
    captureObjectsBySelectedCaptureJobQuery.isSuccess,
    viewerController?.info?.viewerReady,
    viewerController?.info?.sceneLoaded,
  ]);

  useEffect(() => {
    const captureObjects =
      (captureObjectsBySelectedCaptureJobQuery?.data as APIClient.CaptureObject[]) ??
      [];

    if (captureObjects.length > 0) {
      setupCaptureObjects(captureObjects);
    }
  }, []);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }
    if (!viewerController?.threeViewer?.camera) return;
    if (!viewerController?.threeViewer?.scene) return;
    if (!viewerController?.threeViewer?.targetEl) return;
    if (!viewerController?.threeViewer?.controls) return;
    annoCtrl.current = new AnnotationsController(
      viewerController?.threeViewer?.camera,
      viewerController.threeViewer?.scene,
      viewerController.threeViewer?.controls,
      viewerController.threeViewer?.targetEl,
      viewerController.mousePosition,
      isPotree,
      isMobile
    );
    return () => {
      annoCtrl.current?.destroy();
    };
  }, [viewerController?.info?.viewerReady, isMobile]);

  useEffect(() => {
    if (
      !selectedCaptureJob?.id ||
      !selectedCaptureJob?.cameraSettings ||
      !viewerController?.info.viewerReady
    )
      return;

    viewerController?.setCameraSettings(selectedCaptureJob.cameraSettings);
  }, [selectedCaptureJob?.id, viewerController.info?.viewerReady]);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !viewerController?.threeViewer?.targetEl ||
      !viewerController?.threeViewer?.camera ||
      isMobile
    ) {
      return;
    }
    const raycaster = new Raycaster();
    raycaster.params.Sprite = { threshold: 0.1 }; // Adjust threshold as needed
    raycaster.camera = viewerController?.threeViewer?.camera;

    const mouse = new Vector2();
    let clickTimeout: ReturnType<typeof setTimeout> | null = null;
    const singleClickDelay = 250; // Time in milliseconds to differentiate single vs double click

    const handleMouseEvent = (
      event: MouseEvent,
      isDoubleClick: boolean = false
    ) => {
      if (
        !viewerController.threeViewer?.targetEl ||
        !viewerController?.threeViewer?.camera
      )
        return;

      event.preventDefault();
      event.stopPropagation();

      // Correct for elements offset
      const rect =
        viewerController.threeViewer?.targetEl.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, viewerController?.threeViewer?.camera);
      const group =
        viewerController?.threeViewer?.scene?.getObjectByName(
          AnnotationGroupName
        );
      if (!group) {
        if (!isDoubleClick) {
          annoCtrl.current?.sceneWasClicked();
        }
        return;
      }

      const intersects = raycaster.intersectObjects(group?.children, true);
      let selectedObject;
      if (intersects.length > 0) {
        selectedObject = intersects[0].object as any;
      }

      if (!isDoubleClick && !selectedObject) {
        annoCtrl.current?.sceneWasClicked();
        return;
      }

      if (!selectedObject) return;

      if (isDoubleClick) {
        annoCtrl.current?.object3dDoubleClick(selectedObject);
      } else {
        annoCtrl.current?.object3dMouseDown(selectedObject);
      }

      if (isPotree && viewerController.threeViewer?.viewer?.pauseControls) {
        viewerController.threeViewer?.viewer.pauseControls();
      } else if (viewerController?.threeViewer?.controls) {
        viewerController.threeViewer.controls.enabled = false;
      }
    };

    const onMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (clickTimeout) {
        clearTimeout(clickTimeout);
        clickTimeout = null;
        handleMouseEvent(event, true); // Double click
      } else {
        clickTimeout = setTimeout(() => {
          handleMouseEvent(event); // Single click
          clickTimeout = null;
        }, singleClickDelay);
      }
    };

    const mouseUp = (event: MouseEvent) => {
      annoCtrl.current?.mouseUp();
      if (isPotree && viewerController.threeViewer?.viewer?.unPauseControls) {
        viewerController.threeViewer?.viewer.unPauseControls();
      } else if (viewerController?.threeViewer?.controls) {
        viewerController.threeViewer.controls.enabled = true;
      }
    };

    const doubleClick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    // Attach event listeners
    viewerController.threeViewer?.targetEl.addEventListener(
      'mousedown',
      onMouseDown
    );

    viewerController.threeViewer?.targetEl.addEventListener(
      'mouseup',
      mouseUp,
      false
    );

    viewerController.threeViewer?.targetEl.addEventListener(
      'dblclick',
      doubleClick
    );

    return () => {
      if (!viewerController.threeViewer?.targetEl) return;
      viewerController.threeViewer?.targetEl.removeEventListener(
        'mousedown',
        onMouseDown
      );
      viewerController.threeViewer?.targetEl.removeEventListener(
        'mouseup',
        mouseUp
      );
      viewerController.threeViewer?.targetEl.removeEventListener(
        'dblclick',
        doubleClick
      );
    };
  }, [
    viewerController.info?.viewerReady,
    viewerController.threeViewer?.targetEl,
  ]);

  useEffect(() => {
    if (annoCtrl?.current?.creating2dPoint === undefined) return;
    setPoint2dCreateInProgress(annoCtrl?.current?.creating2dPoint);
  }, [annoCtrl?.current?.creating2dPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.creating3dLine === undefined) return;
    setLine3dCreateInProgress(annoCtrl?.current?.creating3dLine);
  }, [annoCtrl?.current?.creating3dLine]);

  useEffect(() => {
    if (annoCtrl?.current?.creating3dMultiPoint === undefined) return;
    setCount2dCreateInProgress(annoCtrl?.current?.creating3dMultiPoint);
  }, [annoCtrl?.current?.creating3dMultiPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.editing2dPoint === undefined) return;
    setPoint2dEditInProgress(annoCtrl?.current?.editing2dPoint);
  }, [annoCtrl?.current?.editing2dPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.editing3dLine === undefined) return;
    setLine3dEditInProgress(annoCtrl?.current?.editing3dLine);
  }, [annoCtrl?.current?.editing3dLine]);

  useEffect(() => {
    if (annoCtrl?.current?.editing2dMultiPoint === undefined) return;
    setCount2dEditInProgress(annoCtrl?.current?.editing2dMultiPoint);
  }, [annoCtrl?.current?.editing2dMultiPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.editing2dPoint) {
      setPoint2dEditInProgress(true);
    } else {
      setPoint2dEditInProgress(false);
    }
  }, [annoCtrl?.current?.editing2dPoint]);

  const cancelDrawing = useCallback(() => {
    if (multiPoint2dInProgress) {
      setMultiPoint2dInProgress(false);
      annoCtrl?.current?.cancelEditing2dMultiPoint();
    }
    if (point2dCreateInProgress) {
      setPoint2dCreateInProgress(false);
      annoCtrl?.current?.cancelCreating2dPoint();
    }
    if (line3dCreateInProgress) {
      setLine3dCreateInProgress(false);
      annoCtrl?.current?.cancelCreating3dLine();
    }
    // if (polygon3dInProgress) {
    //   setPolygon3dInProgress(false);
    //   annoCtrl?.current?.cancelCreating3dPolygon();
    // }
    if (count2dCreateInProgress) {
      setCount2dCreateInProgress(false);
      annoCtrl?.current?.cancelEditing2dMultiPoint();
    }
  }, [
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
    point2dCreateInProgress,
  ]);

  const canDrawRequestedType = useCallback(() => {
    if (!annoCtrl?.current) return false;
    const pntInProgress = annoCtrl?.current?.creating2dPoint ?? false;
    if (pntInProgress) return false;
    if (
      line3dCreateInProgress ||
      polygon3dInProgress ||
      count2dCreateInProgress
    )
      return false;
    return true;
  }, [
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
    annoCtrl,
  ]);

  const canCancel = useMemo(() => {
    return (
      point2dCreateInProgress ||
      line3dCreateInProgress ||
      polygon3dInProgress ||
      count2dCreateInProgress
    );
  }, [
    point2dCreateInProgress,
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
  ]);

  const canFinish = useMemo(() => {
    return (
      line3dCreateInProgress || polygon3dInProgress || count2dCreateInProgress
    );
  }, [line3dCreateInProgress, polygon3dInProgress, count2dCreateInProgress]);

  const drawMessage = useMemo(() => {
    if (point2dCreateInProgress) {
      return 'Click to place a point marker';
    }

    if (point2dEditInProgress) {
      return 'Drag to reposition';
    }

    if (count2dCreateInProgress) {
      return 'Click an object to start counting';
    }

    if (count2dEditInProgress) {
      return 'Drag to reposition';
    }

    if (line3dCreateInProgress) {
      return 'Click the first point to start the measurement';
    }

    if (line3dEditInProgress) {
      return 'Drag or add vertices to edit, double click to remove a vertex';
    }

    if (polygon3dInProgress) {
      return 'Click to start setting the plane';
    }

    return undefined;
  }, [
    point2dCreateInProgress,
    point2dEditInProgress,
    count2dCreateInProgress,
    count2dEditInProgress,
    line3dCreateInProgress,
    line3dEditInProgress,
    polygon3dInProgress,
  ]);

  const [gallery, setGallery] = useState({ show: false, expanded: false });
  const { sendEvent } = useMixpanel();

  return (
    <>
      {!isMobile && !croppingActive && (
        <>
          <motion.div
            className={`${
              isMobile ? 'fixed' : 'absolute'
            } z-30 bottom-14 left-1/2 flex flex-row bg-white shadow-lg rounded-lg p-1 gap-1`}
            initial={{ y: 0, x: '-50%' }}
            animate={{ y: gallery.show ? -110 : 0, x: '-50%' }}
          >
            {!canCancel && !canFinish && (
              <>
                <CloudButton.Icon
                  disabled={
                    !viewerController?.info?.viewerReady ||
                    !viewerController?.info?.sceneLoaded ||
                    !canDrawRequestedType()
                  }
                  id="point-3d-marker"
                  leadingIcon="location-dot"
                  tooltip="Add Point"
                  tooltipPosition="top"
                  onClick={(e) => {
                    sendEvent(MixpanelNames.AnnotationCreationStartedCloud, {
                      type: Annotation2dPoints.AnnotationPoint2d,
                    });
                    e.stopPropagation();
                    const canDraw = canDrawRequestedType();

                    if (!canDraw) {
                      return;
                    }

                    if (viewerController?.info?.viewerReady) {
                      cancelDrawing();
                      setPoint2dCreateInProgress(true);
                      annoCtrl?.current?.startCreating2dPoint();
                      setSearch(
                        (prev) => {
                          prev.set('details', 'annotations');
                          return prev;
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  toggled={point2dCreateInProgress}
                />
                <CloudButton.Icon
                  disabled={
                    !viewerController?.info?.viewerReady ||
                    !viewerController?.info?.sceneLoaded ||
                    !canDrawRequestedType()
                  }
                  id="multi-point-3d-marker"
                  leadingIcon="ball-pile"
                  tooltip="Add Multi Point"
                  tooltipPosition="top"
                  onClick={(e) => {
                    sendEvent(MixpanelNames.AnnotationCreationStartedCloud, {
                      type: Annotation2dPoints.AnnotationMultiPoint2d,
                    });
                    e.stopPropagation();
                    const canDraw = canDrawRequestedType();

                    if (!canDraw) {
                      // annoCtrl?.current?.finishCreating3dPoint(true);
                      return;
                    }

                    if (viewerController?.info?.viewerReady) {
                      cancelDrawing();
                      setCount2dCreateInProgress(true);
                      annoCtrl?.current?.startCreating2dMultiPoint();
                      setSearch(
                        (prev) => {
                          prev.set('details', 'annotations');
                          return prev;
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  toggled={count2dCreateInProgress}
                />
                <CloudButton.Icon
                  disabled={
                    !viewerController?.info?.viewerReady ||
                    !viewerController?.info?.sceneLoaded ||
                    !canDrawRequestedType()
                  }
                  id="line-3d-marker"
                  leadingIcon="ruler"
                  tooltip="Add Line"
                  tooltipPosition="top"
                  onClick={(e) => {
                    sendEvent(MixpanelNames.AnnotationCreationStartedCloud, {
                      type: Annotation3dLines.AnnotationLine,
                    });
                    e.stopPropagation();
                    const canDraw = canDrawRequestedType();

                    if (!canDraw) {
                      // annoCtrl?.current?.finishCreating3dPoint(true);
                      return;
                    }

                    if (
                      viewerController?.info?.viewerReady &&
                      annoCtrl?.current
                    ) {
                      cancelDrawing();
                      setLine3dCreateInProgress(true);
                      annoCtrl?.current.startCreating3dLine();
                      setSearch(
                        (prev) => {
                          prev.set('details', 'annotations');
                          return prev;
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  toggled={line3dCreateInProgress}
                />
              </>
            )}

            {canCancel && (
              <CloudButton.Icon
                id="cancel-drawing"
                onClick={(e) => {
                  if (point2dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationCanceledCloud, {
                      type: Annotation2dPoints.AnnotationPoint2d,
                    });
                  }
                  if (count2dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationCanceledCloud, {
                      type: Annotation2dPoints.AnnotationMultiPoint2d,
                    });
                  }
                  if (line3dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationCanceledCloud, {
                      type: Annotation3dLines.AnnotationLine,
                    });
                  }
                  e.stopPropagation();
                  cancelDrawing();
                }}
                leadingIcon={'close'}
                label="Cancel"
                leadingIconColor="text-red"
              />
            )}
            {canFinish && (
              <CloudButton.Icon
                id="finish-drawing"
                onClick={(e) => {
                  e.stopPropagation();
                  if (line3dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationDoneCloud, {
                      type: Annotation3dLines.AnnotationLine,
                    });
                    setLine3dCreateInProgress(false);
                    annoCtrl?.current?.finishCreating3dLine();
                  }
                  if (count2dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationDoneCloud, {
                      type: Annotation2dPoints.AnnotationMultiPoint2d,
                    });
                    setCount2dCreateInProgress(false);
                    annoCtrl?.current?.finishCreating2dMultiPoint();
                  }
                }}
                label="Done"
                leadingIcon={'check'}
                leadingIconColor="text-green"
              />
            )}
          </motion.div>
          {drawMessage && (
            <motion.div
              className="pointer-events-none absolute left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg z-30 text-center"
              initial={{ y: 0, x: '-50%', bottom: '120px' }}
              animate={{
                y: gallery.show ? -110 : 0,
                x: '-50%',
                bottom: '120px',
              }}
            >
              {drawMessage}
            </motion.div>
          )}
        </>
      )}

      <CaptureImagesGallery
        captureJob={selectedCaptureJob ?? undefined}
        show={gallery.show}
        setShow={(show) => {
          setGallery({ ...gallery, show });
        }}
        expanded={gallery.expanded}
        setExpanded={(expanded) => {
          setGallery({ ...gallery, expanded });
        }}
      />

      <div className="absolute z-30 top-4 right-4 flex flex-col bg-white shadow-lg rounded-lg p-1 gap-1">
        <CloudButton.Icon
          id="save-camera-settings"
          leadingIcon="save"
          tooltip="Save camera settings"
          tooltipPosition="left"
          disabled={!viewerController?.info?.viewerReady}
          loading={captureJobPutMutation.isPending}
          onClick={() => {
            if (captureJobPutMutation.isPending) return;

            const cameraSettings = viewerController?.getCameraSettings();

            if (!cameraSettings) return;
            const cameraStateJSON = JSON.stringify(cameraSettings);

            const updatedCaptureJob = {
              ...selectedCaptureJob,
              cameraSettings: cameraStateJSON,
            };

            if (!selectedCaptureJob?.captureId || !selectedCaptureJob?.id) {
              return;
            }

            captureJobPutMutation.mutate(
              {
                captureId: selectedCaptureJob?.captureId?.toString(),
                jobId: selectedCaptureJob?.id,
                data: updatedCaptureJob,
              },
              {
                onSuccess: () => {
                  toasts.add({
                    type: 'success',
                    title: 'Camera settings saved!',
                  });
                  setSelectedCaptureJob?.(updatedCaptureJob);
                },
                onError: () => {
                  toasts.add({
                    type: 'error',
                    title: 'Failed to save camera settings!',
                  });
                },
              }
            );
          }}
        />
        <CloudButton.Icon
          id="reset-camera-settings"
          leadingIcon="arrows-to-circle"
          tooltip="Reset camera settings"
          tooltipPosition="left"
          disabled={!selectedCaptureJob?.cameraSettings}
          onClick={() => {
            const cameraSettings = selectedCaptureJob?.cameraSettings;
            if (!cameraSettings) return;
            viewerController?.setCameraSettings(cameraSettings);
          }}
        />
      </div>
    </>
  );
};
