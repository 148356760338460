import { useContext, useEffect, useMemo, useState } from 'react';

import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  isNonLidarJob,
  useGlobalStore,
} from '@agerpoint/utilities';

import { CapturesViewerContext } from '../captures-viewer-context';
import { useCaptureViewerFloatingQueries } from './capture-viewer-floating-queries';
import { ModelRatingComponent } from './model-rating-component/model-rating-component';

export const CaptureViewerFloatingComponents = () => {
  const {
    sidebar: { isOpen: isSidebarOpen },
    bottomBar: { isOpen: isBottomBarOpen },
    permissions,
  } = useGlobalStore();

  const [showRatingComponent, setShowRatingComponent] = useState(false);
  const [hasModelRatingWidgetPermission, setHasModelRatingWidgetPermission] =
    useState(false);
  const { selectedCaptureJob } = useContext(CapturesViewerContext);
  const { captureJobByIdQuery, captureQuery } = useCaptureViewerFloatingQueries(
    {
      captureJobId: selectedCaptureJob?.id,
    }
  );

  useEffect(() => {
    if (hasPermission(LdFlags.ModelRatingWidget, permissions)) {
      setHasModelRatingWidgetPermission(true);
    } else {
      setHasModelRatingWidgetPermission(false);
    }
  }, [permissions]);

  useEffect(() => {
    if (
      captureJobByIdQuery.data &&
      !captureJobByIdQuery.data.captureJobRating
    ) {
      setShowRatingComponent(true);
    } else {
      setShowRatingComponent(false);
    }
  }, [captureJobByIdQuery.data]);

  const modelNameNote = useMemo(() => {
    if (!captureQuery.data) {
      return null;
    }

    if (isNonLidarJob(captureQuery.data, selectedCaptureJob ?? undefined)) {
      return null;
    }
    return selectedCaptureJob?.name;
  }, [selectedCaptureJob]);

  return (
    <div
      id="capture-viewer-floating-components-container"
      className={`${isSidebarOpen ? 'pl-sidebar' : ''} ${
        isBottomBarOpen ? 'pb-bottom-bar' : ''
      } w-full h-full absolute top-0 left-0 z-20 pointer-events-none transition-all duration-300`}
    >
      <div className="p-2 relative w-full h-full">
        <div
          id="rate-component"
          className="p-2 absolute bottom-0 left-0 pointer-events-auto"
        >
          {hasModelRatingWidgetPermission && showRatingComponent && (
            <ModelRatingComponent />
          )}
        </div>
        <div
          id="model-name-note"
          className={`absolute bottom-0 right-0 text-xs text-gray-600 px-2`}
        >
          {modelNameNote}
        </div>
      </div>
    </div>
  );
};
