import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';

import { APIModels } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import { AnalyticRequestStatus } from '@agerpoint/types';
import { promptDownload } from '@agerpoint/utilities';

import { useCapturesViewerQueries } from '../../../../captures-viewer-queries';
import { CVS3AnalyticsRequestListFilter } from '../../../shared/analytics-requests-list/cvs3-analytics-requests-list';

interface ExportAnalyticsOutputsButtonProps {
  filter: CVS3AnalyticsRequestListFilter;
}

export const ExportAnalyticsOutputsButton = ({
  filter,
}: ExportAnalyticsOutputsButtonProps) => {
  const { analyticRequestsWithCaptureObjectsAndJobsCompleteOnlyQuery } =
    useCapturesViewerQueries({});

  const dataLoading = useMemo(() => {
    return analyticRequestsWithCaptureObjectsAndJobsCompleteOnlyQuery.some(
      (q) => q.isLoading
    );
  }, [analyticRequestsWithCaptureObjectsAndJobsCompleteOnlyQuery]);

  const hasFilter = useMemo(() => {
    return (
      filter !== undefined &&
      (filter.analyticName !== '' ||
        filter.objectName !== '' ||
        filter.attributeNames !== '' ||
        filter.status !== undefined)
    );
  }, [filter]);

  const csvCaptureObjectFieldHeadingMap: {
    [K in keyof APIModels.CaptureObject]: string;
  } = useMemo(() => {
    return {
      id: 'ID',
      createDatetime: 'Created',
      updateDatetime: 'Updated',
      updatedById: 'Updated By',
      name: 'Name',
      description: 'Description',
      captureId: 'Capture ID',
      number: 'Number',
      analyticName: 'Analytic',
      createdById: 'Created By',
      captureObjectTypeId: 'Type ID',
      captureObjectType: 'Type',
      x: 'X',
      y: 'Y',
      z: 'Z',
      radius: 'Radius',
      geom: 'Geometry',
      geom2D: 'Geometry 2D',
      centroidX: 'Centroid X',
      centroidY: 'Centroid Y',
      centroidZ: 'Centroid Z',
      diameter: 'Diameter',
      height: 'Height',
      volume: 'Volume',
      confidence: 'Confidence',
      captureExtractionJobId: 'Extraction Job ID',
      validated: 'Validated',
    };
  }, []);

  const dataToExport = useMemo(() => {
    if (dataLoading) {
      return [];
    }

    let data = cloneDeep(
      analyticRequestsWithCaptureObjectsAndJobsCompleteOnlyQuery.map(
        (q) => q.data
      )
    ).filter(
      (d) => d?.analyticRequest.status === AnalyticRequestStatus.COMPLETE
    );

    if (!hasFilter) {
      return data;
    }

    if (filter.status !== undefined) {
      data = data.filter((d) => {
        return d?.analyticRequest?.status === filter.status;
      });
    }

    if (filter.analyticName !== '') {
      data = data.filter((d) => {
        const analyticName =
          d?.analyticRequest?.customerAnalytic?.analytic?.analyticName;
        return analyticName
          ?.toLowerCase()
          .includes(filter.analyticName.toLowerCase().trim());
      });
    }

    if (filter.objectName !== '') {
      data = data.map((d) => {
        if (d?.captureObjects === undefined) {
          return d;
        }

        d.captureObjects = d?.captureObjects?.filter((o) =>
          o.name?.toLowerCase().includes(filter.objectName.toLowerCase().trim())
        );

        return d;
      });

      data = data.filter((d) => (d?.captureObjects.length ?? 0) > 0);
    }

    if (filter.attributeNames !== '') {
      const attributeNames = filter.attributeNames
        .split(',')
        .map((a) => a.trim())
        .filter((a) => a !== '');

      data = data.map((d) => {
        if (attributeNames.length === 0) {
          return d;
        }
        if (d?.captureJobs === undefined) {
          return d;
        }

        d.captureObjects = d.captureObjects?.map((o) => {
          o.captureObjectCustomAttributes =
            o.captureObjectCustomAttributes?.filter((a) => {
              const attrName = a.attributeDisplayName ?? a.attributeName;

              return attributeNames.some((attributeName) =>
                attrName
                  ?.toLowerCase()
                  .includes(attributeName.toLowerCase().trim())
              );
            });

          return o;
        });

        return d;
      });

      data = data.filter((d) =>
        d?.captureObjects?.some(
          (o) => (o.captureObjectCustomAttributes?.length ?? 0) > 0
        )
      );
    }

    return data;
  }, [filter, hasFilter, dataLoading]);

  const startDownload = useCallback(() => {
    const records = dataToExport
      ?.map((d) => {
        // append analytic name to all of the capture objects
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const captureObjects = d?.captureObjects?.map((o: any) => {
          o.analyticName =
            d?.analyticRequest?.customerAnalytic?.analytic?.analyticName;
          return o;
        });
        return { ...d, captureObjects };
      })
      ?.flatMap((t) => t?.captureObjects ?? []);

    promptDownload({
      records,
      recordFieldNames: csvCaptureObjectFieldHeadingMap,
      fileNameBase: 'Capture Objects',
      explodeFieldNames: ['captureObjectCustomAttributes'],
    });
  }, [dataToExport, csvCaptureObjectFieldHeadingMap]);

  return (
    <Button.Small
      id="export-analytics-outputs-button"
      onClick={() => {
        startDownload();
      }}
      disabled={
        dataToExport.length === 0 ||
        dataToExport?.flatMap((t) => t?.captureObjects ?? []).length === 0
      }
      label={hasFilter ? 'Export Filtered' : 'Export All'}
      icon={faFileExport}
    />
  );
};
