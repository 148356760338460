import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { faTh } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useEffect, useState } from 'react';

import { APIClient } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';

import { CapturesViewerContext } from '../../captures-viewer-context';
import { useModelRatingQueries } from './model-rating.queries';

const enum RatingType {
  ThumbsUp = ':thumbsup:',
  ThumbsDown = ':thumbsdown:',
  None = 'none',
}

export const ModelRatingComponent = () => {
  const { selectedCaptureJob } = useContext(CapturesViewerContext);

  const {
    captureJobRatingPutMutation,
    captureJobRatingPostMutation,
    invalidateCaptureJobByIdQuery,
  } = useModelRatingQueries();

  const [ratingType, setRatingType] = useState<RatingType>();
  const [ratingDescription, setRatingDescription] = useState('');

  const [showMore, setShowMore] = useState(false);
  const [thumbUpIcon, setThumbUpIcon] = useState(faThumbsUp);
  const [thumbDownIcon, setThumbDownIcon] = useState(faThumbsDown);
  const [submitted, setSubmitted] = useState(false);
  const [dbResult, setDbResult] = useState<APIClient.CaptureJobRating>();
  const [loading, setLoading] = useState(false);

  const onThumbUpEnter = () => {
    setThumbUpIcon(faThumbsUpSolid);
  };
  const onThumbUpLeave = () => {
    if (ratingType === RatingType.ThumbsUp) return;
    setThumbUpIcon(faThumbsUp);
  };

  const onThumbDownEnter = () => {
    setThumbDownIcon(faThumbsDownSolid);
  };
  const onThumbDownLeave = () => {
    if (ratingType === RatingType.ThumbsDown) return;
    setThumbDownIcon(faThumbsDown);
  };

  const doPutOrPost = useCallback(
    async (rating: RatingType, feedback: string) => {
      let method;
      if (dbResult) {
        method = captureJobRatingPutMutation;
      } else {
        method = captureJobRatingPostMutation;
      }

      if (loading || !selectedCaptureJob) {
        return;
      }
      setLoading(true);
      try {
        const data = {
          rating: rating,
          feedback: feedback,
          scale: rating === RatingType.ThumbsUp ? 5 : 1,
          captureJobId: selectedCaptureJob?.id,
          createdById: '',
        };
        await method.mutateAsync({
          data,
        });
        setDbResult(data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      // invalidate query for capture job
      const time = rating === RatingType.ThumbsUp ? 1000 : 3000;
      setTimeout(() => {
        invalidateCaptureJobByIdQuery(selectedCaptureJob?.id as number);
      }, time);
    },
    [
      selectedCaptureJob,
      loading,
      captureJobRatingPutMutation,
      captureJobRatingPostMutation,
      setLoading,
      setDbResult,
      dbResult,
    ]
  );

  const thumbsUpClick = useCallback(async () => {
    setRatingType(RatingType.ThumbsUp);
    setShowMore(false);
    setRatingDescription('');
    setThumbDownIcon(faThumbsDown);
    setThumbUpIcon(faThumbsUpSolid);
    doPutOrPost(RatingType.ThumbsUp, ratingDescription);
  }, [
    setRatingDescription,
    setRatingType,
    setThumbDownIcon,
    setThumbUpIcon,
    setShowMore,
    doPutOrPost,
    ratingDescription,
  ]);

  const thumbsDownClick = useCallback(async () => {
    setRatingType(RatingType.ThumbsDown);
    setShowMore(true);
    setThumbUpIcon(faThumbsUp);
    setThumbDownIcon(faThumbsDownSolid);
  }, [setShowMore, setRatingType, setThumbDownIcon, setThumbUpIcon]);

  const thumbDownSubmit = useCallback(async () => {
    const doAsync = async () => {
      await doPutOrPost(RatingType.ThumbsDown, ratingDescription);
      setRatingType(RatingType.None);
      setRatingDescription('');
      setShowMore(false);
      setSubmitted(true);
      setLoading(false);
    };
    doAsync();
  }, [
    doPutOrPost,
    setRatingType,
    setRatingDescription,
    setShowMore,
    setSubmitted,
    setLoading,
    ratingDescription,
  ]);

  return submitted ? (
    <div
      className={`flex flex-row bg-white px-2 py-1 rounded justify-between  max-w-64`}
    >
      <div className="flex flex-row w-full justify-center py-1">
        <div
          className={`flex-col rounded cursor-default text-center pr-1 text-md `}
        >
          Thanks for your feedback!
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`flex ${
        showMore ? 'flex-col' : 'flex-col'
      } bg-white px-2 py-1 rounded justify-between  max-w-64`}
    >
      <div className="flex flex-row w-full justify-center py-1">
        <div
          className={`flex-col rounded cursor-default text-center pr-1 text-md `}
        >
          How&apos;s the model quality?
        </div>
        <div
          className={`px-1 rounded cursor-pointer flex items-center ${
            thumbUpIcon === faThumbsUpSolid ||
            ratingType === RatingType.ThumbsUp
              ? 'bg-green-100'
              : ''
          }`}
          onClick={thumbsUpClick}
        >
          <FontAwesomeIcon
            icon={thumbUpIcon}
            className={`text-lg  ${
              thumbUpIcon === faThumbsUpSolid ||
              ratingType === RatingType.ThumbsUp
                ? 'text-green-600'
                : ''
            }`}
            onMouseEnter={onThumbUpEnter}
            onMouseLeave={onThumbUpLeave}
          />
        </div>
        <div
          className={`px-1 rounded fa-flip-horizontal cursor-pointer flex items-center ${
            thumbDownIcon === faThumbsDownSolid ||
            ratingType === RatingType.ThumbsDown
              ? 'bg-red-100'
              : ''
          }`}
          onClick={thumbsDownClick}
        >
          <FontAwesomeIcon
            icon={thumbDownIcon}
            className={`text-lg ${
              thumbDownIcon === faThumbsDownSolid ||
              ratingType === RatingType.ThumbsDown
                ? 'text-red-600'
                : ''
            }
            `}
            onMouseEnter={onThumbDownEnter}
            onMouseLeave={onThumbDownLeave}
          />
        </div>
      </div>
      {/* {loading && ( */}
      {/* <div className="flex flex-row w-full justify-center text-xs h-4">
        {loading && <span>Saving</span>}
      </div> */}
      {/* )} */}

      <div
        className={`${
          showMore ? 'flex' : 'hidden'
        } flex-col rounded cursor-default text-sm pt-2`}
      >
        <Input.Text.Area
          id="capture-rating"
          value={ratingDescription}
          setValue={setRatingDescription}
          rows={3}
          resize={false}
          placeholder="Tell us more"
        />
      </div>
      <div className={`${showMore ? 'flex' : 'hidden'} w-full pt-2`}>
        <Button.Primary
          id="submit-rating"
          onClick={thumbDownSubmit}
          label="Submit"
          expand={true}
        />
      </div>
    </div>
  );
};
