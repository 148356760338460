import { faCircleNotch, faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  APIClient,
  Customer,
  Project,
  useAddCaptureToProject,
  useDeleteCaptureFromProject,
  useGetCustomer,
  useGetProjectsByCapture,
  usePutCaptureById,
} from '@agerpoint/api';
import { Input, MultiSelect, PrimaryButton } from '@agerpoint/component';
import { Options, UserClaims } from '@agerpoint/types';
import {
  APIUtils,
  Sort,
  hasClaims,
  useGlobalStore,
} from '@agerpoint/utilities';

export const CaptureDetailsSectionProjects = () => {
  const params = useParams();

  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);

  const { user } = useGlobalStore();

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
    },
  });

  const [isAdmin, setIsAdmin] = useState(false);

  const [projectsAssigned, setProjectsAssigned] = useState<Project[]>([]);
  const [showProjectEditor, setShowProjectEditor] = useState<boolean>(false);
  const [showOrganizationEditor, setShowOrganizationEditor] =
    useState<boolean>(false);
  const [projectOptions, setProjectOptions] = useState<Options[]>([]);
  const [selectedProjectsOptions, setSelectedProjectsOptions] = useState<
    Options[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [capturesCustomer, setCapturesCustomer] = useState<Customer>();
  const { data: customers, loading: loadingCustomers } = useGetCustomer({});

  const sortedCustomers = useMemo(
    () => Sort.organizations(customers ?? []),
    [customers]
  );

  const queryClient = useQueryClient();

  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
      select: (data) => APIUtils.Sort.projects(data),
    },
  });
  const allProjects = useMemo(() => projectsQuery.data, [projectsQuery.data]);
  const {
    data: projects,
    refetch,
    loading: projectsLoading,
  } = useGetProjectsByCapture({
    captureId: parseInt(id || ''),
  }) as unknown as {
    data: Project[];
    refetch: () => void;
    loading: boolean;
  };
  // just initializing this does out refetch if the state is empty, we dont need to use the funcitons
  const { mutate: postProject } = useAddCaptureToProject({
    captureId: parseInt(id || ''),
    uuid: '',
  });
  const { mutate: deleteProject } = useDeleteCaptureFromProject({
    uuid: '',
  });

  const { mutate: putCapture } = usePutCaptureById({
    id: NaN,
  });

  useEffect(() => {
    if (!captureQuery.data) {
      return;
    }
    const captureCustomer = customers?.find(
      (c) => c.id === captureQuery.data.customerId
    );
    setCapturesCustomer(captureCustomer);
  }, [captureQuery.data, customers]);

  useEffect(() => {
    if (!projects) return;
    if (allProjects && allProjects.length) {
      setProjectsAssigned(projects);
      const opts = allProjects
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map((p) => {
          return { name: p.name, value: p?.uuid || 0 };
        });
      setProjectOptions(opts);

      const selectedOpts = projects
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map((p) => {
          return { name: p.name, value: p?.uuid || 0 };
        });
      setSelectedProjectsOptions(selectedOpts);
    }
  }, [projects, allProjects]);

  useEffect(() => {
    if (!user) {
      setIsAdmin(false);
      return;
    }
    const isAdmin = hasClaims(
      [UserClaims.AgerAdmin],
      user?.cloudClaims as UserClaims[]
    );
    setIsAdmin(isAdmin);
  }, [user]);

  const projectSelected = async (projUuid: string, value: boolean) => {
    try {
      setLoading(true);
      if (value) {
        await postProject(undefined, {
          pathParams: { uuid: projUuid, captureId: parseInt(id || '') },
        });
      } else {
        await deleteProject(parseInt(id || ''), {
          pathParams: { uuid: projUuid },
        });
      }
      await refetch();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const updateCapture = async (newId?: number) => {
    try {
      setLoading(true);
      const updatedCapture = { ...captureQuery.data, customerId: newId };
      if (!updatedCapture?.id) return;
      await putCapture(updatedCapture, {
        pathParams: { id: updatedCapture.id },
      });
    } catch (e) {
      console.error(e);
    } finally {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      });
      setLoading(false);
      setCapturesCustomer(
        newId ? customers?.find((c) => c.id === newId) : undefined
      );
      setShowOrganizationEditor(false);
    }
  };

  return (
    <div className="flex w-full flex-row">
      <div className="flex flex-col justify w-1/2">
        <div className="flex py-1 items-center text-sm font-bold">
          <span>Assigned To</span>&nbsp;
          <PrimaryButton
            label="Edit"
            size="small"
            onClicked={() => {
              setShowProjectEditor((prev) => !prev);
            }}
            icon={<FontAwesomeIcon icon={faPen} />}
            className="ml-10"
          />
        </div>
        {showProjectEditor ? (
          <div className={`relative w-1/2`}>
            {loading && (
              <div className="absolute left-1/2 top-10">
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  size="2x"
                  className="text-green"
                />
              </div>
            )}
            <div
              className={`${loading ? 'opacity-25 pointer-events-none ' : ''}`}
            >
              <ProjectSelector
                projectOptions={projectOptions}
                selectedProjectsOptions={selectedProjectsOptions}
                onChange={projectSelected}
              />
            </div>
          </div>
        ) : (
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside p-3">
            {projectsAssigned.length === 0 && (
              <span className="text-gray-600 text-sm">
                {projectsLoading ? (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    size="2x"
                    className="text-green"
                  />
                ) : (
                  'No Assigned Projects'
                )}
              </span>
            )}
            {projectsAssigned?.map((project) => {
              return (
                <li className="text-gray-600 text-sm" key={project.id}>
                  {project.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="flex flex-col justify w-1/2">
        <div className="py-1 w-48 flex justify-between text-sm font-bold h-9 items-center">
          <span>Organization</span>
          {isAdmin && (
            <PrimaryButton
              label="Edit"
              size="small"
              onClicked={() => {
                setShowOrganizationEditor((prev) => !prev);
              }}
              icon={<FontAwesomeIcon icon={faPen} />}
              className="ml-10"
            />
          )}
        </div>
        {showOrganizationEditor ? (
          <Input.Select.Single
            id="organization-select"
            title="Organization"
            options={sortedCustomers ?? []}
            optionBuilder={(o) => o?.customerDisplayName ?? 'Unknown'}
            loading={loadingCustomers || loading}
            value={capturesCustomer}
            setValue={(c) => {
              if (loading) {
                return;
              }
              updateCapture(c?.id);
            }}
          />
        ) : (
          <span className="pt-2 text-sm pl-1.5 text-gray-800">
            {capturesCustomer?.customerDisplayName}
          </span>
        )}
      </div>
    </div>
  );
};

const ProjectSelector = ({
  projectOptions,
  selectedProjectsOptions,
  onChange,
}: {
  projectOptions: Options[];
  selectedProjectsOptions: Options[];
  onChange: (uuid: string, value: boolean) => void;
}) => {
  return (
    <div>
      <div className="w-full">
        <div className="p-3 h-64 overflow-y-auto border rounded-md">
          <MultiSelect
            options={projectOptions}
            selectedOptions={selectedProjectsOptions}
            onChange={(newSelection) => {
              let diff: Options[] = [];
              let value = undefined;
              if (newSelection.length > selectedProjectsOptions.length) {
                // added one
                value = true;
                diff = newSelection.filter((opt) => {
                  return !selectedProjectsOptions.find((p) => {
                    return p.value === opt.value;
                  });
                });
              } else {
                // removed one
                value = false;
                diff = selectedProjectsOptions.filter((opt) => {
                  return !newSelection.find((p) => {
                    return p.value === opt.value;
                  });
                });
              }
              if (!diff.length) return;

              onChange(diff[0].value as string, value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
