import { ThreeDViewer2ContextWrapper } from 'libs/feature/src/three-d-wrapper';
import { Link, Navigate, Outlet, useParams } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';

import { AppSidebar } from '@agerpoint/feature';
import { UserClaims } from '@agerpoint/types';
import {
  HasClaimsRouteGuard,
  hasClaims,
  useGlobalStore,
} from '@agerpoint/utilities';

import { AnalyticRequestsDetails } from './subpages/analytic-requests-page/analytic-requests-details';
import { AnalyticRequestsDetailsAdHocJob } from './subpages/analytic-requests-page/analytic-requests-details-ad-hoc-job';
import { AnalyticRequestsList } from './subpages/analytic-requests-page/analytic-requests-list';
import { PipelinePage } from './subpages/pipeline-page/pipeline-page';
import { PipelineCapturesHDJobsList } from './subpages/pipeline-page/subpages/pipeline-captures/pipeline-captures-hd-jobs-list';
import { PipelineCapturesHDMLPipeline } from './subpages/pipeline-page/subpages/pipeline-captures/pipeline-captures-hd-ml-pipeline';
import { PipelineCapturesList } from './subpages/pipeline-page/subpages/pipeline-captures/pipeline-captures-list';
import { PipelineJobsList } from './subpages/pipeline-page/subpages/pipeline-jobs/pipeline-jobs-list';
import { PipelineUploadsList } from './subpages/pipeline-page/subpages/pipeline-uploads/pipeline-uploads-list';
import { QaqcJobDetailsPage } from './subpages/qaqc-page/qaqc-job-details-page';
import { QAQCList } from './subpages/qaqc-page/qaqc-list';

export const Ops = () => {
  return (
    <HasClaimsRouteGuard claims={[UserClaims.QAQC]}>
      <Routes>
        <Route path="" element={<OpsPage />} />
        <Route
          path="qaqc"
          element={
            <AppSidebar>
              <Outlet />
            </AppSidebar>
          }
        >
          <Route path="" element={<QAQCList />} />
          <Route
            path=":captureId/:captureJobId/:extractionJobId"
            element={
              <ThreeDViewer2ContextWrapper>
                <QaqcJobDetailsPage />
              </ThreeDViewer2ContextWrapper>
            }
          />
          <Route
            path=":captureId/:captureJobId"
            element={
              <ThreeDViewer2ContextWrapper>
                <QaqcJobDetailsPage />
              </ThreeDViewer2ContextWrapper>
            }
          />
          <Route
            path=":captureId"
            element={
              <ThreeDViewer2ContextWrapper>
                <QaqcJobDetailsPage />
              </ThreeDViewer2ContextWrapper>
            }
          />

          <Route path="*" element={<Navigate to="/ops/qaqc" replace />} />
        </Route>
        <Route
          path="pipeline"
          element={
            <AppSidebar>
              <Outlet />
            </AppSidebar>
          }
        >
          <Route path="" element={<PipelinePage />} />
          <Route path="captures">
            <Route path="" element={<PipelineCapturesList />} />
            <Route
              path=":captureId/hd-jobs"
              element={<PipelineCapturesHDJobsList />}
            />
            <Route
              path="hd-ml-pipeline"
              element={<PipelineCapturesHDMLPipeline />}
            />
            <Route
              path="*"
              element={<Navigate to="/ops/pipeline/captures" replace />}
            />
          </Route>
          <Route path="uploads">
            <Route path="" element={<PipelineUploadsList />} />
            <Route
              path="*"
              element={<Navigate to="/ops/pipeline/uploads" replace />}
            />
          </Route>
          <Route path="pipeline-jobs">
            <Route
              path=""
              element={
                <HasClaimsRouteGuard claims={[UserClaims.AgerAdmin]}>
                  <PipelineJobsList />
                </HasClaimsRouteGuard>
              }
            />
            <Route
              path="*"
              element={<Navigate to="/ops/pipeline/pipeline-jobs" replace />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/ops/pipeline" replace />} />
        </Route>
        <Route
          path="analytics"
          element={
            <HasClaimsRouteGuard claims={[UserClaims.AgerAdmin]}>
              <AppSidebar>
                <Outlet />
              </AppSidebar>
            </HasClaimsRouteGuard>
          }
        >
          <Route path="" element={<AnalyticRequestsList />} />
          <Route path=":analyticRequestId/details">
            <Route path="" element={<AnalyticRequestsDetails />} />
            <Route
              path="ad-hoc/:jobCategory/:entityId"
              element={<AnalyticRequestsDetailsAdHocJob />}
            />
            <Route path="*" element={<AnalyticRequestDetailsRenavigate />} />
          </Route>
          <Route path="*" element={<Navigate to="/ops/analytics" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/ops" replace />} />
      </Routes>
    </HasClaimsRouteGuard>
  );
};

const AnalyticRequestDetailsRenavigate = () => {
  const { analyticRequestId } = useParams();
  return (
    <Navigate to={`/ops/analytics/${analyticRequestId}/details`} replace />
  );
};

export const OpsPage = () => {
  const { user } = useGlobalStore();

  return (
    <AppSidebar>
      <div className="flex p-4 h-full w-full">
        <Link
          to="/ops/qaqc"
          className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
            Captures QAQC
          </h5>
          <p className="font-normal text-gray-700">
            Manage and review captures, capture jobs, and extraction jobs.
          </p>
        </Link>
        <Link
          to="/ops/pipeline"
          className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
            Captures Pipeline
          </h5>
          <p className="font-normal text-gray-700">
            Manage capture pipeline jobs.
          </p>
        </Link>
        {hasClaims(
          [UserClaims.AgerAdmin],
          user?.cloudClaims as UserClaims[]
        ) && (
          <Link
            to="/ops/analytics"
            className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              Analytic Requests
            </h5>
            <p className="font-normal text-gray-700">
              Review analytics requests.
            </p>
          </Link>
        )}
      </div>
    </AppSidebar>
  );
};
