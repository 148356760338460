import { useEffect, useMemo, useState } from 'react';

import {
  useGetCaptureImageFileById,
  useGetCaptureImageHorizontalThumbnailById,
  useGetCaptureImageThumbnailById2,
  useGetCaptureImageVerticalThumbnailById,
} from '@agerpoint/api';
import { ImageViewer } from '@agerpoint/component';
import { CapturesViewerImage, OrientationOptions } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

interface CapturesViewerExpandedImageViewerProps {
  expandedImage?: CapturesViewerImage;
  setExpandedImage: (image?: CapturesViewerImage) => void;
}

/**
 * @deprecated Replaced with CapturesViewer2ExpandedImage
 */
export const CapturesViewerExpandedImageViewer = ({
  expandedImage,
  setExpandedImage,
}: CapturesViewerExpandedImageViewerProps) => {
  const [expandedImageBlob, setExpandedImageBlob] = useState<Blob>();
  const [fullResImageBlob, setFullResImageBlob] = useState<Blob>();
  const [thumbnailData, setThumbnailData] = useState<any>();

  const {
    capturesViewer: { captureImages, imageOrientation },
  } = useGlobalStore();

  const requestBody = useMemo(() => {
    return {
      id: expandedImage?.id ?? NaN,
      fit: 'fit-in',
      crop: '%20',
      size: `${2000}x${2000}`,
      verticalAlign: '%20',
      horizontalAlign: '%20',
      filters: '%20',
      lazy: true,
    };
  }, [expandedImage]);

  const {
    data: thumbnailDataOriginalOrientation,
    refetch: refetchOriginalOrientation,
    cancel: cancelOriginalOrientation,
  } = useGetCaptureImageThumbnailById2(requestBody) as any;

  const {
    data: thumbnailDataLandscapeOrientation,
    refetch: refetchLandscapeOrientation,
    cancel: cancelLandscapeOrientation,
  } = useGetCaptureImageHorizontalThumbnailById(requestBody) as any;

  const {
    data: thumbnailDataPortraitOrientation,
    refetch: refetchPortraitOrientation,
    cancel: cancelPortraitOrientation,
  } = useGetCaptureImageVerticalThumbnailById(requestBody) as any;

  const {
    data: fullResImageDataOriginalOrientation,
    refetch: refetchFullResOriginalOrientation,
    cancel: cancelFullResOriginalOrientation,
  } = useGetCaptureImageFileById({
    id: expandedImage?.id ?? NaN,
    lazy: true,
  }) as any;

  useEffect(() => {
    if (!fullResImageDataOriginalOrientation) return;
    const doAsync = async () => {
      const blob = await fullResImageDataOriginalOrientation.blob();
      setFullResImageBlob(blob);
    };
    doAsync();
  }, [fullResImageDataOriginalOrientation]);

  useEffect(() => {
    if (imageOrientation === OrientationOptions.Original) {
      setThumbnailData(thumbnailDataOriginalOrientation);
    } else if (imageOrientation === OrientationOptions.Landscape) {
      setThumbnailData(thumbnailDataLandscapeOrientation);
    } else if (imageOrientation === OrientationOptions.Portrait) {
      setThumbnailData(thumbnailDataPortraitOrientation);
    }
  }, [
    thumbnailDataOriginalOrientation,
    thumbnailDataPortraitOrientation,
    thumbnailDataLandscapeOrientation,
  ]);

  useEffect(() => {
    if (!expandedImage) return;
    setExpandedImageBlob(undefined);

    if (imageOrientation === OrientationOptions.Original) {
      refetchOriginalOrientation();
    } else if (imageOrientation === OrientationOptions.Landscape) {
      refetchLandscapeOrientation();
    } else if (imageOrientation === OrientationOptions.Portrait) {
      refetchPortraitOrientation();
    }
  }, [expandedImage, imageOrientation]);

  useEffect(() => {
    if (!expandedImage) return;
    refetchFullResOriginalOrientation();
  }, [expandedImage]);

  useEffect(() => {
    if (!thumbnailData?.blob) {
      return;
    }
    const doAsync = async () => {
      const b = await thumbnailData.blob();
      setExpandedImageBlob(b);
    };
    try {
      doAsync();
    } catch (e) {
      console.error(e);
    }
  }, [thumbnailData]);

  return (
    <ImageViewer
      open={!!expandedImage}
      handleCloseViewer={() => {
        setExpandedImage(undefined);
        setExpandedImageBlob(undefined);
        setFullResImageBlob(undefined);
      }}
      handleNavigation={{
        next: () => {
          let index = captureImages.findIndex((c) => c === expandedImage);

          if (index <= -1) {
            return;
          }
          setExpandedImageBlob(undefined);
          setFullResImageBlob(undefined);

          index += 1;
          if (index > captureImages.length - 1) {
            index = 0;
          }

          const newImage = captureImages[index];
          setExpandedImage(newImage);
        },
        previous: () => {
          let index = captureImages.findIndex((c) => c === expandedImage);

          if (index <= -1) {
            return;
          }
          setExpandedImageBlob(undefined);
          setFullResImageBlob(undefined);

          index -= 1;
          if (index < 0) {
            index = captureImages.length - 1;
          }

          const newImage = captureImages[index];
          setExpandedImage(newImage);
        },
      }}
      loading={!expandedImageBlob}
      showDownloadButton={true}
      downloadBlob={fullResImageBlob}
    >
      <div className="flex flex-row justify-center">
        {expandedImageBlob && (
          <div className="relative">
            <div
              className={`absolute bottom-0 left-0 right-0 flex flex-row justify-between gap-1 bg-gray-900 text-gray-100 rounded`}
            >
              <div className="px-1 py-0.5 text-4xl">
                {expandedImage?.localIndex} of {captureImages.length}
              </div>
              <div className="px-1 py-0.5 text-4xl">
                {expandedImage?.note ? `Note: ${expandedImage?.note}` : ''}
              </div>
            </div>
            <img
              style={{ borderRadius: '1%' }}
              src={URL.createObjectURL(expandedImageBlob)}
              alt={`Capture ${expandedImage?.id}`}
            />
          </div>
        )}
      </div>
    </ImageViewer>
  );
};
