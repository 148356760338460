import { CSSProperties, useContext, useMemo, useState } from 'react';

import { APLoader } from '../../ap-loader';
import { CloudDatatableItemData, StyleContext } from '../datatable.types';
import { Cell } from './cell';

export const Row = <T,>({
  index,
  style,
  data: itemData,
}: {
  index: number;
  style: CSSProperties;
  data: CloudDatatableItemData<T>;
}) => {
  const { data, columns, cellOnClick, loading, extraRow } = itemData;
  const d = data[index];
  const [isRowHovered, setIsRowHovered] = useState(false);
  const styleContext = useContext(StyleContext);

  const rowStyle = useMemo(
    () => styleContext?.row?.(d, index),
    [styleContext, d, index]
  );

  if (index === data.length) {
    if (loading) {
      return (
        <div style={{ ...style }} className="flex-center">
          <APLoader.CircleNotch />
        </div>
      );
    }

    return (
      <div style={{ ...style }} className="size-full">
        {extraRow}
      </div>
    );
  }
  return (
    <div
      style={style}
      onPointerEnter={() => {
        setIsRowHovered(true);
      }}
      onPointerLeave={() => {
        setIsRowHovered(false);
      }}
      className={`flex flex-row flex-center ${rowStyle}`}
    >
      {columns.map((c, i) => {
        return (
          <Cell
            key={i}
            data={d}
            column={c}
            index={0}
            isRowHovered={isRowHovered}
            cellOnClick={cellOnClick}
          />
        );
      })}
    </div>
  );
};
