import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

export const searchInfiniteQueriesForInitialValue = <T,>({
  queryClient,
  queryKey,
  id,
  accessor,
  stale = false,
  type = 'all',
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  id: T[keyof T];
  accessor: keyof T;
  stale?: boolean;
  type?: 'active' | 'all' | 'inactive';
}): T | undefined => {
  const queries = queryClient.getQueriesData<InfiniteData<T[]>>({
    queryKey: queryKey,
    stale: stale,
    type: type,
  });

  for (const [, data] of queries) {
    const pages = data?.pages;
    if (!pages) continue;

    for (const p of pages) {
      const u = p.find((u) => u[accessor] === id);
      if (u) return u;
    }
  }

  return undefined;
};

export const searchQueriesForInitialValue = <T,>({
  queryClient,
  queryKey,
  id,
  accessor,
  stale = false,
  type = 'all',
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  id: T[keyof T];
  accessor: keyof T;
  stale?: boolean;
  type?: 'active' | 'all' | 'inactive';
}): T | undefined => {
  const queries = queryClient.getQueriesData<T[]>({
    queryKey: queryKey,
    stale: stale,
    type: type,
  });
  for (const [, data] of queries) {
    if (!Array.isArray(data)) continue;
    const u = data?.find((u) => u[accessor] === id);
    if (u) return u;
  }

  return undefined;
};
