import { Link, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { AppSidebar } from '@agerpoint/feature';
import { UserClaims } from '@agerpoint/types';
import { HasClaimsRouteGuard } from '@agerpoint/utilities';

import { AdminAnalyticsBase } from './subpages/analytics/admin-analytics-base';
import { AdminAnalyticsDetails } from './subpages/analytics/admin-analytics-details';
import { AdminAnalyticsDetailsJobTypesMgmt } from './subpages/analytics/admin-analytics-details-job-types-mgmt';
import { AdminAnalyticsDetailsOrganizationsMgmt } from './subpages/analytics/admin-analytics-details-orgs-mgmt';
import { AdminAnalyticsList } from './subpages/analytics/admin-analytics-list';
import { AdminAnalyticsNew } from './subpages/analytics/admin-analytics-new';
import { AdminBlocksDetails } from './subpages/blocks/admin-blocks-details';
import { AdminBlocksList } from './subpages/blocks/admin-blocks-list';
import { AdminBlocksNew } from './subpages/blocks/admin-blocks-new';
import { AdminCapturesList } from './subpages/captures/admin-captures-list';
import { AdminCapturesDetails } from './subpages/captures/subcomponents/admin-captures-details';
import { AdminCapturesDetails3dModels } from './subpages/captures/subcomponents/admin-captures-details-3d-models';
import { AdminCapturesDetailsAnalytics } from './subpages/captures/subcomponents/admin-captures-details-analytics';
import { AdminCapturesDetailsAnalyticsStepper } from './subpages/captures/subcomponents/admin-captures-details-analytics-stepper';
import { AdminCapturesDetailsBase } from './subpages/captures/subcomponents/admin-captures-details-base';
import { AdminCropsDetails } from './subpages/crops/admin-crops-details';
import { AdminCropsList } from './subpages/crops/admin-crops-list';
import { AdminCropsNew } from './subpages/crops/admin-crops-new';
import { AdminFarmsDetails } from './subpages/farms/admin-farms-details';
import { AdminFarmsList } from './subpages/farms/admin-farms-list';
import { AdminFarmsNew } from './subpages/farms/admin-farms-new';
import { AdminGeometriesDetails } from './subpages/geometries/admin-geometries-details';
import { AdminGeometriesList } from './subpages/geometries/admin-geometries-list';
import { AdminGeometriesNew } from './subpages/geometries/admin-geometries-new';
import { AdminJobTypesDetails } from './subpages/job-types/admin-job-types-details';
import { AdminJobTypesList } from './subpages/job-types/admin-job-types-list';
import { AdminJobTypesNew } from './subpages/job-types/admin-job-types-new';
import { AdminMLModelsDetails } from './subpages/ml-models/admin-ml-models-details';
import { AdminMLModelsList } from './subpages/ml-models/admin-ml-models-list';
import { AdminMLModelsNew } from './subpages/ml-models/admin-ml-models-new';
import { AdminMosaicEnginesDetails } from './subpages/mosaic-engines/admin-mosaic-engines-details';
import { AdminMosaicEnginesList } from './subpages/mosaic-engines/admin-mosaic-engines-list';
import { AdminMosaicEnginesNew } from './subpages/mosaic-engines/admin-mosaic-engines-new';
import { AdminOrganizationsBase } from './subpages/organizations/admin-organizations-base';
import { AdminOrganizationsDetails } from './subpages/organizations/admin-organizations-details';
import { AdminOrganizationsDetailsAnalytics } from './subpages/organizations/admin-organizations-details-analytics';
import { AdminOrganizationsDetailsLicense } from './subpages/organizations/admin-organizations-details-license';
import { AdminOrganizationsList } from './subpages/organizations/admin-organizations-list';
import { AdminOrganizationsNew } from './subpages/organizations/admin-organizations-new';
import { AdminOrthomosaicsList } from './subpages/orthomosaics/admin-orthomosaics-list';
import { AdminOrthomosaicsNew } from './subpages/orthomosaics/admin-orthomosaics-new';
import { AdminPipelinesDetails } from './subpages/pipelines/admin-pipelines-details';
import { AdminPipelinesList } from './subpages/pipelines/admin-pipelines-list';
import { AdminPipelinesNew } from './subpages/pipelines/admin-pipelines-new';
import { AdminPointcloudsList } from './subpages/pointclouds/admin-pointclouds-list';
import { AdminPointcloudsNew } from './subpages/pointclouds/admin-pointclouds-new';
import { AdminProjectsDetails } from './subpages/projects/admin-projects-details';
import { AdminProjectsList } from './subpages/projects/admin-projects-list';
import { AdminUploadsDetails } from './subpages/uploads/admin-uploads-details';
import { AdminUploadsList } from './subpages/uploads/admin-uploads-list';

interface AdminPageProps {
  children?: React.ReactNode;
}

export const AdminPageRoutes = () => {
  return (
    <HasClaimsRouteGuard claims={[UserClaims.AgerAdmin]}>
      <Routes>
        <Route path="/" element={<AdminPage />}>
          <Route path="" element={<AdminLinkTiles />} />
          <Route path="analytics">
            <Route path="" element={<AdminAnalyticsList />} />
            <Route path="new" element={<AdminAnalyticsNew />} />
            <Route path=":analyticId">
              <Route path="*" element={<AdminAnalyticsBase />}>
                <Route
                  path="job-types"
                  element={<AdminAnalyticsDetailsJobTypesMgmt />}
                />
                <Route
                  path="organizations"
                  element={<AdminAnalyticsDetailsOrganizationsMgmt />}
                />
                <Route path="details" element={<AdminAnalyticsDetails />} />
                <Route
                  path="*"
                  element={<Navigate to="/admin/analytics" replace />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="blocks">
            <Route path="" element={<AdminBlocksList />} />
            <Route path=":blockId/details" element={<AdminBlocksDetails />} />
            <Route path="new" element={<AdminBlocksNew />} />
            <Route path="*" element={<Navigate to="/admin/blocks" replace />} />
          </Route>
          <Route path="projects">
            <Route path="" element={<AdminProjectsList />} />
            <Route
              path=":projectUuid/details"
              element={<AdminProjectsDetails />}
            />
            <Route
              path="*"
              element={<Navigate to="/admin/projects" replace />}
            />
          </Route>
          <Route path="organizations">
            <Route path="" element={<AdminOrganizationsList />} />
            <Route path="new" element={<AdminOrganizationsNew />} />
            <Route path=":organizationId">
              <Route path="*" element={<AdminOrganizationsBase />}>
                <Route path="details" element={<AdminOrganizationsDetails />} />
                <Route
                  path="analytics"
                  element={<AdminOrganizationsDetailsAnalytics />}
                />
                <Route
                  path="license"
                  element={<AdminOrganizationsDetailsLicense />}
                />
              </Route>
            </Route>
            <Route
              path="*"
              element={<Navigate to="/admin/organizations" replace />}
            />
          </Route>
          <Route path="captures">
            <Route path="" element={<AdminCapturesList />} />
            <Route
              path=":captureId/manage"
              element={<AdminCapturesDetailsBase />}
            >
              <Route path="details" element={<AdminCapturesDetails />} />
              <Route
                path="3d-models"
                element={<AdminCapturesDetails3dModels />}
              />
              <Route path="analytics">
                <Route path="" element={<AdminCapturesDetailsAnalytics />} />
                <Route
                  path=":analyticId"
                  element={<AdminCapturesDetailsAnalyticsStepper />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/admin/captures" replace />}
                />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/admin/captures" replace />}
              />
            </Route>

            <Route
              path="*"
              element={<Navigate to="/admin/captures" replace />}
            />
          </Route>
          <Route path="farms">
            <Route path="" element={<AdminFarmsList />} />
            <Route path="new" element={<AdminFarmsNew />} />
            <Route path=":farmId/details" element={<AdminFarmsDetails />} />
            <Route path="*" element={<Navigate to="/admin/farms" replace />} />
          </Route>

          <Route path="uploads">
            <Route path="" element={<AdminUploadsList />} />
            <Route path=":uploadId/details" element={<AdminUploadsDetails />} />
            <Route
              path="*"
              element={<Navigate to="/admin/uploads" replace />}
            />
          </Route>
          <Route path="pipelines">
            <Route path="" element={<AdminPipelinesList />} />
            <Route path="new" element={<AdminPipelinesNew />} />
            <Route
              path=":pipelineId/details"
              element={<AdminPipelinesDetails />}
            />
            <Route
              path="*"
              element={<Navigate to="/admin/pipelines" replace />}
            />
          </Route>
          <Route path="pointclouds">
            <Route path="" element={<AdminPointcloudsList />} />
            <Route path="new" element={<AdminPointcloudsNew />} />
            <Route
              path="*"
              element={<Navigate to="/admin/pointclouds" replace />}
            />
          </Route>
          <Route path="orthomosaics">
            <Route path="" element={<AdminOrthomosaicsList />} />
            <Route path="new" element={<AdminOrthomosaicsNew />} />
            <Route
              path="*"
              element={<Navigate to="/admin/orthomosaics" replace />}
            />
          </Route>
          <Route path="crops">
            <Route path="" element={<AdminCropsList />} />
            <Route path=":cropId/details" element={<AdminCropsDetails />} />
            <Route path="new" element={<AdminCropsNew />} />
            <Route path="*" element={<Navigate to="/admin/crops" replace />} />
          </Route>
          <Route path="geometries">
            <Route path="" element={<AdminGeometriesList />} />
            <Route
              path=":geometryId/details"
              element={<AdminGeometriesDetails />}
            />
            <Route path="new" element={<AdminGeometriesNew />} />
            <Route
              path="*"
              element={<Navigate to="/admin/geometries" replace />}
            />
          </Route>

          <Route path="ml-models">
            <Route path="" element={<AdminMLModelsList />} />
            <Route
              path=":mlModelId/details"
              element={<AdminMLModelsDetails />}
            />
            <Route path="new" element={<AdminMLModelsNew />} />
            <Route
              path="*"
              element={<Navigate to="/admin/ml-models" replace />}
            />
          </Route>

          <Route path="mosaic-engines">
            <Route path="" element={<AdminMosaicEnginesList />} />
            <Route
              path=":mosaicEngineId/details"
              element={<AdminMosaicEnginesDetails />}
            />
            <Route path="new" element={<AdminMosaicEnginesNew />} />
            <Route
              path="*"
              element={<Navigate to="/admin/mosaic-engines" replace />}
            />
          </Route>

          <Route path="job-types">
            <Route path="" element={<AdminJobTypesList />} />
            <Route
              path=":jobTypeId/details"
              element={<AdminJobTypesDetails />}
            />
            <Route path="new" element={<AdminJobTypesNew />} />
            <Route
              path="*"
              element={<Navigate to="/admin/job-types" replace />}
            />
          </Route>

          <Route path="*" element={<Navigate to="/admin" replace />} />
        </Route>
      </Routes>
    </HasClaimsRouteGuard>
  );
};

export const AdminPage = ({ children }: AdminPageProps) => {
  return (
    <AppSidebar>
      <div className="w-full h-full">
        <Outlet />
      </div>
    </AppSidebar>
  );
};

const AdminLinkTiles = () => {
  return (
    <div className="flex p-4 flex-wrap h-full overflow-auto content-start">
      <Link
        to="/admin/analytics"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Analytics
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Analytics.
        </p>
      </Link>
      <Link
        to="/admin/blocks"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Blocks
        </h5>
        <p className="font-normal text-gray-700">Manage and review Blocks.</p>
      </Link>
      <Link
        to="/admin/captures"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Captures
        </h5>
        <p className="font-normal text-gray-700">Manage and review Captures.</p>
      </Link>
      <Link
        to="/admin/crops"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Crops
        </h5>
        <p className="font-normal text-gray-700">Manage and review Crops.</p>
      </Link>
      <Link
        to="/admin/farms"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Farms
        </h5>
        <p className="font-normal text-gray-700">Manage and review Farms.</p>
      </Link>
      <Link
        to="/admin/geometries"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Geometries
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Geometries.
        </p>
      </Link>
      <Link
        to="/admin/job-types"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Job Types
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Job Types.
        </p>
      </Link>
      <Link
        to="/admin/ml-models"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          ML Models
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review ML Models.
        </p>
      </Link>
      <Link
        to="/admin/mosaic-engines"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Mosaic Engines
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Mosaic Engines.
        </p>
      </Link>
      <Link
        to="/admin/organizations"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Organizations
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Organizations.
        </p>
      </Link>
      <Link
        to="/admin/orthomosaics"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Orthomosaics
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Orthomosaics.
        </p>
      </Link>
      <Link
        to="/admin/pipelines"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Pipelines
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Pipelines.
        </p>
      </Link>
      <Link
        to="/admin/pointclouds"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Pointclouds
        </h5>
        <p className="font-normal text-gray-700">
          Manage and review Pointclouds.
        </p>
      </Link>
      <Link
        to="/admin/projects"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Projects
        </h5>
        <p className="font-normal text-gray-700">Manage and review Projects.</p>
      </Link>
      <Link
        to="/admin/uploads"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Uploads
        </h5>
        <p className="font-normal text-gray-700">Manage and review Uploads.</p>
      </Link>
    </div>
  );
};
