import {
  faCheck,
  faCircleNotch,
  faGears,
  faImage,
  faMagnifyingGlass,
  faMicrochip,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import { useGetPipelineJob, usePostPipelineJob } from '@agerpoint/api';
import { ConfirmModal, PrimaryButton } from '@agerpoint/component';
import {
  IPipelineStatus,
  IPipelineStatusStyle,
  PipelineStatus,
  PipelineStatusNames,
} from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

import {
  filterAndSortPipelineJobs,
  getAnalyzeClassNames,
  getCompletedClassNames,
  getPreSelectClassNames,
  getReviewClassNames,
  inactiveStyleRes,
} from './capture-viewer-pipeline.utilities';
import { completeStyleRes } from './capture-viewer-pipeline.utilities';

export const CaptureViewerImageSelectionPipeline = ({
  captureId,
}: {
  captureId: number;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [whichJob, setWhichJob] = useState<PipelineStatus | null>(null);
  const { mutate: postPipelineJob } = usePostPipelineJob({});
  const [readyStageStyles, setReadyStageStyles] =
    useState<IPipelineStatusStyle>({
      ...completeStyleRes,
    });
  const [preSelectStageStyles, setPreSelectStageStyles] =
    useState<IPipelineStatusStyle>({
      ...inactiveStyleRes,
    });
  const [reviewStageStyles, setReviewStageStyles] =
    useState<IPipelineStatusStyle>({
      ...inactiveStyleRes,
    });
  const [postSelectStageStyles, setPostSelectStageStyles] =
    useState<IPipelineStatusStyle>({
      ...inactiveStyleRes,
    });
  const [completeStageStyles, setCompleteStageStyles] =
    useState<IPipelineStatusStyle>({
      ...inactiveStyleRes,
    });

  const [pipelineJobs, setPipelineJobs] = useState<IPipelineStatus>({
    preprocess: [],
    analyze: [],
  });

  const {
    data,
    error,
    loading: getPipelineJobsLoading,
  } = useGetPipelineJob({});
  const toasts = useToasts();

  useEffect(() => {
    if (!data) return;
    const thisCaptureJobs = filterAndSortPipelineJobs(captureId, data);
    setPipelineJobs(thisCaptureJobs);
  }, [data]);

  useEffect(() => {
    if (getPipelineJobsLoading) return;
    setPreSelectStageStyles(getPreSelectClassNames(pipelineJobs));
    setReviewStageStyles(getReviewClassNames(pipelineJobs));
    setPostSelectStageStyles(getAnalyzeClassNames(pipelineJobs));
    setCompleteStageStyles(getCompletedClassNames(pipelineJobs));
  }, [pipelineJobs]);

  const triggerSuccess = () => {
    toasts.add({
      title: 'Pipeline job started!',
      type: 'success',
    });
  };
  const triggerError = () => {
    toasts.add(toasts.prepare.error('Pipeline job failed!'));
  };

  const trigger = (jobStatus: PipelineStatus) => {
    setWhichJob(jobStatus);
    setIsModalOpen(true);
  };

  const confirmTrigger = () => {
    if (!whichJob) return;
    const body = {
      captureId,
      stage: PipelineStatus[whichJob],
      pipelineId: 1,
      name: `${whichJob} - ${captureId}`,
    };

    let newPipelineJobs: IPipelineStatus = {
      preprocess: [],
      analyze: [],
    };

    if (whichJob === PipelineStatus.preprocess) {
      newPipelineJobs = {
        ...pipelineJobs,
        preprocess: [{}],
      };
    } else if (whichJob === PipelineStatus.analyze) {
      newPipelineJobs = {
        ...pipelineJobs,
        analyze: [{}],
      };
    }

    const doAsync = async () => {
      try {
        await postPipelineJob(body);
        triggerSuccess();
        setPipelineJobs(newPipelineJobs);
      } catch (e) {
        console.error(e);
        triggerError();
        setIsModalOpen(false);
        setWhichJob(null);
      } finally {
        setIsModalOpen(false);
        setWhichJob(null);
      }
    };

    doAsync();
  };

  return getPipelineJobsLoading ? (
    <div className="flex items-center w-full">
      <span className="pr-2">Loading Pipeline Job Status</span>
      <FontAwesomeIcon icon={faCircleNotch} spin className="text-green" />
    </div>
  ) : (
    <>
      <div className="flex justify-end w-full pb-2">
        <PrimaryButton
          label="Start Pre-Select Pipeline"
          onClicked={() => trigger(PipelineStatus.preprocess)}
        />
        <PrimaryButton
          className="ml-2"
          disabled={reviewStageStyles.status === PipelineStatusNames.inactive}
          label="Start Post-Select Pipeline"
          onClicked={() => trigger(PipelineStatus.analyze)}
        />
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Trigger Pipeline Job"
        message="Are you sure you want to start this pipeline job?"
        canConfirm={true}
        theme="solidDarkBlue"
        confirm={{
          label: 'Confirm',
          callback: confirmTrigger,
        }}
        close={{
          label: 'Cancel',
          callback: () => {
            setIsModalOpen((prev) => !prev);
          },
        }}
      />
      <div className="flex items-center w-full">
        <div className="flex w-full items-center">
          <div>Ready</div>
        </div>
        <div className="flex w-full items-center">
          <div>Pre-Select</div>
        </div>
        <div className="flex w-full items-center">
          <div>Review</div>
        </div>
        <div className="flex w-full items-center">
          <div>Post-Select</div>
        </div>
        <div className="flex w-full items-center">
          <div>Complete</div>
        </div>
      </div>
      <div>
        <ol className="flex items-center w-full">
          <li className={readyStageStyles.container}>
            <span className={readyStageStyles.text}>
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <div className={readyStageStyles.border}></div>
          </li>
          <li className={preSelectStageStyles.container}>
            <span className={preSelectStageStyles.text}>
              <FontAwesomeIcon
                className={
                  preSelectStageStyles.status === PipelineStatusNames.inactive
                    ? 'cursor-pointer'
                    : ''
                }
                icon={
                  preSelectStageStyles.status === PipelineStatusNames.active
                    ? faCircleNotch
                    : faMicrochip
                }
                spin={
                  preSelectStageStyles.status === PipelineStatusNames.active
                }
              />
            </span>
            <div className={preSelectStageStyles.border}></div>
          </li>
          <li className={reviewStageStyles.container}>
            <span className={reviewStageStyles.text}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <div className={reviewStageStyles.border}></div>
          </li>
          <li className={postSelectStageStyles.container}>
            <span className={postSelectStageStyles.text}>
              <FontAwesomeIcon
                className={
                  postSelectStageStyles.status === PipelineStatusNames.active
                    ? 'cursor-pointer'
                    : ''
                }
                icon={
                  postSelectStageStyles.status === PipelineStatusNames.active
                    ? faCircleNotch
                    : faGears
                }
                spin={
                  postSelectStageStyles.status === PipelineStatusNames.active
                }
              />
            </span>
            <div className={postSelectStageStyles.border}></div>
          </li>
          <li className={completeStageStyles.container}>
            <span className={completeStageStyles.text}>
              <FontAwesomeIcon icon={faImage} />
            </span>
          </li>
        </ol>
      </div>
    </>
  );
};
