import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Vector3 } from 'three';

import { APIModels, Coordinate } from '@agerpoint/api';
import { EffectNames, SpecialCaptureObject } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer';
import { useThreeDAnnotationsPluginQueries } from './three-d-annotations-plugin-queries';

type AllowedKeys = 'name' | 'description';

export const ThreeDAnnotationsPlugin = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearch] = useSearchParams();

  const { selectedCaptureJob, annotationCaptureObjects } =
    useCapturesViewerContext();

  const {
    createCaptureObject,
    updateCaptureObject,
    deleteCaptureObject,
    deleteCustomCaptureObjectAttributes,
    updateCustomCaptureObjectAttributes,
  } = useThreeDAnnotationsPluginQueries();
  const { subscribe } = useGlobalStore();

  const createEffectFn = useCallback(
    (captureObject: SpecialCaptureObject) => {
      if (!selectedCaptureJob) return;
      const captureId = selectedCaptureJob.captureId;
      const captureJobId = selectedCaptureJob.id;

      captureObject.captureId = captureId;
      captureObject.captureJobId = captureJobId;
      createCaptureObject.mutate(captureObject);
    },
    [selectedCaptureJob, createCaptureObject]
  );

  const deleteEffectFn = useCallback(
    (captureObjectId: string) => {
      if (!selectedCaptureJob) return;

      const co = annotationCaptureObjects?.find(
        (co) => co.id === Number(captureObjectId)
      );
      if (co?.captureObjectCustomAttributes) {
        const promises = Promise.all(
          co.captureObjectCustomAttributes.map((attr) => {
            if (!attr?.id) return Promise.resolve();
            return deleteCustomCaptureObjectAttributes.mutate(attr.id);
          })
        );
        promises.then(() => {
          deleteCaptureObject.mutate(Number(captureObjectId));
        });
      } else {
        deleteCaptureObject.mutate(Number(captureObjectId));
      }
    },
    [selectedCaptureJob, annotationCaptureObjects]
  );

  const updateColorEffectFn = useCallback(
    ({ id, color }: { id: number; color: string }) => {
      const co = annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co?.captureObjectCustomAttributes) return;
      const customAttribute = co.captureObjectCustomAttributes.find(
        (attr) => attr.attributeName === 'color'
      );
      if (!customAttribute?.id) return;
      customAttribute.attributeValue = color;
      updateCustomCaptureObjectAttributes.mutate({
        updatedCaptureAttribute: customAttribute,
        customAttributeId: customAttribute.id,
      });
    },
    [annotationCaptureObjects]
  );

  const updatePositionEffectFn = useCallback(
    ({ id, position }: { id: number; position: Vector3[] | Vector3 }) => {
      const co = annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co?.geom2D?.coordinates) return;
      co.geom2D = {
        ...co.geom2D,
        coordinates: position,
      } as unknown as Coordinate;
      updateCaptureObject.mutate(co);
    },
    [selectedCaptureJob, annotationCaptureObjects]
  );

  const updateCaptureObjectProperty = useCallback(
    ({
      id,
      value,
      property,
    }: {
      id: number;
      value: string;
      property: AllowedKeys;
    }) => {
      const co: APIModels.CaptureObject | undefined =
        annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co) return;
      co[property] = value || '';
      updateCaptureObject.mutate(co);
    },
    [annotationCaptureObjects]
  );

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_CREATE,
      createEffectFn
    );

    return unsubscribe;
  }, [selectedCaptureJob]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_POSITION,
      updatePositionEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_DELETE,
      deleteEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  // capture object custom attribute - color
  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_COLOR,
      updateColorEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  //name
  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_NAME,
      ({ id, name }: { id: number; name: string }) => {
        updateCaptureObjectProperty({ id, value: name, property: 'name' });
      }
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  //description
  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_DESCRIPTION,
      ({ id, description }: { id: number; description: string }) => {
        updateCaptureObjectProperty({
          id,
          value: description,
          property: 'description',
        });
      }
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECT_WAS_CLICKED,
      (id: number) => {
        setSearch(
          (prev) => {
            prev.set('details', 'annotations');
            return prev;
          },
          {
            replace: true,
          }
        );
      }
    );
    return unsubscribe;
  }, []);

  return null;
};
