import { IconName, IconPrefix } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAgerStore } from '@agerpoint/utilities';

import { APLoader } from '../ap-loader';

interface IContextMenu {
  childRef: React.RefObject<HTMLElement>;
  bonusPadding?: Position;
  parentRef?: React.RefObject<HTMLElement>;
  children?: React.ReactNode;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  show?: boolean;
  positionRelativeToParent?: boolean;
  closeOnWheel?: boolean;
  dontHideOnBodyClick?: boolean;
}

interface Position {
  left?: number;
  top?: number;
  bottom?: number;
  right?: number;
}

const ContextMenu = ({
  childRef,
  bonusPadding,
  parentRef,
  children,
  setShow,
  show,
  positionRelativeToParent,
  closeOnWheel = false,
  dontHideOnBodyClick = false,
}: IContextMenu) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useAgerStore();

  const [position, setPosition] = useState<Position>({});
  const calculatePosition = useCallback(() => {
    if (childRef.current) {
      let isLeft = false;
      let isTop = false;
      let isRight = false;
      let isBottom = false;
      const rect = childRef.current.getBoundingClientRect();
      if (parentRef?.current && positionRelativeToParent) {
        // Check on which quarter of the parent the child is
        const parentRect = parentRef.current.getBoundingClientRect();
        isLeft = rect.left < parentRect.left + parentRect.width / 2;
        isTop = rect.top < parentRect.top + parentRect.height / 2;
        isRight = !isLeft;
        isBottom = !isTop;
      } else {
        // Check on which quarter of the screen the child is
        isLeft = rect.left < window.innerWidth / 2;
        isTop = rect.top < window.innerHeight / 2;
        isRight = !isLeft;
        isBottom = !isTop;
      }

      const position: Position = {};

      if (isTop) {
        position.top = rect.bottom + (bonusPadding?.top ?? 0);
      }

      if (isBottom) {
        position.bottom =
          window.innerHeight - rect.top + (bonusPadding?.bottom ?? 0);
      }

      if (!isMobile) {
        if (isRight) {
          position.right =
            window.innerWidth - rect.right + (bonusPadding?.right ?? 0);
        }
        if (isLeft) {
          position.left = rect.right - rect.width + (bonusPadding?.left ?? 0);
        }
      }

      setPosition(position);
    }
  }, [childRef, bonusPadding, parentRef, positionRelativeToParent, isMobile]);

  const windowOnClick = useCallback(
    (e: MouseEvent) => {
      if (childRef.current?.contains(e.target as Node)) {
        setShow?.((prev) => !prev);
        return;
      }

      if (!show) {
        return;
      }

      if (menuRef.current?.contains(e.target as Node) && dontHideOnBodyClick) {
        return;
      }

      setShow?.(false);
    },
    [setShow, dontHideOnBodyClick, childRef, show]
  );

  const onWheel = useCallback(
    (e: WheelEvent | TouchEvent) => {
      if (!closeOnWheel || !show) {
        return;
      }

      setShow?.(false);
    },
    [setShow, closeOnWheel, show]
  );

  useEffect(() => {
    window.addEventListener('click', windowOnClick);
    window.addEventListener('wheel', onWheel);
    window.addEventListener('touchmove', onWheel);
    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('click', windowOnClick);
      window.removeEventListener('wheel', onWheel);
      window.removeEventListener('touchmove', onWheel);
      window.removeEventListener('resize', calculatePosition);
    };
  }, [windowOnClick, calculatePosition, onWheel, show]);

  useEffect(() => {
    if (show) {
      calculatePosition();
    }
  }, [show]);

  useEffect(() => {
    return () => {
      setShow?.(false);
    };
  }, []);

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          ref={menuRef}
          className={`absolute z-context-menu`}
          style={{
            ...position,
            ...(isMobile
              ? {
                  left: '50%',
                  translateX: '-50%',
                  maxWidth: 'calc(100% - 24px)',
                }
              : {}),
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('apc-context-menus') as HTMLElement
  );
};

interface IContextMenuContent {
  children?: React.ReactNode;
}

const ContextMenuContent = ({ children }: IContextMenuContent) => {
  return (
    <div
      className={`bg-white py-2 rounded-lg shadow-lg
      border-gray-border border overflow-hidden`}
    >
      {children}
    </div>
  );
};

interface IContextMenuItem {
  label: string;
  labelColor?: string;
  icon?: IconName;
  iconColor?: string;
  iconPrefix?: IconPrefix;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  loading?: boolean;
  disabled?: boolean;
  selected?: boolean;
}

const ContextMenuItem = ({
  label,
  labelColor = 'text-gray-textPrimary',
  icon,
  iconColor = 'text-gray-iconPrimary',
  iconPrefix = 'far',
  onClick,
  loading = false,
  disabled = false,
  selected = false,
}: IContextMenuItem) => {
  return (
    <div
      className={`flex flex-row gap-2 px-4 py-2 transition-colors shrink-0 ${
        loading
          ? 'cursor-progress'
          : disabled
          ? 'cursor-not-allowed'
          : 'cursor-pointer hover-overlay-5'
      } ${selected ? 'bg-primary bg-opacity-10' : ''}`}
      onClick={(e) => {
        if (loading || disabled) {
          return;
        }
        onClick?.(e);
      }}
    >
      {loading ? (
        <APLoader.CircleNotch className="text-gray-iconSecondary py-1 shrink-0" />
      ) : icon ? (
        <FontAwesomeIcon
          icon={[iconPrefix, icon]}
          className={`shrink-0 ${
            disabled ? 'text-gray-iconSecondary' : iconColor
          } size-4 py-1`}
          style={{
            color: disabled ? 'text-gray-iconSecondary' : iconColor,
          }}
        />
      ) : (
        <div className="size-4 shrink-0" />
      )}
      <span className={disabled ? 'text-gray-textSecondary' : labelColor}>
        {label}
      </span>
    </div>
  );
};

interface IContextMenuCascadingItem {
  label: string;
  icon?: IconName;
  iconColor?: string;
  iconPrefix?: IconPrefix;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  loading?: boolean;
  disabled?: boolean;
  selected?: boolean;
  dontHideOnBodyClick?: boolean;
  children?: React.ReactNode;
}

const ContextMenuCascadingItem = ({
  label,
  icon,
  iconColor = 'text-gray-iconPrimary',
  iconPrefix = 'far',
  loading = false,
  disabled = false,
  selected = false,
  dontHideOnBodyClick = false,
  children,
}: IContextMenuCascadingItem) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const itemRef = useRef<HTMLDivElement>(null);
  const subMenuRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useAgerStore();

  const [position, setPosition] = useState<Position>({});
  const calculatePosition = useCallback(() => {
    if (itemRef.current) {
      let isLeft = false;
      let isRight = false;
      const rect = itemRef.current.getBoundingClientRect();
      // Check on which half of the screen the child is
      isLeft = rect.left < window.innerWidth / 2;
      isRight = !isLeft;

      const position: Position = {};

      if (isMobile) {
        position.top = rect.bottom;
      } else {
        position.top = rect.top - 9;

        if (!isMobile) {
          if (isRight) {
            position.right = window.innerWidth - rect.right + rect.width;
          }
          if (isLeft) {
            position.left = rect.right;
          }
        }
      }

      setPosition(position);
    }
  }, [itemRef, isMobile]);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [calculatePosition]);

  useEffect(() => {
    if (isHovered || isFocused) {
      calculatePosition();
    }
  }, [isHovered, isFocused]);

  const show = useMemo(() => {
    if (loading || disabled) {
      return false;
    }

    if (isMobile) {
      return isFocused;
    }

    return isHovered;
  }, [disabled, isFocused, isHovered, loading, isMobile]);

  return (
    <div
      className={`flex flex-row gap-2 px-4 py-2 transition-colors ${
        loading
          ? 'cursor-progress'
          : disabled
          ? 'cursor-not-allowed'
          : 'cursor-pointer hover-overlay-5'
      } ${selected ? 'bg-primary bg-opacity-10' : ''}`}
      ref={itemRef}
      onPointerEnter={() => !isMobile && setIsHovered(true)}
      onPointerLeave={() => !isMobile && setIsHovered(false)}
      onClick={(e) => {
        if (isMobile) {
          e.preventDefault();
          setIsFocused((prev) => !prev);
        }

        if (
          subMenuRef.current?.contains(e.target as Node) &&
          !dontHideOnBodyClick
        ) {
          setIsFocused(false);
          setIsHovered(false);
        }

        if (itemRef.current?.contains(e.target as Node)) {
          e.stopPropagation();
        }
      }}
    >
      {loading ? (
        <APLoader.CircleNotch className="text-gray-iconSecondary py-1" />
      ) : icon ? (
        <FontAwesomeIcon
          icon={[iconPrefix, icon]}
          className={`${
            disabled ? 'text-gray-iconSecondary' : iconColor
          } size-4 py-1`}
          style={{
            color: disabled ? 'text-gray-iconSecondary' : iconColor,
          }}
        />
      ) : (
        <div className="size-4" />
      )}
      <span
        className={`w-full ${
          disabled ? 'text-gray-textSecondary' : 'text-gray-textPrimary'
        }`}
      >
        {label}
      </span>
      <FontAwesomeIcon
        icon={['fas', 'caret-right']}
        className="text-gray-iconPrimary py-1"
      />

      {createPortal(
        <AnimatePresence>
          {show && (
            <motion.div
              ref={subMenuRef}
              className={`absolute z-context-menu`}
              style={{
                ...position,
                ...(isMobile
                  ? {
                      left: '50%',
                      translateX: '-50%',
                      maxWidth: 'calc(100% - 24px)',
                    }
                  : {}),
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              onClick={(e) => dontHideOnBodyClick && e.stopPropagation()}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>,
        document.getElementById('apc-context-menus') as HTMLElement
      )}
    </div>
  );
};

interface IContextMenuDivider {
  label?: string;
}

const ContextMenuDivider = ({ label }: IContextMenuDivider) => {
  if (label === undefined) {
    return (
      <div className="py-2 flex items-center w-full">
        <div className="border-t border-gray-border w-full"></div>
      </div>
    );
  }

  return (
    <div className="pt-2">
      <div className="border-t border-gray-border py-2 px-4 font-bold">
        {label}
      </div>
    </div>
  );
};

ContextMenu.Content = ContextMenuContent;
ContextMenu.Item = ContextMenuItem;
ContextMenu.CascadingItem = ContextMenuCascadingItem;
ContextMenu.Divider = ContextMenuDivider;

export { ContextMenu };
