import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, formatDateAndTime } from '@agerpoint/api';
import { AnalyticRequestStatus, CaptureJobTypes } from '@agerpoint/types';

import { useCapturesViewerContext } from '../../../captures-viewer-context';
import { useCapturesViewerQueries } from '../../../captures-viewer-queries';
import { CVS3AnalyticsRequestCard } from './subcomponents/cvs3-analytic-request-card';

interface CVS3AnalyticsRequestsListProps {
  limited: boolean;
  filter?: CVS3AnalyticsRequestListFilter;
}

export interface CVS3AnalyticsRequestListFilter {
  analyticName: string;
  objectName: string;
  attributeNames: string;
  status?: AnalyticRequestStatus;
  sort: string;
}

export const CVS3AnalyticsRequestsList = ({
  limited,
  filter,
}: CVS3AnalyticsRequestsListProps) => {
  const {
    analyticRequestsWithCaptureObjectsAndJobsAllQuery,
    analyticRequestsQuery,
  } = useCapturesViewerQueries({});

  const limitedTo = useMemo(() => 3, []);

  const items = useMemo(() => {
    if (!analyticRequestsWithCaptureObjectsAndJobsAllQuery) return;
    const filtered = analyticRequestsWithCaptureObjectsAndJobsAllQuery
      .filter((q) => {
        if (filter === undefined) {
          return true;
        }

        const data = cloneDeep(q.data);

        if (filter.status !== undefined) {
          if (data?.analyticRequest?.status !== filter.status) {
            return false;
          }
        }

        if (filter.analyticName !== '') {
          const analyticName =
            q.data?.analyticRequest?.customerAnalytic?.analytic?.analyticName;

          const matches = analyticName
            ?.toLowerCase()
            .includes(filter.analyticName.toLowerCase().trim());

          if (!matches) {
            return false;
          }
        }

        if (filter.objectName !== '') {
          if (data?.captureObjects === undefined) {
            return false;
          }

          data.captureObjects = data?.captureObjects?.filter((o) =>
            o.name
              ?.toLowerCase()
              .includes(filter.objectName.toLowerCase().trim())
          );

          if (data.captureObjects.length === 0) {
            return false;
          }
        }

        if (filter.attributeNames !== '') {
          const attributeNames = filter.attributeNames
            .split(',')
            .map((a) => a.trim())
            .filter((a) => a !== '');

          const matches = data?.captureObjects?.some((o) =>
            o.captureObjectCustomAttributes?.some((a) => {
              const attrName = a.attributeDisplayName ?? a.attributeName;

              return attributeNames.some((attributeName) =>
                attrName
                  ?.toLowerCase()
                  .includes(attributeName.toLowerCase().trim())
              );
            })
          );

          if (!matches) {
            return false;
          }
        }

        return true;
      })
      .sort((a, b) => {
        const aAr = a?.data?.analyticRequest?.customerAnalytic?.priority;
        const bAr = b?.data?.analyticRequest?.customerAnalytic?.priority;
        if (aAr === undefined || bAr === undefined) {
          return 0;
        }
        if (filter?.sort === 'ASC') {
          // same as the end of the function, but this is more readable
          return aAr - bAr;
        }
        if (filter?.sort === 'DESC') {
          return bAr - aAr;
        }
        return aAr - bAr;
      })
      ?.slice(0, limited ? limitedTo : undefined);

    return filtered;
  }, [
    analyticRequestsWithCaptureObjectsAndJobsAllQuery,
    filter,
    limitedTo,
    limited,
  ]);

  return (
    <div className="flex flex-col gap-2 py-2">
      {(items ?? []).map((q, index) => (
        <CVS3AnalyticsRequestCard
          key={index}
          analyticRequestWithCaptureObjectsAndJobsQuery={q}
          limited={limited}
          filter={filter}
        />
      ))}
      {limited && <BaseCaptureCard />}
      {limited &&
        (analyticRequestsQuery?.data?.length ?? 0) > limitedTo &&
        limitedTo > 0 && (
          <div className="flex flex-row px-3 text-xs text-gray-500 justify-start">
            <span>
              {(analyticRequestsQuery?.data?.length ?? 0) - limitedTo} more
              request(s)...
            </span>
          </div>
        )}
    </div>
  );
};

const BaseCaptureCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { captureId } = useParams();
  const [clickable, setClickable] = useState(false);
  const [lowResJob, setLowResJob] = useState<APIClient.CaptureJob>();
  const [displayingSelectedCaptureJob, setDisplayingSelectedCaptureJob] =
    useState(false);
  const { captureQuery } = useCapturesViewerQueries({});
  const { selectedCaptureJob } = useCapturesViewerContext();

  useEffect(() => {
    const job = captureQuery?.data?.completedJobs?.find((job) => {
      return (
        job.captureJobTypeId === CaptureJobTypes['Low Resolution'] &&
        job.eptPointcloudId !== null
      );
    });
    if (job) {
      setLowResJob(job);
      setClickable(true);
    }
  }, [captureQuery.data]);

  useEffect(() => {
    if (!lowResJob) {
      return;
    }

    if (selectedCaptureJob?.id === lowResJob?.id) {
      setDisplayingSelectedCaptureJob(true);
    } else {
      setDisplayingSelectedCaptureJob(false);
    }
  }, [selectedCaptureJob, lowResJob]);

  const onClick = useCallback(() => {
    if (location.pathname.includes('analytics-outputs')) {
      navigate(`/captures/${captureId}/analytics-outputs/base`);
    } else {
      navigate(`/captures/${captureId}/analytics-output/base`);
    }
  }, [captureId, location, navigate]);

  return lowResJob ? (
    <div className="w-full px-2">
      <div
        className={`relative w-full flex flex-col bg-gray-50 border border-gray-300 shadow rounded transition-colors ${
          clickable ? 'hover:bg-gray-100 cursor-pointer' : ''
        } ${displayingSelectedCaptureJob ? 'border-green' : ''}`}
        onClick={clickable ? onClick : undefined}
      >
        <div
          className={`w-full px-3 py-2 flex flex-row justify-between items-center text-sm gap-2`}
        >
          <span className="truncate">LiDAR - Mobile Device</span>
          <span className="text-xs text-gray-500 whitespace-nowrap">
            {`${formatDateAndTime(lowResJob?.createDatetime)}`}
          </span>
        </div>
      </div>
    </div>
  ) : null;
};
