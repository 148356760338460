import { useCallback, useRef } from 'react';
import {
  NavigateOptions,
  Path,
  useLocation,
  useNavigate,
} from 'react-router-dom';

interface ICloudNavigateFunction {
  (to: Partial<Path>, options?: ICloudNavigateOptions): void;
}

interface ICloudNavigateOptions extends NavigateOptions {
  clearSearch?: boolean;
}

const pathToSearchMappings: { [key: string]: string | undefined } = {};

export const useCloudNavigate = (): ICloudNavigateFunction => {
  const navigate = useNavigate();
  const location = useLocation();

  const cloudNavigate: ICloudNavigateFunction = useCallback(
    (to: Partial<Path>, options: ICloudNavigateOptions = {}) => {
      if (!to.pathname?.startsWith('/')) {
        throw new Error('Pathname must start with a forward slash');
      }
      // Store the search params for the current pathname
      const currentSearchParams = new URLSearchParams(location.search);
      pathToSearchMappings[location.pathname] = currentSearchParams.toString();

      // Get the stored search params for the target pathname
      const targetSearchParams = new URLSearchParams(
        pathToSearchMappings[to.pathname]
      );

      // Append the stored search params to the existing search params
      const mergedSearchParams = new URLSearchParams(to.search ?? '');
      if (!options.clearSearch) {
        targetSearchParams.forEach((value, key) => {
          mergedSearchParams.append(key, value);
        });
      }

      navigate(
        {
          pathname: to.pathname,
          search: mergedSearchParams.toString(),
        },
        options
      );
    },
    [navigate, location]
  );

  return cloudNavigate;
};
