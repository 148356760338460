import { useCallback, useEffect, useRef, useState } from 'react';
import { Raycaster, Vector2, Vector3 } from 'three';

import { CaptureImage } from '@agerpoint/api';
import { Viewer } from '@agerpoint/three-d-viewer';
import {
  Annotation3dPoints,
  CaptureMaterialClassifications,
  CaptureMaterialColorsLookup,
  ClassificationObject,
  EffectNames,
  ICustomMesh,
  IViewer,
  LdFlags,
  MaterialType,
  TogglePointCloudVisibility,
} from '@agerpoint/types';
import {
  createPyramid,
  environment,
  hasPermission,
  useActionListener,
  useGlobalStore,
} from '@agerpoint/utilities';

import './potree.scss';

interface PotreeViewerProps {
  eptId: string;
  setViewer: React.Dispatch<
    React.SetStateAction<
      React.MutableRefObject<IViewer | undefined> | undefined
    >
  >;
  token?: string;
}

export const PotreeViewer = ({
  setViewer,
  eptId,
  token,
}: PotreeViewerProps) => {
  const serverUrl = environment.api_server_url;
  // had to switch to props for token because once instance of the viewer is loaded into
  // a portal and doesnt have auth access
  // const { userData } = useAuth();
  const {
    permissions,
    capturesViewer: {
      pointStyle,
      captureImages: images,
      captureMaterialClassificationColors,
      actions: { setCaptureMaterialClassificationColors },
    },
  } = useGlobalStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const viewer = useRef<Viewer>();
  const [viewerReady, setViewerReady] = useState(false);
  const [hasPrismPermission, setHasPrismPermission] = useState(false);
  const [hasImageGalleryPermission, setHasImageGalleryPermission] =
    useState(false);
  const visibilityToggleCallback = useCallback(
    (payload: TogglePointCloudVisibility) => {
      if (!viewer?.current) return;
      viewer.current.classification.toggleClassificationVisibilityById(
        payload.classification
      );
    },
    []
  );

  useActionListener<TogglePointCloudVisibility>(
    EffectNames.POINT_CLOUD_MATERIAL_VISIBILITY_TOGGLED,
    visibilityToggleCallback
  );

  useEffect(() => {
    const hasPrismPerm = hasPermission(LdFlags.PotreeImagePrism, permissions);
    setHasPrismPermission(hasPrismPerm);
    const hasImageGalleryPerm = hasPermission(
      LdFlags.PotreeAutoScrollGallery,
      permissions
    );
    setHasImageGalleryPermission(hasImageGalleryPerm);
  }, [permissions]);

  useEffect(() => {
    if (!viewer.current || !token || !eptId) {
      return;
    }
    // update the color options when the material changes
    if (pointStyle === MaterialType.CLASSIFICATION) {
      Object.keys(
        captureMaterialClassificationColors as Partial<
          Record<CaptureMaterialClassifications, number[]>
        >
      ).forEach((key) => {
        const keyAsNumber = Number(key) as CaptureMaterialClassifications;
        const colorArr = captureMaterialClassificationColors[keyAsNumber];
        if (!colorArr) return;
        viewer?.current?.classification.updateClassificationColorById(
          key,
          colorArr.color
        );
      });
    }
  }, [pointStyle, captureMaterialClassificationColors, token]);

  useEffect(() => {
    const doAsync = async () => {
      viewer.current?.destroy();
      viewer.current = undefined;
      await initializeViewer();
      setViewerReady(true);
    };
    doAsync();
  }, []);

  useEffect(() => {
    if (!eptId) return;
    const destroy = () => {
      viewer.current?.clear2dPointMarkers();

      viewer.current?.destroy();
      viewer.current = undefined;
    };
    destroy();

    const reInit = async () => {
      await initializeViewer();
      await renderPotree();
    };

    if (viewerReady && +eptId) {
      viewer.current = undefined;
      setViewerReady(false);
      setViewer(viewer);
      if (containerRef?.current?.children?.length) {
        const children = [...containerRef.current.children];
        children.forEach((child: any) => {
          if (!containerRef.current) return;
          containerRef.current.removeChild(child);
        });
      }

      reInit();
    }
    return () => {
      setViewer(undefined);
      destroy();
    };
  }, [eptId, viewerReady]);

  const plotCameraPositions = useCallback(async () => {
    const filteredImages = [...images]?.filter(
      (image: CaptureImage) => image.x && image.y && image.z
    );

    if (!filteredImages?.length || !viewer?.current || !viewerReady) {
      return;
    }

    await viewer.current.removeAllMeshes();
    filteredImages.forEach((image: CaptureImage, i) => {
      const x = image.x ?? undefined;
      const y = image.y ?? undefined;
      const z = image.z ?? undefined;
      const roll = image.roll ?? undefined;
      const pitch = image.pitch ?? undefined;
      const yaw = image.yaw ?? undefined;
      const id = image.id as number;
      if (!x || !y || !z) return;

      if (!viewer.current) return;
      viewer.current.addCameraLocationMarker(
        id.toString(),
        new Vector3(x, y, z)
      );

      if (!roll || !pitch || !yaw || !id) return;
      if (hasPrismPermission) {
        const pyramid = createPyramid(x, y, z, roll, pitch, yaw);
        viewer.current.addPrism(pyramid);
      }
      if (hasImageGalleryPermission) {
        viewer.current.indexImageLocation(id, x, y, z, roll, pitch, yaw);
      }
    });
  }, [
    images,
    viewer,
    viewerReady,
    hasPrismPermission,
    hasImageGalleryPermission,
  ]);

  useEffect(() => {
    if (!viewerReady || !eptId) return;
    plotCameraPositions();
  }, [plotCameraPositions, viewerReady, eptId]);

  useEffect(() => {
    const mouse = new Vector2();
    const raycaster = new Raycaster();
    raycaster.params.Sprite = { threshold: 0.1 };

    const mouseDown = (event: MouseEvent) => {
      if (
        !viewerReady ||
        !containerRef.current ||
        !viewer?.current?.viewer ||
        !viewer?.current?.camera ||
        !event
      ) {
        return;
      }

      // const pnt: {
      //   point: {
      //     position: Vector3;
      //   };
      // } = Potree.Utils.getMousePointCloudIntersection(
      //   event,
      //   viewer?.current?.camera,
      //   viewer?.current?.viewer,
      //   viewer?.current?.viewer.scene?.pointclouds
      // );
      // mousePosition.current = pnt?.point?.position;

      const rect = containerRef.current.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, viewer?.current?.camera);
      if (!viewer?.current?.scene) return;
      const intersects = raycaster.intersectObjects(
        viewer?.current?.scene.children,
        true
      );

      if (!intersects[0]?.object) return;
      const selectedObject: ICustomMesh = intersects[0]?.object as ICustomMesh;
      if (
        selectedObject.customType === Annotation3dPoints.CaptureImageLocation
      ) {
        selectedObject.callback(selectedObject.uniqueId);
      }
    };

    containerRef?.current?.addEventListener('mousedown', mouseDown, false);
    return () => {
      containerRef?.current?.removeEventListener('mousedown', mouseDown, false);
    };
  }, [containerRef, viewerReady]);

  const renderPotree = useCallback(async () => {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'cache-control': 'no-cache',
      pragma: 'no-cache',
    };
    const eptJson = await viewer?.current?.load(
      'ept.json',
      `${serverUrl}/api/EptPointcloud/${eptId}/ept.json`,
      {
        headers,
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
      }
    );

    const schema = eptJson?.pointcloud?.pcoGeometry?.schema;
    if (!schema) return;
    const classification = schema.find((s: any) => s.name === 'Classification');
    if (classification && classification?.counts) {
      const colors = classification.counts.reduce(
        (
          acc: {
            [key in CaptureMaterialClassifications]: ClassificationObject;
          },
          c: { value: CaptureMaterialClassifications }
        ) => {
          acc[c.value] = {
            color: CaptureMaterialColorsLookup[c.value],
            visible: true,
          };
          return acc;
        },
        {}
      );
      setCaptureMaterialClassificationColors(colors);
    }
  }, [eptId, serverUrl, token]);

  const initializeViewer = useCallback(async () => {
    const _viewer = new Viewer(
      permissions,
      containerRef.current as HTMLDivElement
    );
    viewer.current = _viewer;
    await viewer.current.initialize();
    setViewerReady(true);
    setViewer(viewer);
  }, [permissions]);

  return (
    <div className="absolute inset-0 potree_container w-full h-full bg-black">
      <div id="potree_render_area" ref={containerRef} />
    </div>
  );
};
