import { ButtonIcon } from './button-icon';
import { ButtonPrimary } from './button-primary';
import { ButtonSecondary } from './button-secondary';
import { ButtonTertiary } from './button-tertiary';

const CloudButton = {
  Primary: ButtonPrimary,
  Secondary: ButtonSecondary,
  Tertiary: ButtonTertiary,
  Icon: ButtonIcon,
};

export { CloudButton };
