import { useEffect } from 'react';

import { CaptureJobTypes, Sidebar2Subroute } from '@agerpoint/types';

import { useCapturesViewerContext } from '../../../captures-viewer-context';
import { useCapturesViewerQueries } from '../../../captures-viewer-queries';

export const CVS3BaseCapture = ({
  navigateBack,
}: {
  navigateBack: () => void;
}) => {
  const { setSelectedCaptureJob } = useCapturesViewerContext();
  const { captureQuery } = useCapturesViewerQueries({});

  useEffect(() => {
    if (!captureQuery.data) {
      return;
    }

    const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
      return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
    });
    if (lowResJob) {
      setSelectedCaptureJob?.(lowResJob);
    } else {
      navigateBack();
    }
  }, [captureQuery.data]);

  return null;
};

const CVS3BaseCaptureTitle = () => {
  return (
    <div className="flex flex-col">
      <span>LiDAR - Mobile Device</span>
    </div>
  );
};

export const cvs3BaseCaptureFromRootRoute: Sidebar2Subroute = {
  element: (actions) => <CVS3BaseCapture navigateBack={actions.navigateBack} />,
  subroutes: [],
  path: 'analytics-output/base',
  title: <CVS3BaseCaptureTitle />,
};

export const cvs3BaseCaptureFromOutputsRoute: Sidebar2Subroute = {
  element: (actions) => <CVS3BaseCapture navigateBack={actions.navigateBack} />,
  subroutes: [],
  path: 'base',
  title: <CVS3BaseCaptureTitle />,
};
