import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient } from '@agerpoint/api';
import { CaptureJobTypes } from '@agerpoint/types';
import { APIUtils } from '@agerpoint/utilities';

interface IuseCaptureImageGallery {
  captureJob?: APIClient.CaptureJob;
}

export const useCaptureImageGallery = ({
  captureJob,
}: IuseCaptureImageGallery) => {
  const queryClient = useQueryClient();

  const { captureId } = useParams();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIClient.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          id: Number(captureId),
          accessor: 'id',
        }),
    },
  });

  const lowResModel = useMemo(
    () =>
      captureQuery.data?.completedJobs?.find(
        (j) =>
          j.captureJobTypeId === CaptureJobTypes['Low Resolution'] &&
          j.archived === false &&
          j.eptPointcloudId !== null &&
          (j.status === 'Completed' || j.status === '' || j.status === null)
      ),
    [captureQuery.data]
  );

  // Hack: For now, we're trying to get Low Res Camera Poses for Gaussian Splat Models that don't have images
  const lowResCaptureJobImagesQuery =
    APIClient.useGetCaptureImagesByCaptureJobId(Number(lowResModel?.id), {
      query: {
        enabled: Number.isSafeInteger(Number(lowResModel?.id ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(lowResModel?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data
            ?.filter((image) => image.x && image.y && image.z && image.id)
            ?.sort((a, b) => compare(a?.id, b?.id)),
      },
    });

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(captureJob?.id),
    {
      query: {
        queryFn: async () => {
          let images: APIClient.CaptureImage[] = [];
          try {
            images = await APIClient.getCaptureImagesByCaptureJobId(
              Number(captureJob?.id)
            );
          } catch (e) {
            //
          }

          if (images.length > 0) {
            return images;
          }

          if (!lowResModel?.id) {
            return [];
          }

          images = await APIClient.getCaptureImagesByCaptureJobId(
            Number(lowResModel?.id)
          );
          return images;
        },
        enabled: Number.isSafeInteger(Number(captureJob?.id ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(captureJob?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data
            ?.filter((image) => image.x && image.y && image.z && image.id)
            ?.sort((a, b) => compare(a?.id, b?.id)),
      },
    }
  );

  return {
    captureJobImagesQuery,
    lowResCaptureJobImagesQuery,
  };
};
