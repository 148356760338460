import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CaptureObjectCustomAttribute } from '@agerpoint/api';
import { convertAttributeValueToUIFriendly } from '@agerpoint/utilities';

import { DatatableOld } from '../../datatable/datatable-old';

export const getCaptureViewerModal = ({
  name,
  attributes,
  color,
  handleCloseModal,
}: {
  name: string | undefined | null;
  attributes: CaptureObjectCustomAttribute[] | null | undefined;
  handleCloseModal: () => void;
  color?: string;
}) => {
  return (
    <div
      className="relative flex items-center"
      style={{
        marginLeft: '10px',
      }}
    >
      <div
        className="absolute m-y-auto w-4 h-4 rotate-45 rounded-bl-sm bg-green border border-white"
        style={{
          left: '-7px',
          backgroundColor: color ?? undefined,
        }}
      ></div>
      <div
        className={`absolute flex flex-col bg-gray-50 overflow-hidden
        rounded-lg z-10 border border-white shadow`}
        style={{
          width: '320px',
          height: '350px',
        }}
      >
        <div
          style={{ height: '30px', backgroundColor: color ?? undefined }}
          className="w-full bg-green flex justify-between items-center text-white p-1"
        >
          <div className="h-full" style={{ aspectRatio: '1/1' }}></div>
          <span className="truncate">{name}</span>
          <div
            className={`h-full flex items-center justify-center
          cursor-pointer bg-white bg-opacity-0 hover:bg-opacity-25 rounded`}
            style={{ aspectRatio: '1/1' }}
            onClick={() => {
              handleCloseModal();
            }}
          >
            <FontAwesomeIcon
              icon={faClose}
              className="w-full h-full text-white"
            />
          </div>
        </div>
        <DatatableOld
          data={attributes ?? []}
          style={{
            tableWrapperStyle: 'text-xs',
            headerWrapperStyle:
              'text-gray-700 font-normal border-b border-gray-500',
            rowWrapperStyle: 'items-center hover:bg-gray-100',
            rowStyle: 'border-b border-gray-200',
            headerStyle: 'pr-1 px-1 py-1 h-full flex items-center',
            cellStyle: 'pr-1 px-1 flex items-center',
          }}
          columns={[
            {
              label: 'Attribute Name',
              value: (a: CaptureObjectCustomAttribute) =>
                a.attributeDisplayName ?? a.attributeName,
            },
            {
              label: 'Value',
              value: (a: CaptureObjectCustomAttribute) =>
                convertAttributeValueToUIFriendly(a),
              style: {
                columnWrapperStyle: 'flex justify-end',
              },
            },
          ]}
          rowHeight={20}
        />
      </div>
    </div>
  );
};
