import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import {
  CaptureCustomAttribute,
  CaptureObject,
  CaptureObjectCustomAttribute,
  User,
  useGetCaptureObjectsByCaptureId,
} from '@agerpoint/api';
import { PrimaryButton } from '@agerpoint/component';
import { CaptureObjectMarkerType, UserClaims } from '@agerpoint/types';
import {
  getSymbol,
  getUpdatedCaptureMarkers,
  hasClaims,
  useGlobalStore,
} from '@agerpoint/utilities';

interface AttributeTableProps {
  captureId: number;
  viewer: any;
  smallEditButton?: boolean;
  showEditModal: boolean;
  setShowEditModal: (show: boolean) => void;
}

export const ObjectAttributeTableComponent = ({
  captureId,
  viewer,
  smallEditButton,
  showEditModal,
  setShowEditModal,
}: AttributeTableProps) => {
  const {
    captureObjectMarkers,
    actions: { setCaptureObjectMarkers, setCaptureObjectWindowOpen },
    user,
  } = useGlobalStore();
  const { data: captureObjectsData } = useGetCaptureObjectsByCaptureId({
    id: captureId,
  });

  const [tableData, setTableData] = useState<CaptureCustomAttribute[]>([]);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isQaqc, setIsQaqc] = useState<boolean>(false);

  useEffect(() => {
    if (captureObjectsData) {
      const captureObjs =
        captureObjectsData as unknown as CaptureCustomAttribute[];
      setTableData(
        captureObjs.sort(
          (a: CaptureCustomAttribute, b: CaptureCustomAttribute) =>
            (a?.id || 0) - (b?.id || 0)
        )
      );
      const captureObjectMarkersFromApi = getUpdatedCaptureMarkers(
        captureObjs,
        captureObjectMarkers
      );
      setCaptureObjectMarkers(captureObjectMarkersFromApi);
    }
  }, [captureObjectsData]);

  useEffect(() => {
    const hasClaim = hasClaims(
      [UserClaims.AgerAdmin],
      (user?.cloudClaims || []) as UserClaims[]
    );
    setIsAdmin(hasClaim);
    const hasQaqcClaim = hasClaims(
      [UserClaims.QAQC],
      (user?.cloudClaims || []) as UserClaims[]
    );
    setIsQaqc(hasQaqcClaim);
  }, [user]);

  const showEditableAttributeTable = () => {
    setCaptureObjectWindowOpen(false);
    setShowEditModal(true);
  };

  return (
    <div className="flex w-full h-full flex-col justify-between">
      <div className="flex-grow overflow-y-auto ">
        <table className="table-auto w-full ">
          <ObjectAttributeTableHeader />
          <tbody>
            {tableData
              .filter(
                (item: CaptureObject) =>
                  item.captureObjectCustomAttributes !== null
              )
              .map((item: CaptureObject) => {
                return (
                  <ObjectAttributeTableRows
                    key={item.id}
                    objectAttributes={item.captureObjectCustomAttributes || []}
                    objectName={item.name || ''}
                  />
                );
              })}
          </tbody>
        </table>
      </div>

      {isAdmin || isQaqc ? (
        <div className="flex w-full pt-1">
          <PrimaryButton
            onClicked={showEditableAttributeTable}
            label="Edit"
            icon={<FontAwesomeIcon icon={faEdit} />}
            size="x-small"
          />
        </div>
      ) : (
        <div className="w-full mt-4"></div>
      )}
    </div>
  );
};

const ObjectAttributeTableRows = ({
  objectAttributes,
  objectName,
}: {
  objectAttributes: CaptureObjectCustomAttribute[];
  objectName: string;
}) => {
  const [rows, setRows] = useState<CaptureObjectCustomAttribute[]>([]);

  useEffect(() => {
    setRows(objectAttributes);
  }, [objectAttributes]);

  return (
    <>
      {rows.map((item: CaptureObjectCustomAttribute) => {
        return (
          <tr key={item.id}>
            <td className="text-sm truncate">{objectName}</td>
            <td className="text-sm truncate">
              {item.attributeDisplayName ?? item.attributeName}
            </td>
            <td className="text-sm truncate">{item.attributeValue}</td>
            <td className="text-sm truncate">{getSymbol(item)}</td>
          </tr>
        );
      })}
    </>
  );
};

const ObjectAttributeTableHeader = () => {
  return (
    <thead>
      <tr>
        <th className="text-left">ID</th>
        <th className="text-left">Attribute</th>
        <th className="text-left">Value</th>
        <th className="text-left">Unit</th>
      </tr>
    </thead>
  );
};
