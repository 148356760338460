import { faCircleNotch, faRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@agerpoint/component';
import { IImageViewer, IImageViewerController } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const ImageViewer = ({ controller: setController }: IImageViewer) => {
  const [url, setUrl] = useState<string>();

  const [viewerReady, setViewerReady] = useState<boolean>(false);

  const loadImage = useCallback((url: string) => {
    setUrl(url);
  }, []);

  const clearImage = useCallback(() => {
    setUrl(undefined);
  }, []);

  const {
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();

  const controller: IImageViewerController = useMemo(
    () => ({
      info: {
        viewerReady,
      },
      loadImage,
      clearImage,
    }),
    [loadImage, clearImage, viewerReady]
  );

  useEffect(() => {
    setViewerReady(true);
    return () => {
      setViewerReady(false);
    };
  }, []);

  useEffect(() => {
    setController?.(controller);
  }, [controller, setController]);

  const [clicked, setClicked] = useState(false);
  const [zoom, setZoom] = useState(0.5);
  const [rotate, setRotate] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const onWheel = useCallback((e: WheelEvent) => {
    e.preventDefault();
    setZoom((prev) => {
      const newZoom = prev - e.deltaY / 100;
      if (newZoom < 0.5) return 0.5;
      if (newZoom > 3) return 3;
      return newZoom;
    });
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    container.addEventListener('wheel', onWheel, {
      passive: false,
    });

    return () => {
      container.removeEventListener('wheel', onWheel);
    };
  }, [onWheel]);

  return (
    <div className="w-full h-full relative" ref={containerRef}>
      <div
        className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
          sidebarOpen ? 'pl-sidebar' : ''
        }`}
        style={{
          background: 'radial-gradient(#1b292f, #0c1417)',
        }}
      >
        {url === undefined ? (
          <div className="flex flex-row gap-2">
            <span>Loading Image...</span>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <motion.div
            drag
            dragMomentum={false}
            initial={{
              scale: 0.5,
            }}
            animate={{
              scale: zoom,
              rotate: rotate,
              transition: {
                rotate: {
                  type: 'spring',
                  damping: 30,
                  stiffness: 500,
                  duration: 0.3,
                },
              },
            }}
            className={`flex-center rounded-lg overflow-hidden shadow-lg ${
              clicked ? 'cursor-grabbing' : 'cursor-grab'
            }`}
            onPointerDown={() => setClicked(true)}
            onPointerUp={() => setClicked(false)}
          >
            <img
              src={url}
              className="size-full pointer-events-none"
              crossOrigin="anonymous"
              alt="Capture Viewer"
            />
          </motion.div>
        )}
      </div>
      <div className="absolute bottom-4 right-4">
        <div className="bg-white rounded-full p-2">
          <Button.Icon
            id="rotate"
            icon={faRotate}
            onClick={() => {
              setRotate((prev) => prev + 90);
            }}
          />
        </div>
      </div>
    </div>
  );
};
