import { User } from '@agerpoint/api';
import { Intermediate } from '@agerpoint/feature';
import { AppBarProps, NewUploadProps } from '@agerpoint/types';
import {
  getAppBarProps,
  useGlobalStore,
  usePageTitle,
} from '@agerpoint/utilities';

import { NewUploadPage } from './new-upload-page';

export const NewUpload = ({ bingKey }: NewUploadProps) => {
  usePageTitle(() => 'New Upload', []);

  const { user } = useGlobalStore();

  const appBarProps: AppBarProps = getAppBarProps(user || ({} as User));

  if (!user) {
    return <Intermediate message="Getting account information ..." />;
  }

  return <NewUploadPage appBar={appBarProps} />;
};
