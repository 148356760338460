import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Vector2 } from 'three';
import compare from 'trivial-compare';

import { APIClient } from '@agerpoint/api';
import { CloudButton, CloudInput } from '@agerpoint/cloud/components';
import {
  CaptureJobTypes,
  IGs3dViewerController,
  IGsCloudToolState,
} from '@agerpoint/types';
import { APIUtils, useAgerStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../captures-viewer';

interface IGS3DCloudTools {
  viewerController?: IGs3dViewerController;
}

export const Gs3DCloudTools = ({ viewerController }: IGS3DCloudTools) => {
  const {
    gsCloudToolState,
    setGsCloudToolState,
    annotations3dGeometry,
    selectedCaptureJob,
  } = useCapturesViewerContext();
  const [toggleAll3dAnnotations, setToggleAll3dAnnotations] = useState(true);
  const { isMobile } = useAgerStore();

  const annotations = useMemo(() => {
    return [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
    ];
  }, [annotations3dGeometry]);

  useEffect(() => {
    const allNotVisible = annotations.every(
      (annotation) => !annotation.isVisible
    );
    if (allNotVisible) {
      setToggleAll3dAnnotations(!allNotVisible);
    } else {
      setToggleAll3dAnnotations(true);
    }
  }, [annotations]);

  const { captureId } = useParams();

  // Hack: For now, we're trying to get Low Res Camera Poses for Gaussian Splat Visualization
  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      enabled: Number.isSafeInteger(Number(captureId)),
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
    },
  });

  const lowResModel = useMemo(
    () =>
      captureQuery.data?.completedJobs?.find(
        (j) =>
          j.captureJobTypeId === CaptureJobTypes['Low Resolution'] &&
          j.archived === false &&
          j.eptPointcloudId !== null &&
          (j.status === 'Completed' || j.status === '' || j.status === null)
      ),
    [captureQuery.data]
  );

  const lowResCaptureJobImagesQuery =
    APIClient.useGetCaptureImagesByCaptureJobId(Number(lowResModel?.id), {
      query: {
        enabled: Number.isSafeInteger(Number(lowResModel?.id ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(lowResModel?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data
            ?.filter((image) => image.x && image.y && image.z && image.id)
            ?.sort((a, b) => compare(a?.id, b?.id)),
      },
    });

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(selectedCaptureJob?.id),
    {
      query: {
        enabled: Number.isSafeInteger(
          Number(selectedCaptureJob?.id ?? undefined)
        ),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(selectedCaptureJob?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data
            ?.filter((image) => image.x && image.y && image.z && image.id)
            ?.sort((a, b) => compare(a?.id, b?.id)),
      },
    }
  );

  const hasImages = useMemo(() => {
    if ((captureJobImagesQuery.data?.length ?? 0) > 0) {
      return true;
    }

    if ((lowResCaptureJobImagesQuery.data?.length ?? 0) > 0) {
      return true;
    }

    return false;
  }, [lowResCaptureJobImagesQuery.data, captureJobImagesQuery.data]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady || !captureQuery.data) {
      return;
    }

    viewerController?.setCaptureMetadata(captureQuery.data);
  }, [captureQuery.data]);

  const loadCameraPositions = useCallback(() => {
    let captureJobImages: APIClient.CaptureImage[] | undefined = undefined;
    if (captureJobImagesQuery.data === undefined) {
      return;
    } else if (captureJobImagesQuery.data.length > 0) {
      captureJobImages = captureJobImagesQuery.data;
    } else if (lowResCaptureJobImagesQuery.data === undefined) {
      return;
    } else if (lowResCaptureJobImagesQuery.data.length === 0) {
      return;
    } else {
      captureJobImages = lowResCaptureJobImagesQuery.data;
    }
    viewerController?.loadCameraPositions(captureJobImages);
  }, [captureJobImagesQuery.data, lowResCaptureJobImagesQuery.data]);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !viewerController?.info?.captureMetadata
    ) {
      return;
    }
    loadCameraPositions();
  }, [
    lowResCaptureJobImagesQuery.data,
    captureJobImagesQuery.data,
    viewerController?.info?.viewerReady,
    viewerController?.info?.captureMetadata,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }
    if (viewerController?.info?.cameraPositionsVisible) {
      viewerController?.threeViewer?.showImageMarkers();
    } else {
      viewerController?.threeViewer?.hideImageMarkers();
    }
  }, [loadCameraPositions]);

  const updateToolState = useCallback(
    (key: keyof IGsCloudToolState, value: boolean | Vector2) => {
      if (!viewerController?.info?.viewerReady) return;
      const newState = {
        ...gsCloudToolState,
        [key]: value,
      };
      setGsCloudToolState?.(newState);
    },
    [setGsCloudToolState, gsCloudToolState, viewerController]
  );

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      <div
        className={`bg-white shadow-lg rounded-lg flex flex-row p-1 items-center`}
      >
        {isMobile ? (
          <CloudButton.Icon
            id="camera-positions-toggle"
            onClick={() => {
              viewerController?.setCameraPositionsVisible?.(
                !viewerController.info.cameraPositionsVisible
              );
            }}
            disabled={!hasImages}
            toggled={
              (viewerController?.info.cameraPositionsVisible ?? false) &&
              hasImages
            }
            leadingIcon="camera"
            tooltip="Toggle Camera Positions"
            tooltipPosition="bottom"
          />
        ) : (
          <CloudInput.Toggle
            id="camera-positions-toggle"
            disabled={!hasImages}
            value={
              (viewerController?.info.cameraPositionsVisible ?? false) &&
              hasImages
            }
            setValue={(value) => {
              if (!hasImages) {
                return;
              }

              viewerController?.setCameraPositionsVisible?.(
                !viewerController.info.cameraPositionsVisible
              );
            }}
            leadingIcon="camera"
            highlighted={
              (viewerController?.info.cameraPositionsVisible ?? false) &&
              hasImages
            }
          />
        )}
      </div>
      <div
        className={
          'flex flex-row cursor-pointer bg-white rounded-lg px-1 gap-1 items-center shadow-lg'
        }
      >
        {isMobile ? (
          <CloudButton.Icon
            id="annotations-toggle"
            toggled={toggleAll3dAnnotations}
            onClick={() => {
              setToggleAll3dAnnotations(!toggleAll3dAnnotations);
              viewerController?.toggleAll3dAnnotations?.(
                !toggleAll3dAnnotations
              );
            }}
            leadingIcon="location-dot"
            tooltip="Toggle Annotations"
            tooltipPosition="bottom"
            disabled={!annotations?.length}
          />
        ) : (
          <CloudInput.Toggle
            id="annotations-toggle"
            disabled={!annotations?.length}
            value={toggleAll3dAnnotations}
            setValue={(value) => {
              setToggleAll3dAnnotations(value);
              viewerController?.toggleAll3dAnnotations?.(value);
            }}
            highlighted={toggleAll3dAnnotations}
            leadingIcon="location-dot"
          />
        )}
      </div>

      <div
        className={
          'flex flex-row bg-white rounded-lg px-1 gap-1 items-center shadow-lg'
        }
      >
        {isMobile ? (
          <>
            <CloudButton.Icon
              id="model-material-toggle-gaussian-splats"
              toggled={gsCloudToolState?.showSplats}
              onClick={() => {
                updateToolState('showSplats', true);
              }}
              // disabled={!gsModelLoaded}
              leadingIcon="cubes"
              tooltip="Gaussian"
              tooltipPosition="bottom"
            />
            <CloudButton.Icon
              id="model-material-toggle-gaussian-points"
              toggled={!gsCloudToolState?.showSplats}
              onClick={() => {
                updateToolState('showSplats', false);
              }}
              // disabled={!gsModelLoaded}
              leadingIcon="chart-scatter-3d"
              tooltip="Point Cloud"
              tooltipPosition="bottom"
            />
          </>
        ) : (
          <>
            <CloudButton.Icon
              id="model-material-toggle-gaussian-splats"
              toggled={gsCloudToolState?.showSplats}
              onClick={() => {
                updateToolState('showSplats', true);
              }}
              // disabled={!gsModelLoaded}
              leadingIcon="cubes"
              label={'Gaussian'}
            />
            <CloudButton.Icon
              id="model-material-toggle-gaussian-points"
              toggled={!gsCloudToolState?.showSplats}
              onClick={() => {
                updateToolState('showSplats', false);
              }}
              // disabled={!gsModelLoaded}
              leadingIcon="chart-scatter-3d"
              label="Point Cloud"
            />
          </>
        )}
      </div>
      <div
        className={
          'flex flex-row bg-white rounded-lg px-1 gap-1 items-center shadow-lg'
        }
      >
        <CloudButton.Icon
          id="model-crop-button"
          onClick={() => {
            viewerController?.toggleCropEditingTool();
          }}
          disabled={!viewerController?.info?.sceneLoaded}
          leadingIcon="crop"
          tooltip="Crop"
        />
      </div>
    </div>
  );
};
