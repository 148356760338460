import { Customer, User, useGetCustomer } from '@agerpoint/api';
import { AppBarProps } from '@agerpoint/types';
import { getAppBarProps, useGlobalStore } from '@agerpoint/utilities';

import { ProjectsPage } from './projects-page';

export function Projects() {
  const { user } = useGlobalStore();
  const { data: customers } = useGetCustomer({});
  const appBarProps: AppBarProps = getAppBarProps(user || ({} as User));

  return (
    <ProjectsPage
      customers={customers ? (customers as Customer[]) : []}
      appBar={appBarProps}
    />
  );
}
