import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import { GsThreeDViewer, Viewer } from '@agerpoint/three-d-viewer';
import { FrameEvent } from '@agerpoint/types';
import { GaussianSplats3D } from '@agerpoint/utilities';

import { useViewerControllerSharedQueries } from './viewer-controller-shared.queries';

type ViewerRef = React.MutableRefObject<
  | {
      splatsViewer?: GaussianSplats3D.Viewer;
      threeViewer?: GsThreeDViewer | Viewer;
    }
  | undefined
>;

export const useToggleCropEditingTool = (
  viewerRef: ViewerRef,
  viewerReady: boolean,
  cropBoxEditingActive: boolean,
  setCropBoxEditingActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return useCallback(() => {
    if (!viewerReady) {
      return;
    }

    if (cropBoxEditingActive) {
      viewerRef.current?.threeViewer?.hideCropBoxEditTool();
      setCropBoxEditingActive(false);
    } else {
      viewerRef.current?.threeViewer?.showCropBoxEditTool();
      setCropBoxEditingActive(true);
    }
  }, [viewerRef, viewerReady, cropBoxEditingActive, setCropBoxEditingActive]);
};

export const useResetCropEditingTool = (
  viewerRef: ViewerRef,
  viewerReady: boolean,
  captureJob: APIClient.CaptureJob | undefined,
  setCropBoxEditingActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return useCallback(() => {
    if (!viewerReady) {
      return;
    }

    const existingCropBox = JSON.parse(captureJob?.cropbox || '{}');
    viewerRef.current?.threeViewer?.resetEditTool([
      existingCropBox?.frame?.min,
      existingCropBox?.frame?.max,
    ]);
    setCropBoxEditingActive(false);
  }, [viewerRef, viewerReady, captureJob, setCropBoxEditingActive]);
};

export const useDestroyCroppingTool = (
  viewerRef: ViewerRef,
  viewerReady: boolean,
  captureJob: APIClient.CaptureJob | undefined,
  setCropBoxEditingActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { captureJobPutMutation } = useViewerControllerSharedQueries({
    captureJob,
  });

  const { captureId } = useParams();

  return useCallback(async () => {
    if (!viewerReady || !captureJob?.id || !captureId) {
      return;
    }
    const newJob = {
      ...captureJob,
      cropbox: null,
    };
    await captureJobPutMutation.mutateAsync({
      captureId: captureId,
      jobId: captureJob.id,
      data: newJob,
    });
    viewerRef.current?.threeViewer?.resetEditTool(undefined);
    setCropBoxEditingActive(false);
  }, [viewerReady, setCropBoxEditingActive, captureJob, captureId]);
};

export const useFinishCropEditingTool = (
  viewerRef: ViewerRef,
  viewerReady: boolean,
  frame: FrameEvent | null,
  captureJob: APIClient.CaptureJob | undefined,
  setCropBoxEditingActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { captureJobPutMutation } = useViewerControllerSharedQueries({
    captureJob,
  });
  const { captureId } = useParams();

  return useCallback(async () => {
    if (!viewerReady || !frame || !captureJob?.id || !captureId) {
      return;
    }
    const newJob = {
      ...captureJob,
      cropbox: JSON.stringify(frame),
    };

    await captureJobPutMutation.mutateAsync({
      captureId: captureId,
      jobId: captureJob.id,
      data: newJob,
    });

    viewerRef.current?.threeViewer?.hideCropBoxEditTool();
    setCropBoxEditingActive(false);
  }, [viewerReady, setCropBoxEditingActive, frame, captureJob, captureId]);
};

export const useStartCropEditingTool = (
  viewerRef: ViewerRef,
  viewerReady: boolean,
  setCropBoxEditingActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return useCallback(() => {
    if (!viewerReady) {
      return;
    }

    setCropBoxEditingActive(true);
  }, [viewerReady, setCropBoxEditingActive]);
};
