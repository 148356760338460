import { APIClient } from '@agerpoint/api';

import { getSymbol } from '..';

/**
 *
 * @param value length in meters
 * @returns string formatted length in readable format
 */
export const formatLength = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  let unit = 'm';
  let length = value;
  if (value < 0.01) {
    unit = 'mm';
    length = value * 1000;
  } else if (value < 1) {
    unit = 'cm';
    length = value * 100;
  } else if (value >= 1000) {
    unit = 'km';
    length = value / 1000;
  }

  return `${length.toFixed(2)} ${unit}`;
};

/**
 *
 * @param value area in square meters
 * @returns string formatted area in readable format
 */
export const formatArea = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const squared = '\u00B2';
  let unit = 'm';
  let area = value;

  if (value < 0.0001) {
    unit = 'mm';
    area = value * 1000000;
  } else if (value < 1) {
    unit = 'cm';
    area = value * 10000;
  } else if (value >= 1000000) {
    unit = 'km';
    area = value / 1000000;
  }

  return `${area.toFixed(2)} ${unit}${squared}`;
};

/**
 *
 * @param value volume in cubic meters
 * @returns string formatted volume in readable format
 */
export const formatVolume = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const cubed = '\u00B3';
  let unit = 'm';
  let volume = value;

  if (value < 0.000001) {
    unit = 'mm';
    volume = value * 1000000000;
  } else if (value < 1) {
    unit = 'cm';
    volume = value * 1000000;
  } else if (value >= 1000000000) {
    unit = 'km';
    volume = value / 1000000000;
  }

  return `${volume.toFixed(2)} ${unit}${cubed}`;
};

export const formatAttribute = (
  attr: APIClient.CaptureCustomAttribute | null | undefined
) => {
  if (!attr) {
    return '';
  }

  if (attr.customAttributeUnitId === 2) {
    return formatLength(Number(attr.attributeValue));
  }

  if (attr.customAttributeUnitId === 4) {
    return formatArea(Number(attr.attributeValue));
  }

  if (attr.customAttributeUnitId === 5) {
    return formatVolume(Number(attr.attributeValue));
  }

  const value = attr.attributeValue;

  if (!value) {
    return '';
  }

  if (Number.isNaN(+value)) {
    return value;
  }

  const decimalPlaces = 4;

  const userFriendlyValue = (
    Math.round(+value * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  )
    .toString()
    .replace(/(\.\d*?[1-9])0+$/, '$1')
    .replace(/\.$/, '');

  return `${userFriendlyValue} ${getSymbol(attr)}`;
};
