import {
  faDownload,
  faPlayCircle,
  faTable,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCamera,
  faCircleNotch,
  faFileExport,
  faLocationDot,
  faPaintBrush,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCaptureViewerModal } from 'libs/feature/src/captures-viewer/capture-viewer-modal/capture-viewer-modal';
import { useCapturesViewerContext } from 'libs/feature/src/captures-viewer/captures-viewer-context';
import { useCapturesViewerQueries } from 'libs/feature/src/captures-viewer/captures-viewer-queries';
import { ColorPicker2 } from 'libs/feature/src/color-picker2/color-picker2';
import { Sidebar2Section } from 'libs/feature/src/sidebar2';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';

import { CaptureCustomAttribute, CaptureImage } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import {
  AnalyticRequestStatus,
  CaptureJobTypes,
  IPotreeViewerController,
  LdFlags,
  MaterialType,
  PotreeViewer2ControllerInfoClassificationProps,
  Sidebar2Subroute,
} from '@agerpoint/types';
import {
  createFilename,
  cvtRgbaArrToRgbaStr,
  cvtRgbaPctToStdRgba,
  getBestCaptureJob,
  getSymbol,
  hasPermission,
  isGaussianJob,
  markerColorPalette,
  useGlobalStore,
} from '@agerpoint/utilities';

import { CVS3ObjectsList } from '../../../shared/objects-list/cvs3-objects-list';

interface DataExport {
  attribute: CaptureCustomAttribute;
  relatedEntity: string;
}

export const CVS3AnalyticsOutputDetails = ({
  navigateBack,
}: {
  navigateBack: () => void;
}) => {
  const [lowResJobId, setLowResJobId] = useState<number>();
  const [captureJobImages, setCaptureJobImages] = useState<CaptureImage[]>([]);
  const [cameraPositionsVisibleLocal, setCameraPositionsVisibleLocal] =
    useState(false);

  const [cameraPositionsAreReady, setCameraPositionsAreReady] = useState(false);
  const { analyticRequestId } = useParams();
  const { permissions } = useGlobalStore();

  const { selectedCaptureJob, setSelectedCaptureJob, viewerController } =
    useCapturesViewerContext();
  const {
    analyticRequestsWithCaptureObjectsAndJobsAllQuery,
    analyticRequestQuery,
    analyticRequestsQuery,
    captureJobImagesQuery,
    captureQuery,
    customCaptureAttributesForAnalyticRequestQuery,
    captureVideoByAnalyticRequestQuery,
    captureJobImagesForLowResJobIdQuery,
  } = useCapturesViewerQueries({
    selectedCaptureJob,
    lowResJobId,
  });

  const dataToExport = useMemo(() => {
    const analyticRequestsWithCaptureObjectsAndJobsQuery =
      analyticRequestsWithCaptureObjectsAndJobsAllQuery.find(
        (q) => q.data?.analyticRequest?.id === Number(analyticRequestId)
      );

    if (!analyticRequestsWithCaptureObjectsAndJobsQuery) {
      return;
    }

    if (
      analyticRequestsWithCaptureObjectsAndJobsQuery.isLoading ||
      customCaptureAttributesForAnalyticRequestQuery.isLoading
    ) {
      return undefined;
    }

    let result: DataExport[] = [];
    for (const captureObject of analyticRequestsWithCaptureObjectsAndJobsQuery
      .data?.captureObjects ?? []) {
      result = [
        ...result,
        ...(captureObject.captureObjectCustomAttributes?.map((attr) => ({
          attribute: attr,
          relatedEntity: captureObject.name ?? '',
        })) ?? []),
      ];
    }

    result = [
      ...result,
      ...(customCaptureAttributesForAnalyticRequestQuery.data?.map((attr) => ({
        attribute: attr,
        relatedEntity:
          analyticRequestsWithCaptureObjectsAndJobsQuery?.data?.analyticRequest
            ?.customerAnalytic?.analytic?.analyticName ?? '',
      })) ?? []),
    ];

    return result;
  }, [
    analyticRequestsWithCaptureObjectsAndJobsAllQuery,
    customCaptureAttributesForAnalyticRequestQuery,
  ]);

  const exportData = useCallback(() => {
    const header = 'Context,Attribute Name,Attribute Value,Attribute Unit\n';
    const content = dataToExport
      ?.map(
        (data) =>
          `${data.relatedEntity},${
            data.attribute.displayName ?? data.attribute.attributeName
          },${data.attribute.attributeValue},${getSymbol(data.attribute, true)}`
      )
      .join('\n');
    const csvString = header + content;

    const analyticRequestName =
      analyticRequestQuery.data?.customerAnalytic?.analytic?.analyticName
        ?.toLowerCase()
        ?.split(' ')
        ?.join('_');

    const fileName = `${analyticRequestName}_analytic_output`;
    const file = new File([csvString], createFilename(fileName, 'csv'), {
      type: 'text/csv;charset=utf-8',
    });
    const url = URL.createObjectURL(file);

    const a = document.createElement('a');
    a.href = url;
    a.download = createFilename(fileName, 'csv');
    a.type = 'text/csv;charset=UTF-8';
    a.click();
  }, [dataToExport, analyticRequestQuery.data]);

  useEffect(() => {
    if (analyticRequestQuery.data === undefined) {
      return;
    }

    if (analyticRequestQuery.data?.status !== AnalyticRequestStatus.COMPLETE) {
      navigateBack();
    }
  }, [analyticRequestQuery.data?.status]);

  const hasAPFGeometryPermission = useMemo(
    () => hasPermission(LdFlags.CaptureObjectApfGeometry, permissions),
    []
  );

  const analyticRequestWithCaptureObjectAndJobsQuery = useMemo(() => {
    return analyticRequestsWithCaptureObjectsAndJobsAllQuery.find(
      (q) => q.data?.analyticRequest?.id === Number(analyticRequestId)
    );
  }, [analyticRequestsWithCaptureObjectsAndJobsAllQuery, analyticRequestId]);

  useEffect(() => {
    viewerController?.potreeController?.bindObjectOnClick((e: CustomEvent) => {
      const captureObjectIndex =
        analyticRequestWithCaptureObjectAndJobsQuery?.data?.captureObjects.findIndex(
          (o) => o.id === Number(e.detail.id)
        );

      if (captureObjectIndex === -1 || captureObjectIndex === undefined) {
        return;
      }

      const captureObject =
        analyticRequestWithCaptureObjectAndJobsQuery?.data?.captureObjects[
          captureObjectIndex
        ];

      const x = captureObject?.x;
      const y = captureObject?.y;
      const z = captureObject?.z;

      if (!x || !y || !z) {
        return;
      }

      const color =
        markerColorPalette[captureObjectIndex % markerColorPalette.length];

      const modal = getCaptureViewerModal({
        name: captureObject?.name,
        attributes: captureObject?.captureObjectCustomAttributes,
        color: color,
        handleCloseModal: () => {
          viewerController?.potreeController?.removeInfoModal();
        },
      });

      viewerController?.potreeController?.addInfoModal({
        element: modal,
        loc: {
          x,
          y,
          z,
        },
      });
    });
    return () => {
      viewerController?.potreeController?.bindObjectOnClick(undefined);
    };
  }, [
    viewerController?.potreeController?.info.viewerReady,
    analyticRequestWithCaptureObjectAndJobsQuery,
  ]);

  useEffect(() => {
    viewerController?.potreeController?.removeObjects();

    if (!selectedCaptureJob) {
      return;
    }

    if (isGaussianJob(selectedCaptureJob)) {
      return;
    }

    for (const captureObject of analyticRequestWithCaptureObjectAndJobsQuery
      ?.data?.captureObjects ?? []) {
      viewerController?.potreeController?.addObject({
        captureObject,
        clickable: true,
        includeApfGeometry: hasAPFGeometryPermission,
      });
    }
  }, [
    analyticRequestWithCaptureObjectAndJobsQuery,
    viewerController?.potreeController?.info.viewerReady,
    hasAPFGeometryPermission,
  ]);

  useEffect(() => {
    return () => {
      viewerController?.potreeController?.removeObjects();
    };
  });

  useEffect(() => {
    if (
      analyticRequestWithCaptureObjectAndJobsQuery === undefined ||
      analyticRequestWithCaptureObjectAndJobsQuery?.isLoading ||
      analyticRequestQuery.isLoading
    ) {
      setSelectedCaptureJob?.(undefined);
      return;
    }

    const doAsync = async () => {
      const bestModelForThisAnalytic = await getBestCaptureJob({
        analyticRequests: analyticRequestsQuery.data,
        specificAnalyticRequestId: Number(analyticRequestId),
      });
      setSelectedCaptureJob?.(bestModelForThisAnalytic ?? null);
    };
    doAsync();
  }, [
    analyticRequestWithCaptureObjectAndJobsQuery?.data,
    analyticRequestsQuery?.data,
  ]);

  useEffect(() => {
    const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
      return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
    });
    if (!lowResJob || !lowResJob?.id || !captureQuery?.data?.id) return;
    const lowResJobId = lowResJob.id;
    setLowResJobId(lowResJobId);
  }, [captureQuery?.data]);

  useEffect(() => {
    if (isGaussianJob(selectedCaptureJob)) {
      setCaptureJobImages(captureJobImagesForLowResJobIdQuery.data ?? []);
    } else {
      setCaptureJobImages(captureJobImagesQuery.data ?? []);
    }
  }, [
    selectedCaptureJob,
    captureJobImagesForLowResJobIdQuery.data,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    if (isGaussianJob(selectedCaptureJob)) {
      const isPending =
        captureJobImagesForLowResJobIdQuery.isLoading ||
        !viewerController?.threeController?.info?.cameraPositionsLoaded;
      setCameraPositionsAreReady(!isPending);
    } else {
      const isPending = captureJobImagesQuery.isLoading;
      setCameraPositionsAreReady(!isPending);
    }
  }, [
    selectedCaptureJob,
    viewerController,
    captureJobImagesQuery.isLoading,
    captureJobImagesForLowResJobIdQuery.isLoading,
  ]);

  const videoRef = useRef<HTMLVideoElement>(null);

  const toggleCameraPositionsVisible = useCallback(() => {
    if (isGaussianJob(selectedCaptureJob)) {
      const isVisible =
        !viewerController?.threeController?.info?.cameraPositionsVisible;
      viewerController?.threeController?.setCameraPositionsVisible(isVisible);
      setCameraPositionsVisibleLocal(isVisible);
    } else {
      const isVisible =
        !viewerController?.potreeController?.info.cameraPositionsVisible;
      viewerController?.potreeController?.setCameraPositionsVisible(isVisible);
      setCameraPositionsVisibleLocal(isVisible);
    }
  }, [viewerController, selectedCaptureJob]);

  if (
    analyticRequestWithCaptureObjectAndJobsQuery?.isLoading ||
    !analyticRequestWithCaptureObjectAndJobsQuery?.data
  ) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-y-auto overflow-x-hidden flex flex-col py-2 gap-2">
      <Sidebar2Section
        title="Style"
        leading={<FontAwesomeIcon icon={faPaintBrush} />}
        trailing={
          (analyticRequestWithCaptureObjectAndJobsQuery.isLoading ||
            analyticRequestWithCaptureObjectAndJobsQuery === undefined) && (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          )
        }
        info={selectedCaptureJob?.mlModel ? undefined : 'Not available'}
      >
        {selectedCaptureJob?.mlModel && (
          <div className="flex flex-col gap-2 pb-2">
            <div className="px-4 flex flex-col gap-2">
              <Input.Select.Inline
                disabled={
                  analyticRequestQuery.isLoading ||
                  viewerController?.potreeController?.info
                    .classificationClasses === undefined ||
                  selectedCaptureJob.mlModel === undefined
                }
                id="3d-model-style-select"
                options={[MaterialType.RGBA, MaterialType.CLASSIFICATION]}
                value={viewerController?.potreeController?.info.pointStyle}
                setValue={(value) => {
                  viewerController?.potreeController?.setPointStyle(
                    value ?? MaterialType.CLASSIFICATION
                  );
                }}
                style={Input.style.mini}
                optionBuilder={(option) => {
                  if (option === MaterialType.RGBA) {
                    return 'RGB';
                  }
                  if (option === MaterialType.CLASSIFICATION) {
                    return 'Classification';
                  } else {
                    return 'Unknown';
                  }
                }}
              />
              {viewerController?.potreeController?.info
                .classificationClasses && (
                <div className="flex flex-col gap-1 text-sm">
                  {viewerController?.potreeController?.info.classificationClasses.map(
                    (classificationClass, index) => (
                      <div
                        key={index}
                        className="flex flex-row w-full items-center justify-between"
                      >
                        <Input.Checkbox
                          id={`classification-class-checkbox-${index}`}
                          value={classificationClass.visible}
                          setValue={(value) => {
                            viewerController?.potreeController?.updateClassificationClassByLabel(
                              classificationClass.label,
                              { visible: value }
                            );
                          }}
                          label={
                            <Input.Label
                              label={classificationClass.labelString}
                            />
                          }
                        />
                        <ClassificationColorPicker
                          viewerController={viewerController?.potreeController}
                          classificationClass={classificationClass}
                        />
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Sidebar2Section>

      <Sidebar2Section
        title={<span>Camera Positions</span>}
        leading={<FontAwesomeIcon icon={faCamera} />}
        info={
          captureJobImagesQuery.isLoading ||
          captureJobImagesForLowResJobIdQuery.isLoading
            ? undefined
            : (captureJobImages?.length ?? 0) === 0
            ? 'Not available'
            : undefined
        }
        trailing={
          !cameraPositionsAreReady ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          ) : (captureJobImages.length ?? 0) > 0 ? (
            <span className="pointer-events-none">
              <Input.Checkbox
                id="camera-positions-visible-checkbox"
                value={cameraPositionsVisibleLocal ?? false}
                setValue={() => {
                  //
                }}
                style={Input.style.mini}
              />
            </span>
          ) : undefined
        }
        onClick={
          (captureJobImages.length ?? 0) === 0
            ? undefined
            : toggleCameraPositionsVisible
        }
      />

      <Sidebar2Section
        title="Objects"
        leading={<FontAwesomeIcon icon={faLocationDot} />}
        info={
          (analyticRequestWithCaptureObjectAndJobsQuery.data?.captureObjects
            ?.length ?? 0) === 0
            ? 'Not available'
            : undefined
        }
      >
        {analyticRequestWithCaptureObjectAndJobsQuery.data?.captureObjects?.map(
          (obj, index) => (
            <CVS3ObjectsList
              key={obj.id}
              index={index}
              limited={false}
              captureObject={obj}
              zoomable
              renameable
            />
          )
        )}
      </Sidebar2Section>

      <Sidebar2Section
        title="Attributes"
        leading={<FontAwesomeIcon icon={faTable} />}
        info={
          customCaptureAttributesForAnalyticRequestQuery.data?.length === 0
            ? 'Not available'
            : undefined
        }
        trailing={
          customCaptureAttributesForAnalyticRequestQuery.isLoading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          ) : undefined
        }
      >
        {customCaptureAttributesForAnalyticRequestQuery.data?.map(
          (attribute) => (
            <div className="flex flex-col px-3 py-1" key={attribute?.id}>
              <div className="flex flex-col pl-2 divide-y divide-gray-300">
                <div className="flex flex-col text-xs divide-y divide-gray-300">
                  <div className="flex flex-row justify-between gap-2">
                    <span className="truncate">
                      {attribute.displayName ?? attribute.attributeName}
                    </span>
                    <span className="whitespace-nowrap truncate">
                      <span>{attribute.attributeValue}</span>
                      <span>{getSymbol(attribute)}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </Sidebar2Section>

      {(captureVideoByAnalyticRequestQuery.data?.length ?? 0) > 0 && (
        <Sidebar2Section
          title="Analytic Video"
          leading={<FontAwesomeIcon icon={faVideo} />}
        >
          {captureVideoByAnalyticRequestQuery.data?.map((video, i) => (
            <div className="flex flex-col px-3 py-1" key={video.id}>
              <div className="flex flex-col pl-2 divide-y divide-gray-300">
                <div className="flex flex-col text-xs divide-y divide-gray-300">
                  <div className="flex flex-col justify-center items-stretch gap-1">
                    <Button.Small
                      id={`download-analytic-video-button-${i}`}
                      onClick={() => {
                        if (!video?.downloadUrl) return;
                        window.open(video?.downloadUrl, '_blank');
                      }}
                      label="Download video"
                      icon={faDownload}
                    />
                    <Button.Small
                      id={`view-analytic-video-button-${i}`}
                      onClick={() => {
                        if (!video?.downloadUrl) return;
                        videoRef.current?.requestFullscreen();
                      }}
                      label="Play video in full screen"
                      icon={faPlayCircle}
                      disabled={!video?.downloadUrl}
                    />
                    <video
                      controls
                      ref={videoRef}
                      muted
                      crossOrigin="anonymous"
                      src={video?.downloadUrl ?? undefined}
                      width={0}
                      height={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Sidebar2Section>
      )}
      <Sidebar2Section
        title="Export"
        leading={<FontAwesomeIcon icon={faFileExport} />}
        info={dataToExport?.length === 0 ? 'Not available' : undefined}
        trailing={
          dataToExport === undefined ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          ) : dataToExport.length > 0 ? (
            <FontAwesomeIcon icon={faDownload} />
          ) : undefined
        }
        onClick={
          dataToExport === undefined || dataToExport.length === 0
            ? undefined
            : exportData
        }
      />
    </div>
  );
};

const CVS3AnalyticsOutputDetailsTitle = () => {
  const { analyticRequestQuery } = useCapturesViewerQueries({});
  if (analyticRequestQuery.isLoading) {
    return (
      <div className="flex flex-row w-full">
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <span>
        {analyticRequestQuery.data?.customerAnalytic?.analytic?.analyticName}
      </span>
    </div>
  );
};

// Route for analytic output cards clicked on the /analytics-outputs route sidebar
export const cvs3AnalyticsOutputsDetailsRoute: Sidebar2Subroute = {
  element: (actions) => (
    <CVS3AnalyticsOutputDetails navigateBack={actions.navigateBack} />
  ),
  path: ':analyticRequestId',
  title: <CVS3AnalyticsOutputDetailsTitle />,
  subroutes: [],
};

// Route for analytic output cards clicked on the root of the sidebar
export const cvs3AnalyticsOutputDetailsRoute: Sidebar2Subroute = {
  element: (actions) => (
    <CVS3AnalyticsOutputDetails navigateBack={actions.navigateBack} />
  ),
  path: 'analytics-output/:analyticRequestId',
  title: <CVS3AnalyticsOutputDetailsTitle />,
  subroutes: [],
};

export const ClassificationColorPicker = ({
  viewerController,
  classificationClass,
}: {
  viewerController?: IPotreeViewerController;
  classificationClass: PotreeViewer2ControllerInfoClassificationProps;
}) => {
  const [expanded, setExpanded] = useState(false);

  const portal = useRef(document.createElement('div'));

  useEffect(() => {
    const p = portal.current;
    document.body.appendChild(p);
    return () => {
      document.body.removeChild(p);
    };
  }, []);

  const [colorTileRef, setColorTileRef] = useState<HTMLDivElement | null>(null);
  const [pickerRef, setPickerRef] = useState<HTMLDivElement | null>(null);

  const portalPos = useMemo(() => {
    if (!colorTileRef || !pickerRef) {
      return { x: 0, y: 0 };
    }

    const rect = colorTileRef.getBoundingClientRect();
    const pickRect = pickerRef.getBoundingClientRect();
    const whatQuad = (x: number, y: number) => {
      if (x < window.innerWidth / 2) {
        return y < window.innerHeight / 2 ? 1 : 3;
      }
      return y < window.innerHeight / 2 ? 2 : 4;
    };

    const quad = whatQuad(rect.left, rect.top);
    let position = { x: '', y: '' };

    if (quad === 1) {
      position = { x: `${rect.left + 10}px`, y: `${rect.top + 10}px` };
    } else if (quad === 2) {
      position = {
        x: `${rect.right - pickRect.width + 10}px`,
        y: `${rect.top + 10}px`,
      };
    } else if (quad === 3) {
      position = {
        x: `${rect.left + 10}px`,
        y: `${rect.top - pickRect.height}px`,
      };
    } else {
      // quad 4
      position = {
        x: `${rect.right - pickRect.width - 10}px`,
        y: `${rect.top - pickRect.height}px`,
      };
    }

    return position;
  }, [colorTileRef, pickerRef]);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    const clickHandler = (e: MouseEvent) => {
      if (pickerRef?.contains(e.target as Node)) {
        return;
      }

      if (colorTileRef?.contains(e.target as Node)) {
        return;
      }

      setExpanded(false);
    };

    document.addEventListener('mousedown', clickHandler);
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [expanded, pickerRef, colorTileRef]);

  return (
    <div
      className="w-5 h-5 rounded relative overflow-visible cursor-pointer ring-1 ring-gray-500"
      style={{
        background: `repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px`,
      }}
      tabIndex={0}
      onClick={() => {
        if (colorTileRef) {
          setExpanded(true);
        }
      }}
      ref={setColorTileRef}
    >
      <div
        className="absolute inset-0 rounded"
        style={{
          backgroundColor: cvtRgbaArrToRgbaStr(
            cvtRgbaPctToStdRgba(classificationClass.color)
          ),
        }}
      />
      {expanded &&
        createPortal(
          <div
            className="absolute z-200 bg-white shadow rounded-t-md rounded-b p-1 border border-gray-light"
            style={{
              top: portalPos.y,
              left: portalPos.x,
            }}
            ref={setPickerRef}
          >
            <ColorPicker2.RGB
              initialPercentRgbColor={classificationClass.color.slice(0, -1)}
              onChange={(color) => {
                viewerController?.updateClassificationClassByLabel(
                  classificationClass.label,
                  { color: [...color, 1] }
                );
              }}
              debounceTime={300}
            />
          </div>,
          portal.current
        )}
    </div>
  );
};
