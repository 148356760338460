import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  CaptureCustomAttribute,
  CaptureObjectCustomAttribute,
  CustomAttributeUnit,
} from '@agerpoint/api';
import { PrimaryButton } from '@agerpoint/component';

export const getDefaultCaptureCustomAttribute = (
  captureId: number
): CaptureCustomAttribute => ({
  attributeName: '',
  attributeValue: '',
  captureId,
});

export const getDefaultObjectCustomAttribute = (
  captureObjectId: number
): CaptureObjectCustomAttribute => ({
  attributeName: '',
  attributeValue: '',
  captureObjectId,
  validated: true,
});

export const getDefaultCaptureObject = (captureId: number, number = -1) => ({
  id: 0,
  name: '',
  description: '',
  captureId,
  number,
  captureObjectTypeId: 1,
  captureObjectCustomAttributes: [],
  validated: true,
});

export const AddAttributeButton = ({
  disabled,
  isSaving,
  addRow,
}: {
  disabled?: boolean;
  isSaving: boolean;
  addRow: () => void;
}) => {
  return (
    <PrimaryButton
      disabled={isSaving || disabled}
      // className="mt-4"
      onClicked={addRow}
      icon={<FontAwesomeIcon icon={faPlus} />}
      label="Add Attribute"
    />
  );
};

export const useUnitOptions = (units: CustomAttributeUnit[] = []) => {
  const unitOptions =
    units
      .sort((a: CustomAttributeUnit, b: CustomAttributeUnit) => {
        if ((a?.unitName || '') > (b?.unitName || '')) {
          return 1;
        }
        if ((a?.unitName || '') < (b?.unitName || '')) {
          return -1;
        }
        return 0;
      })
      ?.sort((a: CustomAttributeUnit, b: CustomAttributeUnit) => {
        if (a?.isMetric && !b?.isMetric) {
          return -1;
        }
        if (b?.isMetric && !a?.isMetric) {
          return 1;
        }
        return 0;
      })
      ?.map((unit: CustomAttributeUnit) => ({
        name: unit?.unitName || '',
        value: unit?.id || 0,
      })) || [];
  unitOptions.push({ name: 'None', value: null as any });
  return unitOptions;
};
