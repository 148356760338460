import {
  faChevronsLeft,
  faChevronsRight,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardOption } from 'libs/feature/src/captures-shared/capture-details-modal/capture-details-sections/capture-details-section-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import compare from 'trivial-compare';

import { ColorDefaults, IconTypes, SvgElement } from '@agerpoint/component';
import { AnalyticRequestStatus } from '@agerpoint/types';
import { convertNameToCamelCase } from '@agerpoint/utilities';

import { useCapturesViewerQueries } from '../../captures-viewer-queries';
import { AnalyticStatusIcon } from './analytic-status-icon';

export const CVS3AnalyticsList = () => {
  // const hdOption: CardOption = useMemo(
  //   () => ({
  //     id: 'hdOption',
  //     icon: 'high-definition',
  //     desc: '',
  //     title: '3D Model',
  //     subTitle: '',
  //     disabled: false,
  //   }),
  //   []
  // );

  const [availableOptions, setAvailableOptions] = useState<CardOption[]>([]);
  const [firstThree, setFirstThree] = useState<CardOption[]>();
  const [pointer, setPointer] = useState(0);

  const { customerAnalyticsQuery, analyticRequestsQuery } =
    useCapturesViewerQueries({});

  useEffect(() => {
    if (customerAnalyticsQuery.isSuccess) {
      const availableAnalytics = customerAnalyticsQuery.data
        ?.filter((a) => a.archived === false)
        .sort((a, b) => compare(a.priority, b.priority))
        .map((a) => {
          const status: AnalyticRequestStatus =
            (analyticRequestsQuery.data?.find(
              (ar) => ar.customerAnalyticId === a.id
            )?.status ??
              AnalyticRequestStatus.UNKNOWN) as AnalyticRequestStatus;

          return {
            id: a?.analyticId?.toString() ?? '',
            icon: a?.analytic?.icon ?? '',
            desc: a?.analytic?.analyticDescription ?? '',
            title: a?.analytic?.analyticName ?? '',
            subTitle: '',
            disabled: !a?.available,
            status: status,
          };
        });

      setAvailableOptions([...availableAnalytics]);
    }
  }, [customerAnalyticsQuery.data, analyticRequestsQuery.data]);

  useEffect(() => {
    if (availableOptions.length === 0) {
      setFirstThree([]);
      return;
    }

    const firstChunk =
      availableOptions.length >= 3 ? 3 : availableOptions.length;
    const firstThree = [...availableOptions, ...availableOptions].slice(
      pointer,
      pointer + firstChunk
    );
    setFirstThree(firstThree);
  }, [availableOptions, pointer]);

  const pointerChanged = useCallback(
    (direction: 'left' | 'right') => {
      let nextPointer;
      if (direction === 'left') {
        nextPointer = pointer - 1;
      } else {
        nextPointer = pointer + 1;
      }

      if (nextPointer > availableOptions.length - 1) {
        nextPointer = 0;
      } else if (nextPointer < 0) {
        nextPointer = availableOptions.length - 1;
      }
      setPointer(nextPointer);
    },
    [availableOptions, pointer]
  );

  const loading =
    analyticRequestsQuery.isLoading || customerAnalyticsQuery.isLoading;
  return (
    <div className="flex flex-col">
      <div className="flex flex-row px-1 pb-1 items-center">
        {loading || firstThree === undefined ? (
          <div className="flex flex-row p-2 w-full justify-center items-center text-center h-22">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : firstThree.length === 0 ? (
          <div className="flex flex-row p-2 w-full justify-center items-center text-sm text-center h-22">
            No analytics available for your organization.
          </div>
        ) : (
          <>
            <div
              className="h-22 flex items-center cursor-pointer text-gray-900
    hover:bg-green-100 bg-white transition-colors rounded"
              onClick={() => {
                pointerChanged('left');
              }}
            >
              <FontAwesomeIcon icon={faChevronsLeft} className="px-0.5" />
            </div>
            <div className="h-22 pt-1 w-full overflow-hidden pb-1 flex justify-center">
              <div className="flex flex-row h-full gap-1 px-1 w-fit ">
                {firstThree.map(function (opt) {
                  return <AnalyticCard key={opt.id} opt={opt} />;
                })}
              </div>
            </div>
            <div
              className="h-22 flex items-center cursor-pointer text-gray-900
    hover:bg-green-100 bg-white transition-colors rounded"
              onClick={() => {
                pointerChanged('right');
              }}
            >
              <FontAwesomeIcon icon={faChevronsRight} className="px-0.5" />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row justify-center flex-wrap gap-0.5 px-8 pb-1">
        {availableOptions.map((opt, index) => (
          <div
            key={index}
            className={`w-2 h-2 flex-shrink-0 border-green border rounded-full ${
              firstThree?.includes(opt) ? 'bg-green' : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const AnalyticCard = ({ opt }: { opt: CardOption }) => {
  const navigate = useNavigate();

  const onClickFn = () => {
    if (opt?.disabled) return;
    if (opt?.status && opt?.status !== AnalyticRequestStatus.UNKNOWN) return;
    if (opt.id === 'hdOption') {
      navigate(`./models`);
    } else {
      navigate(`./analytics/${opt?.id}`);
    }
  };

  const className = useMemo(() => {
    const base = `w-full relative rounded h-full border-2 border cursor-pointer bg-white transition-colors flex flex-col py-2`;
    let pointer = 'hover:bg-green-100';
    let text = 'text-green';
    if (opt.disabled) {
      text = 'text-gray-400';
      pointer = 'pointer-events-none';
    }
    if (opt.status && opt.status !== AnalyticRequestStatus.UNKNOWN) {
      text = 'text-gray-400';
      pointer = 'pointer-events-none';
    }
    return `${base} ${pointer} ${text}`;
  }, [opt?.disabled, opt?.status]);

  return (
    <div className="flex relative">
      {opt?.status === null ||
      opt?.status === AnalyticRequestStatus.UNKNOWN ? null : (
        <div className="absolute -top-0.5 -left-0.5 z-50">
          <AnalyticStatusIcon
            status={opt?.status}
            spin={opt?.status === AnalyticRequestStatus.PROCESSING}
          />
        </div>
      )}

      <div className="relative font-bold overflow-hidden rounded">
        {opt?.disabled && (
          <>
            <div
              className="absolute z-50 text-center tracking-wide bg-green text-white text-xs font-normal py-1 px-6 whitespace-nowrap"
              style={{
                fontSize: '0.6rem',
                lineHeight: '0.6rem',
                transformOrigin: 'center',
                transform: 'rotate(-19deg) translateX(-22px) translateY(3px)',
              }}
            >
              Coming Soon!
            </div>
          </>
        )}
        <div
          className={className}
          style={{ aspectRatio: '1/1' }}
          onClick={onClickFn}
          title={`${opt?.title}`}
        >
          <div className={`w-full h-full `}>
            <div className={`h-1/2 w-full flex items-center justify-center`}>
              <SvgElement
                color={opt?.status ? ColorDefaults.grey : ColorDefaults.green}
                className="h-6"
                type={convertNameToCamelCase(`fa-${opt?.icon}`) as IconTypes}
              />
            </div>
            <div className="h-1/2 w-full flex items-center justify-center">
              <span className="text-xs text-center leading-none overflow-hidden line-clamp-2">
                {opt?.title}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
