import { useAuth } from 'oidc-react';
import { useEffect, useMemo, useState } from 'react';

import {
  APIClient,
  LayerGroup,
  Project,
  fetchGetProjectLayerGroups,
  useGetLibrary,
  useGetProjectLayerGroups,
} from '@agerpoint/api';
import { ComboboxSelect } from '@agerpoint/component';
import { ComboBoxOption, LibraryLayer } from '@agerpoint/types';
import { APIUtils, environment, getTitleCase } from '@agerpoint/utilities';

interface FusionFiltersProps {
  selectedLayer: ComboBoxOption;
  setSelectedLayer: (layer: ComboBoxOption) => void;
  availableAttributes: ComboBoxOption[];
  selectedAttribute: ComboBoxOption;
  setSelectedAttribute: (attribute: ComboBoxOption) => void;
  filterLoading: boolean;
  setFilterLoading: (loading: boolean) => void;
}

export const FusionFilters = ({
  selectedLayer,
  setSelectedLayer,
  availableAttributes,
  selectedAttribute,
  setSelectedAttribute,
  filterLoading,
  setFilterLoading,
}: FusionFiltersProps) => {
  const { userData } = useAuth();
  const [libraryLayers, setLibraryLayers] = useState<ComboBoxOption[]>([]);
  const [selectedProject, setSelectedProject] = useState<ComboBoxOption>({
    name: '',
    id: '',
  });
  const [projects, setProjects] = useState<ComboBoxOption[]>([]);
  const { data: apiLibraryRes } = useGetLibrary({}) as unknown as {
    data: LibraryLayer[];
  };
  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
      select: (data) => APIUtils.Sort.projects(data),
    },
  });
  const collection = useMemo(() => projectsQuery.data, [projectsQuery.data]);
  const { data: layerGroupsApiRes, refetch: refetchLayerGroup } =
    useGetProjectLayerGroups({
      projectUuid: selectedProject?.id as string,
      lazy: true,
    });

  useEffect(() => {
    if (!collection) return;
    const res = collection as Project[];
    const projects = res
      .map((project) => {
        return {
          name: getTitleCase(project.name),
          id: project.uuid || '',
        };
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    setProjects(projects);
  }, [collection]);

  useEffect(() => {
    if (!layerGroupsApiRes) return;
    setFilterLoading(true);
    const filteredLayers = layerGroupsApiRes as LayerGroup[];

    const doAsync = async () => {
      if (!userData) return;
      const layerPromises = filteredLayers.map(async (layer) => {
        const id = layer.id as number;
        return await fetchGetProjectLayerGroups(
          id,
          environment.api_server_url,
          userData.access_token
        );
      });
      const layers = await Promise.all(layerPromises);
      const options = filterAndMapToComboBoxOptions(layers.flat());
      setLibraryLayers(options);
      setFilterLoading(false);
    };
    doAsync();
  }, [layerGroupsApiRes]);

  useEffect(() => {
    if (!selectedProject.id) {
      // const filteredLayers = filterAndMapToComboBoxOptions(apiLibraryRes)

      // setLibraryLayers(filteredLayers);
      return;
    }
    setFilterLoading(true);
    setLibraryLayers([]);
    setSelectedLayer({ name: '', id: '' });
    refetchLayerGroup();
  }, [selectedProject, refetchLayerGroup]);

  useEffect(() => {
    if (!apiLibraryRes) return;

    const filteredLayers = filterAndMapToComboBoxOptions(apiLibraryRes);

    setLibraryLayers(filteredLayers);
    setFilterLoading(false);
  }, [apiLibraryRes]);

  const filterAndMapToComboBoxOptions = (layers: any[]) => {
    return layers
      .filter((layer) => {
        return layer.layerTypeId === 1;
      })
      .map((layer) => ({
        id: layer.entityId || NaN,
        name: layer.name || '',
      }));
  };

  // useEffect(() => {
  // }, [filterLoading]);
  return (
    <>
      <ComboboxSelect
        className="pl-2 w-64"
        choices={projects}
        selected={selectedProject}
        changeFn={setSelectedProject}
        label="Filter by Project"
        disabled={filterLoading}
      />
      <ComboboxSelect
        className="w-64 pl-2"
        selected={selectedLayer}
        changeFn={setSelectedLayer}
        choices={libraryLayers}
        label="Select Layer"
        disabled={filterLoading}
      />
      <ComboboxSelect
        className="w-64 pl-2"
        selected={selectedAttribute}
        changeFn={setSelectedAttribute}
        choices={availableAttributes}
        label="Select Attribute"
        disabled={filterLoading}
      />
    </>
  );
};
