import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, APIModels } from '@agerpoint/api';
import { APIUtils, useIsViteApp, useToasts } from '@agerpoint/utilities';

export const useAdminJobTypesQueries = (archivedFilter?: boolean) => {
  const queryClient = useQueryClient();
  const toasts = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { jobTypeId } = useParams();

  const mosaicEnginesQuery = APIClient.useGetMosaicEngines({
    query: {
      queryKey: [APIUtils.QueryKey.mosaicEngines, { archived: false }],
      select: (data) => data.filter((d) => d.archived === false),
    },
  });

  const mlModelsQuery = APIClient.useGetMlModels({
    query: {
      queryKey: [APIUtils.QueryKey.mlModels, { archived: false }],
      select: useMemo(
        () => (data) => {
          return data.filter((d) => d.archived === false);
        },
        []
      ),
    },
  });

  const pipelinesQuery = APIClient.useGetPipelines({
    query: {
      queryKey: [APIUtils.QueryKey.pipelines],
      select: (data) => APIUtils.Sort.pipelines(data),
    },
  });

  const jobTypeQuery = APIClient.useGetJobTypeById<APIModels.JobType, unknown>(
    Number(jobTypeId),
    {
      query: {
        enabled: Number.isSafeInteger(Number(jobTypeId)),
        queryKey: [
          APIUtils.QueryKey.jobTypes,
          { jobTypeId: Number(jobTypeId) },
        ],
        initialData: () =>
          APIUtils.searchQueriesForInitialValue<APIModels.JobType>({
            queryClient,
            queryKey: [APIUtils.QueryKey.jobTypes],
            id: Number(jobTypeId),
            accessor: 'id',
          }),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
        retry: 0,
      },
    }
  );

  const jobTypePutMutation = APIClient.usePutJobTypeById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.jobTypes],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache<APIModels.JobType>({
          queryClient,
          queryKey: [APIUtils.QueryKey.jobTypes, { archived: true }],
          accessor: 'id',
          data: variables.data,
          id: variables.id,
        });

        APIUtils.updateListQueryCache<APIModels.JobType>({
          queryClient,
          queryKey: [APIUtils.QueryKey.jobTypes, { archived: false }],
          accessor: 'id',
          data: variables.data,
          id: variables.id,
        });

        APIUtils.updateQueryCache<APIModels.JobType>({
          queryClient,
          queryKey: [APIUtils.QueryKey.jobTypes, { jobTypeId: variables.id }],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('job type'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update job type!'));
      },
    },
  });

  const jobTypePutArchivedMutation = APIClient.usePutJobTypeById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.jobTypes],
        });
      },
      onSuccess: (_, variables) => {
        if (variables.data.archived) {
          toasts.add(toasts.prepare.entityArchived('job type'));
        } else {
          toasts.add(toasts.prepare.entityRestored('job type'));
        }
      },
      onError: (e, variables) => {
        console.error(e);

        toasts.add(
          toasts.prepare.error(
            `Failed to ${
              variables.data.archived ? 'archive' : 'restore'
            } job type`
          )
        );
      },
    },
  });

  useEffect(() => {
    if (jobTypeId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(jobTypeId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/job-types' + params);
      } else {
        navigate('/admin/job-types' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.jobTypes,
          { jobTypeId: Number(jobTypeId) },
        ],
      });
    }
  }, [jobTypeId]);

  const jobTypesQuery = APIClient.useGetJobType({
    query: {
      queryKey: [APIUtils.QueryKey.jobTypes, { archived: archivedFilter }],
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      select: (d) =>
        d
          .filter((j) => j.archived === archivedFilter)
          .sort((a, b) => compare(a.id, b.id)),
      enabled: archivedFilter !== undefined,
    },
  });

  const jobTypeBulkSetArchivedMutation = useMutation({
    mutationFn: async (data: { jobTypes: APIModels.JobType[] }) => {
      const promises: Promise<APIModels.JobType>[] = [];

      for (const jobType of data.jobTypes) {
        if (jobType.id !== undefined) {
          promises.push(APIClient.putJobTypeById(jobType.id, jobType));
        }
      }

      return Promise.all(promises);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.jobTypes],
      });
    },
    onError: (e) => {
      console.error(e);

      toasts.add(
        toasts.prepare.error(
          `Failed to ${!archivedFilter ? 'archive' : 'restore'} job types`
        )
      );
    },
    onSuccess: (_, variables) => {
      if (!archivedFilter) {
        toasts.add(toasts.prepare.entityArchived('job types'));
      } else {
        toasts.add(toasts.prepare.entityRestored('job types'));
      }

      for (const jobType of variables.jobTypes) {
        APIUtils.updateQueryCache<APIModels.JobType>({
          queryClient,
          queryKey: [APIUtils.QueryKey.jobTypes, { jobTypeId: jobType.id }],
          data: jobType,
        });

        APIUtils.updateListQueryCache<APIModels.JobType>({
          queryClient,
          queryKey: [APIUtils.QueryKey.jobTypes, { archivedFilter: true }],
          accessor: 'id',
          data: jobType,
          id: jobType.id,
        });

        APIUtils.updateListQueryCache<APIModels.JobType>({
          queryClient,
          queryKey: [APIUtils.QueryKey.jobTypes, { archivedFilter: false }],
          accessor: 'id',
          data: jobType,
          id: jobType.id,
        });
      }
    },
  });

  const jobTypePostMutation = APIClient.usePostJobType({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.jobTypes],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityCreated('job type'));

        if (isViteApp) {
          navigate('/app/admin/platform/job-types' + params);
        } else {
          navigate('/admin/job-types' + params);
        }
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to create job type!'));
      },
    },
  });

  return {
    mosaicEnginesQuery,
    mlModelsQuery,
    jobTypeQuery,
    jobTypePutMutation,
    jobTypePutArchivedMutation,
    jobTypesQuery,
    jobTypeBulkSetArchivedMutation,
    jobTypePostMutation,
    pipelinesQuery,
  };
};
