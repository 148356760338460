import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';

import { AppSidebar } from '@agerpoint/feature';
import { LdFlags } from '@agerpoint/types';
import { HasPermissionRouteGuard } from '@agerpoint/utilities';

import { CapturesReportPage, FusionPage } from './sub-pages';

export const Reports = () => {
  return (
    <HasPermissionRouteGuard permissionToCheck={LdFlags.ReportingPage}>
      <CanViewReports />
    </HasPermissionRouteGuard>
  );
};

export const CanViewReports = () => {
  return (
    <Routes>
      <Route path="/fusion-dashboard" element={<FusionPage />} />
      <Route path="/captures" element={<CapturesReportPage />} />
      <Route path="/*" element={<ReportsPage />} />
    </Routes>
  );
};

export const ReportsPage = () => {
  return (
    <AppSidebar>
      <div className="flex pt-4 h-full w-full">
        <Link
          to="/reports/fusion-dashboard"
          className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
            Fusion Dashboard
          </h5>
          <p className="font-normal text-gray-700 ">
            Create detailed chart analysis from map layer data. Includes Outlier
            Detection, Correlation, and more.
          </p>
        </Link>
        <Link
          to="/reports/captures"
          className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
            Captures Report
          </h5>
          <p className="font-normal text-gray-700 ">
            Aggregate and analyze data from your captures.
          </p>
        </Link>
      </div>
    </AppSidebar>
  );
};
