import { PipelineJob } from '@agerpoint/api';
import {
  IPipelineStatus,
  PipelineStatus,
  PipelineStatusNames,
} from '@agerpoint/types';

const baseContainerStyle = `flex w-full items-center`;
const baseTextStyle = `flex items-center justify-center w-12 h-12 rounded-full lg:h-12 lg:w-12 shrink-0 min-w-12`;
const baseBorder = `border-b border-2 inline-block w-full `;

export const completeStyleContainer = `${baseContainerStyle} text-green-600`;
export const completeTextStyle = `${baseTextStyle} bg-green-100`;
export const completeBorder = `${baseBorder} border-green-100`;

export const activeStyleContainer = `${baseContainerStyle} text-blue-600 after:border-blue-100 `;
export const activeTextStyle = `${baseTextStyle} bg-blue-100`;
export const activeBorder = `${baseBorder} border-blue-100 `;

export const inactiveStyleContainer = `${baseContainerStyle} text-gray-600 after:border-gray-300`;
export const inactiveTextStyle = `${baseTextStyle} bg-gray-200`;
export const inactiveBorder = `${baseBorder} border-gray-200`;

export const inactiveStyleRes = {
  container: inactiveStyleContainer,
  text: inactiveTextStyle,
  border: inactiveBorder,
  status: PipelineStatusNames.inactive,
};
export const activeStyleRes = {
  container: activeStyleContainer,
  text: activeTextStyle,
  border: activeBorder,
  status: PipelineStatusNames.active,
};
export const completeStyleRes = {
  container: completeStyleContainer,
  text: completeTextStyle,
  border: completeBorder,
  status: PipelineStatusNames.complete,
};

export const getPreSelectClassNames = (PipelineStatus: IPipelineStatus) => {
  if (PipelineStatus.preprocess.length === 0) {
    return { ...inactiveStyleRes };
  }
  const lastPreprocess =
    PipelineStatus.preprocess[PipelineStatus.preprocess.length - 1];
  if (lastPreprocess && !lastPreprocess?.completedDatetime) {
    return { ...activeStyleRes };
  }
  return { ...completeStyleRes };
};

export const getReviewClassNames = (PipelineStatus: IPipelineStatus) => {
  const lastPreprocess =
    PipelineStatus.preprocess[PipelineStatus.preprocess.length - 1];
  const lastAnalyze = PipelineStatus.analyze[PipelineStatus.analyze.length - 1];

  if (lastPreprocess?.completedDatetime && !lastAnalyze) {
    return { ...activeStyleRes };
  }
  if (lastPreprocess?.completedDatetime && lastAnalyze) {
    return { ...completeStyleRes };
  }
  return { ...inactiveStyleRes };
};

export const getAnalyzeClassNames = (PipelineStatus: IPipelineStatus) => {
  const lastPreprocess =
    PipelineStatus.preprocess[PipelineStatus.preprocess.length - 1];
  const lastAnalyze = PipelineStatus.analyze[PipelineStatus.analyze.length - 1];

  if (
    lastPreprocess?.completedDatetime &&
    lastAnalyze &&
    !lastAnalyze?.completedDatetime
  ) {
    return { ...activeStyleRes };
  }
  if (lastPreprocess?.completedDatetime && lastAnalyze?.completedDatetime) {
    return { ...completeStyleRes };
  }
  return { ...inactiveStyleRes };
};

export const getCompletedClassNames = (PipelineStatus: IPipelineStatus) => {
  const lastPreprocess =
    PipelineStatus.preprocess[PipelineStatus.preprocess.length - 1];
  const lastAnalyze = PipelineStatus.analyze[PipelineStatus.analyze.length - 1];

  if (lastPreprocess?.completedDatetime && lastAnalyze?.completedDatetime) {
    return { ...completeStyleRes };
  }
  return { ...inactiveStyleRes };
};

export const filterAndSortPipelineJobs = (
  captureId: number,
  pipelineJobs: PipelineJob[]
) => {
  const reduced = pipelineJobs
    .filter((job) => job.captureId === captureId)
    .sort((a, b) => {
      const aCreate = new Date(a.createDatetime || 0).getTime();
      const bCreate = new Date(b.createDatetime || 0).getTime();
      return bCreate - aCreate;
    })
    .reduce(
      (acc: IPipelineStatus, job: PipelineJob) => {
        if (job.stage === PipelineStatus.preprocess) {
          acc.preprocess.push(job);
        } else if (job.stage === PipelineStatus.analyze) {
          acc.analyze.push(job);
        }
        return acc;
      },
      {
        preprocess: [] as PipelineJob[],
        analyze: [] as PipelineJob[],
      }
    );

  // ready
  if (!reduced.preprocess.length) {
    return {
      preprocess: [],
      analyze: [],
    };
  }

  const firstPreprocess = reduced.preprocess[0];
  const firstAnalyze = reduced.analyze[0];

  if (firstPreprocess && !firstPreprocess?.completedDatetime) {
    return {
      preprocess: [firstPreprocess],
      analyze: [],
    };
  }

  if (!firstAnalyze) {
    return {
      preprocess: [firstPreprocess],
      analyze: [],
    };
  }

  if (
    firstAnalyze &&
    new Date(firstAnalyze.createDatetime || 0) <=
      new Date(firstPreprocess.createDatetime || 0)
  ) {
    return {
      preprocess: [firstPreprocess],
      analyze: [],
    };
  }

  return {
    preprocess: [firstPreprocess],
    analyze: [firstAnalyze],
  };
};
