import {
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faExpand,
  faNote,
  faSave,
  faSpinner,
  faTrash,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseGetReturn } from 'restful-react';

import {
  CaptureImage,
  CaptureImageTag,
  UseGetCaptureImageTagThumbnailById2Props,
  useGetCaptureImageTagThumbnailById2,
  useGetCaptureImageTagsByCaptureId,
  usePutCaptureImageTag,
} from '@agerpoint/api';
import {
  ConfirmModal,
  ImageViewer,
  Input,
  PrimaryButton,
} from '@agerpoint/component';
import { MixpanelNames } from '@agerpoint/types';
import { blobCache, useGlobalStore, useToasts } from '@agerpoint/utilities';

export interface CaptureSupplementalImagesProps {
  show: boolean;
  captureId: number;
}

/**
 * @deprecated Replaced with CVS3SupplementalImages
 */
export const CaptureSupplementalImages = ({
  show,
  captureId,
}: CaptureSupplementalImagesProps) => {
  const { data: images, refetch: refetchSupplementalImages } =
    useGetCaptureImageTagsByCaptureId({
      captureId: captureId,
    }) as unknown as UseGetReturn<CaptureImageTag[], void, void, unknown>;

  const [page, setPage] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>();
  const [expandedImage, setExpandedImage] = useState<CaptureImage>();
  const [expandedImageBlob, setExpandedImageBlob] = useState<Blob>();
  const [sortedImages, setSortedImages] = useState<CaptureImage[]>();

  const expandedImageRequestBody = useMemo(() => {
    return {
      id: expandedImage?.id ?? NaN,
      fit: 'fit-in',
      crop: '%20',
      size: `${2000}x${2000}`,
      verticalAlign: '%20',
      horizontalAlign: '%20',
      filters: '%20',
      lazy: true,
    };
  }, [expandedImage]);

  const {
    data: expandedThumbnailData,
    refetch: getExpandedThumbnail,
    cancel: cancelGetExpandedThumbnail,
  } = useGetCaptureImageTagThumbnailById2(
    expandedImageRequestBody
  ) as unknown as UseGetReturn<Response, void, void, unknown>;

  useEffect(() => {
    cancelGetExpandedThumbnail();
    if (!expandedImage) return;
    setExpandedImageBlob(undefined);
    getExpandedThumbnail();
  }, [expandedImage]);

  useEffect(() => {
    if (!expandedThumbnailData) return;
    const doAsync = async () => {
      const blob = await expandedThumbnailData.blob();
      setExpandedImageBlob(blob);
    };
    doAsync();
  }, [expandedThumbnailData]);

  useEffect(() => {
    setSelectedImageIndex(undefined);
  }, [page]);

  useEffect(() => {
    const imgs =
      images
        ?.sort((a: CaptureImageTag, b: CaptureImageTag) => {
          if (!a.fileName || !b.fileName) return 0;
          // sort by fileName
          if (a.fileName < b.fileName) {
            return -1;
          }
          if (a.fileName > b.fileName) {
            return 1;
          }
          return 0;
        })
        ?.slice(page * take, (page + 1) * take) ?? [];
    setSortedImages(imgs);
  }, [images]);

  const take = useMemo(() => 12, []);

  const pageIndicator = useMemo(() => {
    let result = '';

    const imagesLength = images?.length ?? 0;
    const from = page * take;
    const to = (page + 1) * take;

    result += `${from}`;
    result += ` - `;
    if (to > imagesLength) {
      result += `${imagesLength}`;
    } else {
      result += `${(page + 1) * take}`;
    }
    result += ' of ';
    result += `${images?.length}`;

    return result;
  }, [images, page, take]);

  const refetchSupplementalImagesCallback = useCallback(() => {
    setSelectedImageIndex(undefined);
    refetchSupplementalImages();
  }, [setSelectedImageIndex]);

  const pageNavigator = useMemo(() => {
    return (
      <div className="flex flex-row justify-center gap-2 pb-1 items-center text-gray-600">
        <div
          onClick={() => setPage((prev) => (prev - 1 <= 0 ? 0 : prev - 1))}
          className="cursor-pointer"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        {pageIndicator}
        <div
          onClick={() =>
            setPage((prev) =>
              (prev + 1) * take > (images?.length ?? 0) ? prev : prev + 1
            )
          }
          className="cursor-pointer"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    );
  }, [images?.length, pageIndicator, take]);

  if (!show) {
    return null;
  }
  return (
    <>
      {expandedImage && (
        <ImageViewer
          open={!!expandedImage}
          handleCloseViewer={() => {
            setExpandedImage(undefined);
            setExpandedImageBlob(undefined);
          }}
          handleNavigation={{
            next: () => {
              if (!images) {
                return;
              }
              let index =
                images.findIndex((c) => c.id === expandedImage.id) ?? -1;
              if (index <= -1) {
                return;
              }
              setExpandedImageBlob(undefined);
              index += 1;
              if (index > images.length - 1) {
                index = 0;
              }
              const newImage = images[index];
              setExpandedImage(newImage);
            },
            previous: () => {
              if (!images) {
                return;
              }
              let index = images.findIndex((c) => c === expandedImage);
              if (index <= -1) {
                return;
              }
              setExpandedImageBlob(undefined);
              index -= 1;
              if (index < 0) {
                index = images.length - 1;
              }
              const newImage = images[index];
              setExpandedImage(newImage);
            },
          }}
          loading={!expandedImageBlob}
          showDownloadButton={false}
        >
          <div className="flex flex-row justify-center">
            {expandedImageBlob && (
              <div className="relative">
                <div
                  className={`absolute bottom-0 left-0 right-0 flex flex-row justify-between gap-1 bg-gray-900 text-gray-100 rounded`}
                >
                  <div className="px-1 py-0.5 text-4xl">
                    {(images?.findIndex((i) => i.id === expandedImage.id) ??
                      0) + 1}{' '}
                    of {images?.length}
                  </div>
                  <div className="px-1 py-0.5 text-4xl">
                    {expandedImage?.note ? `Note: ${expandedImage?.note}` : ''}
                  </div>
                </div>
                <img
                  style={{ borderRadius: '1%' }}
                  src={URL.createObjectURL(expandedImageBlob)}
                  alt={`Capture ${expandedImage?.id}`}
                />
              </div>
            )}
          </div>
        </ImageViewer>
      )}

      {(images?.length ?? 0) === 0 ? (
        <div className="w-full flex justify-center px-2 text-sm">
          No Images Found
        </div>
      ) : (
        <div className="px-1 text-sm w-full">
          {pageNavigator}
          <CaptureSupplementalImagesGallery
            images={sortedImages ?? []}
            selectedImageIndex={selectedImageIndex}
            setSelectedImageIndex={setSelectedImageIndex}
            setExpandedImage={setExpandedImage}
            refetchSupplementalImages={refetchSupplementalImagesCallback}
          />
        </div>
      )}
    </>
  );
};

interface CaptureSupplementalImagesGalleryProps {
  images: CaptureImage[];
  selectedImageIndex: number | undefined;
  setSelectedImageIndex: (index: number | undefined) => void;
  setExpandedImage: (image: CaptureImage) => void;
  refetchSupplementalImages: () => void;
}

const CaptureSupplementalImagesGallery = ({
  images,
  selectedImageIndex,
  setSelectedImageIndex,
  setExpandedImage,
  refetchSupplementalImages,
}: CaptureSupplementalImagesGalleryProps) => {
  const imgsPerRow = 3;
  const rows: JSX.Element[] = [];

  for (let i = 0; i < images.length; i += imgsPerRow) {
    const rowImgs = images.slice(i, i + imgsPerRow);
    const row = (
      <div key={`ROW ${i}`} className="w-full flex flex-col">
        <div className="flex flex-row w-full gap-x-1">
          {rowImgs.map((img, index) => (
            <div
              className="w-1/3 flex flex-col items-center justify-center"
              key={`IMG ${index + i}`}
            >
              <div className="w-full">
                <CaptureSupplementalImage
                  image={img}
                  index={i + index}
                  setSelectedImageIndex={setSelectedImageIndex}
                  setExpandedImage={setExpandedImage}
                />
              </div>
              <div
                className={`${
                  selectedImageIndex !== undefined &&
                  selectedImageIndex >= i &&
                  selectedImageIndex < i + imgsPerRow
                    ? 'pt-2'
                    : 'pt-1'
                }`}
              >
                {selectedImageIndex === i + index && (
                  <div
                    className="-mt-2 w-0 h-0"
                    style={{
                      borderLeft: '8px solid transparent',
                      borderRight: '8px solid transparent',
                      borderBottom: '8px solid rgb(226, 226, 226)',
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {Array.from(
            { length: imgsPerRow - rowImgs.length },
            (_, __) => null
          ).map((_, counterWeightIndex) => (
            <div className="w-1/3" key={counterWeightIndex} />
          ))}
        </div>
        {selectedImageIndex !== undefined &&
          selectedImageIndex >= i &&
          selectedImageIndex < i + imgsPerRow && (
            <CaptureSupplementalImageDetails
              key={selectedImageIndex}
              image={images[selectedImageIndex]}
              clearSelectedImageIndex={() => {
                setSelectedImageIndex(undefined);
              }}
              refetchSupplementalImages={refetchSupplementalImages}
            />
          )}
      </div>
    );
    rows.push(row);
  }

  return <div>{rows}</div>;
};

interface CaptureSupplementalImageProps {
  image: CaptureImage;
  index: number;
  setSelectedImageIndex: (index: number) => void;
  setExpandedImage: (image: CaptureImage) => void;
}

const CaptureSupplementalImage = ({
  image,
  index,
  setSelectedImageIndex,
  setExpandedImage,
}: CaptureSupplementalImageProps) => {
  const thumbnailRequestBody: UseGetCaptureImageTagThumbnailById2Props =
    useMemo(
      () => ({
        id: image.id ?? NaN,
        fit: 'fit-in',
        crop: '%20',
        size: `${300}x${300}`,
        verticalAlign: '%20',
        horizontalAlign: '%20',
        filters: '%20',
        lazy: true,
      }),
      [image]
    );

  const {
    actions: { sendEvent },
  } = useGlobalStore();
  const cacheKey = useMemo(
    () => `capture-supplemental-image-${image.id}`,
    [image]
  );

  const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState<string>();

  const {
    data: thumbnailData,
    refetch: getThumbnail,
    cancel: cancelGetThumbnail,
  } = useGetCaptureImageTagThumbnailById2(
    thumbnailRequestBody
  ) as unknown as UseGetReturn<Response, void, void, unknown>;

  useEffect(() => {
    return () => {
      cancelGetThumbnail();
    };
  }, []);

  useEffect(() => {
    setThumbnailBlobUrl(undefined);

    if (blobCache.has(cacheKey)) {
      setThumbnailBlobUrl(blobCache.get(cacheKey)?.url);
    } else {
      getThumbnail();
    }
  }, [cacheKey]);

  useEffect(() => {
    if (!thumbnailData || blobCache.has(cacheKey)) {
      return;
    }

    const doAsync = async () => {
      const blob = await thumbnailData.blob();
      const url = URL.createObjectURL(blob);
      blobCache.set(cacheKey, { blob, url });
      setThumbnailBlobUrl(url);
    };

    try {
      doAsync();
    } catch (e) {
      console.error(e);
    }
  }, [thumbnailData]);

  return (
    <div
      className={`w-full h-full flex justify-center items-center
    border border-gray-800 rounded overflow-hidden relative`}
      style={{ aspectRatio: '1 / 1' }}
    >
      {!thumbnailBlobUrl ? (
        <FontAwesomeIcon icon={faSpinner} spin className="text-gray-800" />
      ) : (
        <img
          src={thumbnailBlobUrl}
          alt={`Supplemental ${image.id}`}
          className="h-full w-full object-cover cursor-pointer"
          onClick={() => {
            sendEvent(MixpanelNames.SupplementalImagesSectionOpened, {});
            setSelectedImageIndex(index);
          }}
        />
      )}

      {thumbnailBlobUrl && (
        <div
          className="absolute top-0 right-0 border-b border-l border-gray-800 rounded-bl-lg
        px-1 py-0.5 text-xs cursor-pointer bg-white hover:bg-gray-200"
          onClick={() => {
            sendEvent(MixpanelNames.SupplementalImagesExpanded, {});
            setExpandedImage(image);
          }}
        >
          <FontAwesomeIcon icon={faExpand} />
        </div>
      )}
      {thumbnailBlobUrl && (image.note?.length ?? 0) > 0 && (
        <div
          className="absolute bottom-0 left-0 border-t border-r border-gray-800 rounded-tr-lg
        px-1 py-0.5 text-xs bg-white hover:bg-gray-200 cursor-pointer"
          onClick={() => {
            sendEvent(MixpanelNames.SupplementalImagesSectionOpened, {});
            setSelectedImageIndex(index);
          }}
        >
          <FontAwesomeIcon icon={faNote} />
        </div>
      )}
    </div>
  );
};

interface CaptureSupplementalImageDetailsProps {
  image: CaptureImage;
  clearSelectedImageIndex: () => void;
  refetchSupplementalImages: () => void;
}

const CaptureSupplementalImageDetails = ({
  image,
  clearSelectedImageIndex,
  refetchSupplementalImages,
}: CaptureSupplementalImageDetailsProps) => {
  const {
    actions: { sendEvent },
  } = useGlobalStore();
  const { mutate: updateImage } = usePutCaptureImageTag({
    id: NaN,
  });

  const toasts = useToasts();

  const [note, setNote] = useState(image.note ?? '');
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);

  const saveNote = async () => {
    const imageId = image.id ?? NaN;
    if (isNaN(imageId)) return;

    setIsSaving(true);
    try {
      await updateImage({ id: imageId, note }, { pathParams: { id: imageId } });
    } catch (e) {
      console.error(e);
    } finally {
      sendEvent(MixpanelNames.SupplementalImagesNoteEdited, {});
      refetchSupplementalImages();
      setIsSaving(false);
    }
  };

  const archiveImage = async () => {
    setShowConfirmArchive(true);
  };

  const confirmArchiveImage = async () => {
    const imageId = image.id ?? NaN;
    if (isNaN(imageId)) return;

    setIsSaving(true);
    try {
      await updateImage(
        { id: imageId, archived: true },
        { pathParams: { id: imageId } }
      );
    } catch (e) {
      console.error(e);
    } finally {
      sendEvent(MixpanelNames.SupplementalImagesArchived, {});
      refetchSupplementalImages();
      setIsSaving(false);
      setShowConfirmArchive(false);

      toasts.add(toasts.prepare.entityArchived('supplemental image'));
    }
  };

  return (
    <>
      <div className="w-full bg-gray-200 rounded p-1 mb-2 flex flex-col">
        <div className="w-full flex flex-row justify-between items-center pb-1">
          <label htmlFor="supplemental-image-note">Note</label>
          <div className="flex flex-row gap-2">
            <div
              className={`rounded-full hover:bg-gray-400 cursor-pointer
            w-5 h-5 flex justify-center items-center`}
              onClick={() => {
                clearSelectedImageIndex();
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </div>
        <div className="py-2">
          <Input.Text.Area
            id="supplemental-image-note"
            value={note}
            setValue={setNote}
            disabled={isSaving}
          />
        </div>
        <div className="flex flex-row justify-between">
          <PrimaryButton
            size="x-small"
            theme="red"
            label="Delete Image"
            icon={
              isSaving ? (
                <FontAwesomeIcon icon={faCircleNotch} spin={isSaving} />
              ) : (
                <FontAwesomeIcon icon={faTrash} />
              )
            }
            onClicked={archiveImage}
          />
          <PrimaryButton
            size="x-small"
            label="Save Note"
            icon={
              isSaving ? (
                <FontAwesomeIcon icon={faCircleNotch} spin={isSaving} />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )
            }
            onClicked={saveNote}
          />
        </div>
      </div>
      <ConfirmModal
        isOpen={showConfirmArchive}
        title="Confirm Archive Image"
        message="Are you sure you want to archive this image?"
        confirm={{
          label: 'Archive',
          callback: confirmArchiveImage,
        }}
        canConfirm={true}
        close={{
          label: 'Cancel',
          callback: () => setShowConfirmArchive(false),
        }}
      />
    </>
  );
};
