import { Link } from 'react-router-dom';

import { AppSidebar } from '@agerpoint/feature';
import { useGlobalStore, usePageTitle } from '@agerpoint/utilities';

export const OrganizationDetailsPage = () => {
  usePageTitle(() => 'Organization Details', []);

  return (
    <section className="overflow-auto">
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16 bg-white rounded">
        <h2 className="mb-2 text-xl font-semibold leading-none text-gray-900 md:text-2xl">
          Organization Details
        </h2>

        <dl className="flex items-center space-x-6">
          <div className="w-1/2 md:w-1/4">
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Name{' '}
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- </dd>
          </div>
          <div className="w-1/2 md:w-1/4">
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Owner
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">-</dd>
          </div>
        </dl>
        <dl>
          <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
            Usage
          </dt>
          <dd className="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400">
            Cloud Processing Allowance allows users to upload and process raw
            data for analysis.
            <br />
            Cloud Storage Allowance offers storage space for raw data and
            processed outputs.
            <br />
            Analytics Credits assigns credits for applying various analytics to
            user data, with tracking based on capture or acreage.
            <br />
            These credits are paid at the organizational level and consumed at
            the user level, and users can conveniently view their credit usage
            in their account.
          </dd>
        </dl>
        <dl className="flex items-center space-x-6">
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
              Storage
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">&nbsp;</dd>
          </div>
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Allowance
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- GB</dd>
          </div>
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Consumed
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- GB</dd>
          </div>
        </dl>
        <dl className="flex items-center space-x-6">
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
              Processing
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">&nbsp;</dd>
          </div>
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Allowance
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- GB</dd>
          </div>
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Consumed
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- GB</dd>
          </div>
        </dl>
        <dl className="flex items-center space-x-6">
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
              Analytics
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">&nbsp;</dd>
          </div>
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Allowance
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- GB</dd>
          </div>
          <div>
            <dt className="mb-2 font-semibold leading-none text-gray-900">
              Consumed
            </dt>
            <dd className="mb-4 font-light text-gray-500 sm:mb-5">- GB</dd>
          </div>
        </dl>
      </div>
    </section>
  );
};
