import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseMutateReturn } from 'restful-react';

import {
  Customer,
  Project,
  PutProjectPathParams,
  usePutProject,
} from '@agerpoint/api';
import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import {
  Sort,
  useFormValidation,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

interface EditProjectModalProps {
  project?: Project;
  organizations?: Customer[];
  open: boolean;
  handleCloseDialog: () => void;
  onProjectUpdated: (project: Project) => void;
}

export const EditProjectModal = ({
  project,
  organizations,
  open,
  handleCloseDialog,
  onProjectUpdated,
}: EditProjectModalProps) => {
  const { mutate: putProject } = usePutProject({
    uuid: project?.uuid as string,
  }) as unknown as UseMutateReturn<
    Project,
    void,
    Project,
    void,
    PutProjectPathParams
  >;

  const [loading, setLoading] = useState<boolean>(false);

  const toasts = useToasts();

  useEffect(() => {
    if (open && project) {
      setProjectName(project.name);
      setProjectOrganization(project.customer);
      formValidation.advancedCallbacks.reset();
    }
  }, [open, project]);

  const [projectName, setProjectName] = useState<string>('');
  const [projectOrganization, setProjectOrganization] = useState<Customer>();
  const sortedOrganizations = useMemo(
    () => Sort.organizations(organizations),
    [organizations]
  );

  const formValidation = useFormValidation();

  const handleEditProject = useCallback(async () => {
    if (project === undefined) return;

    if (await formValidation.hasErrors()) {
      return;
    }

    setLoading(true);

    try {
      await putProject(
        {
          ...project,
          name: projectName.trim(),
          customerId: projectOrganization?.id as number,
        },
        { pathParams: { uuid: project?.uuid as string } }
      );

      handleCloseDialog();
      onProjectUpdated({
        ...project,
        name: projectName.trim(),
        customer: projectOrganization,
        customerId: projectOrganization?.id as number,
      });
      toasts.add(toasts.prepare.entityUpdated('project'));
    } catch (error) {
      console.error(error);

      toasts.add(toasts.prepare.error('Failed to update project!'));
    } finally {
      setLoading(false);
    }
  }, [
    projectName,
    projectOrganization,
    toasts,
    project,
    putProject,
    handleCloseDialog,
    onProjectUpdated,
    formValidation,
  ]);

  return (
    <DialogModal
      open={open}
      handleCloseDialog={handleCloseDialog}
      size={'small'}
      title={'Edit Project'}
      visibleOverflow={true}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row w-full gap-2 pt-2">
          <div className="flex flex-col flex-1 gap-2">
            <Input.Text.Single
              id="project-name"
              label={<Input.Label label="Project Name" required />}
              value={projectName}
              setValue={setProjectName}
              error={
                <Input.Error error={formValidation.errors['project-name']} />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Name')],
              }}
            />
            <Input.Select.Single
              id="project-organization"
              options={sortedOrganizations ?? []}
              loading={sortedOrganizations === undefined}
              optionBuilder={(customer) =>
                customer.customerDisplayName ??
                customer.customerName ??
                'Unknown'
              }
              label={<Input.Label label="Organization" required />}
              title="Organization"
              value={projectOrganization}
              setValue={setProjectOrganization}
              error={
                <Input.Error
                  error={formValidation.errors['project-organization']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Organization')],
              }}
              compareFn={(a, b) => a.id === b.id}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <PrimaryButton
            label="Save"
            onClicked={() => {
              if (loading) return;

              handleEditProject();
            }}
            disabled={loading}
          />
        </div>
      </div>
    </DialogModal>
  );
};
