import { Sidebar2SectionProps } from '@agerpoint/types';

export const Sidebar2Section = ({
  title,
  children,
  leading,
  trailing,
  info,
  onClick,
}: Sidebar2SectionProps) => {
  const onClickStyle = onClick
    ? 'hover:bg-gray-100 cursor-pointer transition-colors'
    : '';

  return (
    <div className="w-full flex flex-col bg-white shadow">
      <div
        className={`w-full h-10 bg-white flex ${onClickStyle}`}
        onClick={onClick}
      >
        <div className="w-full flex items-center justify-between p-4 gap-2">
          <span className="flex flex-row items-center gap-1">
            <span className="w-6 h-6 text-lg text-gray-600 flex items-center justify-center">
              {leading}
            </span>
            <span className="whitespace-nowrap">{title}</span>
          </span>
          <span className="flex flex-row items-center truncate gap-1">
            <span className="flex items-center truncate">
              <span className="truncate text-xs text-gray-500">{info}</span>
            </span>
            {trailing && (
              <span className="w-4 h-4 flex justify-center items-center">
                {trailing}
              </span>
            )}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};
