import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { RgbaStringColorPicker } from 'react-colorful';

import { Capture } from '@agerpoint/api';
import {
  ExpansionContainerOuter,
  ExpansionContentContainer,
  ExpansionContentTabContentContainer,
  ExpansionContentTabsContainer,
  IconButton,
} from '@agerpoint/component';
import { CaptureExpansionPanels } from '@agerpoint/types';
import {
  cvtRgbaArrToRgbaStr,
  cvtRgbaPctToStdRgba,
  cvtRgbaStrToRgbaArr,
  cvtStdRgbaToRgbaPct,
  useGlobalStore,
} from '@agerpoint/utilities';

import { StyleProperty } from '../layer-style/style-property';
import { ColorValue } from '../layer-style/vector-styles';
import './captures-job-expansion.scss';

export const CapturesJobExpansionPanel = ({
  // isSidebarOpen,
  isExpansionPanelOpen,
  capture,
  openExpansionPanel,
}: {
  isSidebarOpen: boolean;
  isExpansionPanelOpen: boolean;
  capture: Capture;
  openExpansionPanel: (whichPanel: CaptureExpansionPanels) => void;
}) => {
  const {
    capturesViewer: {
      actions: { setCaptureMaterialClassificationColor },
      captureMaterialClassificationColors,
      captureMaterialClassificationSelected,
    },
  } = useGlobalStore();
  const [mode, setMode] = useState<string>('fillColor');
  const [internalColor, setInternalColor] = useState<string>('');
  const toggleExpansionPanel = () => {
    openExpansionPanel(CaptureExpansionPanels.CaptureColorSelector);
  };

  const internalOnChange = useCallback(
    (color: string) => {
      const rgbaArr = cvtRgbaStrToRgbaArr(color);
      const colorAsPctArr = cvtStdRgbaToRgbaPct(rgbaArr);
      setInternalColor(color);
      setCaptureMaterialClassificationColor(
        captureMaterialClassificationSelected,
        colorAsPctArr,
        true
      );
    },
    [captureMaterialClassificationSelected]
  );

  useEffect(() => {
    const colorAsStdRgba = cvtRgbaPctToStdRgba(
      captureMaterialClassificationColors[captureMaterialClassificationSelected]
        ?.color || [0, 0, 0, 0]
    );
    const strRgba = cvtRgbaArrToRgbaStr(colorAsStdRgba);

    setInternalColor(strRgba);
  }, [captureMaterialClassificationSelected]);

  return isExpansionPanelOpen ? (
    <div
      className={`absolute bg-white h-full top-0 overflow-auto left-sidebar w-sidebar-expansion`}
    >
      <div
        className="h-full relative"
        onClick={(e) => {
          // do this so we dont click the three canvas
          e.stopPropagation();
        }}
      >
        <div className="shadow-heading flex justify-between items-center px-4 pt-3 leading-4 pb-2">
          <h2 className="font-bold w-full border-b border-transparent truncate">
            {capture?.captureName}
          </h2>
          <IconButton
            show
            onClick={toggleExpansionPanel}
            className={`w-7 h-7 `}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                className="text-2xl text-gray-600 hover:text-gray-900"
              />
            }
          />
        </div>
        <ExpansionContainerOuter>
          <ExpansionContentContainer>
            <ExpansionContentTabsContainer>
              <StyleProperty
                name="Fill Color"
                value={<ColorValue color={internalColor} />}
                selected={mode === 'fillColor'}
                onSelect={() => setMode('fillColor')}
              />
            </ExpansionContentTabsContainer>
            <ExpansionContentTabContentContainer>
              <RgbaStringColorPicker
                color={internalColor}
                onChange={internalOnChange}
                className="captures-job-expansion-panel__color-picker"
              />
            </ExpansionContentTabContentContainer>
          </ExpansionContentContainer>
        </ExpansionContainerOuter>
      </div>
    </div>
  ) : null;
};
