import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Capture,
  CaptureJob,
  useGetCaptureJobsByCaptureId,
} from '@agerpoint/api';
import {
  ExpansionContainerOuter,
  ExpansionContentContainer,
  ExpansionContentTabContentContainer,
  ExpansionContentTabsContainer,
  IconButton,
} from '@agerpoint/component';
import {
  CaptureExpansionPanels,
  CaptureJobTypes,
  CapturesUrlParams,
  Options,
} from '@agerpoint/types';
import { getDateString } from '@agerpoint/utilities';

export const CapturesViewerExpansionPanel = ({
  isSidebarOpen,
  isExpansionPanelOpen,
  capture,
  openExpansionPanel,
}: {
  isSidebarOpen: boolean;
  isExpansionPanelOpen: boolean;
  capture: Capture;
  openExpansionPanel: (whichPanel: CaptureExpansionPanels) => void;
}) => {
  const params = useParams();
  const [currentEptId, setCurrentEptId] = useState<number | undefined>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [jobList, setJobList] = useState<Options[]>([]);
  const [selectedJob, setSelectedJob] = useState<CaptureJob>();
  const [showDetails, setShowDetails] = useState(true);

  const { data } = useGetCaptureJobsByCaptureId({
    captureId: capture.id as number,
  }) as unknown as { data: CaptureJob[] };

  useEffect(() => {
    if (!data) return;
    const tempJobList = data
      .filter((job) => job?.eptPointcloudId)
      .map((job) => {
        return {
          value: job.eptPointcloudId || 0,
          name: `${CaptureJobTypes[job?.captureJobTypeId || 0]} - ${
            job?.eptPointcloudId
          }`,
        };
      });
    setJobList(tempJobList);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    const job = data.find((job) => job.eptPointcloudId === currentEptId);
    setSelectedJob(job);
  }, [currentEptId, data]);

  useEffect(() => {
    if (params.eptId) {
      setCurrentEptId(parseInt(params.eptId));
    }
  }, [params]);

  const openExpansionPanelClicked = () => {
    // close the panel
    openExpansionPanel(CaptureExpansionPanels.CaptureJobInfo);
  };

  return isExpansionPanelOpen ? (
    <div
      className={`absolute bg-white h-full top-0 overflow-auto left-sidebar w-sidebar-expansion`}
    >
      <div
        className="h-full relative"
        onClick={(e) => {
          // do this so we dont click the three canvas
          e.stopPropagation();
        }}
      >
        <div className="shadow-heading flex justify-between items-center px-4 pt-3 leading-4 pb-2">
          <h2
            title="parashometree"
            className="font-bold w-full border-b border-transparent truncate"
          >
            {capture?.captureName}
          </h2>
          <IconButton
            show
            onClick={openExpansionPanelClicked}
            className={`w-7 h-7 `}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                className="text-2xl text-gray-600 hover:text-gray-900"
              />
            }
          />
        </div>
        <ExpansionContainerOuter>
          <ExpansionContentContainer>
            <ExpansionContentTabsContainer>
              <div
                className={`px-4 py-3 text-gray-800 text-sm ${
                  selectedTab === 0 ? 'border-b' : 'border-b border-r'
                } border-gray-300 cursor-pointer`}
                onClick={() => setSelectedTab(0)}
              >
                <h3 className="font-bold mb-2 leading-3">Current View</h3>
                <div
                  className={`truncate ${
                    selectedTab === 0
                      ? 'text-system-gray-600'
                      : 'text-system-gray-400'
                  }`}
                >
                  {capture?.completedJobs?.[0]?.name}
                </div>
                <div
                  className={`${
                    selectedTab === 0
                      ? 'text-system-gray-600'
                      : 'text-system-gray-400'
                  }`}
                >
                  {getDateString(
                    capture?.completedJobs?.[0]?.completedDateTime
                  )}
                </div>
              </div>
            </ExpansionContentTabsContainer>
            <ExpansionContentTabContentContainer>
              <div className="text-sm text-gray-800 font-bold underline cursor-pointer">
                Details
              </div>
              {showDetails && (
                <div className="text-sm">
                  <dl className="">
                    <dt className="text-sm text-gray-800 font-bold">
                      Job Name
                    </dt>
                    <dd className="pl-2">{selectedJob?.name}</dd>
                    <dt className="text-sm text-gray-800 font-bold">
                      Create Date
                    </dt>
                    <dd className="pl-2">
                      {getDateString(selectedJob?.createDatetime)}
                    </dd>
                    <dt className="text-sm text-gray-800 font-bold">
                      Completed Date
                    </dt>
                    <dd className="pl-2">
                      {getDateString(selectedJob?.completedDateTime)}
                    </dd>
                    <dt className="text-sm text-gray-800 font-bold">
                      Job Type
                    </dt>
                    <dd className="pl-2">
                      {selectedJob?.captureJobTypeId
                        ? CaptureJobTypes[selectedJob?.captureJobTypeId]
                        : ''}
                    </dd>
                    <dt className="text-sm text-gray-800 font-bold">UUID</dt>
                    <dd className="pl-2">{selectedJob?.uuid}</dd>
                    <dt className="text-sm text-gray-800 font-bold">EPT ID</dt>
                    <dd className="pl-2">{selectedJob?.eptPointcloudId}</dd>
                  </dl>
                </div>
              )}
            </ExpansionContentTabContentContainer>
          </ExpansionContentContainer>
        </ExpansionContainerOuter>
      </div>
    </div>
  ) : null;
};
