import {
  faCamera,
  faCube,
  faImages,
  faTreeDeciduous,
} from '@fortawesome/pro-light-svg-icons';
import {
  faInputText,
  faRectangleWide,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CaptureJob,
  CaptureObject,
  useGetCaptureJobsByCaptureId,
  useGetCaptureObjectsByCaptureId,
} from '@agerpoint/api';
import { GroupToggle, Input } from '@agerpoint/component';
import {
  CaptureExpansionPanels,
  EffectNames,
  EventBusNames,
  IViewer,
  MixpanelNames,
  ViewerTypeOld,
} from '@agerpoint/types';
import {
  eventBus,
  useActionListener,
  useGlobalStore,
} from '@agerpoint/utilities';

import { EllipsisIconButton } from '../../icon-buttons';
import { CaptureJobSidebar } from '../capture-jobs-sidebar/capture-jobs-sidebar';
import { CaptureObjectSidebar } from '../capture-object-sidebar/capture-object-sidebar';
import { CaptureSupplementalImages } from '../capture-supplemental-images/capture-supplemental-images';
import './captures-viewer-sidebar-ddd-layers.scss';

/**
 * @deprecated
 */
export const CapturesViewerThreeDLayerControls = ({
  openExpansionPanel,
  eptId,
  captureId,
  viewer,
}: {
  openExpansionPanel: (whichPanel: CaptureExpansionPanels) => void;
  eptId: number | undefined;
  captureId: number;
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
}) => {
  const navigate = useNavigate();
  const {
    data: captureObjectsApiResponse,
    loading: captureObjectsLoading,
    refetch: captureObjectsRefetch,
  } = useGetCaptureObjectsByCaptureId({
    id: captureId,
  }) as unknown as {
    data: CaptureObject[];
    refetch: () => void;
    loading: boolean;
  };

  const { data: captureJobs, refetch: refetchCaptureJobs } =
    useGetCaptureJobsByCaptureId({
      captureId: captureId,
    }) as unknown as { data: CaptureJob[]; refetch: () => void };

  const handleRefreshCaptureJobs = useCallback(() => {
    refetchCaptureJobs();
  }, [refetchCaptureJobs]);

  useActionListener(EffectNames.REFRESH_CAPTURE_JOBS, handleRefreshCaptureJobs);

  const {
    captureObjectMarkers,
    actions: { setCaptureObjectMarkers, sendEvent },
    capturesViewer: { viewerType },
  } = useGlobalStore();

  const [showCameraPosition, setShowCameraPosition] = useState(true);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [showObjectDetails, setShowObjectDetails] = useState(false);
  const [showSupplementalImagesSection, setShowSupplementalImagesSection] =
    useState(false);
  const [showCaptureObjectsAllMarkers, setShowCaptureObjectsAllMarkers] =
    useState(true);
  const [showCaptureObjectsAllLabels, setShowCaptureObjectsAllLabels] =
    useState(true);
  const [showCaptureJobs, setShowCaptureJobs] = useState(false);
  const [showPointCloud, setShowPointCloud] = useState(true);

  useEffect(() => {
    if (showCameraPosition) {
      eventBus.dispatch(EventBusNames.CaptureCameraPositionShowAllClicked, {
        detail: undefined,
      });
    } else {
      eventBus.dispatch(EventBusNames.CaptureCameraPositionHideAllClicked, {
        detail: undefined,
      });
    }
  }, [showCameraPosition]);

  useEffect(() => {
    if (eptId) {
      setShowCameraPosition(true);
      setShowPointCloud(true);
    }
  }, [eptId]);

  useEffect(() => {
    if (!viewer || !viewer?.current?.togglePointCloudVisibility) return;
    viewer?.current?.togglePointCloudVisibility(showPointCloud);
  }, [showPointCloud, eptId]);

  const toggleCaptureObjectAllMarkers = () => {
    setShowCaptureObjectsAllMarkers(!showCaptureObjectsAllMarkers);
  };

  useEffect(() => {
    let toggledOn = false;
    captureObjectMarkers.forEach((co) => {
      if (co.showLabel) {
        toggledOn = true;
      }
    });
    setShowCaptureObjectsAllLabels(toggledOn);
  }, [captureObjectMarkers]);

  const toggleAllMarkerLabels = () => {
    sendEvent(MixpanelNames.CaptureObjectLabelToggleClicked);
    const newCaptureObjects = captureObjectMarkers.map((co) => {
      const show = !showCaptureObjectsAllLabels;
      const coId = co.id.toString();
      viewer?.current?.toggleTextLabelById(coId, show);
      return { ...co, showLabel: show };
    });
    setCaptureObjectMarkers(newCaptureObjects);
  };

  return (
    <>
      <div
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        className="border-gray-300 w-full border-t bg-white shadow-sm py-1"
      >
        <div
          className="w-full h-10 flex items-center pl-2"
          onClick={() => {
            setShowCaptureJobs(!showCaptureJobs);
          }}
        >
          <span
            className="mx-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowPointCloud(!showPointCloud);
            }}
          >
            <Input.Checkbox
              id="show-point-cloud"
              value={showPointCloud}
              setValue={() => {
                //
              }}
            />
          </span>
          <div className="flex-grow flex items-center justify-between ">
            <div className="flex justify-between items-center max-h-24">
              <div className="flex justify-center pl-2 ">
                <FontAwesomeIcon icon={faCube} className="fa-l" />
              </div>
              <div className="m-auto ml-2 mr-2 h-auto max-h-20 overflow-hidden line-clamp-2 cursor-pointer">
                3D Model
              </div>
            </div>
            <div className="flex pr-3">
              <EllipsisIconButton
                show={mouseOver}
                onClick={() => {
                  navigate('./models');
                }}
                className="block h-full"
              />
              <GroupToggle isOpen={showCaptureJobs} />
            </div>
          </div>
        </div>
        {showCaptureJobs && eptId && (
          <CaptureJobSidebar
            captureJobs={captureJobs}
            captureId={captureId}
            eptId={eptId}
            viewer={viewer}
            openExpansionPanel={openExpansionPanel}
          />
        )}
      </div>

      {viewerType === ViewerTypeOld.POTREE && (
        <>
          <div className="border-gray-300 w-full border-t bg-white shadow-sm py-1">
            <div
              className="w-full h-10 flex items-center pl-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowObjectDetails(!showObjectDetails);
              }}
            >
              <span
                className="mx-1"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleCaptureObjectAllMarkers();
                }}
              >
                {captureObjectsLoading ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="w-4 h-4 text-gray-800 spin"
                  />
                ) : (
                  <Input.Checkbox
                    id="show-capture-objects"
                    value={showCaptureObjectsAllMarkers}
                    setValue={() => {
                      //
                    }}
                  />
                )}
              </span>
              <div className="flex-grow flex items-center justify-between ">
                <div className="flex justify-between items-center max-h-24">
                  <div className="flex justify-center pl-2 fa-gradient-parent">
                    <FontAwesomeIcon icon={faTreeDeciduous} className="fa-l" />
                  </div>
                  <div
                    className={`m-auto ml-2 mr-2 h-auto max-h-20 overflow-hidden line-clamp-2 cursor-pointer`}
                  >
                    Capture Objects
                  </div>
                  {(captureObjectMarkers?.length ?? 0) > 0 && (
                    <button
                      className="text-gray-400 hover:text-gray-800"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleAllMarkerLabels();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={
                          showCaptureObjectsAllLabels
                            ? faInputText
                            : faRectangleWide
                        }
                      />
                    </button>
                  )}
                </div>
                <div className="flex pr-3">
                  <GroupToggle isOpen={showObjectDetails} />
                </div>
              </div>
            </div>
            <CaptureObjectSidebar
              viewer={viewer}
              captureObjectsData={captureObjectsApiResponse}
              show={showObjectDetails}
              showAllMarkers={showCaptureObjectsAllMarkers}
              eptId={eptId}
              captureId={captureId}
              refetch={captureObjectsRefetch}
            />
          </div>
          <div className="border-gray-300 w-full border-t bg-white shadow-sm py-1">
            <div className="w-full h-10 flex items-center pl-2">
              <span className="mx-1">
                <Input.Checkbox
                  id="show-camera-position"
                  value={showCameraPosition}
                  setValue={(value) => setShowCameraPosition(value)}
                />
              </span>
              <div className="flex-grow flex items-center justify-between ">
                <div className="flex justify-between items-center max-h-24">
                  <div className="flex justify-center pl-2">
                    <FontAwesomeIcon icon={faCamera} className="fa-l" />
                  </div>
                  <div
                    className={`m-auto ml-2 mr-2 h-auto max-h-20 overflow-hidden line-clamp-2 cursor-default`}
                  >
                    Camera Positions
                  </div>
                </div>
                <div className="flex pr-4">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="fa-l camera-position-circle"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-gray-300 w-full border-t bg-white shadow-sm py-1">
            <div
              className="w-full h-10 flex items-center pl-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowSupplementalImagesSection(
                  !showSupplementalImagesSection
                );
              }}
            >
              <span className="mx-1">
                <Input.Checkbox
                  id="show-supplemental-images"
                  value={false}
                  setValue={() => null}
                  disabled={true}
                />
              </span>
              <div className="flex-grow flex items-center justify-between ">
                <div className="flex justify-between items-center max-h-24">
                  <div className="flex justify-center pl-2 fa-gradient-parent">
                    <FontAwesomeIcon icon={faImages} className="fa-l" />
                  </div>
                  <div
                    className={`m-auto ml-2 mr-2 h-auto max-h-20 overflow-hidden line-clamp-2 cursor-pointer`}
                  >
                    Supplemental Images
                  </div>
                </div>
                <div className="flex pr-3">
                  <GroupToggle isOpen={showSupplementalImagesSection} />
                </div>
              </div>
            </div>
            <CaptureSupplementalImages
              show={showSupplementalImagesSection}
              captureId={captureId}
            />
          </div>
        </>
      )}
    </>
  );
};
