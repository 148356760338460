import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import {
  Customer,
  CustomerLicense,
  User,
  useGetCustomer,
  useGetCustomerLicenseByCustomerId,
} from '@agerpoint/api';
import { AppBar } from '@agerpoint/feature';
import { AppBarProps, UserClaims } from '@agerpoint/types';
import {
  environment,
  getDatePickerFormattedFromUtc,
  hasClaims,
  usePageTitle,
} from '@agerpoint/utilities';
import { getAppBarProps, useGlobalStore } from '@agerpoint/utilities';

export const Settings = () => {
  const { user, permissions } = useGlobalStore();

  return (
    <SettingsPage
      appBarProps={getAppBarProps(user || ({} as User))}
      user={user}
    ></SettingsPage>
  );
};

interface SettingsPageProps {
  appBarProps: AppBarProps;
  user: User | null;
}

const SettingsPage = ({ appBarProps, user }: SettingsPageProps) => {
  usePageTitle(() => 'Profile', []);

  const [customerId, setCustomerId] = useState<number>(NaN);
  const { data: customers } = useGetCustomer({});
  const { data: rawCustomerLicense, refetch } =
    useGetCustomerLicenseByCustomerId({
      customerId: customerId,
      lazy: true,
    });
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [clientLicense, setClientLicense] = useState<CustomerLicense>({});
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [updatedDate, setUpdatedDate] = useState<string>('');

  useEffect(() => {
    setClientLicense(rawCustomerLicense || {});
  }, [rawCustomerLicense]);

  useEffect(() => {
    if (!customers) {
      return;
    }
    const filteredList = [...new Map(customers.map((v) => [v.id, v])).values()];
    setFilteredCustomers(filteredList);
  }, [customers]);

  useEffect(() => {
    if (!user) {
      setIsAdmin(false);
      return;
    }
    const isAdmin = hasClaims(
      [UserClaims.AgerAdmin],
      user?.cloudClaims as UserClaims[]
    );
    setIsAdmin(isAdmin);
  }, [user]);

  useEffect(() => {
    if (filteredCustomers.length !== 1 || isAdmin) return;
    if (!filteredCustomers[0].id) return;
    setCustomerId(filteredCustomers[0].id);
  }, [filteredCustomers, isAdmin]);

  useEffect(() => {
    refetch();
  }, [customerId, refetch]);

  useEffect(() => {
    if (clientLicense.subsciptionStartDateTime) {
      const start = getDatePickerFormattedFromUtc(
        new Date(clientLicense.subsciptionStartDateTime)
      );
      setStartDate(start);
    }
    if (clientLicense.subsciptionEndDateTime) {
      const end = getDatePickerFormattedFromUtc(
        new Date(clientLicense.subsciptionEndDateTime)
      );
      setEndDate(end);
    }
    if (clientLicense?.updateDatetime) {
      const update = getDatePickerFormattedFromUtc(
        new Date(clientLicense.updateDatetime)
      );
      setUpdatedDate(update);
    }
  }, [clientLicense]);

  return (
    <>
      <AppBar {...appBarProps} />
      <section className="overflow-auto">
        <div className="pt-8 px-4 mx-auto max-w-2xl lg:pt-16 bg-white rounded">
          <h2 className="mb-2 text-xl font-semibold leading-none text-gray-900 md:text-2xl">
            Help
          </h2>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
                <span>Email Support</span>
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {' '}
                <a
                  className="hover:underline"
                  href="mailto:support@agerpoint.com"
                >
                  {' '}
                  support@agerpoint.com{' '}
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </dd>
            </div>
          </dl>
          <h2 className="mb-2 text-xl font-semibold leading-none text-gray-900 md:text-2xl">
            Profile
          </h2>
          <dl className="flex items-center space-x-6">
            <div className="w-1/2 md:w-1/4">
              <dt className="mb-2 font-semibold leading-none text-gray-900 ">
                First Name
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {user?.userProfiles?.[0].firstName}
              </dd>
            </div>
            <div className="w-1/2 md:w-1/4">
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Last Name
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {user?.userProfiles?.[0].lastName}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Email
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {user?.email}
              </dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Username
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {user?.userName}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Password
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                <a
                  href={`${environment.authentication_server_url}/Account/ForgotPassword`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Reset <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Units
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {user?.userProfiles?.[0].isMetric ? 'Metric' : 'Imperial'}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Organization
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {isAdmin ? (
                  <span>Admin (all orgs)</span>
                ) : (
                  filteredCustomers?.map((customer) => (
                    <span key={customer.id}>{customer.customerName}, </span>
                  ))
                )}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Teams
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                -
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Subscription Tier
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {clientLicense?.subscriptionTier || '-'}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Subscription Start
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {startDate || '-'}
              </dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Subscription Expiration
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {endDate || '-'}
              </dd>
            </div>
          </dl>
          <h2 className="mb-2 text-xl font-semibold leading-none text-gray-900 md:text-2xl">
            Data Usage
          </h2>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Last updated
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 truncate">
                {updatedDate || '-'}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
                Storage
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">&nbsp;</dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Allowance
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {clientLicense?.storageAllowance || '-'}
              </dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Consumed
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {clientLicense?.storageConsumed || '-'}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
                Processing
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">&nbsp;</dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Allowance
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {clientLicense?.processingAllowance || '-'}
              </dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Consumed
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {clientLicense?.processingConsumed || '-'}
              </dd>
            </div>
          </dl>
          <dl className="flex items-center space-x-6">
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900 w-28">
                Analytics
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">&nbsp;</dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Allowance
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {' '}
                {clientLicense?.analyticCreditsAllowance || '-'}
              </dd>
            </div>
            <div>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Consumed
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {clientLicense?.analyticCreditsConsumed || '-'}
              </dd>
            </div>
          </dl>
        </div>
      </section>
    </>
  );
};
