import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { Coordinate } from 'ol/coordinate';
import Point from 'ol/geom/Point';
import { useMemo, useState } from 'react';

import { CloudOpenlayersMap } from '../openlayers-map';
import { ICloudOpenlayersFeatureLayer } from '../openlayers-map.types';

export const useUserLocationLayer = () => {
  const [userLocation, setUserLocation] = useState<Coordinate | undefined>();

  const userLocationLayer: ICloudOpenlayersFeatureLayer<
    Coordinate | undefined
  > = useMemo(
    () => ({
      featureLayerId: 'user-location',
      data: userLocation ? [userLocation] : [],
      featureBuilder: (location) => {
        if (!location) {
          return undefined;
        }

        return {
          id: 'user-location',
          geometry: new Point(location),
          style: 'default',
        };
      },
      styles: {
        default: CloudOpenlayersMap.GeomStyles.Point.dot({
          color: '#339BC0',
          radius: 10,
          borderThickness: 2,
        }),
      },
    }),
    [userLocation]
  );

  return { userLocationLayer, setUserLocation, userLocation };
};
