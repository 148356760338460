import { useState } from 'react';

import {
  ToggleableSidebar,
  ToggleableSidebarContent,
} from '@agerpoint/component';

import { AppSidebarBottomItems } from './app-sidebar-bottom-items';
import { AppSidebarNav } from './app-sidebar-nav';
import { AppSidebarStandardItems } from './app-sidebar-routes';

export const AppSidebar = ({ children }: { children: JSX.Element }) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  return (
    <>
      <AppSidebarNav
        expanded={sidebarExpanded}
        setExpanded={setSidebarExpanded}
      />
      <ToggleableSidebar
        expanded={sidebarExpanded}
        items={<AppSidebarStandardItems />}
        bottomItems={<AppSidebarBottomItems />}
        paddingTop={48}
      />
      <ToggleableSidebarContent expanded={sidebarExpanded}>
        {children}
      </ToggleableSidebarContent>
    </>
  );
};
