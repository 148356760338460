import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useAdminMLModelsQueries = (archivedFilter?: boolean) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const toasts = useToasts();

  const isViteApp = useIsViteApp();

  const { mlModelId } = useParams();

  const mlModelQuery = APIClient.useGetMlModelById(Number(mlModelId), {
    query: {
      enabled: Number.isSafeInteger(Number(mlModelId)),
      queryKey: [APIUtils.QueryKey.mlModels, { mlModelId: Number(mlModelId) }],
      initialData: () =>
        APIUtils.searchQueriesForInitialValue<APIModels.MlModel>({
          queryClient,
          queryKey: [APIUtils.QueryKey.mlModels],
          id: Number(mlModelId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const pipelinesQuery = APIClient.useGetPipelines({
    query: {
      queryKey: [APIUtils.QueryKey.pipelines],
      select: (data) => APIUtils.Sort.pipelines(data),
    },
  });

  const pipelinesLookupTable = useLookupTable(pipelinesQuery.data, 'id');

  const mlModelPutMutation = APIClient.usePutMlModelById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mlModels],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.MlModel>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mlModels,
            { mlModelId: variables.data.id },
          ],
          data: variables.data,
        });

        APIUtils.updateListQueryCache<APIModels.MlModel>({
          queryClient,
          queryKey: [APIUtils.QueryKey.mlModels, { archived: false }],
          data: variables.data,
          accessor: 'id',
          id: variables.data.id,
        });

        APIUtils.updateListQueryCache<APIModels.MlModel>({
          queryClient,
          queryKey: [APIUtils.QueryKey.mlModels, { archived: true }],
          data: variables.data,
          accessor: 'id',
          id: variables.data.id,
        });

        toasts.add(toasts.prepare.entityUpdated('ML model'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create ML model!'));
      },
    },
  });

  const mlModelArchivePutMutation = APIClient.usePutMlModelById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mlModels],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.MlModel>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mlModels,
            { mlModelId: variables.data.id },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityArchived('ML model'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive ML model!'));
      },
    },
  });

  const mlModelUnarchivePutMutation = APIClient.usePutMlModelById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mlModels],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.MlModel>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mlModels,
            { mlModelId: variables.data.id },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityRestored('ML model'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to restore ML model!'));
      },
    },
  });

  useEffect(() => {
    if (mlModelId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(mlModelId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/ml-models' + params);
      } else {
        navigate('/admin/ml-models' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.mlModels,
          { mlModelId: Number(mlModelId) },
        ],
      });
    }
  }, [mlModelId]);

  const mlModelPostMutation = APIClient.usePostMlModel({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mlModels],
        });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.MlModel>({
          queryClient,
          queryKey: [APIUtils.QueryKey.mlModels, { mlModelId: data.id }],
          data: data,
        });
        toasts.add(toasts.prepare.entityCreated('ML model'));

        if (isViteApp) {
          navigate(`/app/admin/platform/ml-models/${data.id}/details`, {
            state: { params },
          });
        } else {
          navigate(`/admin/ml-models/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create ML model!'));
      },
    },
  });

  const mlModelsQuery = APIClient.useGetMlModels({
    query: {
      queryKey: [APIUtils.QueryKey.mlModels, { archived: archivedFilter }],
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      select: (data) => data.filter((d) => d.archived === archivedFilter),
      enabled: archivedFilter !== undefined,
    },
  });

  return {
    pipelinesQuery,
    mlModelQuery,
    mlModelPutMutation,
    mlModelArchivePutMutation,
    mlModelUnarchivePutMutation,
    mlModelsQuery,
    pipelinesLookupTable,
    mlModelPostMutation,
  };
};
