import { faRightFromBracket, faUser } from '@fortawesome/pro-regular-svg-icons';
import { ToggleableSidebarBottomItem } from 'libs/component/src/toggleable-sidebar/toggleable-sidebar';
import mixpanel from 'mixpanel-browser';
import { useAuth } from 'oidc-react';

export const AppSidebarBottomItems = () => {
  const appVersion = process.env.VERSION;
  const { userData, signOutRedirect } = useAuth();

  return (
    <>
      <ToggleableSidebarBottomItem
        title="Profile"
        icon={faUser}
        route={'/profile'}
      />
      <ToggleableSidebarBottomItem
        title="Sign Out"
        icon={faRightFromBracket}
        action={() => {
          mixpanel.reset();
          signOutRedirect({ id_token_hint: userData?.id_token });
        }}
      />
      <div className="text-xs text-gray-500 text-center flex flex-grow items-end justify-end">
        Version - {appVersion}
      </div>
    </>
  );
};
