import {
  ExtrudeGeometry,
  Group,
  Material,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  ShapePath,
  Sprite,
  SpriteMaterial,
  TextureLoader,
  Vector3,
} from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';

import {
  Annotation3dPoints,
  ColorsThreeD,
  CustomGroup,
  CustomSprite,
  EventBusNames,
  HexColor,
  ICustomMesh,
  IPoint3d,
} from '@agerpoint/types';
import {
  AnnotationGroupName,
  CustomMesh,
  createSphereObject,
} from '@agerpoint/utilities';

import { GeometryBase } from '../geometry.base';

export class Point3d extends GeometryBase implements IPoint3d {
  readonly object: ICustomMesh | CustomGroup<ICustomMesh>; // Use a more generic type to accommodate both Mesh and Sprite
  public type: Annotation3dPoints;
  readonly uniqueId: string;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    id: string,
    position: Vector3,
    type: Annotation3dPoints,
    isSprite: boolean = false,
    color: ColorsThreeD | HexColor = ColorsThreeD.Cyan,
    visible: boolean = true,
    isPotree: boolean = false,
    name: string = ''
  ) {
    super(scene, perspectiveCamera, isPotree);
    this.name = name;
    this.uniqueId = id;
    this.type = type;
    this.color = color;
    this.object = isSprite
      ? this.createMeshLocationMarker(id, position, color, visible)
      : this.createMeshSphere(id, position, color, visible);
    if (!this.scene) return;
    this.init();
  }

  get isVisible() {
    return this.object.visible;
  }

  /**
   * Disposes of the geometry and removes the object from the scene.
   */
  dispose() {
    if (!this.scene) return;
    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (!group) return;
    if (this.object instanceof Group) {
      this.object.traverse((child) => {
        if (child instanceof Mesh) {
          if (child.isMesh) {
            if (child.geometry) {
              child.geometry.dispose(); // Dispose geometry
            }
            if (child.material) {
              if (child.material instanceof Material) {
                child.material.dispose(); // Dispose material
              } else if (Array.isArray(child.material)) {
                // In case of multi-materials
                child.material.forEach((material) => material.dispose());
              }
            }
          }
        }
      });
      group.remove(this.object);
    } else {
      if (this?.object?.parent?.remove) {
        this.object.parent.remove(this.object);
      }
      this.object.geometry.dispose();
      group.remove(this.object);
    }
  }

  /**
   * Disposes and deletes the object.
   */
  disposeAndDelete() {
    this.dispose();
    this.delete();
    this.notifyListeners();
  }

  /**
   * persists deletion to the database
   */
  delete() {
    this.removePoint3dById(this.uniqueId);
    this.annoMgr.deleteCapObj(this.uniqueId);
  }

  /**
   * Updates the color of the object.
   *
   * @param color - The new color to set.
   * @param persist - Whether to persist the color change.
   */
  updateColor(color: ColorsThreeD | HexColor, persist = true) {
    if (!this.object) return;
    this.color = color;
    if (persist) {
      this.annoMgr.updateCapObjColor(this.uniqueId, color);
      this.notifyListeners();
    }
    if (this.object instanceof Group) {
      this.object.traverse((object) => {
        if (object instanceof Mesh) {
          object.material = new MeshBasicMaterial({ color });
        }
      });
      return;
    }
    this.object.material = new MeshBasicMaterial({ color });
  }

  /**
   * Highlights the object with a specified color.
   *
   *
   */
  highlight(color?: ColorsThreeD | HexColor) {
    this.userData.originalColor = this.color;
    this.userData.originalScale = this.object.scale.clone();
    this.updateColor(color || this.highlightColor, false);
    if (this.type === Annotation3dPoints.AnnotationPoint) {
      this.object.scale.set(0.002, 0.002, 0.002);
    } else {
      this.object.scale.set(1.5, 1.5, 1.5);
    }
    this.setSelectedObject(this.uniqueId, this.type);
  }

  /**
   * Removes the highlight from the object.
   */
  unHighlight() {
    if (this?.userData?.originalColor) {
      this.updateColor(this.userData.originalColor, false);
    }
    if (this?.userData?.originalScale) {
      this.object.scale.copy(this.userData.originalScale);
    }
  }

  /**
   * Hides the object.
   */
  hide() {
    if (!this.object) return;
    this.object.visible = false;
    this.notifyListeners();
  }

  /**
   * Shows the object.
   */
  show() {
    if (!this.object) return;
    this.object.visible = true;
    this.notifyListeners();
  }

  /**
   * Updates the visibility of the object.
   *
   * @param isVisible - Whether the object should be visible.
   */
  updateVisibility(isVisible: boolean) {
    if (!this.object) return;
    this.object.visible = isVisible;
    this.notifyListeners();
  }

  /**
   * Updates the position of the object.
   *f
   * @param pos - The new position to set.
   */
  updatePosition(pos: Vector3) {
    if (!this.object) return;
    this.object.updatePosition(pos);
    // face the camera
    this.object.lookAt(this.perspectiveCamera.position);

    this.object.rotateX(Math.PI);
    this.emit('positionChange', pos);
  }

  updateLookAt() {
    if (!this.object) return;

    this.object.lookAt(this.perspectiveCamera.position);
    if (this.perspectiveCamera.up.equals(new Vector3(0, 0, 1))) {
      this.object.rotateX(Math.PI);
    }
  }

  updateType(type: Annotation3dPoints) {
    this.type = type;
  }

  zoomTo() {
    this.doZoom(this.object);
  }

  private spriteOffsetPosition(scale: Vector3, pos: Vector3) {
    // Adjust sprite position after texture has loaded
    // this depends on the camera up vector
    const cameraUp = this.perspectiveCamera.up;
    if (cameraUp.y === 1) {
      return new Vector3(pos.x, pos.y + scale.y / 2, pos.z);
    }
    return new Vector3(pos.x, pos.y - scale.y / 2, pos.z);
  }

  private createSprite(id: string, point: Vector3): Sprite {
    const eventName = EventBusNames.Point3dLocationMarkerClicked;
    const textureLoader = new TextureLoader();
    const texture = textureLoader.load('assets/location-dot-sharp-regular.png');

    const material = new SpriteMaterial({
      map: texture,
      color: 0xffffff,
      fog: true,
      alphaTest: 0.5,
    });

    const sprite = new Sprite(material) as CustomSprite;

    sprite.scale.set(0.25, 0.25, 1);

    sprite.position.set(point.x, point.y, point.z);
    sprite.uniqueId = id;
    sprite.customType = this.type;
    sprite.updatePosition = (pos: Vector3) => {
      const offsetPos = this.spriteOffsetPosition(sprite.scale, pos);
      sprite.position.copy(offsetPos);
    };
    return sprite;
  }

  createMeshSphere(
    id: string,
    position: Vector3,
    color: ColorsThreeD | HexColor,
    visible = true
  ) {
    const object = createSphereObject(
      id,
      position,
      EventBusNames.Point3dLocationMarkerClicked,
      this.type,
      this.isPotree,
      color
    );
    object.visible = visible;
    return object;
  }

  createMeshLocationMarker(
    id: string,
    position: Vector3,
    color: ColorsThreeD | HexColor,
    visible = true
  ) {
    // SVG content
    const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M336 192c0-79.5-64.5-144-144-144S48 112.5 48 192c0 12.4 4.5 31.6 15.3 57.2c10.5 24.8 25.4 52.2 42.5 79.9c28.5 46.2 61.5 90.8 86.2 122.6c24.8-31.8 57.8-76.4 86.2-122.6c17.1-27.7 32-55.1 42.5-79.9C331.5 223.6 336 204.4 336 192zm48 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-160 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-112 0a80 80 0 1 1 160 0 80 80 0 1 1 -160 0z"/></svg>`;

    // Load SVG from the string
    const loader = new SVGLoader();
    const paths = loader.parse(svgString).paths;

    // Create group to hold the entire SVG shape collection
    const svgGroup = new Group() as CustomGroup<ICustomMesh>;
    // svgGroup is upside down (svg string is flipped)
    if (this.perspectiveCamera.up.equals(new Vector3(0, -1, 0))) {
      svgGroup.up.set(0, 1, 0);
    } else if (this.perspectiveCamera.up.equals(new Vector3(0, 0, 1))) {
      svgGroup.up.set(0, 0, 1);
      const cameraPosition = new Vector3();
      this.perspectiveCamera.getWorldPosition(cameraPosition);
      svgGroup.lookAt(cameraPosition);
      svgGroup.rotateX(-Math.PI / 2);
    }
    // Process each path
    paths.forEach((path: ShapePath) => {
      const shapes = path.toShapes(true);
      const geometry = new ExtrudeGeometry(shapes, {
        depth: 1,
        bevelEnabled: false,
      });
      geometry.computeBoundingBox();
      const max = geometry.boundingBox!.max;
      const min = geometry.boundingBox!.min;
      geometry.translate(-0.5 * (max.x + min.x), -max.y + min.y, 0);

      const material = new MeshBasicMaterial({ color });
      const object = new CustomMesh(geometry, material);
      object.uniqueId = id;
      object.customType = this.type;
      svgGroup.add(object);
    });

    svgGroup.scale.multiplyScalar(0.001); // Scale down because SVG might be large

    svgGroup.uniqueId = id;
    svgGroup.customType = this.type;
    svgGroup.position.set(position.x, position.y, position.z);
    svgGroup.updatePosition = (pos: Vector3) => {
      svgGroup.position.copy(pos);
    };

    svgGroup.visible = visible;
    return svgGroup;
  }

  private init() {
    this.object.customType = this.type;
    // add only to annotation group
    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (group) {
      group.add(this.object);
    }
  }
}
