import {
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query';
// @ts-ignore
import * as jsonlint from 'jsonlint-mod';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useOpsPipelineCapturesQueries = ({
  filter,
  captureJob,
  captureIds,
}: {
  filter?: APIModels.CaptureFilter;
  captureJob?: APIModels.CaptureJob;
  captureIds?: number[];
}) => {
  const queryClient = useQueryClient();
  const { captureId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const toasts = useToasts();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          id: Number(captureId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const capturesByIdsQueries = useQueries({
    queries:
      captureIds?.map((id) => ({
        queryFn: () => APIClient.getCaptureById(id),
        queryKey: [APIUtils.QueryKey.captures, { captureId: id }],
        enabled: Number.isSafeInteger(id),
        initialData: () =>
          APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Capture>({
            queryClient,
            queryKey: [APIUtils.QueryKey.captures],
            id,
            accessor: 'id',
          }),
        staleTime: APIUtils.getDuration({ seconds: 20 }),
      })) ?? [],
  });

  const capturesQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.captures,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    staleTime: APIUtils.getDuration({ seconds: 20 }),
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    queryFn: ({ pageParam }) => {
      return APIClient.getFilteredPageCaptures(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.CaptureFilter
      );
    },
    enabled: filter !== undefined,
  });

  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
      select: (data) => APIUtils.Sort.projects(data),
    },
  });

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const usersLookupTable = useLookupTable(usersQuery.data, 'id');

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const captureJobsQuery = APIClient.useGetCaptureJobsByCaptureId(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureJobs,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
      },
    }
  );

  useEffect(() => {
    if (captureId !== undefined && !Number.isSafeInteger(Number(captureId))) {
      if (isViteApp) {
        navigate('/app/admin/pipelines/captures' + params);
      } else {
        navigate('/ops/pipeline/captures' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
        ],
      });

      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          { captureId: Number(captureId) },
        ],
      });
    }
  }, [captureId]);

  const captureJobPutMutation = APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
          accessor: 'id',
          data: variables.data,
          id: variables.jobId,
        });

        toasts.add(toasts.prepare.entityUpdated('capture job'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update capture job!'));
      },
    },
  });

  const captureJobPlyUrlQuery = APIClient.useGetPlyDownloadUrlByCaptureJobId(
    captureJob?.id as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureJob?.captureId },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: captureJob?.id },
          APIUtils.QueryKey.plyUrl,
        ],
        enabled:
          !!captureJob?.mosaicEngine?.startsWith('g') &&
          !!captureJob?.id &&
          !!captureJob?.captureId,
        retry: 0,
        select: (data) => data?.plyDownloadUrl,
      },
    }
  );

  const eptJsonQuery = APIClient.useGetEptJsonById(
    captureJob?.eptPointcloudId as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureJob?.captureId },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: captureJob?.id },
          APIUtils.QueryKey.eptJson,
        ],
        enabled:
          !captureJob?.mosaicEngine?.startsWith('g') &&
          !!captureJob?.eptPointcloudId &&
          !!captureJob?.mosaicEngine &&
          !!captureJob?.id &&
          !!captureJob?.captureId,
        retry: 0,
      },
    }
  );

  const mosaicEnginesQuery = APIClient.useGetMosaicEngines({
    query: {
      queryKey: [APIUtils.QueryKey.mosaicEngines],
      select: (data) =>
        data
          ?.filter((engine) => engine.archived === false)
          .sort((a, b) => compare(a.id, b.id)),
    },
  });

  const mlModelsQuery = APIClient.useGetMlModels({
    query: {
      queryKey: [APIUtils.QueryKey.mlModels],
      select: (data) =>
        APIUtils.Sort.mlModels(data.filter((d) => d.archived === false)),
    },
  });

  const runPipelineForCapturesMutation = useMutation({
    mutationFn: async (data: {
      captures: APIModels.Capture[];
      mosaicEngine: APIModels.MosaicEngine;
      mlModel?: APIModels.MlModel;
      modelParameters: string;
    }) => {
      const promises = [];

      let parameters: string | undefined;
      try {
        parameters = JSON.stringify(jsonlint.parse(data.modelParameters));
      } catch (e) {
        // ignore as most of the cases are handled by input validators
      }

      for (const capture of data.captures) {
        promises.push(
          APIClient.postCaptureHighResWithMlModelJob(
            capture.id as number,
            data.mosaicEngine.id as number,
            (data.mlModel?.id ?? 0) as number,
            { parameters }
          )
        );
      }

      await Promise.all(promises);
    },
    onSuccess: () => {
      toasts.add({
        title: 'Pipeline for your captures started!',
        type: 'success',
      });

      if (isViteApp) {
        navigate('/app/admin/pipelines/captures' + params, {
          state: undefined,
        });
      } else {
        navigate('/ops/pipeline/captures' + params, {
          state: undefined,
        });
      }
    },
    onError: (e) => {
      console.error(e);
      toasts.add(
        toasts.prepare.error('Failed to start pipeline for your captures!')
      );
    },
  });

  return {
    capturesQuery,
    projectsQuery,
    usersQuery,
    usersLookupTable,
    organizationsQuery,
    captureJobsQuery,
    captureQuery,
    captureJobPutMutation,
    captureJobPlyUrlQuery,
    eptJsonQuery,
    capturesByIdsQueries,
    mosaicEnginesQuery,
    mlModelsQuery,
    runPipelineForCapturesMutation,
  };
};
