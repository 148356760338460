import { faEllipsis, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CaptureJob, useGetEptJsonById } from '@agerpoint/api';
import { Input } from '@agerpoint/component';
import {
  CaptureExpansionPanels,
  CaptureMaterialClassifications,
  CaptureMaterialClassificationsLabels,
  EffectNames,
  IViewer,
  LdFlags,
  MaterialType,
  MixpanelNames,
  Options,
  ViewerTypeOld,
} from '@agerpoint/types';
import {
  createFilename,
  cvtRgbaArrToRgbaStr,
  cvtRgbaPctToStdRgba,
  hasPermission,
  materialList,
  useGlobalStore,
} from '@agerpoint/utilities';

interface CaptureJobSidebarProps {
  captureId: number;
  eptId: number;
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
  openExpansionPanel: (whichPanel: CaptureExpansionPanels) => void;
  captureJobs: CaptureJob[];
}
/**
 * @deprecated
 */
export const CaptureJobSidebar = ({
  captureId,
  eptId,
  viewer,
  openExpansionPanel,
  captureJobs,
}: CaptureJobSidebarProps) => {
  const navigate = useNavigate();
  const {
    permissions,
    capturesViewer: {
      actions: { setPointStyle },
      viewerType,
    },
  } = useGlobalStore();
  const [jobList, setJobList] = useState<Options[]>([]);

  const [showClassifications] = useState(true);

  const [
    hasMaterialClassificationPermission,
    setHasMaterialClassificationPermission,
  ] = useState<boolean>(false);

  const { data: eptData } = useGetEptJsonById({
    id: eptId,
    lazy: true,
  }) as any;

  const selected = (): Options => {
    return jobList.find((job) => job.value === eptId) || ({} as Options);
  };

  const onChange = useCallback(
    (option: Options) => {
      if (!option.value) return;
      setPointStyle(MaterialType.RGBA);

      navigate(`/captures/${captureId}/${option.value}`);
    },
    [setPointStyle]
  );

  useEffect(() => {
    if (!captureJobs) return;
    const tempJobList = captureJobs
      .filter((job) => job?.eptPointcloudId && !job?.archived)
      .map((job) => {
        return {
          value: job.eptPointcloudId || 0,
          name: `${job.name}`,
        };
      });
    setJobList(tempJobList);
  }, [captureJobs]);

  useEffect(() => {
    const hasPerm = hasPermission(
      LdFlags.CapturePointCloudClassification,
      permissions
    );
    setHasMaterialClassificationPermission(hasPerm);
  }, [permissions]);

  useEffect(() => {
    if (!eptData) return;
    const data =
      'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(eptData));

    const a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = createFilename('ept', '.json');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [eptData]);

  return (
    <div className="my-1 pl-5 pr-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        {jobList.length > 0 &&
          jobList.map((job) => {
            return (
              <div className="flex items-center pb-1" key={job.value}>
                <div className="flex items-center justify-between min-h-[25px] flex-row w-full">
                  <div className="mx-1">
                    <input
                      type="radio"
                      checked={selected().value === job.value}
                      value={job.value}
                      onChange={() => {
                        onChange(job);
                      }}
                      className={`w-4 h-4 text-green bg-gray-100 border-gray-300 hover:ring-green hover:ring-2 cursor-pointer`}
                    />
                  </div>

                  <div className={`ml-2 px-1 w-full truncate`}>
                    <span className="py-0 px-1 text-sm rounded border border-transparent truncate">
                      {job.name}
                    </span>
                  </div>
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    className="mx-1 text-gray-400 cursor-pointer hover:text-gray-900"
                    onClick={() => {
                      navigate('./models');
                    }}
                  />
                </div>
              </div>
            );
          })}
      </form>
      {hasMaterialClassificationPermission &&
        showClassifications &&
        viewerType === ViewerTypeOld.POTREE && (
          <>
            <ClassificationSection viewer={viewer} />
            <MaterialSection
              viewer={viewer}
              openExpansionPanel={openExpansionPanel}
              eptId={eptId}
            />
          </>
        )}
    </div>
  );
};

const MaterialSection = ({
  viewer,
  openExpansionPanel,
  eptId,
}: {
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
  openExpansionPanel: (whichPanel: CaptureExpansionPanels) => void;
  eptId: number;
}) => {
  const pointCloudLoadedFn = () => {
    const keys = Object.keys(captureMaterialClassificationColors);
    Object.values(captureMaterialClassificationColors).forEach((obj, index) => {
      if (!obj.color) return;
      viewer?.current?.classification.updateClassificationColorById(
        keys[index],
        obj.color
      );
    });
  };

  const {
    subscribe,
    capturesViewer: {
      actions: {
        setCaptureMaterialClassificationSelected,
        setCaptureMaterialClassificationColor,
      },
      captureMaterialClassificationColors,
      captureMaterialClassificationSelected,
    },
  } = useGlobalStore();

  useEffect(() => {
    subscribe(EffectNames.POTREE_POINT_CLOUD_LOADED, pointCloudLoadedFn);
  }, []);

  useEffect(() => {
    const keys = Object.keys(captureMaterialClassificationColors);
    Object.values(captureMaterialClassificationColors).forEach((obj, index) => {
      if (!obj.color) return;
      viewer?.current?.classification.updateClassificationColorById(
        keys[index],
        obj.color
      );
    });
  }, [
    captureMaterialClassificationColors,
    captureMaterialClassificationSelected,
  ]);

  const [classificationObjects, setClassificationObjects] = useState<
    {
      name: CaptureMaterialClassificationsLabels;
      id: CaptureMaterialClassifications;
      color: number[] | undefined;
    }[]
  >([]);

  useEffect(() => {
    // build the classification objects
    const tempClassificationObjects = buildClassificationObjects();
    setClassificationObjects(tempClassificationObjects);
  }, [captureMaterialClassificationColors, eptId]);

  const buildClassificationObjects = useCallback(() => {
    return Object.keys(captureMaterialClassificationColors).map((key) => {
      const keyAsNumber = Number(key) as CaptureMaterialClassifications;
      return {
        name: Object.values(CaptureMaterialClassificationsLabels)[keyAsNumber],
        color: captureMaterialClassificationColors[keyAsNumber]?.color,
        id: keyAsNumber,
      };
    });
  }, [captureMaterialClassificationColors]);

  return (
    <>
      <div className="border-b border-gray-200 text-gray-800 my-2">
        Classification
      </div>
      {classificationObjects
        .filter((classificationObject) => classificationObject?.color)
        .map((classificationObject) => (
          <div
            className="flex items-center justify-between w-full text-sm text-gray-800 pl-1"
            key={classificationObject.id}
          >
            <span className="mx-1">
              <Input.Checkbox
                id={`classification-${classificationObject.id}`}
                setValue={() => {
                  setCaptureMaterialClassificationColor(
                    classificationObject.id,
                    captureMaterialClassificationColors[classificationObject.id]
                      ?.color || [0, 0, 0, 0],
                    !captureMaterialClassificationColors[
                      classificationObject.id
                    ]?.visible
                  );
                  viewer?.current?.classification.toggleClassificationVisibilityById(
                    classificationObject.id
                  );
                }}
                value={
                  captureMaterialClassificationColors[classificationObject.id]
                    ?.visible || false
                }
              />
            </span>
            <div
              className="rounded-sm border w-5 h-5 flex-shrink-0"
              style={{
                backgroundColor: cvtRgbaArrToRgbaStr(
                  cvtRgbaPctToStdRgba(
                    classificationObject?.color || [0, 0, 0, 0]
                  )
                ),
              }}
              onClick={() => {
                setCaptureMaterialClassificationSelected(
                  classificationObject.id
                );
                openExpansionPanel(CaptureExpansionPanels.CaptureColorSelector);
              }}
            />
            <div className="ml-2 text-md text-gray-800 truncate flex-grow flex items-center">
              {classificationObject.name}
            </div>
            <FontAwesomeIcon
              icon={faEllipsis}
              className="fa-lg text-gray-400 hover:text-gray-800"
              onClick={() => {
                setCaptureMaterialClassificationSelected(
                  classificationObject.id
                );
                openExpansionPanel(CaptureExpansionPanels.CaptureColorSelector);
              }}
            />
          </div>
        ))}
    </>
  );
};

const ClassificationSection = ({
  viewer,
}: {
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
}) => {
  const {
    capturesViewer: {
      pointStyle,
      actions: { setPointStyle },
    },
    actions: { sendEvent },
  } = useGlobalStore();

  const materialListChange = (option: Options) => {
    if (!option.value) return;

    sendEvent(MixpanelNames.CapturesViewerPointCloudStyleChanged, {
      style: option.name,
    });

    if (option.value !== pointStyle) {
      setPointStyle(option.value as MaterialType);
    }
  };

  useEffect(() => {
    if (!pointStyle) return;
    viewer?.current?.classification.switchPointCloudMaterial(pointStyle);
  }, [pointStyle]);

  return (
    <>
      <div className="border-b border-gray-200 text-gray-800 my-2">
        Point Cloud Style
      </div>
      <div className="pl-2">
        {materialList.map((mat) => {
          return (
            <div className="flex items-center pb-1" key={mat.value}>
              <div className="flex items-center justify-between min-h-[25px] flex-row w-full">
                <div className="mx-1">
                  <input
                    key={mat.value}
                    type="radio"
                    checked={pointStyle === mat.value}
                    value={mat.value}
                    onChange={() => {
                      materialListChange(mat);
                    }}
                    className={`w-4 h-4 text-green bg-gray-100 border-gray-300 hover:ring-green hover:ring-2 cursor-pointer`}
                  />
                </div>

                <div className={`ml-2 px-1 w-full truncate`}>
                  <span className="py-0 px-1 text-sm rounded border border-transparent truncate">
                    {mat.name}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
