import { useQueryClient } from '@tanstack/react-query';

import { APIClient } from '@agerpoint/api';
import { APIUtils } from '@agerpoint/utilities';

export const useModelRatingQueries = () => {
  const queryClient = useQueryClient();

  const captureJobRatingPutMutation = APIClient.useUpdateCaptureJobRating();
  const captureJobRatingPostMutation = APIClient.useCreateCaptureJobRating();

  const invalidateCaptureJobByIdQuery = (captureJobId: number) => {
    queryClient.invalidateQueries({
      queryKey: [
        APIUtils.QueryKey.captureJobs,
        { captureJobId: Number(captureJobId) },
      ],
      refetchType: 'active',
    });
  };

  return {
    captureJobRatingPutMutation,
    captureJobRatingPostMutation,
    invalidateCaptureJobByIdQuery,
  };
};
