import { useAuth } from 'oidc-react';

import { StandAloneViewer, StandAloneViewerSidebar } from '@agerpoint/feature';
import { UserClaims } from '@agerpoint/types';
import { HasClaimsRouteGuard } from '@agerpoint/utilities';

export const StandAloneViewerMainPage = () => {
  const { userData } = useAuth();
  return (
    <HasClaimsRouteGuard claims={[UserClaims.AgerAdmin]}>
      <>
        <StandAloneViewerSidebar />
        {userData?.access_token && (
          <StandAloneViewer token={userData.access_token} />
        )}
      </>
    </HasClaimsRouteGuard>
  );
};
