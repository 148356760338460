import {
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faExclamationTriangle,
  faExpand,
  faNote,
  faSave,
  faSpinner,
  faTrash,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { UseGetReturn } from 'restful-react';

import {
  APIModels,
  UseGetCaptureImageTagThumbnailById2Props,
  useGetCaptureImageTagThumbnailById2,
} from '@agerpoint/api';
import { Button, ImageViewer, Input } from '@agerpoint/component';
import { MixpanelNames, Sidebar2Subroute } from '@agerpoint/types';
import { blobCache, useGlobalStore } from '@agerpoint/utilities';

import { useCapturesViewerQueries } from '../../../captures-viewer-queries';

interface CVS3SupplementalImagesProps {
  navigateBack: () => void;
}

const CVS3SupplementalImages = ({
  navigateBack,
}: CVS3SupplementalImagesProps) => {
  const { captureSupplementalImagesQuery } = useCapturesViewerQueries({});

  useEffect(() => {
    if (
      captureSupplementalImagesQuery.isSuccess &&
      (captureSupplementalImagesQuery.data?.length ?? 0) === 0
    ) {
      navigateBack();
    }
  }, [
    captureSupplementalImagesQuery.data,
    captureSupplementalImagesQuery.isSuccess,
  ]);

  const [expandedImage, setExpandedImage] =
    useState<APIModels.CaptureImageTag>();
  const [expandedImageBlob, setExpandedImageBlob] = useState<Blob>();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>();

  const [page, setPage] = useState(0);
  const take = useMemo(() => 15, []);

  useEffect(() => {
    setSelectedImageIndex(undefined);
  }, [page]);

  const imagePage = useMemo(() => {
    return captureSupplementalImagesQuery.data?.slice(
      page * take,
      (page + 1) * take
    );
  }, [captureSupplementalImagesQuery.data, page, take]);

  const expandedImageRequestBody = useMemo(() => {
    return {
      id: expandedImage?.id ?? NaN,
      fit: 'fit-in',
      crop: '%20',
      size: `${2000}x${2000}`,
      verticalAlign: '%20',
      horizontalAlign: '%20',
      filters: '%20',
      lazy: true,
    };
  }, [expandedImage]);

  const {
    data: expandedThumbnailData,
    refetch: getExpandedThumbnail,
    cancel: cancelGetExpandedThumbnail,
  } = useGetCaptureImageTagThumbnailById2(
    expandedImageRequestBody
  ) as unknown as UseGetReturn<Response, void, void, unknown>;

  useEffect(() => {
    cancelGetExpandedThumbnail();
    if (!expandedImage) return;
    setExpandedImageBlob(undefined);
    getExpandedThumbnail();
  }, [expandedImage]);

  useEffect(() => {
    if (!expandedThumbnailData) return;
    const doAsync = async () => {
      const blob = await expandedThumbnailData.blob();
      setExpandedImageBlob(blob);
    };
    doAsync();
  }, [expandedThumbnailData]);

  const pageIndicator = useMemo(() => {
    let result = '';

    const imagesLength = captureSupplementalImagesQuery.data?.length ?? 0;
    const from = page * take;
    const to = (page + 1) * take;

    result += `${from}`;
    result += ` - `;
    if (to > imagesLength) {
      result += `${imagesLength}`;
    } else {
      result += `${(page + 1) * take}`;
    }
    result += ' of ';
    result += `${captureSupplementalImagesQuery.data?.length}`;

    return result;
  }, [captureSupplementalImagesQuery.data, page, take]);

  const pageNavigator = useMemo(() => {
    return (
      <div className="flex flex-row justify-center gap-2 pb-1 items-center text-gray-600">
        <div
          onClick={() => setPage((prev) => (prev - 1 <= 0 ? 0 : prev - 1))}
          className="cursor-pointer"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        {pageIndicator}
        <div
          onClick={() =>
            setPage((prev) =>
              (prev + 1) * take >
              (captureSupplementalImagesQuery.data?.length ?? 0)
                ? prev
                : prev + 1
            )
          }
          className="cursor-pointer"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    );
  }, [captureSupplementalImagesQuery.data?.length, pageIndicator, take]);

  if (captureSupplementalImagesQuery.isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </div>
    );
  }

  return (
    <>
      <ImageViewer
        open={!!expandedImage}
        handleCloseViewer={() => {
          setExpandedImage(undefined);
          setExpandedImageBlob(undefined);
        }}
        handleNavigation={{
          next: () => {
            if (!captureSupplementalImagesQuery.data) {
              return;
            }
            let index =
              captureSupplementalImagesQuery.data.findIndex(
                (c) => c.id === expandedImage?.id
              ) ?? -1;
            if (index <= -1) {
              return;
            }
            setExpandedImageBlob(undefined);
            index += 1;
            if (index > captureSupplementalImagesQuery.data.length - 1) {
              index = 0;
            }
            const newImage = captureSupplementalImagesQuery.data[index];
            setExpandedImage(newImage);
          },
          previous: () => {
            if (!captureSupplementalImagesQuery.data) {
              return;
            }
            let index = captureSupplementalImagesQuery.data.findIndex(
              (c) => c === expandedImage
            );
            if (index <= -1) {
              return;
            }
            setExpandedImageBlob(undefined);
            index -= 1;
            if (index < 0) {
              index = captureSupplementalImagesQuery.data.length - 1;
            }
            const newImage = captureSupplementalImagesQuery.data[index];
            setExpandedImage(newImage);
          },
        }}
        loading={!expandedImageBlob}
        showDownloadButton={false}
      >
        <div className="flex flex-row justify-center">
          {expandedImageBlob && (
            <div className="relative">
              <div
                className={`absolute bottom-0 left-0 right-0 flex flex-row justify-between gap-1 bg-gray-900 text-gray-100 rounded`}
              >
                <div className="px-1 py-0.5 text-4xl">
                  {(captureSupplementalImagesQuery.data?.findIndex(
                    (i) => i.id === expandedImage?.id
                  ) ?? 0) + 1}{' '}
                  of {captureSupplementalImagesQuery.data?.length}
                </div>
                <div className="px-1 py-0.5 text-4xl">
                  {expandedImage?.note ? `Note: ${expandedImage?.note}` : ''}
                </div>
              </div>
              <img
                style={{ borderRadius: '1%' }}
                src={URL.createObjectURL(expandedImageBlob)}
                alt={`Capture ${expandedImage?.id}`}
              />
            </div>
          )}
        </div>
      </ImageViewer>
      <div className="px-1 text-sm w-full py-2">
        {pageNavigator}
        <CaptureSupplementalImagesGallery
          images={imagePage ?? []}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={setSelectedImageIndex}
          setExpandedImage={setExpandedImage}
        />
      </div>
    </>
  );
};

interface CaptureSupplementalImagesGalleryProps {
  images: APIModels.CaptureImageTag[];
  selectedImageIndex: number | undefined;
  setSelectedImageIndex: (index: number | undefined) => void;
  setExpandedImage: (image: APIModels.CaptureImageTag) => void;
}

const CaptureSupplementalImagesGallery = ({
  images,
  selectedImageIndex,
  setSelectedImageIndex,
  setExpandedImage,
}: CaptureSupplementalImagesGalleryProps) => {
  const imgsPerRow = 3;
  const rows: JSX.Element[] = [];

  for (let i = 0; i < images.length; i += imgsPerRow) {
    const rowImgs = images.slice(i, i + imgsPerRow);
    const row = (
      <div key={`ROW ${i}`} className="w-full flex flex-col">
        <div className="flex flex-row w-full gap-x-1">
          {rowImgs.map((img, index) => (
            <div
              className="w-1/3 flex flex-col items-center justify-center"
              key={`IMG ${index + i}`}
            >
              <div className="w-full">
                <CaptureSupplementalImage
                  image={img}
                  isSelected={selectedImageIndex === i + index}
                  index={i + index}
                  setSelectedImageIndex={setSelectedImageIndex}
                  setExpandedImage={setExpandedImage}
                />
              </div>
              <div
                className={`${
                  selectedImageIndex !== undefined &&
                  selectedImageIndex >= i &&
                  selectedImageIndex < i + imgsPerRow
                    ? 'pt-2'
                    : 'pt-1'
                }`}
              >
                {selectedImageIndex === i + index && (
                  <div
                    className="-mt-2 w-0 h-0"
                    style={{
                      borderLeft: '8px solid transparent',
                      borderRight: '8px solid transparent',
                      borderBottom: '8px solid rgb(23, 103, 84)',
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {Array.from(
            { length: imgsPerRow - rowImgs.length },
            (_, __) => null
          ).map((_, counterWeightIndex) => (
            <div className="w-1/3" key={counterWeightIndex} />
          ))}
        </div>
        {selectedImageIndex !== undefined &&
          selectedImageIndex >= i &&
          selectedImageIndex < i + imgsPerRow && (
            <CaptureSupplementalImageDetails
              key={selectedImageIndex}
              image={images[selectedImageIndex]}
              clearSelectedImageIndex={() => {
                setSelectedImageIndex(undefined);
              }}
            />
          )}
      </div>
    );
    rows.push(row);
  }

  return <div>{rows}</div>;
};

interface CaptureSupplementalImageProps {
  image: APIModels.CaptureImageTag;
  index: number;
  isSelected: boolean;
  setSelectedImageIndex: (index: number) => void;
  setExpandedImage: (image: APIModels.CaptureImageTag) => void;
}

const CaptureSupplementalImage = ({
  image,
  index,
  isSelected,
  setSelectedImageIndex,
  setExpandedImage,
}: CaptureSupplementalImageProps) => {
  const thumbnailRequestBody: UseGetCaptureImageTagThumbnailById2Props =
    useMemo(
      () => ({
        id: image.id ?? NaN,
        fit: 'fit-in',
        crop: '%20',
        size: `${300}x${300}`,
        verticalAlign: '%20',
        horizontalAlign: '%20',
        filters: '%20',
        lazy: true,
      }),
      [image]
    );

  const {
    actions: { sendEvent },
  } = useGlobalStore();
  const cacheKey = useMemo(
    () => `capture-supplemental-image-${image.id}`,
    [image]
  );

  const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState<string>();

  const {
    data: thumbnailData,
    refetch: getThumbnail,
    cancel: cancelGetThumbnail,
    error: thumbnailDataError,
  } = useGetCaptureImageTagThumbnailById2(
    thumbnailRequestBody
  ) as unknown as UseGetReturn<Response, void, void, unknown>;

  useEffect(() => {
    return () => {
      cancelGetThumbnail();
    };
  }, []);

  useEffect(() => {
    setThumbnailBlobUrl(undefined);

    if (blobCache.has(cacheKey)) {
      setThumbnailBlobUrl(blobCache.get(cacheKey)?.url);
    } else {
      getThumbnail();
    }
  }, [cacheKey]);

  useEffect(() => {
    if (!thumbnailData || blobCache.has(cacheKey)) {
      return;
    }

    const doAsync = async () => {
      const blob = await thumbnailData.blob();
      const url = URL.createObjectURL(blob);
      blobCache.set(cacheKey, { blob, url });
      setThumbnailBlobUrl(url);
    };

    try {
      doAsync();
    } catch (e) {
      console.error(e);
    }
  }, [thumbnailData]);

  return (
    <div
      className={`w-full h-full flex justify-center items-center bg-white
     rounded overflow-hidden relative border transition-transform ${
       isSelected ? 'border-green' : 'border-gray-800 scale-95'
     }`}
      style={{ aspectRatio: '1 / 1' }}
    >
      {thumbnailDataError ? (
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-red" />
      ) : !thumbnailBlobUrl ? (
        <FontAwesomeIcon icon={faSpinner} spin className="text-gray-800" />
      ) : (
        <img
          src={thumbnailBlobUrl}
          alt={`Supplemental ${image.id}`}
          className="h-full w-full object-cover cursor-pointer"
          onClick={() => {
            sendEvent(MixpanelNames.SupplementalImagesSectionOpened, {});
            setSelectedImageIndex(index);
          }}
        />
      )}

      {thumbnailBlobUrl && (
        <div
          className="absolute top-0 right-0 border-b border-l border-gray-800 rounded-bl-lg
        px-1 py-0.5 text-xs cursor-pointer bg-white hover:bg-gray-50"
          onClick={() => {
            sendEvent(MixpanelNames.SupplementalImagesExpanded, {});
            setExpandedImage(image);
          }}
        >
          <FontAwesomeIcon icon={faExpand} />
        </div>
      )}
      {thumbnailBlobUrl && (image.note?.length ?? 0) > 0 && (
        <div
          className="absolute bottom-0 left-0 border-t border-r border-gray-800 rounded-tr-lg
        px-1 py-0.5 text-xs bg-white hover:bg-gray-50 cursor-pointer"
          onClick={() => {
            sendEvent(MixpanelNames.SupplementalImagesSectionOpened, {});
            setSelectedImageIndex(index);
          }}
        >
          <FontAwesomeIcon icon={faNote} />
        </div>
      )}
    </div>
  );
};

interface CaptureSupplementalImageDetailsProps {
  image: APIModels.CaptureImageTag;
  clearSelectedImageIndex: () => void;
}

const CaptureSupplementalImageDetails = ({
  image,
  clearSelectedImageIndex,
}: CaptureSupplementalImageDetailsProps) => {
  const {
    supplementalImagesArchivePutMutation,
    supplementalImagesNotePutMutation,
  } = useCapturesViewerQueries({});

  const {
    actions: { sendEvent },
  } = useGlobalStore();

  const [note, setNote] = useState(image.note ?? '');

  return (
    <div
      className={`w-full bg-gray-50 rounded-lg p-1 mb-2 flex
        flex-col border border-green shadow-heading`}
    >
      <div className="relative">
        <div className="p-1">
          <Input.Text.Area
            label={<Input.Label label="Note" />}
            id="supplemental-image-note"
            value={note}
            setValue={setNote}
            rows={3}
            disabled={
              supplementalImagesNotePutMutation.isPending ||
              supplementalImagesArchivePutMutation.isPending
            }
          />
        </div>
        <div className="absolute top-0 right-0">
          <Button.Icon
            id="supplemental-image-note-hide"
            icon={faXmark}
            onClick={() => {
              clearSelectedImageIndex();
            }}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between px-1">
        <Button.Danger
          id="delete-image-button"
          label="Delete Image"
          icon={faTrash}
          loading={supplementalImagesArchivePutMutation.isPending}
          onClick={() => {
            const confirm = window.confirm(
              `Are you sure you want to archive this image?`
            );

            if (!confirm) {
              return;
            }

            supplementalImagesArchivePutMutation.mutate(
              {
                id: image.id as number,
                data: { ...image, archived: true },
              },
              {
                onSuccess: () => {
                  sendEvent(MixpanelNames.SupplementalImagesArchived, {});
                },
              }
            );
          }}
        />
        <Button.Primary
          id="save-note-button"
          label="Save Note"
          icon={faSave}
          loading={supplementalImagesNotePutMutation.isPending}
          onClick={() => {
            if (supplementalImagesNotePutMutation.isPending) {
              return;
            }

            supplementalImagesNotePutMutation.mutate(
              {
                id: image.id as number,
                data: { ...image, note: note.trim() },
              },
              {
                onSuccess: () => {
                  sendEvent(MixpanelNames.SupplementalImagesNoteEdited, {});
                },
              }
            );
          }}
        />
      </div>
    </div>
  );
};

export const cvs3SupplementalImagesRoute: Sidebar2Subroute = {
  element: (actions) => (
    <CVS3SupplementalImages navigateBack={actions.navigateBack} />
  ),
  subroutes: [],
  path: 'supplemental-images',
  title: <div>Supplemental Images</div>,
};
