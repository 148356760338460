import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface DraggableWindowProps {
  height: number;
  width: number;
  topInitial?: number;
  leftInitial?: number;
  bottomInitial?: number;
  title: string;
  show: boolean;
  toggleShow: () => void;
  children: ReactNode;
}
export const DraggableWindow = ({
  height,
  width,
  title,
  show,
  toggleShow,
  children,
  topInitial,
  leftInitial,
}: DraggableWindowProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [draggableWindowTopLeft, setDraggableWindowTopLeft] = useState({
    left: leftInitial ? leftInitial : undefined,
    top: topInitial ? topInitial : undefined,
  });
  const [leftAdjustment, setLeftAdjustment] = useState(0);
  const [topAdjustment, setTopAdjustment] = useState(0);
  const [windowMovable, setWindowMovable] = useState(false);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      let left = e.pageX - leftAdjustment;
      let top = e.pageY - topAdjustment;

      if (left < 0) left = 0;
      if (top < 0) top = 0;

      if (left + width > windowWidth) left = windowWidth - width;
      if (top + height > windowHeight) top = windowHeight - height;

      setDraggableWindowTopLeft({
        left,
        top,
      });
    },
    [leftAdjustment, topAdjustment]
  );

  const onMouseUp = useCallback(() => {
    setLeftAdjustment(0);
    setTopAdjustment(0);
    setWindowMovable(false);
  }, []);

  const onMouseDown = useCallback((e: MouseEvent) => {
    const bbox = divRef?.current?.getBoundingClientRect?.();
    if (!bbox) return;
    const leftDiff = e.clientX - bbox.left;
    const topDiff = e.clientY - bbox.top;
    setLeftAdjustment(leftDiff);
    setTopAdjustment(topDiff);
    setWindowMovable(true);
  }, []);

  useEffect(() => {
    if (windowMovable) {
      window.addEventListener('mousemove', onMouseMove as any);
      window.addEventListener('mouseup', onMouseUp as any);
    } else {
      window.removeEventListener('mousemove', onMouseMove as any);
      window.removeEventListener('mouseup', onMouseUp as any);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove as any);
      window.removeEventListener('mouseup', onMouseUp as any);
    };
  }, [windowMovable, onMouseMove, onMouseUp]);

  return (
    <div data-test-id="draggable-window">
      {show ? (
        <div
          className="rounded absolute bg-gray-50 shadow-lg flex flex-col"
          style={{
            height,
            width,
            zIndex: 999,
            top: draggableWindowTopLeft.top,
            left: draggableWindowTopLeft.left,
          }}
        >
          <div
            className={`w-full flex items-center px-4 py-2 text-white rounded-t cursor-move  bg-green select-none`}
            onMouseDown={onMouseDown}
            ref={divRef}
          >
            <div className="flex-grow truncate select-none">{title}</div>
            <div className="flex">
              <FontAwesomeIcon
                data-test-id="close-button"
                icon={faTimes}
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  toggleShow();
                }}
              />
            </div>
          </div>
          <div className="p-2 rounded-b flex flex-grow border-l border-b border-r border-green overflow-auto">
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
};
