import {
  faInputText,
  faLocationQuestion,
  faMapPin,
  faRectangleWide,
} from '@fortawesome/pro-light-svg-icons';
import { faLockAlt, faLockOpen } from '@fortawesome/pro-regular-svg-icons';
import { faLocationPin } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import { GroupToggle, Input } from '@agerpoint/component';
import { CaptureObjectMarkers, UserClaims } from '@agerpoint/types';
import { hasClaims, useGlobalStore } from '@agerpoint/utilities';

import { CaptureObjectAttributeSection } from './capture-object-attribute-section/capture-object-attribute-section';

/**
 * @deprecated
 */
export const CaptureObjectTreeSection = ({
  captureObjectMarkers,
  toggleMarker,
  toggleViewerLabel,
  locateCaptureObject,
  addGeometry,
  visibleAttributesLookup,
  updateObjectEditability,
  setVisibleAttributesLookup,
}: {
  captureObjectMarkers: CaptureObjectMarkers[];
  toggleMarker: (id: number) => void;
  toggleViewerLabel: (id: number) => void;
  locateCaptureObject: (captureObject: CaptureObjectMarkers) => void;
  updateObjectEditability: (id: number) => void;
  addGeometry: (captureObject: CaptureObjectMarkers) => void;
  visibleAttributesLookup: { [key: string]: boolean };
  setVisibleAttributesLookup: (visibleAttributesLookup: {
    [key: string]: boolean;
  }) => void;
}) => {
  const { user } = useGlobalStore();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isQAQC, setIsQAQC] = useState<boolean>(false);
  useEffect(() => {
    const hasClaim = hasClaims(
      [UserClaims.AgerAdmin],
      (user?.cloudClaims || []) as UserClaims[]
    );
    setIsAdmin(hasClaim);
    const hasQAQC = hasClaims(
      [UserClaims.QAQC],
      (user?.cloudClaims || []) as UserClaims[]
    );
    setIsQAQC(hasQAQC);
  }, [user]);
  return (
    <div>
      {captureObjectMarkers?.map(
        (captureObject: CaptureObjectMarkers, i: number) => {
          return (
            <div key={captureObject.id}>
              <div className="flex items-center pb-1">
                <div className="flex items-center justify-between w-full">
                  <span className="mx-1">
                    <Input.Checkbox
                      id={`marker-${captureObject.id}`}
                      value={captureObject.showMarker}
                      setValue={() => toggleMarker(captureObject.id)}
                    />
                  </span>
                  <div className="ml-2 text-sm text-gray-800 truncate flex-grow flex items-center">
                    {captureObject.name}
                    <span
                      className="fa-layers fa-fw ml-2 cursor-pointer text-gray-400 hover:text-gray-800"
                      onClick={() => {
                        toggleViewerLabel(captureObject.id);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={
                          captureObject.showLabel
                            ? faInputText
                            : faRectangleWide
                        }
                      />
                    </span>
                  </div>

                  {captureObject.x && captureObject.y && captureObject.z ? (
                    <>
                      {!captureObject.captureExtractionJobId &&
                        (isAdmin || isQAQC) && (
                          <FontAwesomeIcon
                            icon={
                              captureObject.editable ? faLockOpen : faLockAlt
                            }
                            className={`text-lg cursor-pointer text-gray-400 hover:text-gray-800 ${
                              captureObject.editable ? ' mr-2 ' : ' mr-3 '
                            }`}
                            title="Lock Marker"
                            onClick={() => {
                              updateObjectEditability(captureObject.id);
                            }}
                          />
                        )}
                      <FontAwesomeIcon
                        data-test-id={`existing-location-${i}`}
                        icon={
                          captureObject.captureExtractionJobId
                            ? faMapPin
                            : faLocationPin
                        }
                        className="text-lg cursor-pointer"
                        style={{
                          color: captureObject.markerFill,
                        }}
                        title="Identify Location"
                        onClick={() => {
                          locateCaptureObject(captureObject);
                        }}
                      />
                    </>
                  ) : (
                    <FontAwesomeIcon
                      data-test-id={`add-location-${i}`}
                      icon={faLocationQuestion}
                      className={`ml-2 fa-l text-gray-500 ${
                        isAdmin ? 'cursor-pointer' : ''
                      }`}
                      title="Add Or Change Location"
                      onClick={() => {
                        if (isAdmin || isQAQC) {
                          addGeometry(captureObject);
                        }
                      }}
                    />
                  )}
                  <GroupToggle
                    isOpen={visibleAttributesLookup[captureObject.id]}
                    onClick={() => {
                      const newLookup = {
                        ...visibleAttributesLookup,
                        [captureObject.id]:
                          !visibleAttributesLookup[captureObject.id],
                      };
                      setVisibleAttributesLookup(newLookup);
                    }}
                  />
                </div>
              </div>
              <div className="pl-5">
                {visibleAttributesLookup[captureObject.id] && (
                  <CaptureObjectAttributeSection
                    captureAttributes={
                      captureObject.captureObjectCustomAttributes
                    }
                  />
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};
