import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  Capture,
  Customer,
  User,
  useGetCaptureById,
  useGetCustomer,
  usePostAnalyticRequest,
} from '@agerpoint/api';
import {
  CaptureDetailsSectionAnalytics,
  CaptureDetailsSectionAnalyticsStepper,
  CaptureDetailsSectionJobs,
  CaptureDetailsSectionMain,
  CaptureDetailsSectionModels,
  CaptureDetailsSectionProjects,
  CapturesViewer,
  CapturesViewer2,
  ThreeDViewer2ContextWrapper,
} from '@agerpoint/feature';
import {
  AppBarProps,
  CapturePagesProps,
  LdFlags,
  MixpanelNames,
  UserClaims,
} from '@agerpoint/types';
import {
  HasClaimsRouteGuard,
  HasPermissionRouteGuard,
  environment,
  getAppBarProps,
  useGlobalStore,
  useNewLayout,
} from '@agerpoint/utilities';

import '../project-page/state/use-project';
import { CaptureTableWrapper } from './subpages/captures-table-page';

export const CapturePages = ({
  customers,
  appBarProps,
  environment,
}: CapturePagesProps) => {
  const { search } = useLocation();
  const { mutate: postAnalyticRequest } = usePostAnalyticRequest({});
  const { showNewLayout } = useNewLayout();

  return (
    <Routes>
      <Route path="/list" element={<Navigate to={'/captures' + search} />} />
      <Route
        path="/"
        element={
          <CaptureTableWrapper
            customers={customers ? (customers as Customer[]) : []}
            environment={environment}
            appBarProps={appBarProps}
          />
        }
      >
        <Route
          path="manage/:id/details"
          element={<CaptureDetailsSectionMain />}
        />
        <Route
          path="manage/:id/analytics/:analyticUriName"
          element={
            <HasPermissionRouteGuard
              permissionToCheck={LdFlags.SelfServeAnalytics}
            >
              <CaptureDetailsSectionAnalyticsStepper
                postAnalyticRequest={postAnalyticRequest}
              />
            </HasPermissionRouteGuard>
          }
        />
        <Route
          path="manage/:id/analytics"
          element={
            <HasPermissionRouteGuard
              permissionToCheck={LdFlags.SelfServeAnalytics}
            >
              <CaptureDetailsSectionAnalytics />
            </HasPermissionRouteGuard>
          }
        />
        <Route
          path="manage/:id/models"
          element={<CaptureDetailsSectionModels />}
        />
        <Route
          path="manage/:id/projects"
          element={<CaptureDetailsSectionProjects />}
        />
        <Route path="manage/:id/jobs" element={<CaptureDetailsSectionJobs />} />
      </Route>
      {showNewLayout === true ? (
        <Route
          path="/:captureId/*"
          element={
            <ThreeDViewer2ContextWrapper>
              <CapturesViewer2 />
            </ThreeDViewer2ContextWrapper>
          }
        >
          <Route path="details" element={<CaptureDetailsSectionMain />} />
          <Route
            path="analytics/:analyticUriName"
            element={
              <HasPermissionRouteGuard
                permissionToCheck={LdFlags.SelfServeAnalytics}
              >
                <CaptureDetailsSectionAnalyticsStepper
                  postAnalyticRequest={postAnalyticRequest}
                />
              </HasPermissionRouteGuard>
            }
          />
          <Route
            path="analytics"
            element={<CaptureDetailsSectionAnalytics />}
          />
          <Route path="models" element={<CaptureDetailsSectionModels />} />
          <Route path="projects" element={<CaptureDetailsSectionProjects />} />
          <Route path="jobs" element={<CaptureDetailsSectionJobs />} />
        </Route>
      ) : showNewLayout === false ? (
        <>
          <Route
            path="/:captureId/:eptId"
            element={<CaptureViewerWrapper appBarProps={appBarProps} />}
          >
            <Route path="details" element={<CaptureDetailsSectionMain />} />
            <Route
              path="analytics/:analyticUriName"
              element={
                <HasPermissionRouteGuard
                  permissionToCheck={LdFlags.SelfServeAnalytics}
                >
                  <CaptureDetailsSectionAnalyticsStepper
                    postAnalyticRequest={postAnalyticRequest}
                  />
                </HasPermissionRouteGuard>
              }
            />
            <Route
              path="analytics"
              element={<CaptureDetailsSectionAnalytics />}
            />
            <Route path="models" element={<CaptureDetailsSectionModels />} />
            <Route
              path="projects"
              element={<CaptureDetailsSectionProjects />}
            />
            <Route path="jobs" element={<CaptureDetailsSectionJobs />} />
          </Route>
          <Route
            path="/:captureId"
            element={<CaptureViewerWrapper appBarProps={appBarProps} />}
          />
        </>
      ) : null}

      {showNewLayout !== undefined && (
        <Route path="*" element={<Navigate to="/captures" replace={true} />} />
      )}
    </Routes>
  );
};

const CaptureViewerWrapper = ({
  appBarProps,
}: {
  appBarProps: AppBarProps;
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const [currentCaptureId, setCurrentCaptureId] = useState<number | undefined>(
    params?.captureId ? +params.captureId : undefined
  );

  const {
    data: capture,
    error,
    refetch: refetchCapture,
  } = useGetCaptureById({
    id: Number(params.captureId),
    lazy: true,
  });

  useEffect(() => {
    if (params.eptId === undefined) {
      refetchCapture();
    }
  }, [params.eptId]);

  useEffect(() => {
    if (error || !Number.isSafeInteger(Number(params.captureId))) {
      navigate('/captures');
    }
  }, [error, params.captureId]);

  useEffect(() => {
    if (capture && Number.isSafeInteger(Number(params.captureId))) {
      const latestJob = capture.completedJobs
        ?.filter((j) => j.archived === false)
        .sort((a, b) => {
          if (a.createDatetime && b.createDatetime) {
            return (
              new Date(b.createDatetime).valueOf() -
              new Date(a.createDatetime).valueOf()
            );
          }
          return 0;
        })[0];
      if (latestJob) {
        navigate(`/captures/${params.captureId}/${latestJob.eptPointcloudId}`, {
          replace: true,
        });
      } else {
        navigate('/captures');
      }
    }
  }, [capture, params.captureId]);

  const {
    actions: { sendEvent },
    captureObjectWindowOpen,
  } = useGlobalStore();

  useEffect(() => {
    if (captureObjectWindowOpen) {
      sendEvent(MixpanelNames.CaptureObjectAttributeDraggableTableVisible);
    }
  }, [captureObjectWindowOpen, sendEvent]);

  useEffect(() => {
    if (params.captureId) {
      setCurrentCaptureId(+params.captureId);
    }
  }, [params.captureId]);

  return (
    <div className="w-full h-full">
      {currentCaptureId && (
        <CapturesViewer key={currentCaptureId} appBarProps={appBarProps} />
      )}
    </div>
  );
};

export function CapturesRoutes() {
  return (
    <HasClaimsRouteGuard claims={[UserClaims.Cloud]}>
      <CanViewerCaptures />
    </HasClaimsRouteGuard>
  );
}

function CanViewerCaptures() {
  const { user } = useGlobalStore();
  const apiData: Capture[] = [];
  const { data: organizations } = useGetCustomer({});

  const appBarProps: AppBarProps = getAppBarProps(user || ({} as User));
  return (
    <CapturePages
      captures={apiData}
      customers={organizations ?? []}
      appBarProps={appBarProps}
      environment={environment}
    />
  );
}
