import { debounce } from 'lodash';
import { Vector3 } from 'three';

import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  EventBusNames,
  HexColor,
  IAnnotations2dGeometry,
  IAnnotations3dGeometry,
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint3d,
} from '@agerpoint/types';
import { eventBus } from '@agerpoint/utilities';

import { Point2d } from '../annotations-2d/point-2d';

export class AnnotationsStore {
  private points3d: { [key: string]: IPoint3d } = {};
  private multiPoints3d: { [key: string]: IMultiPoint3d } = {};
  private lines3d: { [key: string]: ILine3d } = {};
  private polygons3d: { [key: string]: ILine3d } = {};

  hasPoint3d(id: string): boolean {
    return this.points3d.hasOwnProperty(id);
  }
  hasLine3d(id: string): boolean {
    return this.lines3d.hasOwnProperty(id);
  }
  hasPolygon3d(id: string): boolean {
    return this.polygons3d.hasOwnProperty(id);
  }

  getPoint3dById(id: string): IPoint3d {
    return this.points3d[id];
  }
  getMultiPoint3dById(id: string): IMultiPoint3d {
    return this.multiPoints3d[id];
  }
  getLine3dById(id: string): ILine3d {
    return this.lines3d[id];
  }
  getPolygon3dById(id: string): ILine3d {
    return this.polygons3d[id];
  }

  getLast3dMultiPoint() {
    return Object.values(this.multiPoints3d).pop();
  }
  getLast3dLine() {
    return Object.values(this.lines3d).pop();
  }

  getAllPoints3d() {
    return this.points3d;
  }
  getAllLines3d() {
    return this.lines3d;
  }
  getAllPolygons3d() {
    return this.polygons3d;
  }
  getAllMultiPoints3d() {
    return this.multiPoints3d;
  }

  getLinePositionById(id: string) {
    if (!this.lines3d[id]) return [];
    return this.lines3d[id].getPosition();
  }

  getPoint3dByType(type: Annotation3dPoints) {
    return Object.values(this.points3d).filter((point) => point.type === type);
  }
  getMultiPoint3dByType(type: Annotation3dPoints) {
    return Object.values(this.multiPoints3d).filter(
      (point) => point.type === type
    );
  }
  getLine3dByType(type: Annotation3dLines) {
    return Object.values(this.lines3d).filter((line) => line.type === type);
  }
  getPolygon3dByType(type: Annotation3dPolygons) {
    return Object.values(this.polygons3d).filter(
      (polygon) => polygon.type === type
    );
  }

  public get3dAnnotationGeometry() {
    return {
      points: this.getPoint3dByType(Annotation3dPoints.AnnotationPoint),
      lines: this.getLine3dByType(Annotation3dLines.AnnotationLine),
      polygons: this.getPolygon3dByType(Annotation3dPolygons.AnnotationPolygon),
      multiPoints: this.getMultiPoint3dByType(
        Annotation3dPoints.MultiPointMarker
      ),
    };
  }

  addPoint3d(point: IPoint3d) {
    this.points3d[point.uniqueId] = point;
  }
  addMultiPoint3d(multiPoint: IMultiPoint3d, id: string) {
    this.multiPoints3d[id] = multiPoint;
  }
  addLine3d(line: ILine3d) {
    this.lines3d[line.uniqueId] = line;
  }
  addPolygon3d(polygon: ILine3d) {
    this.polygons3d[polygon.uniqueId] = polygon;
  }

  removeAllPoints3d() {
    console.log('removeAllPoints3d');
    for (const key in this.points3d) {
      this.points3d[key]?.dispose();
    }
    this.points3d = {};
  }
  removePoint3dById(id: string) {
    this.points3d[id]?.dispose();
    delete this.points3d[id];
  }
  removePoint3dByType(type: Annotation3dPoints) {
    for (const key in this.points3d) {
      if (this.points3d[key].type === type) {
        this.points3d[key]?.dispose();
        delete this.points3d[key];
      }
    }
  }
  removeAllLines3d() {
    for (const key in this.lines3d) {
      this.lines3d[key]?.dispose();
    }
    this.lines3d = {};
  }
  removeLine3dById(id: string) {
    this.lines3d[id]?.dispose();
    delete this.lines3d[id];
  }
  removeLine3dByType(type: Annotation3dLines) {
    for (const key in this.lines3d) {
      if (this.lines3d[key].type === type) {
        this.lines3d[key]?.dispose();
        delete this.lines3d[key];
      }
    }
  }

  removeAllPolygons3d() {
    for (const key in this.polygons3d) {
      this.polygons3d[key]?.dispose();
    }
    this.polygons3d = {};
  }
  removePolygon3dById(id: string) {
    this.polygons3d[id]?.dispose();
    delete this.polygons3d[id];
  }
  removePolygon3dByType(type: Annotation3dPolygons) {
    for (const key in this.polygons3d) {
      if (this.polygons3d[key].type === type) {
        this.polygons3d[key]?.dispose();
        delete this.polygons3d[key];
      }
    }
  }

  removeAllMultiPoints3d() {
    for (const key in this.multiPoints3d) {
      this.multiPoints3d[key]?.dispose();
    }
    this.multiPoints3d = {};
  }
  removeMultiPoint3dById(id: string) {
    this.multiPoints3d[id]?.dispose();
    delete this.multiPoints3d[id];
  }
  removeMultiPoint3dByType(type: Annotation3dPoints) {
    for (const key in this.multiPoints3d) {
      if (this.multiPoints3d[key].type === type) {
        this.multiPoints3d[key]?.dispose();
        delete this.multiPoints3d[key];
      }
    }
  }

  // Highlighting
  highlightPoint3dById(id: string, color?: ColorsThreeD | HexColor) {
    this.unHighlightAll3d();
    const obj = this.points3d[id];
    if (!obj) return;
    obj.highlight(color);
  }
  highlightLine3dById(id: string) {
    this.unHighlightAll3d();
    const obj = this.lines3d[id];
    if (!obj) return;
    obj.highlight();
  }
  highlightPolygon3dById(id: string) {
    this.unHighlightAll3d();
    if (!this.polygons3d[id]) return;
    this.polygons3d[id].highlight();
  }
  highlightMultiPoint3dById(id: string) {
    this.unHighlightAll3d();
    const obj = this.multiPoints3d[id];
    if (!obj) return;
    obj.isHighlighted = true;
    obj.points.forEach((point) => point.highlight());
  }

  // unHighlighting
  unHighlightAllPoint3d() {
    for (const key in this.points3d) {
      this.points3d[key].unHighlight();
    }
  }
  unHighlightAllMultiPoint3d() {
    for (const key in this.multiPoints3d) {
      const obj = this.multiPoints3d[key];
      obj.isHighlighted = false;
      obj.points.forEach((point) => point.unHighlight());
    }
  }
  unHighlightAllLine3d() {
    for (const key in this.lines3d) {
      this.lines3d[key].unHighlight();
    }
  }
  unHighlightAllPolygon3d() {
    for (const key in this.polygons3d) {
      this.polygons3d[key].unHighlight();
    }
  }
  unHighlightAll3d() {
    this.unHighlightAllPoint3d();
    this.unHighlightAllMultiPoint3d();
    this.unHighlightAllLine3d();
    this.unHighlightAllPolygon3d();
  }

  showPoints3dByType(type: Annotation3dPoints) {
    for (const key in this.points3d) {
      if (this.points3d[key].type === type) {
        this.points3d[key].show();
      }
    }
  }

  showAllGeometry() {
    for (const key in this.points2d) {
      this.points2d[key].show();
    }
    for (const key in this.multiPoints2d) {
      this.multiPoints2d[key].show();
    }
    for (const key in this.points3d) {
      if (this.points3d[key].type !== Annotation3dPoints.AnnotationPoint) {
        continue;
      }
      this.points3d[key].show();
    }
    for (const key in this.multiPoints3d) {
      this.multiPoints3d[key].show();
    }
    for (const key in this.lines3d) {
      this.lines3d[key].show();
    }
    for (const key in this.polygons3d) {
      this.polygons3d[key].show();
    }
  }

  hideAllGeometry() {
    for (const key in this.points2d) {
      this.points2d[key].hide();
    }
    for (const key in this.multiPoints2d) {
      this.multiPoints2d[key].hide();
    }
    for (const key in this.points3d) {
      if (this.points3d[key].type !== Annotation3dPoints.AnnotationPoint) {
        continue;
      }
      this.points3d[key].hide();
    }
    for (const key in this.multiPoints3d) {
      if (
        this.multiPoints3d[key].type !== Annotation3dPoints.MultiPointMarker
      ) {
        continue;
      }
      this.multiPoints3d[key].hide();
    }
    for (const key in this.lines3d) {
      this.lines3d[key].hide();
    }
    for (const key in this.polygons3d) {
      this.polygons3d[key].hide();
    }
  }

  hidePointsByType(type: Annotation3dPoints) {
    for (const key in this.points3d) {
      if (this.points3d[key].type === type) {
        this.points3d[key].hide();
      }
    }
  }

  getNewPoint3dId() {
    return `new-point-${Object.keys(this.points3d).length}`;
  }

  getNewLine3dId() {
    return `new-line-${Object.keys(this.lines3d).length}`;
  }

  getNewPolygon3dId() {
    return `new-polygon-${Object.keys(this.polygons3d).length}`;
  }

  getNewMultiPoint3dId() {
    return `new-count-${Object.keys(this.multiPoints3d).length}`;
  }

  updateAllPoint3dLookAt() {
    const pointKeys = Object.keys(this.points3d);
    pointKeys.forEach((point) => {
      this.points3d[point].updateLookAt();
    });
  }

  updateMultiPoint3dPosition(id: string, points: Vector3[]) {
    if (this.multiPoints3d[id]) {
      this.multiPoints3d[id].updatePositions(points);
    }
  }

  updateLine3dPosition(id: string, points: Vector3[]) {
    if (this.lines3d[id]) {
      this.lines3d[id].updatePosition(points);
    }
  }

  updatePolygon3dPosition(id: string, points: Vector3[]) {
    if (this.polygons3d[id]) {
      this.polygons3d[id].updatePosition(points);
    }
  }

  finishCreating3dMultiPoint(id: string) {
    if (this.multiPoints3d[id]) {
      this.multiPoints3d[id].updateVisibility(true);
      this.multiPoints3d[id].updateType(Annotation3dPoints.MultiPointMarker);
    }
  }

  finishCreating3dLine(id: string) {
    if (this.lines3d[id]) {
      this.lines3d[id].updateVisibility(true);
      this.lines3d[id].updateType(Annotation3dLines.AnnotationLine);
    }
  }

  finishCreating3dPolygon(id: string) {
    if (this.polygons3d[id]) {
      this.polygons3d[id].updateVisibility(true);
      this.polygons3d[id].updateType(Annotation3dPolygons.AnnotationPolygon);
    }
  }

  // 2d methods
  private points2d: { [key: string]: Point2d } = {};
  private multiPoints2d: { [key: string]: IMultiPoint2d } = {};

  addPoint2d(point: Point2d) {
    this.points2d[point.uniqueId] = point;
  }
  addMultiPoint2d(multiPoint: IMultiPoint2d) {
    this.multiPoints2d[multiPoint.uniqueId] = multiPoint;
  }

  getNewPoint2dId() {
    return `new-point-${Object.keys(this.points2d).length}`;
  }
  getNewMultiPoint2dId() {
    return `new-multi-point-${Object.keys(this.multiPoints2d).length}`;
  }

  getLastMultiPoint2d() {
    return Object.values(this.multiPoints2d).pop();
  }
  getLastPoint2d() {
    return Object.values(this.points2d).pop();
  }

  getPoints2d() {
    return { ...this.points2d };
  }
  getMultiPoints2d() {
    return { ...this.multiPoints2d };
  }

  getPoint2dById(id: string): Point2d | undefined {
    return this.points2d[id];
  }
  getMultiPoint2dById(id: string): IMultiPoint2d | undefined {
    return this.multiPoints2d[id];
  }
  getMultiPoint2dByChildId(id: string): IMultiPoint2d | undefined {
    return Object.values(this.multiPoints2d).find((point) =>
      point.points.some((p) => p.uniqueId === id)
    );
  }

  getPoint2dByType(type: Annotation2dPoints) {
    return Object.values(this.points2d).filter((point) => point.type === type);
  }
  getMultiPoint2dByType(type: Annotation2dPoints) {
    return Object.values(this.multiPoints2d).filter(
      (point) => point.type === type
    );
  }

  get2dAnnotationGeometry() {
    return {
      points: this.getPoint2dByType(Annotation2dPoints.AnnotationPoint2d),
      multiPoints: this.getMultiPoint2dByType(
        Annotation2dPoints.AnnotationMultiPoint2d
      ),
    };
  }

  // Highlighting
  highlightPoint2dById(id: string, color?: ColorsThreeD | HexColor) {
    this.unHighlightAll2d();
    const obj = this.points2d[id];
    if (!obj) return;
    obj.highlight();
  }
  highlightMultiPoint2dById(id: string) {
    this.unHighlightAll2d();
    const obj = this.multiPoints2d[id];
    if (!obj) return;
    obj.isHighlighted = true;
    obj.highlight();
  }

  unHighlightAll2dPoints() {
    for (const key in this.points2d) {
      this.points2d[key].unHighlight();
    }
  }
  unHighlightAll2dMultiPoints() {
    for (const key in this.multiPoints2d) {
      const obj = this.multiPoints2d[key];
      obj.isHighlighted = false;
      obj.points.forEach((point) => point.unHighlight());
    }
  }

  unHighlightAll2d() {
    this.unHighlightAll2dPoints();
    this.unHighlightAll2dMultiPoints();
  }

  removeAllPoints2d() {
    for (const key in this.points2d) {
      this.points2d[key]?.dispose();
    }
    this.points2d = {};
  }
  removeAllMultiPoints2d() {
    for (const key in this.multiPoints2d) {
      this.multiPoints2d[key]?.dispose();
    }
    this.multiPoints2d = {};
  }
  removePoint2dById(id: string) {
    this.points2d[id]?.dispose();
    delete this.points2d[id];
  }
  removeMultiPoint2dById(id: string) {
    this.multiPoints2d[id]?.dispose();
    delete this.multiPoints2d[id];
  }

  render2dPoints() {
    for (const key in this.points2d) {
      this.points2d[key].render();
    }
  }
  render3dLines() {
    for (const key in this.lines3d) {
      this.lines3d[key].render();
    }
  }
  render2dMultiPoints() {
    for (const key in this.multiPoints2d) {
      this.multiPoints2d[key].render();
    }
  }

  updateColor2dPointById(id: string, color: ColorsThreeD | HexColor) {
    this.points2d[id]?.updateColor(color);
  }
  updateColor3dPointById(id: string, color: ColorsThreeD | HexColor) {
    this.points3d[id]?.updateColor(color);
  }
  updateColor3dLineById(id: string, color: ColorsThreeD | HexColor) {
    this.lines3d[id]?.updateColor(color);
  }
  updateColor3dPolygonById(id: string, color: ColorsThreeD | HexColor) {
    this.polygons3d[id]?.updateColor(color);
  }
  updateColor3dMultiPointById(id: string, color: ColorsThreeD | HexColor) {
    this.multiPoints3d[id]?.updateColor(color);
  }

  debouncedDispatch = debounce(
    (
      annotationGeom: IAnnotations3dGeometry,
      annotation2dGeom: IAnnotations2dGeometry
    ) => {
      eventBus.dispatch(EventBusNames.AnnotationGeometryUpdate, {
        detail: annotationGeom,
      });

      eventBus.dispatch(EventBusNames.Annotation2dGeometryUpdate, {
        detail: annotation2dGeom,
      });
    },
    500
  );
}
