import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { CaptureExtractionJob } from '@agerpoint/api';
import { APIUtils } from '@agerpoint/utilities';

import { AssignToUserModal } from '../../assign-user-to-ext-job-modal/assign-user-to-ext-job-modal';

interface QaqcCaptureJobAssignmentButtonProps {
  extractionJob?: CaptureExtractionJob;
  setExtractionJob: React.Dispatch<
    React.SetStateAction<CaptureExtractionJob | undefined>
  >;
}

export const QaqcCaptureJobAssignmentButton = ({
  extractionJob,
  setExtractionJob,
}: QaqcCaptureJobAssignmentButtonProps) => {
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <div
        className="w-full flex flex-row hover:bg-gray-800 rounded cursor-pointer p-1"
        onClick={() => {
          setShowAssignmentModal(true);
        }}
      >
        <div
          className={`text-xs leading-4 text-gray-400 flex flex-row
        items-center gap-1 justify-between w-full`}
        >
          <div className="flex flex-col whitespace-nowrap">
            <span>Assigned To:</span>
            <span className="text-white">
              {extractionJob?.assignedToUserName ?? 'Unassigned'}
            </span>
          </div>
          <div>
            <FontAwesomeIcon icon={faPen} />
          </div>
        </div>
      </div>
      <AssignToUserModal
        extractionJobs={extractionJob ? [extractionJob] : []}
        isOpen={showAssignmentModal}
        handleCloseDialog={() => {
          setShowAssignmentModal(false);
        }}
        updatedCallback={(user) => {
          const unassign = user.id === '' && user.name === '';
          setExtractionJob((prev) => {
            if (!prev) {
              return prev;
            }
            prev.assignedToUserName = unassign ? undefined : user.name;
            prev.assignedToId = unassign ? undefined : user.id;
            return prev;
          });

          queryClient.invalidateQueries({
            queryKey: [APIUtils.QueryKey.capturesWithExtractionJobs],
          });
        }}
      />
    </>
  );
};
