import { IconName } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIClient } from '@agerpoint/api';
import { APIUtils } from '@agerpoint/utilities';

import { APLoader } from '../../ap-loader';
import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface IInputCapture {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display.
   */
  error?: string;

  /**
   * The label to display.
   */
  label?: string;

  /**
   * The placeholder text for the input element.
   */
  placeholder?: string;

  /**
   * Required asterisk
   */
  required?: boolean;

  /**
   * The leading icon for the input element.
   */
  leadingIcon?: IconName;

  /**
   * The trailing icon for the input element.
   */
  trailingIcon?: IconName;

  /**
   * Determines whether the input element is highlighted.
   */
  highlighted?: boolean;

  /**
   * The disabled state of the input element.
   */
  disabled?: boolean;

  /**
   * The value of the input element.
   */
  value: APIClient.Capture | undefined;

  /**
   * A callback function to set the value of the input element.
   */
  setValue: (value: APIClient.Capture | undefined) => void;

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<APIClient.Capture | undefined>;
}

interface Position {
  top: number;
  left: number;
  width: number;
}

function InputCapture({
  id,
  error,
  label,
  value,
  setValue,
  placeholder,
  leadingIcon,
  trailingIcon,
  disabled = false,
  validation,
  required,
  highlighted = false,
}: IInputCapture) {
  const [expanded, setExpanded] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filter, setFilter] = useState({} as APIClient.CaptureFilter);
  const inputRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const captureQuery = useQuery<APIClient.Capture[]>({
    queryKey: [APIUtils.QueryKey.captures, { filter }],
    queryFn: async () => await APIClient.getFilteredPageCaptures(0, 20, filter),
    enabled: filter.captureName !== undefined && filter.captureName !== '',
  });

  useDebouncyEffect(
    () => {
      setFilter({ captureName: searchInputValue.trim() });
    },
    1000,
    [searchInputValue]
  );

  const inputClassName = useMemo(() => {
    let className = 'flex flex-row text-base rounded-lg border-0 h-10 w-full';
    if (disabled) {
      className += ` text-gray-textSecondary placeholder-gray-border
       ring-1 ring-gray-border cursor-not-allowed`;
    } else if (error) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors cursor-text
         ring-1 ring-status-error focus:ring-status-error focus:ring-2 hover-overlay-5`;
    } else if (highlighted) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors cursor-text
         ring-1 ring-gray-border focus:ring-primary focus:ring-2 hover-overlay-5 bg-primary bg-opacity-10`;
    } else {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors cursor-text
         ring-1 ring-gray-border focus:ring-primary focus:ring-2 hover-overlay-5`;
    }
    return className;
  }, [disabled, error, highlighted]);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const leadingIconComponent = useMemo(() => {
    if (!leadingIcon) {
      return undefined;
    }

    return (
      <div className="size-6 flex-center">
        <FontAwesomeIcon
          icon={[highlighted ? 'fas' : 'far', leadingIcon]}
          className={
            disabled
              ? 'text-gray-border'
              : highlighted
              ? 'text-primary'
              : 'text-gray-iconSecondary'
          }
        />
      </div>
    );
  }, [leadingIcon, disabled, highlighted]);

  const trailingIconComponent = useMemo(() => {
    if (!trailingIcon) {
      return undefined;
    }

    return (
      <div
        className={`size-6 flex-center transition-transform ${
          expanded ? 'rotate-180' : ''
        }`}
      >
        <FontAwesomeIcon
          icon={[highlighted ? 'fas' : 'far', trailingIcon]}
          className={
            disabled
              ? 'text-gray-border'
              : highlighted
              ? 'text-primary'
              : 'text-gray-iconSecondary'
          }
        />
      </div>
    );
  }, [disabled, expanded, trailingIcon, highlighted]);

  const calculatePosition = useCallback(() => {
    const input = inputRef.current?.getBoundingClientRect();

    if (!input) {
      return undefined;
    }

    setPosition({
      top: input.top - 9,
      left: input.left - 9,
      width: input.width + 18,
    });
  }, [inputRef]);

  const [position, setPosition] = useState<Position | undefined>(undefined);

  const windowOnClick = useCallback((e: MouseEvent) => {
    if (
      !dropdownRef.current?.contains(e.target as Node) &&
      !inputRef.current?.contains(e.target as Node)
    ) {
      setExpanded(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [calculatePosition]);

  useEffect(() => {
    if (expanded) {
      searchInputRef.current?.focus();
    }
  }, [expanded, windowOnClick]);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    window.addEventListener('click', windowOnClick);

    return () => {
      window.removeEventListener('click', windowOnClick);
    };
  }, [windowOnClick, expanded]);

  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label && <CloudInput.Label label={label} required={required} />}
        <div
          className={inputClassName}
          style={{
            paddingLeft: '8.5px',
            paddingRight: '8.5px',
          }}
          ref={inputRef}
          onClick={(e) => {
            if (disabled) {
              return;
            }
            calculatePosition();
            setExpanded(true);
          }}
        >
          <div className="flex flex-row items-center justify-between truncate gap-1 w-full">
            {leadingIconComponent ?? null}
            <div className="size-full flex flex-row items-center justify-between gap-2 truncate">
              {value === undefined ? (
                <span className="text-gray-textSecondary truncate">
                  {placeholder}
                </span>
              ) : (
                <span className="truncate">
                  {value?.captureName ?? 'Unknown'}
                </span>
              )}
            </div>
            {trailingIconComponent}
          </div>
        </div>
        {error && <CloudInput.Error error={error} />}
      </div>
      {expanded &&
        createPortal(
          <div
            className={`absolute bg-white shadow-lg border border-gray-border rounded-lg overflow-hidden z-dropdown`}
            ref={dropdownRef}
            style={{
              ...position,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="p-2">
              <CloudInput.Text.Single
                id={id + '-search'}
                value={searchInputValue}
                setValue={setSearchInputValue}
                leadingIcon={'search'}
                inputRef={searchInputRef}
                placeholder={`Search Capture`}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    setExpanded(false);
                  }

                  if (event.key === 'Enter') {
                    if (captureQuery.data?.length === 1) {
                      setValue(captureQuery.data[0]);
                      setExpanded(false);
                    }
                  }
                }}
              />
            </div>
            <div className="flex flex-col max-h-72 overflow-auto">
              {captureQuery.data?.map((option, index) => {
                let isSelected = false;
                if (value !== undefined) {
                  isSelected = value?.id === option?.id;
                }

                return (
                  <div key={index}>
                    <div
                      className={`px-4 py-2 cursor-pointer hover-overlay-5 flex flex-row gap-2 ${
                        isSelected ? 'bg-primary bg-opacity-10' : ''
                      }`}
                      onClick={(e) => {
                        if (isSelected) {
                          setValue(undefined);
                        } else {
                          setValue(option);
                        }

                        setExpanded(false);
                      }}
                    >
                      <div className="size-6 flex-center">
                        {isSelected && (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-primary"
                          />
                        )}
                      </div>
                      {option?.captureName ?? 'Unknown'}
                    </div>
                  </div>
                );
              })}
              {captureQuery.isLoading && (
                <div className="flex-center h-10 w-full">
                  <APLoader.CircleNotch />
                </div>
              )}
            </div>
          </div>,
          document.getElementById('apc-dropdowns') as HTMLElement
        )}
    </InputIdContext.Provider>
  );
}

export { InputCapture };
