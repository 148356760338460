// Data related
export const organizations = 'organizations';
export const usersCaptures = 'users-captures';
export const usersQaqc = 'users-qaqc';
export const mosaicEngines = 'mosaic-engines';
export const jobTypes = 'job-types';
export const uploadTypes = 'upload-types';
export const blocks = 'blocks';
export const farms = 'farms';
export const regions = 'regions';
export const crops = 'crops';
export const mlModels = 'ml-models';
export const orthomosaics = 'orthomosaics';
export const geometries = 'geometries';
export const projectLayerGroups = 'project-layer-groups';
export const pointclouds = 'pointclouds';
export const countries = 'countries';
export const organizationLicense = 'organization-license';
export const analytics = 'analytics';
export const analyticRequests = 'analytic-requests';
export const analyticRequestJobs = 'analytic-request-jobs';
export const uploadFiles = 'upload-files';
export const uploads = 'uploads';
export const projects = 'projects';
export const captures = 'captures';
export const sharedCaptures = 'shared-captures';
export const capturesLocations = 'captures-locations';
export const captureCounts = 'capture-counts';
export const pipelines = 'pipelines';
export const captureJobs = 'capture-jobs';
export const plyUrl = 'ply-url';
export const eptJson = 'ept-json';
export const libraryLayers = 'library-layers';
export const layerTypes = 'layer-types';
export const pipelineJobs = 'pipeline-jobs';
export const capturesWithExtractionJobs = 'captures-with-extraction-jobs';
export const captureExtractionJobStatuses = 'capture-extraction-job-statuses';
export const extractionJobs = 'extraction-jobs';
export const captureImages = 'capture-images';
export const captureObjects = 'capture-objects';
export const supplementalImages = 'supplemental-images';
export const customAttributes = 'custom-attributes';
export const captureVideos = 'capture-videos';
export const captureAudios = 'capture-audios';
export const operationJobs = 'operation-jobs';
export const organizationAnalytics = 'organization-analytics';
export const captureCustomAttributes = 'capture-custom-attributes';
export const currentUser = 'current-user';
export const thumborImage = 'thumbor-image';
export const captureObjectsByCompleteAnalyticRequests =
  'capture-objects-by-complete-analytic-requests';
export const captureCustomAttributesByCompleteAnalyticRequests =
  'capture-custom-attributes-by-complete-analytic-requests';
export const captureObjectTags = 'capture-object-tags';
export const annotationCategories = 'annotation-categories';

// Not API related
export const recentAccess = 'recent-access';
export const captureVideoMetadata = 'capture-video-metadata';
export const captureAudioMetadata = 'capture-audio-metadata';
export const captureImageMetadata = 'capture-image-metadata';

// Query related
export const infinite = 'infinite';
