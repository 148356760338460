import { useCallback } from 'react';

import { Entity, Layer, usePostLayer, usePutLayerById } from '@agerpoint/api';
import { Layer as ProjectLayer } from '@agerpoint/types';
import { toApiLayer } from '@agerpoint/utilities';

import { useProject } from '../state/use-project';

export function useAddAndPersistLayer(
  saveNewLayer: (groupId: number, layer: Layer, index: number) => void,
  addTemporaryLayer: (groupId: number, index: number) => void,
  removeTemporaryLayer: (groupId: number) => void,
  setIsDraggingFromLibrary: (isDragging: boolean) => void
) {
  const { mutate: postLayer } = usePostLayer({});
  const { mutate: putLayerById } = usePutLayerById({ id: NaN });

  const saveZIndex = useCallback(persistLayersNewZIndexOrder, [putLayerById]);
  const saveAndPersistGroupZIndex = useCallback(persistLayer, [
    addTemporaryLayer,
    postLayer,
    saveNewLayer,
    saveZIndex,
    setIsDraggingFromLibrary,
    removeTemporaryLayer,
  ]);

  async function persistLayer(
    groupId: number,
    layer: Layer,
    destinationIndex: number
  ) {
    setIsDraggingFromLibrary(true);
    addTemporaryLayer(groupId, destinationIndex);
    try {
      const result = await postLayer({
        name: layer.name,
        entityId: layer.entityId,
        layerGroupId: groupId,
        layerTypeId: layer.layerTypeId,
        visible: true,
        zIndex: undefined,
      });
      const resultLayer = result as any;
      saveNewLayer(groupId, resultLayer, destinationIndex);

      const { groups } = useProject.getState();
      const group = groups.find((group) => group.id === groupId);

      if (group) {
        const layers = group.layers;
        await saveZIndex(layers as Array<ProjectLayer>);
      }
    } catch (error) {
      console.error('Failed to save layer', error);
      removeTemporaryLayer(groupId);
    } finally {
      setIsDraggingFromLibrary(false);
    }
  }

  async function persistLayersNewZIndexOrder(layers: Array<ProjectLayer>) {
    await Promise.all(
      layers.map((layer) =>
        putLayerById(toApiLayer(layer), {
          pathParams: { id: layer.id },
        })
      )
    );
  }

  return saveAndPersistGroupZIndex;
}
