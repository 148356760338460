import { faAngleDown, faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

import { ComboBoxOption } from '@agerpoint/types';

interface ComboboxSelectProps {
  className?: string;
  selected: ComboBoxOption;
  changeFn: (layer: ComboBoxOption) => void;
  choices: ComboBoxOption[];
  label?: string;
  disabled?: boolean;
}

/**
 * @deprecated Use Input.Select.* instead
 */
export const ComboboxSelect = ({
  className,
  selected,
  changeFn,
  choices,
  label = '',
  disabled = false,
}: ComboboxSelectProps) => {
  const [query, setQuery] = useState('');

  const filteredChoices = choices.filter((choice) => {
    let nameMatch = true;
    if (query !== '' && choice?.name) {
      nameMatch = choice.name
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.toLowerCase().replace(/\s+/g, ''));
    }
    return nameMatch;
  });

  return (
    <div className={`flex flex-col ${className}`}>
      <Combobox value={selected} onChange={changeFn} disabled={disabled}>
        <div className="relative mt-1">
          <div
            className={`relative w-full cursor-default overflow-hidden rounded ring-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm`}
          >
            {label && (
              <Combobox.Label className="text-gray-700 pl-1">
                {label}:
              </Combobox.Label>
            )}

            <Combobox.Input
              className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${
                disabled ? 'cursor-not-allowed' : ''
              }`}
              displayValue={(person: any) => person.name}
              onChange={(event: any) => setQuery(event.target.value)}
            />
            <Combobox.Button
              className={`absolute inset-y-0 right-0 flex items-center pr-2 ${
                disabled ? 'cursor-not-allowed' : ''
              }`}
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className={`z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 py-1 text-base shadow-lg ring-1 ring-gray-400 ring-opacity-5 focus:outline-none sm:text-sm`}
            >
              {choices.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredChoices.map((lyr, i) => (
                  <Combobox.Option
                    key={`${lyr.id} - ${i}`}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-green-300 text-white' : 'text-gray-900'
                      }`
                    }
                    value={lyr}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {lyr.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-green-600'
                            }`}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
