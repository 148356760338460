import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Button, SidebarToggleButton } from '@agerpoint/component';
import {
  AppBarProps,
  Sidebar2ContentProps,
  Sidebar2Props,
  Sidebar2Route,
  Sidebar2Subroute,
  getFirstActiveEptId,
  isSidebar2Subroute,
} from '@agerpoint/types';
import {
  getAppBarProps,
  useGlobalStore,
  useNewLayout,
} from '@agerpoint/utilities';

import { AppBar } from '../app-bar/app-bar';
import { useCapturesViewerQueries } from '../captures-viewer/captures-viewer-queries';
import { Sidebar2Element } from './sidebar2-element';

export const Sidebar2 = ({ routing }: Sidebar2Props) => {
  const { sidebar } = useGlobalStore();

  return (
    <div
      className={`fixed flex top-0 bottom-0 left-0 z-50 transform duration-300 transition-transform select-none ${
        sidebar.isOpen ? 'translate-x-0' : '-translate-x-80'
      }`}
      onClick={(e) => {
        // To prevent the mouse event from being passed to the captures viewer under sidebar
        e.stopPropagation();
      }}
    >
      <div
        className={`h-full w-80 flex flex-col bg-white border-r border-gray-500`}
      >
        <Sidebar2AppBar />
        <Sidebar2Content routing={routing} />
        <Sidebar2BottomBar />
      </div>
      <SidebarToggleButton
        show={true}
        open={sidebar.isOpen}
        onClick={() => sidebar.actions.setIsOpen(!sidebar.isOpen)}
      />
    </div>
  );
};

const Sidebar2AppBar = () => {
  const { user } = useGlobalStore();

  const appBarProps: AppBarProps | undefined = useMemo(() => {
    if (user) {
      return getAppBarProps(user);
    }
    return undefined;
  }, [user]);
  return (
    <AppBar
      logoPath={'ap-logo-color.png'}
      navigation={appBarProps?.navigation ?? []}
      compact={true}
      firstLetterFirstName={appBarProps?.firstLetterFirstName}
    />
  );
};

const Sidebar2Content = ({ routing }: Sidebar2ContentProps) => {
  const buildRoute = useCallback(
    (route: Sidebar2Route | Sidebar2Subroute, level: number) => {
      const path = isSidebar2Subroute(route) ? `${route.path}/*` : '/*';
      return (
        <Route
          path={path}
          element={<Sidebar2Element route={route} level={level} />}
          key={`${path}_${level}`}
        >
          {route.subroutes?.map((sub) => buildRoute(sub, level + 1))}
        </Route>
      );
    },
    []
  );

  return (
    <div className={`w-full h-full overflow-hidden relative`}>
      <Routes>{buildRoute(routing, 0)}</Routes>
    </div>
  );
};

const Sidebar2BottomBar = () => {
  const { toggleLegacyLayout, showNewLayoutToggle } = useNewLayout();
  const { captureQuery } = useCapturesViewerQueries({});

  return (
    <div className="w-full h-20 shadow-top z-50 flex justify-center items-end shrink-0 grow-0 p-2">
      {showNewLayoutToggle && (
        <Button.Small
          id="legacy-layout-toggle"
          label="Switch to Legacy Viewer"
          onClick={() => {
            const eptId = getFirstActiveEptId(captureQuery.data);

            toggleLegacyLayout(eptId);
          }}
        />
      )}
      <div className="text-xs text-gray-500 text-center flex flex-grow items-end justify-end">
        Version - {process.env.VERSION}
      </div>
    </div>
  );
};
