import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';

export const regions = (data?: APIModels.Region[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) =>
    compare(a.regionName?.toLowerCase(), b.regionName?.toLowerCase())
  );
  return sorted;
};
