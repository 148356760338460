import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ToastsComponent, UndosComponent } from '@agerpoint/feature';
import {
  EffectNames,
  EventBusNames,
  LdFlags,
  UserClaims,
} from '@agerpoint/types';
import {
  environment,
  eventBus,
  hasClaims,
  hasPermission,
  useGlobalStore,
} from '@agerpoint/utilities';

import { AdminPageRoutes } from '../admin-page';
import { CapturesRoutes } from '../captures-page/captures-page';
import { NewUpload } from '../new-upload-page';
import { Ops } from '../ops-page';
import { Organization } from '../organization-page';
import { ProjectPage } from '../project-page';
import { Projects } from '../projects-page/projects';
import { Reports } from '../reports-page';
import { Settings } from '../settings/settings-page';
import { StandAloneViewerMainPage } from '../stand-alone-viewer-main-page/stand-alone-viewer-main-page';
import { UploadsPage } from '../uploads';

export const AgercloudMainPage = () => {
  const {
    actions: { dispatchEffect },
  } = useGlobalStore();

  const handlePointCloudLoaded = () => {
    dispatchEffect(EffectNames.POTREE_POINT_CLOUD_LOADED, {});
  };

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.PointCloudLoaded,
      handlePointCloudLoaded,
      true
    );
    return () => {
      eventBus.remove(
        EventBusNames.PointCloudLoaded,
        handlePointCloudLoaded,
        eventId
      );
    };
  }, []);

  return (
    <>
      <ToastsComponent />
      <UndosComponent />
      <ForceM2AppRoutes />
    </>
  );
};

const ForceM2AppRoutes = () => {
  const { permissions } = useGlobalStore();
  const location = useLocation();

  const forceM2App = useMemo(
    () => hasPermission(LdFlags.ForceM2App, permissions),
    [permissions]
  );

  const { user } = useGlobalStore();

  const hasCapturesClaim = hasClaims(
    [UserClaims.Cloud],
    user?.cloudClaims as UserClaims[]
  );

  const defaultUserRoute = hasCapturesClaim ? '/captures' : '/projects';

  useEffect(() => {
    if (!forceM2App) {
      return;
    }

    if (location.pathname.startsWith('/app')) {
      return;
    }

    const base = '/app';

    // regex to match /captures/:captureId...
    if (location.pathname.match(/\/captures\/\d+/)) {
      // get the id from the path
      const id = location.pathname.split('/')[2];
      const path = `${base}/capture/${id}`;

      window.location.href = path;
      return;
    }

    // regex to match /project/:projectId... where project id is a string uuid
    if (location.pathname.match(/\/project\/[a-f0-9-]+/)) {
      // get the id from the path
      const id = location.pathname.split('/')[2];
      const path = `${base}?project=${id}`;

      window.location.href = path;
      return;
    }

    // regex to match /ops/pipeline
    if (location.pathname.match(/\/ops\/pipeline/)) {
      let path = `${base}/admin/pipelines`;

      // add the rest of the path
      path += location.pathname.replace('/ops/pipeline', '') + location.search;

      // one more replace to fix the pipeline-jobs path
      path = path.replace('pipeline-jobs', 'jobs');

      window.location.href = path;
      return;
    }

    // regex to match /ops
    else if (location.pathname.match(/\/ops/)) {
      let path = `${base}/admin/operations`;

      // add the rest of the path
      path += location.pathname.replace('/ops', '') + location.search;

      window.location.href = path;
      return;
    }

    // regex to match /admin
    if (location.pathname.match(/\/admin/)) {
      let path = `${base}/admin/platform`;

      // add the rest of the path
      path += location.pathname.replace('/admin', '') + location.search;

      window.location.href = path;
      return;
    }

    window.location.href = base;
  }, [forceM2App, location]);

  if (forceM2App) {
    return null;
  }

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden">
      <Routes>
        <Route path="/captures/*" element={<CapturesRoutes />} />
        <Route path="/project/:id" element={<ProjectPage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/uploads" element={<UploadsPage />} />
        <Route
          path="/uploads/new"
          element={<NewUpload bingKey={environment.bing_api_key} />}
        />
        <Route path="/profile" element={<Settings />} />
        <Route path="/admin/*" element={<AdminPageRoutes />} />
        <Route path="/ops/*" element={<Ops />} />
        <Route path="/reports/*" element={<Reports />} />
        <Route path="/organization/*" element={<Organization />} />
        <Route path="/viewer" element={<StandAloneViewerMainPage />} />
        <Route path="/dashboard" element={<Navigate to="/projects" />} />
        <Route path="/" element={<Navigate to={defaultUserRoute} />} />
      </Routes>
    </div>
  );
};
