import {
  faArrowDown,
  faArrowUp,
  faCircleNotch,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Input } from '@agerpoint/component';
import {
  AnalyticRequestStatus,
  Sidebar2Subroute,
  analyticRequestStatusList,
} from '@agerpoint/types';
import { useQueryState } from '@agerpoint/utilities';

import { useCapturesViewerQueries } from '../../../captures-viewer-queries';
import { CVS3AnalyticsRequestsList } from '../../shared/analytics-requests-list/cvs3-analytics-requests-list';
import { cvs3BaseCaptureFromOutputsRoute } from '../base-capture/cvs3-base-capture';
import { cvs3AnalyticsOutputsDetailsRoute } from './analytic-output-details/cvs3-analytic-output-details';
import { ExportAnalyticsOutputsButton } from './subcomponents/export-analytics-outputs-button';

export const CVS3AnalyticsOutputs = () => {
  const { analyticRequestsQuery } = useCapturesViewerQueries({});

  const [analyticStatusFilterInputValue, setAnalyticStatusFilterInputValue] =
    useQueryState<AnalyticRequestStatus | undefined>({
      paramName: 'analytic_status',
      initialValue: undefined,
      fromUrlParam: (a) => {
        return analyticRequestStatusList.find(
          (status) => status.name.toLowerCase() === a.toLowerCase()
        )?.name;
      },
      toUrlParam: (a) => a?.toString()?.toLowerCase() ?? '',
    });

  const [analyticFilterInputValue, setAnalyticFilterInputValue] = useQueryState(
    {
      paramName: 'analytic_name',
      initialValue: '',
      fromUrlParam: (a) => a.trim(),
      toUrlParam: (a) => a.trim(),
    }
  );

  const [objectFilterInputValue, setObjectFilterInputValue] = useQueryState({
    paramName: 'object_name',
    initialValue: '',
    fromUrlParam: (a) => a.trim(),
    toUrlParam: (a) => a.trim(),
  });

  const [attributeFilterInputValue, setAttributeFilterInputValue] =
    useQueryState({
      paramName: 'attribute_names',
      initialValue: '',
      fromUrlParam: (a) => a.trim(),
      toUrlParam: (a) => a.trim(),
    });

  const [sorting, setSorting] = useQueryState({
    paramName: 'sort',
    initialValue: 'ASC',
    toUrlParam: (a) => {
      if (a === 'DESC') {
        return 'desc';
      }

      return '';
    },
    fromUrlParam: (a) => {
      if (a.toLowerCase() === 'desc') {
        return 'DESC';
      }

      return 'ASC';
    },
  });

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className={`flex flex-col items-center justify-center bg-white border-b border-gray-200 w-full text-xs p-2`}
      >
        <div className="flex flex-row w-full pt-1">
          <div className="flex flex-col gap-1 w-full">
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-row items-center">
                <Input.Text.Single
                  id={'analytic-name-filter'}
                  placeholder="Filter by Analytic name"
                  placeholderIcon={Input.placeholderIcons.filter}
                  value={analyticFilterInputValue}
                  setValue={setAnalyticFilterInputValue}
                  style={Input.style.mini}
                />
                <div className="pl-1">
                  {analyticFilterInputValue.length ? (
                    <div
                      className={`w-4 h-4 text-gray-500 hover:text-gray-900
                  flex items-center justify-center cursor-pointer`}
                      onClick={() => {
                        setAnalyticFilterInputValue('');
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilterSlash}
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="w-4 h-4" />
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <Input.Select.Single
                  id={'analytic-status-filter'}
                  placeholder="Filter by Analytic status"
                  placeholderIcon={Input.placeholderIcons.filter}
                  value={analyticStatusFilterInputValue}
                  setValue={setAnalyticStatusFilterInputValue}
                  style={Input.style.mini}
                  title="Analytic Status"
                  search={false}
                  clearAllButton={false}
                  options={analyticRequestStatusList.map(
                    (status) => status.name
                  )}
                  optionBuilder={(option) =>
                    option.charAt(0).toUpperCase() +
                    option.slice(1).toLowerCase()
                  }
                  stretch={true}
                />
                <div className="pl-1">
                  {analyticStatusFilterInputValue !== undefined ? (
                    <div
                      className={`w-4 h-4 text-gray-500 hover:text-gray-900
                  flex items-center justify-center cursor-pointer`}
                      onClick={() => {
                        setAnalyticStatusFilterInputValue(undefined);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilterSlash}
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="w-4 h-4" />
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <Input.Text.Single
                  id={'object-name-filter'}
                  placeholder="Filter by Object name"
                  placeholderIcon={Input.placeholderIcons.filter}
                  value={objectFilterInputValue}
                  setValue={setObjectFilterInputValue}
                  style={Input.style.mini}
                />
                <div className="pl-1">
                  {objectFilterInputValue.length ? (
                    <div
                      className={`w-4 h-4 text-gray-500 hover:text-gray-900
                  flex items-center justify-center cursor-pointer`}
                      onClick={() => {
                        setObjectFilterInputValue('');
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilterSlash}
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="w-4 h-4" />
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <Input.Text.Single
                  id={'object-attribute-filter'}
                  placeholder="Filter by Attribute name"
                  placeholderIcon={Input.placeholderIcons.filter}
                  value={attributeFilterInputValue}
                  setValue={setAttributeFilterInputValue}
                  style={Input.style.mini}
                />
                <div className="pl-1">
                  {attributeFilterInputValue.length ? (
                    <div
                      className={`w-4 h-4 text-gray-500 hover:text-gray-900
                  flex items-center justify-center cursor-pointer`}
                      onClick={() => {
                        setAttributeFilterInputValue('');
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilterSlash}
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="w-4 h-4" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full justify-between gap-1">
              <span className="text-xs text-gray-500 w-full leading-none">
                Tip: use comma to filter multiple attributes
              </span>
              <div className="whitespace-nowrap">
                <Button.Small
                  id="sort-button"
                  onClick={() => {
                    setSorting(sorting === 'ASC' ? 'DESC' : 'ASC');
                  }}
                  label={`Sort ${sorting}`}
                  icon={sorting === 'ASC' ? faArrowUp : faArrowDown}
                />
              </div>
            </div>
            <div className="w-full flex flex-col items-stretch justify-stretch">
              <ExportAnalyticsOutputsButton
                filter={{
                  analyticName: analyticFilterInputValue,
                  objectName: objectFilterInputValue,
                  attributeNames: attributeFilterInputValue,
                  status: analyticStatusFilterInputValue,
                  sort: sorting,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {analyticRequestsQuery.isLoading ? (
        <div className="flex-grow w-full flex items-center justify-center">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : (
        <div className="flex-grow w-full overflow-x-hidden overflow-y-auto">
          <CVS3AnalyticsRequestsList
            limited={false}
            filter={{
              analyticName: analyticFilterInputValue,
              objectName: objectFilterInputValue,
              attributeNames: attributeFilterInputValue,
              sort: sorting,
              status: analyticStatusFilterInputValue,
            }}
          />
        </div>
      )}
    </div>
  );
};

export const cvs3AnalyticsOutputsRoute: Sidebar2Subroute = {
  element: <CVS3AnalyticsOutputs />,
  path: 'analytics-outputs',
  title: <div>Outputs</div>,
  subroutes: [
    cvs3AnalyticsOutputsDetailsRoute,
    cvs3BaseCaptureFromOutputsRoute,
  ],
};
