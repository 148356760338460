import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import { Button, Input } from '@agerpoint/component';
import { Sidebar2Subroute } from '@agerpoint/types';

import { useCapturesViewerQueries } from '../../../captures-viewer-queries';

export const CVS3Note = () => {
  const { captureQuery, capturePutMutation } = useCapturesViewerQueries({});

  useEffect(() => {
    setNote(captureQuery.data?.description ?? '');
  }, [captureQuery.data]);

  const [note, setNote] = useState('');

  return (
    <div className="px-1 text-sm w-full">
      <div className="w-full flex flex-col p-1 gap-2">
        <Input.Text.Area
          id="capture-notes"
          value={note}
          setValue={setNote}
          rows={8}
          resize={true}
        />
        <Button.Primary
          id="save-note"
          label="Save Note"
          icon={faSave}
          loading={capturePutMutation.isPending}
          onClick={() => {
            if (capturePutMutation.isPending) {
              return;
            }

            capturePutMutation.mutate({
              id: captureQuery.data?.id as number,
              data: { ...captureQuery.data, description: note.trim() },
            });
          }}
        />
      </div>
    </div>
  );
};

export const cvs3NoteRoute: Sidebar2Subroute = {
  element: <CVS3Note />,
  subroutes: [],
  path: 'note',
  title: <div>Note</div>,
};
