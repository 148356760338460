import { useEffect, useMemo, useState } from 'react';

import {
  APIModels,
  useGetCaptureImageFileById,
  useGetCaptureImageHorizontalThumbnailById,
  useGetCaptureImageThumbnailById2,
  useGetCaptureImageVerticalThumbnailById,
} from '@agerpoint/api';
import { ImageViewer } from '@agerpoint/component';
import { CaptureJobTypes, OrientationOptions } from '@agerpoint/types';
import { isGaussianJob } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../captures-viewer-context';
import { useCapturesViewerQueries } from '../../captures-viewer-queries';

interface CapturesViewer2ExpandedImageProps {
  expandedImage?: APIModels.CaptureImage;
  setExpandedImage: (image?: APIModels.CaptureImage) => void;
  orientation: OrientationOptions;
}

export const CapturesViewer2ExpandedImage = ({
  expandedImage,
  setExpandedImage,
  orientation,
}: CapturesViewer2ExpandedImageProps) => {
  const [lowResJobId, setLowResJobId] = useState<number>();
  const [totalImageCount, setTotalImageCount] = useState<number>(0);
  const [captureJobImages, setCaptureJobImages] = useState<
    APIModels.CaptureImage[]
  >([]);

  const { selectedCaptureJob } = useCapturesViewerContext();
  const {
    captureQuery,
    captureJobImagesQuery,
    captureJobImagesForLowResJobIdQuery,
  } = useCapturesViewerQueries({
    selectedCaptureJob,
    lowResJobId,
  });

  const [expandedImageBlob, setExpandedImageBlob] = useState<Blob>();
  const [fullResImageBlob, setFullResImageBlob] = useState<Blob>();
  const [thumbnailData, setThumbnailData] = useState<any>();

  const requestBody = useMemo(() => {
    return {
      id: expandedImage?.id ?? NaN,
      fit: 'fit-in',
      crop: '%20',
      size: `${2000}x${2000}`,
      verticalAlign: '%20',
      horizontalAlign: '%20',
      filters: '%20',
      lazy: true,
    };
  }, [expandedImage]);

  const {
    data: thumbnailDataOriginalOrientation,
    refetch: refetchOriginalOrientation,
    cancel: cancelOriginalOrientation,
  } = useGetCaptureImageThumbnailById2(requestBody) as any;

  const {
    data: thumbnailDataLandscapeOrientation,
    refetch: refetchLandscapeOrientation,
    cancel: cancelLandscapeOrientation,
  } = useGetCaptureImageHorizontalThumbnailById(requestBody) as any;

  const {
    data: thumbnailDataPortraitOrientation,
    refetch: refetchPortraitOrientation,
    cancel: cancelPortraitOrientation,
  } = useGetCaptureImageVerticalThumbnailById(requestBody) as any;

  const {
    data: fullResImageDataOriginalOrientation,
    refetch: refetchFullResOriginalOrientation,
    cancel: cancelFullResOriginalOrientation,
  } = useGetCaptureImageFileById({
    id: expandedImage?.id ?? NaN,
    lazy: true,
  }) as any;

  useEffect(() => {
    const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
      return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
    });
    if (!lowResJob || !lowResJob?.id || !captureQuery?.data?.id) return;
    const lowResJobId = lowResJob.id;
    setLowResJobId(lowResJobId);
  }, [captureQuery?.data]);

  useEffect(() => {
    if (!fullResImageDataOriginalOrientation) return;
    const doAsync = async () => {
      const blob = await fullResImageDataOriginalOrientation.blob();
      setFullResImageBlob(blob);
    };
    doAsync();
  }, [fullResImageDataOriginalOrientation]);

  useEffect(() => {
    if (orientation === OrientationOptions.Original) {
      setThumbnailData(thumbnailDataOriginalOrientation);
    } else if (orientation === OrientationOptions.Landscape) {
      setThumbnailData(thumbnailDataLandscapeOrientation);
    } else if (orientation === OrientationOptions.Portrait) {
      setThumbnailData(thumbnailDataPortraitOrientation);
    }
  }, [
    thumbnailDataOriginalOrientation,
    thumbnailDataPortraitOrientation,
    thumbnailDataLandscapeOrientation,
  ]);

  useEffect(() => {
    if (isGaussianJob(selectedCaptureJob)) {
      setTotalImageCount(captureJobImagesForLowResJobIdQuery.data?.length ?? 0);
      setCaptureJobImages(captureJobImagesForLowResJobIdQuery.data ?? []);
    } else {
      setTotalImageCount(captureJobImagesQuery.data?.length ?? 0);
      setCaptureJobImages(captureJobImagesQuery.data ?? []);
    }
  }, [
    selectedCaptureJob,
    captureJobImagesForLowResJobIdQuery.data,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    if (!expandedImage) return;
    setExpandedImageBlob(undefined);

    if (orientation === OrientationOptions.Original) {
      refetchOriginalOrientation();
    } else if (orientation === OrientationOptions.Landscape) {
      refetchLandscapeOrientation();
    } else if (orientation === OrientationOptions.Portrait) {
      refetchPortraitOrientation();
    }
  }, [expandedImage, orientation]);

  useEffect(() => {
    if (!expandedImage) return;
    refetchFullResOriginalOrientation();
  }, [expandedImage]);

  useEffect(() => {
    if (!thumbnailData?.blob) {
      return;
    }
    const doAsync = async () => {
      const b = await thumbnailData.blob();
      setExpandedImageBlob(b);
    };
    try {
      doAsync();
    } catch (e) {
      console.error(e);
    }
  }, [thumbnailData]);

  return (
    <ImageViewer
      open={!!expandedImage}
      handleCloseViewer={() => {
        setExpandedImage(undefined);
        setExpandedImageBlob(undefined);
        setFullResImageBlob(undefined);
      }}
      handleNavigation={{
        next: () => {
          let index = captureJobImages.findIndex(
            (c) => c.id === expandedImage?.id
          );

          if (index === undefined) {
            return;
          }

          if (index <= -1) {
            return;
          }
          setExpandedImageBlob(undefined);
          setFullResImageBlob(undefined);

          index += 1;
          if (index > (captureJobImages.length ?? 0) - 1) {
            index = 0;
          }

          const newImage = captureJobImages[index];
          setExpandedImage(newImage);
        },
        previous: () => {
          let index = captureJobImages.findIndex(
            (c) => c.id === expandedImage?.id
          );

          if (index === undefined) {
            return;
          }

          if (index <= -1) {
            return;
          }
          setExpandedImageBlob(undefined);
          setFullResImageBlob(undefined);

          index -= 1;
          if (index < 0) {
            index = (captureJobImages.length ?? 0) - 1;
          }

          const newImage = captureJobImages[index];
          setExpandedImage(newImage);
        },
      }}
      loading={!expandedImageBlob}
      showDownloadButton={true}
      downloadBlob={fullResImageBlob}
    >
      <div className="flex flex-row justify-center">
        {expandedImageBlob && (
          <div className="relative">
            <div
              className={`absolute bottom-0 left-0 right-0 flex flex-row justify-between gap-1 bg-gray-900 text-gray-100 rounded`}
            >
              <div className="px-1 py-0.5 text-4xl">
                {/* localIndex is set behind TypeScript's back in captures-viewer-queries */}
                {`${(expandedImage as any)?.localIndex} of ${
                  totalImageCount ?? 0
                }`}
              </div>
              <div className="px-1 py-0.5 text-4xl">
                {expandedImage?.note ? `Note: ${expandedImage?.note}` : ''}
              </div>
            </div>
            <img
              style={{ borderRadius: '1%' }}
              src={URL.createObjectURL(expandedImageBlob)}
              alt={`Capture ${expandedImage?.id}`}
            />
          </div>
        )}
      </div>
    </ImageViewer>
  );
};
