import {
  faBoxArchive,
  faDownload,
  faImageSlash,
  faPen,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleNotch,
  faSunPlantWilt,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faSlashBack } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseGetReturn } from 'restful-react';

import {
  APIClient,
  Capture,
  CaptureJob,
  User,
  formatDateAndTime,
  useGetCaptureImageThumbnailById2,
  useGetCaptureJobsByCaptureId,
  useGetEptJsonById,
  useGetPlyDownloadUrlByCaptureJobId,
  usePostCaptureHighResJob,
  usePutCaptureJobById,
} from '@agerpoint/api';
import { ConfirmModal, Input } from '@agerpoint/component';
import {
  CaptureJobTypes,
  CaptureRow,
  EffectNames,
  LDFlagSet,
  MixpanelNames,
  UserClaims,
  isCaptureReadyToViewInLegacyLayout,
  isCaptureReadyToViewInNewLayout,
} from '@agerpoint/types';
import {
  APIUtils,
  blobCache,
  createFilename,
  hasClaims,
  isNonLidarJob,
  useGlobalStore,
  useNewLayout,
  useToasts,
} from '@agerpoint/utilities';

type CaptureThumbnailSize = 'small' | 'big';
export const CaptureThumbnail = ({
  capture,
  size,
  ldFlags,
}: {
  capture: Capture;
  size: CaptureThumbnailSize;
  ldFlags: LDFlagSet;
}) => {
  const { showNewLayout } = useNewLayout();

  const canViewCapture = showNewLayout
    ? isCaptureReadyToViewInNewLayout(capture)
    : isCaptureReadyToViewInLegacyLayout(capture);

  const sizeMapping = useMemo(
    () => ({
      small: 100,
      big: 400,
    }),
    []
  );

  if (canViewCapture) {
    return (
      <ImageFromImageService
        size={size}
        capture={capture}
        sizeMapping={sizeMapping}
      />
    );
  }

  return <MissingThumborImage capture={capture} size={size} />;

  // } else {
  //   return (
  //     <div
  //       className={`${
  //         size === 'small'
  //           ? 'w-14 rounded-lg overflow-hidden flex justify-center mx-2 items-center'
  //           : 'w-full flex items-center justify-center object-cover rounded-t-md overflow-hidden'
  //       }`}
  //       style={{ aspectRatio: size === 'small' ? '' : '5/ 3' }}
  //     >
  //       <FontAwesomeIcon icon={faImage} className="fa-lg" />
  //     </div>
  //   );
  // }
};

const ImageFromImageService = ({
  size,
  capture,
  sizeMapping,
}: {
  size: CaptureThumbnailSize;
  capture: CaptureRow;
  sizeMapping: { [key in CaptureThumbnailSize]: number };
}) => {
  const [blobUrl, setBlobUrl] = useState<string>();

  const {
    data: thumbnail,
    loading,
    refetch,
  } = useGetCaptureImageThumbnailById2({
    id: capture.firstCaptureImageId ?? -1,
    fit: 'fit-in',
    crop: '%20',
    size: `${sizeMapping[size]}x${sizeMapping[size]}`,
    verticalAlign: '%20',
    horizontalAlign: '%20',
    filters: '%20',
    lazy: true,
  }) as any;

  useEffect(() => {
    setBlobUrl(undefined);
    const key = `capture-thumbnail-${capture.id}-${sizeMapping[size]}`;
    if (blobCache.has(key)) {
      setBlobUrl(blobCache.get(key)?.url);
    } else {
      refetch();
    }
  }, [capture, size, sizeMapping]);

  useEffect(() => {
    if (!thumbnail?.blob) {
      return;
    }
    const doAsync = async () => {
      try {
        const blob = await thumbnail.blob();
        const url = URL.createObjectURL(blob);
        blobCache.set(`capture-thumbnail-${capture.id}-${sizeMapping[size]}`, {
          blob,
          url,
        });
        setBlobUrl(url);
      } catch (e) {
        //
      }
    };
    doAsync();
  }, [thumbnail, capture, sizeMapping, size]);

  return (
    <div
      className={`${
        size === 'small'
          ? 'w-14 rounded-lg overflow-hidden mx-2'
          : 'w-full flex items-stretch object-cover rounded-t-md overflow-hidden'
      }`}
      style={{ aspectRatio: size === 'small' ? '4 / 3' : '5 / 3' }}
    >
      {blobUrl && (
        <img
          className="object-cover select-none flex-1"
          alt={`${capture.captureName} thumbnail`}
          src={blobUrl}
        />
      )}
    </div>
  );
};

export const MissingThumborImage = ({
  capture,
  size,
}: {
  capture: Capture;
  size: string;
}) => {
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!capture?.scanDatetime) return;
    const scanDate = Date.parse(capture?.scanDatetime);
    const now: number = new Date().getTime();
    const hours = Math.abs(now - scanDate) / 36e5;
    if (hours > 24) {
      setImageIsLoading(false);
    }
  }, [capture]);

  return (
    <div
      className={`${
        size === 'small'
          ? 'w-14 rounded-lg overflow-hidden flex justify-center mx-2 items-center'
          : 'w-full flex items-center justify-center object-cover rounded-t-md overflow-hidden'
      }`}
      style={{ aspectRatio: size === 'small' ? '' : '5/ 3' }}
    >
      {imageIsLoading ? (
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          className={`fa-spin  text-green-600 ${
            size === 'small' ? 'fa-xl' : 'fa-4x'
          }`}
        />
      ) : (
        <FontAwesomeIcon
          icon={faImageSlash}
          className={`text-green-600 ${size === 'small' ? 'fa-xl' : 'fa-4x'}`}
        />
      )}
    </div>
  );
};

interface CapturePipelineJobTableRowProps {
  index: number;
  hideUUIDs: boolean;
  job: CaptureJob;
  editNameChange: (name: string, id: number | undefined) => void;
  downloadEpt: (id: number) => Promise<void>;
  confirmUnarchiveJob: (id: number) => Promise<void>;
  confirmArchiveJob: (id: number) => Promise<void>;
  jobBeingEdited: number | null | undefined;
  setJobBeingEdited: (id: number | undefined | null) => void;
  loadingPutCaptureJob: boolean;
  userIsAdmin: boolean;
}

const CapturePipelineJobTableRow = ({
  index,
  hideUUIDs,
  job,
  editNameChange,
  downloadEpt,
  confirmArchiveJob,
  confirmUnarchiveJob,
  jobBeingEdited,
  setJobBeingEdited,
  loadingPutCaptureJob,
  userIsAdmin,
}: CapturePipelineJobTableRowProps) => {
  const toasts = useToasts();

  const { data, refetch: refetchPly } = useGetPlyDownloadUrlByCaptureJobId({
    id: job.id ?? NaN,
    lazy: true,
  }) as unknown as UseGetReturn<CaptureJob, void, void, unknown>;

  const [plyUrl, setPlyUrl] = useState<string>();

  const GSPLAT_VIEWER_URL = 'https://gsplat.agerpoint.com/?load=';

  useEffect(() => {
    if (data?.plyPath) {
      setPlyUrl(data?.plyDownloadUrl ?? undefined);
    }
  }, [data]);

  useEffect(() => {
    refetchPly();
  }, []);

  const plyViewerUrl: string | undefined = useMemo(() => {
    if (!plyUrl) {
      return undefined;
    }

    return GSPLAT_VIEWER_URL + encodeURIComponent(encodeURIComponent(plyUrl));
  }, [plyUrl]);

  const downloadPly = useCallback(() => {
    if (!plyUrl) {
      return;
    }

    const link = document.createElement('a');
    link.href = plyUrl;
    link.click();
  }, [plyUrl]);

  const openPlyViewer = useCallback(() => {
    if (!plyViewerUrl) {
      return;
    }

    const link = document.createElement('a');
    link.href = plyViewerUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  }, [plyViewerUrl]);

  return (
    <tr>
      {jobBeingEdited === index ? (
        <td className="p-2 w-1/2 h-12">
          <div className="flex items-center ">
            <Input.Text.Single
              id={`job-name-input-${job?.id}`}
              value={job?.name || ''}
              setValue={(v) => {
                editNameChange(v, job.id);
              }}
              style={Input.style.mini}
            />
            <div className="w-8 flex justify-center items-center">
              {loadingPutCaptureJob ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="text-green text-xl "
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-green cursor-pointer text-xl "
                  onClick={() => {
                    setJobBeingEdited(null);
                  }}
                />
              )}
            </div>
          </div>
        </td>
      ) : (
        <td className="p-1 w-1/3 h-12">
          {`${job?.name} `}
          {job?.archived ? (
            <span className="bg-red text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
              Archived
            </span>
          ) : (
            ''
          )}
        </td>
      )}

      {!hideUUIDs && (
        <>
          <td className="p-1 h-12">{job?.uuid}</td>
          <td className="p-1 h-12">{job?.createdById}</td>
        </>
      )}
      {userIsAdmin && (
        <>
          <td className="p-1 h-12">{job?.mosaicEngine}</td>
          <td className="p-1 h-12">{job?.mlModel}</td>
        </>
      )}
      <td className="p-1 h-12">{formatDateAndTime(job?.createDatetime)}</td>
      <td className="p-1 h-12">{formatDateAndTime(job?.completedDateTime)}</td>
      <td className="p-1 h-12">
        {CaptureJobTypes[job?.captureJobTypeId ?? 0]}
      </td>
      <td className="px-1">
        <FontAwesomeIcon
          icon={faPen}
          className={`px-1 text-xl text-gray-400 hover:text-gray-900 ${
            job?.archived ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          title="Edit Job Name"
          onClick={() => {
            if (job?.archived) {
              toasts.add({
                title: 'Model is archived.',
                message: 'Restore it first before editing.',
                type: 'info',
              });
              return;
            }
            setJobBeingEdited(index);
          }}
        />
      </td>
      <td className="px-1">
        <div
          className={`flex flex-col items-center justify-center px-1
                    text-gray-400 hover:text-gray-900 cursor-pointer text-sm`}
          title="Download Job Ept Json"
          onClick={() => {
            if (job?.archived) {
              toasts.add({
                title: 'Model is archived.',
                message: 'Restore it first before downloading.',
                type: 'info',
              });

              return;
            }
            downloadEpt(job?.eptPointcloudId || NaN);
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
          <span className="text-xs">EPT</span>
        </div>
      </td>
      {plyUrl ? (
        <>
          <td className="px-1">
            <div
              className={`flex flex-col items-center justify-center px-1
                        text-gray-400 hover:text-gray-900 cursor-pointer text-sm`}
              title="Download Job Ply"
              onClick={() => {
                if (job?.archived) {
                  toasts.add({
                    title: 'Model is archived.',
                    message: 'Restore it first before downloading.',
                    type: 'info',
                  });

                  return;
                }
                downloadPly();
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className="text-xs">PLY</span>
            </div>
          </td>
          <td className="px-1">
            <div
              className={`flex flex-col items-center justify-center px-1
                        text-gray-400 hover:text-gray-900 cursor-pointer text-sm`}
              title="Open Ply in GSplat Viewer"
              onClick={() => {
                if (job?.archived) {
                  toasts.add({
                    title: 'Model is archived.',
                    message: 'Restore it first before opening.',
                    type: 'info',
                  });
                  return;
                }
                openPlyViewer();
              }}
            >
              <FontAwesomeIcon icon={faSunPlantWilt} />
              <span className="text-xs">PLY</span>
            </div>
          </td>
        </>
      ) : (
        <>
          <td className="px-1" />
          <td className="px-1" />
        </>
      )}
      {job?.archived ? (
        <td
          className="px-1"
          title="Restore This Model"
          onClick={() => {
            if (loadingPutCaptureJob) {
              return;
            }
            confirmUnarchiveJob(job?.id || NaN);
          }}
        >
          <div className="fa-stack text-gray-400 hover:text-gray-900 cursor-pointer">
            <FontAwesomeIcon
              icon={faBoxArchive}
              className="fa-stack-2x fa-xs"
            />
            <FontAwesomeIcon icon={faSlashBack} className="fa-stack-2x" />
          </div>
        </td>
      ) : (
        <td className="px-1" style={{ textAlign: 'center' }}>
          <FontAwesomeIcon
            icon={faBoxArchive}
            className=" fa-xl text-gray-400 hover:text-gray-900 cursor-pointer"
            title="Archive This Model"
            onClick={() => {
              if (loadingPutCaptureJob) {
                return;
              }

              confirmArchiveJob(job?.id || NaN);
            }}
          />
        </td>
      )}
    </tr>
  );
};

export const CapturePipelineJobTable = ({
  hideUUIDs = true,
}: {
  hideUUIDs?: boolean;
}) => {
  const {
    actions: { dispatchEffect },
    user,
  } = useGlobalStore();

  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
  const [jobs, setJobs] = useState<CaptureJob[]>([]);
  const [selectedEptIdForDownload, setSelectedEptIdForDownload] =
    useState<number>(NaN);
  const [jobBeingEdited, setJobBeingEdited] = useState<number | null>();
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] =
    useState<boolean>(false);
  const [showConfirmUnarchiveModal, setShowConfirmUnarchiveModal] =
    useState<boolean>(false);

  const { mutate: putCaptureJob, loading: loadingPutCaptureJob } =
    usePutCaptureJobById({
      captureId: '',
      jobId: NaN,
    });

  const params = useParams();

  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
      select: (data) => ({
        ...data,
        completedJobs: data?.completedJobs?.filter(
          (job) => !isNonLidarJob(data, job)
        ),
      }),
    },
  });

  const { data: eptData, refetch } = useGetEptJsonById({
    id: selectedEptIdForDownload,
    lazy: true,
  }) as any;

  const { data: allCaptureJobs, refetch: refetchAllCaptureJobs } =
    useGetCaptureJobsByCaptureId({
      captureId: captureQuery.data?.id ?? NaN,
      lazy: true,
    }) as unknown as UseGetReturn<CaptureJob[], void, void, unknown>;

  useEffect(() => {
    const isAdmin = hasClaims(
      [UserClaims.AgerAdmin],
      user?.cloudClaims as UserClaims[]
    );
    setUserIsAdmin(isAdmin);
  }, [user]);

  useEffect(() => {
    if (!selectedEptIdForDownload) return;
    refetch();
  }, [selectedEptIdForDownload]);

  useEffect(() => {
    if (!eptData) return;
    setSelectedEptIdForDownload(NaN);
    const data =
      'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(eptData));

    const a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = createFilename('ept', '.json');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [eptData]);

  useEffect(() => {
    if (!userIsAdmin) {
      setJobs(captureQuery.data?.completedJobs ?? []);
    } else if (captureQuery.data?.id) {
      refetchAllCaptureJobs();
    }
  }, [captureQuery.data, userIsAdmin]);

  useEffect(() => {
    if (!allCaptureJobs || !captureQuery.data?.id) return;
    setJobs(
      allCaptureJobs?.filter((j) => !isNonLidarJob(captureQuery.data, j)) ?? []
    );
  }, [allCaptureJobs]);

  const debounceCaptureJobUpdate = useCallback(
    debounce(async (job: { name: string }, captureId: string, id: number) => {
      await putCaptureJob(job, { pathParams: { captureId, jobId: id } });
      dispatchEffect(EffectNames.REFRESH_CAPTURE_JOBS);

      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.captures, { captureId: captureId }],
      });
    }, 500),
    [dispatchEffect]
  );

  const editNameChange = (name: string, id: number | undefined) => {
    if (!id) return;
    const tempJobList = [...jobs];
    const beingEditedJob = tempJobList.find((job) => job.id === id);
    if (!beingEditedJob) return;
    beingEditedJob.name = name;
    setJobs(tempJobList);

    if (!captureQuery.data?.id || !beingEditedJob.id) return;

    debounceCaptureJobUpdate(
      { name: name },
      captureQuery.data?.id.toString(),
      beingEditedJob.id
    );
  };

  const downloadEpt = async (id: number) => {
    if (!id) return;
    setSelectedEptIdForDownload(id);
  };

  const confirmUnarchiveJob = async (id: number) => {
    if (!id) return;
    if (loadingPutCaptureJob) return;
    setJobBeingEdited(id);
    setShowConfirmUnarchiveModal(true);
  };

  const confirmArchiveJob = async (id: number) => {
    if (!id) return;
    if (loadingPutCaptureJob) return;
    setJobBeingEdited(id);
    setShowConfirmArchiveModal(true);
  };

  const queryClient = useQueryClient();

  const archiveJob = async (id: number, archived = false) => {
    if (!id || !captureQuery.data?.id || loadingPutCaptureJob) return;
    const jobBeingArchived = jobs.find((job) => job.id === id);
    if (!jobBeingArchived) {
      setShowConfirmArchiveModal(false);
      return;
    }
    const captureId = captureQuery.data?.id.toString();
    await putCaptureJob(
      { ...jobBeingArchived, archived },
      { pathParams: { captureId, jobId: id } }
    );
    dispatchEffect(EffectNames.REFRESH_CAPTURE_JOBS);

    if (userIsAdmin) {
      refetchAllCaptureJobs();
    } else {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
        ],
      });
    }
    setShowConfirmArchiveModal(false);
  };

  return (jobs?.length ?? 0) > 0 ? (
    <>
      <table className="text-xs w-full table-auto">
        <thead>
          <tr className="border-t border-b border-gray-300">
            <th className="p-1">Name</th>
            {!hideUUIDs && (
              <>
                <th className="p-1">Capture Job UUID</th>
                <th className="p-1">Created By UUID</th>
              </>
            )}
            {userIsAdmin && (
              <>
                <th className="p-1">Mosaic Engine</th>
                <th className="p-1">MLModel</th>
              </>
            )}
            <th className="p-1">Created</th>
            <th className="p-1">Completed</th>
            <th className="p-1">Type</th>
            <th className="p-1"></th>
            <th className="p-1"></th>
            <th className="p-1"></th>
            <th className="p-1"></th>
            <th className="p-1"></th>
          </tr>
        </thead>
        <tbody>
          {jobs?.map((job, i) => {
            return (
              <CapturePipelineJobTableRow
                key={job?.id}
                hideUUIDs={hideUUIDs}
                index={i}
                job={job}
                confirmArchiveJob={confirmArchiveJob}
                confirmUnarchiveJob={confirmUnarchiveJob}
                downloadEpt={downloadEpt}
                editNameChange={editNameChange}
                jobBeingEdited={jobBeingEdited}
                setJobBeingEdited={setJobBeingEdited}
                loadingPutCaptureJob={loadingPutCaptureJob}
                userIsAdmin={userIsAdmin}
              />
            );
          })}
        </tbody>
      </table>
      <ConfirmModal
        title="Archive Job"
        message="Are you sure you want to archive this job?"
        isOpen={showConfirmArchiveModal}
        canConfirm={true}
        confirm={{
          label: 'Archive',
          callback: async () => {
            await archiveJob(jobBeingEdited || NaN, true);
            setShowConfirmArchiveModal(false);
          },
        }}
        close={{
          label: 'Cancel',
          callback: () => {
            setShowConfirmArchiveModal(false);
          },
        }}
      />
      <ConfirmModal
        title="Restore Job"
        message="Are you sure you want to restore this job?"
        isOpen={showConfirmUnarchiveModal}
        canConfirm={true}
        confirm={{
          label: 'Restore',
          callback: async () => {
            await archiveJob(jobBeingEdited || NaN);
            setShowConfirmUnarchiveModal(false);
          },
        }}
        close={{
          label: 'Cancel',
          callback: () => {
            setShowConfirmUnarchiveModal(false);
          },
        }}
      />
    </>
  ) : (
    <span>No Capture Jobs Available</span>
  );
};

export const TriggerHighResJob = ({ id }: { id: number }) => {
  const { mutate: postCaptureHighResJob } = usePostCaptureHighResJob({
    id: NaN,
  });

  const toasts = useToasts();

  useEffect(() => {
    if (!id) return;
    const doAsync = async () => {
      try {
        mixpanel.track(MixpanelNames.ProcessingRequest, { type: 'HD' });
        await postCaptureHighResJob(undefined as void, {
          pathParams: { id: id },
        });
        toasts.add({
          title: 'High definition job started!',
          type: 'success',
        });
      } catch (e) {
        toasts.prepare.error('Could not start high definition job!');
      }
    };
    doAsync();
  }, [id]);

  return null;
};

export const getUsername = (user?: User) => {
  if (!user || !user.userProfiles?.[0]) {
    return '';
  }

  return `${user.userProfiles[0].firstName} ${user.userProfiles[0].lastName}`.trim();
};
