import {
  faArrowUpRightFromSquare,
  faCircleCheck,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'oidc-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { APIClient, Capture, CaptureJob, formatDate } from '@agerpoint/api';
import { PreviewMediaPlayer } from '@agerpoint/component';
import {
  CaptureJobTypes,
  IUseItemSelectionState,
  getFirstActiveEptId,
  isCaptureReadyToViewInLegacyLayout,
  isCaptureReadyToViewInNewLayout,
} from '@agerpoint/types';
import {
  APIUtils,
  convertAttributeValueToUIFriendly,
  getBestCaptureJob,
  getCapturesUnique3DModelList,
  isGaussianJob,
} from '@agerpoint/utilities';
import { useGlobalStore, useNewLayout, useToasts } from '@agerpoint/utilities';

import { CaptureThumbnail, getUsername } from '../captures-table';
import { Datatable, dataTableAgerStyle } from '../datatable/datatable';
import { ThreeDViewer } from '../three-d-wrapper';
import { useCaptureMapPopoverQueries } from './capture-map-popover-queries';

enum CaptureMapPopupTab {
  PREVIEW = 'Preview',
  INFO = 'Info',
  ATTRIBUTES = 'Attributes',
}

interface CapturesMapPopupProps {
  capture: Capture;
  selection?: IUseItemSelectionState<number, Capture>;
}

enum PreviewTypes {
  GAUSSIAN = 'Gaussian Video',
  MODEL = 'Model',
  EMERGENCE = 'Emergence Video',
  NO_CONTENT = 'No Content',
}

export const CapturesMapPopup = ({
  capture,
  selection,
}: CapturesMapPopupProps) => {
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { permissions } = useGlobalStore();

  const {
    usersLookupTable,
    organizationsLookupTable,
    analyticRequestsQuery,
    captureCustomAttributesByCompleteAnalyticRequestsQuery,
    captureObjectsByCompleteAnalyticRequestsQuery,
  } = useCaptureMapPopoverQueries({
    captureId: capture.id,
  });

  const toasts = useToasts();

  const [previewType, setPreviewType] = useState<PreviewTypes | null>(null);
  const [bestCaptureJob, setBestCaptureJob] = useState<CaptureJob | null>(null);

  useEffect(() => {
    const tryLowResOrEmergence = () => {
      const lowRes = capture?.completedJobs?.find(
        (job) => job.captureJobTypeId === CaptureJobTypes['Low Resolution']
      );
      if (lowRes && lowRes.eptPointcloudId) {
        setPreviewType(PreviewTypes.MODEL);
        setBestCaptureJob(lowRes);
        return;
      } else if (lowRes) {
        setPreviewType(PreviewTypes.EMERGENCE);
        return;
      }
      setPreviewType(PreviewTypes.NO_CONTENT);
    };

    const doAsync = async () => {
      const bestJob = await getBestCaptureJob({
        includeArchived: false,
        analyticRequests: analyticRequestsQuery.data ?? [],
      });
      if (bestJob) {
        setBestCaptureJob(bestJob);
        if (isGaussianJob(bestJob)) {
          setPreviewType(PreviewTypes.GAUSSIAN);
        } else if (bestJob.eptPointcloudId) {
          setPreviewType(PreviewTypes.MODEL);
        } else {
          setPreviewType(PreviewTypes.EMERGENCE);
        }
        return;
      }
      tryLowResOrEmergence();
    };

    if (analyticRequestsQuery.isLoading) {
      setPreviewType(null);
      return;
    }
    if (analyticRequestsQuery?.data?.length) {
      doAsync();
      return;
    }

    tryLowResOrEmergence();
  }, [capture, analyticRequestsQuery.data, analyticRequestsQuery.isLoading]);

  const captureJobWithVideoQuery =
    APIClient.useGeVideoPreviewDownloadUrlByCaptureJobId(
      bestCaptureJob?.id as number,
      {
        query: {
          queryKey: [
            APIUtils.QueryKey.captureJobs,
            {
              captureJobId: bestCaptureJob?.id,
            },
            APIUtils.QueryKey.captureVideos,
          ],
          enabled: previewType === PreviewTypes.GAUSSIAN,
        },
      }
    );

  const captureVideo = APIClient.useGetCaptureVideosByCaptureId(
    capture.id as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: capture.id },
          APIUtils.QueryKey.captureVideos,
        ],
        enabled: previewType === PreviewTypes.EMERGENCE,
        select: (data) => {
          const emergence = data.find(
            (v) =>
              v.archived === false &&
              v.captureExtractionJobId !== null &&
              v.validated === true
          );
          if (emergence) {
            return emergence;
          }
          return data.find((v) => v.archived === false && v.validated === true);
        },
      },
    }
  );

  const [tab, setTab] = useState<CaptureMapPopupTab>(
    CaptureMapPopupTab.PREVIEW
  );

  const infoTab = useMemo(() => {
    const org = organizationsLookupTable?.[capture.customerId ?? ''];
    const user = usersLookupTable?.[capture.createdById ?? ''];

    return (
      <div className="w-full h-full flex flex-col p-1">
        <div className="w-full rounded-lg overflow-hidden">
          <CaptureThumbnail
            size="big"
            capture={capture}
            ldFlags={permissions}
          />
        </div>
        <div className="font-normal text-xs text-gray-700 flex flex-col pt-1">
          <p className="flex justify-between">
            <span>
              {capture.scanDatetime ? formatDate(capture.scanDatetime) : null}
            </span>
            <span>{getCapturesUnique3DModelList(capture)?.join(', ')}</span>
          </p>
          <p className="flex justify-between">
            <span>{capture.fileSize ? `${capture.fileSize} MB` : null}</span>
            <span>
              {capture.numberImages ? `${capture.numberImages} images` : null}
            </span>
          </p>
          <p className="flex justify-between">
            <span>
              {org && (org.customerDisplayName ?? org?.customerName ?? null)}
            </span>
            <span>{user && getUsername(user)}</span>
          </p>
          {captureCustomAttributesByCompleteAnalyticRequestsQuery.data?.map(
            (item) => (
              <p className="flex justify-between" key={item.id}>
                <span>
                  {item?.attributeName ?? item?.attributeName ?? null}
                </span>
                <span>{convertAttributeValueToUIFriendly(item)}</span>
              </p>
            )
          )}
        </div>
      </div>
    );
  }, [
    capture,
    organizationsLookupTable,
    usersLookupTable,
    permissions,
    captureCustomAttributesByCompleteAnalyticRequestsQuery.data,
  ]);

  const attributesTab = useMemo(() => {
    return (
      <Datatable
        style={{
          ...dataTableAgerStyle,
          headerWrapperStyle:
            'px-1 text-xs text-gray-700 font-normal border-b border-gray-500',
          rowWrapperStyle: 'px-1 items-center text-xs hover:bg-gray-100',
          headerStyle: 'pr-1 py-1 h-full flex items-center',
        }}
        loading={captureObjectsByCompleteAnalyticRequestsQuery.isLoading}
        noResults={{
          message: 'No attributes available',
        }}
        columns={[
          {
            label: 'ID',
            value: (row) => row?.captureObject?.name,
          },
          {
            label: 'Attribute',
            value: (row) =>
              row?.attributeDisplayName ?? row?.attributeName ?? null,
            flex: 2,
          },
          {
            label: 'Value',
            value: (row) => convertAttributeValueToUIFriendly(row),
          },
        ]}
        data={
          captureObjectsByCompleteAnalyticRequestsQuery.data?.flatMap(
            (captureObject) => {
              return captureObject?.captureObjectCustomAttributes?.map(
                (item) => ({
                  ...item,
                  captureObject,
                })
              );
            }
          ) ?? []
        }
        rowHeight={20}
      />
    );
  }, [captureObjectsByCompleteAnalyticRequestsQuery]);

  const previewTab = useMemo(() => {
    const loading = (
      <div className="w-full h-full flex justify-center items-center">
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </div>
    );

    const noPreview = (
      <div className="w-full h-full flex justify-center items-center">
        <div className="text-xs text-gray-700">No preview available</div>
      </div>
    );

    if (previewType === null) {
      return loading;
    }

    if (previewType === PreviewTypes.NO_CONTENT) {
      return noPreview;
    }

    if (previewType === PreviewTypes.MODEL) {
      return (
        <div className="w-full h-full p-1">
          <div className="w-full h-full relative rounded-md overflow-hidden">
            <ThreeDViewer
              overrideCapture={capture}
              setViewer={() => {
                return null;
              }}
              token={userData?.access_token ?? ''}
              controlsAllowed={false}
              overrideCaptureJob={bestCaptureJob}
            />
          </div>
        </div>
      );
    }

    if (previewType === PreviewTypes.GAUSSIAN) {
      if (captureJobWithVideoQuery.isLoading) {
        return loading;
      }

      const url = captureJobWithVideoQuery.data?.videoPreviewDownloadUrl ?? '';

      if (url === '') {
        return noPreview;
      }

      return (
        <div className="w-full h-full p-1 relative">
          <div className="absolute inset-0 -z-10">{loading}</div>
          <PreviewMediaPlayer
            key={capture?.id}
            mp4URL={url}
            className="object-cover rounded-md"
            autoPlay
            loop
          />
        </div>
      );
    }

    if (previewType === PreviewTypes.EMERGENCE) {
      if (captureVideo.isLoading) {
        return loading;
      }

      const url = captureVideo.data?.downloadUrl ?? '';

      if (url === '') {
        return noPreview;
      }

      return (
        <div className="w-full h-full p-1 relative">
          <div className="absolute inset-0 -z-10">{loading}</div>
          <PreviewMediaPlayer
            key={capture?.id}
            mp4URL={url}
            className="object-cover rounded-md"
            autoPlay
            loop
          />
        </div>
      );
    }

    return noPreview;
  }, [
    capture,
    previewType,
    userData?.access_token,
    captureJobWithVideoQuery.data,
    captureJobWithVideoQuery.isLoading,
    captureVideo.data,
    captureVideo.isLoading,
  ]);

  const { showNewLayout } = useNewLayout();

  const canViewCapture = useMemo(
    () =>
      showNewLayout
        ? isCaptureReadyToViewInNewLayout(capture)
        : isCaptureReadyToViewInLegacyLayout(capture),
    [capture, showNewLayout]
  );

  return (
    <div style={{ width: '320px', height: '370px' }}>
      <div
        className="w-full bg-green text-white p-1 flex flex-row justify-between items-center"
        style={{ height: '30px' }}
      >
        {selection && (
          <div
            className="h-full flex justify-center items-center rounded hover:bg-gray-600 cursor-pointer"
            style={{ aspectRatio: '1/1' }}
            onClick={() => {
              if (capture.id) {
                selection.toggleSelection(capture.id, capture);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={`${
                capture.id && selection.isSelected(capture.id)
                  ? 'text-gray-900'
                  : 'text-gray-300'
              }`}
            />
          </div>
        )}
        <div className="truncate px-1 ">
          <div
            className={`underline ${
              canViewCapture ? 'cursor-pointer' : 'cursor-not-allowed'
            } truncate`}
            onClick={() => {
              if (!canViewCapture) {
                toasts.add({
                  title: 'Capture not ready.',
                  message:
                    'This capture is not ready or is missing a complete pointcloud.',
                  type: 'info',
                });
                return;
              }

              const url = showNewLayout
                ? `/captures/${capture.id}`
                : `/captures/${capture.id}/${getFirstActiveEptId(capture)}`;

              navigate(url);
            }}
          >
            {capture.captureName}
          </div>
        </div>
        <div
          className={`h-full flex justify-center items-center rounded ${
            canViewCapture
              ? 'cursor-pointer hover:bg-gray-600'
              : 'cursor-not-allowed'
          }  `}
          style={{ aspectRatio: '1/1' }}
          onClick={() => {
            if (!canViewCapture) {
              toasts.add({
                title: 'Capture not ready.',
                message:
                  'This capture is not ready or is missing a complete pointcloud.',
                type: 'info',
              });
              return;
            }

            const captureEptId = getFirstActiveEptId(capture);

            const url = captureEptId
              ? `/captures/${capture.id}/${captureEptId}`
              : `/captures/${capture.id}`;
            window.open(url, '_blank', 'noopener noreferrer');
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </div>
      </div>
      <div className="w-full flex flex-row text-xs" style={{ height: '20px' }}>
        <div
          className={`h-full w-1/3 flex items-center justify-center rounded-br ${
            CaptureMapPopupTab.PREVIEW === tab
              ? 'bg-white'
              : 'bg-gray-300 cursor-pointer hover:bg-gray-100 '
          }`}
          onClick={() => {
            if (CaptureMapPopupTab.PREVIEW !== tab) {
              setTab(CaptureMapPopupTab.PREVIEW);
            }
          }}
        >
          {CaptureMapPopupTab.PREVIEW}
        </div>

        <div
          className={`h-full w-1/3 flex items-center justify-center rounded-b ${
            CaptureMapPopupTab.INFO === tab
              ? 'bg-white'
              : 'bg-gray-300 cursor-pointer hover:bg-gray-100 '
          }`}
          onClick={() => {
            if (CaptureMapPopupTab.INFO !== tab) {
              setTab(CaptureMapPopupTab.INFO);
            }
          }}
        >
          {CaptureMapPopupTab.INFO}
        </div>
        <div
          className={`h-full w-1/3 flex items-center justify-center rounded-bl ${
            CaptureMapPopupTab.ATTRIBUTES === tab
              ? 'bg-white'
              : 'bg-gray-300 cursor-pointer hover:bg-gray-100 '
          }`}
          onClick={() => {
            if (CaptureMapPopupTab.ATTRIBUTES !== tab) {
              setTab(CaptureMapPopupTab.ATTRIBUTES);
            }
          }}
        >
          {CaptureMapPopupTab.ATTRIBUTES}
        </div>
      </div>
      <div className="w-full" style={{ height: '320px' }}>
        <div
          className={`w-full h-full overflow-auto ${
            tab === CaptureMapPopupTab.INFO ? '' : 'hidden'
          }`}
        >
          {infoTab}
        </div>
        <div
          className={`w-full h-full overflow-auto ${
            tab === CaptureMapPopupTab.ATTRIBUTES ? '' : 'hidden'
          }`}
        >
          {attributesTab}
        </div>
        <div
          className={`w-full h-full overflow-auto ${
            tab === CaptureMapPopupTab.PREVIEW ? '' : 'hidden'
          }`}
        >
          {previewTab}
        </div>
      </div>
    </div>
  );
};
