import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { APIUtils } from '..';

interface RecentAccessEntry {
  id: string;
  name: string;
  path: string;
  dataType: RecentAccessEntryDataType;
  createdAt: string;
}

interface NewRecentAccessEntry {
  id: string;
  name: string;
  path: string;
  dataType: RecentAccessEntryDataType;
}

type RecentAccessEntryDataType = 'capture';

export const useRecentAccess = () => {
  const recentAccessStorageKey = 'apcloud-recent-access';

  const queryClient = useQueryClient();

  const recentAccessQuery = useQuery<RecentAccessEntry[]>({
    queryKey: [APIUtils.QueryKey.recentAccess],
    queryFn: async () => {
      const localStorageValue = localStorage.getItem(recentAccessStorageKey);

      return localStorageValue ? JSON.parse(localStorageValue) : [];
    },
  });

  const recentAccessAddMutation = useMutation({
    mutationFn: async (newRecentAccessEntry: NewRecentAccessEntry) => {
      let recentAccess = [...(recentAccessQuery.data ?? [])];

      const exists = recentAccess.some(
        (entry) => entry.id === newRecentAccessEntry.id
      );

      if (exists) {
        recentAccess = recentAccess.map((entry) =>
          entry.id === newRecentAccessEntry.id
            ? {
                ...entry,
                createdAt: new Date().toISOString(),
              }
            : entry
        );
      } else {
        recentAccess = [
          {
            ...newRecentAccessEntry,
            createdAt: new Date().toISOString(),
          },
          ...recentAccess,
        ];

        if (recentAccess.length > 10) {
          // remove the oldest entry based on createdAt field
          const oldestRecentEntry = recentAccess.reduce((prev, current) =>
            prev.createdAt < current.createdAt ? prev : current
          );

          recentAccess = recentAccess.filter(
            (entry) => entry.id !== oldestRecentEntry.id
          );
        }
      }

      localStorage.setItem(
        recentAccessStorageKey,
        JSON.stringify(recentAccess)
      );
      return recentAccess;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.recentAccess],
      });
    },
  });

  const recentAccessRemoveMutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      let recentAccess = [...(recentAccessQuery.data ?? [])];

      recentAccess = recentAccess.filter((entry) => entry.id !== id);

      localStorage.setItem(
        recentAccessStorageKey,
        JSON.stringify(recentAccess)
      );

      return recentAccess;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.recentAccess],
      });
    },
  });

  return {
    data: recentAccessQuery.data,
    add: recentAccessAddMutation.mutate,
    remove: recentAccessRemoveMutation.mutate,
  };
};
