// IAnnotations3d.ts
import EventEmitter from 'events';
import {
  Group,
  Mesh,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Sprite,
  Vector3,
} from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';

import { HexColor } from './colors';
import { EventBusNames } from './event-bus';
import { ColorsThreeD } from './potree-types';
import { IMarkerObj, MarkerObjOptions } from './viewer';

export interface CaptureObjectGenericAttributes {
  name: string | null | undefined;
  description: string | null | undefined;
  color: ColorsThreeD | HexColor | undefined;
  id: string;
  type:
    | Annotation3dPolygons
    | Annotation3dLines
    | Annotation3dPoints
    | Annotation2dPoints;
}

export enum Annotation3dPoints {
  CaptureImageLocation = 'CaptureImageLocation',
  PendingLocationMarker = 'PendingLocationMarker',
  LocationMarker = 'LocationMarker',
  AnnotationPoint = 'AnnotationPoint',
  PendingMultiPointMarker = 'PendingMultiPointMarker',
  MultiPointMarker = 'MultiPointMarker',
  AnnotationLineVertex = 'AnnotationLineVertex',
  AnnotationLineVerticesGroup = 'AnnotationLineVerticesGroup',
  AnnotationLineMidpointGroup = 'AnnotationLineMidpointGroup',
  AnnotationLineMidpointVertex = 'AnnotationLineMidpointVertex',
  AnnotationPolygonVertex = 'AnnotationPolygonVertex',
  AnnotationPolygonVerticesGroup = 'AnnotationPolygonVerticesGroup',
  AnnotationPolygonMidpointGroup = 'AnnotationPolygonMidpointGroup',
  AnnotationPolygonMidpointVertex = 'AnnotationPolygonMidpointVertex',
  Reticle = 'Reticle',
}

export enum Annotation2dPoints {
  PendingLocationMarker2d = 'PendingLocationMarker2d',
  LocationMarker2d = 'LocationMarker2d',
  AnnotationPoint2d = 'AnnotationPoint2d',
  PendingMultiPointMarker2d = 'PendingMultiPointMarker2d',
  AnnotationMultiPoint2d = 'AnnotationMultiPoint2d',
}

export enum Annotation3dLines {
  CaptureLine = 'CaptureLine',
  PendingLine = 'PendingLine',
  LineMarker = 'LineMarker',
  PolygonMarker = 'PolygonMarker',
  CaptureObjectLine = 'CaptureObjectLine',
  CaptureObjectPolygon = 'CaptureObjectPolygon',
  AnnotationLine = 'AnnotationLine',
}

export enum Annotation3dPolygons {
  PendingPolygon = 'PendingPolygon',
  AnnotationPolygon = 'AnnotationPolygon',
}

export interface ICustomMesh extends Mesh {
  customType?: Annotation3dPoints;
  updatePosition: (pos: Vector3) => void;
  uniqueId: string;
  callback: (id: string) => void;
}

export interface CustomSprite extends Sprite {
  customType?: Annotation3dPoints;
  updatePosition: (pos: Vector3) => void;
  uniqueId: string;
}

export interface CustomGroup<T extends Object3D> extends Group {
  customType?: Annotation3dPoints;
  updatePosition: (pos: Vector3) => void;
  uniqueId: string;
  callback: (id: string) => void;
  children: T[];
}

export interface ICustomLine extends Line2 {
  customType?: Annotation3dLines | Annotation3dPolygons;
  updatePosition: (pos: Vector3[]) => void;
  uniqueId: string;
}

export interface IAnnotationsSelected {
  id: string;
  type:
    | Annotation3dPoints
    | Annotation3dLines
    | Annotation3dPolygons
    | Annotation2dPoints
    | undefined;
}

export interface IAnnotations3d {
  add3dPoint(
    genAttr: CaptureObjectGenericAttributes,
    position: Vector3,
    type: Annotation3dPoints,
    visible?: boolean
  ): IPoint3d;

  add3dLine(
    genAttr: CaptureObjectGenericAttributes,
    points: Vector3[],
    type: Annotation3dLines
  ): ILine3d; // Specify the correct return type based on your implementation

  add3dSprite(
    genAttr: CaptureObjectGenericAttributes,
    position: Vector3,
    type: Annotation3dPoints
  ): IPoint3d; // Specify the correct return type based on your implementation

  remove3dSpriteById(id: string): void;

  add3dPolygon(
    genAttr: CaptureObjectGenericAttributes,
    points: Vector3[],
    type: Annotation3dPolygons
  ): ILine3d; // Specify the correct return type based on your implementation

  remove3dPointById(id: string): void;

  remove3dPointsByType(type: Annotation3dPoints): void;

  get3dPointById(id: string): IPoint3d; // Specify the correct return type based on your implementation

  get3dLinePositionById(id: string): Vector3[] | undefined;

  highlight3dPointById(id: string, color: ColorsThreeD | HexColor): void;

  hide3dPointsByType(type: Annotation3dPoints): void;

  show3dPointsByType(type: Annotation3dPoints): void;

  getNew3dPointId(): string;

  getNew3dLineId(): string;

  getNew3dPolygonId(): string;

  update3dLinePosition(id: string, points: Vector3[]): void;

  update3dPolygonPosition(id: string, points: Vector3[]): void;

  finishCreating3dLine(id: string): void;

  clear3dPoints(): void;
  clear3dPointsByType(type: Annotation3dPoints): void;

  clear3dLines(): void;

  clear3dPolygons(): void;

  savePoint(id: string): void;

  saveLine(id: string): void;

  savePolygon(id: string): void;

  destroyGeometry(): void;

  destroy(): void;

  showAll3dAnnotations(): void;
  hideAll3dAnnotations(): void;
}

export interface IAnnotations2d {
  add2dPoint(
    eventName: EventBusNames,
    pointId: string,
    position: Vector3,
    options: MarkerObjOptions
  ): void;
  get2dPoints(): { [key: string]: IPoint2d };
  get2dPointById(id: string): IPoint2d | undefined;
  remove2dPoints(): void;
  remove2dPointById(id: string): void;
  render(): void;
  destroy(): void;
  unHighlightAll(): void;
  highlight2dPointById(id: string): void;
  savePoint(id: string): void;
}

export interface IGeometryBase extends IAnnotationBase {
  name?: string | null | undefined;
  doZoom(object: Object3D): void;
  dispose(): void;
  delete(): void;
  disposeAndDelete(): void;
  userData: {
    originalColor: ColorsThreeD | HexColor;
    originalScale?: Vector3;
    originalLineWidth?: number;
  };
  highlight(color?: ColorsThreeD | HexColor): void;
  unHighlight(): void;
  highlightColor: ColorsThreeD | HexColor;
  updateColor(color: ColorsThreeD | HexColor, persist?: boolean): void;
  updateName(name: string): void;
  updateDescription(id: string, description: string): void;
  isVisible: boolean;
  isHighlighted: boolean;
  visibility: boolean;
  updateVisibility(isVisible: boolean): void;
}

export interface IPoint3d extends IGeometryBase {
  readonly object: ICustomMesh | CustomSprite | CustomGroup<ICustomMesh>;
  readonly uniqueId: string;
  type: Annotation3dPoints;
  hide(): void;
  show(): void;
  updatePosition(pos: Vector3): void;
  updateType(type: Annotation3dPoints): void;
  zoomTo(): void;
  updateLookAt(): void;
}

export interface ILine3d extends IGeometryBase {
  readonly uniqueId: string;
  lineObject: ICustomLine;
  type: Annotation3dLines | Annotation3dPolygons;
  hide(): void;
  show(): void;
  updatePosition(points: Vector3[]): void;
  updateType(type: Annotation3dLines | Annotation3dPolygons): void;
  getPosition(): Vector3[];
  calculateArea(): number;
  calculateLength(): number;
  zoomTo(): void;
  highlight(): void;
  unHighlight(): void;
  render(): void;
}

export interface IMultiPoint3d extends IGeometryBase {
  points: IPoint3d[];
  type: Annotation3dPoints;
  readonly uniqueId: string;
  updatePositions(points: Vector3[]): void;
  updateType(type: Annotation3dPoints): void;
  updateColor(color: ColorsThreeD | HexColor): void;
  zoomTo(): void;
  show(): void;
  hide(): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunction = (...args: any[]) => any;

export interface IAnnotationBase extends EventEmitter {
  scene: Scene;
  perspectiveCamera: PerspectiveCamera;
  canvas?: HTMLCanvasElement;
  isPotree: boolean;
  orthographicCamera: OrthographicCamera;
  color: ColorsThreeD | HexColor;

  destroy(): void;
  // notifyListeners(): void;
  unHighlightEverything(): void;
}

export interface IAnnotations3dGeometry {
  points: IPoint3d[];
  multiPoints: IMultiPoint3d[];
  lines: ILine3d[];
  polygons: ILine3d[];
  selected: {
    id: string;
    type:
      | Annotation3dPoints
      | Annotation3dLines
      | Annotation3dPolygons
      | Annotation2dPoints
      | undefined;
  } | null;
}

export interface IAnnotations2dGeometry {
  points: IPoint2d[];
  multiPoints: IMultiPoint2d[];
  selected: {
    id: string;
    type:
      | Annotation3dPoints
      | Annotation3dLines
      | Annotation3dPolygons
      | Annotation2dPoints
      | undefined;
  } | null;
}

export interface IPoint2d extends IGeometryBase {
  point: Vector3;
  readonly uniqueId: string;
  type: Annotation2dPoints;
  hide(): void;
  show(): void;
  updatePosition(pos: Vector3): void;
  updateType(type: Annotation2dPoints): void;
  zoomTo(): void;
  marker: IMarkerObj;
  dispose(): void;
  render(): void;
  updateColor(color: ColorsThreeD | HexColor | string): void;
}

export interface IMultiPoint2d extends IGeometryBase {
  points: IPoint2d[];
  type: Annotation2dPoints;
  readonly uniqueId: string;
  addPoints(points: Vector3[]): void;
  addNewPoint(point: Vector3): void;
  updatePositions(points: Vector3[]): void;
  updatePositionByIndex(index: number, position: Vector3): void;
  updateType(type: Annotation2dPoints): void;
  updateColor(color: ColorsThreeD | HexColor): void;
  zoomTo(): void;
  show(): void;
  hide(): void;
  render(): void;
  getChildById(id: string): IPoint2d | undefined;
  removeLastPoint(): void;
}

export interface AnnotationGeometry {
  points: { [key: string]: IPoint3d };
  lines: { [key: string]: ILine3d };
  polygons: { [key: string]: ILine3d };
  multiPoints: { [key: string]: IMultiPoint3d };
}

export interface IAnnotationDrawing {
  startCreating2dPoint(): void;
  startEditing2dPoint(uniqueId: string): void;
  startMoving2dPoint(object: ICustomMesh): void;
  finishCreating2dPoint(cancelled: boolean): void;
  finishEditing2dPoint(): void;
  cancelCreating2dPoint(): void;

  startCreating3dMultiPoint(): void;
  finishCreating3dMultiPoint(): void;
  cancelCreating3dMultiPoint(): void;

  startCreating3dLine(): void;
  startEditing3dLine(uniqueId: string): void;
  startEditing3dLineVertex(point: ICustomMesh): void;
  finishCreating3dLine(): void;
  finishEditing3dLineVertex(): void;
  finishEditing3dLine(): void;
  cancelCreating3dLine(): void;

  startCreating3dPolygon(): void;
  finishCreating3dPolygon(): void;
  cancelCreating3dPolygon(): void;
}

export enum SVG_PATH_NAMES {
  LOCATION_MARKER = 'LOCATION_MARKER',
  CIRCLE_MARKER = 'CIRCLE_MARKER',
}

export enum SVG_PATH_LOOKUP {
  LOCATION_MARKER = `M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z`,
  CIRCLE_MARKER = `M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z`,
}

export enum SVG_PATH_SIZE_LOOKUP {
  LOCATION_MARKER = 32,
  CIRCLE_MARKER = 18,
}

export enum SVG_PATH_TOP {
  LOCATION_MARKER = 32,
  CIRCLE_MARKER = 8,
}
