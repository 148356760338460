import { faDisplay } from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowsToCircle,
  faChevronDown,
  faChevronUp,
  faFloppyDisk,
} from '@fortawesome/pro-light-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { APIClient } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  BackgroundOptionsValues,
  IGs3dViewerController,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import {
  APIUtils,
  hasPermission,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

export const OldGs3dTools = ({
  viewerController,
}: {
  viewerController?: IGs3dViewerController;
}) => {
  const {
    actions: { sendEvent },
    permissions,
  } = useGlobalStore();
  const [expanded, setExpanded] = useState(false);
  const [hasMeasurement3DPermission, setHasMeasurement3DPermission] =
    useState(false);

  const queryClient = useQueryClient();
  const toasts = useToasts();

  useEffect(() => {
    const hasPerm = hasPermission(LdFlags.Measurements3D, permissions);
    setHasMeasurement3DPermission(hasPerm);
  }, [permissions]);

  useEffect(() => {
    if (
      !viewerController?.info.captureJobMetadata?.cameraSettings ||
      !viewerController?.info.viewerReady
    )
      return;
    viewerController?.setCameraSettings?.(
      viewerController?.info.captureJobMetadata?.cameraSettings
    );
  }, [
    viewerController?.info.captureJobMetadata?.cameraSettings,
    viewerController?.info.viewerReady,
  ]);

  const resetSavedView = useCallback(() => {
    if (!viewerController) return;
    if (!viewerController?.info.captureJobMetadata?.cameraSettings) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Reset View',
      type: '',
    });

    viewerController?.setCameraSettings?.(
      viewerController?.info.captureJobMetadata?.cameraSettings
    );
  }, [viewerController, sendEvent]);

  const saveView = useCallback(() => {
    if (
      !viewerController?.info.viewerReady ||
      !viewerController?.info.captureJobMetadata?.captureId
    ) {
      return;
    }

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Save View',
      type: '',
    });
    const cameraState = viewerController?.getCameraSettings?.();
    if (!cameraState) return;

    const cameraStateJSON = JSON.stringify(cameraState);

    const data = {
      ...viewerController?.info.captureJobMetadata,
      cameraSettings: cameraStateJSON,
    };

    delete data.plyDownloadUrl;

    APIClient.putCaptureJobById(
      viewerController?.info.captureJobMetadata?.captureId.toString(),
      Number(viewerController?.info.captureJobMetadata?.id),
      data
    )
      .then(() => {
        toasts.add(toasts.prepare.entityUpdated('capture job', 'View saved.'));

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            {
              captureId: Number(
                viewerController?.info.captureJobMetadata?.captureId
              ),
            },
          ],
        });

        viewerController?.setCaptureJobMetadata?.({
          ...viewerController?.info.captureJobMetadata,
          cameraSettings: cameraStateJSON,
        });
      })
      .catch(() => {
        toasts.add(toasts.prepare.error('Failed to save view!'));
      });
  }, [
    viewerController?.info?.captureJobMetadata,
    viewerController?.info?.viewerReady,
    queryClient,
    sendEvent,
    toasts,
  ]);

  return (
    <div className="absolute top-0 bottom-0 right-0 z-30 m-0 flex text-black pointer-events-none">
      <div className="flex flex-col justify-center w-8 mr-4">
        <div className="bg-white flex flex-col items-center rounded py-2 pointer-events-auto relative gap-2">
          <div className="relative" onMouseDown={(e) => e.stopPropagation()}>
            <Button.Icon
              id="save-camera-view"
              icon={faFloppyDisk}
              onClick={saveView}
              title="Save View"
            />
            <Button.Icon
              id="reset-camera-view"
              icon={faArrowsToCircle}
              onClick={resetSavedView}
              title="Saved View"
            />
            <Button.Icon
              id="viewer-settings"
              icon={expanded ? faChevronUp : faChevronDown}
              onClick={() => setExpanded(!expanded)}
              title="Viewer Settings"
            />
            {expanded && (
              <>
                <Button.Icon
                  id="black-background"
                  icon={faDisplay}
                  iconColor="black-display"
                  onClick={() => {
                    if (viewerController?.info?.viewerReady) {
                      viewerController.setBackground(
                        BackgroundOptionsValues.Black
                      );
                    }
                  }}
                  title="Black Background"
                />
                <Button.Icon
                  id="gradient-background"
                  icon={faDisplay}
                  iconColor="grey-display"
                  onClick={() => {
                    if (viewerController?.info?.viewerReady) {
                      viewerController.setBackground(
                        BackgroundOptionsValues.Gradient
                      );
                    }
                  }}
                  title="Gradient Background"
                />
                <Button.Icon
                  id="white-background"
                  icon={faDisplay}
                  iconColor="white-display"
                  onClick={() => {
                    if (viewerController?.info?.viewerReady) {
                      viewerController.setBackground(
                        BackgroundOptionsValues.White
                      );
                    }
                  }}
                  title="White Background"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
