import TWEEN from '@tweenjs/tween.js';
import { frame } from 'framer-motion';
import { BehaviorSubject, filter, startWith, tap } from 'rxjs';
import {
  BackSide,
  BoxGeometry,
  Group,
  Line3,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshNormalMaterial,
  Object3D,
  Object3DEventMap,
  PerspectiveCamera,
  Plane,
  PlaneGeometry,
  Raycaster,
  Scene,
  SphereGeometry,
  TextureLoader,
  TorusGeometry,
  Vector2,
  Vector3,
  Vector4,
  WebGLRenderer,
} from 'three';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

import {
  FrameEvent,
  ICroppingTool,
  ICurrentHandle,
  IPickSphereMesh,
  PotreeViewer,
} from '@agerpoint/types';
import { Potree, getLinearPositions } from '@agerpoint/utilities';

const sceneConstants = {
  lineWidth: {
    potree: 0.02,
    gs: 0.1,
  },
  axisWidth: {
    potree: 0.1,
    gs: 0.05,
  },
  sphereScale: {
    potree: 0.3,
    gs: 0.4,
  },
  outlineScale: {
    potree: 0.3,
    gs: 0.6,
  },
  pickScale: {
    potree: 1,
    gs: 1,
  },
};

abstract class TransformationTool {
  protected scene: Scene;
  protected camera: PerspectiveCamera;
  protected renderer: WebGLRenderer;
  protected controls: OrbitControls;
  protected isPotree: boolean;
  protected viewer: PotreeViewer | null;

  protected _frameEvent = new BehaviorSubject<FrameEvent | null>(null);
  constructor(
    scene: Scene,
    camera: PerspectiveCamera,
    renderer: WebGLRenderer,
    controls: OrbitControls,
    viewer: PotreeViewer | null,
    isPotree = false
  ) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.controls = controls;
    this.viewer = viewer;
    this.isPotree = isPotree;
  }
}
// for visual reference
/**
        4 ---------- 3
       /|           /|
      / |          / |
     8 ---------- 7  |   <--- Corner 8 is at the front-bottom-left
     |  |         |  |
     |  1 --------|--2   <--- Corner 2 is at the back-bottom-right
     | /          | /
     |/           |/
     5 ---------- 6
*/

const CroppingToolGroup = 'CroppingToolGroup';
export class CroppingTool extends TransformationTool implements ICroppingTool {
  selection: Group[];
  pivot: Vector3;
  dragging: boolean;
  showPickVolumes: boolean;
  activeHandle: any;
  scaleHandles: Record<string, any> = {};
  focusHandles: Record<string, any> = {};
  translationHandles: Record<string, any> = {};
  rotationHandles: Record<string, any> = {};
  handles: Record<string, any> = {};
  pickVolumes: Object3D[] = [];
  // frame: FrameEvent;
  group: Group;
  mouse: Vector2 = new Vector2();
  previousMouse: Vector2 = new Vector2();
  framePositions: number[];
  frameMin: Vector3;
  frameMax: Vector3;
  isDragging = false;
  dragStartPosition = new Vector2();
  currentHandle = {
    object: null,
    alignment: null,
    parent: null,
  } as ICurrentHandle | null;
  origin: Vector3 = new Vector3();
  upVector: Vector3 = new Vector3();
  debugCorners: any = [];
  _croppingIsActive = false;
  boxLimits = {
    min: new Vector3(-Infinity, -Infinity, -Infinity),
    max: new Vector3(Infinity, Infinity, Infinity),
  };

  private _boundOnMouseDown: (e: MouseEvent) => void;
  private _boundOnMouseMove: (e: MouseEvent) => void;
  private _boundOnMouseUp: (e: MouseEvent) => void;
  private frameName = 'CropFrameEdgesFrame';

  // 1. create an instance
  // 2. register a callback
  constructor(
    scene: Scene,
    camera: PerspectiveCamera,
    renderer: WebGLRenderer,
    controls: OrbitControls,
    viewer: PotreeViewer | null,
    isPotree = false
  ) {
    super(scene, camera, renderer, controls, viewer, isPotree);
    this.frameMin = new Vector3();
    this.frameMax = new Vector3();
    this.selection = [];
    this.pivot = new Vector3();
    this.dragging = false;
    this.showPickVolumes = false;

    this.framePositions = [];
    // set up a special group for everything related to the cropping tool
    // save it by a special name and check if it already exists first
    let group = this.scene.getObjectByName(
      CroppingToolGroup
    ) as Group<Object3DEventMap>;
    if (group) {
      this.scene.remove(group);
    }
    group = new Group();
    group.name = CroppingToolGroup;
    this.group = group;
    this.scene.add(group);

    // Bind the event listeners once and store them
    this._boundOnMouseDown = this.onMouseDown.bind(this);
    this._boundOnMouseMove = this.onMouseMove.bind(this);
    this._boundOnMouseUp = this.onMouseUp.bind(this);
  }

  get frame(): FrameEvent {
    return {
      frame: {
        min: this.frameMin,
        max: this.frameMax,
      },
    };
  }

  get frameEventStream() {
    return this._frameEvent.asObservable().pipe(
      startWith({
        frame: {
          min: new Vector3().copy(this.frameMin),
          max: new Vector3().copy(this.frameMax),
        },
      }),
      // Emit the initial value first
      filter((frameEvent, index) => {
        // Bypass filter for the first emission (the initial value)
        return index === 0 || this._croppingIsActive;
      })
    );
  }

  set setCroppingIsActive(value: boolean) {
    this._croppingIsActive = value;
  }

  get croppingIsActive() {
    return this._croppingIsActive;
  }

  // 3. setup the initial state
  init(
    pos: Vector3,
    initBbox: [Vector3, Vector3],
    upVector: Vector3,
    _boxLimit: [Vector3, Vector3]
  ) {
    this.origin = pos;
    this.upVector = upVector;
    this.group.visible = false;
    const normalizedCorners = this.getCorners8and2(initBbox[0], initBbox[1]);
    this.frameMin.copy(normalizedCorners.corner2);
    this.frameMax.copy(normalizedCorners.corner8);
    this.boxLimits.min.copy(_boxLimit[0]);
    this.boxLimits.max.copy(_boxLimit[1]);

    this.initializeHandles();
    this._frameEvent.next({
      frame: {
        min: normalizedCorners.corner2,
        max: normalizedCorners.corner8,
      },
    });
  }

  hideEditTool() {
    this.group.visible = false;
    this.removeCorners();
    this.removeListeners();
    this.removeFrame();
  }

  showEditTool() {
    const corners = this.getBoundingBoxCorners(this.frameMin, this.frameMax);

    this.updateCubeEdges(corners);
    this.visualizeCorners();
    this.initializeListeners();
    this.initializeScaleHandles();
    this.adjustTranslationAxis(this.frameMin, this.frameMax);
    this.group.visible = true;
  }

  resetEditTool(existingCropBox?: [Vector3, Vector3]) {
    this.hideEditTool();
    const min = new Vector3().copy(existingCropBox?.[0] || this.boxLimits.min);
    const max = new Vector3().copy(existingCropBox?.[1] || this.boxLimits.max);
    const normalizedCorners = this.getCorners8and2(min, max);
    this.frameMin.copy(normalizedCorners.corner2);
    this.frameMax.copy(normalizedCorners.corner8);
    this._frameEvent.next({
      frame: {
        min: new Vector3().copy(this.frameMin),
        max: new Vector3().copy(this.frameMax),
      },
    });
  }

  destroyCroppingTool() {
    this.destroy();
  }

  setExistingCropBox(origin: Vector3, cropBox: [Vector3, Vector3]) {
    const normalizedCorners = this.getCorners8and2(cropBox[0], cropBox[1]);
    this.frameMin.copy(normalizedCorners.corner2);
    this.frameMax.copy(normalizedCorners.corner8);
    this._frameEvent.next({
      frame: {
        min: this.frameMin,
        max: this.frameMax,
      },
    });
  }

  private initializeListeners() {
    this.renderer.domElement.addEventListener(
      'mousedown',
      this._boundOnMouseDown
    );
    this.renderer.domElement.addEventListener(
      'mousemove',
      this._boundOnMouseMove
    );
    this.renderer.domElement.addEventListener('mouseup', this._boundOnMouseUp);
  }

  private initializeHandles() {
    let red = 0xe73100;
    let green = 0x44a24a;
    let blue = 0x2669e7;
    let neonRed = 0xff05c5; // Neon Magenta
    let neonGreen = 0xc5ff05; // Neon Green (more like a bright cyan-green)
    let neonBlue = 0x05c5ff; // Neon Cyan
    red = neonRed;
    green = neonGreen;
    blue = neonBlue;

    this.activeHandle = null;
    this.scaleHandles = {
      'scale.x+': {
        name: 'scale.x+',
        node: new Object3D(),
        color: red,
        alignment: [+1, +0, +0],
        axis: new Vector3(1, 0, 0),
        axisName: 'x+',
        axisDirection: 1,
      },
      'scale.x-': {
        name: 'scale.x-',
        node: new Object3D(),
        color: red,
        alignment: [-1, +0, +0],
        axis: new Vector3(1, 0, 0),
        axisName: 'x-',
        axisDirection: -1,
      },
      'scale.y+': {
        name: 'scale.y+',
        node: new Object3D(),
        color: green,
        alignment: [+0, +1, +0],
        axis: new Vector3(0, 1, 0),
        axisName: 'y+',
        axisDirection: 1,
      },
      'scale.y-': {
        name: 'scale.y-',
        node: new Object3D(),
        color: green,
        alignment: [+0, -1, +0],
        axis: new Vector3(0, 1, 0),
        axisName: 'y-',
        axisDirection: -1,
      },
      'scale.z+': {
        name: 'scale.z+',
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, +1],
        axis: new Vector3(0, 0, 1),
        axisName: 'z+',
        axisDirection: 1,
      },
      'scale.z-': {
        name: 'scale.z-',
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, -1],
        axis: new Vector3(0, 0, 1),
        axisName: 'z-',
        axisDirection: -1,
      },
    };
    this.focusHandles = {
      'focus.x+': {
        name: 'focus.x+',
        node: new Object3D(),
        color: red,
        alignment: [+1, +0, +0],
      },
      'focus.x-': {
        name: 'focus.x-',
        node: new Object3D(),
        color: red,
        alignment: [-1, +0, +0],
      },
      'focus.y+': {
        name: 'focus.y+',
        node: new Object3D(),
        color: green,
        alignment: [+0, +1, +0],
      },
      'focus.y-': {
        name: 'focus.y-',
        node: new Object3D(),
        color: green,
        alignment: [+0, -1, +0],
      },
      'focus.z+': {
        name: 'focus.z+',
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, +1],
      },
      'focus.z-': {
        name: 'focus.z-',
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, -1],
      },
    };
    this.translationHandles = {
      'translation.x': {
        name: 'translation.x',
        node: new Object3D(),
        color: red,
        alignment: [1, 0, 0],
      },
      'translation.y': {
        name: 'translation.y',
        node: new Object3D(),
        color: green,
        alignment: [0, 1, 0],
      },
      'translation.z': {
        name: 'translation.z',
        node: new Object3D(),
        color: blue,
        alignment: [0, 0, 1],
      },
    };
    this.rotationHandles = {
      'rotation.x': {
        name: 'rotation.x',
        node: new Object3D(),
        color: red,
        alignment: [1, 0, 0],
      },
      'rotation.y': {
        name: 'rotation.y',
        node: new Object3D(),
        color: green,
        alignment: [0, 1, 0],
      },
      'rotation.z': {
        name: 'rotation.z',
        node: new Object3D(),
        color: blue,
        alignment: [0, 0, 1],
      },
    };
    this.handles = Object.assign(
      {},
      this.scaleHandles,
      this.focusHandles,
      this.translationHandles,
      this.rotationHandles
    );
    this.pickVolumes = [];

    this.initializeScaleHandles();
    // this.initializeFocusHandles();
    this.initializeTranslationHandles();
    // this.initializeRotationHandles();
  }

  private initializeScaleHandles() {
    let sgSphere = new SphereGeometry(1, 32, 32);
    let sgLowPolySphere = new SphereGeometry(1, 16, 16);

    const faceCenters = this.getFaceCenters(this.frameMin, this.frameMax);
    for (let handleName of Object.keys(this.scaleHandles)) {
      let handle = this.scaleHandles[handleName];
      let node = handle.node;
      this.group.add(node);
      // based on the node's alignment, and direction, get the position from the frame min and max
      // position is the center of the face of the cube
      const position = faceCenters[handle.axisName];

      node.position.set(position.x, position.y, position.z);

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0.4,
        transparent: true,
      });

      let outlineMaterial = new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
        opacity: 0.4,
        transparent: false,
      });

      let pickMaterial = new MeshNormalMaterial({
        opacity: 0,
        transparent: true,
        visible: this.showPickVolumes,
      });

      let sphere = new Mesh(sgSphere, material);
      const sphereScale = this.isPotree
        ? sceneConstants.sphereScale.potree
        : sceneConstants.sphereScale.gs;
      sphere.scale.set(sphereScale, sphereScale, sphereScale);

      sphere.name = `${handleName}.handle`;
      node.add(sphere);

      // let outline = new Mesh(sgSphere, outlineMaterial);
      // const outlineScale = this.isPotree
      //   ? sceneConstants.outlineScale.potree
      //   : sceneConstants.outlineScale.gs;
      // outline.scale.set(outlineScale, outlineScale, outlineScale);
      // outline.name = `${handleName}.outline`;
      // sphere.add(outline);

      let pickSphere = new PickSphereMesh(
        sgLowPolySphere,
        pickMaterial
      ) as PickSphereMesh;
      pickSphere.name = `${handleName}.pick_volume`;
      const pickSphereScale = this.isPotree
        ? sceneConstants.pickScale.potree
        : sceneConstants.pickScale.gs;
      pickSphere.scale.set(pickSphereScale, pickSphereScale, pickSphereScale);
      sphere.add(pickSphere);
      pickSphere.handle = handleName;
      this.pickVolumes.push(pickSphere);

      node.setOpacity = (target: number) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          sphere.visible = opacity.x > 0;
          pickSphere.visible = opacity.x > 0;
          material.opacity = opacity.x;
          outlineMaterial.opacity = opacity.x;
          // @ts-ignore
          pickSphere.material.opacity = opacity.x * 0.5;
        });
        t.start();
      };

      // pickSphere.addEventListener('drag', (e) => this.dragScaleHandle(e));
      // pickSphere.addEventListener('drop', (e) => this.dropScaleHandle(e));

      // pickSphere.addEventListener('mouseover', (e) => {
      //   //node.setOpacity(1);
      // });

      // pickSphere.addEventListener('click', (e: unknown) => {
      //   e.consume();
      // });

      // pickSphere.addEventListener('mouseleave', (e) => {
      //   //node.setOpacity(0.4);
      // });
    }
  }

  private initializeFocusHandles() {
    //let sgBox = new BoxGeometry(1, 1, 1);
    let sgPlane = new PlaneGeometry(4, 4, 1, 1);
    let sgLowPolySphere = new SphereGeometry(1, 16, 16);

    let texture = new TextureLoader().load(
      `${Potree.resourcePath}/icons/eye_2.png`
    );

    for (let handleName of Object.keys(this.focusHandles)) {
      let handle = this.focusHandles[handleName];
      let node = handle.node;
      this.group.add(node);
      let align = handle.alignment;

      //node.lookAt(new Vector3().addVectors(node.position, new Vector3(...align)));
      node.lookAt(new Vector3(...align));

      let off = 0.8;
      if (align[0] === 1) {
        node.position.set(1, off, -off).multiplyScalar(0.5);
        node.rotation.z = Math.PI / 2;
      } else if (align[0] === -1) {
        node.position.set(-1, -off, -off).multiplyScalar(0.5);
        node.rotation.z = Math.PI / 2;
      } else if (align[1] === 1) {
        node.position.set(-off, 1, -off).multiplyScalar(0.5);
        node.rotation.set(Math.PI / 2, Math.PI, 0.0);
      } else if (align[1] === -1) {
        node.position.set(off, -1, -off).multiplyScalar(0.5);
        node.rotation.set(Math.PI / 2, 0.0, 0.0);
      } else if (align[2] === 1) {
        node.position.set(off, off, 1).multiplyScalar(0.5);
      } else if (align[2] === -1) {
        node.position.set(-off, off, -1).multiplyScalar(0.5);
      }

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0,
        transparent: true,
        map: texture,
      });

      let pickMaterial = new MeshNormalMaterial({
        transparent: true,
        visible: this.showPickVolumes,
      });

      let box = new Mesh(sgPlane, material);
      box.name = `${handleName}.handle`;
      box.scale.set(1.5, 1.5, 1.5);
      box.position.set(0, 0, 0);
      box.visible = false;
      node.add(box);

      let pickSphere = new PickSphereMesh(sgLowPolySphere, pickMaterial);
      pickSphere.name = `${handleName}.pick_volume`;
      pickSphere.scale.set(3, 3, 3);
      box.add(pickSphere);
      pickSphere.handle = handleName;
      this.pickVolumes.push(pickSphere);

      node.setOpacity = (target: number) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          pickSphere.visible = opacity.x > 0;
          box.visible = opacity.x > 0;
          material.opacity = opacity.x;
          if (Array.isArray(pickSphere.material)) return;
          pickSphere.material.opacity = opacity.x * 0.5;
        });
        t.start();
      };

      // pickSphere.addEventListener('click', (e) => {
      //   let selected = this.selection[0];
      //   let maxScale = Math.max(...selected.scale.toArray());
      //   let minScale = Math.min(...selected.scale.toArray());
      //   let handleLength = Math.abs(
      //     selected.scale.dot(new Vector3(...handle.alignment))
      //   );
      //   let alignment = new Vector3(...handle.alignment).multiplyScalar(
      //     (2 * maxScale) / handleLength
      //   );
      //   alignment.applyMatrix4(selected.matrixWorld);
      //   let newCamPos = alignment;
      //   let newCamTarget = selected.getWorldPosition(new Vector3());

      //   Potree.Utils.moveTo(this.viewer, newCamPos, newCamTarget, 500);
      // });

      // pickSphere.addEventListener('mouseover', (e) => {
      //   //box.setOpacity(1);
      // });

      // pickSphere.addEventListener('mouseleave', (e) => {
      //   //box.setOpacity(0.4);
      // });
    }
  }

  private initializeTranslationHandles() {
    let boxGeometry = new BoxGeometry(1, 1, 1);

    // Assuming you want to use 'potree' or 'gs' depending on the scene you're working on
    const currentScene = this.isPotree ? 'potree' : 'gs'; // or 'gs', depending on the scene context

    for (let handleName of Object.keys(this.translationHandles)) {
      let handle = this.handles[handleName];
      let node = handle.node;
      this.group.add(node);

      // Line2 requires LineGeometry and LineMaterial
      let lineGeometry = new LineGeometry();
      lineGeometry.setPositions([0, 0, -40, 0, 0, 40]); // Example positions for the line
      let lineMaterial = new LineMaterial({
        color: handle.color,
        transparent: true,
        opacity: 0.4,
        dashed: false,
        dashSize: 5,
        gapSize: 2,
        linewidth: 4,
        resolution: new Vector2(1000, 1000),
        polygonOffset: true,
        polygonOffsetFactor: this.isPotree ? -100 : -1, // Opposite direction to the border
        polygonOffsetUnits: this.isPotree ? -100 : -1,
      });

      let line = new Line2(lineGeometry, lineMaterial);
      line.name = `${handleName}.handle`;
      line.computeLineDistances(); // Needed for proper rendering
      line.scale.set(1, 1, 1);
      line.lookAt(new Vector3(...handle.alignment));
      line.renderOrder = 10;
      node.add(line);
      handle.translateNode = line;

      // Outline and pick volumes (spheres) remain Mesh
      let outlineMaterial = new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
        opacity: 0.4,
        transparent: true,
      });

      let outline = new Mesh(boxGeometry, outlineMaterial);
      outline.name = `${handleName}.outline`;
      outline.scale.set(
        sceneConstants.outlineScale[currentScene],
        sceneConstants.outlineScale[currentScene],
        sceneConstants.outlineScale[currentScene]
      );
      outline.renderOrder = 0;
      // line.add(outline);

      let pickMaterial = new MeshNormalMaterial({
        opacity: 0.2,
        transparent: true,
        visible: this.showPickVolumes,
      });

      let pickVolume = new PickSphereMesh(boxGeometry, pickMaterial);
      pickVolume.name = `${handleName}.pick_volume`;
      pickVolume.scale.set(
        sceneConstants.pickScale[currentScene],
        sceneConstants.pickScale[currentScene],
        sceneConstants.pickScale[currentScene]
      );
      pickVolume.handle = handleName;
      line.add(pickVolume);

      node.setOpacity = (target: number) => {
        let opacity = { x: lineMaterial.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          line.visible = opacity.x > 0;
          pickVolume.visible = opacity.x > 0;
          lineMaterial.opacity = opacity.x;
          outlineMaterial.opacity = opacity.x;
          pickMaterial.opacity = opacity.x * 0.5;
        });
        t.start();
      };
    }
  }

  private initializeRotationHandles() {
    let adjust = 0.5;
    let torusGeometry = new TorusGeometry(
      1,
      adjust * 0.015,
      8,
      64,
      Math.PI / 2
    );
    let outlineGeometry = new TorusGeometry(
      1,
      adjust * 0.04,
      8,
      64,
      Math.PI / 2
    );
    let pickGeometry = new TorusGeometry(1, adjust * 0.1, 6, 4, Math.PI / 2);

    for (let handleName of Object.keys(this.rotationHandles)) {
      let handle = this.handles[handleName];
      let node = handle.node;
      this.group.add(node);

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0.4,
        transparent: true,
      });

      let outlineMaterial = new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
        opacity: 0.4,
        transparent: true,
      });

      let pickMaterial = new MeshNormalMaterial({
        opacity: 0.2,
        transparent: true,
        visible: this.showPickVolumes,
      });

      let box = new Mesh(torusGeometry, material);
      box.name = `${handleName}.handle`;
      box.scale.set(20, 20, 20);
      box.lookAt(new Vector3(...handle.alignment));
      node.add(box);
      handle.translateNode = box;

      let outline = new Mesh(outlineGeometry, outlineMaterial);
      outline.name = `${handleName}.outline`;
      outline.scale.set(1, 1, 1);
      outline.renderOrder = 0;
      box.add(outline);

      let pickVolume = new PickSphereMesh(pickGeometry, pickMaterial);
      pickVolume.name = `${handleName}.pick_volume`;
      pickVolume.scale.set(1, 1, 1);
      pickVolume.handle = handleName;
      box.add(pickVolume);
      this.pickVolumes.push(pickVolume);

      node.setOpacity = (target: number) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          box.visible = opacity.x > 0;
          pickVolume.visible = opacity.x > 0;
          material.opacity = opacity.x;
          outlineMaterial.opacity = opacity.x;
          pickMaterial.opacity = opacity.x * 0.5;
        });
        t.start();
      };

      //pickVolume.addEventListener("mouseover", (e) => {
      //	//let a = this.viewer.scene.getActiveCamera().getWorldDirection(new Vector3()).dot(pickVolume.getWorldDirection(new Vector3()));
      //});

      // pickVolume.addEventListener('drag', (e) => {
      //   this.dragRotationHandle(e);
      // });
      // pickVolume.addEventListener('drop', (e) => {
      //   this.dropRotationHandle(e);
      // });
    }
  }

  private dragRotationHandle(e: any) {
    let drag = e.drag;
    let handle = this.activeHandle;
    let camera = this.camera;

    if (!handle) {
      return;
    }

    let localNormal = new Vector3(...handle.alignment);
    let n = new Vector3();
    n.copy(
      new Vector4(...localNormal.toArray(), 0).applyMatrix4(
        handle.node.matrixWorld
      )
    );
    n.normalize();

    if (!drag.intersectionStart) {
      //this.viewer.scene.scene.remove(this.debug);
      //this.debug = new Object3D();
      //this.viewer.scene.scene.add(this.debug);
      //Utils.debugSphere(this.debug, drag.location, 3, 0xaaaaaa);
      //let debugEnd = drag.location.clone().add(n.clone().multiplyScalar(20));
      //Utils.debugLine(this.debug, drag.location, debugEnd, 0xff0000);

      drag.intersectionStart = drag.location;
      drag.objectStart = drag.object.getWorldPosition(new Vector3());
      drag.handle = handle;

      let plane = new Plane().setFromNormalAndCoplanarPoint(
        n,
        drag.intersectionStart
      );

      drag.dragPlane = plane;
      drag.pivot = drag.intersectionStart;
    } else {
      handle = drag.handle;
    }

    this.dragging = true;

    let mouse = drag.end;
    let domElement = this.renderer.domElement;
    let ray = Potree.Utils.mouseToRay(
      this.mouse,
      camera,
      domElement.clientWidth,
      domElement.clientHeight
    );

    let I = ray.intersectPlane(drag.dragPlane, new Vector3());

    if (I) {
      let center = this.scene.getWorldPosition(new Vector3());
      let from = drag.pivot;
      let to = I;

      let v1 = from.clone().sub(center).normalize();
      let v2 = to.clone().sub(center).normalize();

      let angle = Math.acos(v1.dot(v2));
      let sign = Math.sign(v1.cross(v2).dot(n));
      angle = angle * sign;
      if (Number.isNaN(angle)) {
        return;
      }

      let normal = new Vector3(...handle.alignment);
      for (let selection of this.selection) {
        selection.rotateOnAxis(normal, angle);
        selection.dispatchEvent({
          type: 'orientation_changed',
          object: selection,
        } as any);
      }

      drag.pivot = I;
    }
  }

  private dropRotationHandle(e: unknown) {
    this.dragging = false;
    this.setActiveHandle(null);
  }

  private dragTranslationHandle(e: any) {
    let drag = e.drag;
    let handle = this.activeHandle;
    let camera = this.camera;

    if (!drag.intersectionStart && handle) {
      drag.intersectionStart = drag.location;
      drag.objectStart = drag.object.getWorldPosition(new Vector3());

      let start = drag.intersectionStart;
      let dir = new Vector4(...handle.alignment, 0).applyMatrix4(
        this.scene.matrixWorld
      );
      let end = new Vector3().addVectors(start, dir);
      let line = new Line3(start.clone(), end.clone());
      drag.line = line;

      let camOnLine = line.closestPointToPoint(
        camera.position,
        false,
        new Vector3()
      );
      let normal = new Vector3().subVectors(camera.position, camOnLine);
      let plane = new Plane().setFromNormalAndCoplanarPoint(
        normal,
        drag.intersectionStart
      );
      drag.dragPlane = plane;
      drag.pivot = drag.intersectionStart;
    } else {
      handle = drag.handle;
    }

    this.dragging = true;

    {
      let mouse = drag.end;
      let domElement = this.renderer.domElement;
      let ray = Potree.Utils.mouseToRay(
        this.mouse,
        camera,
        domElement.clientWidth,
        domElement.clientHeight
      );
      let I = ray.intersectPlane(drag.dragPlane, new Vector3());

      if (I) {
        let iOnLine = drag.line.closestPointToPoint(I, false, new Vector3());

        let diff = new Vector3().subVectors(iOnLine, drag.pivot);

        for (let selection of this.selection) {
          selection.position.add(diff);
          selection.dispatchEvent({
            type: 'position_changed',
            object: selection,
          } as any);
        }

        drag.pivot = drag.pivot.add(diff);
      }
    }
  }

  private dropTranslationHandle(e: unknown) {
    this.dragging = false;
    this.setActiveHandle(null);
  }

  private getAxisFromVector3(vector: Vector3) {
    const xAbs = Math.abs(vector.x);
    const yAbs = Math.abs(vector.y);
    const zAbs = Math.abs(vector.z);

    if (xAbs >= yAbs && xAbs >= zAbs) {
      return vector.x > 0 ? 'x+' : 'x-';
    } else if (yAbs >= xAbs && yAbs >= zAbs) {
      return vector.y > 0 ? 'y+' : 'y-';
    } else if (zAbs >= xAbs && zAbs >= yAbs) {
      return vector.z > 0 ? 'z+' : 'z-';
    } else {
      throw new Error(
        'Vector does not correspond to a primary axis or is a zero vector.'
      );
    }
  }

  private getDistanceAlongAxis(
    initialMouse: Vector2,
    currentMouse: Vector2,
    camera: PerspectiveCamera,
    axisDirection: Vector3
  ) {
    // initial and current mouse positions are NDC
    // Create a raycaster for projecting the mouse positions into the 3D scene
    const raycaster = new THREE.Raycaster();

    // Determine the plane perpendicular to the camera's view direction
    // Define the plane perpendicular to the axis of interest
    let planeNormal: Vector3;
    if (
      axisDirection.equals(new THREE.Vector3(1, 0, 0)) ||
      axisDirection.equals(new THREE.Vector3(-1, 0, 0))
    ) {
      // Plane for X-axis movement
      planeNormal = new THREE.Vector3(0, 1, 0); // Y-axis normal
    } else if (
      axisDirection.equals(new THREE.Vector3(0, 1, 0)) ||
      axisDirection.equals(new THREE.Vector3(0, -1, 0))
    ) {
      // Plane for Y-axis movement
      planeNormal = new THREE.Vector3(1, 0, 0); // X-axis normal
    } else if (
      axisDirection.equals(new THREE.Vector3(0, 0, 1)) ||
      axisDirection.equals(new THREE.Vector3(0, 0, -1))
    ) {
      // Plane for Z-axis movement
      planeNormal = camera
        .getWorldDirection(new THREE.Vector3())
        .clone()
        .normalize();
    } else {
      console.error(
        'Invalid axis direction provided. Use a unit vector along the x, y, or z axis.'
      );
      return;
    }

    const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(
      planeNormal,
      this.origin
    );

    // Project the initial mouse position onto the plane
    raycaster.setFromCamera(initialMouse, camera);
    const initialPoint = new THREE.Vector3();
    raycaster.ray.intersectPlane(plane, initialPoint);

    // Project the current mouse position onto the plane
    raycaster.setFromCamera(currentMouse, camera);
    const currentPoint = new THREE.Vector3();
    raycaster.ray.intersectPlane(plane, currentPoint);

    // Ensure that the intersection succeeded
    if (!initialPoint || !currentPoint) {
      console.warn('Raycaster failed to intersect with the plane.');
      return 0;
    }

    // Step 1: Define a small tolerance value for clamping
    const tolerance = 1e-10;

    // Step 2: Clamp small values to zero
    initialPoint.x = Math.abs(initialPoint.x) < tolerance ? 0 : initialPoint.x;
    initialPoint.y = Math.abs(initialPoint.y) < tolerance ? 0 : initialPoint.y;
    initialPoint.z = Math.abs(initialPoint.z) < tolerance ? 0 : initialPoint.z;

    currentPoint.x = Math.abs(currentPoint.x) < tolerance ? 0 : currentPoint.x;
    currentPoint.y = Math.abs(currentPoint.y) < tolerance ? 0 : currentPoint.y;
    currentPoint.z = Math.abs(currentPoint.z) < tolerance ? 0 : currentPoint.z;

    const movement = new THREE.Vector3().subVectors(currentPoint, initialPoint);
    const distanceAlongAxis = movement.dot(axisDirection);
    // Debug log the movement and the distance along axis

    if (isNaN(distanceAlongAxis)) {
      console.error('Distance along axis resulted in NaN.');
      return 0; // Return zero if NaN is detected
    }

    return distanceAlongAxis;
  }

  private dragScaleHandle(event: MouseEvent, handle: ICurrentHandle) {
    if (
      !handle?.alignment ||
      !handle?.object ||
      !handle?.parent ||
      !handle?.parent?.parent?.position
    ) {
      return;
    }

    const axis = handle.alignment;
    // convert axis to the letter x, y, or z from the alignment vector
    // example alignment vector: alignment: vector3(+0, -1, +0) -> y
    const axisLetter = this.getAxisFromVector3(new Vector3(...axis));
    const axisDirection = new THREE.Vector3();
    switch (axisLetter.toLowerCase()) {
      case 'x+':
        axisDirection.set(1, 0, 0);
        break;
      case 'x-': // Handle negative x-axis movement
        axisDirection.set(-1, 0, 0);
        break;
      case 'y+':
        axisDirection.set(0, 1, 0);
        break;
      case 'y-': // Handle negative y-axis movement
        axisDirection.set(0, -1, 0);
        break;
      case 'z+':
        axisDirection.set(0, 0, 1);
        break;
      case 'z-': // Handle negative z-axis movement
        axisDirection.set(0, 0, -1);
        break;
      default:
        throw new Error("Invalid axis provided. Use 'x', 'y', or 'z'.");
    }

    const dist = this.getDistanceAlongAxis(
      this.previousMouse,
      this.mouse.clone(),
      this.camera,
      axisDirection
    );
    if (!dist) return;
    const movement = axisDirection.clone().multiplyScalar(dist);
    this.previousMouse = this.mouse.clone();
    handle.parent.parent.position.add(movement);
    const oldFrameMin = this.frameMin.clone();
    const oldFrameMax = this.frameMax.clone();
    const newBbox = this.adjustBoundingBox(
      this.frameMin.clone(),
      this.frameMax.clone(),
      axisLetter,
      dist,
      this.upVector
    );

    // Define a small margin (1%)
    const margin = 0.01;

    if (this.boxLimits && oldFrameMin && oldFrameMax) {
      // Clamp the new bounding box within the box limits
      newBbox.newFrameMin.clamp(this.boxLimits.min, this.boxLimits.max);
      newBbox.newFrameMax.clamp(this.boxLimits.min, this.boxLimits.max);

      // Ensure the box doesn't collapse into a 2D plane
      const maintainMinimumDistance = (min: Vector3, max: Vector3) => {
        if (Math.abs(max.x - min.x) < margin) {
          // Adjust the max corner to ensure at least the margin distance
          max.x = min.x + margin;
        }
        if (Math.abs(max.y - min.y) < margin) {
          max.y = min.y + margin;
        }
        if (Math.abs(max.z - min.z) < margin) {
          max.z = min.z + margin;
        }
      };

      // Check and revert to old corners if within margin
      const applyMarginCheck = (newCorner: Vector3, oldCorner: Vector3) => {
        if (Math.abs(newCorner.x - oldCorner.x) < margin) {
          newCorner.x = oldCorner.x;
        }
        if (Math.abs(newCorner.y - oldCorner.y) < margin) {
          newCorner.y = oldCorner.y;
        }
        if (Math.abs(newCorner.z - oldCorner.z) < margin) {
          newCorner.z = oldCorner.z;
        }
      };

      // Apply the margin check to both min and max corners
      applyMarginCheck(newBbox.newFrameMin, oldFrameMin);
      applyMarginCheck(newBbox.newFrameMax, oldFrameMax);

      // Ensure the bounding box stays three-dimensional
      maintainMinimumDistance(newBbox.newFrameMin, newBbox.newFrameMax);
    }

    this.adjustFaceHandles(newBbox.newFrameMin, newBbox.newFrameMax);
    this.adjustTranslationAxis(newBbox.newFrameMin, newBbox.newFrameMax);
    this.frameMin = newBbox.newFrameMin.clone();
    this.frameMax = newBbox.newFrameMax.clone();
    this.visualizeCorners();

    const corners = this.getBoundingBoxCorners(this.frameMin, this.frameMax);
    this.updateCubeEdges(corners);
    this.renderer.render(this.scene, this.camera);
    this._frameEvent.next({
      frame: {
        min: this.frameMin,
        max: this.frameMax,
      },
    });
  }

  private setActiveHandle(handle: string | null) {
    if (this.dragging) {
      return;
    }

    if (this.activeHandle === handle) {
      return;
    }

    this.activeHandle = handle;
  }

  update() {
    // return;
    // if (this.selection.length === 1) {
    // this.scene.visible = true;

    this.group.updateMatrix();
    this.scene.updateMatrixWorld();

    // let selected = this.selection[0];
    // // let world = selected.matrixWorld;
    // let camera = this.camera;
    let domElement = this.renderer.domElement;
    // let mouse = this.mouse;
    // if (!selected) return;
    // let center = selected.boundingBox
    //   .getCenter(new Vector3())
    //   .clone()
    //   .applyMatrix4(selected.matrixWorld);

    // this.scene.scale.copy(
    //   selected.boundingBox.getSize(new Vector3()).multiply(selected.scale)
    // );
    // this.scene.position.copy(center);
    // this.scene.rotation.copy(selected.rotation);

    // this.scene.updateMatrixWorld();

    {
      // adjust scale of components
      for (let handleName of Object.keys(this.handles)) {
        let handle = this.handles[handleName];
        let node = handle.node;

        let handlePos = node.getWorldPosition(new Vector3());
        let distance = handlePos.distanceTo(this.camera.position);
        // @ts-ignore
        let pr = Potree.Utils.projectedRadius(
          1,
          this.camera,
          distance,
          domElement.clientWidth,
          domElement.clientHeight
        );

        let ws = node.parent.getWorldScale(new Vector3());

        let s = 7 / pr;
        let scale = new Vector3(s, s, s).divide(ws);

        let rot = new Matrix4().makeRotationFromEuler(node.rotation);
        let rotInv = rot.clone().invert();

        scale.applyMatrix4(rotInv);
        scale.x = Math.abs(scale.x);
        scale.y = Math.abs(scale.y);
        scale.z = Math.abs(scale.z);

        node.scale.copy(scale);
      }

      // adjust rotation handles
      if (!this.dragging) {
        let tWorld = this.scene.matrixWorld;
        let tObject = tWorld.clone().invert();
        let camObjectPos = this.camera
          .getWorldPosition(new Vector3())
          .applyMatrix4(tObject);

        let x = this.rotationHandles['rotation.x'].node.rotation;
        let y = this.rotationHandles['rotation.y'].node.rotation;
        let z = this.rotationHandles['rotation.z'].node.rotation;

        x.order = 'ZYX';
        y.order = 'ZYX';

        let above = camObjectPos.z > 0;
        let below = !above;
        let PI_HALF = Math.PI / 2;

        if (above) {
          if (camObjectPos.x > 0 && camObjectPos.y > 0) {
            x.x = 1 * PI_HALF;
            y.y = 3 * PI_HALF;
            z.z = 0 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y > 0) {
            x.x = 1 * PI_HALF;
            y.y = 2 * PI_HALF;
            z.z = 1 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y < 0) {
            x.x = 2 * PI_HALF;
            y.y = 2 * PI_HALF;
            z.z = 2 * PI_HALF;
          } else if (camObjectPos.x > 0 && camObjectPos.y < 0) {
            x.x = 2 * PI_HALF;
            y.y = 3 * PI_HALF;
            z.z = 3 * PI_HALF;
          }
        } else if (below) {
          if (camObjectPos.x > 0 && camObjectPos.y > 0) {
            x.x = 0 * PI_HALF;
            y.y = 0 * PI_HALF;
            z.z = 0 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y > 0) {
            x.x = 0 * PI_HALF;
            y.y = 1 * PI_HALF;
            z.z = 1 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y < 0) {
            x.x = 3 * PI_HALF;
            y.y = 1 * PI_HALF;
            z.z = 2 * PI_HALF;
          } else if (camObjectPos.x > 0 && camObjectPos.y < 0) {
            x.x = 3 * PI_HALF;
            y.y = 0 * PI_HALF;
            z.z = 3 * PI_HALF;
          }
        }
      }
    }
  }

  private visualizeCorners() {
    // for debugging purposes only
    return;
    // Remove any previously added spheres
    this.removeCorners();

    // Get the updated corners for the current crop box
    const corners = this.getBoundingBoxCorners(this.frameMin, this.frameMax);

    corners.forEach((pos) => {
      const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.1), // Small sphere for debugging
        new THREE.MeshBasicMaterial({ color: 0xff0000 }) // Red color for visibility
      );
      sphere.position.copy(pos);
      sphere.name = 'debugCorner';
      this.scene.add(sphere);

      // Store the sphere so we can remove it later
      this.debugCorners.push(sphere);
    });

    this.renderer.render(this.scene, this.camera);
  }

  private removeCorners() {
    const debugCorners = this.scene.children.filter(
      (child) => child.name === 'debugCorner'
    );
    debugCorners.forEach((corner) => {
      if (corner instanceof Mesh) {
        corner.geometry.dispose();
        corner.material.dispose();
        this.scene.remove(corner);
      }
    });
    this.debugCorners = [];
  }

  private adjustBoundingBox(
    frameMin: Vector3, // Current min corner of the box
    frameMax: Vector3, // Current max corner of the box
    face: string, // The face being moved (e.g., 'x+', 'y-', 'z+')
    movement: number, // The length of the movement to apply
    upVector: Vector3 // Defines the up direction (e.g., (0, 1, 0) for Y-up or (0, 0, 1) for Z-up)
  ): { newFrameMin: Vector3; newFrameMax: Vector3 } {
    const _cur_corners = this.getBoundingBoxCorners(frameMin, frameMax);

    // Step 1: Determine which axis is the "up" direction
    const upAxis = this.getUpAxis(upVector);

    // Step 2: Adjust the face based on the provided face and the up axis

    const corners = this.isPotree
      ? this.adjustFaceCornersQuad1(_cur_corners, face, movement)
      : this.adjustFaceCorners(_cur_corners, face, movement, upAxis);

    // Return the updated min and max
    return {
      // 2, 8
      newFrameMin: corners[0],
      newFrameMax: corners[6],
    };
  }

  private getBoundingBoxCorners(
    frameMin: Vector3,
    frameMax: Vector3
  ): Vector3[] {
    // Return the 8 corners of the bounding box
    return [
      new Vector3(frameMin.x, frameMin.y, frameMin.z), // Corner 1 (Front-bottom-left)
      new Vector3(frameMax.x, frameMin.y, frameMin.z), // Corner 2 (Front-bottom-right)
      new Vector3(frameMax.x, frameMax.y, frameMin.z), // Corner 3 (Front-top-right)
      new Vector3(frameMin.x, frameMax.y, frameMin.z), // Corner 4 (Front-top-left)
      new Vector3(frameMin.x, frameMin.y, frameMax.z), // Corner 5 (Back-bottom-left)
      new Vector3(frameMax.x, frameMin.y, frameMax.z), // Corner 6 (Back-bottom-right)
      new Vector3(frameMax.x, frameMax.y, frameMax.z), // Corner 7 (Back-top-right)
      new Vector3(frameMin.x, frameMax.y, frameMax.z), // Corner 8 (Back-top-left)
    ];
  }

  private getUpAxis(upVector: Vector3): string {
    // Return the axis based on the up vector
    if (upVector.equals(new Vector3(0, 1, 0))) return 'y'; // Y-axis is up
    if (upVector.equals(new Vector3(0, -1, 0))) return 'y'; // Y-axis is up
    if (upVector.equals(new Vector3(0, 0, 1))) return 'z'; // Z-axis is up
    if (upVector.equals(new Vector3(0, 0, -1))) return 'z'; // Z-axis is up
    if (upVector.equals(new Vector3(1, 0, 0))) return 'x'; // X-axis is up
    if (upVector.equals(new Vector3(-1, 0, 0))) return 'x'; // X-axis is up

    throw new Error('Unsupported up vector');
  }

  private adjustTranslationAxis(min: Vector3, max: Vector3) {
    for (let handleName of Object.keys(this.translationHandles)) {
      let handle = this.translationHandles[handleName];
      let node = handle.node;
      let alignment = handle.alignment;

      let axis = new Vector3(...alignment);
      let axisNorm = axis.clone().normalize();

      let minToMax = new Vector3().subVectors(max, min);
      let minToMaxNorm = minToMax.clone().normalize();

      let angle = Math.acos(minToMaxNorm.dot(axisNorm));
      let sign = Math.sign(minToMax.cross(axis).dot(this.upVector));
      angle = angle * sign;

      let distance = min.distanceTo(max);

      let scale = new Vector3(1, 1, distance);
      let rot = new Matrix4().makeRotationAxis(axisNorm, angle);
      let pos = min.clone().add(max).multiplyScalar(0.5);

      node.position.copy(pos);
      node.rotation.setFromRotationMatrix(rot);
      node.scale.copy(scale);
    }
  }

  private adjustFaceHandles(min: Vector3, max: Vector3) {
    // Reposition the scale handles based on the new corners
    const handleNames = Object.keys(this.scaleHandles);
    for (let handleName of handleNames) {
      const handle = this.scaleHandles[handleName];
      const node = handle.node;
      const face = handle.name.split('.')[1];

      const newX = (min.x + max.x) / 2;
      const newY = (min.y + max.y) / 2;
      const newZ = (min.z + max.z) / 2;

      // Update position fields using Object.assign to avoid readonly errors
      switch (face) {
        case 'x+':
          Object.assign(node.position, { x: max.x, y: newY, z: newZ });
          break;
        case 'x-':
          Object.assign(node.position, { x: min.x, y: newY, z: newZ });
          break;
        case 'y+':
          Object.assign(node.position, { x: newX, y: max.y, z: newZ });
          break;
        case 'y-':
          Object.assign(node.position, { x: newX, y: min.y, z: newZ });
          break;
        case 'z+':
          Object.assign(node.position, { x: newX, y: newY, z: max.z });
          break;
        case 'z-':
          Object.assign(node.position, { x: newX, y: newY, z: min.z });
          break;
      }
    }
  }

  // TODO: Combine adjustFaceCorners and adjustFaceCornersQuad1
  private adjustFaceCorners(
    _corners: Vector3[],
    face: string,
    movement: number,
    upAxis: string
  ): Vector3[] {
    const corners = _corners.map((corner) => corner.clone());
    const axis = face[0] as 'x' | 'y' | 'z'; // 'x', 'y', or 'z'
    const isPositiveDirection = face[1] === '+';

    function moveCorners(
      corners: Vector3[],
      indices: number[],
      movement: number,
      isPositiveDirection: boolean,
      axis: 'x' | 'y' | 'z'
    ) {
      indices.forEach((index: number) => {
        if (isPositiveDirection) {
          corners[index][axis] += movement;
        } else {
          corners[index][axis] -= movement;
        }
      });
    }

    function moveOnAxis(
      corners: Vector3[],
      axis: 'x' | 'y' | 'z',
      movement: number,
      isPositiveDirection: boolean
    ) {
      const faces: { [key: string]: { [key: string]: number[][] } } = {
        x: {
          z: [
            [2, 3, 6, 7],
            [0, 1, 4, 5],
          ],
          y: [
            [1, 2, 5, 6],
            [0, 3, 4, 7],
          ],
          x: [
            [4, 5, 6, 7],
            [0, 1, 2, 3],
          ],
        },
        y: {
          z: [
            [1, 2, 5, 6],
            [0, 3, 4, 7],
          ],
          y: [
            [2, 3, 6, 7],
            [0, 1, 4, 5],
          ],
          x: [
            [1, 2, 5, 6],
            [0, 3, 4, 7],
          ],
        },
        z: {
          z: [
            [2, 3, 6, 7],
            [0, 1, 4, 5],
          ],
          y: [
            [4, 5, 6, 7],
            [0, 1, 2, 3],
          ],
          x: [
            [1, 2, 5, 6],
            [0, 3, 4, 7],
          ],
        },
      };

      const upAxisFaces = faces[axis][upAxis];
      const indices = isPositiveDirection ? upAxisFaces[0] : upAxisFaces[1];

      moveCorners(corners, indices, movement, isPositiveDirection, axis);
    }

    // Call the moveOnAxis function for each axis
    moveOnAxis(corners, axis, movement, isPositiveDirection);

    return corners;
  }

  // later we can combine this with adjustFaceCorners
  private adjustFaceCornersQuad1(
    _corners: Vector3[],
    face: string,
    movement: number
  ): Vector3[] {
    const corners = _corners.map((corner) => corner.clone());

    // get the x+ face
    if (face === 'x+') {
      corners[2].x += movement;
      corners[3].x += movement;
      corners[6].x += movement;
      corners[7].x += movement;
    } else if (face === 'x-') {
      corners[0].x -= movement;
      corners[1].x -= movement;
      corners[4].x -= movement;
      corners[5].x -= movement;
    } else if (face === 'y+') {
      corners[1].y += movement;
      corners[2].y += movement;
      corners[5].y += movement;
      corners[6].y += movement;
    } else if (face === 'y-') {
      corners[0].y -= movement;
      corners[3].y -= movement;
      corners[4].y -= movement;
      corners[7].y -= movement;
    } else if (face === 'z+') {
      corners[4].z += movement;
      corners[5].z += movement;
      corners[6].z += movement;
      corners[7].z += movement;
    } else if (face === 'z-') {
      corners[0].z -= movement;
      corners[1].z -= movement;
      corners[2].z -= movement;
      corners[3].z -= movement;
    }
    return corners;
  }

  private onMouseDown(event: MouseEvent) {
    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    const raycaster = new Raycaster();
    raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = raycaster.intersectObjects(this.pickVolumes, true);
    if (intersects.length > 0) {
      if (this.isPotree && this.viewer) {
        this.viewer.pauseControls();
      } else {
        this.controls.enabled = false;
      }

      const rect = this.renderer.domElement.getBoundingClientRect();

      const currentMouseNDC = new Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );
      this.previousMouse = currentMouseNDC;

      this.isDragging = true;
      this.dragStartPosition.set(event.clientX, event.clientY);
      const intersectedObject: ICurrentHandle =
        intersects[0] as unknown as ICurrentHandle;
      if (!intersectedObject?.object?.handle) return;
      const handle = this.handles[intersectedObject.object.handle];
      const parentName = `${handle.name}.handle`;
      const handleParent = this.group.getObjectByName(parentName) || null;
      this.currentHandle = {
        object: intersectedObject.object,
        alignment: handle.alignment,
        parent: handleParent,
      };
      this.setActiveHandle(handle);
    }
  }

  private onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    if (!this.currentHandle) return;
    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    if (
      this.currentHandle?.object?.name.includes('scale') &&
      this.currentHandle
    ) {
      this.dragScaleHandle(event, this.currentHandle);
    }

    this.dragStartPosition.set(event.clientX, event.clientY);
  }

  private onMouseUp() {
    this.visualizeCorners();
    if (this.isPotree && this.viewer) {
      this.viewer.unPauseControls();
    } else {
      this.controls.enabled = true;
    }
    if (this.isDragging) {
      this.isDragging = false;
      this.currentHandle = null;
      this.setActiveHandle(null);
    }
  }

  private getFaceCenters(min: Vector3, max: Vector3): Record<string, Vector3> {
    const centers = {
      'x+': new Vector3(max.x, (min.y + max.y) / 2, (min.z + max.z) / 2), // Right face center (max.x)
      'x-': new Vector3(min.x, (min.y + max.y) / 2, (min.z + max.z) / 2), // Left face center (min.x)
      'y+': new Vector3((min.x + max.x) / 2, max.y, (min.z + max.z) / 2), // Top face center (max.y)
      'y-': new Vector3((min.x + max.x) / 2, min.y, (min.z + max.z) / 2), // Bottom face center (min.y)
      'z+': new Vector3((min.x + max.x) / 2, (min.y + max.y) / 2, max.z), // Front face center (max.z)
      'z-': new Vector3((min.x + max.x) / 2, (min.y + max.y) / 2, min.z), // Back face center (min.z)
    };
    return centers;
  }

  private getCorners8and2(
    oppositeA: Vector3,
    oppositeB: Vector3
  ): { corner8: Vector3; corner2: Vector3 } {
    // Calculate the minimum and maximum coordinates between oppositeA and oppositeB
    const min = new Vector3(
      Math.min(oppositeA.x, oppositeB.x),
      Math.min(oppositeA.y, oppositeB.y),
      Math.min(oppositeA.z, oppositeB.z)
    );

    const max = new Vector3(
      Math.max(oppositeA.x, oppositeB.x),
      Math.max(oppositeA.y, oppositeB.y),
      Math.max(oppositeA.z, oppositeB.z)
    );

    // Corrected:
    // Corner 8: (min.x, max.y, max.z) => back-top-left
    const corner8 = new Vector3(min.x, max.y, max.z);

    // Corner 2: (max.x, min.y, min.z) => front-bottom-right
    const corner2 = new Vector3(max.x, min.y, min.z);

    return { corner8, corner2 };
  }

  private cubeEdges: Line2 | null = null;

  private updateCubeEdges(cornerPoints: Vector3[]) {
    if (cornerPoints.length !== 8) {
      console.error('Expected 8 corner points.');
      return;
    }

    // Define the edges of the cube without diagonals
    const edgesIndices = [
      // Front face
      [0, 1],
      [1, 2],
      [2, 3],
      [3, 0], // Close the front face

      // Move to the back face (front-bottom-left to back-bottom-left)
      [0, 4],

      // Back face
      [4, 5],
      [5, 6],
      [6, 7],
      [7, 4], // Close the back face

      // Move to front-top-left (back-bottom-left to front-top-left via front-bottom-left)
      [4, 0],
      [0, 3],

      // Vertical edges (top-right to front-top-right, bottom-right to front-bottom-right)
      [3, 7], // Front-top-left to back-top-left
      [7, 6], // Back-top-left to back-top-right
      [6, 2], // Back-top-right to front-top-right
      [2, 1], // Front-top-right to front-bottom-right
      [1, 5], // Front-bottom-right to back-bottom-right
      [5, 4], // Back-bottom-right to back-bottom-left
    ];

    const interpolatedPositions = [];

    for (let edge of edgesIndices) {
      const start = cornerPoints[edge[0]];
      const end = cornerPoints[edge[1]];

      // Instead of interpolating, just push the start and end positions directly
      interpolatedPositions.push(start);
      interpolatedPositions.push(end);
    }

    const firstCorner = cornerPoints[0]; // This will be the world position

    if (!this.cubeEdges) {
      // Create the geometry and add the interpolated edge vertices
      const geometry = new LineGeometry();
      const linearPositions = getLinearPositions(interpolatedPositions, 100);
      geometry.setPositions(linearPositions);

      // Create a LineMaterial (note that LineMaterial uses width, unlike LineBasicMaterial)
      const material = new LineMaterial({
        color: 0xffffff,
        // worldUnits: true, // Makes the linewidth independent of camera distance
        dashSize: 5,
        gapSize: 2,
        linewidth: 4,
        resolution: new Vector2(1000, 1000),
        polygonOffset: true,
        polygonOffsetFactor: this.isPotree ? -100 : -1, // Opposite direction to the border
        polygonOffsetUnits: this.isPotree ? -100 : -1,
      });

      this.cubeEdges = new Line2(geometry, material);
      this.cubeEdges.name = this.frameName;
      this.cubeEdges.computeLineDistances(); // Required for Line2 to work correctly

      // Set the position of the entire Line2 object to align with the first corner point in world space

      // Add the object to the scene
      this.scene.add(this.cubeEdges);
    } else {
      // Update the existing geometry
      const geometry = this.cubeEdges.geometry as LineGeometry;
      const linearPositions = getLinearPositions(interpolatedPositions, 100);
      geometry.setPositions(linearPositions);
      geometry.attributes.position.needsUpdate = true;
    }
    this.cubeEdges.position.copy(firstCorner);
  }

  private destroy() {
    // clean up listeners
    this.removeListeners();
    this._frameEvent.next({
      frame: {
        min: new Vector3(-1000, -1000, -1000),
        max: new Vector3(1000, 1000, 1000),
      },
    });
    this._frameEvent.complete();
    this.group.children.forEach((child) => {
      // Ensure the child is a Mesh before operating on it
      if (child instanceof Mesh) {
        // Remove the child from the group

        // Ensure each grandchild is a Mesh too
        child.children.forEach((grandchild: Object3D) => {
          if (grandchild instanceof Mesh) {
            // Dispose of geometry and material safely
            const geometry = grandchild.geometry;
            const material = grandchild.material;
            geometry.dispose();
            material.dispose();
          }
        });
        this.group.remove(child);
      }
    });
    this.scene.remove(this.group);
    this.removeFrame();
    this.removeCorners();
  }

  private removeListeners() {
    this.renderer.domElement.removeEventListener(
      'mousedown',
      this._boundOnMouseDown
    );
    this.renderer.domElement.removeEventListener(
      'mousemove',
      this._boundOnMouseMove
    );
    this.renderer.domElement.removeEventListener(
      'mouseup',
      this._boundOnMouseUp
    );
  }

  private removeFrame() {
    this.cubeEdges?.geometry.dispose();
    this.cubeEdges?.material.dispose();
    if (this.cubeEdges) {
      this.scene.remove(this.cubeEdges);
    }
    this.cubeEdges = null;
  }
}

class PickSphereMesh extends Mesh implements IPickSphereMesh {
  handle = '';
}
