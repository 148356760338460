import _ from 'lodash';

import { Collection } from '@agerpoint/api';
import {
  ComboBoxOption,
  IBoxPlotData,
  IboxPlotWithSummary,
  SummaryStats,
} from '@agerpoint/types';

export const getTitleCase = (str: string): string => {
  return str
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => {
      const lower = x.toLocaleLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    })
    .join(' ');
};

export const createComboBoxOptions = (
  options: string[],
  idKey?: string
): ComboBoxOption[] => {
  return options
    .map((value) => {
      return {
        name: getTitleCase(value),
        id: value,
      };
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
};

export const createChartDataAndSort = (
  data: any[],
  sortByKey: string,
  key: string
): any[] => {
  return data
    ?.sort((a: any, b: any) => {
      return b[sortByKey] - a[sortByKey];
    })
    .map((value: any) => {
      return {
        name: value.id,
        [key]: value[key],
      };
    });
};

export const prepBoxPlotData = (
  data: IboxPlotWithSummary | void | null,
  key: string
): any[] => {
  if (!data || !data?.bins?.length) {
    return [];
  }

  return data.bins.map((value) => {
    return {
      name: value.range,
      [key]: [value.summary.q25, value.summary.q75],
      errorY: [value.summary.minError, value.summary.maxError],
    };
  });
};

export const groupByAttribute = (
  attribute: string,
  apiData: any[],
  keyToGet: string
) => {
  const keyInCamelCase = _.camelCase(keyToGet as string);
  const groupedData = apiData.reduce((acc, data) => {
    const key = data[attribute];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(data);
    return acc;
  }, {});
  const summary = Object.keys(groupedData).map((key) => {
    const values = groupedData[key].map(
      (data: Collection[]) => data[keyInCamelCase as any]
    );
    const summary = summaryStats(values);
    return {
      name: key,
      [keyToGet]: [summary.q25, summary.q75],
      errorY: [summary.minError, summary.maxError],
    };
  });
  return summary;
};

export const groupByBins = (
  binData: IBoxPlotData,
  key: string,
  apiData: any[]
): IboxPlotWithSummary => {
  const bins = binData.bins;
  const groupedData = bins.map((bin) => {
    const binValues = apiData
      .filter((data) => {
        return data[key] >= bin.rangeMin && data[key] <= bin.rangeMax;
      })
      // .map((data) => ({ id: data.id, value: data[key] }));
      .map((data) => data[key]);
    return {
      ...bin,
      values: binValues,
      summary: summaryStats(binValues),
    };
  });
  return {
    ...binData,
    bins: groupedData,
  };
};

const summaryStats = (inputArr: number[]) => {
  // sort array ascending
  const asc = (arr: number[]) => arr.sort((a, b) => a - b);

  const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

  const mean = (arr: number[]) => sum(arr) / arr.length;

  // sample standard deviation
  const std = (arr: number[]) => {
    const mu = mean(arr);
    const diffArr = arr.map((a) => (a - mu) ** 2);
    return Math.sqrt(sum(diffArr) / (arr.length - 1));
  };

  const quantile = (arr: number[], q: number) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  };

  const q25 = (arr: number[]) => quantile(arr, 0.25);

  const q50 = (arr: number[]) => quantile(arr, 0.5);

  const q75 = (arr: number[]) => quantile(arr, 0.75);

  const median = (arr: number[]) => q50(arr);

  const sorted = asc(inputArr);

  const q25val = q25(sorted);
  const q75val = q75(sorted);
  const iqr = q75val - q25val;

  return {
    sum: sum(sorted),
    mean: mean(sorted),
    std: std(sorted),
    min: sorted[0],
    q25: q25val,
    median: median(sorted),
    q75: q75val,
    max: sorted[sorted.length - 1],
    minError: q25val - 1.5 * iqr,
    maxError: q75val + 1.5 * iqr,
  };
};
