import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import {
  faBoxArchive,
  faCircleNotch,
  faPencil,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatatableOld,
  dataTableAgerStyle,
} from 'libs/feature/src/datatable/datatable-old';
import { isEqual, set } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseGetReturn } from 'restful-react';
import { useDebouncyEffect } from 'use-debouncy';

import {
  Customer,
  Project,
  ProjectFilter,
  User,
  formatDate,
  useDeleteProject,
  useGetFilteredPageProjects,
  useGetUsersAvailibleFromCaptures,
} from '@agerpoint/api';
import {
  Button,
  ConfirmModal,
  Input,
  PrimaryButton,
} from '@agerpoint/component';
import {
  AppBar,
  ContextMenu,
  ContextMenuGroupProps,
  CreateProjectModal,
  EditProjectModal,
} from '@agerpoint/feature';
import { AppBarProps, UserClaims } from '@agerpoint/types';
import {
  Sort,
  hasClaims,
  useGlobalStore,
  useLookupTable,
  usePageTitle,
  useQueryState,
  useToasts,
} from '@agerpoint/utilities';

export interface ProjectsPageProps {
  customers: Customer[];
  appBar: AppBarProps;
}

/**
 * Consists of AppBar and Datatable ui components
 *
 * @param props
 * @returns
 */
export function ProjectsPage({ customers, appBar }: ProjectsPageProps) {
  usePageTitle(() => 'Projects', []);
  const navigate = useNavigate();

  const {
    user,
    actions: { setSiteWideLoading },
  } = useGlobalStore();

  const toasts = useToasts();

  const {
    mutate: getProjects,
    cancel: cancelPreviousGetProjectsCall,
    loading: loadingProjects,
  } = useGetFilteredPageProjects({ skip: NaN, take: NaN });

  const [projectToEdit, setProjectToEdit] = useState<Project>();
  const [projectToArchive, setProjectToArchive] = useState<Project>();
  const [loadingArchiveProject, setLoadingArchiveProject] =
    useState<boolean>(false);

  const [showCreateProjectModal, setCreateProjectModal] = useState(false);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);

  const { mutate: archiveProject } = useDeleteProject({});

  const { data: users } = useGetUsersAvailibleFromCaptures(
    {}
  ) as unknown as UseGetReturn<User[], void, void, unknown>;

  const isAdmin = useMemo(() => {
    return hasClaims(
      [UserClaims.AgerAdmin],
      (user?.cloudClaims || []) as UserClaims[]
    );
  }, [user]);

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a.trim(),
    toUrlParam: (a) => a.trim(),
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        if (!isAdmin) {
          return [];
        }

        const splitted = a?.split(',').map((x) => Number(x));
        const filtered = customers.filter((x) => {
          return splitted?.includes(x.id as number) ?? false;
        });
        return filtered;
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: customers.length > 0,
    });

  const [selectedFilterUsers, setSelectedFilterUsers] = useQueryState<User[]>({
    paramName: 'users',
    initialValue: [],
    fromUrlParam: (a) => {
      if (!users) {
        return [];
      }

      return users.filter((x) => a?.includes(x.id as string) ?? false);
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: (users?.length || 0) > 0,
  });

  const sortedOrganizations = useMemo(
    () => Sort.organizations(customers),
    [customers]
  );

  const sortedUsers = useMemo(() => Sort.users(users ?? undefined), [users]);

  const [projects, setProjects] = useState<Project[]>([]);
  const [pagingExhausted, setPagingExhausted] = useState<boolean>(false);

  const projectsRequestBody = useRef<{ filter: ProjectFilter; skip: number }>({
    filter: {
      projectName: nameFilter || '',
      orderBy: 'createDatetime',
      orderAscending: false,
      customerIds: selectedFilterOrganizations.map((x) => x.id as number),
      userUuids: selectedFilterUsers.map((x) => x.id as string),
    },
    skip: 0,
  });

  useDebouncyEffect(
    () => {
      const newFilter = {
        ...projectsRequestBody.current.filter,
        projectName: nameFilter.trim(),
      };
      if (isEqual(newFilter, projectsRequestBody.current.filter)) {
        return;
      }
      projectsRequestBody.current = { filter: newFilter, skip: 0 };

      getProjectsPage();
    },
    1000,
    [nameFilter]
  );

  useEffect(() => {
    const newFilter = {
      ...projectsRequestBody.current.filter,
      customerIds: selectedFilterOrganizations.map((x) => x.id as number),
      userUuids: selectedFilterUsers.map((x) => x.id as string),
    };

    if (isEqual(newFilter, projectsRequestBody.current.filter)) {
      return;
    }
    projectsRequestBody.current = { filter: newFilter, skip: 0 };
    getProjectsPage();
  }, [selectedFilterOrganizations, selectedFilterUsers]);

  const getProjectsPage = useCallback(async () => {
    cancelPreviousGetProjectsCall();
    setSiteWideLoading(true);
    try {
      const response = (await getProjects(projectsRequestBody.current.filter, {
        pathParams: {
          skip: projectsRequestBody.current.skip,
          take: 30,
        },
      })) as unknown as Project[];

      if (!response) {
        return;
      }

      if (projectsRequestBody.current.skip === 0) {
        setProjects([...response]);
      } else {
        setProjects((prev) => [...prev, ...response]);
      }

      if (response.length > 0) {
        setPagingExhausted(false);
      } else {
        setPagingExhausted(true);
      }
    } catch (e: any) {
      if (e.message.includes('aborted')) {
        return;
      }
    }
    setSiteWideLoading(false);
  }, [cancelPreviousGetProjectsCall, getProjects, setSiteWideLoading]);

  useEffect(() => {
    return () => {
      setSiteWideLoading(false);
    };
  }, []);

  const handleArchiveProject = useCallback(async () => {
    if (projectToArchive?.id === undefined) {
      return;
    }

    setLoadingArchiveProject(true);
    try {
      await archiveProject(projectToArchive?.uuid as string);
      setProjects((prev) => {
        return prev.filter((p) => p.id !== projectToArchive?.id);
      });
      setProjectToArchive(undefined);

      toasts.add(toasts.prepare.entityArchived('project'));
    } catch (error) {
      console.error(error);

      toasts.add(toasts.prepare.error('Failed to archive project!'));
    } finally {
      setLoadingArchiveProject(false);
    }
  }, [projectToArchive, toasts, archiveProject]);

  const customersLookupTable = useLookupTable(customers, 'id');

  const contextMenuItems = useCallback((item: Project) => {
    const managementGroup: ContextMenuGroupProps = {
      label: 'Management',
      items: [
        {
          icon: <FontAwesomeIcon icon={faPencil} />,
          label: 'Edit',
          disabled: false,
          onClick: () => {
            setProjectToEdit(item);
            setShowEditProjectModal(true);
          },
        },
        {
          icon: <FontAwesomeIcon icon={faBoxArchive} />,
          label: 'Archive',
          disabled: false,
          onClick: () => {
            setProjectToArchive(item);
          },
        },
      ],
    };

    const otherGroup: ContextMenuGroupProps = {
      label: 'Other',
      items: [
        {
          icon: <FontAwesomeIcon icon={faExternalLink} />,
          label: 'New Window',
          disabled: false,
          onClick: () => {
            window.open(`/project/${item.uuid}`, '_blank');
          },
        },
      ],
    };

    return [managementGroup, otherGroup];
  }, []);

  const rowOnClick = useCallback(
    (project: Project, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate({
        pathname: `/project/${project.uuid}`,
      });
    },
    [navigate]
  );

  return (
    <>
      <AppBar {...appBar} />
      <div className="flex flex-col flex-1 overflow-hidden px-4 relative pb-4">
        <header>
          <div className="flex max-w-7xl mx-auto pt-2 justify-between pb-1">
            <h1 className="text-3xl font-bold">Projects</h1>
            <PrimaryButton
              onClicked={() => setCreateProjectModal(true)}
              label="New Project"
              icon={<FontAwesomeIcon icon={faPlus} />}
            />
          </div>
          <div className="flex max-w-7xl mx-auto justify-start flex-wrap w-full gap-1">
            <div className="pr-2 flex w-2/3 sm:w-1/3">
              <div className="w-full">
                <Input.Text.Single
                  id="project-name-search"
                  value={nameFilter}
                  setValue={setNameFilter}
                  placeholderIcon={Input.placeholderIcons.search}
                  placeholder="Search by Name"
                />
              </div>
            </div>
            {isAdmin && (
              <Input.Select.Multi
                id="organization-filter-select"
                placeholder="Organizations"
                title="Organizations"
                options={sortedOrganizations ?? []}
                value={selectedFilterOrganizations}
                loading={(sortedOrganizations?.length ?? 0) === 0}
                setValue={setSelectedFilterOrganizations}
                optionBuilder={(o) =>
                  o.customerDisplayName ?? o.customerName ?? 'Unknown'
                }
                maxWidth="232px"
              />
            )}
            <Input.Select.Multi
              id="user-filter-select"
              placeholder="Owners"
              title="Owners"
              options={sortedUsers ?? []}
              value={selectedFilterUsers}
              loading={(sortedUsers?.length ?? 0) === 0}
              setValue={setSelectedFilterUsers}
              optionBuilder={(o) =>
                `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
              }
              maxWidth="232px"
            />
            <Button.ClearFilter
              visible={
                !!(
                  nameFilter ||
                  selectedFilterOrganizations.length ||
                  selectedFilterUsers.length
                )
              }
              onClick={() => {
                setSelectedFilterOrganizations([]);
                setSelectedFilterUsers([]);
                setNameFilter('');

                const searchParams = new URLSearchParams(
                  window.location.search
                );
                searchParams.delete('organizations');
                searchParams.delete('users');
                searchParams.delete('name');
                navigate(
                  {
                    pathname: '/projects',
                    search: searchParams.toString(),
                  },
                  {
                    replace: true,
                  }
                );
              }}
            />
          </div>
        </header>
        <main className="max-h-full min-h-0 max-w-7xl mx-auto flex h-full w-full pt-2">
          <DatatableOld
            data={projects}
            style={{ ...dataTableAgerStyle, tableMinWidth: 900 }}
            rowHeight={40}
            columns={[
              {
                label: 'Name',
                value: (row) => {
                  return <span title={row.name || ''}>{row.name}</span>;
                },
                flex: 2,
                name: 'name',
                sortKey: 'name',
              },
              {
                label: 'Created On',
                value: (row) => {
                  return row.createDatetime
                    ? formatDate(row.createDatetime.toString())
                    : '';
                },
                name: 'created',
                sortKey: 'createDatetime',
              },
              {
                label: 'Owner',
                value: (row) => {
                  return [
                    row.ownerProfile?.firstName ?? '',
                    row.ownerProfile?.lastName ?? '',
                  ]
                    .join(' ')
                    .trim();
                },
                name: 'owner',
                flex: 2,
              },
              {
                label: 'Organization',
                value: (row) => {
                  if (!row.customerId) {
                    return null;
                  }
                  if (!customersLookupTable) {
                    return <FontAwesomeIcon icon={faCircleNotch} spin />;
                  }

                  const c = customersLookupTable?.[row.customerId ?? ''];

                  return c?.customerDisplayName ?? c?.customerName ?? '';
                },
                name: 'customer',
                flex: 2,
              },
              {
                label: 'Actions',
                value: function Action(row, index) {
                  if (row.id) {
                    return (
                      <ContextMenu
                        dataTestId={`projects-list-context-menu-${index}`}
                        groups={contextMenuItems(row)}
                      />
                    );
                  }
                  return null;
                },
                style: { columnWrapperStyle: 'flex justify-center' },
              },
            ]}
            cellOnClick={(columnName) => {
              const clickable = ['name', 'customer', 'owner', 'created'];
              if (clickable.includes(columnName)) {
                return rowOnClick;
              }
              return;
            }}
            pagination={{
              loadPage: () => {
                if (loadingProjects || pagingExhausted) {
                  return;
                }
                projectsRequestBody.current = {
                  ...projectsRequestBody.current,
                  skip: projects.length,
                };
                getProjectsPage();
              },
              hasNextPage: !pagingExhausted,
              threshold: 10,
            }}
            initialSortingOptions={{
              key: 'createDatetime',
              order: 'desc',
            }}
            sortingChanged={(options) => {
              projectsRequestBody.current = {
                filter: {
                  ...projectsRequestBody.current.filter,
                  orderBy: options.key,
                  orderAscending: options.order === 'asc',
                },
                skip: 0,
              };
              getProjectsPage();
            }}
          />
        </main>
        <CreateProjectModal
          open={showCreateProjectModal}
          handleCloseDialog={() => {
            setCreateProjectModal(false);
          }}
          organizations={customers}
          users={users ?? undefined}
          onProjectCreated={(project) => {
            navigate(`/project/${project.uuid}`);
          }}
        />
        <EditProjectModal
          project={projectToEdit}
          open={showEditProjectModal}
          handleCloseDialog={() => {
            setShowEditProjectModal(false);
          }}
          organizations={customers}
          onProjectUpdated={(project) => {
            setProjects((prev) => {
              return prev.map((p) => {
                if (p.id === project.id) {
                  return project;
                }
                return p;
              });
            });
          }}
        />
        <ConfirmModal
          title="Archive Project"
          message={`Are you sure you want to archive the '${projectToArchive?.name}' project?`}
          isOpen={projectToArchive !== undefined}
          close={{
            label: 'No',
            callback: () => setProjectToArchive(undefined),
          }}
          confirm={{
            label: 'Yes',
            callback: handleArchiveProject,
          }}
          canConfirm={!loadingArchiveProject}
        />
      </div>
    </>
  );
}
