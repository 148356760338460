import { Customer, Project } from '@agerpoint/api';
import { AppBar, CapturesTable } from '@agerpoint/feature';
import { AppBarProps, IEnvironment, UserClaims } from '@agerpoint/types';
import { hasClaims, useGlobalStore } from '@agerpoint/utilities';

export const CaptureTableWrapper = ({
  customers,
  environment,
  appBarProps,
}: {
  customers: Customer[] | [];
  environment: IEnvironment;
  appBarProps: AppBarProps;
}) => {
  const { user, permissions } = useGlobalStore();
  const isAdmin = hasClaims(
    [UserClaims.AgerAdmin],
    user?.cloudClaims as UserClaims[]
  );
  const hasProjectSelection = hasClaims(
    [UserClaims.Projects],
    user?.cloudClaims as UserClaims[]
  );

  return (
    <>
      <AppBar {...appBarProps} />
      <CapturesTable
        customers={customers ? (customers as Customer[]) : []}
        serverUrl={environment.api_server_url}
        claims={{
          Projects: hasProjectSelection,
          AgerAdmin: isAdmin,
        }}
        ldFlags={permissions}
      />
    </>
  );
};
