import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import compare from 'trivial-compare';

import { APIClient, Capture, Customer, User } from '@agerpoint/api';
import {
  DialogModal,
  Input,
  MultiSelect,
  PrimaryButton,
} from '@agerpoint/component';
import { Options } from '@agerpoint/types';
import { APIUtils, useToasts } from '@agerpoint/utilities';

import { CreateProjectModal } from '../create-project-modal/create-project-modal';

interface AddCapturesToProjectsModalProps {
  open: boolean;
  handleCloseDialog: () => void;
  captures: Capture[];
  customers: Customer[];
  users?: User[];
  updatedCallback?: () => void;
}

export const AddCapturesToProjectsModal = ({
  open,
  handleCloseDialog,
  captures,
  customers,
  users,
  updatedCallback,
}: AddCapturesToProjectsModalProps) => {
  const toasts = useToasts();

  const addCaptureToProjectMutation = APIClient.useAddCaptureToProject({});

  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
    },
  });

  const [selectedProjects, setSelectedProjects] = useState<Options[]>([]);

  const [showNewProjectModal, setShowNewProjectModal] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [projectNameSearch, setProjectNameSearch] = useState<string>('');

  useEffect(() => {
    if (open) {
      setSelectedProjects([]);
      setProjectNameSearch('');
    }
  }, [open]);

  const projectOptions: Options[] = useMemo(() => {
    const p =
      projectsQuery.data?.map(
        (p) =>
          ({
            name: p.name,
            value: p.uuid,
          } as Options)
      ) ?? [];

    p.sort((a, b) =>
      compare(a.name.toString().toLowerCase(), b.name.toString().toLowerCase())
    );

    if (projectNameSearch.trim().length > 0) {
      return p.filter((p) => {
        return p.name
          .toString()
          .toLowerCase()
          .includes(projectNameSearch.trim().toLowerCase());
      });
    }

    return p;
  }, [projectsQuery.data, projectNameSearch]);

  if (captures.length === 0) {
    return null;
  }

  const addCaptures = async () => {
    setLoading(true);
    let allGood = true;
    let iterator = 1;
    for (let i = 0; i < captures.length; i++) {
      const c = captures[i];
      if (!c.id) {
        continue;
      }
      for (let j = 0; j < selectedProjects.length; j++) {
        const p = selectedProjects[j];
        try {
          setLoadingMessage(
            `(${iterator}/${selectedProjects.length * captures.length})`
          );

          await addCaptureToProjectMutation.mutateAsync({
            uuid: p.value.toString(),
            captureId: c.id,
          });

          iterator += 1;
        } catch (e) {
          allGood = false;
          console.error(e);
        }
      }
    }

    updatedCallback?.();
    handleCloseDialog();

    if (allGood) {
      toasts.add(
        toasts.prepare.entityUpdated('projects', 'Capture added to projects.')
      );
    } else {
      toasts.add(
        toasts.prepare.error('Failed to add capture to all selected projects!')
      );
    }
    setLoading(false);
    setSelectedProjects([]);
    setLoadingMessage('');
  };

  return (
    <>
      <DialogModal
        open={open && !showNewProjectModal}
        handleCloseDialog={() => {
          if (loading) {
            return;
          }
          handleCloseDialog();
        }}
        size="small"
        title="Add To Projects"
        testId="add-captures-to-projects-modal"
      >
        <div className="relative w-full p-1">
          <div className="w-full">
            <Input.Text.Single
              id="search-projects"
              value={projectNameSearch}
              placeholder="Search by Name"
              placeholderIcon={Input.placeholderIcons.search}
              setValue={setProjectNameSearch}
            />
          </div>
          {loading && (
            <div
              className={`absolute top-0 bottom-0 left-0 right-0 bg-white
        opacity-75 flex flex-col gap-2 items-center justify-center`}
            >
              <FontAwesomeIcon icon={faCircleNotch} className="w-8 h-8" spin />
              <p>{` Loading ${loadingMessage}`}</p>
            </div>
          )}
          <div className="pt-3">
            <div className="p-3 h-64 overflow-y-auto border rounded-md">
              <MultiSelect
                options={projectOptions}
                selectedOptions={selectedProjects}
                onChange={(selected) => {
                  if (loading) {
                    return;
                  }
                  setSelectedProjects(selected);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between pt-3 gap-x-1">
            <PrimaryButton
              data-testid="create-new-project"
              label="Create New Project"
              onClicked={() => {
                if (loading) {
                  return;
                }
                setShowNewProjectModal(true);
              }}
              disabled={loading}
            />
            <PrimaryButton
              data-testid="add-to-projects"
              label="Add"
              onClicked={addCaptures}
              disabled={loading || selectedProjects.length === 0}
            />
          </div>
        </div>
      </DialogModal>
      <CreateProjectModal
        open={showNewProjectModal}
        handleCloseDialog={() => {
          setShowNewProjectModal(false);
        }}
        organizations={customers}
        users={users}
        onProjectCreated={() => {
          projectsQuery.refetch();
          setShowNewProjectModal(false);
        }}
      />
    </>
  );
};
