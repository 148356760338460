import EventEmitter from 'events';

import {
  MarkerClassNames2d,
  SVG_PATH_LOOKUP,
  SVG_PATH_NAMES,
  SVG_PATH_SIZE_LOOKUP,
} from '@agerpoint/types';

export class CustomMarker extends EventEmitter {
  private eventId: string;
  private fill: string;
  private clickable: boolean;
  private outlineColor: string;
  private isHighlighted = false;
  private div: HTMLDivElement;
  private path: SVGPathElement | undefined = undefined;
  private customPathString: SVG_PATH_NAMES;

  constructor(
    eventId: string,
    customPathString: SVG_PATH_NAMES,
    fill?: string,
    clickable?: boolean
  ) {
    super();
    this.eventId = eventId;
    this.customPathString = customPathString || SVG_PATH_NAMES.LOCATION_MARKER;
    this.fill = fill || '#339BC0';
    this.outlineColor = this.getLighterColor(this.fill, 0.3);
    this.clickable = clickable || false;
    this.div = this.createMarker();
  }

  private createMarker(): HTMLDivElement {
    const size = SVG_PATH_SIZE_LOOKUP[this.customPathString];
    const xlmns = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(xlmns, 'svg');
    svg.style.height = `${size}px`;
    svg.style.overflow = 'visible';
    svg.setAttributeNS(null, 'viewBox', '0 0 384 512');

    this.path = document.createElementNS(xlmns, 'path');
    this.path.setAttribute('d', SVG_PATH_LOOKUP[this.customPathString]);
    this.path.style.fill = this.fill;
    this.path.setAttribute(
      'class',
      `${MarkerClassNames2d.CustomMarkerIcon} fill`
    );
    this.path.setAttribute('stroke', this.outlineColor);
    this.path.setAttribute('stroke-width', '20');
    this.path.setAttribute('stroke-linejoin', 'arcs');

    svg.appendChild(this.path);
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = '-1000px';
    div.style.left = '-1000px';
    div.style.pointerEvents = this.clickable ? 'auto' : 'none';
    div.style.cursor = this.clickable ? 'pointer' : 'default';

    div.addEventListener('mousedown', (event) => {
      event.stopPropagation();
      // left click only
      if (event.button !== 0) {
        return;
      }
      this.emit('mousedown', this.eventId, event);
      this.handleDocumentMouseUp();
    });
    div.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    if (this.clickable) {
      div.addEventListener('mouseover', () => {
        div.style.cursor = 'pointer';
      });
      div.addEventListener('mouseout', () => {
        div.style.cursor = 'default';
      });
    }

    div.appendChild(svg);
    return div;
  }

  public getElement(): HTMLDivElement {
    return this.div;
  }

  public getIsHighlighted(): boolean {
    return this.isHighlighted;
  }

  private getLighterColor(hex: string, percent: number): string {
    const f = parseInt(hex.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00ff;
    const B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }

  public highlight(): void {
    this.isHighlighted = true;
    this.path?.setAttribute('stroke', '#ffffff'); // Change outline to white
  }

  public unHighlight(): void {
    this.isHighlighted = false;
    this.path?.setAttribute('stroke', this.outlineColor); // Revert to the lighter color outline
  }

  private handleDocumentMouseUp() {
    const onMouseUp = (event: MouseEvent) => {
      this.emit('mouseup', this.eventId);
      document.removeEventListener('mouseup', onMouseUp); // Stop listening after the event occurs
    };

    document.addEventListener('mouseup', onMouseUp);
  }

  setVisibility(visible: boolean) {
    this.div.style.display = visible ? 'block' : 'none';
  }
}

export default CustomMarker;
