import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseQueryResult } from '@tanstack/react-query';
import { useCapturesViewerContext } from 'libs/feature/src/captures-viewer/captures-viewer-context';
import { AnalyticStatusIcon } from 'libs/feature/src/captures-viewer/captures-viewer-sidebar3/subcomponents/analytic-status-icon';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { AnalyticRequestStatus } from '@agerpoint/types';

import { CVS3ObjectsList } from '../../objects-list/cvs3-objects-list';
import { CVS3AnalyticsRequestListFilter } from '../cvs3-analytics-requests-list';

interface CVS3AnalyticsRequestCardProps {
  limited: boolean;
  analyticRequestWithCaptureObjectsAndJobsQuery: UseQueryResult<
    {
      analyticRequest: APIModels.AnalyticRequest;
      captureObjects: APIModels.CaptureObject[];
      captureJobs: APIModels.CaptureJob[];
      captureAttributes: APIModels.CaptureCustomAttribute[];
      captureVideos: APIModels.CaptureVideo[];
    },
    Error
  >;
  filter?: CVS3AnalyticsRequestListFilter;
}

export const CVS3AnalyticsRequestCard = ({
  analyticRequestWithCaptureObjectsAndJobsQuery: query,
  limited,
  filter,
}: CVS3AnalyticsRequestCardProps) => {
  const limitedTo = useMemo(() => 3, []);

  const navigate = useNavigate();
  const location = useLocation();

  const { captureId } = useParams();
  const { selectedCaptureJob } = useCapturesViewerContext();

  const [displayingSelectedCaptureJob, setDisplayingSelectedCaptureJob] =
    useState(false);
  const [canDisplayAnalyticsOutputs, setCanDisplayAnalyticsOutputs] =
    useState(false);

  useEffect(() => {
    const selectedJobIdIsInQuery = query.data?.captureJobs.some((job) => {
      return job.id === selectedCaptureJob?.id;
    });
    if (selectedJobIdIsInQuery) {
      setDisplayingSelectedCaptureJob(true);
    } else {
      setDisplayingSelectedCaptureJob(false);
    }
  }, [selectedCaptureJob, query.data]);

  useEffect(() => {
    // analytic must be complete
    // and only one completed capture job
    const analyticRequestComplete =
      query.data?.analyticRequest?.status === AnalyticRequestStatus.COMPLETE;
    const filteredCaptureJobs = [...(query.data?.captureJobs ?? [])]?.filter(
      (job) => job.status === 'Completed' || job.status === ''
    );

    const hasCaptureJobs = filteredCaptureJobs?.length || NaN > 0;
    const hasCaptureAttributes =
      query.data?.captureAttributes?.filter((ca) => ca.validated).length ||
      NaN > 0;
    const hasCaptureVideos =
      query.data?.captureVideos?.filter((cv) => cv.validated).length || NaN > 0;

    const hasOne = hasCaptureJobs || hasCaptureAttributes || hasCaptureVideos;
    const canDisplay = analyticRequestComplete && hasOne;

    setCanDisplayAnalyticsOutputs(Boolean(canDisplay));
  }, [query.data]);

  const onClick = useCallback(() => {
    if (query.data?.analyticRequest === undefined) {
      return;
    }

    if (location.pathname.includes('analytics-outputs')) {
      navigate(
        `/captures/${captureId}/analytics-outputs/${query.data.analyticRequest.id}`
      );
    } else {
      navigate(
        `/captures/${captureId}/analytics-output/${query.data.analyticRequest.id}`
      );
    }
  }, [query.data, navigate, captureId, location.pathname]);

  if (query.isLoading) {
    return (
      <div className="w-full px-2 py-1">
        <div className="w-full flex bg-gray-50 border border-gray-300 shadow rounded h-9 items-center justify-center overflow-visible">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-2 py-1">
      <div
        className={`relative w-full flex flex-col bg-gray-50 border border-gray-300 shadow rounded transition-colors ${
          canDisplayAnalyticsOutputs ? 'hover:bg-gray-100 cursor-pointer' : ''
        } ${displayingSelectedCaptureJob ? 'border-green' : ''}`}
        onClick={canDisplayAnalyticsOutputs ? onClick : undefined}
      >
        <div className="absolute -top-2 -left-1 z-40">
          {canDisplayAnalyticsOutputs ? null : (
            <AnalyticStatusIcon
              status={
                !canDisplayAnalyticsOutputs &&
                query.data?.analyticRequest?.status ===
                  AnalyticRequestStatus.COMPLETE
                  ? AnalyticRequestStatus.UNKNOWN
                  : (query.data?.analyticRequest
                      ?.status as AnalyticRequestStatus)
              }
              spin={
                query.data?.analyticRequest?.status ===
                AnalyticRequestStatus.PROCESSING
              }
            />
          )}
        </div>

        <div
          className={`w-full px-3 py-2 flex flex-row justify-between items-center text-sm gap-2`}
        >
          <span className="truncate">{`${
            query.data?.analyticRequest?.customerAnalytic?.analytic
              ?.analyticName || ''
          }`}</span>
          <span className="text-xs text-gray-500 whitespace-nowrap">
            {`${formatDateAndTime(
              query.data?.analyticRequest?.createDatetime
            )}`}
          </span>
        </div>
        {canDisplayAnalyticsOutputs ? (
          <>
            <div className="flex flex-col gap-1">
              {query.data?.captureObjects
                ?.slice(0, limited ? limitedTo : undefined)
                ?.filter((captureObject) => {
                  if (filter === undefined) {
                    return true;
                  }

                  const data = cloneDeep(captureObject);

                  if (filter?.objectName !== '') {
                    const objectName = data.name;

                    const matches = objectName
                      ?.toLowerCase()
                      .includes(filter.objectName.toLowerCase().trim());

                    if (!matches) {
                      return false;
                    }
                  }

                  if (filter?.attributeNames !== '') {
                    if (
                      data.captureObjectCustomAttributes === undefined ||
                      data.captureObjectCustomAttributes === null
                    ) {
                      return false;
                    }
                    const attributeNames = filter.attributeNames
                      .split(',')
                      .map((a) => a.trim())
                      .filter((a) => a !== '');

                    const matches = data.captureObjectCustomAttributes?.some(
                      (a) => {
                        const attrName =
                          a.attributeDisplayName ?? a.attributeName;

                        return attributeNames.some((attributeName) =>
                          attrName
                            ?.toLowerCase()
                            .includes(attributeName.toLowerCase().trim())
                        );
                      }
                    );

                    if (!matches) {
                      return false;
                    }
                  }

                  return true;
                })
                ?.map((captureObject, index) => (
                  <CVS3ObjectsList
                    key={index}
                    index={index}
                    captureObject={captureObject}
                    limited={limited}
                    filter={filter}
                  />
                ))}
            </div>

            {limited &&
              (query.data?.captureObjects?.length ?? 0) > limitedTo &&
              limitedTo > 0 && (
                <div className="flex flex-row px-4 py-1 text-xs text-gray-500 justify-start">
                  <span>
                    {(query.data?.captureObjects?.length ?? 0) - limitedTo} more
                    result(s)...
                  </span>
                </div>
              )}
          </>
        ) : null}
      </div>
    </div>
  );
};
