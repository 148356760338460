import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ErrorBar,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  Collection,
  useGetCollectionSummary,
  useGetMorphologiesByCollectionId,
  useGetMorphologyHistogramByCollectionId,
} from '@agerpoint/api';
import { ComboboxSelect } from '@agerpoint/component';
import { AppSidebar, FusionFilters } from '@agerpoint/feature';
import { BoxPlotData, ComboBoxOption } from '@agerpoint/types';
import {
  createChartDataAndSort,
  createComboBoxOptions,
  getTitleCase,
  groupByAttribute,
} from '@agerpoint/utilities';

interface Summary {
  availibleCategorizedAttributes: string[];
  availibleCategorizedCustomAttributes: string[];
  availibleAttributes: string[];
}

export const FusionPage = () => {
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [selectedLayer, setSelectedLayer] = useState<ComboBoxOption>({
    name: '',
    id: NaN,
  });
  const [selectedAttribute, setSelectedAttribute] = useState<ComboBoxOption>({
    name: '',
    id: NaN,
  });
  const [selectedSortBy, setSelectedSortBy] = useState<ComboBoxOption>({
    name: '',
    id: NaN,
  });

  const [breakType] = useState<ComboBoxOption[]>([
    { name: 'Equal Interval', id: 'equalInterval' },
    { name: 'Equal Counts', id: 'equalCounts' },
    { name: 'Natural Breaks', id: 'naturalBreaks' },
  ]);
  const [selectedBreakType, setSelectedBreakType] = useState<ComboBoxOption>({
    name: 'Equal Interval',
    id: 'equalInterval',
  });
  const [currentSelectedLayerId, setCurrentSelectedLayerId] =
    useState<number>(NaN);
  const [availableAttributes, setAvailableAttributes] = useState<
    ComboBoxOption[]
  >([]);
  const [availableSortBy, setAvailableSortBy] = useState<ComboBoxOption[]>([]);

  const [chartData, setChartData] = useState<
    { name: number; [key: string]: number }[]
  >([]);
  const [boxPlotData, setBoxPlotData] = useState<BoxPlotData[]>([]);
  const [chartKey, setChartKey] = useState<string>('');
  const { data: summaryApiRes, refetch: refetchLayerSummary } =
    useGetCollectionSummary({
      id: currentSelectedLayerId,
      lazy: true,
    });
  const { data: morphologiesApiRes, refetch: refetchMorphologies } =
    useGetMorphologiesByCollectionId({
      collectionId: currentSelectedLayerId,
      lazy: true,
    });
  const { data: morphHistogramApiRes, refetch: refetchMorphHistogram } =
    useGetMorphologyHistogramByCollectionId({
      collectionId: currentSelectedLayerId,
      attributeName: selectedAttribute.id as string,
      binCount: 5,
      classificationMethod: selectedBreakType.id as string,
      lazy: true,
    });

  useEffect(() => {
    if (!summaryApiRes) return;
    const res = summaryApiRes as Summary;
    const catCustAttr = createComboBoxOptions(res?.availibleAttributes);
    const catAttr = createComboBoxOptions(res?.availibleCategorizedAttributes);
    setAvailableSortBy(catAttr);
    setSelectedSortBy(catAttr[0]);
    setAvailableAttributes(catCustAttr);
  }, [summaryApiRes]);

  useEffect(() => {
    if (!morphologiesApiRes) return;
    const res = morphologiesApiRes as Collection[];
    const key = _.camelCase(selectedAttribute.id as string);
    const data = res?.map((value: any) => {
      return {
        name: value.id,
        [key]: value[key],
      };
    });
    setChartKey(key);
    setChartData(data);
  }, [morphologiesApiRes]);

  useEffect(() => {
    if (!selectedSortBy?.id || !morphologiesApiRes) return;
    setFilterLoading(true);
    const res = morphologiesApiRes as Collection[];
    const sortByKey = _.camelCase(selectedSortBy.id as string);
    const key = _.camelCase(selectedAttribute.id as string);
    const data = createChartDataAndSort(res, sortByKey, key);
    setChartData(data);
    setFilterLoading(false);
  }, [selectedSortBy]);

  useEffect(() => {
    if (selectedAttribute.id) {
      refetchMorphologies();
      refetchMorphHistogram();
    }
  }, [selectedAttribute]);

  useEffect(() => {
    if (currentSelectedLayerId) {
      refetchLayerSummary();
    }
  }, [currentSelectedLayerId]);

  useEffect(() => {
    if (!selectedLayer?.id) {
      setCurrentSelectedLayerId(NaN);
      setChartKey('');
      setSelectedAttribute({ name: '', id: NaN });
      setChartData([]);
      setBoxPlotData([]);
      return;
    }
    if (selectedLayer?.id !== currentSelectedLayerId) {
      setCurrentSelectedLayerId(selectedLayer.id as number);
    }
  }, [selectedLayer]);

  useEffect(() => {
    if (!morphologiesApiRes || !morphHistogramApiRes || !chartKey) return;

    const dataByTreeRow = groupByAttribute(
      'rowId',
      morphologiesApiRes,
      selectedAttribute.id as string
    );
    setBoxPlotData(dataByTreeRow as any);
  }, [chartKey]);

  return (
    <AppSidebar>
      <div className="flex flex-col h-full w-full bg-gray-150 p-2">
        <div className="flex items-center">
          <FusionFilters
            selectedLayer={selectedLayer}
            setSelectedLayer={setSelectedLayer}
            availableAttributes={availableAttributes}
            selectedAttribute={selectedAttribute}
            setSelectedAttribute={setSelectedAttribute}
            filterLoading={filterLoading}
            setFilterLoading={setFilterLoading}
          />
          {/* {selectedAttribute.id ? (
            <ComboboxSelect
              className="pl-2 w-64"
              selected={selectedBreakType}
              changeFn={setSelectedBreakType}
              choices={breakType}
              label="Break Type"
            />
          ) : null} */}
          {filterLoading && (
            <div className="flex justify-center w-16">
              <FontAwesomeIcon
                className="flex text-xl text-center text-green fa-spin "
                icon={faCircleNotch}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end pt-10">
          {chartKey ? (
            <>
              <ResponsiveContainer width="75%" height={300}>
                <BarChart width={730} height={500} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey={chartKey}
                    name={getTitleCase(selectedAttribute.id as string)}
                    fill="#8884d8"
                  />
                </BarChart>
              </ResponsiveContainer>
              <ResponsiveContainer width="75%" height={300}>
                <BarChart width={730} height={250} data={boxPlotData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey={selectedAttribute.id}
                    name={getTitleCase(selectedAttribute.id as string)}
                    fill="#8884d8"
                  >
                    <ErrorBar
                      dataKey="errorY"
                      width={4}
                      strokeWidth={2}
                      stroke="green"
                      direction="y"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : null}
        </div>
      </div>
    </AppSidebar>
  );
};
