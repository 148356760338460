import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { faNoteSticky } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEmpty, extend } from 'ol/extent';
import { Point } from 'ol/geom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import {
  APIClient,
  APIModels,
  CaptureObject,
  CaptureObjectType,
  useGetCaptureObjectsByCaptureId,
} from '@agerpoint/api';
import { Input, PrimaryButton } from '@agerpoint/component';
import { AppSidebar, OpenLayerMap } from '@agerpoint/feature';
import {
  OpenLayerMapController,
  OpenMapLayer,
  defaultCaptureMapFeatureStyle,
} from '@agerpoint/types';
import { environment, getDateString } from '@agerpoint/utilities';

import '../../print.css';
import { CaptureObjectTable } from './capture-object-table';
import { Note } from './captures-report-notes';

export const CapturesReportPage = () => {
  const location = useLocation();

  const preselected = location.state.selectedCaptures ?? [];

  const [selectedCapturesForReport, setSelectedCapturesForReport] =
    useState<APIModels.Capture[]>(preselected);

  const [addNoteModeToggledOn, setAddNoteModeToggledOn] = useState(false);

  const [mapController, setMapController] = useState<OpenLayerMapController>();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (!selectedCapturesForReport?.length || !mapController) return;
    const extent = createEmpty();
    for (const capture of selectedCapturesForReport) {
      if (!capture.longitude || !capture.latitude) continue;
      const point = new Point([capture.longitude, capture.latitude]).transform(
        'EPSG:4326',
        'EPSG:3857'
      );
      extend(extent, point.getExtent());
    }

    mapController?.zoomMapToExtent?.(extent);
  }, [selectedCapturesForReport, mapController]);

  const handleRightClick = useCallback(
    (event: MouseEvent) => {
      if (addNoteModeToggledOn) {
        event.preventDefault();
        setAddNoteModeToggledOn(false);
      }
    },
    [addNoteModeToggledOn]
  );

  useEffect(() => {
    document.addEventListener('contextmenu', handleRightClick);

    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, [handleRightClick]);

  return (
    <AppSidebar>
      <div
        className={`flex flex-col h-full w-full bg-gray-150 p-2 items-center relative ${
          addNoteModeToggledOn ? 'cursor-no-drop' : ''
        }`}
        onClick={(e) => {
          if (e.type === 'contextmenu') {
            setAddNoteModeToggledOn(false);
          }
        }}
      >
        <div className="flex flex-row w-full justify-between pb-2 ">
          <div className="w-80">
            {(selectedCapturesForReport?.length ?? 0) < 2 && (
              <Input.Capture
                id="capture-select"
                value={selectedCapturesForReport?.[0]}
                setValue={(capture) => {
                  setSelectedCapturesForReport(capture ? [capture] : []);
                }}
                placeholder="Search by Capture Name"
                placeholderIcon={Input.placeholderIcons.search}
              />
            )}
          </div>
          <div className="flex flex-row gap-2 relative">
            <PrimaryButton
              label={addNoteModeToggledOn ? 'Cancel' : 'Add Note'}
              theme="primary"
              size="small"
              onClicked={() => {
                setAddNoteModeToggledOn((prev) => !prev);
              }}
              icon={<FontAwesomeIcon icon={faNoteSticky} />}
            />
            {addNoteModeToggledOn && (
              <p className="absolute -bottom-4 text-xs text-center">
                Right click to cancel
              </p>
            )}

            <PrimaryButton
              label="Print"
              theme={'primary'}
              size="small"
              onClicked={
                addNoteModeToggledOn
                  ? undefined
                  : () => {
                      handlePrint();
                    }
              }
              icon={<FontAwesomeIcon icon={faPrint} />}
              className="print:hidden"
            />
          </div>
        </div>
        <div
          className="bg-white p-4 overflow-auto "
          style={{ width: '8.3in' }}
          id="main-print-window"
          ref={componentRef}
        >
          <div className="text-lg">Agerpoint Capture Report</div>
          <div style={{ height: '0.25in' }} />
          <div>Summary</div>
          <div
            style={{
              height: '3in',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <OpenLayerMap
              id="capture-report-map"
              bingKey={environment.bing_api_key}
              controller={setMapController}
              mapLayers={{
                used: [OpenMapLayer.Hybrid],
                initial: OpenMapLayer.Hybrid,
              }}
              featureLayer={{
                data: selectedCapturesForReport,
                styles: {
                  default: defaultCaptureMapFeatureStyle,
                },
                featureGenerator: (data: any) => {
                  if (!data.longitude || !data.latitude || !data.id) {
                    return null;
                  }

                  return {
                    id: data.id,
                    longitude: data.longitude,
                    latitude: data.latitude,
                    name: data.captureName ?? undefined,
                    style: 'default',
                  };
                },
              }}
              disableInteraction={true}
              dependencies={[selectedCapturesForReport]}
            />
          </div>
          <Note
            addNoteModeToggledOn={addNoteModeToggledOn}
            setAddNoteModeToggledOn={setAddNoteModeToggledOn}
          />
          <div style={{ height: '0.25in' }} />
          <div
            style={{
              paddingLeft: '0.25in',
            }}
          >
            {selectedCapturesForReport.map((c, i) => {
              return (
                <div className="text-sm border-b flex flex-col" key={c.id}>
                  <div className="flex flex-row gap-1">
                    <span>
                      {`${i + 1}.`} {c.id} {c.captureName}
                    </span>
                    <span>{getDateString(c.scanDatetime)}</span>
                  </div>
                  <Note
                    addNoteModeToggledOn={addNoteModeToggledOn}
                    setAddNoteModeToggledOn={setAddNoteModeToggledOn}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ height: '0.25in' }} />
          <div className="page-break"></div>
          <div>Details</div>
          <div
            className="break-inside-auto"
            style={{
              paddingLeft: '0.1in',
            }}
          >
            {selectedCapturesForReport.map((c, i) => {
              return (
                <CaptureObjectRow
                  capture={c}
                  key={c.id}
                  addNoteModeToggledOn={addNoteModeToggledOn}
                  setAddNoteModeToggledOn={setAddNoteModeToggledOn}
                />
              );
            })}
          </div>
        </div>
      </div>
    </AppSidebar>
  );
};

const CaptureObjectRow = ({
  capture,
  addNoteModeToggledOn,
  setAddNoteModeToggledOn,
}: {
  capture: APIClient.Capture;
  addNoteModeToggledOn: boolean;
  setAddNoteModeToggledOn: (value: boolean) => void;
}) => {
  const [currentCaptureId, setCurrentCaptureId] = useState<number>(NaN);
  const [captureObjectGroups, setCaptureObjectGroups] = useState<
    Record<number, CaptureObject[]>
  >({});
  const [captureObjectTypes, setCaptureObjectTypes] = useState<
    Record<number, CaptureObjectType>
  >({});
  const [captureObjectTypeIds, setCaptureObjectTypeIds] = useState<number[]>(
    []
  );

  const { data: captureObjects, refetch: refetchCaptureObjects } =
    useGetCaptureObjectsByCaptureId({
      id: currentCaptureId,
      lazy: true,
    }) as unknown as {
      data: CaptureObject[];
      refetch: () => void;
    };

  useEffect(() => {
    if (currentCaptureId) {
      refetchCaptureObjects();
    }
  }, [currentCaptureId]);

  useEffect(() => {
    if (!capture || !capture.id) return;
    setCurrentCaptureId(capture.id);
  }, [capture]);

  useEffect(() => {
    // group capture objects by captureObjectType.id
    if (!captureObjects?.length) return;
    const captureObjectTypes = {} as Record<number, CaptureObjectType>;
    const groupedCaptureObjects = captureObjects.reduce((acc, co) => {
      if (!co.captureObjectType?.id) return acc;
      if (!acc[co.captureObjectType.id]) {
        acc[co.captureObjectType.id] = [];
        captureObjectTypes[co.captureObjectType.id] = co.captureObjectType;
      }
      acc[co.captureObjectType.id].push(co);
      return acc;
    }, {} as Record<number, CaptureObject[]>);
    setCaptureObjectGroups(groupedCaptureObjects);
    setCaptureObjectTypes(captureObjectTypes);
    setCaptureObjectTypeIds(Object.keys(groupedCaptureObjects).map(Number));
  }, [captureObjects]);

  return (
    <div className="text-sm">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="text-sm font-bold">
            {capture.captureName} - {getDateString(capture.scanDatetime)} - AI
            Results ({captureObjects?.length})
          </div>
          <Note
            addNoteModeToggledOn={addNoteModeToggledOn}
            setAddNoteModeToggledOn={setAddNoteModeToggledOn}
          />
        </div>
        {captureObjectTypeIds?.length && (
          <>
            {captureObjectTypeIds.map((id: number) => {
              return (
                <div key={id}>
                  <CaptureObjectTable
                    captureObjectGroup={captureObjectTypes[id]}
                    captureObjects={captureObjectGroups[id]}
                    addNoteModeToggledOn={addNoteModeToggledOn}
                    setAddNoteModeToggledOn={setAddNoteModeToggledOn}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
