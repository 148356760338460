import {
  IconDefinition,
  faAngleDown,
  faAngleUp,
  faCheck,
  faCircleInfo,
  faCircleNotch,
  faExpand,
  faFolderMagnifyingGlass,
  faGalleryThumbnails,
  faImageLandscape,
  faImagePortrait,
  faMobile,
  faSpinner,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import {
  faTrash,
  faImageLandscape as solidFaImageLandscape,
  faImagePortrait as solidFaImagePortrait,
  faMobile as solidFaMobile,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  APIClient,
  APIModels,
  useGetCaptureImageHorizontalThumbnailById,
  useGetCaptureImageThumbnailById2,
  useGetCaptureImageVerticalThumbnailById,
} from '@agerpoint/api';
import {
  Button,
  DialogModal,
  DraggableWindow,
  Gallery,
  GalleryEvents,
  GalleryOrientation,
  Input,
  PrimaryButton,
} from '@agerpoint/component';
import {
  CaptureJobTypes,
  EventBusNames,
  OrientationOptions,
  UserClaims,
} from '@agerpoint/types';
import {
  blobCache,
  eventBus,
  hasClaims,
  isGaussianJob,
  useGlobalStore,
  useItemSelection,
} from '@agerpoint/utilities';

import { CaptureViewerImageSelectionPipeline } from '../capture-viewer-gallery/capture-viewer-pipeline';
import { useCapturesViewerContext } from '../captures-viewer-context';
import { useCapturesViewerQueries } from '../captures-viewer-queries';
import { CapturesViewer2ExpandedImage } from './subcomponents/captures-viewer-gallery2-expanded-image';

export const CapturesViewerGallery2 = () => {
  const [visible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [qaqcMode, setQAQCMode] = useState({
    enabled: false,
    showSelectedOnly: false,
  });
  const [expandedImage, setExpandedImage] = useState<APIModels.CaptureImage>();
  const [highlightedImage, setHighlightedImage] =
    useState<APIModels.CaptureImage>();
  const { captureId } = useParams();

  const [showDetailModal, setShowDetailModal] = useState(false);

  const [isUsable, setIsUsable] = useState(false);

  const [imageOrientation, setImageOrientation] = useState<OrientationOptions>(
    OrientationOptions.Original
  );
  const [lowResJobId, setLowResJobId] = useState<number>();

  const { bottomBar, sidebar, user } = useGlobalStore();

  const { selectedCaptureJob, viewerController } = useCapturesViewerContext();

  const [galleryImages, setGalleryImages] =
    useState<APIModels.CaptureImage[]>();
  const [galleryImagesLoading, setGalleryImagesLoading] = useState(false);

  useEffect(() => {
    bottomBar.actions.setIsOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (!selectedCaptureJob) {
      setVisible(false);
      setExpanded(false);
      setIsUsable(false);
      setQAQCMode({
        enabled: false,
        showSelectedOnly: false,
      });
    } else {
      setVisible(true);
      setExpanded(false);
      setIsUsable(true);
      setQAQCMode({
        enabled: false,
        showSelectedOnly: false,
      });
    }
  }, [selectedCaptureJob?.id]);

  const {
    captureQuery,
    captureJobImagesQuery,
    captureJobImagePutMutation,
    captureJobImagesForLowResJobIdQuery,
  } = useCapturesViewerQueries({
    selectedCaptureJob,
    lowResJobId,
  });

  useEffect(() => {
    if (!isGaussianJob(selectedCaptureJob)) return;

    const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
      return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
    });
    if (!lowResJob || !lowResJob?.id || !captureQuery?.data?.id) return;
    const lowResJobId = lowResJob.id;
    viewerController?.threeController?.setCaptureMetadata(captureQuery?.data);
    setLowResJobId(lowResJobId);
  }, [captureQuery?.data, selectedCaptureJob]);

  useEffect(() => {
    if (isGaussianJob(selectedCaptureJob)) {
      setGalleryImages(captureJobImagesForLowResJobIdQuery.data);
    } else {
      setGalleryImages(captureJobImagesQuery.data);
    }
  }, [
    selectedCaptureJob,
    captureJobImagesQuery.data,
    captureJobImagesForLowResJobIdQuery.data,
  ]);

  useEffect(() => {
    if (!selectedCaptureJob) {
      return;
    }
    if (isGaussianJob(selectedCaptureJob)) {
      setGalleryImagesLoading(captureJobImagesForLowResJobIdQuery.isLoading);
    } else {
      setGalleryImagesLoading(captureJobImagesQuery.isLoading);
    }
  }, [
    selectedCaptureJob,
    captureJobImagesQuery.isLoading,
    captureJobImagesForLowResJobIdQuery.isLoading,
  ]);

  const imageSelection = useItemSelection<number, APIModels.CaptureImage>({
    dependencies: [captureJobImagesQuery?.data?.length],
    idField: 'id',
    items: captureJobImagesQuery?.data ?? [],
  });

  useEffect(() => {
    if (captureJobImagesQuery?.data) {
      const selectedImages = captureJobImagesQuery.data.filter(
        (image) => image.isFavorite
      );

      if (selectedImages.length === 0) {
        return;
      }

      const selectionItems = selectedImages.map((image) => ({
        key: image.id as number,
        value: image,
      }));

      imageSelection.addSelectionList(selectionItems);
    }
  }, [captureJobImagesQuery?.data?.length]);

  const buildButton = useCallback(
    (
      title: string,
      orientation: OrientationOptions,
      onIcon: IconDefinition,
      offIcon: IconDefinition
    ) => {
      return (
        <FontAwesomeIcon
          onClick={() => {
            setImageOrientation(orientation);
          }}
          title={title}
          icon={imageOrientation === orientation ? onIcon : offIcon}
          className={`text-2xl hover:text-gray-500 rounded w-8 cursor-pointer pb-1
  ${imageOrientation === orientation ? 'text-green' : ''} `}
        />
      );
    },
    [imageOrientation, setImageOrientation]
  );

  useEffect(() => {
    // only for pointclouds
    if (!selectedCaptureJob) {
      return;
    }
    if (isGaussianJob(selectedCaptureJob)) {
      return;
    }
    viewerController?.potreeController?.bindCameraPositionOnClick((e) => {
      const index = captureJobImagesQuery.data?.findIndex(
        (i) => i.id === Number(e.detail.id)
      );

      if (index === -1 || index === undefined) {
        return;
      }

      window.dispatchEvent(
        new CustomEvent(GalleryEvents.scrollTo, { detail: { index } })
      );
      setHighlightedImage(captureJobImagesQuery.data?.[index]);
    });

    return () => {
      viewerController?.potreeController?.bindCameraPositionOnClick(undefined);
    };
  }, [
    selectedCaptureJob?.id,
    viewerController?.potreeController?.info.viewerReady,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    // only for gaussians
    if (!selectedCaptureJob) {
      return;
    }
    if (!isGaussianJob(selectedCaptureJob)) {
      return;
    }
    viewerController?.threeController?.bindCameraPositionOnClick((e) => {
      // remember that gaussian is using the low res job id and images
      const index = captureJobImagesForLowResJobIdQuery.data?.findIndex(
        (i) => i.id === Number(e.detail.id)
      );

      if (index === -1 || index === undefined) {
        return;
      }

      window.dispatchEvent(
        new CustomEvent(GalleryEvents.scrollTo, { detail: { index } })
      );
      setHighlightedImage(captureJobImagesForLowResJobIdQuery.data?.[index]);
    });

    return () => {
      viewerController?.threeController?.bindCameraPositionOnClick(undefined);
    };
  }, [
    selectedCaptureJob?.id,
    viewerController?.threeController?.info.viewerReady,
    captureJobImagesForLowResJobIdQuery.data,
  ]);

  return (
    <>
      <div className="w-full h-full">
        {isUsable && (
          <div
            className={`absolute bottom-4 right-4 bg-white p-2 z-50 rounded cursor-pointer ${
              visible ? 'opacity-0' : 'opacity-100'
            } transition-opacity duration-300 leading-none`}
            onClick={() => setVisible(true)}
            title="Open Bottom Panel"
          >
            <FontAwesomeIcon icon={faGalleryThumbnails} />
          </div>
        )}

        <div
          className={`fixed bottom-0 right-0 flex z-40 h-8 transition-all duration-300 ${
            sidebar.isOpen ? 'left-sidebar' : 'left-0'
          }`}
        >
          <div className="absolute flex flex-col left-0 w-full bottom-0 border-gray-300 border-t">
            <div
              className={`shadow-xl
          transition-height
          duration-300
          border-r
          border-gray-300
          w-full
          overflow-y-auto
          bg-white
          ${expanded ? 'h-screen' : visible ? 'h-44' : 'h-0'}`}
            >
              <div className="flex flex-row w-full h-full justify-end overflow-hidden">
                {galleryImagesLoading ? (
                  <div className="w-full h-full flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      spin
                      className="w-10 h-10"
                    />
                  </div>
                ) : (galleryImages?.length ?? 0) === 0 ? (
                  <div className="w-full h-full flex justify-center items-center text-gray">
                    No Images
                  </div>
                ) : (
                  <Gallery
                    items={
                      galleryImages
                        ?.filter((image) => {
                          if (
                            qaqcMode.enabled &&
                            qaqcMode.showSelectedOnly &&
                            image.id
                          ) {
                            return imageSelection.isSelected(image.id);
                          }
                          return true;
                        })
                        ?.map((image: APIModels.CaptureImage) => {
                          return (
                            <CaptureGalleryImage2
                              key={image.id}
                              image={image}
                              imageOrientation={imageOrientation}
                              setExpandedImage={setExpandedImage}
                              imageSelection={imageSelection}
                              qaqcModeEnabled={qaqcMode.enabled}
                              captureJobImagePutMutation={
                                captureJobImagePutMutation
                              }
                              isHighlighted={highlightedImage?.id === image.id}
                              setHighlightedImage={setHighlightedImage}
                            />
                          );
                        }) ?? []
                    }
                    orientation={
                      expanded
                        ? GalleryOrientation.Vertical
                        : GalleryOrientation.Horizontal
                    }
                  />
                )}

                <div
                  className={`flex w-8 flex-none flex-col items-center justify-start border-l border-gray-500 bg-white pt-2`}
                >
                  <FontAwesomeIcon
                    onClick={() => {
                      setExpanded(false);
                      setVisible(false);
                      setQAQCMode({
                        enabled: false,
                        showSelectedOnly: false,
                      });
                    }}
                    title="Close Bottom Panel"
                    icon={faTimes}
                    className={`text-2xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-1`}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      if (expanded) {
                        setQAQCMode({
                          enabled: false,
                          showSelectedOnly: false,
                        });
                      }
                      setExpanded((prev) => !prev);
                    }}
                    title="Expand Bottom Panel"
                    icon={expanded ? faAngleDown : faAngleUp}
                    className={`text-2xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-1`}
                  />
                  {hasClaims(
                    [UserClaims.AgerAdmin],
                    (user?.cloudClaims || []) as UserClaims[]
                  ) && (
                    <>
                      <FontAwesomeIcon
                        onClick={() => {
                          if (!expanded) {
                            setExpanded(true);
                          }

                          setQAQCMode((prev) => ({
                            enabled: !prev.enabled,
                            showSelectedOnly: false,
                          }));
                        }}
                        title="QAQC Mode Toggle"
                        icon={faFolderMagnifyingGlass}
                        className={`text-xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-2 `}
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          setShowDetailModal(
                            (showDetailModal: boolean) => !showDetailModal
                          );
                        }}
                        title="Image Selection Details"
                        icon={faCircleInfo}
                        className={`text-xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-1 `}
                      />
                      {expanded && (
                        <div className="flex flex-col items-center py-6 bg-white">
                          {buildButton(
                            'Original Orientation',
                            OrientationOptions.Original,
                            solidFaMobile,
                            faMobile
                          )}
                          {buildButton(
                            'Landscape Orientation',
                            OrientationOptions.Landscape,
                            solidFaImageLandscape,
                            faImageLandscape
                          )}
                          {buildButton(
                            'Portrait Orientation',
                            OrientationOptions.Portrait,
                            solidFaImagePortrait,
                            faImagePortrait
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DraggableWindow
        title="Capture Image Selection"
        show={qaqcMode.enabled}
        height={224}
        width={450}
        toggleShow={() => {
          setQAQCMode({
            enabled: false,
            showSelectedOnly: false,
          });
        }}
        leftInitial={324}
        topInitial={window.innerHeight - 229}
      >
        <CaptureViewerImageSelectionTable2
          imageSelection={imageSelection}
          qaqcModeShowSelectedOnly={qaqcMode.showSelectedOnly}
          setQAQCModeShowSelectedOnly={() => {
            setQAQCMode((prev) => ({
              enabled: true,
              showSelectedOnly: !prev.showSelectedOnly,
            }));
          }}
          captureJobImagePutMutation={captureJobImagePutMutation}
        />
      </DraggableWindow>
      <DialogModal
        open={showDetailModal}
        title="Image Selection Details And Processing"
        handleCloseDialog={() => setShowDetailModal(false)}
        size="large"
      >
        <div className="w-full p-1">
          <h5 className="text-xl font-bold pt-6">Processing Details</h5>
          <CaptureViewerImageSelectionPipeline captureId={Number(captureId)} />
          <h5 className="text-xl font-bold pt-6">Image Selection</h5>

          <CaptureViewerImageSelectionTable2ReadOnly
            selectedCaptureImages={imageSelection.getSelectionArray()}
          />
        </div>
      </DialogModal>
      <CapturesViewer2ExpandedImage
        expandedImage={expandedImage}
        setExpandedImage={setExpandedImage}
        orientation={imageOrientation}
      />
    </>
  );
};

export interface CaptureGalleryImage2Props {
  image: APIModels.CaptureImage;
  setExpandedImage: (image: APIModels.CaptureImage) => void;
  imageOrientation: OrientationOptions;
  imageSelection?: ReturnType<
    typeof useItemSelection<number, APIModels.CaptureImage>
  >;
  qaqcModeEnabled?: boolean;
  captureJobImagePutMutation?: ReturnType<
    typeof APIClient.usePutCaptureImageById
  >;
  isHighlighted?: boolean;
  setHighlightedImage?: (image: APIModels.CaptureImage) => void;
}

const CaptureGalleryImage2 = ({
  image,
  setExpandedImage,
  imageOrientation,
  imageSelection,
  qaqcModeEnabled,
  captureJobImagePutMutation,
  isHighlighted,
  setHighlightedImage,
}: CaptureGalleryImage2Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [setIsVisible]);

  const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState<string>();
  const [body, setBody] = useState<any>({
    id: image.id as number,
    fit: 'fit-in',
    crop: '%20',
    size: `${400}x${400}`,
    verticalAlign: '%20',
    horizontalAlign: '%20',
    filters: '%20',
    lazy: true,
  });

  const {
    data: thumbnailDataOriginalOrientation,
    refetch: refetchOriginalOrientation,
    cancel: cancelOriginalOrientation,
    loading: loadingThumbnailDataOriginalOrientation,
  } = useGetCaptureImageThumbnailById2(body) as any;

  const {
    data: thumbnailDataLandscapeOrientation,
    refetch: refetchLandscapeOrientation,
    cancel: cancelLandscapeOrientation,
    loading: loadingThumbnailDataLandscapeOrientation,
  } = useGetCaptureImageHorizontalThumbnailById(body) as any;

  const {
    data: thumbnailDataPortraitOrientation,
    refetch: refetchPortraitOrientation,
    cancel: cancelPortraitOrientation,
    loading: loadingThumbnailDataPortraitOrientation,
  } = useGetCaptureImageVerticalThumbnailById(body) as any;

  useEffect(() => {
    if (!isVisible) {
      cancelOriginalOrientation();
      cancelLandscapeOrientation();
      cancelPortraitOrientation();
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    setThumbnailBlobUrl(undefined);
    const key = `capture-image-${image.id}-orientation-${imageOrientation}`;

    if (blobCache.has(key)) {
      setThumbnailBlobUrl(blobCache.get(key)?.url);
    } else {
      switch (imageOrientation) {
        case OrientationOptions.Landscape:
          refetchLandscapeOrientation();
          break;
        case OrientationOptions.Portrait:
          refetchPortraitOrientation();
          break;
        default:
          refetchOriginalOrientation();
          break;
      }
    }
  }, [image, imageOrientation, isVisible]);

  useEffect(() => {
    const doAsync = async () => {
      let blob;
      try {
        switch (imageOrientation) {
          case OrientationOptions.Landscape:
            blob = await thumbnailDataLandscapeOrientation.blob();
            break;
          case OrientationOptions.Portrait:
            blob = await thumbnailDataPortraitOrientation.blob();
            break;
          default:
            blob = await thumbnailDataOriginalOrientation.blob();
            break;
        }
      } catch (e) {
        console.error(e);
        return;
      }

      const url = URL.createObjectURL(blob);
      blobCache.set(
        `capture-image-${image.id}-orientation-${imageOrientation}`,
        { blob, url }
      );
      setThumbnailBlobUrl(url);
    };

    if (!isVisible) return;
    if (
      !thumbnailDataOriginalOrientation?.blob &&
      imageOrientation === OrientationOptions.Original
    ) {
      return;
    }
    if (
      !thumbnailDataLandscapeOrientation?.blob &&
      imageOrientation === OrientationOptions.Landscape
    ) {
      return;
    }
    if (
      !thumbnailDataPortraitOrientation?.blob &&
      imageOrientation === OrientationOptions.Portrait
    ) {
      return;
    }
    const key = `capture-image-${image.id}-orientation-${imageOrientation}`;

    if (blobCache.has(key)) {
      return;
    }
    try {
      doAsync();
    } catch (e) {
      console.error(e);
    }
  }, [
    thumbnailDataOriginalOrientation,
    thumbnailDataPortraitOrientation,
    thumbnailDataLandscapeOrientation,
    imageOrientation,
    isVisible,
  ]);

  const loading = useMemo(() => {
    return (
      loadingThumbnailDataLandscapeOrientation ||
      loadingThumbnailDataOriginalOrientation ||
      loadingThumbnailDataPortraitOrientation
    );
  }, [
    loadingThumbnailDataLandscapeOrientation,
    loadingThumbnailDataOriginalOrientation,
    loadingThumbnailDataPortraitOrientation,
  ]);

  const isSelected = useMemo(
    () =>
      image.id !== undefined ? imageSelection?.isSelected(image.id) : false,
    [imageSelection, image.id]
  );

  const onClick = useCallback(() => {
    if (image.id) {
      eventBus.dispatch(EventBusNames.ImageCarouselImageClicked, {
        detail: { id: image.id },
      });
    }

    if (!qaqcModeEnabled) {
      setHighlightedImage?.(image);
      return;
    }
    if (image.id === undefined) {
      return;
    }
    if (isSelected) {
      captureJobImagePutMutation?.mutate({
        id: image.id,
        data: {
          ...image,
          isFavorite: false,
          note: '',
        },
      });
    } else {
      captureJobImagePutMutation?.mutate({
        id: image.id,
        data: {
          ...image,
          isFavorite: true,
        },
      });
    }

    imageSelection?.toggleSelection(image.id, image);
  }, [
    image,
    imageSelection,
    qaqcModeEnabled,
    isSelected,
    captureJobImagePutMutation,
    setHighlightedImage,
  ]);

  return (
    <div
      className="w-full h-full flex justify-center items-center relative"
      onClick={onClick}
      ref={targetRef}
    >
      {isSelected && qaqcModeEnabled && (
        <div
          className={`absolute -top-1 -left-1 w-5 h-5 bg-selectBlue
          rounded-full flex justify-center items-center z-40 shadow`}
        >
          <FontAwesomeIcon icon={faCheck} className="text-xs text-white" />
        </div>
      )}
      <div
        className={`w-full h-full flex justify-center items-center rounded-lg overflow-hidden
      shadow relative border transition-transform transform ${
        qaqcModeEnabled ? 'hover:border-blue' : ''
      }
      ${
        isHighlighted && !qaqcModeEnabled
          ? 'ring-selectBlue ring-2'
          : 'border-gray-800'
      }
      ${
        isSelected && qaqcModeEnabled
          ? 'scale-95 ring-selectBlue ring-2'
          : 'scale-100'
      }
      `}
        style={{
          cursor: qaqcModeEnabled ? 'cell' : undefined,
        }}
      >
        {!thumbnailBlobUrl ? (
          loading ? (
            <FontAwesomeIcon icon={faSpinner} spin className="text-gray-800" />
          ) : null
        ) : (
          <img
            loading="lazy"
            src={thumbnailBlobUrl}
            alt={`imageId ${image.id}`}
            className="h-full w-full object-cover"
          />
        )}
        {isSelected && qaqcModeEnabled && (
          <div className="absolute inset-0 bg-white opacity-25" />
        )}
        <div className="absolute bottom-0 left-0 right-0 flex flex-row justify-between gap-1">
          <div
            className={`border-t border-r border-gray-800 rounded-tr-lg
           bg-white px-1 py-0.5 text-xs`}
          >
            {/* localIndex is set behind TypeScript's back in captures-viewer-queries */}
            {(image as any).localIndex}
          </div>
          {image.note && qaqcModeEnabled && (
            <div
              className="border-l border-t border-gray-800
            rounded-tl-lg bg-white px-1 py-0.5 text-xs truncate"
            >
              {image.note}
            </div>
          )}
        </div>
        <div
          className={`absolute top-0 right-0 border-b border-l border-gray-800 rounded-bl-lg
        px-1 py-0.5 text-xs  ${
          thumbnailBlobUrl
            ? 'cursor-pointer bg-white hover:bg-gray-200'
            : 'cursor-wait bg-gray-300'
        }`}
          onClick={() => {
            if (!thumbnailBlobUrl) {
              return;
            }
            setExpandedImage(image);
          }}
        >
          <FontAwesomeIcon icon={faExpand} />
        </div>
      </div>
    </div>
  );
};

const CaptureViewerImageSelectionTable2 = ({
  imageSelection,
  qaqcModeShowSelectedOnly,
  setQAQCModeShowSelectedOnly,
  captureJobImagePutMutation,
}: {
  imageSelection: ReturnType<
    typeof useItemSelection<number, APIModels.CaptureImage>
  >;
  qaqcModeShowSelectedOnly: boolean;
  setQAQCModeShowSelectedOnly: (value: (previous: boolean) => boolean) => void;
  captureJobImagePutMutation?: ReturnType<
    typeof APIClient.usePutCaptureImageById
  >;
}) => {
  return (
    <div className="flex flex-col w-full">
      <table className="table-auto w-full max-w-2xl">
        <thead className="pb-1">
          <tr>
            <th className="text-left max-w-16 w-16 pb-1">Frame</th>
            <th className="text-left max-w-16 w-16 pb-1">Note</th>
            <th className="text-center max-w-18 w-18 pb-1">
              <PrimaryButton
                onClicked={() => {
                  setQAQCModeShowSelectedOnly((prev: boolean) => !prev);
                }}
                className="py-1 px-1"
                theme={qaqcModeShowSelectedOnly ? 'secondary' : undefined}
                label={`${
                  qaqcModeShowSelectedOnly ? 'Show All' : 'Show Selected'
                }`}
                size="x-small"
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {imageSelection.getSelectionArray().map((image, index) => {
            return (
              <tr key={index} className="align-baseline">
                <td className="text-sm truncate max-w-16">
                  {/* localIndex is set behind TypeScript's back in captures-viewer-queries */}
                  {(image as any).localIndex}
                </td>
                <td className="text-sm truncate max-w-16 flex">
                  <CaptureNoteInput2
                    image={image}
                    captureJobImagePutMutation={captureJobImagePutMutation}
                  />
                </td>
                <td className="text-center max-w-18 w-18"></td>
                <td>
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faTrash}
                    onClick={() => {
                      if (image.id === undefined) {
                        return;
                      }
                      imageSelection.removeSelection(image.id);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const CaptureViewerImageSelectionTable2ReadOnly = ({
  selectedCaptureImages,
}: {
  selectedCaptureImages: APIModels.CaptureImage[];
}) => {
  return (
    <table>
      <thead className="pb-1">
        <tr>
          <th className="text-left max-w-16 w-16 pb-1">Frame</th>
          <th className="text-left max-w-16 w-16 pb-1">Note</th>
          <th className="text-center max-w-18 w-18 pb-1">Image Path</th>
        </tr>
      </thead>
      <tbody>
        {selectedCaptureImages.map(
          (image: APIModels.CaptureImage, index: number) => (
            <tr key={index} className="align-baseline">
              <td className="text-sm truncate max-w-16">
                {/* localIndex is set behind TypeScript's back in captures-viewer-queries */}
                {(image as any).localIndex}
              </td>
              <td className="text-sm truncate max-w-16">{image.note}</td>
              <td className="text-center max-w-18 w-18">{image.imagePath}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export interface CaptureNoteInput2Props {
  image: APIModels.CaptureImage;
  captureJobImagePutMutation?: ReturnType<
    typeof APIClient.usePutCaptureImageById
  >;
}

export const CaptureNoteInput2 = ({
  image,
  captureJobImagePutMutation,
}: CaptureNoteInput2Props) => {
  const [note, setNote] = useState(image.note ?? '');

  return (
    <div className="flex flex-row gap-1 items-center">
      <Input.Text.Single
        id={`note-${image.id}`}
        value={note}
        setValue={setNote}
      />
      <Button.Icon
        id="save-note"
        icon={faSave}
        loading={captureJobImagePutMutation?.isPending}
        onClick={() => {
          if (image.id === undefined) {
            return;
          }
          captureJobImagePutMutation?.mutate({
            id: image.id,
            data: {
              ...image,
              note: note,
            },
          });
        }}
      />
    </div>
  );
};
