import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';

export const mlModels = (data?: APIModels.MlModel[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => {
    return compare(a.id, b.id);
  });
  return sorted;
};
