import {
  faEdit,
  faFloppyDisk,
  faSpinner,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';

import {
  CaptureObject,
  CaptureObjectCustomAttribute,
  useDeleteCaptureObjectCustomAttribute,
  useGetCaptureObjectsByCaptureId,
  useGetCustomAttributeUnits,
  usePostCaptureObjectCustomAttribute,
  usePutCaptureObjectCustomAttributeById,
} from '@agerpoint/api';
import { DialogModal, PrimaryButton, Select } from '@agerpoint/component';
import { IViewer, LdFlags, MixpanelNames, Options } from '@agerpoint/types';
import { hasPermission, useGlobalStore } from '@agerpoint/utilities';

import { debounce } from '../../../utilities';
import {
  AddAttributeButton,
  getDefaultObjectCustomAttribute,
  useUnitOptions,
} from '../shared';
import './object-attributes.scss';
import { EditCaptureObjects } from './object-edit';

interface CaptureObjectCustomAttributeTableProps {
  data: CaptureObject[];
  setIsSaving: (isSaving: boolean) => void;
  unitOptions: Options[];
  refetch: () => void;
  captureObjectOptions: Options[];
}

interface CaptureObjectRowProps {
  item: CaptureObject;
  setIsSaving: (isSaving: boolean) => void;
  units: Options[];
  refetch: () => void;
  captureObjectOptions: Options[];
}

interface EditableAttributeTableObjectsProps {
  isOpen: boolean;
  handleCloseDialog: () => void;
  captureId: number;
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
}

export const EditableAttributeTableObjects = ({
  isOpen,
  handleCloseDialog,
  captureId,
  viewer,
}: EditableAttributeTableObjectsProps) => {
  const { data: captureObjects, refetch } = useGetCaptureObjectsByCaptureId({
    id: captureId,
  }) as unknown as { data: CaptureObject[]; refetch: () => void };

  const {
    actions: { sendEvent },
  } = useGlobalStore();

  const { mutate: postCaptureObjectAttribute } =
    usePostCaptureObjectCustomAttribute({});

  const { data: units } = useGetCustomAttributeUnits({});

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [tableData, setTableData] = useState<CaptureObjectCustomAttribute[]>(
    []
  );
  const [captureObjectOptions, setCaptureObjectOptions] = useState<Options[]>(
    []
  );
  const [showObjectEdit, setShowObjectEdit] = useState<boolean>(false);
  const unitOptions = useUnitOptions(units || []);

  const addRow = async () => {
    setIsSaving(true);
    const latestCaptureObject = captureObjects[captureObjects.length - 1];
    if (!latestCaptureObject) return;
    const baseObjectCustomAttribute = getDefaultObjectCustomAttribute(
      latestCaptureObject.id as number
    );
    await postCaptureObjectAttribute(baseObjectCustomAttribute);
    sendEvent(MixpanelNames.CaptureObjectAttributesCreated, {});
    refetch();
    setIsSaving(false);
  };

  const closeDialog = () => {
    if (isSaving) return;
    handleCloseDialog();
    setShowObjectEdit(false);
  };

  useEffect(() => {
    sendEvent(MixpanelNames.CaptureObjectModalViewed, {});
  }, []);

  useEffect(() => {
    if (captureObjects !== null) {
      const d = captureObjects.sort(
        (a, b) => (a.id || 0) - (b.id || 0)
      ) as CaptureObjectCustomAttribute[];
      setTableData(d);
      const objectOptions: Options[] = captureObjects.map((item) => {
        return { value: item?.id || 0, name: item?.name || '' };
      });
      setCaptureObjectOptions(objectOptions);
    }
  }, [captureObjects]);

  return (
    <DialogModal
      open={isOpen}
      handleCloseDialog={closeDialog}
      title="Capture Attributes"
      size={'large'}
    >
      <div className="flex flex-col w-full p-1">
        <div className="flex flex-row pt-4">
          <div className="flex-1">
            {isSaving ? (
              <div className="flex justify-end items-center">
                Saving...
                <FontAwesomeIcon icon={faSpinner} spin className="pl-2" />
              </div>
            ) : (
              <div className="flex justify-end items-center">
                Saved <FontAwesomeIcon icon={faFloppyDisk} className="pl-2" />
              </div>
            )}
          </div>
        </div>

        <div className="modal-body">
          {showObjectEdit ? (
            <EditCaptureObjects
              captureId={captureId}
              data={captureObjects}
              setIsSaving={setIsSaving}
              refetch={refetch}
              viewer={viewer}
              goBack={() => {
                refetch();
                setShowObjectEdit(false);
              }}
            />
          ) : (
            <CaptureObjectCustomAttributeTable
              data={tableData}
              setIsSaving={setIsSaving}
              unitOptions={unitOptions}
              refetch={refetch}
              captureObjectOptions={captureObjectOptions}
            />
          )}
        </div>

        {showObjectEdit ? null : (
          <div className="flex flex-row w-full pb-1">
            <AddAttributeButton
              disabled={!captureObjects || captureObjects.length === 0}
              isSaving={isSaving}
              addRow={addRow}
            />
            <PrimaryButton
              onClicked={() => {
                setShowObjectEdit(true);
              }}
              label="Edit Capture Objects"
              icon={<FontAwesomeIcon icon={faEdit} />}
              className="ml-6"
              disabled={isSaving}
            />
          </div>
        )}
      </div>
    </DialogModal>
  );
};

const CaptureObjectCustomAttributeTable = ({
  data,
  setIsSaving,
  unitOptions,
  refetch,
  captureObjectOptions,
}: CaptureObjectCustomAttributeTableProps) => {
  const [tableData, setTableData] = useState<CaptureObjectCustomAttribute[]>(
    []
  );
  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <table className="table-fixed w-full mt-4">
      <thead>
        <tr>
          <th className="text-left">ID</th>
          <th className="text-left">Attribute</th>
          <th className="text-left">Value</th>
          <th className="text-left">Unit</th>
          <th className="text-left">Remove</th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((item: CaptureObject) => {
          return (
            <CaptureObjectRow
              key={item.id}
              item={item}
              setIsSaving={setIsSaving}
              units={unitOptions || []}
              refetch={refetch}
              captureObjectOptions={captureObjectOptions}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const CaptureObjectRow = ({
  item,
  setIsSaving,
  units,
  refetch,
  captureObjectOptions,
}: CaptureObjectRowProps) => {
  const { permissions } = useGlobalStore();
  const [attributeList, setAttributeList] = useState<
    CaptureObjectCustomAttribute[]
  >([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    const hasPermissionToEdit = hasPermission(
      LdFlags.AgerpointDemos,
      permissions
    );
    setDisabled(!hasPermissionToEdit);
  }, [permissions]);

  useEffect(() => {
    const list: CaptureObjectCustomAttribute[] =
      item?.captureObjectCustomAttributes || [];
    setAttributeList(list);
  }, [item]);

  return attributeList && attributeList.length ? (
    <>
      {attributeList.map((attr: CaptureObjectCustomAttribute) => {
        return (
          <tr key={attr.id}>
            <CaptureObjectCells
              captureObject={item}
              row={attr}
              setIsSaving={setIsSaving}
              units={units}
              refetch={refetch}
              captureObjectOptions={captureObjectOptions}
              empty={false}
              disabled={disabled && item.captureObjectTypeId !== 1}
            />
          </tr>
        );
      })}
    </>
  ) : (
    <tr>
      <CaptureObjectCells
        captureObject={item}
        row={{}}
        setIsSaving={setIsSaving}
        units={units}
        refetch={refetch}
        captureObjectOptions={captureObjectOptions}
        empty={true}
        disabled={disabled && item.captureObjectTypeId !== 1}
      />
    </tr>
  );
};

const CaptureObjectCells = ({
  captureObject,
  row,
  setIsSaving,
  units,
  refetch,
  captureObjectOptions,
  empty,
  disabled,
}: {
  captureObject: CaptureObject;
  row: CaptureObjectCustomAttribute;
  setIsSaving: (isSaving: boolean) => void;
  units: Options[];
  refetch: () => void;
  captureObjectOptions: Options[];
  empty: boolean;
  disabled?: boolean;
}) => {
  const {
    actions: { sendEvent },
  } = useGlobalStore();
  const { mutate: putCaptureObjectAttribute } =
    usePutCaptureObjectCustomAttributeById({ id: NaN });

  const { mutate: deleteCaptureObjectAttribute } =
    useDeleteCaptureObjectCustomAttribute({});

  const { mutate: postCaptureObjectAttribute } =
    usePostCaptureObjectCustomAttribute({});

  const [attrRow, setAttrRow] = useState<CaptureObjectCustomAttribute>();
  const [objectId, setObjectId] = useState<number | undefined>(undefined);

  useEffect(() => {
    setAttrRow(row);
  }, [row]);

  useEffect(() => {
    setObjectId(captureObject.id);
  }, [captureObject]);

  const deleteRow = async (row?: CaptureObjectCustomAttribute) => {
    setIsSaving(true);
    if (!row?.id) {
      setIsSaving(false);
      return;
    }
    await deleteCaptureObjectAttribute(row.id as number);
    sendEvent(MixpanelNames.CaptureObjectAttributesDeleted, {});
    await refetch();
    setIsSaving(false);
  };

  const updateRow = async (
    key: string,
    newRow: CaptureObjectCustomAttribute
  ) => {
    if (!newRow.id) {
      await postCaptureObjectAttribute({
        ...newRow,
        captureObjectId: captureObject.id,
        customAttributeUnitId: 0,
        validated: true,
      });
      await refetch();
      setIsSaving(false);
      return;
    }

    await putCaptureObjectAttribute(
      { ...newRow },
      {
        pathParams: { id: newRow.id as number },
      }
    );

    if (key === 'customAttributeUnitId') {
      await refetch();
    }
    sendEvent(MixpanelNames.CaptureObjectAttributesUpdated, {});

    setIsSaving(false);
  };

  const doPut = useCallback(
    debounce((key: string, newRow: CaptureObjectCustomAttribute) => {
      updateRow(key, newRow);
    }, 500),
    []
  );

  const updateAttributeRow = async (key: string, value: string | null) => {
    setIsSaving(true);
    const newRow = { ...attrRow, [key]: value };
    setAttrRow({ ...newRow });

    if (key === 'customAttributeUnitId') {
      delete newRow.customAttributeUnit;
      newRow.customAttributeUnitId = parseInt(value as string);
    }
    if (key === 'captureObjectId') {
      setObjectId(parseInt(value as string));
    }

    doPut(key, newRow);
  };

  return (
    <>
      <td className="text-sm pr-8">
        <Select
          value={objectId}
          options={captureObjectOptions}
          onChange={(event) => {
            updateAttributeRow('captureObjectId', event.target.value);
          }}
          name="captureObject"
          id="captureObject"
          disabled={disabled}
        />
      </td>
      <td className="text-sm">
        <input
          data-test-id="attribute-name"
          onChange={(event) => {
            updateAttributeRow('attributeName', event.target.value);
          }}
          value={attrRow?.attributeName || ''}
          className={` mt-1 h-9 w-5/6 border rounded border-gray-500 px-2 ${
            disabled ? 'disabled:opacity-75' : 'cursor-pointer'
          }`}
          disabled={disabled}
        />
      </td>
      <td className="text-sm">
        <input
          data-test-id="attribute-value"
          onChange={(event) => {
            updateAttributeRow('attributeValue', event.target.value);
          }}
          value={attrRow?.attributeValue || ''}
          className={`mt-1 h-9 w-5/6 border rounded border-gray-500 px-2 ${
            disabled ? 'disabled:opacity-75' : 'cursor-pointer'
          }`}
          disabled={disabled}
        />
      </td>
      <td className="text-sm pr-10 ">
        <Select
          data-test-id="unit"
          value={attrRow?.customAttributeUnit?.id || 'None'}
          onChange={(event) => {
            const value =
              event.target.value === 'None' ? null : event.target.value;
            updateAttributeRow('customAttributeUnitId', value);
          }}
          options={units}
          placeholder="Select Unit"
          name="unit"
          id="unit"
          disabled={disabled}
        />
      </td>
      <td>
        {!captureObject.captureExtractionJobId ? (
          <FontAwesomeIcon
            data-test-id="delete"
            className={`${
              empty ? 'text-gray-200 cursor-not-allowed' : 'cursor-pointer'
            }`}
            icon={faTrash}
            onClick={() => {
              if (empty) {
                return;
              }
              deleteRow(attrRow);
            }}
          />
        ) : null}
      </td>
    </>
  );
};
