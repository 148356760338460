import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import { APIUtils, useIsViteApp, useToasts } from '@agerpoint/utilities';

export const useAdminAnalyticsQueries = () => {
  const queryClient = useQueryClient();

  const { analyticId } = useParams();

  const toasts = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const analyticsQuery = APIClient.useGetAnalytic({
    query: {
      queryKey: [APIUtils.QueryKey.analytics],
      staleTime: APIUtils.getDuration({ seconds: 20 }),
    },
  });

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
    },
  });

  const analyticPostMutation = APIClient.usePostAnalytic({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.analytics],
        });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.Analytic>({
          queryClient,
          queryKey: [APIUtils.QueryKey.analytics, { analyticId: data.id }],
          data: data,
        });

        toasts.add(toasts.prepare.entityCreated('analytic'));

        if (isViteApp) {
          navigate(`/app/admin/platform/analytics/${data.id}/details`, {
            state: { params },
          });
        } else {
          navigate(`/admin/analytics/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create analytic!'));
      },
    },
  });

  const analyticQuery = APIClient.useGetAnalyticById(Number(analyticId), {
    query: {
      enabled: Number.isSafeInteger(Number(analyticId)),
      queryKey: [
        APIUtils.QueryKey.analytics,
        { analyticId: Number(analyticId) },
      ],
      initialData: () =>
        APIUtils.searchQueriesForInitialValue({
          queryClient,
          queryKey: [APIUtils.QueryKey.analytics],
          id: Number(analyticId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({ seconds: 20 }),
      retry: 0,
    },
  });

  const analyticPutMutation = APIClient.usePutAnalyticById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analytics,
            { analyticId: Number(analyticId) },
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache<APIModels.Analytic>({
          queryClient,
          queryKey: [APIUtils.QueryKey.analytics],
          accessor: 'id',
          data: variables.data,
          id: variables.id,
        });

        APIUtils.updateQueryCache<APIModels.Analytic>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.analytics,
            { analyticId: Number(analyticId) },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('analytic'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update analytic!'));
      },
    },
  });

  const analyticOrganizations = APIClient.useGetCustomerAnalyticsByAnalyticId(
    Number(analyticId),
    {
      query: {
        enabled: Number.isSafeInteger(Number(analyticId)),
        queryKey: [
          APIUtils.QueryKey.analytics,
          { analyticId: Number(analyticId) },
          APIUtils.QueryKey.organizations,
        ],
      },
    }
  );

  const organizationAnalyticPostMutation = APIClient.usePostCustomerAnalytic({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analytics,
            { analyticId: Number(analyticId) },
            APIUtils.QueryKey.organizations,
          ],
        });
      },
      onSuccess: () => {
        toasts.add(
          toasts.prepare.entityUpdated('analytic', 'Added organization.')
        );
      },
    },
  });

  const organizationAnalyticRemovePutMutation =
    APIClient.usePutCustomerAnalyticById({
      mutation: {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              APIUtils.QueryKey.analytics,
              { analyticId: Number(analyticId) },
              APIUtils.QueryKey.organizations,
            ],
          });
        },
        onSuccess: () => {
          toasts.add(
            toasts.prepare.entityUpdated('analytic', 'Removed organization.')
          );
        },
        onError: (e) => {
          console.error(e);
          toasts.add(toasts.prepare.error('Failed to remove organization!'));
        },
      },
    });

  const organizationAnalyticCostPutMutation =
    APIClient.usePutCustomerAnalyticById({
      mutation: {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              APIUtils.QueryKey.analytics,
              { analyticId: Number(analyticId) },
              APIUtils.QueryKey.organizations,
            ],
          });
        },
        onSuccess: (_, variables) => {
          APIUtils.updateListQueryCache({
            queryClient,
            queryKey: [
              APIUtils.QueryKey.analytics,
              { analyticId: Number(analyticId) },
              APIUtils.QueryKey.organizations,
            ],
            id: variables.id,
            accessor: 'id',
            data: variables.data,
          });

          toasts.add(
            toasts.prepare.entityUpdated('analytic', 'Analytic cost updated.')
          );
        },
        onError: (e) => {
          console.error(e);
          toasts.add(toasts.prepare.error('Failed to update analytic cost!'));
        },
      },
    });

  const organizationAnalyticAvailabilityPutMutation =
    APIClient.usePutCustomerAnalyticById({
      mutation: {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [APIUtils.QueryKey.organizations],
          });
        },
        onSuccess: (_, variables) => {
          APIUtils.updateListQueryCache({
            queryClient,
            queryKey: [
              APIUtils.QueryKey.analytics,
              { analyticId: Number(analyticId) },
              APIUtils.QueryKey.organizations,
            ],
            id: variables.id,
            accessor: 'id',
            data: variables.data,
          });

          toasts.add(
            toasts.prepare.entityUpdated(
              'analytic',
              'Availability for organization updated.'
            )
          );
        },
        onError: (e) => {
          console.error(e);

          toasts.add(
            toasts.prepare.error(
              'Failed to update availability for organization!'
            )
          );
        },
      },
    });

  const analyticJobTypesQuery = APIClient.useGetAnalyticJobTypeByAnalyticId(
    Number(analyticId),
    {
      query: {
        enabled: Number.isSafeInteger(Number(analyticId)),
        queryKey: [
          APIUtils.QueryKey.analytics,
          { analyticId: Number(analyticId) },
          APIUtils.QueryKey.jobTypes,
        ],
      },
    }
  );

  const analyticJobTypesPostMutation = APIClient.usePostAnalyticJobType({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.analytics,
            { analyticId: Number(analyticId) },
            APIUtils.QueryKey.jobTypes,
          ],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityUpdated('analytic', 'Job type added.'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to add job type to analytic!'));
      },
    },
  });

  const analyticJobTypesArchivePutMutation =
    APIClient.usePutAnalyticJobTypeById({
      mutation: {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              APIUtils.QueryKey.analytics,
              { analyticId: Number(analyticId) },
              APIUtils.QueryKey.jobTypes,
            ],
          });
        },
        onSuccess: () => {
          toasts.add(
            toasts.prepare.entityUpdated('analytic', 'Job type removed.')
          );
        },
        onError: (e) => {
          console.error(e);

          toasts.add(
            toasts.prepare.error('Failed to remove job type from analytic!')
          );
        },
      },
    });

  const jobTypesQuery = APIClient.useGetJobType({
    query: {
      queryKey: [APIUtils.QueryKey.jobTypes],
    },
  });

  const mosaicEnginesQuery = APIClient.useGetMosaicEngines({
    query: {
      queryKey: [APIUtils.QueryKey.mosaicEngines, { archived: false }],
      select: (data) => data.filter((d) => d.archived === false),
    },
  });

  useEffect(() => {
    if (analyticId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(analyticId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/analytics' + params);
      } else {
        navigate('/admin/analytics' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.analytics,
          { analyticId: Number(analyticId) },
        ],
      });
    }
  }, [analyticId]);

  const analyticArchivePutMutation = APIClient.usePutAnalyticById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.analytics],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.Analytic>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.analytics,
            { analyticId: variables.data.id },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityArchived('analytic'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive analytic!'));
      },
    },
  });

  const analyticUnarchivePutMutation = APIClient.usePutAnalyticById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.analytics],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.Analytic>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.analytics,
            { analyticId: variables.data.id },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityRestored('analytic'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to restore analytic!'));
      },
    },
  });

  return {
    analyticsQuery,
    analyticQuery,
    analyticPostMutation,
    analyticPutMutation,
    analyticOrganizations,
    organizationsQuery,
    organizationAnalyticPostMutation,
    organizationAnalyticRemovePutMutation,
    organizationAnalyticCostPutMutation,
    organizationAnalyticAvailabilityPutMutation,
    analyticJobTypesQuery,
    jobTypesQuery,
    mosaicEnginesQuery,
    analyticJobTypesPostMutation,
    analyticJobTypesArchivePutMutation,
    analyticArchivePutMutation,
    analyticUnarchivePutMutation,
  };
};
