import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';

import {
  EventBusNames,
  IAnnotations2dGeometry,
  IAnnotations3dGeometry,
  IAudioViewerController,
  IGs3dViewerController,
  IImageViewerController,
  IPotreeViewerController,
  IVideoViewerController,
  ViewerType,
} from '@agerpoint/types';
import { eventBus } from '@agerpoint/utilities';

import { AudioViewer } from '../audio-viewer';
import { useCapturesViewerContext } from '../captures-viewer/captures-viewer-context';
import { GsThreeDSplatViewerController } from '../gs-3d-viewer/gs-three-d.splat.controller';
import { ImageViewer } from '../image-viewer';
import { PotreeViewerController } from '../potree-viewer/potree-controller';
import { VideoViewer } from '../video-viewer';
import { ThreeDViewerMessage } from './three-d-message';

interface IViewerOptions {
  showTools?: boolean;
  showCloudTools?: boolean;
  showLoadingIndicator?: boolean;
  plugins?: React.ReactElement[];
}

interface ThreeDViewer2Props {
  cloudApp?: boolean;
  controller?: (controller: IThreeDViewerControllerGroup) => void;
  potree?: IViewerOptions;
  three?: IViewerOptions;
  overlay?: React.ReactElement;
  plugins?: React.ReactElement[];
  token?: string;
}

export interface IThreeDViewerControllerGroup {
  potreeController?: IPotreeViewerController;
  threeController?: IGs3dViewerController;
  videoController?: IVideoViewerController;
  audioController?: IAudioViewerController;
  imageController?: IImageViewerController;
  setViewerType: (viewerType: ViewerType | undefined) => void;
  viewerType?: ViewerType;
}

export const AllControllersComponent = ({
  cloudApp = false,
  controller: setController,
  potree,
  three,
  plugins,
  overlay,
  token,
}: ThreeDViewer2Props) => {
  const [potreeController, setPotreeController] =
    useState<IPotreeViewerController>();
  const [threeController, setThreeController] =
    useState<IGs3dViewerController>();

  const [videoController, setVideoController] =
    useState<IVideoViewerController>();
  const [audioController, setAudioController] =
    useState<IAudioViewerController>();
  const [imageController, setImageController] =
    useState<IImageViewerController>();

  const [viewerType, setViewerType] = useState<ViewerType>();

  useEffect(() => {
    if (setController) {
      setController({
        potreeController:
          viewerType === ViewerType.Potree ? potreeController : undefined,
        threeController:
          viewerType === ViewerType.Three ? threeController : undefined,
        videoController:
          viewerType === ViewerType.Video ? videoController : undefined,
        audioController:
          viewerType === ViewerType.Audio ? audioController : undefined,
        imageController:
          viewerType === ViewerType.Image ? imageController : undefined,
        setViewerType,
        viewerType,
      });
    }
  }, [
    potreeController,
    threeController,
    videoController,
    audioController,
    imageController,
    viewerType,
    setController,
  ]);

  const { setAnnotations3dGeometry, setAnnotations2dGeometry } =
    useCapturesViewerContext();

  const annotation3dGeometryUpdateCallback = useCallback(
    (data: CustomEvent) => {
      setAnnotations3dGeometry?.(data?.detail as IAnnotations3dGeometry);
    },
    [setAnnotations3dGeometry]
  );

  const annotation2dGeometryUpdateCallback = useCallback(
    (data: CustomEvent) => {
      setAnnotations2dGeometry?.(data?.detail as IAnnotations2dGeometry);
    },
    [setAnnotations2dGeometry]
  );

  useEffect(() => {
    if (!potreeController?.info?.viewerReady) {
      return;
    }

    const eventId3d = eventBus.on(
      EventBusNames.AnnotationGeometryUpdate,
      annotation3dGeometryUpdateCallback
    );
    const eventId2d = eventBus.on(
      EventBusNames.Annotation2dGeometryUpdate,
      annotation2dGeometryUpdateCallback
    );
    return () => {
      eventBus.remove(
        EventBusNames.AnnotationGeometryUpdate,
        annotation3dGeometryUpdateCallback,
        eventId3d
      );
      eventBus.remove(
        EventBusNames.Annotation2dGeometryUpdate,
        annotation2dGeometryUpdateCallback,
        eventId2d
      );
    };
  }, [
    potreeController?.info.viewerReady,
    annotation3dGeometryUpdateCallback,
    annotation2dGeometryUpdateCallback,
    setAnnotations3dGeometry,
    setAnnotations2dGeometry,
  ]);

  useEffect(() => {
    if (!threeController?.info?.viewerReady) {
      return;
    }
    const eventId3d = eventBus.on(
      EventBusNames.AnnotationGeometryUpdate,
      annotation3dGeometryUpdateCallback
    );
    const eventId2d = eventBus.on(
      EventBusNames.Annotation2dGeometryUpdate,
      annotation2dGeometryUpdateCallback
    );

    return () => {
      eventBus.remove(
        EventBusNames.AnnotationGeometryUpdate,
        annotation3dGeometryUpdateCallback,
        eventId3d
      );
      eventBus.remove(
        EventBusNames.Annotation2dGeometryUpdate,
        annotation2dGeometryUpdateCallback,
        eventId2d
      );
    };
  }, [
    threeController?.info.viewerReady,
    annotation3dGeometryUpdateCallback,
    annotation2dGeometryUpdateCallback,
    setAnnotations3dGeometry,
    setAnnotations2dGeometry,
  ]);

  if (viewerType === ViewerType.Unavailable) {
    return <ThreeDViewerMessage message={overlay ?? 'Preview Unavailable'} />;
  }

  if (viewerType === ViewerType.Image) {
    return <ImageViewer controller={setImageController} />;
  }

  if (viewerType === ViewerType.Audio) {
    return <AudioViewer controller={setAudioController} />;
  }

  if (viewerType === ViewerType.Video) {
    return <VideoViewer controller={setVideoController} />;
  }

  if (viewerType === ViewerType.Potree) {
    if (potreeController?.info.error) {
      return (
        <ThreeDViewerMessage
          message={potreeController.info.error?.toString()}
        />
      );
    }
    // non cloud app only has old tools
    return (
      <PotreeViewerController
        cloudApp={cloudApp}
        controller={setPotreeController}
        showTools={potree?.showTools}
        showCloudTools={potree?.showCloudTools}
        plugins={[...(plugins ?? []), ...(potree?.plugins ?? [])]}
        token={token}
      />
    );
  }

  if (viewerType === ViewerType.Three) {
    if (threeController?.info.error) {
      return (
        <ThreeDViewerMessage message={threeController.info.error?.toString()} />
      );
    }
    return (
      <GsThreeDSplatViewerController
        cloudApp={cloudApp}
        controller={setThreeController}
        showTools={!three?.showCloudTools}
        showCloudTools={three?.showCloudTools}
        showLoadingIndicator={three?.showLoadingIndicator}
        plugins={[...(plugins ?? []), ...(three?.plugins ?? [])]}
      />
    );
  }

  return (
    <ThreeDViewerMessage
      message={
        <div className="flex flex-row gap-2">
          <span>Loading Preview...</span>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      }
    />
  );
};
