import {
  faArrowLeft,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';

import {
  CaptureCustomAttribute,
  CaptureObject,
  useDeleteCaptureObject,
  usePostCaptureObject,
  usePostCaptureObjectCustomAttribute,
  usePutCaptureObjectById,
} from '@agerpoint/api';
import { Input, PrimaryButton } from '@agerpoint/component';
import { IViewer, MixpanelNames } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { debounce } from '../../../utilities';
import { getDefaultCaptureObject } from '../shared';

interface EditCaptureObjectsProps {
  data: CaptureObject[];
  setIsSaving: (isSaving: boolean) => void;
  refetch: () => void;
  captureId: number;
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
  goBack: () => void;
}

interface PostCaptureObject {
  mutate: (a: CaptureObject) => CaptureCustomAttribute;
}

export const EditCaptureObjects = ({
  data,
  setIsSaving,
  refetch,
  captureId,
  viewer,
  goBack,
}: EditCaptureObjectsProps) => {
  const {
    actions: { sendEvent },
  } = useGlobalStore();
  const { mutate: postCaptureObject } = usePostCaptureObject(
    {}
  ) as unknown as PostCaptureObject;
  const { mutate: putCaptureObject } = usePutCaptureObjectById({ id: NaN });
  const { mutate: deleteCaptureObject } = useDeleteCaptureObject({});
  const { mutate: postCaptureObjectAttribute } =
    usePostCaptureObjectCustomAttribute({});

  const [captureObjectData, setCaptureObjectData] = useState<CaptureObject[]>(
    []
  );

  useEffect(() => {
    setCaptureObjectData(data);
  }, [data]);

  const updateObject = async (
    index: number,
    captureObject: CaptureObject,
    id: number,
    value: string
  ) => {
    const newCaptureObject = {
      ...captureObject,
      name: value.trim(),
    };
    delete newCaptureObject.captureObjectCustomAttributes;
    await putCaptureObject(newCaptureObject, {
      pathParams: { id: id as number },
    });
    sendEvent(MixpanelNames.CaptureObjectUpdated, {});

    setIsSaving(false);
  };

  const putObjectChanges = useCallback(
    debounce(
      (
        index: number,
        captureObject: CaptureObject,
        key: number,
        value: string
      ) => {
        updateObject(index, captureObject, key, value);
      },
      500
    ),
    []
  );

  const objectRowChanged = (
    index: number,
    captureObject: CaptureObject,
    id: number,
    value: string
  ) => {
    setIsSaving(true);
    const newData = [...captureObjectData];
    const newCaptureObject = {
      ...captureObject,
      name: value,
    };
    newData[index] = newCaptureObject;
    setCaptureObjectData(newData);
    putObjectChanges(index, captureObject, id, value);
  };

  const deleteCaptureObjectFn = async (id: number) => {
    setIsSaving(true);
    await deleteCaptureObject(id as number);
    sendEvent(MixpanelNames.CaptureObjectDeleted, {});

    await refetch();
    viewer?.current?.clearMarkerById(id.toString());
    setIsSaving(false);
  };

  const addNewCaptureObject = async () => {
    setIsSaving(true);
    const captureObject = await postCaptureObject(
      getDefaultCaptureObject(captureId)
    );
    const defaultCaptureObjectAttribute = {
      captureObjectId: captureObject.id,
      customAttributeUnitId: 0,
      validated: true,
    };
    await postCaptureObjectAttribute(defaultCaptureObjectAttribute);
    await refetch();
    sendEvent(MixpanelNames.CaptureObjectCreated, {});
    setIsSaving(false);
  };

  return (
    <>
      <div>
        <table className="table-fixed w-full mt-4">
          <thead>
            <tr>
              <th className="text-left">ID</th>
            </tr>
          </thead>
          <tbody>
            {captureObjectData?.map((item: CaptureObject, i) => {
              if (!item.id) {
                return null;
              }
              const id = item.id;
              return (
                <tr key={item.id}>
                  <td className="px-2">
                    <Input.Text.Single
                      id="object-name"
                      value={item.name || ''}
                      setValue={(v) => {
                        objectRowChanged(i, item, id, v);
                      }}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      data-test-id="delete"
                      className={`${
                        !item?.captureObjectCustomAttributes?.length
                          ? 'cursor-pointer'
                          : 'text-gray-200 cursor-not-allowed'
                      }`}
                      icon={faTrash}
                      onClick={() => {
                        if (!item?.captureObjectCustomAttributes?.length) {
                          deleteCaptureObjectFn(id);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-sm">
          *Remove object attributes before deleting an object
        </div>
      </div>
      <div className="flex justify-start">
        <PrimaryButton
          onClicked={() => {
            goBack();
          }}
          label="Back"
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          className="mr-6"
        />
        <PrimaryButton
          className="w-40 grow-0"
          label="New Object"
          onClicked={() => {
            addNewCaptureObject();
          }}
          icon={<FontAwesomeIcon icon={faPlus} />}
        />
      </div>
    </>
  );
};
