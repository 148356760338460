import { useEffect, useState } from 'react';

interface NoteProps {
  addNoteModeToggledOn: boolean;
  setAddNoteModeToggledOn: (value: boolean) => void;
}

export const Note = ({
  addNoteModeToggledOn,
  setAddNoteModeToggledOn,
}: NoteProps) => {
  const [value, setValue] = useState<string>('');
  const [showTextArea, setShowTextArea] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!addNoteModeToggledOn) {
      setHover(false);
    }
  }, [addNoteModeToggledOn]);

  if (value.trim().length === 0 && !addNoteModeToggledOn && !showTextArea) {
    return null;
  }

  if (value.trim().length === 0 && addNoteModeToggledOn && !showTextArea) {
    return (
      <div
        className="w-full h-2 flex items-center gap-2 px-2 cursor-copy select-none"
        onPointerEnter={() => {
          setHover(true);
        }}
        onPointerLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          setAddNoteModeToggledOn(false);
          setShowTextArea(true);
        }}
      >
        <hr
          className={`border-dashed w-full border-2 ${
            hover ? 'border-green-600' : 'border-gray-300'
          }`}
        />
      </div>
    );
  }

  return (
    <div
      className={`py-1 ${
        addNoteModeToggledOn
          ? 'cursor-no-drop pointer-events-none select-none'
          : ''
      }`}
    >
      <textarea
        className={`p-1 resize-none focus:resize-y w-full text-gray-500
        border-none outline-0 overflow-hidden text-xs`}
        value={value}
        style={{
          maxHeight: '2in',
        }}
        autoFocus
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={(_) => {
          if (value.trim().length === 0) {
            setShowTextArea(false);
          }
        }}
      />
    </div>
  );
};
