import { useEffect, useRef } from 'react';

const BASE_TITLE = 'Agerpoint Cloud';

export const usePageTitle = (
  titleCallback: () => string | null | undefined,
  deps: React.DependencyList
) => {
  const prevTitle = useRef<string | undefined | null>(BASE_TITLE);

  useEffect(() => {
    const title = titleCallback();

    if (prevTitle.current === title) {
      return;
    }

    if (title) {
      document.title = `${title} - ${BASE_TITLE}`;
    } else {
      document.title = BASE_TITLE;
    }
    prevTitle.current = title;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleCallback, ...deps]);

  useEffect(() => {
    return () => {
      document.title = BASE_TITLE;
    };
  }, []);
};
