import { LDFlagSet, LdFlags } from '@agerpoint/types';

export interface IAgerStoreLDSlice {
  featureFlags: LDFlagSet;
  setFeatureFlags?: (flags: LDFlagSet) => void;
  hasFeatureFlag: (flag: LdFlags) => boolean;
}

export const initialAgerStoreLDSlice: IAgerStoreLDSlice = {
  featureFlags: {},
  hasFeatureFlag: (_) => false,
};
