import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import { MixpanelNames } from '@agerpoint/types';
import {
  APIUtils,
  isNonLidarJob,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useAdminCapturesQueries = (
  filter?: APIModels.CaptureFilter,
  captureJob?: APIModels.CaptureJob
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const { captureId, analyticId } = useParams();
  const toasts = useToasts();

  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
      select: (data) => APIUtils.Sort.projects(data),
    },
  });

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const capturesQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.captures,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    staleTime: APIUtils.getDuration({ seconds: 20 }),
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    queryFn: ({ pageParam }) =>
      APIClient.getFilteredPageCaptures(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.CaptureFilter
      ),
    enabled: filter !== undefined,
  });

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          id: Number(captureId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const captureJobPostMutation = APIClient.usePostCaptureHighResWithMlModelJob({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
        });
      },
      onSuccess: () => {
        mixpanel.track(MixpanelNames.ProcessingRequest, { type: 'HD' });
        toasts.add({
          title: 'Capture processing job(s) added to queue.',
          type: 'success',
        });
      },
      onError: (e) => {
        console.error(e);

        toasts.add(
          toasts.prepare.error(
            'Failed to add capture processing job(s) to queue!'
          )
        );
      },
    },
  });

  const captureJobsQuery = APIClient.useGetCaptureJobsByCaptureId(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureJobs,
        ],
        enabled: Number.isSafeInteger(Number(captureId)) && !!captureQuery.data,
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
        select: (data) =>
          data.filter((d) => {
            return !isNonLidarJob(captureQuery.data, d);
          }),
      },
    }
  );

  const mosaicEnginesQuery = APIClient.useGetMosaicEngines({
    query: {
      queryKey: [APIUtils.QueryKey.mosaicEngines],
    },
  });

  const availableMosaicEngines = useMemo(
    () => mosaicEnginesQuery.data?.filter((me) => me.archived === false),
    [mosaicEnginesQuery.data]
  );

  useEffect(() => {
    if (captureId !== undefined && !Number.isSafeInteger(Number(captureId))) {
      navigate('/admin/captures' + params);
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
        ],
      });

      return;
    }

    if (analyticId !== undefined && !Number.isSafeInteger(Number(analyticId))) {
      navigate('..', { relative: 'path', state: { params } });
      return;
    }
  }, [analyticId, captureId]);

  const captureJobPutMutation = APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.captureId) },
            APIUtils.QueryKey.captureJobs,
          ],
          accessor: 'id',
          data: variables.data,
          id: variables.jobId,
        });

        toasts.add(toasts.prepare.entityUpdated('capture job'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update capture job!'));
      },
    },
  });

  const captureJobPlyUrlQuery = APIClient.useGetPlyDownloadUrlByCaptureJobId(
    captureJob?.id as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureJob?.captureId },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: captureJob?.id },
          APIUtils.QueryKey.plyUrl,
        ],
        enabled:
          !!captureJob?.mosaicEngine?.startsWith('g') &&
          !!captureJob?.id &&
          !!captureJob?.captureId,
        retry: 0,
        select: (data) => data?.plyDownloadUrl,
      },
    }
  );

  const eptJsonQuery = APIClient.useGetEptJsonById(
    captureJob?.eptPointcloudId as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureJob?.captureId },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: captureJob?.id },
          APIUtils.QueryKey.eptJson,
        ],
        enabled:
          !captureJob?.mosaicEngine?.startsWith('g') &&
          !!captureJob?.eptPointcloudId &&
          !!captureJob?.mosaicEngine &&
          !!captureJob?.id &&
          !!captureJob?.captureId,
        retry: 0,
      },
    }
  );

  const customerAnalyticsQuery = APIClient.useGetCustomerAnalyticsByCustomerId(
    Number(captureQuery.data?.customerId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: captureQuery.data?.customerId },
          APIUtils.QueryKey.analytics,
        ],
        enabled: !!captureQuery.data?.customerId,
        select: (data) => data.filter((d) => d.archived === false),
      },
    }
  );

  const captureAnalyticsQuery = APIClient.useGetAnalyticRequestsByCaptureId(
    Number(captureId),
    {},
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.analytics,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        select: (data) => data.filter((d) => d.archived === false),
      },
    }
  );

  const analyticRequestPostMutation = APIClient.usePostAnalyticRequest({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.analytics,
          ],
        });

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.analyticRequests,
          ],
        });
      },
      onSuccess: () => {
        toasts.add({
          title: 'Analytic processing started!',
          type: 'success',
        });
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Error starting analytic!'));
      },
    },
  });

  const captureAnalyticsLookupTable = useLookupTable(
    captureAnalyticsQuery.data,
    'customerAnalyticId'
  );

  const captureArchiveMutation = APIClient.usePutCaptureById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.captures],
        });
      },
      onSuccess: (_, variables) => {
        toasts.add(toasts.prepare.entityArchived('capture'));

        APIUtils.updateInfiniteQueryCache<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          accessor: 'id',
          data: variables.data,
          id: Number(captureId),
        });
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive capture!'));
      },
    },
  });

  const captureUnarchiveMutation = APIClient.usePutCaptureById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.captures],
        });
      },
      onSuccess: (_, variables) => {
        toasts.add(toasts.prepare.entityRestored('capture'));

        APIUtils.updateInfiniteQueryCache<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          accessor: 'id',
          data: variables.data,
          id: Number(captureId),
        });
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to restore capture!'));
      },
    },
  });

  const captureBulkArchiveMutation = useMutation({
    mutationFn: async (data: { captures: APIModels.Capture[] }) => {
      const promises = [];

      for (const capture of data.captures) {
        if (!capture.id) {
          continue;
        }
        promises.push(
          APIClient.putCaptureById(capture.id, {
            ...capture,
            archived: true,
          })
        );
      }
      //
      await Promise.all(promises);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.captures],
      });
    },
    onSuccess: (_, variables) => {
      toasts.add(toasts.prepare.entityArchived('captures'));

      for (const capture of variables.captures) {
        APIUtils.updateInfiniteQueryCache<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          accessor: 'id',
          data: {
            ...capture,
            archived: true,
          },
          id: Number(captureId),
        });
      }
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to archive captures!'));
    },
  });

  const captureBulkRestoreMutation = useMutation({
    mutationFn: async (data: { captures: APIModels.Capture[] }) => {
      const promises = [];

      for (const capture of data.captures) {
        if (!capture.id) {
          continue;
        }
        promises.push(
          APIClient.putCaptureById(capture.id, {
            ...capture,
            archived: false,
          })
        );
      }
      //
      await Promise.all(promises);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.captures],
      });
    },
    onSuccess: (_, variables) => {
      toasts.add(toasts.prepare.entityRestored('captures'));

      for (const capture of variables.captures) {
        APIUtils.updateInfiniteQueryCache<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          accessor: 'id',
          data: {
            ...capture,
            archived: false,
          },
          id: Number(captureId),
        });
      }
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to restore captures!'));
    },
  });

  const captureProjectsQuery = APIClient.useGetProjectsByCapture(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.projects,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const capturePutMutation = useMutation({
    mutationFn: async (data: {
      id: number;
      data: APIModels.Capture;
      projectsToAssign: APIModels.Project[];
      projectsToUnassign: APIModels.Project[];
    }) => {
      const promises: Promise<unknown>[] = [
        APIClient.putCaptureById(data.id, data.data),
      ];
      if (data.projectsToAssign.length > 0) {
        for (const project of data.projectsToAssign) {
          if (project.uuid) {
            promises.push(APIClient.addCaptureToProject(project.uuid, data.id));
          }
        }
      }

      if (data.projectsToUnassign.length > 0) {
        for (const project of data.projectsToUnassign) {
          if (project.uuid) {
            promises.push(
              APIClient.deleteCaptureFromProject(project.uuid, data.id)
            );
          }
        }
      }
      await Promise.all(promises);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.captures],
      });
    },
    onSuccess: (_, variables) => {
      toasts.add(toasts.prepare.entityUpdated('capture'));

      APIUtils.updateInfiniteQueryCache<APIModels.Capture>({
        queryClient,
        queryKey: [APIUtils.QueryKey.captures],
        accessor: 'id',
        data: variables.data,
        id: Number(captureId),
      });
    },
    onError: (e) => {
      console.error(e);

      toasts.add(toasts.prepare.error('Failed to update capture!'));
    },
  });

  const usersLookupTable = useLookupTable(usersQuery.data, 'id');

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  return {
    projectsQuery,
    usersQuery,
    organizationsQuery,
    capturesQuery,
    captureQuery,
    captureJobPostMutation,
    captureBulkArchiveMutation,
    captureBulkRestoreMutation,
    captureJobsQuery,
    mosaicEnginesQuery,
    availableMosaicEngines,
    captureJobPutMutation,
    captureJobPlyUrlQuery,
    eptJsonQuery,
    customerAnalyticsQuery,
    captureAnalyticsQuery,
    analyticRequestPostMutation,
    captureAnalyticsLookupTable,
    captureArchiveMutation,
    captureUnarchiveMutation,
    capturePutMutation,
    usersLookupTable,
    captureProjectsQuery,
    organizationsLookupTable,
  };
};
