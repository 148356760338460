import { Group, PerspectiveCamera, Scene, Vector2, Vector3 } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  CaptureObjectGenericAttributes,
  ColorsThreeD,
  CustomGroup,
  HexColor,
  ICustomLine,
  ILine3d,
} from '@agerpoint/types';
import {
  AnnotationGroupName,
  CalloutLabel,
  CustomMesh,
  _create3dExtractionMarker,
  _createCalloutLabel,
  addGeometryToLine,
  createSphereObject,
  getLinearPositions,
} from '@agerpoint/utilities';

import { GeometryBase } from '../geometry.base';

export class Line3d extends GeometryBase implements ILine3d {
  protected line: ICustomLine;
  public type: Annotation3dPolygons | Annotation3dLines;
  readonly uniqueId: string;
  protected position: Vector3[] = [];
  public vertices: CustomGroup<CustomMesh>;
  public midpoints: CustomGroup<CustomMesh>;
  private label: CalloutLabel;
  private labelDiv: HTMLDivElement;
  private container: HTMLElement;
  private borderLine: ICustomLine | null = null;
  private destroyed = false;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    points: Vector3[],
    container: HTMLElement,
    coAttrs: CaptureObjectGenericAttributes
  ) {
    super(scene, perspectiveCamera, isPotree);
    const { id, name, type, color, description } = coAttrs;
    this.uniqueId = id;
    this.container = container;
    this.name = name || '';
    this.type = type as Annotation3dPolygons | Annotation3dLines;
    this.line = {} as ICustomLine;
    this.position = points;
    this.color = color || ColorsThreeD.Cyan;
    this.userData.originalColor = color || ColorsThreeD.Cyan;
    this.vertices = this.createVertexGroup();
    this.midpoints = this.createMidPointHandles();
    this.label = new CalloutLabel(this.name || '', this.color, true);
    this.labelDiv = this.label.element;
    this.container.appendChild(this.labelDiv);
    this.updateLabelPosition();
    this.visibility = false;
    this.init();
    this.label.on('label-click', () => {
      this.emit('label-click', this.uniqueId);
    });
  }

  get isVisible() {
    return this.line.visible;
  }
  get lineObject() {
    return this.line;
  }

  destroy() {
    this.destroyed = true;
  }

  /**
   * removes the line from the scene and disposes of the geometry
   */
  dispose() {
    const annotationGroup = this.scene.getObjectByName(AnnotationGroupName);

    if (this.line.parent) {
      this.line.geometry.dispose();
      this.line.parent.remove(this.line);
    }
    if (this.borderLine?.parent) {
      this.borderLine.geometry.dispose();
      this.borderLine.parent.remove(this.borderLine);
    }

    this.labelDiv.remove();
    this.removeVertices();
    this.removeMidpoints();
    while (this.vertices.children.length > 0) {
      this.vertices.remove(this.vertices.children[0]);
    }
    while (this.midpoints.children.length > 0) {
      this.midpoints.remove(this.midpoints.children[0]);
    }
  }

  /**
   * persists to the database
   */
  delete() {
    if (this.type === Annotation3dLines.AnnotationLine) {
      this.removeLine3dById(this.uniqueId);
    } else if (this.type === Annotation3dPolygons.AnnotationPolygon) {
      this.removePolygon3dById(this.uniqueId);
    }
    this.annoMgr.deleteCapObj(this.uniqueId);
  }

  disposeAndDelete() {
    // store calls dispose
    this.destroy();
    this.delete();
    this.notifyListeners();
  }

  hide() {
    if (!this.line) return;
    this.line.visible = false;
    this.visibility = false;
    this.labelDiv.style.display = 'none';
    this.notifyListeners();
  }

  show() {
    if (!this.line) return;
    this.line.visible = true;
    this.visibility = true;
    this.labelDiv.style.display = 'block';
    this.notifyListeners();
  }

  updateVisibility(isVisible: boolean) {
    if (!this.line) return;
    this.line.visible = isVisible;
    this.visibility = isVisible;
    this.notifyListeners();
  }

  updateType(type: Annotation3dPolygons | Annotation3dLines) {
    this.type = type;
  }

  updateColor(color: ColorsThreeD | HexColor, persist = false) {
    if (!this.line) return;

    if (persist) {
      this.annoMgr.updateCapObjColor(this.uniqueId, color);
      this.notifyListeners();
    }
    this.color = color;
  }

  getPosition() {
    return this.position;
  }

  zoomTo() {
    this.doZoom(this.line);
  }

  highlight() {
    if (this.destroyed) return;
    this.label.highlight();
    this.userData.originalColor = this.color;
    this.userData.originalLineWidth = this.line.material.linewidth;
    this.borderLine = this.createLine2(
      `${this.uniqueId}-border`,
      this.position,
      this.highlightColor
      // true
    );
    const borderMaterial = this.getBorderMaterial();
    this.borderLine.material = borderMaterial;
    const annotationGroup = this.scene.getObjectByName(AnnotationGroupName);
    const borderLine = annotationGroup?.getObjectByProperty(
      'uniqueId',
      `${this.uniqueId}-border`
    );
    if (borderLine) {
      annotationGroup?.remove(borderLine);
      (borderLine as CustomLine).geometry.dispose();
      (borderLine as CustomLine).material.dispose();
    }
    if (annotationGroup) {
      annotationGroup.add(this.borderLine);
    }
    this.vertices.visible = true;
    this.vertices.traverse((child) => {
      if (child instanceof CustomMesh) {
        child.visible = true;
      }
    });
    this.midpoints.visible = true;
    this.midpoints.traverse((child) => {
      if (child instanceof CustomMesh) {
        child.visible = true;
      }
    });
    this.setSelectedObject(this.uniqueId, this.type);
  }

  unHighlight() {
    this.label.unHighlight();
    const annotationGroup = this.scene.getObjectByName(AnnotationGroupName);
    const borderLine = annotationGroup?.getObjectByProperty(
      'uniqueId',
      `${this.uniqueId}-border`
    );
    if (borderLine) {
      annotationGroup?.remove(borderLine);
      (borderLine as CustomLine).geometry.dispose();
      (borderLine as CustomLine).material.dispose();
    }
    this.borderLine = null;
    this.vertices.visible = false;
    this.vertices.traverse((child) => {
      if (child instanceof CustomMesh) {
        child.visible = false;
      }
    });
    this.midpoints.visible = false;
    this.midpoints.traverse((child) => {
      if (child instanceof CustomMesh) {
        child.visible = false;
      }
    });
  }

  calculateArea(): number {
    if (this.position?.length < 3) {
      return 0;
    }

    const refPoint = this.position[0];
    let totalArea = 0.0;

    for (let i = 1; i < this.position.length - 1; i++) {
      const vec1 = this.position[i].clone().sub(refPoint);
      const vec2 = this.position[i + 1].clone().sub(refPoint);

      const crossProd = vec1.cross(vec2);
      const triangleArea = crossProd.length() / 2.0;

      totalArea += triangleArea;
    }

    return totalArea;
  }

  calculateLength(): number {
    const segmentsLength = this.position.slice(1).map((vertex, index) => {
      const start = this.position[index];
      const end = vertex;
      return Math.sqrt(
        Math.pow(end.x - start.x, 2) +
          Math.pow(end.y - start.y, 2) +
          Math.pow(end.z - start.z, 2)
      );
    });

    return segmentsLength.reduce((acc, length) => acc + length, 0);
  }

  updatePosition(points: Vector3[]) {
    if (!this.line) return;

    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (!group) return;
    const isSameNumberOfPoints = points.length === this.position.length;
    const isAddingPoints = points.length > this.position.length;
    // find the new point index
    const newPointIndex = isAddingPoints
      ? points.findIndex(
          (point, index) =>
            !this.position[index] || !point.equals(this.position[index])
        )
      : undefined;
    const isRemovingPoints = points.length < this.position.length;
    const oldPointIndex = isRemovingPoints
      ? this.position.findIndex(
          (point, index) => !points[index] || !point.equals(points[index])
        )
      : undefined;
    this.position = points;

    if (points.length < 2) {
      this.line.visible = false;
      this.vertices.visible = false;
      this.midpoints.visible = false;
      return;
    }

    this.show();

    let linearPositions: number[] = [];
    if (this.isPotree) {
      linearPositions = getLinearPositions(points, 100);
      this.line.position.copy(points[0]);
      this?.borderLine?.position.copy(points[0]);
    } else {
      linearPositions = points.flatMap((point) => [point.x, point.y, point.z]);
    }
    if (
      linearPositions.length !==
      this.line.geometry.attributes.position.count * 3
    ) {
      // hack: https://github.com/mrdoob/three.js/issues/21488
      // @ts-expect-error
      delete this.line.geometry._maxInstanceCount;
      // @ts-expect-error
      delete this.borderLine?.geometry._maxInstanceCount;
    }

    this.line.geometry.setPositions(linearPositions);
    if (this.borderLine) {
      this.borderLine.geometry.setPositions(linearPositions);
    }

    this.updateVerticesAndMidpoints(
      points,
      isSameNumberOfPoints,
      isAddingPoints,
      isRemovingPoints,
      newPointIndex,
      oldPointIndex
    );

    this.emit('positionChange', this.position);
  }

  calculateUpdatedVerticesAndMidpoints(
    points: Vector3[],
    isSameNumberOfPoints: boolean,
    isAddingPoints: boolean,
    isRemovingPoints: boolean,
    newPointIndex?: number,
    oldPointIndex?: number
  ) {
    let updatedVertices = [...this.vertices.children];
    let updatedMidpoints = [...this.midpoints.children];

    if (isSameNumberOfPoints) {
      updatedVertices = updatedVertices.map((vertex, index) => {
        if (vertex instanceof CustomMesh && points[index]) {
          vertex.position.copy(points[index]);
        }
        return vertex;
      });

      updatedMidpoints = updatedMidpoints.map((midpoint, index) => {
        if (
          midpoint instanceof CustomMesh &&
          points[index] &&
          points[index + 1]
        ) {
          const midPoint = points[index].clone().lerp(points[index + 1], 0.5);
          midpoint.position.copy(midPoint);
        }
        return midpoint;
      });

      return { updatedVertices, updatedMidpoints };
    }

    if (isAddingPoints && newPointIndex !== undefined) {
      const newVertex = createSphereObject(
        `${this.type}-${this.uniqueId}-${newPointIndex}`,
        points[newPointIndex],
        null,
        Annotation3dPoints.AnnotationLineVertex,
        this.isPotree,
        this.color,
        this.getCustomSize()
      );

      updatedVertices.splice(newPointIndex, 0, newVertex);

      updatedVertices.forEach((vertex: CustomMesh, index: number) => {
        vertex.uniqueId = `${this.type}-${this.uniqueId}-${index}`;
      });

      if (newPointIndex > 0 && newPointIndex - 1 < updatedMidpoints.length) {
        updatedMidpoints.splice(newPointIndex - 1, 1);
      }

      if (points.length > 1) {
        if (newPointIndex > 0) {
          const beforeIndex = newPointIndex - 1;
          const midPointBefore = points[beforeIndex]
            .clone()
            .lerp(points[newPointIndex], 0.5);

          const newMidpointBefore = createSphereObject(
            `${this.type}-${this.uniqueId}-${beforeIndex}`,
            midPointBefore,
            null,
            Annotation3dPoints.AnnotationLineMidpointVertex,
            this.isPotree,
            this.getLighterColor() as HexColor,
            this.getCustomSize()
          );
          updatedMidpoints.splice(beforeIndex, 0, newMidpointBefore);
        }

        if (newPointIndex < points.length - 1) {
          const midPointAfter = points[newPointIndex]
            .clone()
            .lerp(points[newPointIndex + 1], 0.5);
          const newMidpointAfter = createSphereObject(
            `${this.type}-${this.uniqueId}-${newPointIndex}`,
            midPointAfter,
            null,
            Annotation3dPoints.AnnotationLineMidpointVertex,
            this.isPotree,
            this.getLighterColor() as HexColor,
            this.getCustomSize()
          );

          updatedMidpoints.splice(newPointIndex, 0, newMidpointAfter);
        }

        updatedMidpoints.forEach((midpoint: CustomMesh, index: number) => {
          midpoint.uniqueId = `${this.type}-${this.uniqueId}-${index}`;
        });
      }

      return { updatedVertices, updatedMidpoints };
    }

    if (isRemovingPoints && oldPointIndex !== undefined) {
      updatedVertices.splice(oldPointIndex, 1);

      if (updatedMidpoints.length > 0) {
        if (oldPointIndex === 0) {
          updatedMidpoints.splice(0, 1);
        } else if (oldPointIndex === updatedVertices.length) {
          updatedMidpoints.splice(updatedMidpoints.length - 1, 1);
        } else {
          updatedMidpoints.splice(oldPointIndex - 1, 1);
        }
      }

      updatedVertices.forEach((vertex: CustomMesh, index: number) => {
        vertex.uniqueId = `${this.type}-${this.uniqueId}-${index}`;
      });

      updatedMidpoints = updatedMidpoints.map((midpoint, index) => {
        if (index < updatedVertices.length - 1) {
          const midPoint = updatedVertices[index].position
            .clone()
            .lerp(updatedVertices[index + 1].position, 0.5);
          midpoint.position.copy(midPoint);
          midpoint.uniqueId = `${this.type}-${this.uniqueId}-${index}`;
        }
        return midpoint;
      });

      return { updatedVertices, updatedMidpoints };
    }

    return { updatedVertices, updatedMidpoints };
  }

  applyUpdatedVerticesAndMidpoints(
    updatedVertices: CustomMesh[],
    updatedMidpoints: CustomMesh[]
  ) {
    // Apply updated vertices
    this.vertices.children = updatedVertices;

    // Apply updated midpoints
    this.midpoints.children = updatedMidpoints;

    // Emit changes or any additional logic needed after updating
    this.emit(
      'positionChange',
      this.vertices.children.map((v) => v.position)
    );
  }

  updateVerticesAndMidpoints(
    points: Vector3[],
    isSameNumberOfPoints: boolean,
    isAddingPoints: boolean,
    isRemovingPoints: boolean,
    newPointIndex?: number,
    oldPointIndex?: number
  ) {
    const { updatedVertices, updatedMidpoints } =
      this.calculateUpdatedVerticesAndMidpoints(
        points,
        isSameNumberOfPoints,
        isAddingPoints,
        isRemovingPoints,
        newPointIndex,
        oldPointIndex
      );

    this.applyUpdatedVerticesAndMidpoints(updatedVertices, updatedMidpoints);
  }

  render() {
    this.updateLabelPosition();
  }

  private updateLabelPosition() {
    const centerPosition = this.calculateCenterPosition();
    if (!centerPosition) return;

    // Project the 3D center position to 2D screen coordinates
    const vector = centerPosition.clone().project(this.perspectiveCamera);
    const x = (vector.x * 0.5 + 0.5) * this.container.clientWidth;
    const y = (vector.y * -0.5 + 0.5) * this.container.clientHeight;
    const offset = 10;
    // Set the div position
    this.labelDiv.style.left = `${x + offset}px`;
    this.labelDiv.style.top = `${y + offset}px`;
  }

  private calculateCenterPosition(): Vector3 | null {
    if (this.position.length === 2) {
      // For a line with only two points, find the midpoint
      return this.position[0].clone().lerp(this.position[1], 0.5);
    } else if (this.position.length > 2) {
      // For a line with more points, find the midpoint of the middle segment
      const middleIndex = Math.floor(this.position.length / 2);
      if (this.position.length % 2 === 0) {
        // Even number of points, find the middle segment
        return this.position[middleIndex - 1]
          .clone()
          .lerp(this.position[middleIndex], 0.5);
      } else {
        // Odd number of points, use the middle vertex
        return this.position[middleIndex].clone();
      }
    }
    return null;
  }

  private getBorderMaterial() {
    return new LineMaterial({
      color: 0xffffff,
      dashSize: 5,
      gapSize: 2,
      linewidth: 8,
      resolution: new Vector2(1000, 1000),
      polygonOffset: true,
      polygonOffsetFactor: this.isPotree ? 100 : 1, // Opposite direction to the border
      polygonOffsetUnits: this.isPotree ? 100 : 1,
    });
  }

  private getLineMaterial(color: HexColor) {
    return new LineMaterial({
      color,
      dashSize: 5,
      gapSize: 2,
      linewidth: 4,
      resolution: new Vector2(1000, 1000),
      polygonOffset: true,
      polygonOffsetFactor: this.isPotree ? -100 : -1, // Opposite direction to the border
      polygonOffsetUnits: this.isPotree ? -100 : -1,
    });
  }

  private createLine2(
    id: string,
    points: Vector3[],
    color: ColorsThreeD | HexColor
    // visible: boolean
  ): ICustomLine {
    // const hasEnoughPoints = points.length > 1;
    // const overRideVisibility = hasEnoughPoints ? true : false;
    const thisIsANewLine = points.length < 2;

    const initialPositions = thisIsANewLine
      ? [new Vector3(0, 0, 0), new Vector3(1, 1, 1)]
      : points;

    const positions: number[] = [];
    initialPositions.forEach((point) => {
      positions.push(point.x, point.y, point.z);
    });

    const lineGeometry = new LineGeometry();
    const lineMaterial = this.getLineMaterial(color);

    const line: ICustomLine = new CustomLine(lineGeometry, lineMaterial);
    // crazy scale in potree requires a special line
    if (this.isPotree) {
      const lineWithGeom = addGeometryToLine(
        line,
        initialPositions
      ) as ICustomLine;
      lineWithGeom.visible = points.length > 0 ? true : false;
      lineWithGeom.uniqueId = id;
      lineWithGeom.customType = this.type || Annotation3dLines.LineMarker;
      return lineWithGeom;
    }

    line.geometry.setPositions(positions);
    line.uniqueId = id;
    line.customType = this.type || Annotation3dLines.LineMarker;
    if (thisIsANewLine) {
      line.visible = false;
      this.hide();
    } else {
      line.visible = true;
      this.show();
    }
    return line;
  }

  private init() {
    this.line = this.createLine2(this.uniqueId, this.position, this.color);

    this.updatePosition(this.position);
    this.line.customType = this.type;
    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (group) {
      group.add(this.line);
      group.add(this.vertices);
      group.add(this.midpoints);
    }
  }

  private createVertexGroup() {
    const groupType =
      this.type === Annotation3dLines.AnnotationLine
        ? Annotation3dPoints.AnnotationLineVerticesGroup
        : Annotation3dPoints.AnnotationPolygonVerticesGroup;
    const nameType =
      this.type === Annotation3dLines.AnnotationLine
        ? Annotation3dPoints.AnnotationLineVertex
        : Annotation3dPoints.AnnotationPolygonVertex;
    const vertexGroup = new Group() as CustomGroup<CustomMesh>;
    vertexGroup.name = `${groupType}-${this.uniqueId}`;
    const customSize = this.isPotree ? 0.017 : 0.015;
    const vertexSpheres = this.position.map((pnt: Vector3, i) => {
      return createSphereObject(
        `${this.type}-${this.uniqueId}-${i}`,
        pnt,
        null,
        nameType,
        this.isPotree,
        this.color,
        customSize
      );
    });
    vertexSpheres.forEach((sphere) => {
      sphere.visible = false;
      vertexGroup.add(sphere);
    });
    vertexGroup.customType = groupType;
    vertexGroup.visible = false;
    return vertexGroup;
  }

  private removeVertices() {
    this.vertices.traverse((child) => {
      if (child instanceof CustomMesh) {
        child?.geometry?.dispose();
        if (Array.isArray(child.material)) {
          child.material.forEach((material) => material.dispose());
        } else {
          child.material.dispose();
        }
      }
    });
    while (this.vertices.children.length > 0) {
      this.vertices.remove(this.vertices.children[0]);
    }
  }

  private createMidPointHandles() {
    const midPoints: Vector3[] = [];
    for (let i = 0; i < this.position.length - 1; i++) {
      const midPoint = this.position[i].clone().lerp(this.position[i + 1], 0.5);
      midPoints.push(midPoint);
    }
    const groupType =
      this.type === Annotation3dLines.AnnotationLine
        ? Annotation3dPoints.AnnotationLineMidpointGroup
        : Annotation3dPoints.AnnotationPolygonMidpointGroup;
    const nameType =
      this.type === Annotation3dLines.AnnotationLine
        ? Annotation3dPoints.AnnotationLineMidpointVertex
        : Annotation3dPoints.AnnotationPolygonMidpointVertex;
    const midPointGroup = new Group() as CustomGroup<CustomMesh>;
    midPointGroup.name = `${groupType}-${this.uniqueId}`;
    const customSize = this.getCustomSize();
    // make the midpoint color just a bit transparent
    const lighterColor = this.getLighterColor();

    const midPointSpheres = midPoints.map((pnt: Vector3, i) => {
      return createSphereObject(
        `${this.type}-${this.uniqueId}-${i}`,
        pnt,
        null,
        nameType,
        this.isPotree,
        lighterColor as HexColor,
        customSize
      );
    });
    midPointSpheres.forEach((sphere) => {
      sphere.visible = false;
      midPointGroup.add(sphere);
    });
    midPointGroup.customType = groupType;
    midPointGroup.visible = false;
    return midPointGroup;
  }

  private removeMidpoints() {
    this.midpoints.traverse((child) => {
      if (child instanceof CustomMesh) {
        child?.geometry?.dispose();
        if (Array.isArray(child.material)) {
          child.material.forEach((material) => material.dispose());
        } else {
          child.material.dispose();
        }
      }
    });
    while (this.midpoints.children.length > 0) {
      this.midpoints.remove(this.midpoints.children[0]);
    }
  }

  private getLighterColor() {
    return `#${(parseInt(this.color.slice(1), 16) + 0x333333)
      .toString(16)
      .padStart(6, '0')}`;
  }

  private getCustomSize() {
    return this.isPotree ? 0.017 : 0.015;
  }
}

class CustomLine extends Line2 implements ICustomLine {
  uniqueId = '';
  customType?: Annotation3dLines | Annotation3dPolygons;
  updatePosition: (pos: Vector3[]) => void;
  constructor(geometry: LineGeometry, material: LineMaterial) {
    super(geometry, material);
    this.updatePosition = (pos: Vector3[]) => {
      // Do nothing
    };
  }
}
