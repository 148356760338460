import {
  formatDateLong,
  formatDateRegular,
  formatDateShort,
} from './date-formatter';
import {
  formatDateTimeLong,
  formatDateTimeRegular,
  formatDateTimeShort,
} from './dateTime-formatter';
import { formatFileSize } from './file-formatter';
import {
  formatArea,
  formatAttribute,
  formatLength,
  formatVolume,
} from './measurements-formatter';

const Formatters = {
  Date: {
    Regular: formatDateRegular,
    Long: formatDateLong,
    Short: formatDateShort,
  },
  DateTime: {
    Regular: formatDateTimeRegular,
    Long: formatDateTimeLong,
    Short: formatDateTimeShort,
  },
  Measurement: {
    Length: formatLength,
    Area: formatArea,
    Volume: formatVolume,
    Attribute: formatAttribute,
  },
  File: {
    Size: formatFileSize,
  },
};

export { Formatters };
