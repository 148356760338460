import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, APIModels, CaptureCustomAttribute } from '@agerpoint/api';
import { SpecialCaptureObject } from '@agerpoint/types';
import { APIUtils } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer';

export const useThreeDAnnotationsPluginQueries = () => {
  const { captureId } = useParams();

  const { selectedCaptureJob } = useCapturesViewerContext();

  const captureJobId = useMemo(
    () => selectedCaptureJob?.id,
    [selectedCaptureJob]
  );

  const captureObjectsBySelectedCaptureJobQuery =
    APIClient.useGetCaptureObjectsByCaptureId(Number(captureId), {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(selectedCaptureJob?.captureId) },
          APIUtils.QueryKey.captureObjects,
        ],
        enabled: Number.isSafeInteger(Number(selectedCaptureJob?.captureId)),
        select: (data) =>
          data
            .filter((co) => co.captureJobId === Number(captureJobId))
            .sort((a, b) => compare(a.id, b.id)),
      },
    });

  const createCaptureObject = useMutation({
    mutationFn: async (captureObject: SpecialCaptureObject) => {
      return await APIClient.postCaptureObject(captureObject);
    },
    onSuccess: async () => {
      await captureObjectsBySelectedCaptureJobQuery.refetch();
    },
  });

  const updateCaptureObject = useMutation({
    mutationFn: async (captureObject: APIModels.CaptureObject) => {
      if (!captureObject.id)
        return Promise.reject('Capture object id is required');
      return await APIClient.putCaptureObjectById(
        captureObject.id,
        captureObject
      );
    },
    onSuccess: async () => {
      await captureObjectsBySelectedCaptureJobQuery.refetch();
    },
  });

  const deleteCaptureObject = useMutation({
    mutationFn: async (captureObjectId: number) => {
      return await APIClient.deleteCaptureObject(captureObjectId);
    },
    onSuccess: () => {
      captureObjectsBySelectedCaptureJobQuery.refetch();
    },
  });

  const deleteCustomCaptureObjectAttributes = useMutation({
    mutationFn: async (captureObjectId: number) => {
      return await APIClient.deleteCaptureObjectCustomAttribute(
        captureObjectId
      );
    },
    onSuccess: async () => {
      await captureObjectsBySelectedCaptureJobQuery.refetch();
    },
  });

  const updateCustomCaptureObjectAttributes = useMutation({
    mutationFn: async ({
      updatedCaptureAttribute,
      customAttributeId,
    }: {
      updatedCaptureAttribute: CaptureCustomAttribute;
      customAttributeId: number;
    }) => {
      return await APIClient.putCaptureObjectCustomAttributeById(
        customAttributeId,
        updatedCaptureAttribute
      );
    },
    onSuccess: async () => {
      await captureObjectsBySelectedCaptureJobQuery.refetch();
    },
  });

  return {
    createCaptureObject,
    updateCaptureObject,
    deleteCaptureObject,
    deleteCustomCaptureObjectAttributes,
    captureObjectsBySelectedCaptureJobQuery,
    updateCustomCaptureObjectAttributes,
  };
};
