import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useAdminProjectsQueries = (
  filter?: APIModels.ProjectFilter,
  selectedAnotherProject?: APIModels.Project
) => {
  const { projectUuid } = useParams();
  const queryClient = useQueryClient();
  const toasts = useToasts();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const projectsInfiniteQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.projects,
      APIUtils.QueryKey.infinite,
      { filter: filter },
    ],
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPageProjects(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.ProjectFilter
      ),
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({ seconds: 20 }),
    enabled: filter !== undefined,
  });

  const projectQuery = APIClient.useGetProjectByUuid(projectUuid as string, {
    query: {
      queryKey: [APIUtils.QueryKey.projects, { projectId: projectUuid }],
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Project>({
          queryClient,
          queryKey: [APIUtils.QueryKey.projects, APIUtils.QueryKey.infinite],
          id: projectUuid,
          accessor: 'uuid',
        }),
      staleTime: APIUtils.getDuration({ seconds: 20 }),
      retry: 0,
    },
  });

  const layerGroupsQuery = APIClient.useGetProjectLayerGroups(
    projectUuid as string,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.projectLayerGroups,
          { projectId: projectUuid },
        ],
        staleTime: APIUtils.getDuration({ seconds: 20 }),
        retry: 0,
      },
    }
  );

  const layerGroupsPostMutation = APIClient.usePostLayerGroup({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.projectLayerGroups,
            { projectId: projectUuid },
          ],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityCreated('layer group'));
      },
      onError: (e) => {
        toasts.add(toasts.prepare.error('Failed to create layer group!'));
      },
    },
  });

  const cloneCapturesMutation = APIClient.useCloneLayerGroupCaptures({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.projectLayerGroups],
        });
      },
    },
  });

  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
      staleTime: APIUtils.getDuration({ seconds: 20 }),
    },
  });

  const anotherProjectLayerGroupsQuery = APIClient.useGetProjectLayerGroups(
    selectedAnotherProject?.uuid as string,
    {
      query: {
        enabled: selectedAnotherProject !== undefined,
        queryKey: [
          APIUtils.QueryKey.projectLayerGroups,
          { projectId: selectedAnotherProject?.uuid },
        ],
      },
    }
  );

  const projectPutMutation = APIClient.usePutProject({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.projects],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateInfiniteQueryCache<APIModels.Project>({
          queryClient,
          queryKey: [APIUtils.QueryKey.projects],
          accessor: 'uuid',
          data: variables.data,
          id: variables.uuid,
        });

        APIUtils.updateQueryCache<APIModels.Project>({
          queryClient,
          queryKey: [APIUtils.QueryKey.projects, { projectId: variables.uuid }],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('project'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update project!'));
      },
    },
  });

  const projectArchiveDeleteMutation = APIClient.useDeleteProject({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.projects],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityArchived('project'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive project!'));
      },
    },
  });

  const projectUnarchivePutMutation = APIClient.usePutProject({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.projects],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityRestored('project'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to restore project!'));
      },
    },
  });

  useEffect(() => {
    if (projectUuid === undefined) {
      return;
    }

    if (!(typeof projectUuid === 'string')) {
      if (isViteApp) {
        navigate('/app/admin/platform/projects' + params);
      } else {
        navigate('/admin/projects' + params);
      }
    }
  }, [projectUuid]);

  return {
    organizationsQuery,
    organizationsLookupTable,
    usersQuery,
    projectsInfiniteQuery,
    projectsQuery,
    projectQuery,
    layerGroupsQuery,
    layerGroupsPostMutation,
    cloneCapturesMutation,
    anotherProjectLayerGroupsQuery,
    projectPutMutation,
    projectArchiveDeleteMutation,
    projectUnarchivePutMutation,
  };
};
