/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureObjectCategory, Category } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const postCaptureObjectCategory = (
  captureObjectCategory: BodyType<CaptureObjectCategory>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectCategory>(
    {
      url: `/api/CaptureObjectCategory`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectCategory,
    },
    options
  );
};

export const getPostCaptureObjectCategoryMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObjectCategory>>,
    TError,
    { data: BodyType<CaptureObjectCategory> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureObjectCategory>>,
  TError,
  { data: BodyType<CaptureObjectCategory> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureObjectCategory>>,
    { data: BodyType<CaptureObjectCategory> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptureObjectCategory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureObjectCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureObjectCategory>>
>;
export type PostCaptureObjectCategoryMutationBody =
  BodyType<CaptureObjectCategory>;
export type PostCaptureObjectCategoryMutationError = ErrorType<void>;

export const usePostCaptureObjectCategory = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObjectCategory>>,
    TError,
    { data: BodyType<CaptureObjectCategory> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureObjectCategory>>,
  TError,
  { data: BodyType<CaptureObjectCategory> },
  TContext
> => {
  const mutationOptions = getPostCaptureObjectCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureObjectCategoryById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectCategory>(
    {
      url: `/api/CaptureObjectCategory/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureObjectCategoryByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObjectCategoryById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureObjectCategoryById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureObjectCategoryById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCaptureObjectCategoryById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureObjectCategoryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureObjectCategoryById>>
>;

export type DeleteCaptureObjectCategoryByIdMutationError = ErrorType<void>;

export const useDeleteCaptureObjectCategoryById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObjectCategoryById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureObjectCategoryById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getDeleteCaptureObjectCategoryByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureObjectCategoriesByCaptureObjectId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Category[]>(
    {
      url: `/api/CaptureObjectCategory/CaptureObject/${encodeURIComponent(
        String(id)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectCategoriesByCaptureObjectIdQueryKey = (
  id: number
) => {
  return [`/api/CaptureObjectCategory/CaptureObject/${id}`] as const;
};

export const getGetCaptureObjectCategoriesByCaptureObjectIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>
  >,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureObjectCategoriesByCaptureObjectIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>>
  > = ({ signal }) =>
    getCaptureObjectCategoriesByCaptureObjectId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectCategoriesByCaptureObjectIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>>
  >;
export type GetCaptureObjectCategoriesByCaptureObjectIdQueryError =
  ErrorType<void>;

export const useGetCaptureObjectCategoriesByCaptureObjectId = <
  TData = Awaited<
    ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>
  >,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectCategoriesByCaptureObjectId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetCaptureObjectCategoriesByCaptureObjectIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
