import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UndoButtonProps } from '@agerpoint/types';

import PrimaryButton from '../primary-button/primary-button';

export const UndoButton = ({ title, undoFn, closeFn, id }: UndoButtonProps) => {
  return (
    <div
      id="toast-undo"
      style={{ zIndex: 999999 }}
      className={`flex items-center w-full max-w-lg p-4 text-gray-500 bg-white 
      rounded-lg shadow mt-1 border border-gray-300`}
      role="alert"
    >
      <div className="text-sm font-normal truncate w-56">{title}</div>
      <div className="flex items-center ml-auto space-x-2">
        <PrimaryButton label="Undo" onClicked={undoFn} size="small" />
        <FontAwesomeIcon
          icon={faTimes}
          className="cursor-pointer pl-4"
          onClick={() => closeFn(id)}
        />
      </div>
    </div>
  );
};
