import {
  IconName,
  IconPrefix,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react';

import { Tooltip } from '../tooltip';
import { ButtonIconToggle } from './button-icon-toggle';

interface IButtonIconBase {
  id: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  leadingIcon?: IconName;
  leadingIconPrefix?: IconPrefix;
  leadingIconSpin?: boolean;
  leadingIconColor?: string;
  trailingIcon?: IconName;
  trailingIconPrefix?: IconPrefix;
  trailingIconSpin?: boolean;
  trailingIconColor?: string;
  toggled?: boolean;
  compact?: boolean;
}

interface IButtonIconWithLabel extends IButtonIconBase {
  label: string;
  tooltip?: never;
  tooltipPosition?: never;
}

interface IButtonIconWithToolTip extends IButtonIconBase {
  tooltip: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  label?: never;
}

type IButtonIcon = IButtonIconWithLabel | IButtonIconWithToolTip;

const ButtonIcon = ({
  id,
  buttonRef: buttonRefProp,
  onClick,
  disabled,
  loading,
  leadingIcon,
  leadingIconPrefix = 'far',
  leadingIconSpin,
  leadingIconColor,
  trailingIcon,
  trailingIconPrefix = 'far',
  trailingIconSpin,
  trailingIconColor,
  label,
  toggled,
  compact,
  tooltip,
  tooltipPosition = 'bottom',
}: IButtonIcon) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const className = useMemo(() => {
    let classes = `transition-colors duration-200 h-8 shrink-0
    flex flex-row items-center justify-center relative`;

    if (!compact) {
      classes += ' px-1 ';
    }

    if (toggled) {
      classes += ' rounded-lg bg-primary bg-opacity-10 text-primary ';
    } else {
      classes += ' rounded-full ';
    }

    if (loading) {
      classes += ' cursor-progress ';
    } else if (disabled) {
      classes += ' cursor-not-allowed ';
    } else {
      classes += ' cursor-pointer hover-overlay-5 ';
    }

    return classes.trim();
  }, [disabled, toggled, compact, loading]);

  const buildIcon = useCallback(
    (prefix: IconPrefix, icon?: IconName, spin?: boolean, color?: string) => {
      if (!icon) return null;

      if (loading) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              spin
              icon={faCircleNotch}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      if (disabled) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              icon={[toggled ? 'fas' : prefix, icon]}
              spin={spin}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      const isHex = color?.startsWith('#');
      return (
        <div className="size-6 flex-center">
          <FontAwesomeIcon
            icon={[toggled ? 'fas' : prefix, icon]}
            spin={spin}
            className={`text-base ${toggled ? color ?? 'text-primary' : color}`}
            style={{
              color: isHex ? color : undefined,
            }}
          />
        </div>
      );
    },
    [loading, disabled, toggled]
  );

  return (
    <>
      <button
        data-test-id={id}
        type="button"
        onClick={onClick}
        className={className}
        disabled={disabled}
        style={{
          height: compact ? '24px' : '32px',
        }}
        ref={buttonRefProp ?? buttonRef}
      >
        {buildIcon(
          leadingIconPrefix,
          leadingIcon,
          leadingIconSpin,
          leadingIconColor
        )}
        {label && (
          <div className={`px-1 ${disabled ? 'text-gray-textSecondary' : ''}`}>
            {label}
          </div>
        )}
        {buildIcon(
          trailingIconPrefix,
          trailingIcon,
          trailingIconSpin,
          trailingIconColor
        )}
      </button>
      {tooltip && tooltipPosition && (buttonRefProp ?? buttonRef) && (
        <Tooltip
          childRef={buttonRefProp ?? buttonRef}
          position={tooltipPosition}
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

ButtonIcon.Toggle = ButtonIconToggle;

export { ButtonIcon };
