import { PipelineJob } from '@agerpoint/api';

export enum PipelineStatus {
  preprocess = 'preprocess',
  analyze = 'analyze',
}

export interface IPipelineStatus {
  preprocess: PipelineJob[];
  analyze: PipelineJob[];
}

export enum PipelineStatusNames {
  inactive = 'inactive',
  active = 'active',
  complete = 'complete',
}

export interface IPipelineStatusStyle {
  container: string;
  text: string;
  border: string;
  status: PipelineStatusNames;
}
