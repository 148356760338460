import {
  IconDefinition,
  faChartBar,
  faFile,
  faLink,
  faPenToSquare,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faBoxArchive,
  faCheckCircle,
  faCircleCheck,
  faCircleNotch,
  faClone,
  faCopy,
  faCube,
  faEllipsisH,
  faExternalLink,
  faFileExport,
  faGrid2,
  faInfoSquare,
  faLocation,
  faLocationPinSlash,
  faMagnifyingGlassChart,
  faMap,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UseGetReturn, UseMutateReturn } from 'restful-react';
import compare from 'trivial-compare';
import { useDebouncyEffect } from 'use-debouncy';

import {
  APIClient,
  Capture,
  CaptureFilter,
  Customer,
  GetFilteredPageCapturesPathParams,
  Project,
  User,
  formatDate,
  useCloneCaptures,
  useDeleteCapture,
  useGetCaptureByUuid,
  useGetFilteredPageCaptures,
  useGetUsersAvailibleFromCaptures,
  usePutCaptureById,
} from '@agerpoint/api';
import {
  Button,
  ConfirmModal,
  Input,
  PrimaryButton,
  useIsMounted,
} from '@agerpoint/component';
import {
  CaptureModalTabs,
  LDFlagSet,
  LdFlags,
  OpenLayerMapController,
  OpenMapLayer,
  OptionalClaimLookup,
  ProjectNext,
  defaultCaptureMapFeatureStyle,
  getCaptureModeNiceName,
  getFirstActiveEptId,
  isCaptureReadyToViewInLegacyLayout,
  isCaptureReadyToViewInNewLayout,
  selectedCaptureMapFeatureStyle,
} from '@agerpoint/types';
import { BackgroundTaskResult } from '@agerpoint/types';
import {
  APIUtils,
  Sort,
  convertDateToUtcBeginningOfDay,
  convertDateToUtcEndOfDay,
  endOfDay,
  environment,
  getCapturesUnique3DModelList,
  hasPermission,
  isMobileTablet,
  useGlobalStore,
  useItemSelection,
  useLookupTable,
  useNewLayout,
  usePrevious,
  useQueryState,
  useToasts,
  uuidRegex,
} from '@agerpoint/utilities';

import { AddCapturesToProjectsModal } from '../add-captures-to-projects-modal/add-captures-to-projects-modal';
import { useBackgroundTaskManager } from '../background-task-manager/background-task-manager';
import { CapturesMapPopup } from '../capture-map-popover';
import { getContextMenuLink } from '../captures-shared/capture-details-modal/capture-modal.utils';
import { ManageCaptureWrapper } from '../captures-shared/capture-details-modal/manage-capture-wrapper';
import { CloneDatasetModal } from '../clone-dataset-modal/clone-dataset-modal';
import {
  ContextMenu,
  ContextMenuGroupProps,
} from '../context-menu/context-menu';
import { DatatableOld, dataTableAgerStyle } from '../datatable/datatable-old';
import { EditCapturesOrgModal } from '../edit-captures-org-modal/edit-captures-org-modal';
import { OpenLayerMap } from '../open-layer-map/open-layer-map';
import { TileViewOld } from '../tile-view/tile-view-old';
import { TileViewLayout } from '../tile-view/tile-view-types';
import { CSVDownloadModal } from './captures-table-download';
import { CaptureThumbnail, getUsername } from './captures-table-utilities';
import { BulkCaptureAnalyticRequestModal } from './utilities/bulk-capture-analytic-request-modal';

export enum CapturesViewDesktopMode {
  List = 'DesktopList',
  Grid = 'DesktopGrid',
}

export enum CapturesViewMobileMode {
  List = 'MobileList',
  SmallGrid = 'MobileSmallGrid',
  LargeGrid = 'MobileLargeGrid',
}

interface CapturesTableProps {
  customers: Customer[] | [];
  serverUrl: string;
  claims: OptionalClaimLookup;
  ldFlags: LDFlagSet;
  isOnAdminPage?: boolean;
}

export function CapturesTable({
  customers,
  serverUrl,
  claims,
  ldFlags,
  isOnAdminPage = false,
}: CapturesTableProps) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const prevParmas = usePrevious(params);
  const projectsQuery = APIClient.useGetProject({
    query: {
      queryKey: [APIUtils.QueryKey.projects],
      select: (data) => APIUtils.Sort.projects(data),
    },
  });
  const projects = useMemo(() => projectsQuery.data, [projectsQuery.data]);
  const {
    showNewLayout,
    toggleNewLayout,
    toggleLegacyLayout,
    showNewLayoutToggle,
  } = useNewLayout();

  const [captureId, setCaptureId] = useState<number>(NaN);
  const [hasCaptureReportPermission, setHasCaptureReportPermission] =
    useState(false);
  const [hasCaptureMapViewPermission, setHasCaptureMapViewPermission] =
    useState(false);

  const [hasSelfServeAnalytics, setHasSelfServeAnalytics] = useState(false);
  const backgroundTaskManager = useBackgroundTaskManager();
  const isMounted = useIsMounted();

  const toasts = useToasts();
  const queryClient = useQueryClient();

  const {
    actions: { setSiteWideLoading, dispatchEffect },
    permissions,
  } = useGlobalStore();

  const { captureId: id } = useParams();

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
    },
  });

  const isMobile = useMemo(() => isMobileTablet(), []);

  useEffect(() => {
    const mapViewPermission = hasPermission(
      LdFlags.CaptureListMapView,
      ldFlags
    );
    setHasCaptureMapViewPermission(mapViewPermission);

    const selfServeAnalytics = hasPermission(
      LdFlags.SelfServeAnalytics,
      permissions
    );
    setHasSelfServeAnalytics(selfServeAnalytics);

    const captureReport = hasPermission(LdFlags.CaptureReport, permissions);
    setHasCaptureReportPermission(captureReport);
  }, [permissions, ldFlags]);

  const [showAnalyticsModal, setShowAnalyticsModal] = useState<boolean>(false);
  const [viewLayout, setViewLayout] = useState<'Tiles' | 'Map' | 'List'>(
    isMobile ? 'Tiles' : 'List'
  );

  useEffect(() => {
    if (!captureQuery.data || !captureQuery.data?.id) return;
    const c = captureQuery.data;
    const index = captures.map((x) => x.id).indexOf(c.id);
    const newCaptures = [...captures];
    newCaptures[index] = c;
    setCaptures(newCaptures);
    setCaptureId(NaN);
  }, [captureQuery.data]);

  useEffect(() => {
    if (!captureId) return;

    queryClient.invalidateQueries({
      queryKey: [APIUtils.QueryKey.captures, { captureId: captureId }],
    });
  }, [captureId]);

  useEffect(() => {
    if (!prevParmas?.id) return;
    // If the modal is closed, we need to refetch the capture which could have been updated
    setCaptureId(prevParmas?.id as any);
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const savedLayout = localStorage.getItem('capturesListViewLayout') as
      | 'Tiles'
      | 'Map'
      | 'List';

    if (
      savedLayout === 'Tiles' ||
      savedLayout === 'Map' ||
      savedLayout === 'List'
    ) {
      setViewLayout(savedLayout);
    } else {
      setViewLayout(isMobile ? 'Tiles' : 'List');
    }
  }, [isMobile]);

  useEffect(() => {
    localStorage.setItem('capturesListViewLayout', viewLayout);
  }, [viewLayout]);

  const prepareChart = () => {
    navigate('/reports/captures', {
      state: {
        selectedCaptures: captureSelection.getSelectionArray(),
      },
    });
  };
  const [mapController, setMapController] = useState<OpenLayerMapController>();

  const [showAddCapturesToProjectsModal, setShowAddCapturesToProjectsModal] =
    useState(false);

  const [showCloneCapturesModal, setShowCloneCapturesModal] = useState(false);
  const [captureIdToClone, setCaptureIdToClone] = useState<number>();

  const [showEditCapturesOrgModal, setShowEditCapturesOrgModal] =
    useState(false);

  const [showCSVDownloadModal, setShowCSVDownloadModal] =
    useState<boolean>(false);

  const { mutate: deleteCapture, loading: loadingDeleteCapture } =
    useDeleteCapture({});

  const { mutate: putCapture, loading: loadingPutCapture } = usePutCaptureById({
    id: NaN,
  });

  const { mutate: cloneCaptures } = useCloneCaptures({});

  const {
    mutate: getCaptures,
    cancel: cancelPreviousGetCapturesCall,
    loading: loadingCaptures,
  } = useGetFilteredPageCaptures({
    skip: NaN,
    take: NaN,
  }) as unknown as UseMutateReturn<
    Capture[],
    void,
    CaptureFilter,
    void,
    GetFilteredPageCapturesPathParams
  >;

  const { refetch: requestCaptureByUUID } = useGetCaptureByUuid({
    uuid: '',
    lazy: true,
  }) as unknown as UseGetReturn<Capture[], void, void, { uuid: string }>;

  useEffect(() => {
    return () => {
      setSiteWideLoading(false);
    };
  }, []);

  const [captures, setCaptures] = useState<Capture[]>([]);

  const captureSelection = useItemSelection<number, Capture>({
    items: captures,
    idField: 'id',
    dependencies: [captures.map((c) => c.id).join(',')],
  });

  const captureSelectionSize = useMemo(() => {
    return captureSelection.selectionSize;
  }, [captureSelection]);

  const { data: captureUsers } = useGetUsersAvailibleFromCaptures(
    {}
  ) as unknown as {
    data: User[];
  };

  const customersLookupTable = useLookupTable(customers, 'id');

  const usersLookupTable = useLookupTable(captureUsers, 'id');

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a.trim(),
    toUrlParam: (a) => a.trim(),
  });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (isOnAdminPage && a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      if (isOnAdminPage) {
        return a ? 'yes' : '';
      }
      return '';
    },
  });

  const [selectedFilterProjects, setSelectedFilterProjects] = useQueryState<
    APIClient.Project[]
  >({
    paramName: 'projects',
    initialValue: [],
    fromUrlParam: (a) => {
      const splitted = a?.split(',');
      return [...(projects || [])]
        .sort((a, b) => compare(a.id, b.id))
        .filter((x) => x.id && splitted?.includes(x.id.toString() ?? ''));
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: projects ? projects.length > 0 : false,
  });

  const sortedProjects = useMemo(
    () => Sort.projects(projects as Project[]),
    [projects]
  );

  const [selectedFilterUsers, setSelectedFilterUsers] = useQueryState<User[]>({
    paramName: 'users',
    initialValue: [],
    fromUrlParam: (a) => {
      return captureUsers.filter((x) => a?.includes(x.id as string) ?? false);
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: (captureUsers?.length || 0) > 0,
  });

  const sortedUsers = useMemo(() => Sort.users(captureUsers), [captureUsers]);

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered = customers.filter((x) => {
          return splitted?.includes(x.id as number) ?? false;
        });
        return filtered;
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: customers.length > 0,
    });

  const sortedOrganizations = useMemo(
    () => Sort.organizations(customers),
    [customers]
  );

  const [selectedFilterCaptureDate, setSelectedFilterCaptureDate] =
    useQueryState<[Date | undefined, Date | undefined]>({
      paramName: 'capture_date',
      initialValue: [undefined, undefined],
      toUrlParam: (v) => {
        if (v?.[0] && v?.[1]) {
          const start = formatDate(v[0].toString());
          const end = formatDate(v[1].toString());

          return `${start}-${end}`;
        }
        return '';
      },
      fromUrlParam: (v) => {
        const dates = v.split('-');
        if (dates.length !== 2) {
          return [undefined, undefined];
        }

        const [start, end] = dates;

        const startDate = new Date(start);
        const endDate = new Date(end);

        return [startDate, endDate];
      },
    });

  const [selectedFilterCreateDate, setSelectedFilterCreateDate] = useQueryState<
    [Date | undefined, Date | undefined]
  >({
    paramName: 'create_date',
    initialValue: [undefined, undefined],
    toUrlParam: (v) => {
      if (v?.[0] && v?.[1]) {
        const start = formatDate(v[0].toString());
        const end = formatDate(v[1].toString());

        return `${start}-${end}`;
      }
      return '';
    },
    fromUrlParam: (v) => {
      const dates = v.split('-');
      if (dates.length !== 2) {
        return [undefined, undefined];
      }

      const [start, end] = dates;

      const startDate = new Date(start);
      const endDate = new Date(end);

      return [startDate, endDate];
    },
  });

  useDebouncyEffect(
    () => {
      const newFilter = {
        ...capturesRequestBody.current.filter,
        captureName: nameFilter.trim(),
      };
      if (isEqual(newFilter, capturesRequestBody.current.filter)) {
        return;
      }
      capturesRequestBody.current = { filter: newFilter, skip: 0 };

      getCapturesPage();
    },
    1000,
    [nameFilter]
  );

  useEffect(() => {
    const adjustedStartScanDate = convertDateToUtcBeginningOfDay(
      selectedFilterCaptureDate[0]
    );
    const adjustedEndScanDate = convertDateToUtcEndOfDay(
      selectedFilterCaptureDate[1]
    );

    const adjustedStartCreateDate = convertDateToUtcBeginningOfDay(
      selectedFilterCreateDate?.[0]
    );
    const adjustedEndCreateDate = convertDateToUtcEndOfDay(
      selectedFilterCreateDate?.[1]
    );

    const newFilter = {
      ...capturesRequestBody.current.filter,
      projectUuids: selectedFilterProjects.map((p) => p.uuid),
      userUuids: selectedFilterUsers.map((u) => u.id),
      customerId: selectedFilterOrganizations.map((o) => o.id),
      startScanDatetime: adjustedStartScanDate?.toISOString(),
      endScanDatetime: adjustedEndScanDate?.toISOString(),
      archived: archivedFilter,
      startCreatedDatetime: adjustedStartCreateDate?.toISOString(),
      endCreatedDatetime: adjustedEndCreateDate?.toISOString(),
    } as CaptureFilter;
    if (isEqual(newFilter, capturesRequestBody.current.filter)) {
      return;
    }
    capturesRequestBody.current = { filter: newFilter, skip: 0 };
    getCapturesPage();
  }, [
    selectedFilterProjects,
    selectedFilterUsers,
    selectedFilterOrganizations,
    selectedFilterCaptureDate,
    selectedFilterCreateDate,
    archivedFilter,
  ]);

  const capturesRequestBody = useRef<{ filter: CaptureFilter; skip: number }>({
    filter: {
      captureName: nameFilter.trim() || undefined,
      projectUuids:
        (selectedFilterProjects.map((p) => p.uuid) as string[]) || undefined,
      orderBy: 'scanDatetime',
      orderAscending: false,
      startScanDatetime: selectedFilterCaptureDate?.[0]?.toISOString(),
      endScanDatetime: endOfDay(selectedFilterCaptureDate?.[1])?.toISOString(),
      archived: archivedFilter,
    },
    skip: 0,
  });

  const [pagingExhausted, setPagingExhausted] = useState<boolean>(false);

  const getCapturesPage = useCallback(async () => {
    cancelPreviousGetCapturesCall();
    setSiteWideLoading(true);
    try {
      const uuidSearch =
        !!capturesRequestBody.current.filter.captureName &&
        uuidRegex.test(capturesRequestBody.current.filter.captureName);

      let response: Capture[] | null;
      if (isOnAdminPage && uuidSearch) {
        response = await requestCaptureByUUID({
          pathParams: {
            uuid: nameFilter,
          },
        });
      } else {
        response = await getCaptures(capturesRequestBody.current.filter, {
          pathParams: {
            skip: capturesRequestBody.current.skip,
            // Take more captures when in Map view
            take: 30,
          },
        });
      }

      if (!response) {
        return;
      }

      if (capturesRequestBody.current.skip === 0) {
        setCaptures([...response]);
      } else {
        setCaptures([...captures, ...response]);
      }

      if (response.length > 0 && !uuidSearch) {
        setPagingExhausted(false);
      } else {
        setPagingExhausted(true);
      }
    } catch (e: any) {
      if (e.message.includes('aborted')) {
        return;
      }
    }
    setSiteWideLoading(false);
  }, [
    cancelPreviousGetCapturesCall,
    captures,
    getCaptures,
    viewLayout,
    setSiteWideLoading,
  ]);

  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] =
    useState(false);
  const [showUnarchiveConfirmationModal, setShowUnarchiveConfirmationModal] =
    useState(false);
  const captureToArchive = useRef<Capture | undefined>();
  const captureToUnarchive = useRef<Capture | undefined>();

  async function archiveCapture() {
    if (!captureToArchive.current) {
      return;
    }
    const c = { ...captureToArchive.current };

    shareableCaptureArchive(c);
  }

  async function unarchiveCapture() {
    if (!captureToUnarchive.current) {
      return;
    }
    const c = { ...captureToUnarchive.current };

    shareableCaptureUnarchive(c);
  }

  const shareableCaptureUnarchive = async (c: Capture) => {
    if (!c.id) {
      return;
    }
    try {
      const index = captures.map((x) => x.id).indexOf(c.id);
      if (index === -1) {
        throw Error("Can't find capture");
      }
      const capture = { ...captures[index] };
      capture.archived = false;
      await putCapture(capture, {
        pathParams: {
          id: c.id,
        },
      });

      const newCaptures = [...captures];
      newCaptures.splice(index, 1);
      setCaptures(newCaptures);
      captureToArchive.current = undefined;
      setShowArchiveConfirmationModal(false);

      toasts.add(toasts.prepare.entityRestored('capture'));
    } catch (e) {
      toasts.add(toasts.prepare.error('Failed to restore capture!'));
    } finally {
      Promise.resolve();
    }
  };

  const shareableCaptureArchive = async (c: Capture) => {
    if (!c.id) {
      return;
    }
    try {
      await deleteCapture(c.id);
      const index = captures.map((x) => x.id).indexOf(c.id);
      if (index > -1) {
        const newCaptures = [...captures];
        newCaptures.splice(index, 1);
        setCaptures(newCaptures);
      }

      captureToArchive.current = undefined;
      setShowArchiveConfirmationModal(false);

      toasts.add(toasts.prepare.entityArchived('capture'));
    } catch (e) {
      toasts.add(toasts.prepare.error('Failed to archive capture!'));
    } finally {
      Promise.resolve();
    }
  };

  const copyCapturesUUIDToClipboard = useCallback(
    (capture: Capture) => {
      if (!capture || !capture.captureUuid) {
        toasts.add(toasts.prepare.error("Failed to copy the capture's UUID!"));
        return;
      }

      try {
        navigator.clipboard.writeText(capture.captureUuid);

        toasts.add(toasts.prepare.valueCopied('capture UUID'));
      } catch (e) {
        console.error(e);
        toasts.add(toasts.prepare.error("Failed to copy the capture's UUID!"));
      }
    },
    [toasts]
  );

  const contextMenuItems = useCallback(
    (item: Capture) => {
      const insightsGroup: ContextMenuGroupProps = {
        label: 'Insights',
        items: [
          {
            icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
            label: 'Analytics',
            visible: hasSelfServeAnalytics,
            onClick: () => {
              if (!item.id) return;

              const linkPath = getContextMenuLink(
                CaptureModalTabs.analytics,
                isOnAdminPage,
                item.id,
                location.search
              );
              navigate(linkPath);
            },
          },
          {
            icon: <FontAwesomeIcon icon={faCube} />,
            label: '3D Models',
            onClick: () => {
              if (!item.id) return;

              const linkPath = getContextMenuLink(
                CaptureModalTabs.models,
                isOnAdminPage,

                item.id,
                location.search
              );
              navigate(linkPath);
            },
          },
          {
            icon: <FontAwesomeIcon icon={faInfoSquare} />,
            label: 'Details',
            disabled: false,
            onClick: () => {
              if (!item.id) return;

              const linkPath = getContextMenuLink(
                CaptureModalTabs.details,
                isOnAdminPage,
                item.id,
                location.search
              );
              navigate(linkPath);
            },
          },
        ],
      };

      const managementGroup: ContextMenuGroupProps = {
        label: 'Management',
        items: [
          {
            icon: <FontAwesomeIcon icon={faBoxArchive} />,
            label: 'Archiving',
            onClick: async () => {
              if (!item.id) return;

              const linkPath = getContextMenuLink(
                CaptureModalTabs.details,
                isOnAdminPage,
                item.id,
                location.search
              );
              navigate(linkPath);
            },
          },
          {
            icon: <FontAwesomeIcon icon={faClone} />,
            visible: isOnAdminPage,
            label: 'Clone',
            onClick: () => {
              setCaptureIdToClone(item.id);
              setShowCloneCapturesModal(true);
            },
          },
        ],
      };

      const otherGroup: ContextMenuGroupProps = {
        label: 'Other',
        items: [
          {
            icon: <FontAwesomeIcon icon={faCopy} />,
            label: 'Copy UUID',
            visible: isOnAdminPage,
            onClick: () => {
              copyCapturesUUIDToClipboard(item);
            },
          },
          {
            icon: <FontAwesomeIcon icon={faExternalLink} />,
            label: 'New Window',
            onClick: () => {
              const canViewCapture = showNewLayout
                ? isCaptureReadyToViewInNewLayout(item)
                : isCaptureReadyToViewInLegacyLayout(item);

              if (!canViewCapture) {
                toasts.add({
                  title: 'Capture not ready.',
                  message:
                    'This capture is not ready or is missing a complete pointcloud.',
                  type: 'info',
                });
                return;
              }

              const url = showNewLayout
                ? `/captures/${item.id}`
                : `/captures/${item.id}/${getFirstActiveEptId(item)}`;

              window.open(url, '_blank', 'noopener noreferrer');
            },
          },
        ],
      };

      return [insightsGroup, managementGroup, otherGroup];
    },
    [
      isOnAdminPage,
      toasts,
      copyCapturesUUIDToClipboard,
      hasSelfServeAnalytics,
      location.search,
      navigate,
      showNewLayout,
    ]
  );

  const rowOnClick = useCallback(
    (capture: Capture, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const canViewCapture = showNewLayout
        ? isCaptureReadyToViewInNewLayout(capture)
        : isCaptureReadyToViewInLegacyLayout(capture);

      if (!canViewCapture) {
        toasts.add({
          title: 'Capture not ready.',
          message:
            'This capture is not ready or is missing a complete pointcloud.',
          type: 'info',
        });
        return;
      }

      const url = showNewLayout
        ? `/captures/${capture.id}`
        : `/captures/${capture.id}/${getFirstActiveEptId(capture)}`;
      if (event.metaKey || event.ctrlKey) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    },
    [toasts, navigate, showNewLayout]
  );

  /// We're wrapping our Datatable2 with useMemo hook to control
  /// when the table should be re-rendered to improve performance.
  const MemoizedDatatable = useMemo(
    () => (
      <DatatableOld
        data={captures}
        style={{ ...dataTableAgerStyle, tableMinWidth: 900 }}
        rowHeight={60}
        columns={[
          {
            label: '',
            value: function Thumbnail(row) {
              return (
                <CaptureThumbnail
                  capture={row}
                  ldFlags={ldFlags}
                  size="small"
                />
              );
            },
            name: 'thumbnail',
          },
          {
            label: 'Name',
            value: (row) => {
              return (
                <span title={row.captureName || ''}>{row.captureName}</span>
              );
            },
            flex: 2,
            name: 'captureName',
            sortKey: 'captureName',
          },
          {
            label: 'Capture Date',
            value: (row) =>
              row.scanDatetime ? formatDate(row.scanDatetime) : null,
            name: 'captureScanDate',
            sortKey: 'scanDatetime',
          },
          {
            label: 'Create Date',
            value: (row) =>
              row.createDatetime ? formatDate(row.createDatetime) : null,
            name: 'captureCreateDate',
            sortKey: 'createDatetime',
            visible: isOnAdminPage ?? false,
          },
          {
            label: 'Mode',
            value: (row) => {
              if (!row.captureModeId) return '-';
              return getCaptureModeNiceName(row.captureModeId);
            },
            visible: isOnAdminPage ?? false,
          },
          {
            label: '3D Model',
            value: (row) => {
              return getCapturesUnique3DModelList(row)?.join(', ');
            },

            name: 'resolution',
          },
          {
            label: 'Size (MB)',
            value: (row) => row.fileSize,
            name: 'fileSize',
            sortKey: 'fileSize',
          },
          {
            label: 'User',
            name: 'userName',
            value: (row) => {
              if (!row.createdById) {
                return null;
              }

              if (!usersLookupTable) {
                return <FontAwesomeIcon icon={faCircleNotch} spin />;
              }

              const user = usersLookupTable?.[row.createdById];

              return getUsername(user);
            },
          },
          {
            label: 'Organization',
            value: (row) => {
              if (!row.customerId) {
                return null;
              }

              if (!customersLookupTable) {
                return <FontAwesomeIcon icon={faCircleNotch} spin />;
              }

              const customer = customersLookupTable?.[row.customerId];

              return (
                customer?.customerDisplayName ?? customer?.customerName ?? null
              );
            },
            name: 'organization',
          },
          {
            label: 'Actions',
            value: function Action(row, index) {
              if (row.id) {
                return (
                  <ContextMenu
                    dataTestId={`captures-list-context-menu-${index}`}
                    groups={contextMenuItems(row)}
                  />
                );
              }
              return null;
            },
            style: { columnWrapperStyle: 'flex justify-center' },
          },
          {
            label: (
              <button
                data-test-id="captures-list-select-all"
                className={`border rounded p-1 whitespace-nowrap
        flex flex-row gap-1 justify-between items-center`}
                type="button"
                onClick={() => {
                  captureSelection.toggleSelectionEverything();
                }}
              >
                <span>Select All</span>
                <FontAwesomeIcon
                  data-test-id="captures-list-select-all"
                  className={`${
                    captureSelection.isEverythingSelected
                      ? 'text-gray-900'
                      : 'text-gray-300'
                  }`}
                  icon={faCircleCheck}
                />
              </button>
            ),
            value: function Select(row, index) {
              const isSelected = row.id
                ? captureSelection.isSelected(row.id)
                : false;
              return (
                <FontAwesomeIcon
                  data-test-id="captures-list-select-one"
                  data-test-is-selected={isSelected ? index : undefined}
                  icon={faCheckCircle}
                  className={`text-lg ${
                    isSelected
                      ? 'text-gray-900'
                      : 'text-gray-300 hover:text-gray-500'
                  } cursor-pointer`}
                  onClick={(e) => {
                    if (!row.id) {
                      return;
                    }
                    if (e.shiftKey) {
                      captureSelection.addBulkSelectionUntilItem(row.id, row);
                    } else {
                      captureSelection.toggleSelection(row.id, row);
                    }
                  }}
                />
              );
            },
            style: { columnWrapperStyle: 'flex justify-center' },
          },
        ]}
        cellOnClick={(columnName) => {
          const clickable = [
            'thumbnail',
            'captureName',
            'captureScanDate',
            'captureCreateDate',
            'resolution',
            'fileSize',
            'numberImages',
            'organization',
            'userName',
          ];
          if (clickable.includes(columnName)) {
            return rowOnClick;
          }
          return;
        }}
        pagination={{
          loadPage: () => {
            if (loadingCaptures || pagingExhausted) {
              return;
            }
            capturesRequestBody.current = {
              ...capturesRequestBody.current,
              skip: captures.length,
            };
            getCapturesPage();
          },
          hasNextPage: !pagingExhausted,
          threshold: 10,
        }}
        initialSortingOptions={{
          key: 'scanDatetime',
          order: 'desc',
        }}
        sortingChanged={(options) => {
          capturesRequestBody.current = {
            filter: {
              ...capturesRequestBody.current.filter,
              orderBy: options.key,
              orderAscending: options.order === 'asc',
            },
            skip: 0,
          };
          getCapturesPage();
        }}
      />
    ),
    [
      captures,
      captureSelectionSize,
      loadingCaptures,
      ldFlags,
      pagingExhausted,
      isOnAdminPage,
      usersLookupTable,
      showNewLayout,
    ]
  );

  const viewSelector = useMemo(() => {
    const ViewSelectorButton = ({
      id,
      layout,
      icon,
    }: {
      id: string;
      layout: 'Map' | 'List' | 'Tiles';
      icon: IconDefinition;
    }) => {
      return (
        <button
          data-test-id={id}
          className={`flex p-2 items-center justify-center w-9 h-9 ${
            viewLayout === layout
              ? 'bg-gray-200 cursor-default'
              : 'hover:bg-gray-100'
          }`}
          onClick={() => {
            if (viewLayout !== layout) {
              setViewLayout(layout);
            }
          }}
        >
          <FontAwesomeIcon icon={icon} />
        </button>
      );
    };

    if (isMobile) {
      return null;
    }

    return (
      <div className="border border-gray-500 rounded flex flex-row bg-white overflow-hidden">
        {!isMobile && (
          <>
            {hasCaptureMapViewPermission && (
              <ViewSelectorButton
                icon={faMap}
                id="map-view-button"
                layout="Map"
              />
            )}

            <ViewSelectorButton
              icon={faBars}
              id="list-view-button"
              layout={'List'}
            />
            <ViewSelectorButton
              icon={faGrid2}
              id="small-grid-view-button"
              layout={'Tiles'}
            />
          </>
        )}
      </div>
    );
  }, [hasCaptureMapViewPermission, isMobile, viewLayout]);

  const resetTableContents = useCallback(() => {
    setCaptures([]);

    capturesRequestBody.current = {
      filter: capturesRequestBody.current.filter,
      skip: 0,
    };
    getCapturesPage();
  }, [capturesRequestBody, setCaptures]);

  const SidebarToggleButtons = () => {
    return showNewLayout ? (
      <Button.Small
        id="legacy-layout-toggle"
        label="Switch to Legacy Viewer"
        onClick={() => {
          const eptId = getFirstActiveEptId(captureQuery.data);

          toggleLegacyLayout(eptId);
        }}
      />
    ) : (
      <Button.Small
        id="captures-layout-toggle"
        label="Switch to Beta Viewer"
        onClick={() => {
          toggleNewLayout();
        }}
      />
    );
  };

  return (
    <div
      className={`flex flex-col flex-1 overflow-hidden relative px-4 pt-2 h-full `}
    >
      <header>
        <div className="flex max-w-7xl mx-auto items-start justify-start">
          <div className="flex-grow">
            <h1
              className="text-3xl font-bold pb-2"
              data-test-id="captures-table-header"
            >
              Captures
            </h1>
          </div>
          {captureSelection.hasSelectedItems ? (
            <div className="flex flex-row flex-wrap justify-end items-center gap-1">
              {hasSelfServeAnalytics && (
                <PrimaryButton
                  testId="analytics-captures-button"
                  onClicked={() => {
                    setShowAnalyticsModal(true);
                  }}
                  label="Analytics"
                  icon={<FontAwesomeIcon icon={faChartBar} />}
                />
              )}
              {hasCaptureReportPermission && (
                <PrimaryButton
                  testId="captures-report-button"
                  onClicked={() => {
                    prepareChart();
                  }}
                  label="Capture Report"
                  icon={<FontAwesomeIcon icon={faFile} />}
                />
              )}
              {isOnAdminPage && (
                <PrimaryButton
                  testId="clone-captures-button"
                  onClicked={() => {
                    setShowCloneCapturesModal(true);
                  }}
                  label="Clone"
                  icon={<FontAwesomeIcon icon={faClone} />}
                />
              )}
              <PrimaryButton
                testId="add-to-project-button"
                onClicked={() => {
                  setShowAddCapturesToProjectsModal(true);
                }}
                label="Add To Project"
                icon={<FontAwesomeIcon icon={faPlus} />}
              />
              {(customers?.length ?? 0) > 0 && (
                <PrimaryButton
                  testId="edit-organization-button"
                  onClicked={() => {
                    setShowEditCapturesOrgModal(true);
                  }}
                  label="Edit Organization"
                  icon={<FontAwesomeIcon icon={faPenToSquare} />}
                />
              )}
              {!isMobile && (
                <PrimaryButton
                  testId="export-captures-button"
                  onClicked={() => {
                    setShowCSVDownloadModal(true);
                  }}
                  label="Export"
                  icon={<FontAwesomeIcon icon={faFileExport} />}
                />
              )}
              <div className="my-1 mx-3 w-full">
                <div className="text-sm text-gray-800 flex justify-end">
                  <div className="flex flex-row gap-1 items-center">
                    <span data-test-id="captures-list-selection-counter">
                      {captureSelectionSize} selected
                    </span>
                    <button
                      data-test-id="captures-list-deselect-all"
                      type="button"
                      title="Clear Selection"
                      className="rounded-full hover:bg-gray-200 w-4 h-4 flex justify-center items-center"
                      onClick={() => {
                        captureSelection.clearSelection();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : showNewLayoutToggle ? (
            <SidebarToggleButtons />
          ) : null}
        </div>
      </header>
      <div className="flex max-w-7xl mx-auto w-full flex-wrap xl:flex-nowrap items-center gap-y-1">
        <div className=" w-1/2 lg:w-auto order-1 pr-2">
          <div className="lg:w-72">
            <Input.Text.Single
              id="capture-table-search"
              value={nameFilter}
              setValue={setNameFilter}
              placeholderIcon={Input.placeholderIcons.search}
              placeholder={
                isOnAdminPage ? 'Search by Name or UUID' : 'Search by Name'
              }
            />
          </div>
        </div>

        <div
          className={`flex-grow overflow-visible order-3 lg:order-2
           flex flex-row gap-1 items-stretch`}
        >
          <Input.Select.Multi
            id="project-filter-select"
            placeholder="Projects"
            title="Projects"
            options={(sortedProjects as ProjectNext[]) ?? []}
            value={selectedFilterProjects}
            loading={(sortedProjects?.length ?? 0) === 0}
            setValue={setSelectedFilterProjects}
            optionBuilder={(o) => o.name}
            maxWidth="232px"
          />

          <Input.Select.Multi
            id="user-filter-select"
            placeholder="Users"
            title="Users"
            options={sortedUsers ?? []}
            value={selectedFilterUsers}
            loading={(sortedUsers?.length ?? 0) === 0}
            setValue={setSelectedFilterUsers}
            optionBuilder={(o) =>
              `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
            }
            maxWidth="232px"
          />
          {(sortedOrganizations?.length ?? 0) > 0 && (
            <Input.Select.Multi
              id="organization-filter-select"
              placeholder="Organizations"
              title="Organizations"
              options={sortedOrganizations ?? []}
              value={selectedFilterOrganizations}
              loading={(sortedOrganizations?.length ?? 0) === 0}
              setValue={setSelectedFilterOrganizations}
              optionBuilder={(o) =>
                o.customerDisplayName ?? o.customerName ?? 'Unknown'
              }
              maxWidth="232px"
            />
          )}
          <div className="w-48">
            <Input.Date.Range
              id="capture-date-filter"
              placeholder="Capture Date Range"
              value={selectedFilterCaptureDate}
              setValue={setSelectedFilterCaptureDate}
            />
          </div>
          {isOnAdminPage && (
            <div className="w-48">
              <Input.Date.Range
                id="create-date-filter"
                placeholder="Create Date Range"
                value={selectedFilterCreateDate}
                setValue={setSelectedFilterCreateDate}
              />
            </div>
          )}
          {isOnAdminPage && (
            <Input.Select.Inline
              id="capture-status-filter"
              options={[false, true]}
              optionBuilder={(o) => (o ? 'Archived' : 'Active')}
              value={archivedFilter}
              setValue={setArchivedFilter}
            />
          )}
          <Button.ClearFilter
            visible={
              !!(
                selectedFilterOrganizations.length > 0 ||
                selectedFilterUsers.length > 0 ||
                selectedFilterProjects.length > 0 ||
                nameFilter.trim() ||
                (selectedFilterCaptureDate?.[0] &&
                  selectedFilterCaptureDate?.[1]) ||
                (selectedFilterCreateDate?.[0] && selectedFilterCreateDate?.[1])
              )
            }
            onClick={() => {
              setSelectedFilterOrganizations([]);
              setSelectedFilterProjects([]);
              setSelectedFilterUsers([]);
              setNameFilter('');
              setSelectedFilterCaptureDate([undefined, undefined]);
              setSelectedFilterCreateDate([undefined, undefined]);

              const searchParams = new URLSearchParams(window.location.search);
              searchParams.delete('organizations');
              searchParams.delete('users');
              searchParams.delete('name');
              searchParams.delete('capture_date');
              searchParams.delete('create_date');
              searchParams.delete('projects');
              navigate(
                {
                  pathname: isOnAdminPage ? '/admin/captures' : '/captures',
                  search: searchParams.toString(),
                },
                {
                  replace: true,
                }
              );
            }}
          />
        </div>
        {viewLayout === 'Tiles' && (
          <div className="order-2 lg:order-3 ml-auto mr-2">
            <button
              data-test-id="captures-list-select-all"
              className={`border border-gray-500 rounded p-1 whitespace-nowrap bg-white
                flex flex-row gap-1 justify-between items-center text-sm`}
              type="button"
              onClick={() => {
                captureSelection.toggleSelectionEverything();
              }}
            >
              <span>Select All</span>
              <FontAwesomeIcon
                className={`${
                  captureSelection.isEverythingSelected
                    ? 'text-gray-900'
                    : 'text-gray-300'
                }`}
                icon={faCircleCheck}
              />
            </button>
          </div>
        )}

        <div className="order-2 lg:order-3 ml-auto">{viewSelector}</div>
      </div>
      {viewLayout === 'List' && (
        <main className="max-h-full min-h-0 max-w-7xl mx-auto flex h-full w-full">
          <div className="py-2 w-full h-full">{MemoizedDatatable}</div>
        </main>
      )}
      {viewLayout === 'Map' && hasCaptureMapViewPermission && (
        <main className="max-h-full min-h-0 max-w-7xl mx-auto flex h-full w-full py-2">
          <div className="w-full h-full flex flex-row">
            <div className="w-1/3">
              <DatatableOld
                rowHeight={30}
                style={{
                  tableWrapperStyle: 'bg-white rounded-l-md',
                  headerWrapperStyle:
                    'px-4 text-xs text-gray-700 font-normal border-b border-gray-500',
                  rowWrapperStyle:
                    'px-4 items-center text-sm hover:bg-gray-100',
                  rowStyle: 'border-b border-gray-200',
                  headerStyle: 'pr-1 py-3 h-full flex items-center',
                  cellStyle: 'pr-1 flex items-center',
                }}
                data={captures}
                columns={[
                  {
                    label: 'Name',
                    value: (row) => row.captureName,
                    style: {
                      headerStyle: 'overflow-visible',
                    },
                    flex: 11,
                  },
                  {
                    label: '',
                    value: (row) => {
                      const canViewCapture = showNewLayout
                        ? isCaptureReadyToViewInNewLayout(row)
                        : isCaptureReadyToViewInLegacyLayout(row);
                      if (!canViewCapture) {
                        return (
                          <FontAwesomeIcon
                            icon={faLink}
                            aria-disabled
                            className="text-gray-300"
                            onClick={() => {
                              toasts.add({
                                title: 'Capture not ready.',
                                message:
                                  'This capture is not ready or is missing a complete pointcloud.',
                                type: 'info',
                              });
                            }}
                          />
                        );
                      }

                      const url = showNewLayout
                        ? `/captures/${row.id}`
                        : `/captures/${row.id}/${getFirstActiveEptId(row)}`;
                      return (
                        <FontAwesomeIcon
                          icon={faLink}
                          className="cursor-pointer"
                          onClick={() => navigate(url)}
                        />
                      );
                    },
                  },
                  {
                    label: '',
                    value: (row) => {
                      return row.latitude && row.longitude ? (
                        <FontAwesomeIcon
                          icon={faLocation}
                          className={
                            'text-gray-300 cursor-pointer hover:text-gray-900'
                          }
                          onClick={() => {
                            if (row.latitude && row.longitude) {
                              mapController?.zoomMapToLonLat?.([
                                row.longitude,
                                row.latitude,
                              ]);
                            }
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faLocationPinSlash}
                          className={'text-gray-300'}
                        />
                      );
                    },
                    flex: 1,
                    style: {
                      columnWrapperStyle: 'flex justify-center',
                      headerStyle: 'overflow-visible',
                    },
                  },
                  {
                    label: (
                      <button
                        data-test-id="captures-list-select-all"
                        className={`border rounded p-1 whitespace-nowrap
                flex flex-row gap-1 justify-between items-center`}
                        type="button"
                        onClick={() => {
                          captureSelection.toggleSelectionEverything();
                        }}
                      >
                        <span>Select All</span>
                        <FontAwesomeIcon
                          data-test-id="captures-list-select-all"
                          className={`${
                            captureSelection.isEverythingSelected
                              ? 'text-gray-900'
                              : 'text-gray-300'
                          }`}
                          icon={faCircleCheck}
                        />
                      </button>
                    ),
                    value: function Select(row, index) {
                      const isSelected = row.id
                        ? captureSelection.isSelected(row.id)
                        : false;
                      return (
                        <FontAwesomeIcon
                          data-test-id="captures-list-select-one"
                          data-test-is-selected={isSelected ? index : undefined}
                          icon={faCheckCircle}
                          className={`text-lg ${
                            isSelected
                              ? 'text-gray-900'
                              : 'text-gray-300 hover:text-gray-500'
                          } cursor-pointer`}
                          onClick={(e) => {
                            if (!row.id) {
                              return;
                            }
                            if (e.shiftKey) {
                              captureSelection.addBulkSelectionUntilItem(
                                row.id,
                                row
                              );
                            } else {
                              captureSelection.toggleSelection(row.id, row);
                            }
                          }}
                        />
                      );
                    },
                    style: {
                      columnWrapperStyle: 'flex justify-end',
                      headerStyle: 'overflow-visible',
                    },
                    flex: 1,
                  },
                ]}
                pagination={{
                  loadPage: () => {
                    if (loadingCaptures || pagingExhausted) {
                      return;
                    }
                    capturesRequestBody.current = {
                      ...capturesRequestBody.current,
                      skip: captures.length,
                    };
                    getCapturesPage();
                  },
                  hasNextPage: !pagingExhausted,
                  threshold: 75,
                }}
              />
            </div>
            <div className="w-2/3 rounded-r overflow-hidden border-white border-y-2 border-r-2">
              <OpenLayerMap
                id="captures-map"
                bingKey={environment.bing_api_key}
                controller={setMapController}
                mapLayers={{
                  used: [
                    OpenMapLayer.Hybrid,
                    OpenMapLayer.Aerial,
                    OpenMapLayer.RoadMap,
                  ],
                  initial: OpenMapLayer.Aerial,
                }}
                featureLayer={{
                  data: captures,
                  styles: {
                    default: defaultCaptureMapFeatureStyle,
                    selected: selectedCaptureMapFeatureStyle,
                  },
                  featureGenerator: (data: any) => {
                    if (!data.longitude || !data.latitude || !data.id) {
                      return null;
                    }

                    return {
                      id: data.id,
                      longitude: data.longitude,
                      latitude: data.latitude,
                      name: data.captureName ?? undefined,
                      style: !captureSelection.isSelected(data.id)
                        ? 'default'
                        : 'selected',
                    };
                  },
                  popupGenerator: (id: any) => {
                    const capture = captures.find((c) => id === c.id);
                    if (!capture) {
                      return null;
                    }

                    return (
                      <CapturesMapPopup
                        capture={capture}
                        selection={captureSelection}
                      />
                    );
                  },
                }}
                callbacks={{
                  onDragBoxSelect: (ids: any) => {
                    const c = captures
                      .filter((c) => ids.includes(c.id ?? -1))
                      .map((c) => ({
                        key: c.id ?? -1,
                        value: c,
                      }));
                    captureSelection.addSelectionList(c);
                  },
                }}
                dependencies={[captureSelectionSize, captures]}
              />
            </div>
          </div>
        </main>
      )}
      {viewLayout === 'Tiles' ? (
        <main className="max-h-full h-full min-h-0 max-w-7xl mx-auto flex w-full pt-2">
          <TileViewOld
            pagination={{
              hasNextPage: !pagingExhausted,
              loadPage: async () => {
                if (loadingCaptures || pagingExhausted) {
                  return;
                }
                capturesRequestBody.current = {
                  ...capturesRequestBody.current,
                  skip: captures.length,
                };
                await getCapturesPage();
              },
            }}
            data={captures}
            layout={TileViewLayout.SmallGrid}
            tileGenerator={(c, layout, index) => {
              const isSelected = c.id
                ? captureSelection.isSelected(c.id)
                : false;

              const organization = customersLookupTable?.[c.customerId ?? ''];

              const dataLayout = (
                <div
                  className={`p-2 flex flex-col justify-between ${
                    isMobile ? 'h-20' : 'h-24'
                  }`}
                >
                  <h5
                    className="mb-1 text-sm tracking-tight text-gray-900
                  truncate cursor-pointer"
                  >
                    {c.captureName}
                  </h5>
                  <div className="font-normal text-xs text-gray-700 flex flex-col">
                    <p className="flex justify-between">
                      <span>
                        {c.scanDatetime ? formatDate(c.scanDatetime) : null}
                      </span>
                      {isMobile && c.fileSize && (
                        <span>{`${c.fileSize} MB`}</span>
                      )}
                      {!isMobile && (
                        <span>
                          {getCapturesUnique3DModelList(c)?.join(', ')}
                        </span>
                      )}
                    </p>
                    {!isMobile && (
                      <p className="flex justify-between">
                        {c.fileSize && <span>{`${c.fileSize} MB`}</span>}
                        {c.numberImages && (
                          <span>{`${c.numberImages} images`}</span>
                        )}
                      </p>
                    )}
                    {organization &&
                      (organization.customerDisplayName ||
                        organization.customerName) && (
                        <p className="flex justify-start">
                          <span>
                            {organization.customerDisplayName ??
                              organization.customerName}
                          </span>
                        </p>
                      )}
                  </div>
                </div>
              );
              let tile;
              if (layout === TileViewLayout.List) {
                tile = (
                  <div>
                    <div
                      style={{
                        boxShadow: isSelected ? '0px 0px 0px 3px #339BC0' : '',
                        borderColor: isSelected
                          ? 'rgba(51, 155, 192, var(--tw-border-opacity))'
                          : 'rgba(226, 226, 226, var(--tw-border-opacity))',
                      }}
                      className="bg-white border-gray-200 border shadow rounded-lg"
                    >
                      <div className="flex justify-between items-center pr-2">
                        <ContextMenu
                          dataTestId={`captures-list-context-menu-${index}`}
                          groups={contextMenuItems(c)}
                        />
                        <div
                          className="rounded-full w-4 text-lg"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!c.id) {
                              return;
                            }
                            if (e.shiftKey) {
                              captureSelection.addBulkSelectionUntilItem(
                                c.id,
                                c
                              );
                            } else {
                              captureSelection.toggleSelection(c.id, c);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            data-test-id="captures-list-select-one"
                            data-test-is-selected={
                              isSelected ? index : undefined
                            }
                            icon={faCheckCircle}
                            className={`w-full h-full ${
                              isSelected
                                ? 'text-gray-900'
                                : 'text-gray-300 hover:text-gray-500'
                            } cursor-pointer`}
                          />
                        </div>
                      </div>
                      {dataLayout}
                    </div>
                  </div>
                );
              } else {
                tile = (
                  <div>
                    <div
                      style={{
                        boxShadow: isSelected ? '0px 0px 0px 3px #339BC0' : '',
                        borderColor: isSelected
                          ? 'rgba(51, 155, 192, var(--tw-border-opacity))'
                          : 'rgba(226, 226, 226, var(--tw-border-opacity))',
                      }}
                      className="bg-white border-gray-200 border shadow rounded-lg"
                    >
                      <div className="relative">
                        <CaptureThumbnail
                          size="big"
                          capture={c}
                          ldFlags={ldFlags}
                        />
                        <div
                          className="absolute top-2 right-2 bg-white rounded-full w-6 h-6 text-lg shadow-md"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!c.id) {
                              return;
                            }
                            if (e.shiftKey) {
                              captureSelection.addBulkSelectionUntilItem(
                                c.id,
                                c
                              );
                            } else {
                              captureSelection.toggleSelection(c.id, c);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            data-test-id="captures-list-select-one"
                            data-test-is-selected={
                              isSelected ? index : undefined
                            }
                            icon={faCheckCircle}
                            className={`w-full h-full ${
                              isSelected
                                ? 'text-gray-900'
                                : 'text-gray-300 hover:text-gray-500'
                            } cursor-pointer`}
                          />
                        </div>
                        <div className="absolute bottom-2 right-2">
                          <ContextMenu
                            dataTestId={`captures-list-context-menu-${index}`}
                            groups={contextMenuItems(c)}
                            icon={
                              <span
                                className={`w-8 rounded-full bg-white hover:bg-gray-50
                              flex items-center justify-center h-4`}
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="text-gray-700"
                                />
                              </span>
                            }
                          />
                        </div>
                      </div>
                      {dataLayout}
                    </div>
                  </div>
                );
              }
              return (
                <div className="p-1">
                  <div
                    className="hover:cursor-pointer bg-white border-gray-200 border shadow rounded-lg"
                    onClick={(e) => {
                      if (e.defaultPrevented) {
                        return;
                      }
                      rowOnClick(c, e);
                    }}
                  >
                    {tile}
                  </div>
                </div>
              );
            }}
          />
        </main>
      ) : null}

      <CSVDownloadModal
        open={showCSVDownloadModal}
        handleCloseDialog={() => {
          setShowCSVDownloadModal(false);
        }}
        selectedCaptures={captureSelection}
        serverUrl={serverUrl}
        userLookup={usersLookupTable}
      />

      <ManageCaptureWrapper
        archiveCapture={shareableCaptureArchive}
        unarchiveCapture={shareableCaptureUnarchive}
        refetchCaptureTable={resetTableContents}
      />

      <BulkCaptureAnalyticRequestModal
        showAnalyticsModal={showAnalyticsModal}
        setShowAnalyticsModal={setShowAnalyticsModal}
        selectedCaptures={captureSelection.getSelectionArray()}
      />

      <ConfirmModal
        title="Archive Capture"
        message="Are you sure you want to archive this capture?"
        canConfirm={!!captureToArchive.current && !loadingDeleteCapture}
        isOpen={showArchiveConfirmationModal}
        close={{
          label: 'Cancel',
          callback: () => {
            captureToArchive.current = undefined;
            setShowArchiveConfirmationModal(false);
          },
        }}
        confirm={{ label: 'Archive', callback: archiveCapture }}
      />

      <ConfirmModal
        title="RestoreCapture"
        message="Are you sure you want to restore this capture?"
        canConfirm={!!captureToUnarchive.current && !loadingDeleteCapture}
        isOpen={showUnarchiveConfirmationModal}
        close={{
          label: 'Cancel',
          callback: () => {
            captureToUnarchive.current = undefined;
            setShowUnarchiveConfirmationModal(false);
          },
        }}
        confirm={{ label: 'Restore', callback: unarchiveCapture }}
      />

      <CloneDatasetModal
        title="Clone Capture(s)"
        handleCloseDialog={() => {
          setCaptureIdToClone(undefined);
          setShowCloneCapturesModal(false);
        }}
        open={showCloneCapturesModal}
        cloningCount={captureIdToClone ? 1 : captureSelectionSize}
        organizations={customers}
        users={captureUsers}
        onClone={(org, user) => {
          const captureIds: number[] = captureIdToClone
            ? [captureIdToClone]
            : captureSelection
                .getSelectionArray()
                .map((c) => c.id ?? NaN)
                .filter((c) => !Number.isNaN(c));

          backgroundTaskManager.addTaskGroup({
            groupDesc: 'Cloning Capture(s)',
            isCancellable: true,
            groupTasks: [
              backgroundTaskManager.createBackgroundTask(
                'Cloning Capture(s)',
                async (resolve, _, abortSignal) => {
                  await cloneCaptures(
                    {
                      assignToUserUuid: user,
                      captureIds: captureIds,
                      customerId: org,
                    },
                    { signal: abortSignal }
                  );

                  resolve({ type: BackgroundTaskResult.success });
                }
              ),
            ],
            onError: async () => {
              toasts.add(toasts.prepare.error('Failed to clone capture(s)!'));
            },
            onSuccess: async () => {
              toasts.add(toasts.prepare.entityCloned('capture(s)'));
              if (isMounted.current) {
                capturesRequestBody.current = {
                  ...capturesRequestBody.current,
                  skip: 0,
                };
                setCaptures([]);
              }
            },
          });

          toasts.add({
            title: 'Capture(s) are being cloned.',
            type: 'info',
          });
        }}
      />

      <AddCapturesToProjectsModal
        captures={captureSelection.getSelectionArray()}
        handleCloseDialog={() => {
          setShowAddCapturesToProjectsModal(false);
        }}
        open={showAddCapturesToProjectsModal}
        customers={customers}
        users={captureUsers}
      />

      <EditCapturesOrgModal
        captures={captureSelection.getSelectionArray()}
        handleCloseDialog={() => {
          setShowEditCapturesOrgModal(false);
        }}
        open={showEditCapturesOrgModal}
        customers={sortedOrganizations ?? []}
        changedCaptures={(changedCaptures) => {
          const capturesIndexes = captures.map((x) => x.id);
          const copy = [...captures];

          changedCaptures.forEach((changedCapture) => {
            if (!changedCapture.id) {
              return;
            }
            const index = capturesIndexes.indexOf(changedCapture.id);
            if (index > -1) {
              copy[index] = changedCapture;
            }
          });

          setCaptures(copy);
        }}
      />
    </div>
  );
}
