import { createFilename } from '../filename';
import { getSymbol } from '../units';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExplodedRecord<T> = T & { [key: string]: any };

export const buildCsvBlobFromRecords = <T>({
  records,
  recordFieldNames,
  fileNameBase,
  explodeFieldNames,
}: {
  records: T[] | undefined;
  recordFieldNames: { [K in keyof T]: string };
  fileNameBase: string;
  explodeFieldNames?: (keyof T)[];
}): string | undefined => {
  if (!records || !records.length) {
    return undefined;
  }
  const rows = records
    ?.flatMap((record) =>
      recordToObject(record, recordFieldNames, explodeFieldNames)
    )
    .map(objectToString);

  // find first record with explodeFieldNames
  const firstRecordWithExplodeField =
    records.find(
      (record) => explodeFieldNames?.length && record[explodeFieldNames[0]]
    ) || records[0];

  const header =
    buildHeaderRow(
      recordFieldNames,
      explodeFieldNames,
      firstRecordWithExplodeField
    ) + '\n';
  const content = rows?.join('\n') ?? '';
  const csvString = header + content;
  const file = new File([csvString], createFilename(fileNameBase, 'csv'), {
    type: 'text/csv;charset=utf-8',
  });
  const url = URL.createObjectURL(file);
  return url;
};

const recordToObject = <T>(
  record: T,
  recordFieldNames: { [K in keyof T]: string },
  explodeFieldNames?: (keyof T)[]
): ExplodedRecord<T>[] => {
  const obj: ExplodedRecord<T> = {} as ExplodedRecord<T>;
  const keys: (keyof T)[] = Object.keys(recordFieldNames) as (keyof T)[];

  keys.forEach((key) => {
    const recordFieldName = recordFieldNames[key];
    const value = record[key];
    if (explodeFieldNames?.includes(key)) {
      return;
    }
    obj[recordFieldName] = value;
  });

  if (!explodeFieldNames) {
    return [obj];
  }

  const explodedRecords: ExplodedRecord<T>[] = [];
  explodeFieldNames.forEach((explodeFieldName) => {
    const explodeFieldList = record[explodeFieldName];
    if (
      !explodeFieldList ||
      !Array.isArray(explodeFieldList) ||
      !explodeFieldList?.length
    ) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    explodeFieldList.forEach((explodeFieldObj: any) => {
      const explodedRecord = { ...obj };
      Object.keys(explodeFieldObj).forEach((explodeFieldKey) => {
        const explodeFieldRecordFieldName = `${explodeFieldName.toString()}.${explodeFieldKey}`;
        let explodeFieldValue = '';
        if (explodeFieldKey === 'customAttributeUnit') {
          explodeFieldValue = getSymbol(explodeFieldObj, true) as string;
        } else {
          explodeFieldValue = explodeFieldObj[explodeFieldKey];
        }
        explodedRecord[explodeFieldRecordFieldName as keyof ExplodedRecord<T>] =
          explodeFieldValue;
      });
      explodedRecords.push(explodedRecord);
    });
  });

  return explodedRecords;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const objectToString = (obj: { [key: string]: any }) => {
  return Object.values(obj)
    .filter((row) => row !== undefined)
    .map((row) => `${row}`.replace(/"/g, '""'))
    .map((row) => `"${row}"`)
    .join(',');
};

const buildHeaderRow = <T>(
  recordFieldNames: { [key: string]: string },
  explodeFieldNames: (keyof T)[] | undefined,
  firstRecord: T
) => {
  const headerList = Object.values(recordFieldNames);

  if (!explodeFieldNames?.length) {
    return headerList.map((row) => `"${row}"`).join(',');
  }

  let explodeRecordKeys: string[] = [];

  explodeFieldNames.forEach((explodeFieldName) => {
    const explodeFieldList = firstRecord[explodeFieldName];
    if (!explodeFieldList || !Array.isArray(explodeFieldList)) {
      return;
    }
    const firstExplodeFieldObj = explodeFieldList[0];
    if (!firstExplodeFieldObj) return;
    const explodeFieldRecordKeys = Object.keys(firstExplodeFieldObj).map(
      (explodeFieldKey) => `${explodeFieldName.toString()}.${explodeFieldKey}`
    );
    explodeRecordKeys = [...explodeRecordKeys, ...explodeFieldRecordKeys];
  });

  return [...headerList, ...explodeRecordKeys]
    .map((row) => `"${row}"`)
    .join(',');
};

export const promptDownload = <T>({
  records,
  recordFieldNames,
  fileNameBase,
  explodeFieldNames,
}: {
  records: T[] | undefined;
  recordFieldNames: { [K in keyof T]: string };
  fileNameBase: string;
  explodeFieldNames?: (keyof T)[];
}) => {
  const blobUrl = buildCsvBlobFromRecords({
    records: records,
    recordFieldNames: recordFieldNames,
    fileNameBase: fileNameBase,
    explodeFieldNames: explodeFieldNames,
  });

  if (!blobUrl) return;
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = createFilename(fileNameBase, 'csv');
  link.type = 'text/csv;charset=UTF-8';
  link.click();
  link.remove();
};

export const downloadCsvForTesting = {
  buildCsvBlobFromRecords,
  buildHeaderRow,
  objectToString,
  recordToObject,
  promptDownload,
};
