import { IconName } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useRef, useState } from 'react';

import { CloudButton } from '../../button';
import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface IInputTextArea {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display below the input element.
   */
  error?: string;

  /**
   * The label to display above the input element.
   */
  label?: string;

  /**
   *  Determines whether the input element is required.
   */
  required?: boolean;

  /**
   * The placeholder text to display when the input is empty.
   */
  placeholder?: string;

  /**
   * The icon to display before the text.
   */
  leadingIcon?: IconName;

  /**
   * The icon to display after the text.
   */
  trailingIcon?: IconName;

  /**
   * Determines whether the input element is highlighted.
   */
  highlighted?: boolean;

  /**
   * The current value of the input element.
   */
  value: string;

  /**
   * The callback function to update the value of the input element.
   */
  setValue: (value: string) => void;

  /**
   * Determines whether to use the raw value without trimming leading whitespace.
   * Default value: false
   */
  useRawValue?: boolean;

  /**
   * The event handler for the blur event of the input element.
   */
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;

  /**
   * The event handler for the focus event of the input element.
   */
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;

  /**
   * The event handler for the click event of the input element.
   */
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;

  /**
   * The event handler for the keydown event of the input element.
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;

  /**
   * The ref object or callback function to get a reference to the input element.
   */
  inputRef?: React.RefObject<HTMLTextAreaElement>;

  /**
   * Determines whether the input element is disabled.
   * Default value: false
   */
  disabled?: boolean;

  /**
   * Determines whether the input element is read-only.
   * Default value: false
   */
  readOnly?: boolean;

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<string>;

  /**
   * Determines whether to display a clear button to clear the input value.
   */
  clearButton?: boolean;

  /**
   * Determines whether the input element is focused.
   */
  autoFocus?: boolean;

  resize?: boolean;

  rows?: number;
}

const InputTextArea = ({
  id,
  value,
  setValue,
  error,
  label,
  required,
  placeholder,
  leadingIcon,
  trailingIcon,
  useRawValue = false,
  onBlur,
  onFocus,
  onClick,
  onKeyDown,
  disabled,
  inputRef: inputRefProp,
  readOnly,
  validation,
  clearButton,
  highlighted,
  autoFocus,
  resize = false,
  rows = 3,
}: IInputTextArea) => {
  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const inputClassName = useMemo(() => {
    let className =
      'text-base rounded-lg border-0 bg-opacity-0 bg-primary custom-textarea';

    if (disabled) {
      className += ` text-gray-textSecondary placeholder-gray-border cursor-not-allowed`;
    } else if (error) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors`;
    } else if (highlighted) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors bg-opacity-10`;
    } else {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors`;
    }
    return className;
  }, [disabled, error, highlighted]);

  const wrapperClassName = useMemo(() => {
    let className = 'flex flex-col w-full relative border-0 rounded-lg';
    if (disabled) {
      className += ` ring-1 ring-gray-border cursor-not-allowed`;
    } else if (error) {
      className += ` transition-colors ring-1 ring-status-error focus:ring-status-error focus:ring-2 hover-overlay-5`;
    } else if (highlighted) {
      className += ` transition-colors focus:ring-primary focus:ring-2 hover-overlay-5 ${
        hasFocus ? 'ring-2 ring-primary' : 'ring-1 ring-gray-border'
      }`;
    } else {
      className += ` transition-colors focus:ring-primary focus:ring-2 hover-overlay-5 ${
        hasFocus ? 'ring-2 ring-primary' : 'ring-1 ring-gray-border'
      }`;
    }
    return className;
  }, [disabled, error, highlighted, hasFocus]);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const clearButtonComponent = useMemo(() => {
    if (!clearButton || !value) {
      return undefined;
    }

    return (
      <div
        className={`absolute top-1/2 -translate-y-1/2 ${
          trailingIcon ? 'right-7' : 'right-1'
        }`}
      >
        <CloudButton.Icon
          id={`${id}-clear-button`}
          leadingIcon="circle-x"
          tooltip="Clear"
          tooltipPosition="left"
          onClick={() => setValue('')}
        />
      </div>
    );
  }, [id, clearButton, setValue, value, trailingIcon]);

  const leadingIconComponent = useMemo(() => {
    if (!leadingIcon) {
      return undefined;
    }

    return (
      <div className="absolute left-2 top-1/2 -translate-y-1/2 size-6 flex-center pointer-events-none">
        <FontAwesomeIcon
          icon={[highlighted || hasFocus ? 'fas' : 'far', leadingIcon]}
          className={
            disabled
              ? 'text-gray-border'
              : hasFocus || highlighted
              ? 'text-primary'
              : 'text-gray-iconSecondary'
          }
        />
      </div>
    );
  }, [leadingIcon, hasFocus, disabled, highlighted]);

  const trailingIconComponent = useMemo(() => {
    if (!trailingIcon) {
      return undefined;
    }

    return (
      <div className="absolute right-2 top-1/2 -translate-y-1/2 size-6 flex-center pointer-events-none">
        <FontAwesomeIcon
          icon={[highlighted ? 'fas' : 'far', trailingIcon]}
          className={
            disabled
              ? 'text-gray-border'
              : highlighted
              ? 'text-primary'
              : 'text-gray-iconPrimary'
          }
        />
      </div>
    );
  }, [trailingIcon, disabled, highlighted, hasFocus]);

  const paddingLeft = useMemo(() => (leadingIcon ? '34px' : ''), [leadingIcon]);

  const paddingRight = useMemo(() => {
    const pixels = (clearButton ? 36 : 0) + (trailingIcon ? 30 : 0);
    return pixels ? `${pixels}px` : '';
  }, [clearButton, trailingIcon, hasFocus]);

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label && <CloudInput.Label label={label} required={required} />}
        <div className={wrapperClassName}>
          <textarea
            readOnly={readOnly}
            ref={inputRefProp ?? inputRef}
            data-test-id={id}
            disabled={disabled}
            className={inputClassName}
            autoFocus={autoFocus}
            placeholder={placeholder}
            rows={rows}
            style={{
              paddingRight,
              paddingLeft,
              resize: resize ? 'vertical' : 'none',
            }}
            onBlur={(e) => {
              setHasFocus(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setHasFocus(true);
              onFocus?.(e);
            }}
            onClick={onClick}
            onKeyDown={(e) => {
              onKeyDown?.(e);
            }}
            value={value}
            onChange={(e) => {
              let v = e.target.value;
              if (useRawValue) {
                setValue(v);
                return;
              }

              v = v.trimStart();
              setValue(v);
            }}
          />
          {clearButtonComponent ?? null}
          {leadingIconComponent ?? null}
          {trailingIconComponent ?? null}
        </div>
        {error && <CloudInput.Error error={error} />}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputTextArea };
