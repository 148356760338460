import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useAdminBlocksQueries = (filter?: APIModels.BlockFilter) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const toasts = useToasts();

  const { blockId } = useParams();

  const isViteApp = useIsViteApp();

  const blocksQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.blocks,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    queryFn: ({ pageParam }) =>
      APIClient.getFilteredPagedBlocks(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.BlockFilter
      ),
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
    enabled: filter !== undefined,
  });

  const farmsQuery = APIClient.useGetFarm({
    query: {
      queryKey: [APIUtils.QueryKey.farms],
      select: (data) => APIUtils.Sort.farms(data),
    },
  });

  const blockPostMutation = APIClient.usePostBlock({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [APIUtils.QueryKey.blocks] });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.Block>({
          queryClient,
          queryKey: [APIUtils.QueryKey.blocks, { blockId: data.id }],
          data: data,
        });

        toasts.add(toasts.prepare.entityCreated('block'));

        if (isViteApp) {
          navigate(`/app/admin/platform/blocks/${data.id}/details`, {
            state: { params },
          });
        } else {
          navigate(`/admin/blocks/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to create block!'));
      },
    },
  });

  const blockQuery = APIClient.useGetBlockById(Number(blockId), {
    query: {
      queryKey: [APIUtils.QueryKey.blocks, { blockId: Number(blockId) }],
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Block>({
          queryClient,
          queryKey: [APIUtils.QueryKey.blocks, APIUtils.QueryKey.infinite],
          id: Number(blockId),
          accessor: 'id',
        }),
      retry: 0,
      enabled: Number.isSafeInteger(Number(blockId)),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
    },
  });

  const regionsQuery = APIClient.useGetRegions({
    query: {
      queryKey: [APIUtils.QueryKey.regions],
    },
  });

  const blockCustomerId = useMemo(() => {
    if (blockQuery.data?.farmId) {
      return farmsQuery.data?.find((f) => f.id === blockQuery.data?.farmId)
        ?.customerId;
    }

    return undefined;
  }, [farmsQuery.data, blockQuery.data]);

  const organizationQuery = APIClient.useGetCustomerById(
    blockCustomerId as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: blockCustomerId },
        ],
        initialData: () =>
          APIUtils.searchQueriesForInitialValue<APIModels.Customer>({
            queryClient,
            queryKey: [APIUtils.QueryKey.organizations],
            id: blockCustomerId,
            accessor: 'id',
          }),
        enabled: blockCustomerId !== undefined,
      },
    }
  );

  const blockPutMutation = APIClient.usePutBlock({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [APIUtils.QueryKey.blocks] });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateInfiniteQueryCache<APIModels.Block>({
          queryClient,
          queryKey: [APIUtils.QueryKey.blocks, APIUtils.QueryKey.infinite],
          accessor: 'id',
          data: variables.data,
          id: variables.id,
        });

        APIUtils.updateQueryCache<APIModels.Block>({
          queryClient,
          queryKey: [APIUtils.QueryKey.blocks, { blockId: variables.id }],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('block'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update block!'));
      },
    },
  });

  useEffect(() => {
    if (blockId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(blockId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/blocks' + params);
      } else {
        navigate('/admin/blocks' + params);
      }
      queryClient.removeQueries({
        queryKey: [APIUtils.QueryKey.blocks, { blockId: Number(blockId) }],
      });
    }
  }, [blockId]);

  const farmsLookupTable = useLookupTable(farmsQuery.data, 'id');

  const regionsLookupTable = useLookupTable(regionsQuery.data, 'id');

  return {
    blocksQuery,
    farmsQuery,
    blockPostMutation,
    blockQuery,
    regionsQuery,
    organizationQuery,
    blockPutMutation,
    farmsLookupTable,
    regionsLookupTable,
  };
};
