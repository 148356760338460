import { IOverlay2d } from 'libs/types/src/overlay-2d';
import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { BehaviorSubject } from 'rxjs';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js';
// @ts-ignore
import Stats from 'three/examples/jsm/libs/stats.module.js';

import {
  Annotation3dPoints,
  CameraGeometry,
  CameraState,
  CaptureObjectMarkerType,
  ClipTask,
  ColorsThreeD,
  EventBusData,
  EventBusNames,
  FrameEvent,
  GlobalStore,
  IMarkerObj,
  IPoint3d,
  IViewer,
  MarkerObjOptions,
  MeasurementOptionsValues,
  MixpanelNames,
  PointCloudSettings,
  PointSizeType,
  PotreeViewer,
  SphereColors,
} from '@agerpoint/types';
import {
  Potree,
  _create3dCustomMarker,
  _create3dExtractionMarker,
  _createTextLabel,
  addGeometryToLine,
  createLine,
  dragElement,
  environment,
  eventBus,
  getPointsInPlane,
  getViewerSettings,
  isOutsideFrustum,
  pointFromEvent,
  pointInPolygon,
  threeDMidpoint,
} from '@agerpoint/utilities';

import { Annotations2d } from '../annotations/annotations-2d/annotations-2d';
import { Annotations3d } from '../annotations/annotations-3d/annotations-3d';
import { BaseThreeDViewer } from '../base-viewer/BaseThreeDViewer';
import {
  MeasurementModeClass,
  measurementModes,
} from '../potree-controls/potree-controls.constants';
import { Classification } from './classification';
import DebugPotreeViewer from './debug';
import { Overlay2d } from './overlay-2d';

export class Viewer extends BaseThreeDViewer implements IViewer {
  private cameraPositions: IPoint3d[] = [];
  private trunkLinesLookup: { [key: string]: any } = {};
  private pointMarkerLookup: { [key: string]: IMarkerObj } = {};
  private convexHullLookup: { [key: string]: any } = {};
  private imageLocationLookup: { [key: string]: CameraGeometry } = {};
  private raycaster = new THREE.Raycaster();
  private mouse = new THREE.Vector2();
  public viewer: PotreeViewer = {} as PotreeViewer;
  private onMouseDown: any;
  private onMouseUp: any;
  private onWheelScroll: any;
  private onKeyDown: any;
  private onKeyUp: any;
  private addingLabelListener: any;
  public pointCloudReady = false;
  private animationLoopRequestId: number | undefined;
  private canvas: HTMLCanvasElement = {} as HTMLCanvasElement;
  public classification: Classification = {} as Classification;
  public overlay2d: IOverlay2d = {} as IOverlay2d;
  public debug: DebugPotreeViewer = {} as DebugPotreeViewer;
  private viewerSettings: PointCloudSettings = getViewerSettings({});
  private trunkLines = new THREE.Object3D();
  private convexHulls = new THREE.Object3D();
  private prisms = new THREE.Object3D();
  private eventBusLookup: { [key: string]: any } = {};
  private stats: any;
  public annotations2d: Annotations2d = {} as Annotations2d;
  public targetEl: HTMLElement;
  private _potreeFrameEvent = new BehaviorSubject<FrameEvent | null>(null);

  constructor(permissions: GlobalStore['permissions'], targetEl: HTMLElement) {
    super(false, targetEl);
    try {
      mixpanel.time_event(MixpanelNames.MetricViewerInitialized);
      mixpanel.time_event(MixpanelNames.MetricViewerCloudLoaded);
    } catch (e) {
      //
    }
    this.viewerSettings = getViewerSettings(permissions);
    this.targetEl = targetEl;
  }

  get potreeFrameEvent() {
    return this.croppingTool?.frameEventStream;
  }

  initialize(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      Potree.setScriptPath(environment.app_url);
      const potreeViewer = new Potree.Viewer(this.targetEl, {});

      this.viewer = potreeViewer;
      this.viewer.renderer.autoClear = false;
      this.scene = potreeViewer.scene.scene;
      this.camera = potreeViewer.scene.cameraP;
      this.controls = potreeViewer.controls;
      this.renderer = potreeViewer.renderer;
      this.debugHelpers();

      window.apv = potreeViewer;

      const canvas = document.querySelector<HTMLCanvasElement>(
        '#potree_render_area > canvas'
      );
      if (!canvas) {
        reject('missing canvas');
        return;
      }
      canvas?.setAttribute?.('data-test-id', 'potree-render-area-canvas');
      this.canvas = canvas;
      this.classification = new Classification(potreeViewer);
      this.overlay2d = new Overlay2d(potreeViewer, canvas);
      this.annotations2d = new Annotations2d(
        potreeViewer.scene.scene,
        potreeViewer.scene.cameraP,
        true,
        canvas
      );

      this.annotations3d = new Annotations3d(
        potreeViewer.scene.scene,
        potreeViewer.scene.cameraP,
        true,
        this.targetEl
      );

      this.debug = new DebugPotreeViewer(potreeViewer);
      window.apvDebug = this.debug;

      this.viewer.setEDLEnabled(true);
      this.viewer.setEDLRadius(1);
      this.viewer.setEDLStrength(0);
      this.viewer.useHQ = this.viewerSettings.useHQ;
      this.viewer.setFOV(60);
      this.viewer.setPointBudget(this.viewerSettings.pointBudget);
      // this.viewer.loadSettingsFromURL();
      // this.viewer.navigationCube.visible = false;
      // this.viewer.setFrontView();

      // has debug permission
      if (this.viewerSettings.showDebugTools) {
        const stats = new Stats();
        stats.dom.style.zIndex = '40';
        stats.dom.style.position = 'absolute';
        stats.dom.style.top = '4px';
        stats.dom.style.left = 'unset';
        stats.dom.style.right = '45px';
        this.stats = stats;
        this.targetEl.appendChild(stats.dom);
        // this.toggleNavigationCube();
      }

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.9);
      hemiLight.color.setHSL(0.7, 1, 0.9);
      hemiLight.position.set(0, 50, 0);
      this.viewer.scene.scene.add(hemiLight);

      const directional = new THREE.DirectionalLight(0xffffff, 1.0);
      directional.position.set(10, 10, 10);
      directional.lookAt(0, 0, 0);
      this.viewer.scene.scene.add(directional);

      const ambient = new THREE.AmbientLight(0x555555);
      this.viewer.scene.scene.add(ambient);

      this.trunkLines.name = 'processingOutputLines';
      this.viewer.scene.scene.add(this.trunkLines);

      this.convexHulls.name = 'processingOutputConvexHulls';
      this.viewer.scene.scene.add(this.convexHulls);

      this.prisms.name = 'processingOutputPrisms';
      this.viewer.scene.scene.add(this.prisms);

      this.viewer.setBackground(
        [27 / 255, 41 / 255, 47 / 255, 1.0],
        [12 / 255, 20 / 255, 23 / 255, 1.0]
      );

      this.addListeners();
      this.startAnimationLoop();
      try {
        mixpanel.track(MixpanelNames.MetricViewerInitialized, {});
      } catch (e) {
        //
      }
      resolve('ok');
    });
  }

  findCamerasBehindPlane = debounce(() => {
    const poly = this.overlay2d.getFrustumPlaneGeometry();
    if (!poly) return;
    if (!this?.viewer?.scene?.cameraP) return;

    const cameraPositions = Object.keys(this.imageLocationLookup).map(
      (id: string) => {
        return {
          ...this.imageLocationLookup[id],
          id: +id,
        };
      }
    );
    const points = getPointsInPlane(
      poly,
      cameraPositions,
      this.viewer.scene.cameraP
    );
    if (!points?.length) return;
    const halfwayIndex = Math.floor(points.length / 2);

    eventBus.dispatch(EventBusNames.UpdateClosestImage, {
      detail: { id: points[halfwayIndex].id },
    });
  }, 500);

  addListeners() {
    this.viewer.scene.addEventListener('pointcloud_added', () => {
      eventBus.dispatch(EventBusNames.PointCloudLoaded, {
        detail: 'point cloud loaded',
      });
    });
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    function onDocumentMouseDown(event: MouseEvent) {
      // TODO: first check if the raycast intersects the bounding box
      that.mouse.x = (event.clientX / that.targetEl.offsetWidth) * 2 - 1;
      that.mouse.y = -(event.clientY / that.targetEl.offsetHeight) * 2 + 1;
      that.raycaster.setFromCamera(that.mouse, that.viewer.scene.cameraP);
      const intersects = that.raycaster.intersectObjects(
        that.viewer.scene.scene.children,
        false
      );
      if (intersects.length > 0) {
        intersects.forEach((intersection: any, i: number) => {
          if (!intersection?.object?.callback) return;
          intersection?.object?.callback(intersection?.object.uniqueId);
        });
      }
    }

    // we dont need this unless the user has permission,
    // but if the permissions are updated mid session, we need to add the listeners
    function onMouseUp(event: MouseEvent) {
      that.findCamerasBehindPlane();
    }
    // we dont need this unless the user has permission
    // but if the permissions are updated mid session, we need to add the listeners
    function onWheelScroll(event: MouseEvent) {
      that.findCamerasBehindPlane();
    }

    this.onMouseDown = onDocumentMouseDown;
    document.addEventListener('click', onDocumentMouseDown, false);

    if (this.viewerSettings.imageGalleryAutoScroll) {
      this.onMouseUp = onMouseUp;
      document.addEventListener('mouseup', onMouseUp, false);

      this.onWheelScroll = onWheelScroll;
      document.addEventListener('wheel', onWheelScroll, false);
    }

    this.eventBusLookup[EventBusNames.ImageCarouselImageClicked] = eventBus.on(
      EventBusNames.ImageCarouselImageClicked,
      this.updateSelectedCameraPosition,
      true
    );
    this.eventBusLookup[EventBusNames.Point3dLocationMarkerClicked] =
      eventBus.on(
        EventBusNames.Point3dLocationMarkerClicked,
        this.updateSelectedCameraPosition,
        true
      );

    eventBus.on(
      EventBusNames.CaptureCameraPositionShowAllClicked,
      this.showImageMarkers.bind(this)
    );
    eventBus.on(
      EventBusNames.CaptureCameraPositionHideAllClicked,
      this.hideImageMarkers.bind(this)
    );
  }

  /**
   * Performs any cleanup necessary to destroy/remove the viewer from the page.
   */
  destroy() {
    this.stopAnimationLoop();

    try {
      this.pointMarkerLookup = {};
      this.trunkLinesLookup = {};
      this.convexHullLookup = {};
      this.imageLocationLookup = {};

      this.cleanUpEventBus();
      this.cleanUpEventListeners();
      this.cameraPositions.length = 0;
      this.annotations2d.destroy();
      this.annotations3d?.destroy();
      this.pointCloudReady = false;

      window.apv = undefined;
      this.viewer.renderer.domElement.remove();
      // this.targetEl = undefined;
      // Dispose of the WebGLRenderer and its resources
      if (this.viewer.renderer) {
        this.viewer.renderer.dispose(); // Frees up GPU resources
        this.viewer.renderer = undefined;
      }

      // Optionally clear the canvas if you don't need it anymore
      const canvas = this.viewer.renderer?.domElement;
      if (canvas) {
        const gl = canvas.getContext('webgl');
        if (gl) {
          const ext = gl.getExtension('WEBGL_lose_context');
          if (ext) {
            ext.loseContext(); // Optionally lose the context if you want to ensure it's cleaned up
          }
        }
        // Optionally remove the canvas from the DOM
        canvas.remove();
      }
      this.viewer = {} as PotreeViewer;
    } catch (e) {}
  }

  /**
   * Loads a point cloud into the viewer and returns it.
   *
   * @param fileName
   *    The name of the point cloud which is to be loaded.
   * @param baseUrl
   *    The url where the point cloud is located and from where we should load the octree nodes.
   */
  load(fileName: string, baseUrl: string, opts?: any): Promise<any> {
    const callback = (e: { pointcloud: typeof Potree.PointCloudOctree }) => {
      try {
        mixpanel.track(MixpanelNames.MetricViewerCloudLoaded, {});
      } catch (e) {
        //
      }
      e.pointcloud.name = fileName;
      const material = e.pointcloud.material;
      material.size = this.viewerSettings.pointSize;
      material.pointSizeType = this.viewerSettings.pointSizeType;
      if (!this.viewer?.scene?.addPointCloud) return;
      this.viewer.scene.addPointCloud(e.pointcloud);
      this.viewer.fitToScreen(0.5);
      this.drawFindCameraPlane();
      this.pointCloudReady = true;

      return e;
    };
    callback.bind(this);

    return Potree.loadPointCloud(baseUrl, opts, 'ept.json')
      .then(callback)
      .catch((err: any) => {
        throw err;
      });
  }

  render(): void {
    if (this.stats?.update) {
      this.stats.update();
    }
    this.overlay2d.render();
    this.annotations2d.render();

    const pointMarkerKeys = Object.keys(this.pointMarkerLookup);
    if (pointMarkerKeys?.length) {
      for (let i = 0; i < pointMarkerKeys?.length; i++) {
        this.pointMarkerLookup[pointMarkerKeys[i]].updatePosition();
      }
    }

    if (!this.pointCloudCenter) {
      this.pointCloudCenter = this.findCenter();
    }
    this.updateOverlayScene();
  }
  // Start the animation loop
  startAnimationLoop = () => {
    if (this.animationLoopRequestId) return; // Prevent starting multiple loops
    const loop = () => {
      this.render(); // Your render logic
      this.animationLoopRequestId = requestAnimationFrame(loop); // Schedule next frame
    };
    this.animationLoopRequestId = requestAnimationFrame(loop); // Start the loop
  };

  stopAnimationLoop = () => {
    if (this.animationLoopRequestId) {
      cancelAnimationFrame(this.animationLoopRequestId);
      this.animationLoopRequestId = undefined;
    }
  };

  togglePointCloudVisibility = (showHide: boolean) => {
    this.viewer.scene.pointclouds.forEach((pc: any) => {
      pc.visible = showHide;
    });
  };

  setPointBudget = (pointBudget: number) => {
    this.viewer.setPointBudget(pointBudget);
  };

  clearPointCloudScene = () => {
    if (!this.viewer?.scene?.scenePointCloud) return;
    this.viewer.scene.scenePointCloud.clear();
    this.viewer.scene.pointclouds = [];
  };

  setBackground = (
    arg1: [number, number, number, number] | string,
    arg2?: [number, number, number, number]
  ) => {
    this.viewer.setBackground(arg1, arg2);
  };

  disableControls = () => {
    if (!this.controls) return;
    this.viewer.controls.enabled = false;
  };

  enableControls = () => {
    if (!this.controls) return;
    this.controls.enabled = true;
  };

  add2dPointMarker = (
    eventName: EventBusNames,
    eventId: string,
    position: Vector3,
    options: MarkerObjOptions
  ) => {
    this.annotations2d.add2dPoint(eventName, eventId, position, options);
  };

  clear2dPointMarkers = () => {
    if (this?.annotations2d?.remove2dPoints) {
      this.annotations2d.remove2dPoints();
    }
  };

  setTool = (tool: MeasurementOptionsValues) => {
    const config = this.getMeasurementParameters(tool);
    if (MeasurementModeClass.measuringTool.includes(tool)) {
      this.viewer.measuringTool.startInsertion(config);
    } else if (MeasurementModeClass.volumeTool.includes(tool)) {
      this.viewer.volumeTool.startInsertion(config);
    } else if (MeasurementModeClass.profileTool.includes(tool)) {
      this.viewer.profileTool.startInsertion(config);
    } else {
      console.warn(`unknown tool: ${tool}`);
    }
  };

  removeAllMeasurements = () => {
    this.viewer.scene.removeAllMeasurements();
  };

  showHideAllMeasurements = (show: boolean) => {
    this.viewer.measuringTool.showLabels = show;
  };

  resetView = () => {
    this.viewer.fitToScreen();
  };

  updateMaterialProperties = (opts: {
    pointSizeType?: PointSizeType;
    pointSize?: number;
  }) => {
    const material = this.viewer.scene.pointclouds[0].material;
    const orgPointSizeType = material.pointSizeType;
    const orgPointSize = material.size;
    material.size = opts.pointSize || orgPointSize;
    material.pointSizeType = opts.pointSizeType || orgPointSizeType;
  };

  updateSplatQuality = (splatQuality: string) => {
    this.viewer.useHQ = splatQuality === 'High';
  };

  addPrism = (prism: any) => {
    try {
      this.prisms.add(prism);
    } catch (e) {
      console.error(e);
    }
  };
  clearAllPrisms = () => {
    this.prisms.remove(...this.prisms.children);
  };

  indexImageLocation = (
    id: number,
    x: number,
    y: number,
    z: number,
    roll: number,
    pitch: number,
    yaw: number
  ) => {
    this.imageLocationLookup[id] = {
      x,
      y,
      z,
      roll,
      pitch,
      yaw,
      id,
    };
  };

  startCropBox = () => {
    this.viewer.setClipTask(ClipTask.HIGHLIGHT);
    this.viewer.volumeTool.startInsertion({
      clip: true,
      name: 'crop box',
    });

    this.viewer.inputHandler.toggleSelection(this.viewer.scene.volumes[0]);
    this.viewer.transformationTool.scene.visible = true;
  };

  stopCropBox = () => {
    this.viewer.scene.removeAllClipVolumes();
  };

  getCropBoxBoundingBox = () => {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(this.viewer.scene.volumes[0]);
    const min = boundingBox.min;
    const max = boundingBox.max;

    return {
      min,
      max,
    };
  };

  getCropBoxCoordinates = () => {
    const cropBox = this.viewer.scene.volumes[0];

    // Get the transformation matrix
    const matrix = new THREE.Matrix4();
    matrix.compose(cropBox.position, cropBox.quaternion, cropBox.scale);

    // Define the 8 corners of a unit cube (scaled and translated later)
    const corners = [
      new THREE.Vector3(-0.5, -0.5, -0.5),
      new THREE.Vector3(0.5, -0.5, -0.5),
      new THREE.Vector3(-0.5, 0.5, -0.5),
      new THREE.Vector3(0.5, 0.5, -0.5),
      new THREE.Vector3(-0.5, -0.5, 0.5),
      new THREE.Vector3(0.5, -0.5, 0.5),
      new THREE.Vector3(-0.5, 0.5, 0.5),
      new THREE.Vector3(0.5, 0.5, 0.5),
    ];

    // Transform the corners to the crop box's world space
    const transformedCorners = corners.map((corner) =>
      corner.applyMatrix4(matrix)
    );

    const flatCoordinates: any = [];
    transformedCorners.forEach((corner) => {
      flatCoordinates.push(corner.x, corner.y, corner.z);
    });

    return flatCoordinates;
  };

  toggleCursor = (nextCursor?: string) => {
    const canvas = document.querySelector<HTMLElement>(
      '#potree_render_area > canvas'
    );
    if (!canvas) return;
    canvas.style.cursor = nextCursor || 'default';
  };

  // should all be deprecated with new annotations 2d class
  locateObject = (id: string, highlighted: boolean) => {
    this.unHighlightAllMarkers();
    const marker = this.pointMarkerLookup[id];
    if (!marker || !highlighted) return;
    marker.highlight();
  };

  toggleObjectVisibility = (id: string, show: boolean) => {
    const marker = this.pointMarkerLookup[id];
    if (!marker) return;
    marker.element.style.display = show ? 'block' : 'none';
  };

  toggleTextLabelById = (id: string, show: boolean) => {
    const marker = this.pointMarkerLookup[id];
    if (!marker) return;
    marker.labelElement.style.display = show ? 'block' : 'none';
    marker.updatePosition();
  };

  updateObjectEditability = (id: string, editable: boolean) => {
    const marker = this.pointMarkerLookup[id];
    if (!marker) return;
    marker.updateEditability(editable);
  };

  addExistingObjectLocation = (
    eventName: EventBusNames,
    eventId: string,
    position: Vector3,
    options: MarkerObjOptions
  ) => {
    if (this.pointMarkerLookup[eventId]) {
      this.clearMarkerById(eventId);
    }

    this._addTextLabel(position, eventName, eventId, options);
  };

  addNewObjectLocation(
    eventName: EventBusNames,
    eventId: string,
    options: MarkerObjOptions
  ): Promise<Vector3> {
    return new Promise((resolve, reject) => {
      let pointerSphere: any;
      let lastPosition: Vector3;
      this.toggleCursor('crosshair');

      const pointerGeometry = new THREE.SphereGeometry(0.017, 32, 32);
      const pointerMaterial = new THREE.MeshLambertMaterial({
        color: SphereColors.Cyan,
      });

      const onDocumentMouseMove = (event: MouseEvent) => {
        event.preventDefault();
        if (!this.canvas) return;

        const pnt: {
          point: {
            position: Vector3;
          };
        } = pointFromEvent(event, this.viewer.scene.cameraP);

        if (!pnt?.point?.position) {
          if (pointerSphere) {
            this.viewer.scene.scene.remove(pointerSphere);
            pointerSphere = undefined;
          }
          return;
        }

        if (!pointerSphere) {
          // pointerMaterial.customProgramCacheKey = () => {
          //   return '';
          // };
          pointerMaterial.needsUpdate = true;

          pointerSphere = new THREE.Mesh(pointerGeometry, pointerMaterial);
          const attrs = {
            id: 'newPointMarker',
            color: SphereColors.Cyan,
            name: 'newPointMarker',
            description: 'newPointMarker',
            type: Annotation3dPoints.AnnotationPoint,
          };
          this.annotations3d?.add3dPoint(
            attrs,
            pnt.point.position,
            Annotation3dPoints.AnnotationPoint
          );
        }

        pointerSphere.position.copy(pnt.point.position);
        lastPosition = pnt.point.position;
      };

      const onDocumentMouseDown = (event: MouseEvent) => {
        this._addTextLabel(lastPosition, eventName, eventId, options);
        this.toggleCursor();

        this.viewer.scene.scene.remove(pointerSphere);
        pointerGeometry.dispose();
        pointerMaterial.dispose();

        document.removeEventListener('mousedown', onDocumentMouseDown, false);
        document.removeEventListener('mousemove', onDocumentMouseMove, false);
        resolve(lastPosition);
      };

      document.addEventListener('mousedown', onDocumentMouseDown, false);
      document.addEventListener('mousemove', onDocumentMouseMove, false);
    });
  }

  clearMarkerById = (id: string) => {
    const marker = this.pointMarkerLookup[id];
    if (!marker) return;
    marker.element.remove();
    marker.labelElement.remove();
    delete this.pointMarkerLookup[id];
  };

  clearAllMarkers = () => {
    Object.keys(this.pointMarkerLookup).forEach((key) => {
      const marker = this.pointMarkerLookup[key];
      if (marker) {
        marker.element.remove();
        marker.labelElement.remove();
      }
    });
    this.pointMarkerLookup = {};
  };

  // trunk lines

  drawTrunkLine = (path: number[][], id: string) => {
    if (this.trunkLinesLookup[id]) return;
    if (!path.length) return;
    const linePath: Vector3[] = path.map((pnt: number[]) => {
      return new Vector3(pnt[0], pnt[1], pnt[2]);
    });
    const newLine = createLine(id);
    const line = addGeometryToLine(newLine, linePath);
    this.trunkLines.add(line);
    this.trunkLinesLookup[id] = line;
  };

  toggleTrunkLineVisibility = (id: string, show: boolean) => {
    const line: any = this.trunkLines.children.find(
      (line: any) => line.uniqueId === id
    );
    if (!line) return;
    line.visible = show;

    this.trunkLinesLookup[id].visible = show;
  };

  clearAllTrunkLines = () => {
    this.trunkLines.remove(...this.trunkLines.children);
    this.trunkLinesLookup = {};
  };

  // convex hulls

  drawConvexHull = (path: number[][], id: string) => {
    if (this.convexHullLookup[id]) return;
    if (!path.length) return;

    var points = path.map((pnt, i) => {
      const vert = new THREE.Vector3(pnt[0], pnt[1], pnt[2]);
      return vert;
    });

    const convexGeometry = new ConvexGeometry(points);

    const lineEndPoints = [];
    const positionAttribute = convexGeometry.getAttribute('position');

    if (!positionAttribute) return;

    const positions = positionAttribute.array;

    for (let i = 0; i < positions.length; i += 9) {
      const vertexA = new THREE.Vector3(
        positions[i],
        positions[i + 1],
        positions[i + 2]
      );
      const vertexB = new THREE.Vector3(
        positions[i + 3],
        positions[i + 4],

        positions[i + 5]
      );
      const vertexC = new THREE.Vector3(
        positions[i + 6],
        positions[i + 7],
        positions[i + 8]
      );

      // Store the end points of each edge
      lineEndPoints.push([vertexA, vertexB]);
      lineEndPoints.push([vertexB, vertexC]);
      lineEndPoints.push([vertexC, vertexA]);
    }

    lineEndPoints.forEach((lineEndPoint, i) => {
      const newLine = createLine(id + '-' + i, SphereColors.Blue500, 2);
      const line = addGeometryToLine(newLine, lineEndPoint);
      this.convexHulls.add(line);
    });
  };

  clearConvexHull = (id: string) => {
    const line: any = this.convexHulls.children.find(
      (line: any) => line.uniqueId === id
    );
    if (!line) return;
    this.convexHulls.remove(line);
  };

  clearAllConvexHulls = () => {
    this.convexHulls.remove(...this.convexHulls.children);
  };

  removeAllMeshes = () => {
    this.annotations3d?.clear3dPointsByType(
      Annotation3dPoints.CaptureImageLocation
    );
    this.clearAllConvexHulls();
    this.clearAllPrisms();
  };

  startObjectSelection = () => {
    // this.viewer.setTopView();
    // this.setNavigationMode(NavigationOptionsValues.Earth);
  };

  stopObjectSelection = () => {
    // this.setNavigationMode(NavigationOptionsValues.Orbit);
  };

  get3DPointFromXYZ = (x: number, y: number, z = -1): Vector3 => {
    return new Vector3(x, y, z).unproject(this.viewer.scene.cameraP);
  };

  resetAllObjectMarkers = () => {
    Object.keys(this.pointMarkerLookup).forEach((key) => {
      const marker = this.pointMarkerLookup[key];
      if (marker) {
        marker.unHighlight();
      }
    });
  };

  selectObjectsByLocation2D = (coords: Vector3[]) => {
    const pointMarkerKeys = Object.keys(this.pointMarkerLookup);
    const poly = coords.map((coord) => [coord.x, coord.y]);

    for (let i = 0; i < pointMarkerKeys?.length; i++) {
      const marker = this.pointMarkerLookup[pointMarkerKeys[i]];
      const markerScreenCoords = marker.get2dCoords(marker.position);
      const isInPolygon = pointInPolygon(poly, [
        markerScreenCoords.x,
        markerScreenCoords.y,
      ]);
      if (isInPolygon) {
        marker.highlight();
      } else {
        marker.unHighlight();
      }
    }
  };

  getHighlightedMarkers = () => {
    const res = [];
    const pointMarkerKeys = Object.keys(this.pointMarkerLookup);
    for (let i = 0; i < pointMarkerKeys?.length; i++) {
      const marker = this.pointMarkerLookup[pointMarkerKeys[i]];
      if (marker.isHighlighted) {
        res.push(marker.id);
      }
    }
    return res;
  };

  addFeatureInfoModal = (
    position: Vector3,
    element: React.ReactElement<any, any>
  ) => {
    this.overlay2d.createFeatureInfoOverlay(position);
    this.overlay2d.setFeatureInfoChildren(element);
  };

  removeFeatureInfoModal = () => {
    this.overlay2d.destroy();
  };

  drawFindCameraPlane = () => {
    this.overlay2d.createFrustumPlaneOverlay();
  };

  // distance is in meters
  zoomToLocation = (
    loc: { x: number; y: number; z: number },
    distance?: number
  ) => {
    const world = new THREE.Vector3(loc.x, loc.y, loc.z);

    // Calculate new camera position
    let newPosition;
    if (distance) {
      const direction = new THREE.Vector3()
        .subVectors(this.viewer.scene.cameraP.position, world)
        .normalize();
      newPosition = world.clone().add(direction.multiplyScalar(distance));
    } else {
      newPosition = threeDMidpoint(world, this.viewer.scene.cameraP.position);
    }

    // Update the camera's position and orientation
    this.viewer.scene.view.position.copy(newPosition);
    this.viewer.scene.view.lookAt(world);
  };

  getCameraSettings(): CameraState {
    const view = this.viewer.scene.view;
    const position = view.position;
    const t = view.getPivot();
    const res = {
      position: new Vector3(position.x, position.y, position.z).toArray(),
      target: new Vector3(t.x, t.y, t.z).toArray(),
    };
    return res;
  }

  setCameraSettings(cameraState: CameraState) {
    // Restore the exact camera state
    const view = this.viewer.scene.view;
    view.setView(cameraState.position, cameraState.target);
  }

  setCameraFrontView = () => {
    // this.viewer.setFrontView();
  };

  findBoundingBox = () => {
    if (!this.viewer?.scene?.pointclouds?.[0]) return;
    let pointCloud = this.viewer.scene.pointclouds[0];
    let boundingBox = pointCloud?.pcoGeometry?.tightBoundingBox;
    if (!boundingBox) {
      return undefined;
    }
    let min = boundingBox.min;
    let max = boundingBox.max;
    return { min, max };
  };

  findCenter = () => {
    if (!this.viewer?.scene?.pointclouds?.[0]) return;
    let pointCloud = this.viewer.scene.pointclouds[0];
    let boundingBox = pointCloud?.pcoGeometry?.tightBoundingBox;
    if (!boundingBox) {
      return undefined;
    }
    let center = boundingBox.getCenter(new Vector3());
    return center;
  };

  destroyCroppingTool = () => {
    if (this.viewer.scene.volumes.length > 0) {
      this.viewer.scene.removeVolume(this.viewer.scene.volumes[0]);
    }
    this.viewer.setClipTask(ClipTask.NONE);

    super.destroyCroppingTool();
  };

  private _addTextLabel = (
    pos: Vector3,
    eventName: EventBusNames,
    eventId: string,
    options: MarkerObjOptions = { editable: false, fill: '#00FFFFFF' }
  ) => {
    const marker = this._createFontAwesomeMarker(eventName, eventId, options);
    marker.position.copy(pos);
    this.pointMarkerLookup[eventId] = marker;

    const potreeRenderAreaElem = document.querySelector('#potree_render_area');
    if (!potreeRenderAreaElem) return;

    potreeRenderAreaElem?.appendChild(marker.element);
    potreeRenderAreaElem?.appendChild(marker.labelElement);
  };

  toggleAll3dAnnotations = (show: boolean) => {
    if (show) {
      this.annotations3d?.showAll3dAnnotations();
    } else {
      this.annotations3d?.hideAll3dAnnotations();
    }
  };

  static boxVolumeName = 'boxVolumeCrop';
  /**
   *
   * @param FrameEvent
   * @param byPassEmit - used on init to set the box volume without emitting the event
   * @returns
   */
  updateBoxVolume = ({ frame }: FrameEvent, byPassEmit = false) => {
    if (!this.renderer || !this.scene || !this.camera) return;
    if (!this.croppingTool) return;
    // Assuming min and max are THREE.Vector3 objects representing your bounding box limits
    let min = new Vector3(frame.min.x, frame.min.y, frame.min.z);
    let max = new Vector3(frame.max.x, frame.max.y, frame.max.z);

    // Calculate the center and size of the box
    let center = new Vector3().addVectors(min, max).multiplyScalar(0.5);
    let size = new Vector3().subVectors(max, min);
    if (this.viewer.scene.volumes.length === 0) {
      this.viewer.volumeTool.startInsertion({
        clip: true,
        name: 'crop box',
      });
      this.viewer.setClipTask(ClipTask.SHOW_INSIDE);
      this.viewer.transformationTool.scene.visible = false;
      this.viewer.dispatchEvent({ type: 'cancel_insertions' });
    }
    let clippingBox = this.viewer.scene.volumes[0];
    clippingBox.position.copy(center);
    clippingBox.scale.copy(size);
    clippingBox.visible = false;
    this.viewer.transformationTool.scene.visible = false;
  };

  private _createFontAwesomeMarker = (
    eventName: EventBusNames,
    eventId: string,
    options: MarkerObjOptions = { editable: false, fill: '#00FFFFFF' }
  ): IMarkerObj => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    let markerElement: HTMLDivElement;
    let labelElement: HTMLDivElement;
    if (options.type === CaptureObjectMarkerType.ExtractionJob) {
      markerElement = _create3dExtractionMarker(
        options?.fill,
        options?.clickable
      );
    } else {
      markerElement = _create3dCustomMarker(
        eventId,
        options?.fill,
        options?.clickable
      );
    }
    labelElement = _createTextLabel(options?.name);

    // Function to update DOM elements
    function updateDOM(
      element: HTMLElement,
      display: string,
      left?: string,
      top?: string
    ): void {
      if (element.style.display !== display) {
        element.style.display = display;
      }
      if (left && element.style.left !== left) {
        element.style.left = left;
      }
      if (top && element.style.top !== top) {
        element.style.top = top;
      }
    }

    const markerObj: IMarkerObj = {
      color: options.fill,
      element: markerElement,
      labelElement,
      id: eventId,
      options,
      editable: options.editable,
      updateEditability: function (value) {
        if (this.type !== CaptureObjectMarkerType.Custom) {
          return;
        }
        this.editable = value;
        this.element.style.pointerEvents = value ? 'auto' : 'none';
        this.element.style.cursor = value ? 'pointer' : 'default';
      },
      // parent: undefined,
      position: new THREE.Vector3(0, 0, 0),
      isHighlighted: false,
      updatePosition: function () {
        const leftAdjust = this.isHighlighted ? 24 : 12;
        const leftAdjustTest = this.isHighlighted ? 36 : 18;
        const topAdjust = this.isHighlighted ? 64 : 32;
        const camera = _this.viewer.scene.cameraP; // Replace this with your camera object

        // Get the combined projection-view matrix
        const projectionMatrix = camera.projectionMatrix;
        const viewMatrix = camera.matrixWorldInverse;
        const viewProjectionMatrix = new THREE.Matrix4().multiplyMatrices(
          projectionMatrix,
          viewMatrix
        );

        // Extract frustum planes from the view projection matrix
        const frustum = new THREE.Frustum() as any;
        frustum.setFromProjectionMatrix(viewProjectionMatrix);
        // Get the six planes of the frustum
        const frustumPlanes = frustum.planes;

        // is the point outside the frustum?
        const isOutside = isOutsideFrustum(this.position, frustumPlanes);
        // Perform DOM updates based on the current state
        if (isOutside) {
          updateDOM(markerElement, 'none');
          updateDOM(labelElement, 'none');
        } else {
          const coords2d = this.get2dCoords(this.position);
          updateDOM(
            markerElement,
            options.visible ? 'block' : 'none',
            `${coords2d.x - leftAdjust}px`,
            `${coords2d.y - topAdjust}px`
          );
          updateDOM(
            labelElement,
            options.visibleLabel ? 'block' : 'none',
            `${coords2d.x + leftAdjustTest}px`,
            `${coords2d.y - topAdjust}px`
          );
        }
      },
      get2dCoords: function (position: any) {
        const temp = new THREE.Vector3(position.x, position.y, position.z);
        const vector = temp.project(_this.viewer.scene.cameraP);
        vector.x = ((temp.x + 1) / 2) * _this.canvas.width;
        vector.y = (-(temp.y - 1) / 2) * _this.canvas.height;
        return new THREE.Vector2(vector.x, vector.y);
      },
      highlight: function () {
        const fill = this.element.querySelector<HTMLElement>('svg');
        if (!fill) return;
        this.isHighlighted = true;
        fill.style.height = '64px';
      },
      unHighlight: function () {
        const fill = this.element.querySelector<HTMLElement>('svg');
        if (!fill) return;
        this.isHighlighted = false;
        fill.style.height = '32px';
      },
      type: options.type,
    };

    if (!options.visible) {
      markerObj.element.style.display = 'none';
    }
    if (!options.visibleLabel) {
      markerObj.labelElement.style.display = 'none';
    }
    if (options.type === CaptureObjectMarkerType.Custom) {
      dragElement(markerObj, _this.viewer.scene.cameraP, eventName, eventId);
    }

    if (options.clickable && markerObj?.element) {
      markerObj.element.onclick = () => {
        eventBus.dispatch(EventBusNames.CaptureObjectClicked, {
          detail: {
            id: eventId,
            position: markerObj.position,
          },
        });
      };
    }

    return markerObj;
  };

  private getMeasurementParameters = (tool: MeasurementOptionsValues) => {
    return measurementModes[tool];
  };

  private updateSelectedCameraPosition = ({ detail }: EventBusData) => {
    this.annotations3d?.highlight3dPointById(detail.id, ColorsThreeD.Magenta);
  };

  private cleanUpEventBus = () => {
    eventBus.remove(
      EventBusNames.ImageCarouselImageClicked,
      this.updateSelectedCameraPosition,
      this.eventBusLookup[EventBusNames.ImageCarouselImageClicked]
    );
    eventBus.remove(
      EventBusNames.Point3dLocationMarkerClicked,
      this.updateSelectedCameraPosition,
      this.eventBusLookup[EventBusNames.Point3dLocationMarkerClicked]
    );
    eventBus.remove(
      EventBusNames.CaptureCameraPositionShowAllClicked,
      this.showImageMarkers
    );
    eventBus.remove(
      EventBusNames.CaptureCameraPositionHideAllClicked,
      this.hideImageMarkers
    );
  };

  private cleanUpEventListeners = () => {
    document.removeEventListener('click', this.onMouseDown);
    document.removeEventListener('mousedown', this.addingLabelListener);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
    document.removeEventListener('wheel', this.onWheelScroll);
  };

  private unHighlightAllMarkers = () => {
    Object.values(this.pointMarkerLookup).forEach((marker: IMarkerObj) => {
      marker.unHighlight();
    });
  };

  private debugHelpers = () => {
    console.info(
      'Scene Orientation:',
      'x:',
      this.scene?.up.x,
      'y:',
      this.scene?.up.y,
      'z:',
      this.scene?.up.z
    );

    console.info(
      'Camera Position:',
      'x:',
      this.camera?.position.x,
      'y:',
      this.camera?.position.y,
      'z:',
      this.camera?.position.z
    );

    console.info(
      'Camera Orientation:',
      'x:',
      this.camera?.up.x,
      'y:',
      this.camera?.up.y,
      'z:',
      this.camera?.up.z
    );
    console.info('Potree Viewer Initialized');
  };
}
