import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';

export const pipelines = (data?: APIModels.Pipeline[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => {
    return compare(a.name, b.name);
  });
  return sorted;
};
