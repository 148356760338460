import { useQueries, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, APIModels } from '@agerpoint/api';
import { AnalyticRequestStatus } from '@agerpoint/types';
import { APIUtils, Sort, isGaussianJob, useToasts } from '@agerpoint/utilities';

export const useCapturesViewerQueries = ({
  selectedCaptureJob,
  lowResJobId,
  specialCaptureJobId,
  captureId: captureIdProp,
}: {
  selectedCaptureJob?: APIModels.CaptureJob | undefined | null;
  lowResJobId?: number;
  specialCaptureJobId?: number;
  captureId?: number;
}) => {
  const { captureId: captureIdParm, analyticRequestId } = useParams();

  const captureId = useMemo(
    () => captureIdProp ?? Number(captureIdParm),
    [captureIdParm, captureIdProp]
  );

  const captureJobId = useMemo(
    () => selectedCaptureJob?.id,
    [selectedCaptureJob]
  );

  const queryClient = useQueryClient();

  const toasts = useToasts();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures],
          id: Number(captureId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const captureJobsWithPlyInfoQueries = useQueries({
    queries:
      captureQuery.data?.completedJobs
        ?.filter((cj) => cj)
        ?.map((cj) => ({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: cj.id },
            APIUtils.QueryKey.plyUrl,
          ],
          queryFn: async () =>
            APIClient.getPlyDownloadUrlByCaptureJobId(cj.id as number),
          enabled:
            Number.isSafeInteger(Number(cj.id)) &&
            ['g1', 'g2'].includes(cj.mosaicEngine?.toLowerCase() ?? ''),
          staleTime: Infinity,
        })) ?? [],
  });

  const capturePutMutation = APIClient.usePutCaptureById({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(variables.id) },
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateInfiniteQueryCache<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures, APIUtils.QueryKey.infinite],
          id: variables.id,
          accessor: 'id',
          data: variables,
        });

        APIUtils.updateQueryCache<APIModels.Capture>({
          queryClient,
          queryKey: [APIUtils.QueryKey.captures, { captureId: variables.id }],
          data: variables,
        });

        toasts.add(toasts.prepare.entityUpdated('capture', 'Note updated.'));
      },
      onError: () => {
        toasts.add(toasts.prepare.error('Failed to update capture note!'));
      },
    },
  });

  const customerAnalyticsQuery = APIClient.useGetCustomerAnalyticsByCustomerId(
    captureQuery?.data?.customerId as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: captureQuery.data?.customerId },
          APIUtils.QueryKey.analytics,
        ],
        enabled: captureQuery?.data?.customerId !== undefined,
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const getCommonQueryOptions = (captureId: number) => ({
    queryKey: [
      APIUtils.QueryKey.captures,
      { captureId: Number(captureId) },
      APIUtils.QueryKey.analyticRequests,
    ],
    enabled: Number.isSafeInteger(Number(captureId)),
    staleTime: APIUtils.getDuration({ seconds: 20 }),
    refetchInterval: 20000, // Refetch every 10 seconds
  });

  const analyticRequestsQuery = APIClient.useGetAnalyticRequestsByCaptureId(
    Number(captureId),
    {},
    {
      query: getCommonQueryOptions(captureId),
    }
  );

  const onlyCompleteAnalyticRequestsQuery =
    APIClient.useGetAnalyticRequestsByCaptureId(
      Number(captureId),
      {},
      {
        query: {
          ...getCommonQueryOptions(captureId),
          select: (data) =>
            data?.filter((ar) => ar.status === AnalyticRequestStatus.COMPLETE),
        },
      }
    );

  const analyticRequestQuery = APIClient.useGetAnalyticRequestById(
    Number(analyticRequestId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: Number(analyticRequestId) },
        ],
        enabled: Number.isSafeInteger(Number(analyticRequestId)),
        initialData: () =>
          APIUtils.searchQueriesForInitialValue<APIModels.AnalyticRequest>({
            queryClient,
            queryKey: [
              APIUtils.QueryKey.captures,
              { captureId: Number(captureId) },
              APIUtils.QueryKey.analyticRequests,
            ],
            id: Number(analyticRequestId),
            accessor: 'id',
          }),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const sharedAnalyticRequestsWithCaptureObjectsAndJobs = (
    ar: APIClient.AnalyticRequest
  ) => ({
    queryKey: [
      APIUtils.QueryKey.captures,
      { captureId: Number(captureId) },
      APIUtils.QueryKey.analyticRequests,
      { analyticRequestId: ar.id },
      APIUtils.QueryKey.captureObjects,
    ],
    enabled: Number.isSafeInteger(Number(ar.id ?? undefined)),
    select: (data: {
      captureObjects: APIModels.CaptureObject[];
      captureJobs: APIModels.CaptureJob[];
      captureAttributes: APIModels.CaptureCustomAttribute[];
      captureVideos: APIModels.CaptureVideo[];
    }) => ({
      analyticRequest: ar,
      captureObjects: data.captureObjects.filter((co) => {
        return co?.captureObjectType?.id !== 4;
      }),
      captureJobs: data.captureJobs,
      captureAttributes: data.captureAttributes,
      captureVideos: data.captureVideos,
    }),
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const analyticRequestsWithCaptureObjectsAndJobsCompleteOnlyQuery = useQueries(
    {
      queries:
        onlyCompleteAnalyticRequestsQuery.data?.map((ar) => ({
          queryFn: async () => {
            const promises = [
              APIClient.getCaptureObjectsByAnalyticRequestId(ar.id as number),
              APIClient.getCaptureJobsByAnalyticRequestId(ar.id as number),
              APIClient.getCaptureCustomAttributesByAnalyticRequestId(
                ar.id as number
              ),
              APIClient.getCaptureVideosByAnalyticRequestId(ar.id as number),
            ];

            const results = await Promise.all(promises);

            return {
              captureObjects: results[0],
              captureJobs: results[1],
              captureAttributes: results[2],
              captureVideos: results[3],
            };
          },

          ...sharedAnalyticRequestsWithCaptureObjectsAndJobs(ar),
        })) ?? [],
    }
  );

  const analyticRequestsWithCaptureObjectsAndJobsAllQuery = useQueries({
    queries:
      analyticRequestsQuery.data?.map((ar) => ({
        queryFn: async () => {
          const promises = [
            APIClient.getCaptureObjectsByAnalyticRequestId(ar.id as number),
            APIClient.getCaptureJobsByAnalyticRequestId(ar.id as number),
            APIClient.getCaptureCustomAttributesByAnalyticRequestId(
              ar.id as number
            ),
            APIClient.getCaptureVideosByAnalyticRequestId(ar.id as number),
          ];

          const results = await Promise.all(promises);

          return {
            captureObjects: results[0],
            captureJobs: results[1],
            captureAttributes: results[2],
            captureVideos: results[3],
          };
        },

        ...sharedAnalyticRequestsWithCaptureObjectsAndJobs(ar),
      })) ?? [],
  });

  const captureSupplementalImagesQuery =
    APIClient.useGetCaptureImageTagsByCaptureId(Number(captureId), {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.supplementalImages,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        select: (data) => APIUtils.Sort.supplementalImages(data),
      },
    });

  const supplementalImagesNotePutMutation = APIClient.usePutCaptureImageTag({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.supplementalImages,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache<APIModels.CaptureImageTag>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.supplementalImages,
          ],
          data: variables.data,
          accessor: 'id',
          id: variables.id,
        });

        toasts.add(
          toasts.prepare.entityUpdated('supplemental image', 'Note updated.')
        );
      },
      onError: () => {
        toasts.add(
          toasts.prepare.error('Failed to update supplemental image note!')
        );
      },
    },
  });

  const supplementalImagesArchivePutMutation = APIClient.usePutCaptureImageTag({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.supplementalImages,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache<APIModels.CaptureImageTag>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.supplementalImages,
          ],
          data: variables.data,
          accessor: 'id',
          id: variables.id,
        });

        toasts.add(toasts.prepare.entityArchived('supplemental image'));
      },
      onError: () => {
        toasts.add(
          toasts.prepare.error('Failed to archive supplemental image!')
        );
      },
    },
  });

  const captureJobQuery = APIClient.useGetCaptureJobById(Number(captureJobId), {
    query: {
      queryKey: [
        APIUtils.QueryKey.captures,
        { captureId: Number(captureId) },
        APIUtils.QueryKey.captureJobs,
        { captureJobId: Number(captureJobId) },
      ],
      enabled: Number.isSafeInteger(Number(captureJobId)),
    },
  });

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(captureJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(captureJobId) },
          APIUtils.QueryKey.captureImages,
        ],
        enabled:
          Number.isSafeInteger(Number(captureJobId)) &&
          (selectedCaptureJob ?? undefined) &&
          !isGaussianJob(selectedCaptureJob),
        select: (data) =>
          data
            .sort(Sort.sortByImageTime)
            .map((c, index) => ({ ...c, localIndex: index + 1 })),
      },
    }
  );

  const captureJobImagePutMutation = APIClient.usePutCaptureImageById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJobId) },
            APIUtils.QueryKey.captureImages,
          ],
        });
      },
      onError: () => {
        toasts.add(toasts.prepare.error('Failed to update capture image!'));
      },
    },
  });

  const captureObjectPutMutation = APIClient.usePutCaptureObjectById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.captureObjects,
          ],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityUpdated('capture object'));
      },
      onError: () => {
        toasts.add(toasts.prepare.error('Failed to update capture object!'));
      },
    },
  });

  const captureJobImagesForLowResJobIdQuery =
    APIClient.useGetCaptureImagesByCaptureJobId(Number(lowResJobId), {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureQuery?.data?.id) },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(lowResJobId) },
          APIUtils.QueryKey.captureImages,
        ],
        enabled:
          Number.isSafeInteger(Number(lowResJobId)) && !!Number(lowResJobId),
        select: (data) =>
          data
            .sort((a, b) => compare(a.id, b.id))
            .map((c, index) => ({ ...c, localIndex: index + 1 })),
      },
    });

  const captureVideoByAnalyticRequestQuery =
    APIClient.useGetCaptureVideosByAnalyticRequestId(
      Number(analyticRequestId),
      {
        query: {
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.captureVideos,
          ],
          enabled: Number.isSafeInteger(Number(analyticRequestId)),
        },
      }
    );

  const captureVideoByCaptureIdQuery = APIClient.useGetCaptureVideosByCaptureId(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureVideos,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        select: (data) =>
          data.find(
            (v) =>
              v.captureExtractionJobId === null &&
              v.captureJobId === null &&
              v.archived === false &&
              v.validated === true
          ),
      },
    }
  );

  const customCaptureAttributesForAnalyticRequestQuery =
    APIClient.useGetCaptureCustomAttributesByAnalyticRequestId(
      Number(analyticRequestId),
      {
        query: {
          queryKey: [
            APIUtils.QueryKey.analyticRequests,
            { analyticRequestId: Number(analyticRequestId) },
            APIUtils.QueryKey.customAttributes,
          ],
          enabled: Number.isSafeInteger(Number(analyticRequestId)),
          select: (data) =>
            data.sort((a, b) => Sort.sortByAtt(a, b, 'attributeName')),
        },
      }
    );

  // only admin users can use this query
  // const extractionJobsForCaptureQuery =
  //   APIClient.useGetCaptureExtractionJobsByCaptureId(Number(captureId), {
  //     query: {
  //       queryKey: [
  //         APIUtils.QueryKey.captures,
  //         { captureId: Number(captureId) },
  //         APIUtils.QueryKey.extractionJobs,
  //       ],
  //       enabled: Number.isSafeInteger(Number(captureId)),
  //     },
  //   });

  const specialCaptureJobByIdQuery = APIClient.useGetCaptureJobById(
    Number(specialCaptureJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(specialCaptureJobId) },
        ],
        enabled: Number.isSafeInteger(Number(specialCaptureJobId)),
      },
    }
  );

  return {
    captureQuery,
    capturePutMutation,
    customerAnalyticsQuery,
    analyticRequestsQuery,
    analyticRequestsWithCaptureObjectsAndJobsCompleteOnlyQuery,
    analyticRequestsWithCaptureObjectsAndJobsAllQuery,
    captureSupplementalImagesQuery,
    analyticRequestQuery,
    supplementalImagesNotePutMutation,
    supplementalImagesArchivePutMutation,
    captureJobQuery,
    captureJobImagesQuery,
    captureJobsWithPlyInfoQueries,
    captureJobImagePutMutation,
    captureObjectPutMutation,
    captureJobImagesForLowResJobIdQuery,
    customCaptureAttributesForAnalyticRequestQuery,
    captureVideoByAnalyticRequestQuery,
    // extractionJobsForCaptureQuery,
    captureVideoByCaptureIdQuery,
    specialCaptureJobByIdQuery,
  };
};
