import { useCallback, useEffect, useRef } from 'react';

import { UndoButton } from '@agerpoint/component';
import { useGlobalStore } from '@agerpoint/utilities';

export const UndosComponent = () => {
  const {
    undos,
    actions: { removeUndo },
  } = useGlobalStore();

  const intervalRef = useRef<NodeJS.Timer | null>();

  const checkForExpiredUndos = useCallback(() => {
    const now = new Date().getTime();
    const oldUndos = undos.filter((undo) => {
      return now - undo.timestamp > 8000;
    });
    if (oldUndos.length) {
      oldUndos.forEach((undo) => {
        removeUndo(undo.id);
      });
    }
  }, [undos]);

  useEffect(() => {
    if (undos.length && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        checkForExpiredUndos();
      }, 1000);
    } else if (!undos.length && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [undos]);

  return (
    <div
      className="absolute bottom-0 left-0 pb-5 pl-5"
      data-test-id="undos-component"
      style={{ zIndex: 9999999 }}
    >
      {undos.map((undo) => {
        return <UndoButton key={undo.id} {...undo}></UndoButton>;
      })}
    </div>
  );
};
