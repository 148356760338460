import { faCheckToSlot } from '@fortawesome/pro-light-svg-icons';
import {
  faBrainCircuit,
  faChevronRight,
  faCircleNotch,
  faCog,
  faCrateApple,
  faDownload,
  faImages,
  faMicroscope,
  faNote,
  faPlayCircle,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Sidebar2Section } from 'libs/feature/src/sidebar2';
import { useEffect, useMemo, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@agerpoint/component';
import {
  CaptureJobTypes,
  LdFlags,
  MaterialType,
  Sidebar2Route,
  UserClaims,
} from '@agerpoint/types';
import {
  getBestCaptureJob,
  hasClaims,
  hasPermission,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer-context';
import { useCapturesViewerQueries } from '../../../captures-viewer-queries';
import { CVS3AnalyticsRequestsList } from '../../shared/analytics-requests-list/cvs3-analytics-requests-list';
import { CVS3AnalyticsList } from '../../subcomponents/cvs3-analytics-list';
import { cvs3AnalyticsOutputDetailsRoute } from '../analytics-outputs/analytic-output-details/cvs3-analytic-output-details';
import { cvs3AnalyticsOutputsRoute } from '../analytics-outputs/cvs3-analytic-outputs';
import { cvs3BaseCaptureFromRootRoute } from '../base-capture/cvs3-base-capture';
import { cvs3NoteRoute } from '../note/cvs3-note';
import { cvs3SupplementalImagesRoute } from '../supplemental-images/cvs3-supplemental-images';

export const CVS3Root = () => {
  const { viewerController, selectedCaptureJob, setSelectedCaptureJob } =
    useCapturesViewerContext();
  const {
    captureQuery,
    captureSupplementalImagesQuery,
    analyticRequestsQuery,
    captureVideoByCaptureIdQuery,
  } = useCapturesViewerQueries({ selectedCaptureJob });

  useEffect(() => {
    if (captureQuery.data?.id === undefined) {
      setSelectedCaptureJob?.(undefined);
    }
  }, [captureQuery.data?.id]);

  const { user } = useGlobalStore();

  const toasts = useToasts();

  const navigate = useNavigate();
  const location = useLocation();

  const { permissions } = useGlobalStore();

  const hasSelfServeAnalyticsPermission = useMemo(
    () => hasPermission(LdFlags.SelfServeAnalytics, permissions),
    [permissions]
  );

  const qaqcLink = useMemo(() => {
    if (
      !hasClaims([UserClaims.QAQC], user?.cloudClaims as UserClaims[]) ||
      !selectedCaptureJob ||
      !captureQuery.data?.id
    ) {
      return undefined;
    }

    return `/ops/qaqc/${captureQuery.data.id}/${selectedCaptureJob?.id}`;
  }, [captureQuery.data, viewerController, user?.cloudClaims]);

  useEffect(() => {
    if (captureQuery.isLoading || analyticRequestsQuery.isLoading) {
      setSelectedCaptureJob?.(undefined);
      return;
    }
    const regexAnalyticsOutputs = /\/analytics-output(s)?(\/|$)/;

    // If we are on the analytic output details page or base capture page, don't select a job and let the subroute handle it
    if (regexAnalyticsOutputs.test(location.pathname)) {
      return;
    }

    const doAsync = async () => {
      const captureJob = await getBestCaptureJob({
        includeArchived: false,
        analyticRequests: analyticRequestsQuery.data,
      });

      viewerController?.potreeController?.setPointStyle(MaterialType.RGBA);

      // use the low res job if it's already selected
      if (!captureJob) {
        const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
          return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
        });
        console.info('selectedCaptureJob', lowResJob);

        if (lowResJob) {
          setSelectedCaptureJob?.(lowResJob);
        }
        return;
      }
      if (selectedCaptureJob?.id === captureJob?.id) {
        return;
      }

      console.info('selectedCaptureJob', captureJob);
      setSelectedCaptureJob?.(captureJob ?? null);
    };
    doAsync();
  }, [captureQuery?.data, analyticRequestsQuery?.data, location.pathname]);

  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white border-b border-gray-200 truncate shrink-0">
        <div className="flex flex-row gap-2 p-4 leading-none truncate">
          <div className="flex-grow flex flex-col items-stretch truncate">
            <div className="truncate pt-1">
              {captureQuery.data?.captureName ? (
                <span
                  title={captureQuery.data?.captureName}
                  className="text-lg pt-1 cursor-copy hover:underline w-full truncate font-bold leading-none"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      captureQuery.data?.captureName || ''
                    );

                    toasts.add(toasts.prepare.valueCopied('capture name'));
                  }}
                >
                  {captureQuery.data.captureName}
                </span>
              ) : (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              )}
            </div>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCog}
              className={`cursor-pointer rounded-full w-4 h-4 p-1
       hover:bg-gray-100 bg-white transition-colors`}
              onClick={() => {
                navigate(`details`);
              }}
              data-test-id="capture-details-cog"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex-grow overflow-y-auto overflow-x-hidden flex flex-col gap-2 py-2">
        {hasSelfServeAnalyticsPermission && (
          <Sidebar2Section
            title={<span>Models and Analytics</span>}
            leading={<FontAwesomeIcon icon={faCrateApple} />}
            info={
              <NavLink to="analytics" className="underline">
                View all
              </NavLink>
            }
          >
            <CVS3AnalyticsList />
          </Sidebar2Section>
        )}

        <Sidebar2Section
          title={<span>Outputs</span>}
          leading={<FontAwesomeIcon icon={faBrainCircuit} />}
          trailing={
            analyticRequestsQuery.isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : analyticRequestsQuery.data?.length === 0 ? null : (
              <FontAwesomeIcon icon={faChevronRight} />
            )
          }
          onClick={
            (analyticRequestsQuery?.data?.length ?? 0) > 0
              ? () => {
                  navigate('analytics-outputs');
                }
              : undefined
          }
          info={
            analyticRequestsQuery.isLoading ? undefined : analyticRequestsQuery
                .data?.length === 0 ? (
              'No requests'
            ) : (
              <span>{`${analyticRequestsQuery.data?.length} request(s)`}</span>
            )
          }
        >
          <CVS3AnalyticsRequestsList limited={true} />
        </Sidebar2Section>

        <Sidebar2Section
          title={<span>Supplemental Images</span>}
          leading={<FontAwesomeIcon icon={faImages} />}
          trailing={
            captureSupplementalImagesQuery.isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : captureSupplementalImagesQuery.data?.length === 0 ? null : (
              <FontAwesomeIcon icon={faChevronRight} />
            )
          }
          info={
            captureSupplementalImagesQuery.isLoading
              ? undefined
              : captureSupplementalImagesQuery.data?.length === 0
              ? 'No images'
              : `${captureSupplementalImagesQuery.data?.length} image(s)`
          }
          onClick={
            (captureSupplementalImagesQuery.data?.length ?? 0) > 0
              ? () => {
                  navigate('supplemental-images');
                }
              : undefined
          }
        />
        {captureVideoByCaptureIdQuery.data && (
          <Sidebar2Section
            title="Capture Video"
            leading={<FontAwesomeIcon icon={faVideo} />}
          >
            <div className="flex flex-col px-3 py-1">
              <div className="flex flex-col pl-2 divide-y divide-gray-300">
                <div className="flex flex-col text-xs divide-y divide-gray-300">
                  <div className="flex flex-col justify-center items-stretch gap-1">
                    <Button.Small
                      id={`download-capture-video-button`}
                      onClick={() => {
                        if (!captureVideoByCaptureIdQuery.data?.downloadUrl)
                          return;
                        window.open(
                          captureVideoByCaptureIdQuery.data?.downloadUrl,
                          '_blank'
                        );
                      }}
                      label="Download video"
                      icon={faDownload}
                    />
                    <Button.Small
                      id={`view-capture-video-button`}
                      onClick={() => {
                        if (!captureVideoByCaptureIdQuery.data?.downloadUrl)
                          return;
                        videoRef.current?.requestFullscreen();
                      }}
                      label="Play video in full screen"
                      icon={faPlayCircle}
                      disabled={!captureVideoByCaptureIdQuery.data?.downloadUrl}
                    />
                    <video
                      controls
                      ref={videoRef}
                      muted
                      crossOrigin="anonymous"
                      src={
                        captureVideoByCaptureIdQuery.data?.downloadUrl ??
                        undefined
                      }
                      width={0}
                      height={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Sidebar2Section>
        )}
        <Sidebar2Section
          title={<span>Note</span>}
          leading={<FontAwesomeIcon icon={faNote} />}
          info={captureQuery.data?.description ? undefined : 'No note'}
          trailing={
            captureQuery.data ? (
              <FontAwesomeIcon icon={faChevronRight} />
            ) : (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            )
          }
          onClick={
            captureQuery.data
              ? () => {
                  navigate('note');
                }
              : undefined
          }
        >
          {captureQuery.data?.description ? (
            <div
              className="text-sm pl-5 pr-2 text-gray-500 overflow-x-hidden overflow-y-auto text-ellipsis"
              style={{ maxHeight: '200px' }}
            >
              {captureQuery.data?.description.split('\n').map((p, idx) => (
                <p key={idx}>{p}</p>
              ))}
            </div>
          ) : null}
        </Sidebar2Section>
        {qaqcLink && (
          <Sidebar2Section
            title={<span>QAQC</span>}
            leading={<FontAwesomeIcon icon={faMicroscope} />}
            trailing={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={() => {
              navigate(qaqcLink);
            }}
          />
        )}
      </div>
    </div>
  );
};

export const cvs3RootRoute: Sidebar2Route = {
  element: <CVS3Root />,
  subroutes: [
    cvs3AnalyticsOutputsRoute,
    cvs3NoteRoute,
    cvs3SupplementalImagesRoute,
    cvs3BaseCaptureFromRootRoute,
    cvs3AnalyticsOutputDetailsRoute,
  ],
};
