interface IBasicOverlay {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
}

export const BasicOverlay = ({
  icon,
  title,
  subtitle,
  actions,
}: IBasicOverlay) => {
  return (
    <div className="size-full flex-center">
      <div className="pointer-events-auto flex flex-col items-center">
        {icon && (
          <div className="text-gray-iconSecondary text-6xl pb-8 px-6">
            {icon}
          </div>
        )}
        {title && (
          <div className="text-2xl font-bold px-6 text-center">{title}</div>
        )}
        {subtitle && (
          <div className="text-gray-textSecondary pt-2 px-6 text-center">
            {subtitle}
          </div>
        )}
        {actions && <div className="flex flex-row pt-6">{actions}</div>}
      </div>
    </div>
  );
};
