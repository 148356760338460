import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useRef } from 'react';

import { TileViewLayout, TileViewOldProps } from './tile-view-old-types';

export function TileViewOld<T>({
  data,
  tileGenerator,
  layout,
  pagination,
}: TileViewOldProps<T>) {
  const layoutMapping: Map<TileViewLayout, string> = new Map([
    [TileViewLayout.LargeGrid, 'lg:w-1/2 md:w-1/2 sm:w-full w-full'],
    [TileViewLayout.SmallGrid, 'lg:w-1/4 md:w-1/3 sm:w-1/2 w-1/2'],
    [TileViewLayout.List, 'w-full'],
  ]);

  const lastRowRef = useRef<HTMLTableRowElement>(null);
  const processingPage = useRef<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];
        if (
          target.isIntersecting &&
          processingPage.current === false &&
          pagination?.hasNextPage
        ) {
          processingPage.current = true;
          await pagination?.loadPage();
          processingPage.current = false;
        }
      },
      {
        root: null,
        rootMargin: '1px',
        threshold: 0,
      }
    );
    if (lastRowRef.current) {
      observer.observe(lastRowRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [pagination]);

  return (
    <div className="overflow-y-auto overflow-x-hidden flex flex-row flex-wrap w-full content-start">
      {data.map((d, id) => {
        return (
          <div key={id} className={layoutMapping.get(layout)}>
            {tileGenerator(d, layout, id)}
          </div>
        );
      })}
      {pagination?.hasNextPage && (
        <div className="w-full" ref={lastRowRef}>
          <div className="py-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon={faCircleNotch}
              spin
              className="fa-spin text-4xl text-green-600"
            />
          </div>
        </div>
      )}
    </div>
  );
}
