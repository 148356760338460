import { Link } from 'react-router-dom';

import { UserClaims } from '@agerpoint/types';
import { hasClaims, useGlobalStore } from '@agerpoint/utilities';

export const PipelinePage = () => {
  const { user } = useGlobalStore();
  return (
    <div className="flex p-4 h-full w-full">
      <Link
        to="/ops/pipeline/captures"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Captures
        </h5>
        <p className="font-normal text-gray-700">
          Manage captures and trigger capture pipelines.
        </p>
      </Link>
      <Link
        to="/ops/pipeline/uploads"
        className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          Uploads
        </h5>
        <p className="font-normal text-gray-700">
          Manage uploads and trigger upload pipelines.
        </p>
      </Link>
      {hasClaims([UserClaims.AgerAdmin], user?.cloudClaims as UserClaims[]) && (
        <Link
          to="/ops/pipeline/pipeline-jobs"
          className={`h-60 w-60 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-1`}
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
            All Jobs
          </h5>
          <p className="font-normal text-gray-700">
            Manage all kinds of pipeline jobs.
          </p>
        </Link>
      )}
    </div>
  );
};
