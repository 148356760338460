import {
  faFileCheck,
  faFileExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatatableOld } from 'libs/feature/src/datatable/datatable-old';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import { useEffect, useMemo, useState } from 'react';

import { Customer, Upload } from '@agerpoint/api';
import { ConfirmModal, PrimaryButton } from '@agerpoint/component';
import { useBackgroundTaskManager } from '@agerpoint/feature';
import {
  GeoJSONFile,
  OpenLayerMapController,
  OpenLayerMapProps,
  PDFFile,
  failedUploadFileDotStyle,
  successfulUploadFileDotStyle,
  unSelectedUploadFileDotStyle,
} from '@agerpoint/types';
import {
  BackgroundTask,
  BackgroundTaskGroup,
  BackgroundTaskGroupResult,
  BackgroundTaskResult,
  CogFile,
  ExtractionsFile,
  LazFile,
} from '@agerpoint/types';
import { UploadFileWithExif } from '@agerpoint/types';

import { NewUploadType } from '../new-upload-page';
import { FileExifOverlay } from './file-exif-overlay';

interface OngoingUploadTabProps {
  organizations: Customer[] | null;
  uploadGroup: BackgroundTaskGroup;
  mapController?: OpenLayerMapController;
  setMapProps: React.Dispatch<
    React.SetStateAction<OpenLayerMapProps<BackgroundTask>>
  >;
}

export const OngoingUploadTab = ({
  organizations,
  uploadGroup,
  mapController,
  setMapProps,
}: OngoingUploadTabProps) => {
  const {
    togglePauseTaskGroup,
    cancelTaskGroup,
    removeTaskGroup,
    retryTaskGroup,
  } = useBackgroundTaskManager();

  const [highlightedFile, setHighlightedFile] = useState<UploadFileWithExif>();
  const [showHighlightedFile, setShowHighlightedFile] = useState(false);

  const [showCancelUploadModal, setShowCancelUploadModal] = useState(false);

  useEffect(() => {
    if (!showCancelUploadModal) {
      return;
    }

    if (uploadGroup.groupResult || uploadGroup.isBeingFinalized) {
      setShowCancelUploadModal(false);
    }
  }, [uploadGroup]);

  const type: NewUploadType = useMemo(() => {
    if (uploadGroup.tags.includes(NewUploadType.cog)) {
      return NewUploadType.cog;
    }
    if (uploadGroup.tags.includes(NewUploadType.images)) {
      return NewUploadType.images;
    }
    if (uploadGroup.tags.includes(NewUploadType.laz)) {
      return NewUploadType.laz;
    }

    if (uploadGroup.tags.includes(NewUploadType.extractions)) {
      return NewUploadType.extractions;
    }

    if (uploadGroup.tags.includes(NewUploadType.pdf)) {
      return NewUploadType.pdf;
    }

    if (uploadGroup.tags.includes(NewUploadType.geojson)) {
      return NewUploadType.geojson;
    }

    return NewUploadType.images;
  }, [uploadGroup]);

  const upload: Upload = useMemo(() => {
    return uploadGroup.groupCustomPayload;
  }, [uploadGroup]);

  const cogFile: CogFile = useMemo(() => {
    return uploadGroup.tasks[0]?.taskCustomPayload;
  }, [uploadGroup]);

  const lazFile: LazFile = useMemo(() => {
    return uploadGroup.tasks[0]?.taskCustomPayload;
  }, [uploadGroup]);

  const extractionsFile: ExtractionsFile = useMemo(() => {
    return uploadGroup.tasks[0]?.taskCustomPayload;
  }, [uploadGroup]);

  const pdfFile: PDFFile = useMemo(() => {
    return uploadGroup.tasks[0]?.taskCustomPayload;
  }, [uploadGroup]);

  const geoJsonFile: GeoJSONFile = useMemo(() => {
    return uploadGroup.tasks[0]?.taskCustomPayload;
  }, [uploadGroup]);

  const organizationName = useMemo(() => {
    return organizations?.find((o) => o.id === upload.customerId)?.customerName;
  }, [organizations, upload?.customerId]);

  const hasRunningTasks = useMemo(() => {
    return (
      uploadGroup.isBeingFinalized ||
      uploadGroup.tasks.some((t) => t.isBeingProcessed)
    );
  }, [uploadGroup]);

  useEffect(() => {
    if (type == NewUploadType.images) {
      setMapProps((prev) => ({
        ...prev,
        featureLayer: {
          data: uploadGroup.tasks,
          featureGenerator: (task) => {
            const file: UploadFileWithExif = task.taskCustomPayload;
            return {
              id: file.name,
              latitude: file.exif.gps.latitude,
              longitude: file.exif.gps.longitude,
              name: file.name,
              style:
                task.result?.type === BackgroundTaskResult.success
                  ? 'success'
                  : task.result?.type === BackgroundTaskResult.error
                  ? 'fail'
                  : 'default',
            };
          },
          styles: {
            default: unSelectedUploadFileDotStyle,
            success: successfulUploadFileDotStyle,
            fail: failedUploadFileDotStyle,
          },
        },
        callbacks: undefined,
        dependencies: [uploadGroup],
      }));
    } else if (type === NewUploadType.geojson) {
      const features: Feature<Geometry>[] =
        uploadGroup.tasks?.[0].taskCustomPayload?.features;
      setMapProps((prev) => ({
        ...prev,
        geoJsonLayer: {
          data: features ?? [],
        },
        callbacks: undefined,
        dependencies: [uploadGroup],
      }));
    }
  }, [uploadGroup]);

  const groupProgress = useMemo(() => {
    let p = uploadGroup.tasks
      .map((t) => t.progress ?? (t.result ? 100 : 0))
      .reduce((p, c) => {
        return c + p;
      }, 0);

    if (uploadGroup.tasks.length !== 0) {
      p /= uploadGroup.tasks.length;
    }
    return p;
  }, [uploadGroup]);

  useEffect(() => {
    setShowHighlightedFile(false);
  }, [uploadGroup.groupId]);

  return (
    <>
      <dl className="pt-2 pb-1">
        <dt className="text-sm">Upload Name</dt>
        <dd className="pl-2">{upload.name}</dd>
      </dl>

      {organizationName && (
        <dl className="py-1">
          <dt className="text-sm">Organization</dt>
          <dd className="pl-2">{organizationName}</dd>
        </dl>
      )}

      {upload.description && (
        <dl className="py-1">
          <dt className="text-sm">Description</dt>
          <dd className="pl-2">{upload.description}</dd>
        </dl>
      )}

      {type === NewUploadType.cog && (
        <>
          <dl className="py-1">
            <dt className="text-sm">File</dt>
            <dd className="pl-2">{cogFile?.data.name}</dd>
          </dl>
          <dl className="py-1">
            <dt className="text-sm">Autorun Pipeline</dt>
            <dd className="pl-2">{cogFile?.autorunPipeline ? 'Yes' : 'No'}</dd>
          </dl>
        </>
      )}
      {type === NewUploadType.laz && (
        <>
          <dl className="py-1">
            <dt className="text-sm">File</dt>
            <dd className="pl-2">{lazFile?.data.name}</dd>
          </dl>
          <dl className="py-1">
            <dt className="text-sm">Autorun Pipeline</dt>
            <dd className="pl-2">{lazFile?.autorunPipeline ? 'Yes' : 'No'}</dd>
          </dl>
        </>
      )}

      {type === NewUploadType.laz && lazFile?.capture && (
        <dl className="py-1">
          <dt className="text-sm">Capture</dt>
          <dd className="pl-2">{lazFile?.capture?.captureName}</dd>
        </dl>
      )}

      {type === NewUploadType.extractions && extractionsFile?.capture && (
        <dl className="py-1">
          <dt className="text-sm">Capture</dt>
          <dd className="pl-2">{extractionsFile?.capture?.captureName}</dd>
        </dl>
      )}

      {type === NewUploadType.extractions && (
        <>
          <dl className="py-1">
            <dt className="text-sm">File</dt>
            <dd className="pl-2">{extractionsFile?.data.name}</dd>
          </dl>
        </>
      )}

      {type === NewUploadType.pdf && (
        <dl className="py-1">
          <dt className="text-sm">File</dt>
          <dd className="pl-2">{pdfFile?.data.name}</dd>
        </dl>
      )}

      {type === NewUploadType.geojson && (
        <dl className="py-1">
          <dt className="text-sm">File</dt>
          <dd className="pl-2">{geoJsonFile?.data.name}</dd>
        </dl>
      )}

      {(type === NewUploadType.cog ||
        type === NewUploadType.laz ||
        type === NewUploadType.extractions ||
        type === NewUploadType.pdf ||
        type === NewUploadType.geojson) && (
        <dl className="py-1">
          <dt className="text-sm">Progress</dt>
          <dd className="pl-2">{`${groupProgress.toFixed(1)}%`}</dd>
        </dl>
      )}

      {type === NewUploadType.images && (
        <div className="flex flex-col flex-grow w-full h-full relative overflow-hidden rounded-md">
          <div
            className={`absolute h-full w-full bg-white border border-gray-500
            rounded-md transition-transform transform z-10 ${
              showHighlightedFile ? 'translate-y-0' : 'translate-y-full'
            }`}
          >
            <FileExifOverlay
              closeOverlay={() => setShowHighlightedFile(false)}
              mapController={mapController}
              file={highlightedFile}
            />
          </div>
          <div className="flex-grow w-full h-full">
            <DatatableOld
              rowHeight={30}
              data={uploadGroup.tasks}
              cellOnClick={(name) => {
                if (name === 'fileName') {
                  return (row) => {
                    setHighlightedFile(row.taskCustomPayload);
                    setShowHighlightedFile(true);
                  };
                }
                return;
              }}
              style={{
                tableWrapperStyle:
                  'bg-white rounded-t-md border border-gray-500',
                headerWrapperStyle:
                  'px-2 text-xs text-gray-700 font-normal border-b border-gray-500',
                rowWrapperStyle: 'px-2 items-center text-sm hover:bg-gray-100',
                rowStyle: 'border-b border-gray-200',
                headerStyle: 'px-1 py-2 h-full flex items-center',
                cellStyle: 'px-1 flex items-center',
              }}
              columns={[
                {
                  label: (
                    <span className="flex flex-row gap-1">
                      File Name
                      <span className="text-gray-400">(.jpeg, .png)</span>
                    </span>
                  ),
                  value: (row) =>
                    (row.taskCustomPayload as UploadFileWithExif).name,
                  flex: 10,
                  name: 'fileName',
                },
                {
                  label: <span>{`${groupProgress.toFixed(1)}%`}</span>,
                  style: {
                    headerStyle: 'overflow-visible justify-end',
                    bodyStyle: 'justify-end',
                  },
                  value: (row) => {
                    if (row.result?.type === BackgroundTaskResult.success) {
                      return (
                        <FontAwesomeIcon
                          icon={faFileCheck}
                          className="text-green-300"
                        />
                      );
                    }
                    if (row.result?.type === BackgroundTaskResult.error) {
                      return (
                        <FontAwesomeIcon
                          icon={faFileExclamation}
                          className="text-red-300"
                        />
                      );
                    }
                    if ((row.progress ?? 0) > 0) {
                      return `${row.progress}%`;
                    }
                    return null;
                  },
                  flex: 3,
                },
              ]}
            />
          </div>
          <div
            className={`bg-white rounded-b-md w-full text-sm border-b border-x border-gray-500 p-1 pl-2 flex justify-end flex-wrap`}
          >
            <span className="whitespace-nowrap">{`Files Added: ${uploadGroup.tasks.length}`}</span>
          </div>
        </div>
      )}
      <div className="pt-2">
        {!uploadGroup.groupResult && (
          <div className="flex flex-row gap-2">
            {type === NewUploadType.images && (
              <PrimaryButton
                className="w-full"
                disabled={
                  !!uploadGroup.groupResult || uploadGroup.isBeingFinalized
                }
                label={
                  uploadGroup.isPaused && hasRunningTasks
                    ? 'Pausing...'
                    : uploadGroup.isPaused
                    ? 'Resume'
                    : 'Pause'
                }
                onClicked={() => {
                  togglePauseTaskGroup(uploadGroup.groupId);
                }}
              />
            )}
            <PrimaryButton
              className="w-full"
              label={'Cancel'}
              disabled={
                !!uploadGroup.groupResult || uploadGroup.isBeingFinalized
              }
              theme="red"
              onClicked={() => {
                setShowCancelUploadModal(true);
              }}
            />
          </div>
        )}
        {uploadGroup.groupResult === BackgroundTaskGroupResult.success && (
          <PrimaryButton
            className="w-full"
            label={'Close'}
            onClicked={() => {
              removeTaskGroup(uploadGroup.groupId);
            }}
          />
        )}
        {uploadGroup.groupResult === BackgroundTaskGroupResult.error && (
          <PrimaryButton
            className="w-full"
            label={'Retry'}
            theme="secondary"
            onClicked={() => {
              retryTaskGroup(uploadGroup.groupId);
            }}
          />
        )}
      </div>
      <ConfirmModal
        title={'Cancel Upload'}
        message={'Are you sure you want to cancel this upload?'}
        isOpen={showCancelUploadModal}
        canConfirm={true}
        confirm={{
          label: 'Yes',
          callback: () => {
            cancelTaskGroup(uploadGroup.groupId);
            setShowCancelUploadModal(false);
          },
        }}
        close={{
          label: 'No',
          callback: () => {
            setShowCancelUploadModal(false);
          },
        }}
      />
    </>
  );
};
