import { useParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import { APIUtils } from '@agerpoint/utilities';

export const useCaptureViewerFloatingQueries = ({
  captureJobId,
}: {
  captureJobId?: number;
}) => {
  const { captureId } = useParams();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
    },
  });

  const captureJobByIdQuery = APIClient.useGetCaptureJobById(
    Number(captureJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(captureJobId) },
        ],
        enabled: Number.isSafeInteger(Number(captureJobId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  return {
    captureJobByIdQuery,
    captureQuery,
  };
};
