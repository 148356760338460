import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminUploadsQueries } from './admin-uploads-queries';

export const AdminUploadsDetails = () => {
  usePageTitle(() => 'Platform - Uploads', []);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { uploadQuery, organizationsQuery, uploadPutMutation } =
    useAdminUploadsQueries();

  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();

  useEffect(() => {
    setUpload((prev) => ({
      ...prev,
      customerId: selectedOrganization?.id,
    }));
  }, [selectedOrganization]);

  useEffect(() => {
    setSelectedOrganization(
      organizationsQuery.data?.find(
        (o) => o.id === uploadQuery.data?.customerId
      )
    );
  }, [uploadQuery.data, organizationsQuery.data]);

  const [upload, setUpload] = useState<APIModels.Upload | undefined>();

  const formValidation = useFormValidation();

  useEffect(() => {
    setUpload(uploadQuery.data);
  }, [uploadQuery.data]);

  const showOrgEditDropdown = useMemo(() => {
    const typeIds = [2, 4];
    return typeIds.includes(upload?.uploadTypeId ?? -1);
  }, [upload?.uploadTypeId]);

  const updateUpload = useCallback(async () => {
    if (uploadPutMutation.isPending || !upload?.id || uploadQuery.isLoading) {
      return;
    }
    if (await formValidation.hasErrors()) {
      return;
    }

    uploadPutMutation.mutate({
      id: upload.id,
      data: upload,
    });
  }, [uploadPutMutation, upload, uploadQuery.isLoading, formValidation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Uploads',
              path: isViteApp
                ? '/app/admin/platform/uploads'
                : '/admin/uploads',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-upload-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/uploads' + params);
            } else {
              navigate('/admin/uploads' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">{uploadQuery.data?.name}</h1>
      </div>
      {uploadQuery.isLoading ? (
        <PageLoadingState />
      ) : uploadQuery.isError ? (
        <PageErrorState
          entityName="upload"
          pluralEntityName="uploads"
          statusCode={uploadQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            uploadQuery.refetch();
          }}
          tryAgainLoading={uploadQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/uploads' + params
                : '/admin/uploads' + params
            )
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="upload-name"
            value={upload?.name ?? ''}
            setValue={(v) => setUpload((prev) => ({ ...prev, name: v }))}
            label={<Input.Label label="Name" required />}
            error={<Input.Error error={formValidation.errors['upload-name']} />}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
          />
          {showOrgEditDropdown && (
            <Input.Select.Single
              id="organization-select"
              options={organizationsQuery.data ?? []}
              optionBuilder={(o) =>
                o.customerDisplayName ?? o.customerName ?? 'Unknown'
              }
              title="Organization"
              value={selectedOrganization}
              setValue={setSelectedOrganization}
              label={<Input.Label label="Organization" />}
              loading={organizationsQuery.isLoading}
              stretch={true}
            />
          )}
          <div className="flex flex-row justify-end py-4">
            <Button.Primary
              id="upload-save-button"
              label="Save"
              onClick={updateUpload}
              loading={uploadPutMutation.isPending}
            />
          </div>
          <EntityDetailsSection
            items={[
              { label: 'UUID', value: upload?.uuid },
              {
                label: 'Upload Type Id',
                value: upload?.uploadType
                  ? `${upload?.uploadType.id} - ${upload?.uploadType.name}`
                  : '-',
              },
              {
                label: 'Farm ID',
                value: upload?.farmId || '-',
              },
              {
                label: 'Container',
                value: upload?.container || '-',
              },
              {
                label: 'Uploaded',
                value: upload?.isUploaded?.toString() || '-',
              },
              {
                label: 'Type',
                value: upload?.uploadType?.name || '-',
              },
              {
                label: 'Created By UUID',
                value: upload?.createdById || '-',
              },
              {
                label: 'Create Date',
                value: upload?.createDatetime
                  ? formatDateAndTime(upload?.createDatetime) || '-'
                  : '-',
              },
              {
                label: 'Update Date',
                value: upload?.updateDatetime
                  ? formatDateAndTime(upload?.updateDatetime) || '-'
                  : '-',
              },
              {
                label: 'Scan Date',
                value: upload?.scanDatetime
                  ? formatDateAndTime(upload?.scanDatetime) || '-'
                  : '-',
              },
              {
                label: 'Description',
                value: upload?.description || '-',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
