import { useMemo } from 'react';

import { CaptureObject, CaptureObjectType } from '@agerpoint/api';
import { getSymbol, roundToFourDecimals } from '@agerpoint/utilities';

import { Note } from './captures-report-notes';

function abbreviateName(name: string) {
  const abbreviations = {
    diameter: 'diam',
    length: 'len',
    points: 'pts',
    surface_area: 'SA',
    volume: 'vol',
    estimate: 'est',
    ratio: 'ratio',
    filtered: 'filt',
    convex_hull: 'hull',
    voxels: 'vox',
    shape: 'shp',
  };

  // Remove common suffix
  name = name.replace(/_cubic_cm$/, '');

  // Replace words with abbreviations
  Object.entries(abbreviations).forEach(([key, value]) => {
    const regex = new RegExp(key, 'gi');
    name = name.replace(regex, value);
  });

  // Capitalize first letter of each word, remove underscores
  name = name
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return name;
}

export const CaptureObjectTable = ({
  captureObjectGroup,
  captureObjects,
  addNoteModeToggledOn,
  setAddNoteModeToggledOn,
}: {
  captureObjectGroup: CaptureObjectType;
  captureObjects: CaptureObject[];
  addNoteModeToggledOn: boolean;
  setAddNoteModeToggledOn: (value: boolean) => void;
}) => {
  const allowedHeaders = useMemo(() => {
    return [
      'name',
      'diameter',
      'fruit_length',
      'volume_filtered_convex_hull_cubic_cm',
    ];
  }, []);
  const tableHeaders = useMemo(() => {
    if (!captureObjects?.length) return [];
    const headers = captureObjects.reduce((acc, co) => {
      if (!co.captureObjectCustomAttributes?.length) return acc;
      co.captureObjectCustomAttributes.forEach((attr) => {
        if (!attr.attributeName) return;
        if (!acc.includes(attr.attributeName)) {
          acc.push(attr.attributeName);
        }
      });
      return acc;
    }, [] as string[]);
    return headers.length
      ? headers.filter((h) => allowedHeaders.includes(h))
      : [];
  }, [captureObjects]);
  return (
    <>
      <div className="block h-2 w-full"></div>
      <div className="flex flex-row text-xs">
        <div className="text-xs font-bold">
          {captureObjectGroup?.name ?? ''}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row ">
          <div className="text-xs pl-1 flex flex-1">Name</div>
          {tableHeaders?.map((th, index) => {
            return (
              <div key={index} className="text-xs pl-1 flex flex-1">
                {abbreviateName(th)}
              </div>
            );
          })}
        </div>
        {captureObjects?.map((co) => {
          return (
            <div key={co.id} className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="w-full text-xs pl-1 flex flex-1">{co.name}</div>
                {tableHeaders.map((th) => {
                  const attr = co.captureObjectCustomAttributes?.find(
                    (a) => a.attributeName === th
                  );

                  let value = '-';

                  if (attr?.attributeValue) {
                    value = attr.attributeValue;

                    value += getSymbol(attr);
                  }

                  return (
                    <div
                      key={`${co.id}-${th}`}
                      className="w-full text-xs pl-1 flex flex-1"
                    >
                      {roundToFourDecimals(value)}
                    </div>
                  );
                })}
              </div>
              <Note
                addNoteModeToggledOn={addNoteModeToggledOn}
                setAddNoteModeToggledOn={setAddNoteModeToggledOn}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
