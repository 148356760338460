import {
  faAngleDown,
  faAngleUp,
  faCircleInfo,
  faFolderMagnifyingGlass,
  faImageLandscape,
  faImagePortrait,
  faMobile,
  faTimes,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import {
  IconDefinition,
  faCircleNotch,
  faEdit,
  faExpand,
  faPlus,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck,
  faImageLandscape as solidFaImageLandscape,
  faImagePortrait as solidFaImagePortrait,
  faMobile as solidFaMobile,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  useGetCaptureImageHorizontalThumbnailById,
  useGetCaptureImageThumbnailById2,
  useGetCaptureImageVerticalThumbnailById,
  usePutCaptureImageById,
} from '@agerpoint/api';
import {
  Gallery,
  GalleryOrientation,
  PrimaryButton,
} from '@agerpoint/component';
import {
  CaptureGalleryImageProps,
  CaptureImage,
  CaptureViewerBottomPanelImageGalleryProps,
  CapturesViewerImage,
  EventBusNames,
  ImageSelection,
  OrientationOptions,
  UserClaims,
} from '@agerpoint/types';
import {
  blobCache,
  eventBus,
  hasClaims,
  useGlobalStore,
} from '@agerpoint/utilities';

/**
 * @deprecated
 */
export const CaptureGalleryImage = ({
  image,
  index,
  setExpandedImage,
  imageSelection,
  imageSelected,
  imageNote,
  isImageHighlighted = false,
  isImageSelectedInQAQC,
}: CaptureGalleryImageProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const targetRef = useRef<HTMLDivElement>(null);

  const {
    capturesViewer: { imageOrientation },
  } = useGlobalStore();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [setIsVisible]);

  const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState<string>();
  const [body, setBody] = useState<any>({
    id: image.id as number,
    fit: 'fit-in',
    crop: '%20',
    size: `${400}x${400}`,
    verticalAlign: '%20',
    horizontalAlign: '%20',
    filters: '%20',
    lazy: true,
  });

  const {
    data: thumbnailDataOriginalOrientation,
    refetch: refetchOriginalOrientation,
    cancel: cancelOriginalOrientation,
    loading: loadingThumbnailDataOriginalOrientation,
  } = useGetCaptureImageThumbnailById2(body) as any;

  const {
    data: thumbnailDataLandscapeOrientation,
    refetch: refetchLandscapeOrientation,
    cancel: cancelLandscapeOrientation,
    loading: loadingThumbnailDataLandscapeOrientation,
  } = useGetCaptureImageHorizontalThumbnailById(body) as any;

  const {
    data: thumbnailDataPortraitOrientation,
    refetch: refetchPortraitOrientation,
    cancel: cancelPortraitOrientation,
    loading: loadingThumbnailDataPortraitOrientation,
  } = useGetCaptureImageVerticalThumbnailById(body) as any;

  useEffect(() => {
    if (!isVisible) {
      cancelOriginalOrientation();
      cancelLandscapeOrientation();
      cancelPortraitOrientation();
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    setThumbnailBlobUrl(undefined);
    const key = `capture-image-${image.id}-orientation-${imageOrientation}`;

    if (blobCache.has(key)) {
      setThumbnailBlobUrl(blobCache.get(key)?.url);
    } else {
      switch (imageOrientation) {
        case OrientationOptions.Landscape:
          refetchLandscapeOrientation();
          break;
        case OrientationOptions.Portrait:
          refetchPortraitOrientation();
          break;
        default:
          refetchOriginalOrientation();
          break;
      }
    }
  }, [image, imageOrientation, isVisible]);

  useEffect(() => {
    const doAsync = async () => {
      try {
        let blob;
        switch (imageOrientation) {
          case OrientationOptions.Landscape:
            blob = await thumbnailDataLandscapeOrientation.blob();
            break;
          case OrientationOptions.Portrait:
            blob = await thumbnailDataPortraitOrientation.blob();
            break;
          default:
            blob = await thumbnailDataOriginalOrientation.blob();
            break;
        }

        const url = URL.createObjectURL(blob);
        blobCache.set(
          `capture-image-${image.id}-orientation-${imageOrientation}`,
          { blob, url }
        );
        setThumbnailBlobUrl(url);
      } catch (e) {
        console.error(e);
      }
    };

    if (!isVisible) return;
    if (
      !thumbnailDataOriginalOrientation?.blob &&
      imageOrientation === OrientationOptions.Original
    ) {
      return;
    }
    if (
      !thumbnailDataLandscapeOrientation?.blob &&
      imageOrientation === OrientationOptions.Landscape
    ) {
      return;
    }
    if (
      !thumbnailDataPortraitOrientation?.blob &&
      imageOrientation === OrientationOptions.Portrait
    ) {
      return;
    }
    const key = `capture-image-${image.id}-orientation-${imageOrientation}`;

    if (blobCache.has(key)) {
      return;
    }
    try {
      doAsync();
    } catch (e) {
      console.error(e);
    }
  }, [
    thumbnailDataOriginalOrientation,
    thumbnailDataPortraitOrientation,
    thumbnailDataLandscapeOrientation,
    imageOrientation,
    isVisible,
  ]);

  const onClick = useCallback(() => {
    if (image.id) {
      eventBus.dispatch(EventBusNames.ImageCarouselImageClicked, {
        detail: { id: image.id },
      });
    }

    if (imageSelection?.mode !== undefined) {
      imageSelected(image);
    }
  }, [image, imageSelected, imageSelection]);

  const loading = useMemo(() => {
    return (
      loadingThumbnailDataLandscapeOrientation ||
      loadingThumbnailDataOriginalOrientation ||
      loadingThumbnailDataPortraitOrientation
    );
  }, [
    loadingThumbnailDataLandscapeOrientation,
    loadingThumbnailDataOriginalOrientation,
    loadingThumbnailDataPortraitOrientation,
  ]);

  return (
    <div
      className="w-full h-full flex justify-center items-center relative"
      onClick={onClick}
      ref={targetRef}
    >
      {isImageSelectedInQAQC && (
        <div
          className={`absolute -top-1 -left-1 w-5 h-5 bg-selectBlue
          rounded-full flex justify-center items-center z-40 shadow`}
        >
          <FontAwesomeIcon icon={faCheck} className="text-xs text-white" />
        </div>
      )}
      <div
        className={`w-full h-full flex justify-center items-center rounded-lg overflow-hidden
        shadow relative border transition-transform transform ${
          imageSelection?.mode !== undefined ? 'hover:border-blue' : ''
        }
        ${isImageHighlighted ? 'ring-selectBlue ring-2' : 'border-gray-800'}
        ${
          isImageSelectedInQAQC === undefined
            ? ''
            : isImageSelectedInQAQC === true
            ? 'scale-95 ring-selectBlue ring-2'
            : 'scale-100'
        }
        `}
        style={{
          cursor: imageSelection?.mode !== undefined ? 'alias' : undefined,
        }}
      >
        {!thumbnailBlobUrl ? (
          loading ? (
            <FontAwesomeIcon icon={faSpinner} spin className="text-gray-800" />
          ) : null
        ) : (
          <img
            loading="lazy"
            src={thumbnailBlobUrl}
            alt={`imageId ${image.id}`}
            className="h-full w-full object-cover"
          />
        )}
        {isImageHighlighted && (
          <div className="absolute inset-0 bg-white opacity-25" />
        )}
        <div className="absolute bottom-0 left-0 right-0 flex flex-row justify-between gap-1">
          <div
            className={`border-t border-r border-gray-800 rounded-tr-lg
           bg-white px-1 py-0.5 text-xs`}
          >
            {image?.localIndex ?? index + 1}
          </div>
          {imageNote && (
            <div
              className="border-l border-t border-gray-800
            rounded-tl-lg bg-white px-1 py-0.5 text-xs truncate"
            >
              {imageNote}
            </div>
          )}
        </div>
        <div
          className={`absolute top-0 right-0 border-b border-l border-gray-800 rounded-bl-lg
        px-1 py-0.5 text-xs  ${
          thumbnailBlobUrl
            ? 'cursor-pointer bg-white hover:bg-gray-200'
            : 'cursor-wait bg-gray-300'
        }`}
          onClick={() => {
            if (!thumbnailBlobUrl) {
              return;
            }
            setExpandedImage(image);
          }}
        >
          <FontAwesomeIcon icon={faExpand} />
        </div>
      </div>
    </div>
  );
};

export interface CaptureNoteInputProps {
  image: CaptureImage;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CaptureNoteInput = ({
  image,
  onChange,
}: CaptureNoteInputProps) => {
  const { mutate: putImage } = usePutCaptureImageById({ id: NaN });

  const updateImageNote = useCallback(async () => {
    putImage(image, { pathParams: { id: image.id as number } });
  }, [image]);

  const debouncedChangeHandler = useMemo(
    () => debounce(updateImageNote, 1000),
    []
  );

  return (
    <input
      className="bg-transparent flex-grow truncate border-b border-gray-800"
      value={image.note ?? ''}
      onChange={(e) => {
        debouncedChangeHandler();
        onChange(e);
      }}
    />
  );
};

export const CaptureViewerBottomPanelImageGallery = ({
  captureImages,
  selectedCaptureImages,
  imageSelectionMode,
  imageSelectedCallback,
  isBottomPanelExpanded,
  setExpandedImage,
  isQAQCModeEnabled,
  qaqcModeShowSelectedOnly,
  highlightedImage,
}: CaptureViewerBottomPanelImageGalleryProps) => {
  return (
    <div
      className={`flex flex-grow overflow-hidden ${
        isBottomPanelExpanded ? 'h-full' : 'h-44'
      }`}
    >
      {!captureImages.length ? (
        <div>No images found</div>
      ) : (
        <Gallery
          items={(qaqcModeShowSelectedOnly
            ? selectedCaptureImages
            : captureImages
          ).map((image: CapturesViewerImage, index: number) => {
            let frame;
            if (qaqcModeShowSelectedOnly) {
              frame = image;
            } else {
              frame = selectedCaptureImages.find((i) => i.id === image.id);
            }

            return (
              <CaptureGalleryImage
                key={image.id}
                image={image}
                index={index}
                setExpandedImage={setExpandedImage}
                imageSelection={imageSelectionMode}
                imageSelected={imageSelectedCallback}
                imageNote={isQAQCModeEnabled ? frame?.note : undefined}
                isImageHighlighted={
                  !isQAQCModeEnabled && highlightedImage === image.id
                }
                isImageSelectedInQAQC={
                  !isQAQCModeEnabled
                    ? undefined
                    : qaqcModeShowSelectedOnly
                    ? true
                    : !!frame
                }
              />
            );
          })}
          orientation={
            isBottomPanelExpanded
              ? GalleryOrientation.Vertical
              : GalleryOrientation.Horizontal
          }
        />
      )}
    </div>
  );
};

export const CaptureViewerImageSelectionTable = ({
  selectedCaptureImages,
  imageSelectionMode,
  qaqcModeShowSelectedOnly,
  setQAQCModeShowSelectedOnly,
  setSelectedCaptureImages,
  setImageSelectionMode,
}: {
  selectedCaptureImages: CapturesViewerImage[];
  imageSelectionMode?: ImageSelection;
  qaqcModeShowSelectedOnly: boolean;
  setQAQCModeShowSelectedOnly: (value: (previous: boolean) => boolean) => void;
  setSelectedCaptureImages: (value: CapturesViewerImage[]) => void;
  setImageSelectionMode: (value: ImageSelection) => void;
}) => {
  const { mutate: putImage } = usePutCaptureImageById({ id: NaN });
  const [loading, setLoading] = useState(false);

  const unFavoriteImage = useCallback(
    (removedImage: CapturesViewerImage) => {
      removedImage.note = '';
      removedImage.isFavorite = false;
      const doAsync = async () => {
        try {
          await putImage(removedImage, {
            pathParams: { id: removedImage.id },
          });
          const newSelectedCaptureImages = selectedCaptureImages.filter(
            (image) => image.id !== removedImage.id
          );
          setSelectedCaptureImages(newSelectedCaptureImages);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      };
      setLoading(true);
      doAsync();
    },
    [setSelectedCaptureImages, selectedCaptureImages, putImage, setLoading]
  );

  return (
    <div className="flex flex-col w-full">
      {loading && (
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 flex flex-col justify-center items-center`}
        >
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            className="text-green text-3xl"
          />
          {/* <div className="text-white text-xl">Updating Image</div> */}
        </div>
      )}
      <table className="table-auto w-full max-w-2xl">
        <thead className="pb-1">
          <tr>
            <th className="text-left max-w-16 w-16 pb-1">Frame</th>
            <th className="text-left max-w-16 w-16 pb-1">Note</th>
            <th className="text-center max-w-18 w-18 pb-1">
              <PrimaryButton
                onClicked={() => {
                  setQAQCModeShowSelectedOnly((prev: boolean) => !prev);
                }}
                className="py-1 px-1"
                theme={qaqcModeShowSelectedOnly ? 'secondary' : undefined}
                label={`${
                  qaqcModeShowSelectedOnly ? 'Show All' : 'Show Selected'
                }`}
                size="x-small"
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedCaptureImages.map((image, index) => {
            const isEdited =
              imageSelectionMode?.mode === 'EDIT' &&
              imageSelectionMode?.editImageId === image.id;

            return (
              <tr key={index} className="align-baseline">
                <td className="text-sm truncate max-w-16">
                  {image.localIndex}
                </td>
                <td className="text-sm truncate max-w-16 flex">
                  <CaptureNoteInput
                    image={image}
                    onChange={(e) => {
                      const note = e.target.value;
                      const copy = [...selectedCaptureImages];
                      copy[index].note = note;
                      setSelectedCaptureImages(copy);
                    }}
                  />
                </td>
                <td className="text-center max-w-18 w-18">
                  <PrimaryButton
                    onClicked={() => {
                      if (isEdited) {
                        setImageSelectionMode({});
                      } else {
                        setImageSelectionMode({
                          mode: 'EDIT',
                          editImageId: image.id,
                        });
                      }
                    }}
                    theme={isEdited ? 'secondary' : undefined}
                    label={`${isEdited ? 'Cancel' : 'Edit'}`}
                    size="x-small"
                    icon={
                      <FontAwesomeIcon icon={isEdited ? faTimes : faEdit} />
                    }
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faTrash}
                    onClick={() => {
                      if (loading) return;
                      unFavoriteImage(image);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pt-2 flex flex-row gap-2 items-center pb-2">
        <PrimaryButton
          onClicked={() => {
            if (imageSelectionMode?.mode === 'ADD') {
              setImageSelectionMode({});
            } else {
              setImageSelectionMode({ mode: 'ADD' });
            }
          }}
          theme={imageSelectionMode?.mode === 'ADD' ? 'secondary' : undefined}
          label={`${imageSelectionMode?.mode === 'ADD' ? 'Cancel' : 'Add'}`}
          size="x-small"
          icon={
            <FontAwesomeIcon
              icon={imageSelectionMode?.mode === 'ADD' ? faTimes : faPlus}
            />
          }
        />
        {imageSelectionMode?.mode === 'ADD' && (
          <p className="text-xs">Select Image From Gallery</p>
        )}
      </div>
    </div>
  );
};

export const CaptureViewerImageSelectionTableReadOnly = ({
  selectedCaptureImages,
}: {
  selectedCaptureImages: CaptureImage[];
}) => {
  return (
    <table>
      <thead className="pb-1">
        <tr>
          <th className="text-left max-w-16 w-16 pb-1">Frame</th>
          <th className="text-left max-w-16 w-16 pb-1">Note</th>
          <th className="text-center max-w-18 w-18 pb-1">Image Path</th>
        </tr>
      </thead>
      <tbody>
        {selectedCaptureImages.map((image: CaptureImage, index: number) => (
          <tr key={index} className="align-baseline">
            <td className="text-sm truncate max-w-16">{image.localIndex}</td>
            <td className="text-sm truncate max-w-16">{image.note}</td>
            <td className="text-center max-w-18 w-18">{image.imagePath}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const CaptureViewerImageSelectionIconBar = ({
  isBottomPanelOpen,
  toggleBottomPanel,
  isBottomPanelExpanded,
  setIsQAQCModeEnabled,
  setIsBottomPanelExpanded,
  isQAQCModeEnabled,
  setShowDetailModal,
}: {
  isBottomPanelOpen: boolean;
  toggleBottomPanel: () => void;
  isBottomPanelExpanded: boolean;
  setIsQAQCModeEnabled: (
    value: boolean | ((previous: boolean) => boolean)
  ) => void;
  setIsBottomPanelExpanded: (
    value: boolean | ((previous: boolean) => boolean)
  ) => void;
  isQAQCModeEnabled: boolean;
  setShowDetailModal: (value: (prev: boolean) => boolean) => void;
}) => {
  const { user } = useGlobalStore();
  return (
    <div
      className={`flex w-8 flex-none flex-col items-center justify-start border-l border-gray-500 bg-white pt-2`}
    >
      <FontAwesomeIcon
        onClick={() => toggleBottomPanel()}
        title="Close Bottom Panel"
        icon={faTimes}
        className={`text-2xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-1`}
      />
      <FontAwesomeIcon
        onClick={() => {
          if (isBottomPanelExpanded) {
            setIsQAQCModeEnabled(false);
          }
          setIsBottomPanelExpanded((prev) => !prev);
        }}
        title="Expand Bottom Panel"
        icon={isBottomPanelExpanded ? faAngleDown : faAngleUp}
        className={`text-2xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-1`}
      />
      {hasClaims(
        [UserClaims.AgerAdmin],
        (user?.cloudClaims || []) as UserClaims[]
      ) && (
        <>
          <FontAwesomeIcon
            onClick={() => {
              if (!isBottomPanelExpanded) {
                setIsBottomPanelExpanded(true);
              }
              setIsQAQCModeEnabled((prev) => !prev);
            }}
            title="QAQC Mode Toggle"
            icon={faFolderMagnifyingGlass}
            className={`text-xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-2 `}
          />
          <FontAwesomeIcon
            onClick={() => {
              setShowDetailModal(
                (showDetailModal: boolean) => !showDetailModal
              );
            }}
            title="Image Selection Details"
            icon={faCircleInfo}
            className={`text-xl text-gray-800 hover:text-gray-500 rounded w-8 cursor-pointer pb-1 `}
          />
          {isBottomPanelExpanded && <ImageOrientationTools />}
        </>
      )}
    </div>
  );
};

const ImageOrientationTools = () => {
  const {
    capturesViewer: {
      imageOrientation,
      actions: { setImageOrientation },
    },
  } = useGlobalStore();

  const buildButton = useCallback(
    (
      title: string,
      orientation: OrientationOptions,
      onIcon: IconDefinition,
      offIcon: IconDefinition
    ) => {
      return (
        <FontAwesomeIcon
          onClick={() => {
            setImageOrientation(orientation);
          }}
          title={title}
          icon={imageOrientation === orientation ? onIcon : offIcon}
          className={`text-2xl hover:text-gray-500 rounded w-8 cursor-pointer pb-1
  ${imageOrientation === orientation ? 'text-green' : ''} `}
        />
      );
    },
    [imageOrientation, setImageOrientation]
  );

  return (
    <div className="flex flex-col items-center py-6 bg-white">
      {buildButton(
        'Original Orientation',
        OrientationOptions.Original,
        solidFaMobile,
        faMobile
      )}
      {buildButton(
        'Landscape Orientation',
        OrientationOptions.Landscape,
        solidFaImageLandscape,
        faImageLandscape
      )}
      {buildButton(
        'Portrait Orientation',
        OrientationOptions.Portrait,
        solidFaImagePortrait,
        faImagePortrait
      )}
    </div>
  );
};
