import { CloudButton } from '@agerpoint/cloud/components';
import {
  IGs3dViewerController,
  IPotreeViewerController,
} from '@agerpoint/types';
import { useAgerStore } from '@agerpoint/utilities';

export const SharedCroppingToolbar = ({
  viewerController,
}: {
  viewerController: IGs3dViewerController | IPotreeViewerController;
}) => {
  const { isMobile } = useAgerStore();
  return (
    !isMobile && (
      <>
        <div
          className="absolute z-30 bottom-14 left-1/2 flex flex-row bg-white shadow-lg rounded-lg p-1 gap-1"
          style={{
            transform: 'translateX(-50%)',
          }}
        >
          <div className="flex flex-row">
            <CloudButton.Icon
              id="cancel-cropping"
              onClick={async (e) => {
                e.preventDefault();
                viewerController?.resetCroppingTool();
              }}
              leadingIcon={'close'}
              label="Cancel"
              leadingIconColor="text-red"
            />
            <CloudButton.Icon
              id="finish-cropping"
              onClick={async (e) => {
                e.preventDefault();
                await viewerController?.finishCropEditingTool();
              }}
              label="Done"
              leadingIcon={'check'}
              leadingIconColor="text-green"
            />
          </div>
        </div>
        <div
          className={`absolute bottom-28 pointer-events-none left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg z-30 text-center`}
          style={{ transform: 'translateX(-50%)' }}
        >
          Move and resize the box to exclude unwanted areas
        </div>
      </>
    )
  );
};
