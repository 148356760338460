export const fetchGetCaptureById = async (
  captureId: number,
  serverUrl: string,
  token: string
) => {
  const url = `${serverUrl}/api/Capture/${captureId}`;
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };

  const res = await fetch(`${url}`, {
    headers,
    method: 'GET',
  });
  return res.json();
};

export const fetchRemoveDeleteCaptureFromProject = async (
  captureId: number,
  projectUuid: string,
  serverUrl: string,
  token: string
) => {
  const url = `${serverUrl}/api/Projects/${projectUuid}/Capture/${captureId}`;
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };

  const res = await fetch(`${url}`, {
    headers,
    method: 'DELETE',
  });
  return res;
};

export const fetchGetProjectLayerGroups = async (
  id: number,
  serverUrl: string,
  token: string
) => {
  const url = `${serverUrl}/api/Layers/GroupLayers/${id}`;
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };

  const res = await fetch(`${url}`, {
    headers,
    method: 'GET',
  });
  return res.json();
};
