import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Sidebar2ElementProps, isSidebar2Subroute } from '@agerpoint/types';

import './sidebar2-animations.scss';

export const Sidebar2Element = ({ route, level }: Sidebar2ElementProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();

  // const overrideNavigateBack = useMemo(
  //   () => location.state?.overrideNavigateBack,
  //   [location]
  // );

  const [navigatingBack, setNavigatingBack] = useState(false);

  const navigateBack = useCallback(() => {
    if (!isSidebar2Subroute(route)) {
      return;
    }

    const dynamicKey = route.path.split(':')[1];
    const dynamicValue = params[dynamicKey];
    const routePath = dynamicValue
      ? route.path.replace(`:${dynamicKey}`, dynamicValue)
      : route.path;

    const substringIndex = location.pathname.lastIndexOf(`/${routePath}`);
    const goBackPath = location.pathname.substring(0, substringIndex);
    navigate(goBackPath, {
      relative: 'path',
    });
  }, [location, route, navigate, params]);

  // useEffect(() => {
  //   if (!isSidebar2Subroute(route)) {
  //     return;
  //   }

  //   if (!location.pathname.endsWith(overrideNavigateBack)) {
  //     setNavigatingBack(true);
  //     location.state
  //   }
  // }, [location]);

  if (!isSidebar2Subroute(route)) {
    return (
      <>
        <div
          className={`absolute bg-gray-50 inset-0 overflow-y-auto overflow-x-hidden
          shadow-lg `}
          style={{ zIndex: level }}
          key={`element_${level}`}
        >
          {route.element}
        </div>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <div
        className={`absolute inset-0 bg-gray-50 shadow-lg subroute-slide-in
        overflow-y-auto overflow-x-hidden ${
          navigatingBack ? 'subroute-slide-out' : ''
        }`}
        style={{ zIndex: level }}
        key={`element_${route.path}_${level}`}
        onAnimationEnd={() => {
          if (navigatingBack) {
            navigateBack();
          }
        }}
      >
        <div className="w-full h-full flex flex-col">
          <div className="flex flex-row w-full p-4 items-center bg-white border-b border-gray-200">
            <FontAwesomeIcon
              icon={faChevronLeft}
              onClick={() => {
                setNavigatingBack(true);
              }}
              className={`cursor-pointer rounded-full w-4 h-4 p-1
            hover:bg-gray-100 bg-white transition-colors shadow border border-gray-300`}
            />
            <div className="w-full pl-2 font-bold text-lg break-all leading-none">
              {route.title}
            </div>
          </div>
          <div className="flex-grow w-full overflow-y-auto overflow-x-hidden">
            {typeof route.element === 'function'
              ? route.element({
                  navigateBack: () => setNavigatingBack(true),
                })
              : route.element}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};
