import {
  faCircle,
  faCircleCheck,
  faCircleNotch,
  faEdit,
  faExternalLink,
  faExternalLinkAlt,
  faInfoCircle,
  faLink,
  faMagnifyingGlass,
  faRecycle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIClient, APIModels, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button } from '@agerpoint/component';
import {
  ContextMenu,
  ContextMenuGroupProps,
  Datatable,
  dataTableAgerSmallStyle,
  getUsername,
} from '@agerpoint/feature';
import {
  AnalyticRequestStatus,
  EnvironmentTypes,
  JobTypeCategory,
  LdFlags,
  analyticRequestStatusLookup,
} from '@agerpoint/types';
import {
  getCurrentEnvironment,
  getIconByStatus,
  hasPermission,
  useGlobalStore,
  useIsViteApp,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useOpsAnalyticRequestsQueries } from './analytic-requests-queries';
import { AnalyticRequestsDetailsJobStatus } from './subcomponents/ar-details-status-dropdown';

export const AnalyticRequestsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const {
    analyticRequestQuery,
    organizationsLookupTable,
    organizationsQuery,
    usersLookupTable,
    usersQuery,
    hasAnalyticRequestManagementPermission,
    analyticRequestPutMutation,
    analyticRequestCaptures,
    analyticRequestJobsQuery,
    analyticRequestAvailabilityPutMutation,
  } = useOpsAnalyticRequestsQueries({});

  const { permissions } = useGlobalStore();
  const [
    warningExcessCompleteCaptureJobs,
    setWarningExcessCompleteCaptureJobs,
  ] = useState(false);
  const [warningExcessExtractionJobs, setWarningExcessExtractionJobs] =
    useState(false);

  useEffect(() => {
    const jobs = analyticRequestJobsQuery.data;
    const completeCaptureJobs = jobs?.filter(
      (job) => job.status === 'Completed' && job.entityName === 'CaptureJob'
    );
    const completeExtractionJobs = jobs?.filter(
      (job) =>
        job.status === 'Complete' && job.entityName === 'CaptureExtractionJob'
    );

    setWarningExcessCompleteCaptureJobs(
      Number(completeCaptureJobs?.length) > 1
    );
    setWarningExcessExtractionJobs(Number(completeExtractionJobs?.length) > 1);
  }, [analyticRequestJobsQuery.data]);

  const organization = useMemo(() => {
    if (
      !analyticRequestQuery.data ||
      !analyticRequestQuery.data.customerAnalytic?.customerId
    ) {
      return undefined;
    }
    return organizationsLookupTable?.[
      analyticRequestQuery.data.customerAnalytic.customerId
    ];
  }, [analyticRequestQuery, organizationsLookupTable]);

  const createdByUser = useMemo(() => {
    if (!analyticRequestQuery.data || !analyticRequestQuery.data.createdById) {
      return undefined;
    }
    return usersLookupTable?.[analyticRequestQuery.data.createdById];
  }, [analyticRequestQuery, usersLookupTable]);

  const assignedToUser = useMemo(() => {
    if (!analyticRequestQuery.data || !analyticRequestQuery.data.assignedToId) {
      return undefined;
    }
    return usersLookupTable?.[analyticRequestQuery.data.assignedToId];
  }, [analyticRequestQuery, usersLookupTable]);

  const status = useMemo(() => {
    const s = analyticRequestQuery.data?.status as
      | AnalyticRequestStatus
      | undefined
      | null;
    return s;
  }, [analyticRequestQuery]);

  const statusInfo = useMemo(() => {
    if (!status) {
      return undefined;
    }
    return analyticRequestStatusLookup[status];
  }, [status]);

  const statusContextMenuItems = useMemo(() => {
    const editStatusGroup: ContextMenuGroupProps = {
      label: 'Set Status',
      items: [
        {
          label: 'Requested',
          icon: (
            <FontAwesomeIcon
              icon={
                analyticRequestQuery.data?.status ===
                AnalyticRequestStatus.REQUESTED
                  ? faCircleCheck
                  : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestPutMutation.mutate({
              id: analyticRequestQuery.data?.id,
              data: {
                ...analyticRequestQuery.data,
                status: AnalyticRequestStatus.REQUESTED,
              },
            });
          },
        },
        {
          label: 'Processing',
          icon: (
            <FontAwesomeIcon
              icon={
                analyticRequestQuery.data?.status ===
                AnalyticRequestStatus.PROCESSING
                  ? faCircleCheck
                  : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestPutMutation.mutate({
              id: analyticRequestQuery.data?.id,
              data: {
                ...analyticRequestQuery.data,
                status: AnalyticRequestStatus.PROCESSING,
              },
            });
          },
        },
        {
          label: 'Complete',
          icon: (
            <FontAwesomeIcon
              icon={
                analyticRequestQuery.data?.status ===
                AnalyticRequestStatus.COMPLETE
                  ? faCircleCheck
                  : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestPutMutation.mutate({
              id: analyticRequestQuery.data?.id,
              data: {
                ...analyticRequestQuery.data,
                status: AnalyticRequestStatus.COMPLETE,
              },
            });
          },
        },
        {
          label: 'Cancelled',
          icon: (
            <FontAwesomeIcon
              icon={
                analyticRequestQuery.data?.status ===
                AnalyticRequestStatus.CANCELLED
                  ? faCircleCheck
                  : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestPutMutation.mutate({
              id: analyticRequestQuery.data?.id,
              data: {
                ...analyticRequestQuery.data,
                status: AnalyticRequestStatus.CANCELLED,
              },
            });
          },
        },
        {
          label: 'Failed',
          icon: (
            <FontAwesomeIcon
              icon={
                analyticRequestQuery.data?.status ===
                AnalyticRequestStatus.FAILED
                  ? faCircleCheck
                  : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestPutMutation.mutate({
              id: analyticRequestQuery.data?.id,
              data: {
                ...analyticRequestQuery.data,
                status: AnalyticRequestStatus.FAILED,
              },
            });
          },
        },
      ],
    };

    return [editStatusGroup];
  }, [analyticRequestQuery, analyticRequestPutMutation]);

  const availabilityContextMenuItems = useMemo(() => {
    const editStatusGroup: ContextMenuGroupProps = {
      label: 'Set Availability',
      items: [
        {
          label: 'Archived',
          icon: (
            <FontAwesomeIcon
              icon={
                analyticRequestQuery.data?.archived ? faCircleCheck : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestAvailabilityPutMutation.mutate({
              analyticRequest: analyticRequestQuery.data,
              archived: true,
            });
          },
        },
        {
          label: 'Active',
          icon: (
            <FontAwesomeIcon
              icon={
                !analyticRequestQuery.data?.archived ? faCircleCheck : faCircle
              }
            />
          ),
          onClick: () => {
            if (!analyticRequestQuery.data?.id) {
              return;
            }

            analyticRequestAvailabilityPutMutation.mutate({
              analyticRequest: analyticRequestQuery.data,
              archived: false,
            });
          },
        },
      ],
    };

    return [editStatusGroup];
  }, [analyticRequestQuery, analyticRequestAvailabilityPutMutation]);

  const captureName = useMemo(() => {
    return analyticRequestCaptures.data?.[0]?.captureName;
  }, [analyticRequestCaptures]);

  const theCapture = useMemo(() => {
    return analyticRequestCaptures.data?.[0];
  }, [analyticRequestCaptures]);

  const QaqcViewerLink = () => {
    const captureId = theCapture?.id;
    const jobs = analyticRequestJobsQuery.data;
    const captureJobId = jobs?.find(
      (job) => job.entityName === 'CaptureJob'
    )?.entityId;
    const extractionJobId = jobs?.find(
      (job) => job.entityName === 'CaptureExtractionJob'
    )?.entityId;

    const isViteApp = useIsViteApp();

    return captureId && captureJobId && extractionJobId ? (
      <div className="flex flex-row gap-2">
        <Button.Text
          id="qaqc-viewer-button"
          onClick={() => {
            if (isViteApp) {
              navigate(
                `/app/admin/operations/qaqc/${captureId}/${captureJobId}/${extractionJobId}`,
                { replace: false }
              );
            } else {
              navigate(
                `/ops/qaqc/${captureId}/${captureJobId}/${extractionJobId}`,
                { replace: false }
              );
            }
          }}
          label=""
          icon={faLink}
        />
        <Button.Text
          id="qaqc-viewer-button"
          onClick={() => {
            if (isViteApp) {
              window.open(
                `/app/admin/operations/qaqc/${captureId}/${captureJobId}/${extractionJobId}`,
                '_blank',
                'noopener,noreferrer'
              );
            } else {
              window.open(
                `/ops/qaqc/${captureId}/${captureJobId}/${extractionJobId}`,
                '_blank',
                'noopener,noreferrer'
              );
            }
          }}
          label=""
          icon={faExternalLinkAlt}
        />
      </div>
    ) : (
      <div className="flex flex-row gap-2">
        <Button.Text
          id="qaqc-viewer-button"
          onClick={() => {
            if (isViteApp) {
              navigate(`/app/admin/operations/qaqc/${captureId}`, {
                replace: false,
              });
            } else {
              navigate(`/ops/qaqc/${captureId}`, { replace: false });
            }
          }}
          label=""
          icon={faLink}
        />
        <Button.Text
          id="qaqc-viewer-button"
          onClick={() => {
            if (isViteApp) {
              window.open(
                `/app/admin/operations/qaqc/${captureId}`,
                '_blank',
                'noopener,noreferrer'
              );
            } else {
              window.open(
                `/ops/qaqc/${captureId}`,
                '_blank',
                'noopener,noreferrer'
              );
            }
          }}
          label=""
          icon={faExternalLinkAlt}
        />
      </div>
    );
  };

  const AirFlowLink = () => {
    if (!theCapture?.captureUuid) {
      return null;
    }

    if (!analyticRequestJobsQuery.isSuccess) {
      return null;
    }

    const job = theCapture?.completedJobs?.find(
      (job) =>
        job.analyticRequestId === analyticRequestQuery.data?.id &&
        job.captureJobTypeId === 2
    );

    const highResJobUuid = job?.uuid;

    if (!highResJobUuid) {
      return null;
    }

    return (
      <Button.Text
        id={`airflow-link-button`}
        onClick={() => {
          const env = getCurrentEnvironment();
          const pipeline =
            env === EnvironmentTypes.PreProduction ||
            env === EnvironmentTypes.Production
              ? 'ap-mobile-mosaic-pipeline'
              : 'ap-mobile-mosaic-pipeline-dev-platform';
          const url = `http://airflow.agerpoint.net/dags/${pipeline}/grid?dag_run_id=ApcloudHighRes-JobUuid_${highResJobUuid}-CaptureUuid_${theCapture.captureUuid}`;

          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          link.click();
        }}
        label=""
        icon={faExternalLink}
      />
    );
  };

  const statusIcon = useMemo(() => {
    const i = getIconByStatus(status as AnalyticRequestStatus);
    return i ? (
      <FontAwesomeIcon
        icon={i}
        spin={status === AnalyticRequestStatus.PROCESSING}
      />
    ) : null;
  }, [status]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Operations',
              path: isViteApp ? '/app/admin/operations' : '/ops',
            },
            {
              label: 'Analytic Requests',
              path: isViteApp
                ? '/app/admin/operations/analytic-requests'
                : '/ops/analytics',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-between items-center px-4 pt-2">
        <div className="flex flex-row gap-2">
          <Button.Back
            id="analytic-request-details-back-button"
            onClick={() => {
              if (isViteApp) {
                navigate('/app/admin/operations/analytic-requests' + params);
              } else {
                navigate('/ops/analytics' + params);
              }
            }}
          />
          <h1 className="text-3xl font-bold truncate">
            {
              analyticRequestQuery?.data?.customerAnalytic?.analytic
                ?.analyticName
            }
            {`${captureName ? ` - (${captureName})` : ''}`}
          </h1>
        </div>
      </div>
      <div className="w-full h-6 flex flex-row justify-end text-red">
        {warningExcessCompleteCaptureJobs && (
          <div className="flex flex-row gap-2 items-center justify-center text-xs pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>
              This analytic request has more than one complete capture job
            </span>
          </div>
        )}
        {warningExcessExtractionJobs && (
          <div className="flex flex-row gap-2 items-center justify-center text-xs pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>
              This analytic request has more than one complete extraction job
            </span>
          </div>
        )}
      </div>
      {analyticRequestQuery.isLoading ||
      organizationsQuery.isLoading ||
      usersQuery.isLoading ? (
        <PageLoadingState />
      ) : analyticRequestQuery.isError ? (
        <PageErrorState
          entityName="analytic request"
          pluralEntityName="analytic requests"
          statusCode={analyticRequestQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            analyticRequestQuery.refetch();
          }}
          tryAgainLoading={analyticRequestQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/operations/analytic-requests' + params
                : '/ops/analytics' + params
            )
          }
        />
      ) : (
        <div className="w-full flex flex-row gap-2 h-full px-4 pb-4">
          <div className="w-full flex flex-col max-w-lg gap-2 h-full">
            <EntityDetailsSection
              items={[
                {
                  label: 'ID',
                  value: analyticRequestQuery.data?.id,
                },
                {
                  label: 'Capture ID',
                  value: theCapture?.id,
                },
                {
                  label: 'Status',
                  value: (
                    <div
                      className="flex flex-row gap-2 items-center"
                      style={{
                        color: statusInfo?.color,
                      }}
                    >
                      {statusIcon}
                      <span>{analyticRequestQuery.data?.status}</span>
                      {hasAnalyticRequestManagementPermission && (
                        <ContextMenu
                          disabled={analyticRequestPutMutation.isPending}
                          dataTestId="status-context-menu-analytic-request-details"
                          groups={statusContextMenuItems}
                          icon={
                            analyticRequestPutMutation.isPending ? (
                              <FontAwesomeIcon
                                icon={faCircleNotch}
                                spin
                                className="text-gray-700"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="text-gray-700"
                              />
                            )
                          }
                        />
                      )}
                    </div>
                  ),
                },
                {
                  label: 'Analytic ID',
                  value:
                    analyticRequestQuery.data?.customerAnalytic?.analyticId,
                },
                {
                  label: 'Analytic',
                  value:
                    analyticRequestQuery?.data?.customerAnalytic?.analytic
                      ?.analyticName,
                },
                {
                  label: 'Cost',
                  value:
                    analyticRequestQuery?.data?.customerAnalytic
                      ?.analyticCreditCost,
                },
                {
                  label: 'Organization',
                  value:
                    organization?.customerDisplayName ??
                    organization?.customerName,
                },
                {
                  label: 'Created By',
                  value: getUsername(createdByUser),
                },
                {
                  label: 'Assigned To',
                  value: getUsername(assignedToUser),
                },
                {
                  label: 'Archived',
                  value: (
                    <div className="flex flex-row gap-2 items-center">
                      <span>
                        {analyticRequestQuery.data?.archived ? 'Yes' : 'No'}
                      </span>
                      {hasAnalyticRequestManagementPermission && (
                        <ContextMenu
                          disabled={
                            analyticRequestAvailabilityPutMutation.isPending
                          }
                          dataTestId="archived-context-menu-analytic-request-details"
                          groups={availabilityContextMenuItems}
                          icon={
                            analyticRequestAvailabilityPutMutation.isPending ? (
                              <FontAwesomeIcon
                                icon={faCircleNotch}
                                spin
                                className="text-gray-700"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="text-gray-700"
                              />
                            )
                          }
                        />
                      )}
                    </div>
                  ),
                },
                {
                  label: 'Created',
                  value: formatDateAndTime(
                    analyticRequestQuery.data?.createDatetime
                  ),
                },
                {
                  label: 'Updated',
                  value: formatDateAndTime(
                    analyticRequestQuery.data?.updateDatetime
                  ),
                },
                {
                  label: 'Completed',
                  value: formatDateAndTime(
                    analyticRequestQuery.data?.completedDatetime
                  ),
                },
              ]}
            />

            <div className="text-sm">Related Capture Info</div>
            <EntityDetailsSection
              items={[
                {
                  label: 'Capture Name',
                  value: captureName,
                },
                {
                  label: 'Capture Scan Date',
                  value: analyticRequestCaptures.data?.[0]?.scanDatetime,
                },
                {
                  label: 'Capture UUID',
                  value: analyticRequestCaptures.data?.[0]?.captureUuid,
                },
                {
                  label: 'Capture Viewer',
                  value: (
                    <div className="flex flex-row gap-2">
                      <Button.Text
                        id="viewer-button"
                        onClick={() => {
                          if (isViteApp) {
                            navigate(`/app/capture/${theCapture?.id}`);
                          } else {
                            navigate(`/captures/${theCapture?.id}`);
                          }
                        }}
                        label=""
                        icon={faLink}
                      />
                      <Button.Text
                        id="viewer-button"
                        onClick={() => {
                          window.open(
                            isViteApp
                              ? `/app/capture/${theCapture?.id}`
                              : `/captures/${theCapture?.id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        label=""
                        icon={faExternalLinkAlt}
                      />
                    </div>
                  ),
                  link: true,
                },
                {
                  label: 'QAQC Search',
                  value: (
                    <div className="flex flex-row gap-2">
                      <Button.Text
                        id="qaqc-panel-button"
                        onClick={() => {
                          if (isViteApp) {
                            navigate(
                              `/app/admin/operations/qaqc?name=${encodeURIComponent(
                                theCapture?.captureUuid || ''
                              )}`
                            );
                          } else {
                            navigate(
                              `/ops/qaqc?name=${encodeURIComponent(
                                theCapture?.captureUuid || ''
                              )}`
                            );
                          }
                        }}
                        label=""
                        icon={faLink}
                      />
                      <Button.Text
                        id="qaqc-panel-button"
                        onClick={() => {
                          if (isViteApp) {
                            window.open(
                              `/app/admin/operations/qaqc?name=${encodeURIComponent(
                                theCapture?.captureUuid || ''
                              )}`,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          } else {
                            window.open(
                              `/ops/qaqc?name=${encodeURIComponent(
                                theCapture?.captureUuid || ''
                              )}`,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          }
                        }}
                        label=""
                        icon={faExternalLinkAlt}
                      />
                    </div>
                  ),
                  link: true,
                },
                {
                  label: 'QAQC Viewer',
                  value: <QaqcViewerLink />,
                  link: true,
                },
                {
                  label: 'Airflow',
                  value: <AirFlowLink />,
                  link: true,
                },
              ]}
            />
          </div>
          <div className="flex-grow h-full">
            <Datatable
              data={analyticRequestJobsQuery.data ?? []}
              noResults={{
                title: 'No jobs were triggered for this analytic request',
                message: 'Check back later for updates or contact an admin',
              }}
              error={
                analyticRequestJobsQuery.isError
                  ? {
                      title:
                        'There was a problem loading jobs for this analytic request',
                      message: 'Try refreshing the page',
                      action: () => analyticRequestJobsQuery.refetch(),
                    }
                  : undefined
              }
              loading={analyticRequestJobsQuery.isLoading}
              columns={[
                { label: 'Job Name', value: (row) => row.name, flex: 2 },
                { label: 'Job Type', value: (row) => row.jobTypeName },

                {
                  label: 'Status',
                  value: (row, index) => (
                    <AnalyticRequestsDetailsJobStatus job={row} index={index} />
                  ),
                },
                {
                  label: 'Entity ID',
                  value: (row) => row.entityId,
                  flex: 0.5,
                },
                {
                  visible: hasPermission(
                    LdFlags.CaptureAnalyticAHJSubmit,
                    permissions
                  ),
                  label: '',
                  value: (row, index) => {
                    const supportedCategories = [
                      JobTypeCategory.CaptureExtractionJob,
                      JobTypeCategory.CaptureJob,
                      JobTypeCategory.PipelineJob,
                    ];

                    if (
                      !supportedCategories.includes(
                        row.entityName as JobTypeCategory
                      )
                    ) {
                      return null;
                    }
                    return (
                      <div className="p-1">
                        <Button.Icon
                          id={`analytic-job-info-${index}`}
                          icon={faRecycle}
                          internalPadding="p-0.5"
                          onClick={() => {
                            if (isViteApp) {
                              navigate(
                                `/app/admin/operations/analytic-requests/${
                                  analyticRequestQuery.data?.id
                                }/details/ad-hoc/${row.entityName?.toLowerCase()}/${
                                  row.entityId
                                }`
                              );
                            } else {
                              navigate(
                                `/ops/analytics/${
                                  analyticRequestQuery.data?.id
                                }/details/ad-hoc/${row.entityName?.toLowerCase()}/${
                                  row.entityId
                                }`
                              );
                            }
                          }}
                        />
                      </div>
                    );
                  },
                  flex: 0.5,
                  style: {
                    bodyStyle: 'flex justify-center',
                  },
                },
                {
                  label: '',
                  value: (row, index) => (
                    <AnalyticRequestsDetailsJobInfoTooltip
                      job={row}
                      index={index}
                    />
                  ),
                  flex: 0.5,
                  style: {
                    bodyStyle: 'flex justify-center',
                  },
                },
                {
                  label: '',
                  value: (row, index) => {
                    return row.entityName === JobTypeCategory.CaptureJob ? (
                      <div className="p-1">
                        <Button.Icon
                          id={`analytic-job-qaqc-${index}`}
                          icon={faMagnifyingGlass}
                          internalPadding="p-0.5"
                          onClick={() => {
                            if (isViteApp) {
                              navigate(
                                `/app/admin/operations/qaqc/${theCapture?.id}/${row.entityId}`
                              );
                            } else {
                              navigate(
                                `/ops/qaqc/${theCapture?.id}/${row.entityId}`
                              );
                            }
                          }}
                        />
                      </div>
                    ) : null;
                  },
                  flex: 0.5,
                  style: {
                    bodyStyle: 'flex justify-center',
                  },
                },
              ]}
              rowHeight={40}
              style={dataTableAgerSmallStyle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const AnalyticRequestsDetailsJobInfoTooltip = ({
  job,
  index,
}: {
  job: APIClient.AnalyticJob;
  index: number;
}) => {
  const [expanded, setExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const {
    extractionJobByIdQuery,
    captureJobByIdQuery,
    pipelineJobByIdQuery,
    operationJobByIdQuery,
  } = useOpsAnalyticRequestsQueries({
    extractionJobId:
      job.entityName === JobTypeCategory.CaptureExtractionJob
        ? job.entityId
        : undefined,
    captureJobId:
      job.entityName === JobTypeCategory.CaptureJob ? job.entityId : undefined,
    pipelineJobId:
      job.entityName === JobTypeCategory.PipelineJob ? job.entityId : undefined,
    operationJobId:
      job.entityName === JobTypeCategory.OperationJob
        ? job.entityId
        : undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setExpanded(false);
    };

    const handleClick = (event: MouseEvent) => {
      if (
        popupRef.current?.contains(event.target as Node) ||
        containerRef.current?.contains(event.target as Node)
      ) {
        return;
      }

      setExpanded(false);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const loading = useMemo(() => {
    if (job.entityName === JobTypeCategory.CaptureExtractionJob) {
      return extractionJobByIdQuery.isLoading;
    }

    if (job.entityName === JobTypeCategory.CaptureJob) {
      return captureJobByIdQuery.isLoading;
    }

    if (job.entityName === JobTypeCategory.PipelineJob) {
      return pipelineJobByIdQuery.isLoading;
    }

    if (job.entityName === JobTypeCategory.OperationJob) {
      return operationJobByIdQuery.isLoading;
    }

    return false;
  }, [
    extractionJobByIdQuery.isLoading,
    captureJobByIdQuery.isLoading,
    pipelineJobByIdQuery.isLoading,
    operationJobByIdQuery.isLoading,
    job,
  ]);

  const infoComponent = useMemo(() => {
    if (loading) {
      return null;
    }

    const noInfo = (
      <div className="p-1 text-xs text-gray-dark text-center border-gray-light">
        No information available
      </div>
    );

    if (job.entityName === JobTypeCategory.CaptureExtractionJob) {
      if (!extractionJobByIdQuery.data) {
        return noInfo;
      }

      const extractionJob =
        extractionJobByIdQuery.data as APIModels.CaptureExtractionJob;
      return (
        <EntityDetailsSection
          title="Capture Extraction Job"
          items={[
            {
              label: 'Name',
              value: extractionJob.name,
            },
            { label: 'ID', value: extractionJob.id },
            { label: 'UUID', value: extractionJob.uuid },
            {
              label: 'Parameters',
              value: extractionJob.parametersJson,
            },
            {
              label: 'Created',
              value: formatDateAndTime(extractionJob.createDatetime),
            },
            {
              label: 'Completed',
              value: formatDateAndTime(extractionJob.completedDateTime),
            },
          ]}
        />
      );
    }

    if (job.entityName === JobTypeCategory.CaptureJob) {
      if (!captureJobByIdQuery.data) {
        return noInfo;
      }

      const captureJob = captureJobByIdQuery.data as APIModels.CaptureJob;
      return (
        <EntityDetailsSection
          title="Capture Job"
          items={[
            {
              label: 'Name',
              value: captureJob.name,
            },
            { label: 'ID', value: captureJob.id },
            { label: 'UUID', value: captureJob.uuid },
            {
              label: 'Parameters',
              value: captureJob.parametersJson,
            },
            {
              label: 'Model',
              value: captureJob.mlModel,
            },
            {
              label: 'Mosaic Engine',
              value: captureJob.mosaicEngine,
            },
            {
              label: 'Created',
              value: formatDateAndTime(captureJob.createDatetime),
            },
            {
              label: 'Completed',
              value: formatDateAndTime(captureJob.completedDateTime),
            },
          ]}
        />
      );
    }

    if (job.entityName === JobTypeCategory.PipelineJob) {
      if (!pipelineJobByIdQuery.data) {
        return noInfo;
      }

      const pipelineJob = pipelineJobByIdQuery.data as APIModels.PipelineJob;
      return (
        <EntityDetailsSection
          title="Pipeline Job"
          items={[
            {
              label: 'Name',
              value: pipelineJob.name,
            },
            { label: 'ID', value: pipelineJob.id },
            {
              label: 'Pipeline',
              value: pipelineJob.pipeline?.name,
            },
            {
              label: 'Model',
              value: pipelineJob.mlModel?.name,
            },
            {
              label: 'Mosaic engine',
              value: pipelineJob.mosaicEngine?.name,
            },
            {
              label: 'Parameters',
              value: pipelineJob.data,
            },
            {
              label: 'Stage',
              value: pipelineJob.stage,
            },
            {
              label: 'Exclude files',
              value: pipelineJob.excludeFiles,
            },
            {
              label: 'Created',
              value: formatDateAndTime(pipelineJob.createDatetime),
            },
            {
              label: 'Completed',
              value: formatDateAndTime(pipelineJob.completedDatetime),
            },
          ]}
        />
      );
    }

    if (job.entityName === JobTypeCategory.OperationJob) {
      if (!operationJobByIdQuery.data) {
        return noInfo;
      }

      const operationJob = operationJobByIdQuery.data as APIModels.OperationJob;
      return (
        <EntityDetailsSection
          title="Operation Job"
          items={[
            {
              label: 'Name',
              value: operationJob.name,
            },
            { label: 'ID', value: operationJob.id },
            {
              label: 'Assigned to ID',
              value: operationJob.assignedToId,
            },
            {
              label: 'Req. Capture extraction job ID',
              value: operationJob.requiredCaptureExtractionJobId,
            },
            {
              label: 'Req. Capture job ID',
              value: operationJob.requiredCaptureJobId,
            },
            {
              label: 'Description',
              value: operationJob.description,
            },
            {
              label: 'Created',
              value: formatDateAndTime(operationJob.createDatetime),
            },
            {
              label: 'Completed',
              value: formatDateAndTime(operationJob.completedDatetime),
            },
          ]}
        />
      );
    }

    return null;
  }, [
    loading,
    extractionJobByIdQuery,
    captureJobByIdQuery,
    pipelineJobByIdQuery,
    operationJobByIdQuery,
    job,
  ]);

  return (
    <div className="p-1" ref={containerRef} tabIndex={-1}>
      <Button.Icon
        id={`analytic-job-info-${index}`}
        icon={faInfoCircle}
        internalPadding="p-0.5"
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
        loading={loading}
      />

      {expanded &&
        createPortal(
          <div
            tabIndex={-1}
            style={{
              top: containerRef.current?.getBoundingClientRect().top,
              left: containerRef.current?.getBoundingClientRect().left,
              transform: 'translate(-100%, 25px)',
              width: '500px',
            }}
            className="z-50 absolute bg-white rounded-lg shadow-md"
            ref={popupRef}
          >
            {infoComponent}
          </div>,
          document.body
        )}
    </div>
  );
};
