export interface TileViewOldProps<T> {
  data: T[];
  tileGenerator: (
    item: T,
    layout: TileViewLayout,
    index: number
  ) => React.ReactNode;
  layout: TileViewLayout;
  pagination?: TileViewOldPaginationProps;
}

export enum TileViewLayout {
  LargeGrid = 'LargeGrid',
  SmallGrid = 'SmallGrid',
  List = 'List',
  Cloud = 'Cloud',
}

export interface TileViewOldPaginationProps {
  loadPage: () => Promise<void>;
  hasNextPage: boolean;
}
