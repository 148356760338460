import {
  faBoxArchive,
  faCircleCheck,
  faClone,
  faCopy,
  faCube,
  faExternalLink,
  faInfoSquare,
  faMagnifyingGlassChart,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import compare from 'trivial-compare';
import { useDebouncyEffect } from 'use-debouncy';

import {
  APIClient,
  APIModels,
  Capture,
  Customer,
  formatDate,
} from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import {
  CloneDatasetModal,
  ContextMenuGroupProps,
  useBackgroundTaskManager,
} from '@agerpoint/feature';
import {
  BackgroundTaskResult,
  getFirstActiveEptId,
  isCaptureReadyToViewInLegacyLayout,
  isCaptureReadyToViewInNewLayout,
} from '@agerpoint/types';
import {
  APIUtils,
  convertDateToUtcBeginningOfDay,
  convertDateToUtcEndOfDay,
  isMobileTablet,
  useIsViteApp,
  useItemSelection,
  useNewLayout,
  usePageTitle,
  useQueryState,
  useToasts,
  uuidRegex,
} from '@agerpoint/utilities';

import { useAdminCapturesQueries } from './admin-captures-queries';
import { AdminCapturesListActions } from './subcomponents/admin-captures-list-actions';
import { AdminCapturesListDatatable } from './subcomponents/admin-captures-list-datatable';
import { AdminCapturesListMap } from './subcomponents/admin-captures-list-map';
import { AdminCapturesListTiles } from './subcomponents/admin-captures-list-tiles';
import {
  CapturesView,
  CapturesViewSelector,
} from './subcomponents/admin-captures-list-view-selector';

export const AdminCapturesList = () => {
  const navigate = useNavigate();
  usePageTitle(() => 'Platform - Captures', []);

  const isViteApp = useIsViteApp();

  const queryClient = useQueryClient();
  const backgroundTaskManager = useBackgroundTaskManager();

  const isMobile = useMemo(() => isMobileTablet(), []);

  const toasts = useToasts();

  const [layout, setLayout] = useState<CapturesView>(
    isMobile ? 'Tiles' : 'List'
  );

  const [filter, setFilter] = useState<APIModels.CaptureFilter>();

  const { showNewLayout } = useNewLayout();

  const { projectsQuery, usersQuery, organizationsQuery, capturesQuery } =
    useAdminCapturesQueries(filter);

  const [nameUUIDFilter, setNameUUIDFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a,
    toUrlParam: (a) => a.trim(),
  });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const [selectedFilterProjects, setSelectedFilterProjects] = useQueryState<
    APIModels.Project[]
  >({
    paramName: 'projects',
    initialValue: [],
    fromUrlParam: (a) => {
      const splitted = a?.split(',');
      return [...(projectsQuery.data || [])]
        .sort((a, b) => compare(a.id, b.id))
        .filter((x) => x.id && splitted?.includes(x.id.toString() ?? ''));
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: projectsQuery.isSuccess,
  });

  const [selectedFilterUsers, setSelectedFilterUsers] = useQueryState<
    APIModels.User[]
  >({
    paramName: 'users',
    initialValue: [],
    fromUrlParam: (a) => {
      return (
        usersQuery.data?.filter((x) => a?.includes(x.id as string) ?? false) ??
        []
      );
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: usersQuery.isSuccess,
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<APIModels.Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered =
          organizationsQuery.data?.filter((x) => {
            return splitted?.includes(x.id as number) ?? false;
          }) ?? [];
        return filtered;
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: organizationsQuery.isSuccess,
    });

  const [selectedFilterCaptureDate, setSelectedFilterCaptureDate] =
    useQueryState<[Date | undefined, Date | undefined]>({
      paramName: 'capture_date',
      initialValue: [undefined, undefined],
      toUrlParam: (v) => {
        if (v?.[0] && v?.[1]) {
          const start = formatDate(v[0].toString());
          const end = formatDate(v[1].toString());

          return `${start}-${end}`;
        }
        return '';
      },
      fromUrlParam: (v) => {
        const dates = v.split('-');
        if (dates.length !== 2) {
          return [undefined, undefined];
        }

        const [start, end] = dates;

        const startDate = new Date(start);
        const endDate = new Date(end);

        return [startDate, endDate];
      },
    });

  const [selectedFilterCreateDate, setSelectedFilterCreateDate] = useQueryState<
    [Date | undefined, Date | undefined]
  >({
    paramName: 'create_date',
    initialValue: [undefined, undefined],
    toUrlParam: (v) => {
      if (v?.[0] && v?.[1]) {
        const start = formatDate(v[0].toString());
        const end = formatDate(v[1].toString());

        return `${start}-${end}`;
      }
      return '';
    },
    fromUrlParam: (v) => {
      const dates = v.split('-');
      if (dates.length !== 2) {
        return [undefined, undefined];
      }

      const [start, end] = dates;

      const startDate = new Date(start);
      const endDate = new Date(end);

      return [startDate, endDate];
    },
  });

  useEffect(() => {
    const isNameAnUUID =
      nameUUIDFilter.trim().length > 0 && uuidRegex.test(nameUUIDFilter.trim());

    setFilter((prev) => ({
      ...prev,
      orderBy: 'scanDatetime',
      orderAscending: false,
      captureName: isNameAnUUID ? '' : nameUUIDFilter.trim(),
      captureUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => {
      let adjustedStartScanDate = convertDateToUtcBeginningOfDay(
        selectedFilterCaptureDate?.[0]
      );

      const adjustedEndScanDate = convertDateToUtcEndOfDay(
        selectedFilterCaptureDate?.[1]
      );

      if (adjustedEndScanDate === undefined) {
        adjustedStartScanDate = undefined;
      }

      let adjustedStartCreateDate = convertDateToUtcBeginningOfDay(
        selectedFilterCreateDate?.[0]
      );
      const adjustedEndCreateDate = convertDateToUtcEndOfDay(
        selectedFilterCreateDate?.[1]
      );

      if (adjustedEndCreateDate === undefined) {
        adjustedStartCreateDate = undefined;
      }

      return {
        ...prev,
        projectUuids: selectedFilterProjects.map((x) => x.uuid as string),
        userUuids: selectedFilterUsers.map((x) => x.id as string),
        customerId: selectedFilterOrganizations.map((x) => x.id as number),
        startScanDatetime: adjustedStartScanDate?.toISOString(),
        endScanDatetime: adjustedEndScanDate?.toISOString(),
        startCreatedDatetime: adjustedStartCreateDate?.toISOString(),
        endCreatedDatetime: adjustedEndCreateDate?.toISOString(),
        archived: archivedFilter,
      };
    });
  }, [
    selectedFilterCaptureDate,
    selectedFilterCreateDate,
    archivedFilter,
    selectedFilterProjects,
    selectedFilterUsers,
    selectedFilterOrganizations,
  ]);

  useDebouncyEffect(
    () => {
      const isNameAnUUID =
        nameUUIDFilter.trim().length > 0 &&
        uuidRegex.test(nameUUIDFilter.trim());

      setFilter((prev) => ({
        ...prev,
        captureName: isNameAnUUID ? '' : nameUUIDFilter.trim(),
        captureUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
      }));
    },
    500,
    [nameUUIDFilter]
  );

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        (filter?.captureName?.length ?? 0) > 0 ||
        (filter?.captureUuid?.length ?? 0) > 0 ||
        (filter?.projectUuids ?? []).length > 0 ||
        (filter?.userUuids ?? []).length > 0 ||
        (filter?.customerId ?? []).length > 0 ||
        (filter?.startScanDatetime && filter?.endScanDatetime) ||
        (filter?.startCreatedDatetime && filter?.endCreatedDatetime)
      ),
    [filter]
  );

  const clearFilter = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setNameUUIDFilter('');
    setSelectedFilterProjects([]);
    setSelectedFilterUsers([]);
    setSelectedFilterOrganizations([]);
    setSelectedFilterCaptureDate([undefined, undefined]);
    setSelectedFilterCreateDate([undefined, undefined]);

    searchParams.delete('organizations');
    searchParams.delete('users');
    searchParams.delete('name');
    searchParams.delete('capture_date');
    searchParams.delete('create_date');
    searchParams.delete('projects');
    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/platform/captures'
          : '/admin/captures',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  const captures = useMemo(() => {
    return capturesQuery.data?.pages.flatMap((x) => x) ?? [];
  }, [capturesQuery.data]);

  const captureSelection = useItemSelection<number, APIModels.Capture>({
    items: captures,
    dependencies: [captures.map((c) => c.id).join(',')],
    idField: 'id',
  });

  const captureOnClick = useCallback(
    (
      capture: APIModels.Capture,
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (isViteApp) {
        if (event.metaKey || event.ctrlKey) {
          window.open(
            `/app/capture/${capture.id}`,
            '_blank',
            'noopener noreferrer'
          );
        } else {
          navigate(`/app/admin/platform/captures/${capture.id}/details`, {
            state: {
              params: window.location.search,
            },
          });
        }
      } else {
        if (event.metaKey || event.ctrlKey) {
          const canViewCapture = showNewLayout
            ? isCaptureReadyToViewInNewLayout(capture)
            : isCaptureReadyToViewInLegacyLayout(capture);

          if (!canViewCapture) {
            toasts.add({
              title: 'Capture not ready.',
              message:
                'This capture is not ready or is missing a complete pointcloud.',
              type: 'info',
            });
            return;
          }

          const url = showNewLayout
            ? `/captures/${capture.id}`
            : `/captures/${capture.id}/${getFirstActiveEptId(capture)}`;

          window.open(url, '_blank', 'noopener noreferrer');
        } else {
          navigate(`${capture.id}/manage/details`, {
            state: {
              params: window.location.search,
            },
          });
        }
      }
    },
    [toasts, navigate, showNewLayout, isViteApp]
  );

  const [showCloneCapturesModal, setShowCloneCapturesModal] = useState(false);
  const [captureIdToClone, setCaptureIdToClone] = useState<number>();

  const copyCapturesUUIDToClipboard = useCallback(
    (capture: Capture) => {
      if (!capture || !capture.captureUuid) {
        toasts.add(toasts.prepare.error('Failed to copy the capture UUID!'));

        return;
      }

      try {
        navigator.clipboard.writeText(capture.captureUuid);
        toasts.add(toasts.prepare.valueCopied('capture UUID'));
      } catch (e) {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to copy the capture UUID!'));
      }
    },
    [toasts]
  );

  const contextMenuItems = useCallback(
    (item: Capture) => {
      const insightsGroup: ContextMenuGroupProps = {
        label: 'Insights',
        items: [
          {
            icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
            label: 'Analytics',
            onClick: () => {
              if (!item.id) return;

              if (isViteApp) {
                navigate(`/app/admin/platform/captures/${item.id}/analytics`, {
                  state: {
                    params: window.location.search,
                  },
                });
              } else {
                navigate(`${item.id}/manage/analytics`, {
                  state: {
                    params: window.location.search,
                  },
                });
              }
            },
          },
          {
            icon: <FontAwesomeIcon icon={faCube} />,
            label: '3D Models',
            onClick: () => {
              if (!item.id) return;

              if (isViteApp) {
                navigate(`/app/admin/platform/captures/${item.id}/3d-models`, {
                  state: {
                    params: window.location.search,
                  },
                });
              } else {
                navigate(`${item.id}/manage/3d-models`, {
                  state: {
                    params: window.location.search,
                  },
                });
              }
            },
          },
          {
            icon: <FontAwesomeIcon icon={faInfoSquare} />,
            label: 'Details',
            disabled: false,
            onClick: () => {
              if (!item.id) return;

              if (isViteApp) {
                navigate(`/app/admin/platform/captures/${item.id}/details`, {
                  state: {
                    params: window.location.search,
                  },
                });
              } else {
                navigate(`${item.id}/manage/details`, {
                  state: {
                    params: window.location.search,
                  },
                });
              }
            },
          },
        ],
      };

      const managementGroup: ContextMenuGroupProps = {
        label: 'Management',
        items: [
          {
            icon: <FontAwesomeIcon icon={faBoxArchive} />,
            label: 'Archiving',
            onClick: async () => {
              if (!item.id) return;

              if (isViteApp) {
                navigate(`/app/admin/platform/captures/${item.id}/details`, {
                  state: {
                    params: window.location.search,
                  },
                });
              } else {
                navigate(`${item.id}/manage/details`, {
                  state: {
                    params: window.location.search,
                  },
                });
              }
            },
          },
          {
            icon: <FontAwesomeIcon icon={faClone} />,
            label: 'Clone',
            onClick: () => {
              setCaptureIdToClone(item.id);
              setShowCloneCapturesModal(true);
            },
          },
        ],
      };

      const otherGroup: ContextMenuGroupProps = {
        label: 'Other',
        items: [
          {
            icon: <FontAwesomeIcon icon={faCopy} />,
            label: 'Copy UUID',
            onClick: () => {
              copyCapturesUUIDToClipboard(item);
            },
          },
          {
            icon: <FontAwesomeIcon icon={faExternalLink} />,
            label: 'New Window',
            onClick: () => {
              if (isViteApp) {
                window.open(
                  `/app/capture/${item.id}`,
                  '_blank',
                  'noopener noreferrer'
                );
                return;
              }

              const canViewCapture = showNewLayout
                ? isCaptureReadyToViewInNewLayout(item)
                : isCaptureReadyToViewInLegacyLayout(item);

              if (!canViewCapture) {
                toasts.add({
                  title: 'Capture not ready.',
                  message:
                    'This capture is not ready or is missing a complete pointcloud.',
                  type: 'info',
                });
                return;
              }

              const url = showNewLayout
                ? `/captures/${item.id}`
                : `/captures/${item.id}/${getFirstActiveEptId(item)}`;

              window.open(url, '_blank', 'noopener noreferrer');
            },
          },
        ],
      };

      return [insightsGroup, managementGroup, otherGroup];
    },
    [toasts, copyCapturesUUIDToClipboard, navigate, showNewLayout]
  );

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="flex flex-row gap-1 justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold py-1">Captures</h1>
        <AdminCapturesListActions
          captureSelection={captureSelection}
          showingArchived={archivedFilter}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="w-72">
          <Input.Text.Single
            id="capture-table-search"
            value={nameUUIDFilter}
            setValue={setNameUUIDFilter}
            placeholderIcon={Input.placeholderIcons.search}
            placeholder={'Search by Name or UUID'}
          />
        </div>
        <Input.Select.Multi
          id="project-filter-select"
          placeholder="Projects"
          title="Projects"
          options={projectsQuery.data ?? []}
          value={selectedFilterProjects}
          loading={projectsQuery.isLoading}
          setValue={setSelectedFilterProjects}
          optionBuilder={(o) => o.name}
          maxWidth="232px"
        />
        <Input.Select.Multi
          id="user-filter-select"
          placeholder="Users"
          title="Users"
          options={usersQuery.data ?? []}
          value={selectedFilterUsers}
          loading={usersQuery.isLoading}
          setValue={setSelectedFilterUsers}
          optionBuilder={(o) =>
            `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
          }
          maxWidth="232px"
        />
        <Input.Select.Multi
          id="organization-filter-select"
          placeholder="Organizations"
          title="Organizations"
          options={organizationsQuery.data ?? []}
          value={selectedFilterOrganizations}
          loading={organizationsQuery.isLoading}
          setValue={setSelectedFilterOrganizations}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          maxWidth="232px"
        />
        <div className="w-48">
          <Input.Date.Range
            id="capture-date-filter"
            placeholder="Capture Date Range"
            value={selectedFilterCaptureDate}
            setValue={setSelectedFilterCaptureDate}
          />
        </div>
        <div className="w-48">
          <Input.Date.Range
            id="create-date-filter"
            placeholder="Create Date Range"
            value={selectedFilterCreateDate}
            setValue={setSelectedFilterCreateDate}
          />
        </div>
        <Input.Select.Inline
          id="capture-status-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <Button.ClearFilter visible={hasFiltersApplied} onClick={clearFilter} />

        <div className="ml-auto flex flex-row gap-1 items-center">
          {layout === 'Tiles' &&
            (captureSelection.isEverythingSelected ? (
              <Button.Small
                id="captures-list-deselect-all"
                label={'Deselect All'}
                onClick={() => {
                  captureSelection.toggleSelectionEverything();
                }}
                icon={faSolidCircleCheck}
                iconColor={'text-accent'}
              />
            ) : (
              <Button.Small
                id="captures-list-select-all"
                label={'Select All'}
                onClick={() => {
                  captureSelection.toggleSelectionEverything();
                }}
                icon={faCircleCheck}
              />
            ))}
          <CapturesViewSelector layout={layout} setLayout={setLayout} />
        </div>
      </div>
      {layout === 'List' && (
        <div className="p-4 w-full h-full">
          <AdminCapturesListDatatable
            capturesQuery={capturesQuery}
            hasFiltersApplied={hasFiltersApplied}
            clearFilters={clearFilter}
            captureSelection={captureSelection}
            rowOnClick={captureOnClick}
            contextMenuItems={contextMenuItems}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      )}
      {layout === 'Tiles' && (
        <AdminCapturesListTiles
          capturesQuery={capturesQuery}
          hasFiltersApplied={hasFiltersApplied}
          clearFilters={clearFilter}
          captureSelection={captureSelection}
          rowOnClick={captureOnClick}
          contextMenuItems={contextMenuItems}
          filter={filter}
        />
      )}

      {layout === 'Map' && (
        <div className="p-4 w-full h-full">
          <AdminCapturesListMap
            capturesQuery={capturesQuery}
            hasFiltersApplied={hasFiltersApplied}
            clearFilters={clearFilter}
            captureSelection={captureSelection}
            rowOnClick={captureOnClick}
            contextMenuItems={contextMenuItems}
            filter={filter}
          />
        </div>
      )}

      <CloneDatasetModal
        title="Clone Capture(s)"
        handleCloseDialog={() => {
          setCaptureIdToClone(undefined);
          setShowCloneCapturesModal(false);
        }}
        open={showCloneCapturesModal}
        cloningCount={captureIdToClone ? 1 : captureSelection.selectionSize}
        organizations={(organizationsQuery.data ?? []) as Customer[]}
        users={usersQuery.data ?? []}
        onClone={(org, user) => {
          const captureIds: number[] = captureIdToClone
            ? [captureIdToClone]
            : captureSelection
                .getSelectionArray()
                .map((c) => c.id ?? NaN)
                .filter((c) => !Number.isNaN(c));

          backgroundTaskManager.addTaskGroup({
            groupDesc: 'Cloning Capture(s)',
            groupTasks: [
              backgroundTaskManager.createBackgroundTask(
                'Cloning Capture(s)',
                async (resolve) => {
                  await APIClient.cloneCaptures({
                    assignToUserUuid: user,
                    captureIds: captureIds,
                    customerId: org,
                  });

                  resolve({ type: BackgroundTaskResult.success });
                }
              ),
            ],
            onError: async () => {
              toasts.add(toasts.prepare.error('Failed to clone capture(s)!'));
            },
            onSuccess: async () => {
              toasts.add(toasts.prepare.entityCloned('capture(s)'));

              queryClient.invalidateQueries({
                queryKey: [APIUtils.QueryKey.captures],
              });
            },
          });

          toasts.add({
            title: 'Captures are being cloned.',
            type: 'info',
          });
        }}
      />
    </div>
  );
};
