import { PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation2dPoints,
  CaptureObjectGenericAttributes,
  ColorsThreeD,
  EventBusNames,
  HexColor,
  IMultiPoint2d,
  SVG_PATH_NAMES,
} from '@agerpoint/types';

import { AnnotationBase } from '../annotations.base';
import { GeometryBase } from '../geometry.base';
import { Point2d } from './point-2d';

export class MultiPoint2d extends GeometryBase implements IMultiPoint2d {
  eventName: EventBusNames;
  points: Point2d[] = [];
  userData: {
    originalColor: ColorsThreeD | HexColor;
    originalScale?: Vector3;
    originalLineWidth?: number;
  };
  type: Annotation2dPoints;

  constructor(
    eventName: EventBusNames,
    id: string,
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    canvas: HTMLCanvasElement,
    isPotree: boolean = false,
    coAttrs: CaptureObjectGenericAttributes
  ) {
    super(scene, perspectiveCamera, isPotree, canvas);
    this.name = coAttrs.name || '';
    this.eventName = eventName;
    this.uniqueId = id;
    this.color = coAttrs.color || ColorsThreeD.Cyan;
    this.perspectiveCamera = perspectiveCamera;
    this.userData = {
      originalColor: coAttrs.color || ColorsThreeD.Cyan,
      originalScale: new Vector3(1, 1, 1),
    };
    this.type = Annotation2dPoints.AnnotationMultiPoint2d;
    if (!canvas || !this.canvas) {
      throw new Error('Canvas is required');
    }
    this.canvas = canvas;
  }

  getChildById(id: string): Point2d | undefined {
    return this.points.find((point) => point.uniqueId === id);
  }

  addPoints(newPoints: Vector3[]): void {
    if (!newPoints?.length) return;
    if (this.points?.length) {
      this.points.forEach((point) => {
        point.dispose();
      });
    }
    const options = {
      fill: this.color,
      name: `${this.name}`,
      editable: true,
      visible: true,
      visibleLabel: true,
      type: this.type,
      clickable: true,
      pathType: SVG_PATH_NAMES.CIRCLE_MARKER,
    };
    this.points = newPoints.map((position, index) => {
      options.name = `${this.name}`;
      const point = new Point2d(
        this.eventName,
        `${this.uniqueId}-${index}`,
        position,
        options,
        this.scene,
        this.perspectiveCamera,
        this.canvas as HTMLCanvasElement,
        this.isPotree
      );
      point.on('click', (id) => {
        this.emit('click', id);
      });
      point.on('mousedown', (id) => {
        this.emit('mousedown', id);
      });
      point.on('mouseup', (id) => {
        this.emit('mouseup', id);
      });
      return point;
    });
  }

  addNewPoint(position: Vector3): void {
    const options = {
      fill: this.color,
      name: `${this.name}`,
      editable: true,
      visible: true,
      visibleLabel: true,
      type: this.type,
      clickable: true,
      pathType: SVG_PATH_NAMES.CIRCLE_MARKER,
    };
    const point = new Point2d(
      this.eventName,
      `${this.uniqueId}-${this.points.length}`,
      position,
      options,
      this.scene,
      this.perspectiveCamera,
      this.canvas as HTMLCanvasElement,
      this.isPotree
    );
    point.on('click', (id) => {
      this.emit('click', id);
    });
    point.on('mousedown', (id) => {
      this.emit('mousedown', id);
    });
    point.on('mouseup', (id) => {
      this.emit('mouseup', id);
    });
    this.points.push(point);
  }

  updateVisibility(isVisible: boolean): void {
    this.visibility = isVisible;
    this.points.forEach((point) => {
      point.updateVisibility(isVisible);
    });
  }

  updatePositions(pointUpdates: Vector3[]): void {
    this.points.forEach((point, i) => {
      point.updatePosition(pointUpdates[i]);
    });
  }

  updatePositionByIndex(index: number, position: Vector3): void {
    if (!this.points[index]) return;
    this.points[index].updatePosition(position);
  }

  updateColor(color: ColorsThreeD | HexColor, persist?: boolean): void {
    this.color = color;
    this.points.forEach((point) => {
      point.updateColor(color);
    });
    if (persist) {
      this.annoMgr.updateCapObjColor(this.uniqueId, color);
    }
  }

  updateType(type: Annotation2dPoints): void {
    this.points.forEach((point) => {
      point.updateType(type);
    });
  }

  updateName(name: string): void {
    this.name = name;
    this.annoMgr.updateCapObjName(this.uniqueId, name);
  }

  highlight(): void {
    this.points.forEach((point) => {
      point.highlight();
    });
  }

  unHighlight(): void {
    this.points.forEach((point) => {
      point.unHighlight();
    });
  }

  show(): void {
    this.updateVisibility(true);
  }

  hide(): void {
    this.updateVisibility(false);
  }

  zoomTo(): void {}

  dispose(): void {
    this.points.forEach((point) => {
      point.dispose();
    });
  }

  delete(): void {
    AnnotationBase.annoStore.removeMultiPoint2dById(this.uniqueId);
    this.annoMgr.deleteCapObj(this.uniqueId);
  }

  disposeAndDelete(): void {
    // store calls dispose
    this.delete();
  }

  removeLastPoint(): void {
    if (!this.points.length) return;

    const lastPoint = this.points[0];
    lastPoint?.dispose();
    this.points = this.points.slice(1);
  }

  render(): void {
    this.points.forEach((point) => {
      point.render();
    });
  }
}
