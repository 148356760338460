import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useQAQCQueries = ({
  filter,
}: {
  filter?: APIModels.CaptureExtractionJobFilter;
}) => {
  const toasts = useToasts();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { captureId, extId, eptId } = useParams();

  const capturesWithExtractionJobsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.capturesWithExtractionJobs,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    queryFn: ({ pageParam }) =>
      APIClient.getFilteredPagedCapturesWithExtractionJobs(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.CaptureExtractionJobFilter
      ),
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    initialPageParam: APIUtils.defaultInitialPageParam,
    enabled: filter !== undefined,
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const qaqcUsersQuery = APIClient.useGetQAQCUsers({
    query: {
      queryKey: [APIUtils.QueryKey.usersQaqc],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const qaqcUsersLookupTable = useLookupTable(qaqcUsersQuery.data, 'id');

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const usersLookupTable = useLookupTable(usersQuery.data, 'id');

  const extractionJobs = useMemo<APIModels.CaptureExtractionJob[]>(() => {
    return (
      capturesWithExtractionJobsQuery.data?.pages
        ?.flatMap((p) => p)
        ?.flatMap(
          (c) => c.extractionJobs as APIModels.CaptureExtractionJob[]
        ) ?? []
    );
  }, [capturesWithExtractionJobsQuery.data]);

  const extractionJobStatusesQuery = APIClient.useGetCaptureExtractionJobStatus(
    {
      query: {
        queryKey: [APIUtils.QueryKey.captureExtractionJobStatuses],
        select: (data) => APIUtils.Sort.captureExtractionJobStatuses(data),
      },
    }
  );

  const extractionJobAssignPutMutation = useMutation({
    mutationFn: async (data: {
      assigneeId?: string;
      extractionJobs: APIModels.CaptureExtractionJob[];
    }) => {
      const promises = [];
      for (const job of data.extractionJobs) {
        const copy = cloneDeep(job);
        if (copy.id === undefined) {
          continue;
        }

        delete copy.createDatetime;
        delete copy.updateDatetime;
        delete copy.completedDateTime;
        delete copy.submittedDateTime;
        delete copy.captureExtractionJobStatus;
        delete copy.updatedById;

        copy.assignedToId = data.assigneeId;

        promises.push(APIClient.putCaptureExtractionJobById(copy.id, copy));
      }
      await Promise.all(promises);
    },
    onSuccess: () => {
      toasts.add(
        toasts.prepare.entityUpdated(
          'extraction job(s)',
          'Assigned extraction job(s) to user.'
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.capturesWithExtractionJobs],
      });
    },
    onError: (e) => {
      console.error(e);
      toasts.add(
        toasts.prepare.error('Failed to assign extraction job(s) to user!')
      );
    },
  });

  const extractionJobUnassignPutMutation = useMutation({
    mutationFn: async (data: {
      extractionJobs: APIModels.CaptureExtractionJob[];
    }) => {
      const promises = [];
      for (const job of data.extractionJobs) {
        const copy = cloneDeep(job);
        if (copy.id === undefined) {
          continue;
        }

        delete copy.createDatetime;
        delete copy.updateDatetime;
        delete copy.completedDateTime;
        delete copy.submittedDateTime;
        delete copy.captureExtractionJobStatus;
        delete copy.updatedById;

        copy.assignedToId = undefined;

        promises.push(APIClient.putCaptureExtractionJobById(copy.id, copy));
      }
      await Promise.all(promises);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.capturesWithExtractionJobs],
      });
    },
    onSuccess: () => {
      toasts.add(
        toasts.prepare.entityUpdated(
          'extraction job(s)',
          'Unassigned extraction job(s).'
        )
      );
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to unassign extraction job(s)!'));
    },
  });

  const extractionJobsQuery = APIClient.useGetCaptureExtractionJobsByCaptureId(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.extractionJobs,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const extractionJobQuery = useQuery({
    queryKey: [
      APIUtils.QueryKey.captures,
      { captureId: Number(captureId) },
      APIUtils.QueryKey.extractionJobs,
      { extractionJobId: Number(extId) },
    ],
    enabled:
      Number.isSafeInteger(Number(captureId)) &&
      Number.isSafeInteger(Number(extId)),
    queryFn: async () => {
      const extJobs = await APIClient.getCaptureExtractionJobsByCaptureId(
        Number(captureId)
      );

      return extJobs.find((job) => job.id === Number(extId));
    },
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
    initialData: () =>
      APIUtils.searchQueriesForInitialValue<APIClient.CaptureExtractionJob>({
        queryClient,
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.extractionJobs,
        ],
        id: Number(extId),
        accessor: 'id',
      }),
  });

  useEffect(() => {
    if (captureId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(captureId))) {
      if (isViteApp) {
        navigate('/app/admin/operations/qaqc' + params);
      } else {
        navigate('/ops/qaqc' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.extractionJobs,
          { captureId: Number(captureId) },
        ],
      });
    }
  }, [captureId]);

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
    },
  });

  const captureProjectsQuery = APIClient.useGetProjectsByCapture(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.projects,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const captureJobId = useMemo(() => {
    return captureQuery.data?.completedJobs?.find(
      (j) => j.eptPointcloudId === Number(eptId)
    )?.id;
  }, [eptId, captureQuery.data]);

  const captureImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(captureJobId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(captureJobId) },
          APIUtils.QueryKey.captureImages,
        ],
        enabled: Number.isSafeInteger(Number(captureJobId ?? undefined)),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  return {
    capturesWithExtractionJobsQuery,
    organizationsLookupTable,
    organizationsQuery,
    qaqcUsersQuery,
    qaqcUsersLookupTable,
    extractionJobs,
    extractionJobStatusesQuery,
    extractionJobAssignPutMutation,
    extractionJobUnassignPutMutation,
    extractionJobsQuery,
    captureQuery,
    usersLookupTable,
    usersQuery,
    captureProjectsQuery,
    extractionJobQuery,
    captureImagesQuery,
  };
};
