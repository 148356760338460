import React, { MouseEventHandler } from 'react';

export interface Sidebar2Props {
  routing: Sidebar2Route;
}

export interface Sidebar2Route {
  element: JSX.Element;
  subroutes?: Sidebar2Subroute[];
}

export interface Sidebar2Subroute {
  element:
    | ((sidebarActions: Sidebar2SubrouteSidebarActions) => JSX.Element)
    | JSX.Element;
  path: string;
  title: JSX.Element;
  subroutes?: Sidebar2Subroute[];
}

export interface Sidebar2SectionProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  trailing?: React.ReactNode;
  leading?: React.ReactNode;
  info?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export function isSidebar2Subroute(
  route: Sidebar2Route | Sidebar2Subroute
): route is Sidebar2Subroute {
  return 'title' in route;
}

export interface Sidebar2ElementProps {
  route: Sidebar2Subroute | Sidebar2Route;
  level: number;
}

export interface Sidebar2SubrouteSidebarActions {
  navigateBack: () => void;
}

export interface Sidebar2ContentProps {
  routing: Sidebar2Route;
}
