import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

interface BreadCrumbsProps {
  items: BreadCrumbsItem[];
}

interface BreadCrumbsItem {
  label: string;
  path: string;
  params?: string;
  additionalState?: { [key: string]: any };
}

export const BreadCrumbs = ({ items }: BreadCrumbsProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row">
      {items.map((item, index) => (
        <div key={index}>
          <span
            className="underline text-gray-700 hover:text-gray-900 cursor-pointer"
            onClick={() => {
              let path = item.path;

              if (item.params) {
                path = `${path}${item.params}`;
              }
              navigate(path, {
                state: item.additionalState,
              });
            }}
          >
            {item.label}
          </span>
          {index !== items.length - 1 && (
            <span className="text-gray-700 px-2">
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
