import { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

type TURLStateContext = ReturnType<typeof useSearchParams>;

export const URLStateContext = createContext<TURLStateContext | undefined>(
  undefined
);

export const useURLStateContext = () => useContext(URLStateContext);
