import { Navigate, Route, Routes } from 'react-router-dom';

import { TabsComponent } from '@agerpoint/component';
import { AppSidebar, Intermediate } from '@agerpoint/feature';
import { LdFlags, TabNames } from '@agerpoint/types';
import { HasPermissionRouteGuard, useGlobalStore } from '@agerpoint/utilities';

import { OrganizationDetailsPage } from './organization-details-page';

export const Organization = () => {
  const { permissions } = useGlobalStore();
  const permissionsHaveBeenSet = Object.keys(permissions).length > 0;
  return !permissionsHaveBeenSet ? (
    <Intermediate message="Getting account information ..." />
  ) : (
    <HasPermissionRouteGuard permissionToCheck={LdFlags.OrganizationManagement}>
      <CanViewOrganization />
    </HasPermissionRouteGuard>
  );
};

const CanViewOrganization = () => {
  return (
    <AppSidebar>
      <>
        <TabsComponent
          tabs={[
            {
              label: 'Details',
              link: '/organization/details',
              type: TabNames.LINK,
            },
            {
              label: 'Settings',
              link: '/organization/settings',
              type: TabNames.LINK,
            },
            {
              label: 'Users',
              link: '/organization/users',
              type: TabNames.LINK,
            },
            {
              label: 'Activity',
              link: '/organization/activity',
              type: TabNames.LINK,
            },
            {
              label: 'Data',
              link: '/organization/data',
              type: TabNames.LINK,
            },
          ]}
        />
        <Routes>
          <Route path="details" element={<OrganizationDetailsPage />} />
          <Route path="/" element={<Navigate to="/details" />} />
        </Routes>
      </>
    </AppSidebar>
  );
};
