import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import { CloudButton, CloudInput } from '@agerpoint/cloud/components';
import {
  EffectNames,
  IPotreeViewerController,
  MaterialType,
} from '@agerpoint/types';
import {
  APIUtils,
  useActionListener,
  useAgerStore,
} from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../captures-viewer';

interface IPotreeCloudTools {
  viewerController?: IPotreeViewerController;
}

export const PotreeCloudTools = ({ viewerController }: IPotreeCloudTools) => {
  const { annotations3dGeometry } = useCapturesViewerContext();
  const { isMobile } = useAgerStore();
  const [toggleAll3dAnnotations, setToggleAll3dAnnotations] = useState(true);

  const [, setSearch] = useSearchParams();

  const annotations = useMemo(() => {
    return [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
    ];
  }, [annotations3dGeometry]);

  const hasClassification = useMemo(
    () => (viewerController?.info?.classificationClasses?.length ?? 0) > 1,
    [viewerController?.info?.classificationClasses]
  );

  const isRGBA = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.RGBA &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const isClassified = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.CLASSIFICATION &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(viewerController?.info?.captureJobMetadata?.id),
    {
      query: {
        enabled: Number.isSafeInteger(
          Number(viewerController?.info?.captureJobMetadata?.id ?? undefined)
        ),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(
              viewerController?.info?.captureJobMetadata?.id
            ),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data?.filter((image) => image.x && image.y && image.z && image.id),
      },
    }
  );

  const hasImages = useMemo(
    () => (captureJobImagesQuery.data?.length ?? 0) > 0,
    [captureJobImagesQuery.data]
  );

  const [pointCloudLoaded, setPointCloudLoaded] = useState(false);

  useEffect(() => {
    setPointCloudLoaded(false);
  }, [viewerController?.info?.captureJobMetadata]);

  const pointCloudLoadedFn = useCallback(() => {
    setPointCloudLoaded(true);
  }, []);

  useActionListener(EffectNames.POTREE_POINT_CLOUD_LOADED, pointCloudLoadedFn);

  useEffect(() => {
    if (!pointCloudLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    viewerController?.removeCameraPositions();
    viewerController?.setCameraPositionsVisible?.(false);
    if (captureJobImagesQuery.data?.length) {
      viewerController?.loadCameraPositions?.(captureJobImagesQuery.data);
    }
  }, [
    pointCloudLoaded,
    viewerController?.info?.viewerReady,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    if (!pointCloudLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    const captureJob = viewerController?.info?.captureJobMetadata;
    if (!captureJob?.cameraSettings) {
      viewerController?.setCameraFrontView();
    } else {
      viewerController?.setCameraSettings(captureJob?.cameraSettings);
    }
  }, [
    pointCloudLoaded,
    viewerController?.info?.viewerReady,
    viewerController?.info?.captureJobMetadata,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }

    if (viewerController?.info?.cameraPositionsVisible) {
      viewerController?.threeViewer?.showImageMarkers();
    } else {
      viewerController?.threeViewer?.hideImageMarkers();
    }
  }, [viewerController?.info?.cameraPositionsVisible]);

  useEffect(() => {
    // go through all annotation3d and see if they are all not visible
    const allNotVisible = annotations.every(
      (annotation) => !annotation.isVisible
    );
    if (allNotVisible) {
      setToggleAll3dAnnotations(!allNotVisible);
    } else {
      setToggleAll3dAnnotations(true);
    }
  }, [annotations]);

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      <div
        className={`bg-white shadow-lg rounded-lg flex flex-row p-1 items-center`}
      >
        {isMobile ? (
          <CloudButton.Icon
            id="camera-positions-toggle"
            disabled={!hasImages || !pointCloudLoaded}
            onClick={() => {
              if (!hasImages && !pointCloudLoaded) {
                return;
              }

              viewerController?.setCameraPositionsVisible?.(
                !viewerController.info.cameraPositionsVisible
              );
            }}
            leadingIcon="camera"
            tooltip="Toggle Camera Positions"
            tooltipPosition="bottom"
            toggled={
              (viewerController?.info.cameraPositionsVisible ?? false) &&
              hasImages
            }
          />
        ) : (
          <CloudInput.Toggle
            id="camera-positions-toggle"
            disabled={!hasImages || !pointCloudLoaded}
            value={
              (viewerController?.info.cameraPositionsVisible ?? false) &&
              hasImages
            }
            setValue={() => {
              if (!hasImages && !pointCloudLoaded) {
                return;
              }

              viewerController?.setCameraPositionsVisible?.(
                !viewerController.info.cameraPositionsVisible
              );
            }}
            leadingIcon="camera"
            highlighted={
              (viewerController?.info.cameraPositionsVisible ?? false) &&
              hasImages
            }
          />
        )}
      </div>
      <div
        className={
          'flex flex-row cursor-pointer bg-white rounded-lg px-1 gap-1 items-center shadow-lg'
        }
      >
        {isMobile ? (
          <CloudButton.Icon
            id="annotations-toggle"
            disabled={!pointCloudLoaded || !annotations?.length}
            toggled={toggleAll3dAnnotations}
            tooltip="Toggle Annotations"
            tooltipPosition="bottom"
            onClick={() => {
              setToggleAll3dAnnotations(!toggleAll3dAnnotations);
              viewerController?.toggleAll3dAnnotations?.(
                !toggleAll3dAnnotations
              );
              if (!toggleAll3dAnnotations) {
                setSearch(
                  (prev) => {
                    prev.set('details', 'annotations');
                    return prev;
                  },
                  {
                    replace: true,
                  }
                );
              }
            }}
            leadingIcon="location-dot"
          />
        ) : (
          <CloudInput.Toggle
            id="annotations-toggle"
            disabled={!pointCloudLoaded || !annotations?.length}
            value={toggleAll3dAnnotations}
            setValue={(value) => {
              setToggleAll3dAnnotations(value);
              viewerController?.toggleAll3dAnnotations?.(value);
              if (value) {
                setSearch(
                  (prev) => {
                    prev.set('details', 'annotations');
                    return prev;
                  },
                  {
                    replace: true,
                  }
                );
              }
            }}
            highlighted={toggleAll3dAnnotations}
            leadingIcon="location-dot"
          />
        )}
      </div>

      {hasClassification && (
        <div className="bg-white shadow-lg rounded-lg flex flex-row p-1 items-center">
          {isMobile ? (
            <>
              <CloudButton.Icon
                id="model-material-toggle-gaussian-splats"
                toggled={isRGBA}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(MaterialType.RGBA);
                  }
                }}
                leadingIcon="image"
                tooltip="Photorealistic"
                tooltipPosition="bottom"
              />
              <CloudButton.Icon
                id="model-material-toggle-gaussian-points"
                toggled={isClassified}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(
                      MaterialType.CLASSIFICATION
                    );
                  }
                }}
                // disabled={!gsModelLoaded}
                leadingIcon="swatchbook"
                tooltip="Classified"
                tooltipPosition="bottom"
              />
            </>
          ) : (
            <>
              <CloudButton.Icon
                id="model-material-toggle-gaussian-splats"
                toggled={isRGBA}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(MaterialType.RGBA);
                  }
                }}
                leadingIcon="image"
                label="Photorealistic"
              />
              <CloudButton.Icon
                id="model-material-toggle-gaussian-points"
                toggled={isClassified}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(
                      MaterialType.CLASSIFICATION
                    );
                  }
                }}
                leadingIcon="swatchbook"
                label="Classified"
              />
            </>
          )}
        </div>
      )}

      <div
        className={
          'flex flex-row bg-white rounded-lg px-1 gap-1 items-center shadow-lg'
        }
      >
        <CloudButton.Icon
          id="model-crop-button"
          onClick={async () => {
            await viewerController?.toggleCropEditingTool();
          }}
          leadingIcon="crop"
          tooltip="Crop"
        />
      </div>
    </div>
  );
};
