import { CaptureObjectCustomAttribute } from '@agerpoint/api';
import { getSymbol } from '@agerpoint/utilities';

interface CaptureObjectAttributeSectionProps {
  captureAttributes: CaptureObjectCustomAttribute[] | undefined | null;
}

export const CaptureObjectAttributeSection = ({
  captureAttributes,
}: CaptureObjectAttributeSectionProps) => {
  return (
    <div className="flex flex-col text-sm pl-6 font-sm">
      {captureAttributes?.length ? (
        captureAttributes.map((attribute: CaptureObjectCustomAttribute) => (
          <div key={attribute.id} className="flex">
            <span className="text-sm text-gray-800 truncate w-2/5">
              {attribute.attributeDisplayName ?? attribute.attributeName}
            </span>
            <span className="text-sm text-gray-800 truncate">
              {attribute.attributeValue} {getSymbol(attribute)}
            </span>
          </div>
        ))
      ) : (
        <div className="text-sm text-gray-800 truncate">
          No attributes found
        </div>
      )}
    </div>
  );
};
