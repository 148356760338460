import EventEmitter from 'events';
import { OrthographicCamera, PerspectiveCamera, Scene } from 'three';

import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  HexColor,
  IAnnotationBase,
  IAnnotations2dGeometry,
  IAnnotations3dGeometry,
} from '@agerpoint/types';

import { AnnotationsStore } from './annotations-store/annotations-store';

export class AnnotationBase extends EventEmitter implements IAnnotationBase {
  static annoStore: AnnotationsStore = new AnnotationsStore();

  scene: Scene;
  perspectiveCamera: PerspectiveCamera;
  canvas: HTMLCanvasElement | undefined;
  isPotree: boolean;
  orthographicCamera: OrthographicCamera;
  color: ColorsThreeD | HexColor = ColorsThreeD.Cyan;

  private selectedObject: {
    id: string;
    type:
      | Annotation3dPoints
      | Annotation3dLines
      | Annotation3dPolygons
      | Annotation2dPoints
      | undefined;
  } | null = null;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    canvas?: HTMLCanvasElement
  ) {
    super();
    this.scene = scene;
    if (canvas) {
      this.canvas = canvas;
    }
    this.perspectiveCamera = perspectiveCamera;
    this.isPotree = isPotree;

    const frustumSize = 1000;
    const aspect = window.innerWidth / window.innerHeight;
    this.orthographicCamera = new OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      1,
      2000
    );
    this.orthographicCamera.position.set(0, 0, 100);
  }

  destroy() {
    this.removeAllListeners();
  }

  public notifyListeners() {
    const annotationGeom = AnnotationBase.annoStore.get3dAnnotationGeometry();
    const annotationGeomWithSelected = {
      ...annotationGeom,
      selected: {
        id: this.selectedObject?.id || '',
        type: this.selectedObject?.type,
      },
    } as IAnnotations3dGeometry;

    const annotation2dGeom = AnnotationBase.annoStore.get2dAnnotationGeometry();
    const annotation2dGeomWithSelected = {
      ...annotation2dGeom,
      selected: {
        id: this.selectedObject?.id || '',
        type: this.selectedObject?.type,
      },
    } as IAnnotations2dGeometry;

    AnnotationBase.annoStore.debouncedDispatch(
      annotationGeomWithSelected,
      annotation2dGeomWithSelected
    );
  }

  public unHighlightEverything() {
    AnnotationBase.annoStore.unHighlightAll3d();
    AnnotationBase.annoStore.unHighlightAll2d();
  }
  /**
   * Set the selected object.
   * @private
   * @param {string} id - The ID of the object.
   * @param {Annotation3dPoints|Annotation3dLines|Annotation3dPolygons} type - The type of the object.
   */
  setSelectedObject(
    id: string,
    type:
      | Annotation3dPoints
      | Annotation3dLines
      | Annotation3dPolygons
      | Annotation2dPoints
      | undefined
  ) {
    this.selectedObject = { id, type };
    this.notifyListeners();
  }

  /**
   * Unhighlight all 3D annotations.
   * @returns {void}
   */
  public unHighlightAll() {
    AnnotationBase.annoStore.unHighlightAll2d();
    AnnotationBase.annoStore.unHighlightAll3d();
    this.selectedObject = null;
    this.notifyListeners();
  }
  public removePoint3dById(id: string) {
    AnnotationBase.annoStore.removePoint3dById(id);
  }
  public removePoint2dById(id: string) {
    AnnotationBase.annoStore.removePoint2dById(id);
  }
  public removeLine3dById(id: string) {
    AnnotationBase.annoStore.removeLine3dById(id);
  }
  public removePolygon3dById(id: string) {
    AnnotationBase.annoStore.removePolygon3dById(id);
  }
}
