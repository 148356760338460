import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { APIClient, Capture, CaptureJob } from '@agerpoint/api';
import { IViewer, MixpanelNames, ViewerTypeOld } from '@agerpoint/types';
import { APIUtils, useGlobalStore } from '@agerpoint/utilities';

import { Gs3dComponent } from '../gs-3d-viewer/gs-three-d.component';
import { PotreeViewer } from '../potree-viewer';

export const ThreeDViewer = ({
  setViewer,
  overrideCapture,
  token,
  controlsAllowed = true,
  overrideCaptureJob,
}: {
  setViewer: React.Dispatch<
    React.SetStateAction<
      React.MutableRefObject<IViewer | undefined> | undefined
    >
  >;
  overrideCapture?: Capture;
  token: string;
  controlsAllowed?: boolean;
  overrideCaptureJob?: CaptureJob | undefined | null;
}) => {
  const { eptId, captureId } = useParams() as {
    eptId: string;
    captureId: string;
  };

  const {
    capturesViewer: {
      viewerType,
      actions: { setViewerType },
    },
  } = useGlobalStore();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
    },
  });

  const [whichComponent, setWhichComponent] = useState<{
    potree: boolean;
    threePly: boolean;
    eptId: string | undefined;
    capture: Capture | undefined;
    captureJob: CaptureJob | undefined;
  }>({
    potree: false,
    threePly: false,
    eptId: undefined,
    capture: undefined,
    captureJob: overrideCaptureJob || undefined,
  });

  useEffect(() => {
    mixpanel.time_event(MixpanelNames.ThreeDViewerUsageTracking);

    return () => {
      if (whichComponent.potree || whichComponent.threePly) {
        mixpanel.track(MixpanelNames.ThreeDViewerUsageTracking, {
          capture: whichComponent.capture?.id,
          captureJob: whichComponent.captureJob?.id,
          eptId: whichComponent.eptId,
          potree: whichComponent.potree,
          threePly: whichComponent.threePly,
        });
      }
    };
  }, [whichComponent.potree, whichComponent.threePly]);

  const hasGsJobFn = useCallback((cj: CaptureJob) => {
    return ['g1', 'g2'].includes(cj.mosaicEngine?.toLowerCase() ?? '');
  }, []);

  useEffect(() => {
    if (!whichComponent.potree && !whichComponent.threePly) {
      setViewerType(ViewerTypeOld.NONE);
      return;
    }
    setViewerType(
      whichComponent.potree ? ViewerTypeOld.POTREE : ViewerTypeOld.THREE_PLY
    );
  }, [whichComponent, setViewerType]);

  useEffect(() => {
    if (overrideCapture || overrideCaptureJob) {
      const captureJob =
        overrideCaptureJob ||
        overrideCapture?.completedJobs?.find((job) => job.archived === false);
      if (!captureJob) return;

      const hasGsJob = hasGsJobFn(captureJob);
      setWhichComponent({
        potree: !hasGsJob,
        threePly: hasGsJob,
        eptId: captureJob.eptPointcloudId?.toString(),
        capture: overrideCapture,
        captureJob: captureJob,
      });
      return;
    }

    if (captureQuery.data && eptId) {
      const captureJob =
        overrideCaptureJob ||
        captureQuery.data?.completedJobs?.find(
          (job) => job.eptPointcloudId?.toString() === eptId
        );
      if (!captureJob) return;
      const hasGsJob = hasGsJobFn(captureJob);
      setWhichComponent({
        potree: !hasGsJob,
        threePly: hasGsJob,
        eptId: eptId,
        capture: captureQuery.data,
        captureJob: captureJob,
      });
      return;
    }

    setWhichComponent({
      potree: false,
      threePly: false,
      eptId: undefined,
      capture: undefined,
      captureJob: undefined,
    });
  }, [captureQuery.data, overrideCapture, eptId, captureId]);

  const viewerComponent = useCallback(() => {
    switch (viewerType) {
      case ViewerTypeOld.POTREE:
        return (
          <PotreeViewer
            setViewer={setViewer}
            eptId={whichComponent.eptId ?? ''}
            token={token}
          />
        );
      case ViewerTypeOld.THREE_PLY:
        return (
          <Gs3dComponent
            selectedCapture={whichComponent.capture}
            selectedCaptureJob={whichComponent.captureJob}
            controlsAllowed={controlsAllowed}
          />
        );
      default:
        return null;
    }
  }, [viewerType, whichComponent, setViewer]);

  return viewerComponent();
};
