import { NavLink } from 'react-router-dom';

import { SitewideLoadingBar, SvgElement } from '@agerpoint/component';

import { BackgroundTaskManagerPanel } from '../background-task-manager/background-task-manager-panel';
import { HelpButton } from '../help-button';
import { RecentAccessPanel } from '../recent-access-panel/recent-access-panel';
import { ToastsBell } from '../toasts/toasts-bell';

interface AppSidebarNavProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

export const AppSidebarNav = ({
  expanded,
  setExpanded,
}: AppSidebarNavProps) => {
  return (
    <nav
      className="fixed top-0 w-full bg-white border-b border-gray-200 print:hidden"
      style={{ zIndex: 100 }}
    >
      <div className="px-3 py-1 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button
              onClick={() => {
                setExpanded(!expanded);
              }}
              type="button"
              className={`inline-flex items-center p-2 text-sm text-gray-500 rounded-lg mr-2 hover:text-gray-900`}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
            <NavLink
              end
              to="/captures"
              className={({ isActive }) =>
                isActive ? ' pointer-events-none' : ''
              }
            >
              <SvgElement type="AgerpointLogo" style={{ width: '10rem' }} />
            </NavLink>
          </div>
          <div className="flex items-center justify-end gap-2">
            <RecentAccessPanel />
            <BackgroundTaskManagerPanel />
            <ToastsBell />
            <HelpButton />
          </div>
        </div>
      </div>
      <SitewideLoadingBar />
    </nav>
  );
};
