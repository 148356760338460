/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { CaptureAudio } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureAudios = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureAudio[]>(
    { url: `/api/CaptureAudios`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureAudiosQueryKey = () => {
  return [`/api/CaptureAudios`] as const;
};

export const getGetCaptureAudiosQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureAudios>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureAudios>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureAudiosQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureAudios>>
  > = ({ signal }) => getCaptureAudios(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureAudios>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureAudiosQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureAudios>>
>;
export type GetCaptureAudiosQueryError = ErrorType<void>;

export const useGetCaptureAudios = <
  TData = Awaited<ReturnType<typeof getCaptureAudios>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureAudios>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureAudiosQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureAudiosByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureAudio[]>(
    {
      url: `/api/CaptureAudios/Capture/${encodeURIComponent(
        String(captureId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureAudiosByCaptureIdQueryKey = (captureId: number) => {
  return [`/api/CaptureAudios/Capture/${captureId}`] as const;
};

export const getGetCaptureAudiosByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureAudiosByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>
  > = ({ signal }) =>
    getCaptureAudiosByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureAudiosByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>
>;
export type GetCaptureAudiosByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureAudiosByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureAudiosByCaptureIdQueryOptions(
    captureId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
