import { useContext } from 'react';

import { Input } from '@agerpoint/component';
import { MaterialType } from '@agerpoint/types';

import { CapturesViewerContext } from '../../captures-viewer/captures-viewer-context';
import { useCapturesViewerQueries } from '../../captures-viewer/captures-viewer-queries';
import { ClassificationColorPicker } from '../../captures-viewer/captures-viewer-sidebar3/routes/analytics-outputs/analytic-output-details/cvs3-analytic-output-details';

export const QaqcCaptureClassification = () => {
  const { viewerController, selectedCaptureJob } = useContext(
    CapturesViewerContext
  );
  const { analyticRequestQuery } = useCapturesViewerQueries({
    selectedCaptureJob,
  });

  return (
    <div className="w-full h-full overflow-auto bg-gray-700 rounded text-xs p-2">
      <Input.Select.Inline
        disabled={
          analyticRequestQuery.isLoading ||
          viewerController?.potreeController?.info.classificationClasses ===
            undefined ||
          selectedCaptureJob?.mlModel === undefined
        }
        id="3d-model-style-select"
        options={[MaterialType.RGBA, MaterialType.CLASSIFICATION]}
        value={viewerController?.potreeController?.info.pointStyle}
        setValue={(value) => {
          viewerController?.potreeController?.setPointStyle(
            value ?? MaterialType.RGBA
          );
        }}
        style={Input.style.mini}
        optionBuilder={(option) => {
          if (option === MaterialType.RGBA) {
            return 'RGB';
          }
          if (option === MaterialType.CLASSIFICATION) {
            return 'Classification';
          } else {
            return 'Unknown';
          }
        }}
      />
      {viewerController?.potreeController?.info.pointStyle ===
        MaterialType.CLASSIFICATION &&
        viewerController?.potreeController?.info.classificationClasses && (
          <div className="flex flex-col gap-1 text-sm pt-2">
            {viewerController?.potreeController?.info.classificationClasses.map(
              (classificationClass, index) => (
                <div
                  key={index}
                  className="flex flex-row w-full items-center justify-between"
                >
                  <Input.Checkbox
                    id={`classification-class-checkbox-${index}`}
                    value={classificationClass.visible}
                    setValue={(value) => {
                      viewerController?.potreeController?.updateClassificationClassByLabel(
                        classificationClass.label,
                        { visible: value }
                      );
                    }}
                    label={
                      <Input.Label label={classificationClass.labelString} />
                    }
                  />
                  <ClassificationColorPicker
                    viewerController={viewerController?.potreeController}
                    classificationClass={classificationClass}
                  />
                </div>
              )
            )}
          </div>
        )}
    </div>
  );
};
