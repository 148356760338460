import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useAdminUploadsQueries = (filter?: APIModels.UploadFilter) => {
  const queryClient = useQueryClient();
  const { uploadId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const toasts = useToasts();

  const isViteApp = useIsViteApp();

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const uploadTypesQuery = APIClient.useGetUploadType({
    query: {
      queryKey: [APIUtils.QueryKey.uploadTypes],
      select: (data) => APIUtils.Sort.uploadTypes(data),
    },
  });

  const uploadsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.uploads,
      APIUtils.QueryKey.infinite,
      { filter: filter },
    ],
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPagedUploads(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.UploadFilter
      ),
    enabled: filter !== undefined,
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const uploadQuery = APIClient.useGetUploadById(Number(uploadId), {
    query: {
      queryKey: [APIUtils.QueryKey.uploads, { uploadId: Number(uploadId) }],
      initialData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue<APIModels.Upload>({
          queryClient,
          queryKey: [APIUtils.QueryKey.uploads, APIUtils.QueryKey.infinite],
          id: Number(uploadId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const uploadPutMutation = APIClient.usePutUploadById({
    mutation: {
      onSuccess: (_, variables) => {
        APIUtils.updateInfiniteQueryCache<APIModels.Upload>({
          queryClient,
          queryKey: [APIUtils.QueryKey.uploads, APIUtils.QueryKey.infinite],
          id: variables.id,
          accessor: 'id',
          data: variables.data,
        });

        APIUtils.updateQueryCache<APIModels.Upload>({
          queryClient,
          queryKey: [APIUtils.QueryKey.uploads, { uploadId: variables.id }],
          data: variables.data,
        });
        toasts.add(toasts.prepare.entityUpdated('upload'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update upload!'));
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.uploads],
        });
      },
    },
  });

  useEffect(() => {
    if (uploadId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(uploadId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/uploads' + params);
      } else {
        navigate('/admin/uploads' + params);
      }
      queryClient.removeQueries({
        queryKey: [APIUtils.QueryKey.uploads, { uploadId: Number(uploadId) }],
      });
    }
  }, [uploadId]);

  return {
    organizationsQuery,
    uploadTypesQuery,
    uploadsQuery,
    organizationsLookupTable,
    uploadQuery,
    uploadPutMutation,
  };
};
