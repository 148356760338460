import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';

export const countries = (data?: APIModels.Country[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) =>
    compare(a.countryName?.toLowerCase(), b.countryName?.toLowerCase())
  );
  return sorted;
};
