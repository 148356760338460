import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL:
    (process.env.NX_API_SERVER_URL as string) || 'AGERCLOUD_API_SERVER_URL',
});

export const AxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const response = await AXIOS_INSTANCE({
    ...config,
    ...options,
  });

  const status = response?.status;

  if (status === undefined) {
    throw new Error('Failed to fetch: No response');
  }

  if (status < 200 || status >= 300) {
    throw new Error(
      `Failed to fetch: ${response?.status} ${response?.statusText}\n ${response?.data}`
    );
  }

  return response?.data ?? undefined;
};

export type BodyType<BodyData> = BodyData;
export type ErrorType<Error> = AxiosError<Error>;
