import { PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  EventBusNames,
  ICustomMesh,
} from '@agerpoint/types';
import { CustomMesh, createSphereObject } from '@agerpoint/utilities';

import { Annotations2d } from '../annotations-2d/annotations-2d';
import { Annotations3d } from '../annotations-3d';

export class AnnotationDrawing {
  protected scene: Scene;

  protected annotation3d: Annotations3d | undefined;
  protected annotation2d: Annotations2d | undefined;

  protected _creating2dPoint: boolean = false;
  protected _creating2dMultiPoint: boolean = false;
  protected _creating3dMultiPoint: boolean = false;
  protected _creating3dLine: boolean = false;
  protected _creating3dPolygon: boolean = false;

  protected new2dPointId: string | undefined = undefined;
  protected new2dMultiPointId: string | undefined = undefined;
  protected new3dMultiPointId: string | undefined = undefined;
  protected new3dLineId: string | undefined = undefined;
  protected new3dPolygonId: string | undefined = undefined;

  protected isInEditMode: boolean = false;
  protected editTime: number = 0;

  protected _editing3dLine: boolean = false;
  protected _editing3dLineVertex: boolean = false;

  protected _editing2dPoint: boolean = false;
  protected _editing2dMultiPoint: boolean = false;
  protected _editing3dMultiPoint: boolean = false;

  protected pointBeingEdited: ICustomMesh | undefined;
  protected line3dBeingEdited_uniqueId = '';
  protected point2dBeingEdited_uniqueId = '';
  protected multiPoint2dBeingEdited_uniqueId = '';
  protected initialMousePosition: { x: number; y: number; z: number } = {
    x: 0,
    y: 0,
    z: 0,
  };

  protected pending2dMultiPoint: Vector3[] | undefined;
  protected pendingLine: Vector3[] | undefined;
  protected pendingPolygon: Vector3[] | undefined;
  protected pending3dMultiPoint: Vector3[] | undefined;

  protected mousePosition: Vector3 | undefined;
  protected mousePositionRef: React.MutableRefObject<Vector3 | undefined>;
  protected mouseReticle: CustomMesh | undefined;

  protected isPotree: boolean = false;

  constructor(
    camera: PerspectiveCamera,
    scene: Scene,
    targetEl: HTMLElement,
    mouseRef: React.MutableRefObject<Vector3 | undefined>,
    isPotree: boolean = false
  ) {
    this.scene = scene;

    this.annotation3d = new Annotations3d(scene, camera, isPotree, targetEl);
    const canvas = targetEl.querySelector('canvas');
    if (canvas) {
      this.annotation2d = new Annotations2d(scene, camera, isPotree, canvas);
    }
    this.mousePositionRef = mouseRef;
    this.mousePosition = new Vector3();
    this.isPotree = isPotree;
  }

  get editing2dPoint(): boolean {
    return !!this.point2dBeingEdited_uniqueId;
  }

  destroy() {
    this.annotation3d?.destroy();
    this.annotation2d?.destroy();
  }

  /**
   * 2D Point Methods
   * */

  startCreating2dPoint() {
    this.noMoreEditing();

    this._creating2dPoint = true;
    this.new2dPointId = this.annotation2d?.getNew2dPointId();
    if (!this.new2dPointId) return;
    this.annotation2d?.add2dPoint(
      EventBusNames.Annotation2dPointLocation,
      this.new2dPointId,
      new Vector3(0, 0, 0),
      {
        fill: this.annotation2d?.getNext2dPointColor(),
        name: 'New Point',
        visible: true,
        visibleLabel: true,
        clickable: true,
        editable: false,
      }
    );
  }

  startEditing2dPoint(uniqueId: string) {
    // enable editing
    this.noMoreEditing();

    this.isInEditMode = true;
    this.point2dBeingEdited_uniqueId = uniqueId;
  }

  startMoving2dPoint() {
    // actually start moving the point
    this._editing2dPoint = true;
    this.editTime = Date.now();
    this.initialMousePosition = {
      x: this.mousePosition?.x || 0,
      y: this.mousePosition?.y || 0,
      z: this.mousePosition?.z || 0,
    };
  }

  updateExisting2dPoint() {
    if (
      !this.mousePosition?.x &&
      !this.mousePosition?.y &&
      !this.mousePosition?.z
    )
      return;

    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - this.editTime;
    // Calculate the distance moved
    const distanceX = this.mousePosition?.x - this.initialMousePosition?.x;
    const distanceY = this.mousePosition?.y - this.initialMousePosition?.y;
    const distanceZ = this.mousePosition?.z - this.initialMousePosition?.z;
    const distanceMoved = Math.sqrt(
      distanceX * distanceX + distanceY * distanceY + distanceZ * distanceZ
    );

    // Only update the position if the mouse has moved significantly or if enough time has passed
    if (timeElapsed < 100 && distanceMoved < 5) return;

    if (
      !this.mousePosition?.x ||
      !this.mousePosition?.y ||
      !this.mousePosition?.z
    )
      return;

    // check if we are updating a single 2d point or a multipoint child
    if (this._editing3dMultiPoint) {
      this._editing2dMultiPoint = true;
      this.annotation2d?.updateMultiPointChildPosition(
        this.point2dBeingEdited_uniqueId,
        this.mousePosition || new Vector3()
      );
    } else {
      this._editing2dPoint = true;
      this.annotation2d?.update2dPointPosition(
        this.point2dBeingEdited_uniqueId,
        this.mousePosition || new Vector3()
      );
    }
  }

  finishCreating2dPoint(cancelled: boolean) {
    this._creating2dPoint = false;
    if (cancelled) {
      this.annotation2d?.remove2dPointById(this.new2dPointId || '');
    }
    this.annotation2d?.savePoint(this.new2dPointId || '');
    this.annotation2d?.remove2dPointById(this.new2dPointId || '');
    this.new2dPointId = undefined;
  }

  finishEditing2dPoint() {
    this.noMoreEditing();

    this.annotation2d?.save2dPointPosition(this.point2dBeingEdited_uniqueId);
    this.point2dBeingEdited_uniqueId = '';
  }

  cancelCreating2dPoint() {
    this._creating2dPoint = false;
    this.annotation2d?.remove2dPointById(this.new2dPointId || '');
    this.new2dPointId = undefined;
  }

  cancelEditing2dPoint() {
    this.noMoreEditing();

    this.point2dBeingEdited_uniqueId = '';
    this.editTime = 0;
    this.initialMousePosition = {
      x: 0,
      y: 0,
      z: 0,
    };
  }

  /**
   * 2D MultiPoint Methods
   * */

  startCreating2dMultiPoint() {
    this.noMoreEditing();
    this._creating2dMultiPoint = true;
    this.new2dMultiPointId = this.annotation2d?.getNew2dMultiPointId();
    if (!this.new2dMultiPointId) return;
    this.multiPoint2dBeingEdited_uniqueId = this.new2dMultiPointId;
    this.annotation2d?.add2dMultiPoint(
      this.new2dMultiPointId,
      [this.mousePosition || new Vector3()],
      {
        color: this.annotation2d?.getNext2dMultiPointColor(),
        name: 'New MultiPoint',
        id: this.new2dMultiPointId,
        description: '',
        type: Annotation2dPoints.AnnotationMultiPoint2d,
      }
    );
  }

  add2dMultiPointToPending(id: string) {
    const obj = this.annotation2d?.get2dMultiPointByChildId(id);
    if (!obj) return;

    if (!this.mousePosition) return;
    obj.addNewPoint(this.mousePosition);
  }

  startEditing2dMultiPoint(uniqueId: string) {
    this.noMoreEditing();
    this.isInEditMode = true;
    this.multiPoint2dBeingEdited_uniqueId = uniqueId;
  }

  startMoving2dMultiPoint() {
    this._editing2dMultiPoint = true;
    this.editTime = Date.now();
    this.initialMousePosition = {
      x: this.mousePosition?.x || 0,
      y: this.mousePosition?.y || 0,
      z: this.mousePosition?.z || 0,
    };
  }

  update2dMultiPointPosition() {
    const id = this.multiPoint2dBeingEdited_uniqueId;
    if (!id) return;
    if (!this.mousePosition) return;
    this.annotation2d?.updateMultiPointChildPosition(id, this.mousePosition);
  }

  finishCreating2dMultiPoint() {
    this.noMoreEditing();

    this.annotation2d?.removeLastPointFrom2dMultiPoint(
      this.multiPoint2dBeingEdited_uniqueId
    );

    this.annotation2d?.saveMultiPoint(this.multiPoint2dBeingEdited_uniqueId);
    this.annotation2d?.remove2dMultiPointById(
      this.multiPoint2dBeingEdited_uniqueId
    );
    this.multiPoint2dBeingEdited_uniqueId = '';
  }

  finishEditing2dMultiPoint() {
    this.noMoreEditing();
    this.annotation2d?.save2dMultiPointPosition(
      this.multiPoint2dBeingEdited_uniqueId
    );
    this.multiPoint2dBeingEdited_uniqueId = '';
  }

  cancelEditing2dMultiPoint() {
    this.noMoreEditing();
    this.pending2dMultiPoint = undefined;
    this.annotation2d?.remove2dMultiPointById(
      this.multiPoint2dBeingEdited_uniqueId
    );
    this.multiPoint2dBeingEdited_uniqueId = '';
  }

  /**
   * Line Methods
   */

  startCreating3dLine() {
    this.noMoreEditing();
    this._creating3dLine = true;
    this.new3dLineId = this.annotation3d?.getNew3dLineId();
    this.pendingLine = [];
    if (!this.new3dLineId) return;
    const coAttrs = {
      name: 'New Line',
      description: '',
      id: this.new3dLineId,
      color: this.annotation3d?.getNext3dLineColor(),
      type: Annotation3dLines.PendingLine,
    };
    this.annotation3d?.add3dLine(
      coAttrs,
      this.pendingLine,
      Annotation3dLines.PendingLine
    );
  }

  startEditing3dLine(uniqueId: string) {
    this.noMoreEditing();
    const line = this.annotation3d?.get3dLineById(uniqueId);
    line?.highlight();
    this._editing3dLine = true;
    this.isInEditMode = true;
    this.line3dBeingEdited_uniqueId = uniqueId;
    this.pendingLine = [];
  }

  startEditing3dLineVertex(point: ICustomMesh) {
    this.pointBeingEdited = point;
    this._editing3dLineVertex = true;
  }

  convertMidpointToVertex(midpoint: ICustomMesh) {
    // Extract and parse the midpoint index
    const midpointIndex = parseInt(midpoint.uniqueId.split('-')[2]);

    // Calculate the vertex position
    const vertexPosition = midpointIndex + 1;

    // Retrieve the line associated with the current unique ID
    const linePos = this.annotation3d?.get3dLinePositionById(
      this.line3dBeingEdited_uniqueId
    );

    // Clone the line positions to a new list if line exists
    const newPointsList = linePos ? [...linePos] : null;
    if (!newPointsList) return;

    // Insert the midpoint's position into the calculated position
    newPointsList.splice(vertexPosition, 0, midpoint.position);
    // Update the line positions with the new points list
    this.annotation3d?.update3dLinePosition(
      this.line3dBeingEdited_uniqueId,
      newPointsList
    );
  }

  deleteLineVertex(point: ICustomMesh) {
    // Extract and parse the point index
    const pointIndex = parseInt(point.uniqueId.split('-')[2], 10);

    // Retrieve the line associated with the current unique ID
    const linePos = this.annotation3d?.get3dLinePositionById(
      this.line3dBeingEdited_uniqueId
    );

    // prevent a line from being deleted if it only has 2 points
    if (!linePos || linePos.length < 3) return;

    // Clone the line positions to a new list if line exists
    const newPointsList = linePos ? [...linePos] : null;
    if (!newPointsList) return;

    // Remove the point from the list
    newPointsList.splice(pointIndex, 1);

    // Update the line positions with the new points list
    const line = this.annotation3d?.get3dLineById(
      this.line3dBeingEdited_uniqueId
    );
    line?.updatePosition(newPointsList);
    this.annotation3d?.update3dLinePosition(
      this.line3dBeingEdited_uniqueId,
      newPointsList
    );
  }

  finishCreating3dLine() {
    if (this.pendingLine?.length) {
      this.noMoreEditing();
      this.annotation3d?.finishCreating3dLine(this.new3dLineId || '');
      this.annotation3d?.update3dLinePosition(this.new3dLineId || '', [
        ...this.pendingLine,
      ]);
      this.annotation3d?.saveLine(this.new3dLineId || '');
      this.pendingLine = undefined;
      this.new3dLineId = undefined;
    }
  }

  finishEditing3dLineVertex() {
    this.pointBeingEdited = undefined;
    this._editing3dLineVertex = false;
  }

  finishEditing3dLine() {
    this.noMoreEditing();
    this.annotation3d?.saveUpdatedLinePosition(this.line3dBeingEdited_uniqueId);
    this.line3dBeingEdited_uniqueId = '';
    this.pendingLine = undefined;
    this.new3dLineId = undefined;
  }

  cancelCreating3dLine() {
    this.noMoreEditing();
    this.annotation3d?.remove3dLineById(this.new3dLineId || '');
    this.pendingLine = undefined;
    this.new3dLineId = undefined;
  }

  protected updateExistingLine() {
    const idList = this.pointBeingEdited?.uniqueId?.split('-');
    const lineId = idList?.[1];
    const index = idList?.[2];
    const currentLinePos = this.annotation3d?.get3dLinePositionById(
      lineId || ''
    );
    const newLinePositions =
      currentLinePos?.map((pos, i) => {
        if (i === parseInt(index || '')) {
          return this.mousePosition || pos;
        }
        return pos;
      }) || [];
    this.annotation3d?.update3dLinePosition(lineId || '', newLinePositions);
  }

  protected noMoreEditing() {
    // creating 3d
    this._creating3dMultiPoint = false;
    this._creating3dLine = false;
    this._creating3dPolygon = false;

    // editing 3d
    this._editing3dLine = false;
    this._editing3dLineVertex = false;

    // creating 2d
    this._creating2dPoint = false;
    this._creating2dMultiPoint = false;

    // editing 2d
    this._editing2dPoint = false;
    this._editing2dMultiPoint = false;
    this._editing3dMultiPoint = false;

    this.isInEditMode = false;
    // this.editTime = 0;

    // this.pointBeingEdited = undefined;
    // this.line3dBeingEdited_uniqueId = '';
    // this.point2dBeingEdited_uniqueId = '';
    // this.multiPoint2dBeingEdited_uniqueId = '';

    // this.pending2dMultiPoint = undefined;
    // this.pendingLine = undefined;
    // this.pendingPolygon = undefined;
    // this.pending3dMultiPoint = undefined;
  }

  protected createWhiteSphereReticle() {
    const sphere = createSphereObject(
      'scene-reticle',
      new Vector3(0, 0, 0),
      null,
      Annotation3dPoints.Reticle,
      this.isPotree,
      '#ffffff'
    );
    return sphere;
  }
}
